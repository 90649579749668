<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveTextarea from '../../components/ui/autosave_textarea.svelte';

	$: inventoryDataByInventory = {
		...$commonParams,
		attribute: 'inventory_data_by_inventory',
		label: 'Данные описей по инвентаризации',
		value: $currentData && $currentData.inventoryDataByInventory,
		placeholder: 'Инвентаризационная опись №0 от дд.мм.гггг',
	};
</script>

<AutosaveTextarea {...inventoryDataByInventory} />
