<!-- @format -->
<script>
	import { createEventDispatcher, tick } from 'svelte';
	import { isPresent, isArray, truncateString, randomString, formatSum } from 'utils/tools';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import { transmitter } from 'base_stores';
	import ReceiptForm from './_form.svelte';
	import BankAccountModal from '~/svelte/bank_accounts/_modal.svelte';
	import ReceivableModal from '~/svelte/receivables/_modal.svelte';
	import { cardsTitle } from 'utils/formats';

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let disabled = false;

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};
	export const editItem = id => {
		formOptions = { item: { id }, method: 'edit' };
		showModal(modalId);
	};
	export const showItem = id => {
		formOptions = { item: { id }, method: 'show' };
		showModal(modalId);
	};

	const modalId = 'receipt-modal-' + randomString();
	const accountModalId = 'bank-account-modal-' + randomString();
	const receivableModalId = 'receivable-modal-' + randomString();

	let formOptions = {};
	let form;
	let formCompleted = false;

	const receiptSubmit = () =>
		form.submitForm().then(result => {
			if (isPresent(result)) {
				// update transmitter
				if (isArray($transmitter.receipts)) {
					const arr = $transmitter.receipts.filter(row => row.id != result.item.id);
					arr.push(result.item);
					arr.sort((a, b) => new Date(a.date_at) - new Date(b.date_at));
					$transmitter.receipts = [...arr];
				}
				dispatch(formOptions.method == 'edit' ? 'update' : 'create', result);
				return result;
			}
		});

	const receiptDelete = () =>
		form.deleteItem().then(id => {
			if (isArray($transmitter.receipts)) {
				$transmitter.receipts = $transmitter.receipts.filter(row => row.id != id);
			}
			dispatch('delete', id);
			return id;
		});

	const receiptDuplicate = () => {
		const { id, ...rest } = form.getItem();
		formOptions = { item: { ...rest }, duplicateMode: true, method: 'new' };
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};

	const bankAccountNew = () => {
		prepareBankAccountData();
		showModal(accountModalId);
	};

	const bankAccountCreated = ({ detail: id }) => {
		const el = $transmitter.bankAccounts.find(e => e.id == id);
		if (isPresent(el)) {
			const name = truncateString(el.kind_name, 18) + ' ' + el.number.slice(-4) + ' (' + el.counterparty_name + (el.is_main ? ' основной' : '') + ')';
			form.setData({ bank_accounts: [...form.getData().bank_accounts, { value: id, label: name }] });
			form.setItem({ bank_account_id: id });
		}
	};
	const bankAccountOptions = {
		bankAccountKind: [],
		currency: [],
		counterparty: [],
	};
	const prepareBankAccountData = () => {
		const data = form.getData();
		bankAccountOptions.bankAccountKind = data.bank_account_kinds.map(e => ({ id: e.value, text: e.label }));
		bankAccountOptions.currency = data.bank_account_currencies.map(e => ({ id: e.value, text: e.label }));
	};

	const receivableNew = () => {
		showModal(receivableModalId);
	};

	const receivableCreated = ({ detail: id }) => {
		const el = $transmitter.receivables.find(e => e.id == id);
		if (isPresent(el)) {
			const name = truncateString(el.counterparty_name, 18) + ' (' + formatSum(el.debt_amount) + ' руб.)';
			form.setData({ receivables: [...form.getData().receivables, { value: id, label: name }] });
			form.setItem({ receivable_id: id });
		}
	};
</script>

<Modal
	{modalId}
	submitButtonDisabled={formOptions.method == 'show' || !formCompleted || disabled}
	submitButtonAction={receiptSubmit}
	deleteIconDisabled={formOptions.method == 'new' || disabled}
	deleteButtonAction={receiptDelete}
	deleteConfirmMessage="Вы действительно хотите удалить поступление?"
	showCopyAndSaveButton={formOptions.method == 'edit' && !disabled}
	copyAndSaveButtonAction={receiptDuplicate}
	form={true}
	showError={false}
	{index}
	size="large"
>
	<h2 slot="header">{cardsTitle('поступления', formOptions.method == 'edit')}</h2>
	<div slot="body">
		<ReceiptForm
			{disabled}
			bind:this={form}
			bind:isCompleted={formCompleted}
			{formOptions}
			on:newBankAccount={bankAccountNew}
			on:newReceivable={receivableNew}
		/>
	</div>
</Modal>

<BankAccountModal modalId={accountModalId} options={bankAccountOptions} on:create={bankAccountCreated} />

<ReceivableModal modalId={receivableModalId} index={+index + 1} on:create={receivableCreated} />
