<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { newObject, options } from 'base_stores';
	import { fetchGet } from 'utils/fetch';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import Case from 'case';

	const dispatch = createEventDispatcher();

	export let regionAttr = 'regionId';
	export let attrKind;

	$: regionId = ($currentData && $currentData[Case.camel(regionAttr)]) || 'all';
	$: correspondentId = $currentData && $currentData.correspondentId;
	$: correspondentKind = attrKind || ($dct && $dct.correspondent_kind);

	$: if ($dct.form_fields.includes('correspondentPosition')) {
		$requiredFieldsFilled.correspondentPosition = !!correspondentPosition.value;
	}

	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('correspondentPosition');

	$: correspondentPosition = {
		...$commonParams,
		disabled:
			$commonParams.disabled ||
			disabled ||
			$dct.status === 'approved' ||
			(!correspondentId && !['administration', 'court', 'creditor', 'bank'].includes(correspondentKind)),
		required: false,
		attribute: 'correspondent_position',
		label: 'Должность КДЛ',
		value: $currentData.correspondentPosition,
		placeholder: 'Введите должность КДЛ',
		previousValue: $currentData.correspondentPosition,
		hintData: {
			url: '/counterparties',
			linkText: 'Карточка контрагента',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.correspondent_position',
			resource: 'counterparty',
			resourceProp: 'additions.kdl.position',
		},
	};

	const refreshCorrespondentOptions = () => {
		let url = '/api/private/counterparties/get_options_for_roles_dcts';
		let params = { roles: ['kdl'] };

		fetchGet(url, params).then(response => ($options.correspondent[correspondentKind][regionId] = response.options));
	};

	const handleResponse = response => {
		dispatch('update', response);
		refreshCorrespondentOptions();
	};
</script>

<AutosaveInput {...correspondentPosition} on:update={({ detail: response }) => handleResponse(response)} />
