<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInputCurrency from '~/svelte/components/ui/autosave_input_currency.svelte';

	$: livingWageForChildren = {
		...$commonParams,
		attribute: 'living_wage_for_children',
		label: 'Прожиточный минимум для детей (руб.)',
		value: $currentData && $currentData.livingWageForChildren,
		placeholder: '0,00',
	};
</script>

<AutosaveInputCurrency {...livingWageForChildren} />
