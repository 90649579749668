<!-- @format -->
<script>
	import { currencyFormat } from 'utils/formats';
	import moment from 'moment';

	export let payment;
	export let goToProcedure = () => {};
</script>

<tr data-payment-id={payment.id}>
	<td class="text-center">{moment(payment.created_at).format('LLL')}</td>
	<td class="text-right">{currencyFormat(payment.total_amount / 100)}</td>
	<td class="text-right">
		{#if payment.procedure_id}
			<span class="procedure-name" role="button" tabindex="0" on:click={() => goToProcedure(payment.procedure_id)} on:keypress|stopPropagation>
				{payment.procedure_name}
			</span>
		{:else}
			-
		{/if}
	</td>
	<td></td>
</tr>

<style>
	.procedure-name {
		cursor: pointer;
		text-decoration: underline;
		color: #337ab7;
	}
</style>
