<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter, procedure } from 'base_stores';
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from '~/js/utils/fetch_helpers.js';
	import AutosaveInputCurrency from '~/svelte/components/ui/autosave_input_currency.svelte';

	$: amountVotingDemands = {
		...$commonParams,
		disabled: true,
		attribute: 'meeting_voting_sum',
		label: 'Сумма голосующих требований',
		value: $currentData.amountVotingDemands,
		placeholder: '0,00',
	};

	onMount(async () => {
		const response = await fetchGet('/api/private/creditors_requirements', { procedure_id: procedure.id, only_active: true });
		$transmitter = { ...$transmitter, creditors_requirements: response.creditors_requirements };
	});
</script>

<AutosaveInputCurrency {...amountVotingDemands} />
