<!-- @format -->
<script>
	import { autosaveStatus, transmitter } from 'base_stores';
	import IMask from 'imask';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import { validate } from 'tools/validators';
	import { isObject, isPresent, randomString } from 'utils/tools';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import { onMount, createEventDispatcher } from 'svelte';
	import SearchBy from '~/svelte/components/ui/dadata/search_by.svelte';

	export let attribute;
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let searchPath;
	export let label;
	export let skipVerificationRequired = true;
	export let value = undefined;
	export let placeholder = '';
	export let required = false;
	export let disabled = false;
	export let modify;
	export let type = 'text';
	export let maskOptions;
	export let hintData;
	export let previousValue = null;
	export let forceSave = false;
	export let additionalParams = {};
	export let saveAutomatically = true;

	let maxLength;
	let inputField;
	let dataErrors;
	let countdown = 1;
	let mask;

	const dispatch = createEventDispatcher();
	const inputId = `dadata-input-passport_department-${model}-${attribute}`; //-${randomString()}`

	$: method = !!id ? fetchPut : fetchPost;
	$: if (!!id) {
		url = `${url}/${id}`;
	}
	$: isSuccess = newValue && !dataErrors;
	$: newValue = modify ? (modify(value) > 0 ? value : null) : value || null;

	$: if (!previousValue && countdown > 0 && value === undefined) {
		countdown = countdown - 1;
	}

	$: if (!previousValue && countdown === 0 && value !== undefined) {
		previousValue = value;
		countdown = countdown - 1;
	}

	$: if (forceSave) {
		handleChange();
	}

	const updateTransmitter = (currentObject, path) => {
		let pathItem = path[0];
		let nextPath = path.slice(1);

		if (nextPath.length === 0) {
			return { ...currentObject, [attribute]: value };
		} else {
			return {
				...currentObject,
				[pathItem]: {
					...currentObject[pathItem],
					[nextPath[0]]: updateTransmitter(currentObject[pathItem][nextPath[0]], nextPath),
				},
			};
		}
	};

	const handleChange = ({ detail: v }) => {
		$autosaveStatus = null;

		if (previousValue !== value) {
			dataErrors = validate(model, attribute, mask ? mask.value : newValue, skipVerificationRequired);

			let nValue = value;
			let nValueCode;

			if (isObject(v)) {
				nValue = v.value;
				if (isPresent(v.data.code)) {
					nValueCode = v.data.code;
				}
			}

			if (!dataErrors) {
				let params = { [model]: { [attribute]: nValue }, [`${childModel}_id`]: childId, ...additionalParams };
				if (nValueCode) {
					params[model] = { ...params[model], [`${attribute}_code`]: nValueCode };
				}

				method(url, params)
					.then(response => {
						if (!forceSave && JSON.stringify(response) !== '{}') {
							$autosaveStatus = 'saved';
						}
						$transmitter = { ...$transmitter, ...response };
						previousValue = nValue;
						forceSave = false;
						dispatch('update', response);
					})
					.catch(_errors => {
						$autosaveStatus = 'not_saved';
					});
				return;
			}
			$autosaveStatus = 'not_saved';
			previousValue = null;

			if (searchPath) {
				$transmitter = updateTransmitter($transmitter, searchPath);
			} else {
				$transmitter[model] = { ...$transmitter[model], [attribute]: value };
			}
		}
	};

	const handleInputType = e => (e.target.type = type);

	const updateMaskValue = () => {
		if (mask) {
			mask.updateValue(mask.value);
		}
	};

	onMount(() => {
		mask = maskOptions ? IMask(inputField, maskOptions) : null;
		mask && mask.on('complete', () => updateMaskValue());
		maxLength = maskOptions && maskOptions.mask.length;
	});
</script>

<div class="form-group row" class:has-error={dataErrors} class:has-success={isSuccess}>
	<label for={inputId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<SearchBy
			method="fms"
			id={inputId}
			requiredborder={required}
			disabledtext={disabled}
			{placeholder}
			bind:value
			{disabled}
			on:change={saveAutomatically && handleChange}
			autocomplete="off"
		/>

		{#if dataErrors}
			<InputErrorsString errors={dataErrors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>
