<!-- @format -->
<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dctFormAvailable, currentData } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { currencyFormat } from 'utils/formats';
	import Modal, { showModal } from '~/svelte/components/modal.svelte';
	import IncomeSourceForm, { incomeSourceSubmit, incomeSourceCancel, incomeSourceDelete } from '~/svelte/income_sources/form.svelte';

	export let fieldId;

	let procedureId = null;
	let newIncomeSource = false;
	let incomeSourceId = null;
	let indexCollapse = false;
	let disableSubmitButton = true;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	$: incomeSources = ($transmitter && $transmitter.incomeSources) || incomeSources || [];
	$: bankruptHasConstantSourceOfIncome = $currentData && $currentData.bankruptHasConstantSourceOfIncome;

	const openModalForm = id => {
		newIncomeSource = !id;
		incomeSourceId = id;
		showModal('form-income_source-modal');
	};

	const loadIncomeSources = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/income_sources', { procedure_id: procedureId });
			$transmitter = { ...$transmitter, incomeSources: response.income_sources };
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: if (!$transmitter.incomeSources && !fetching) {
		loadIncomeSources();
	} else {
		forceSave = false;
	}
</script>

{#if bankruptHasConstantSourceOfIncome}
	<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
		<h4 class="income_sources col-sm-12">
			<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
			<span class="header">{`Источники постоянного дохода (${incomeSources.length})`}</span>
		</h4>
	</div>
	<div id="collapse-income_sources" class="collapse row m-t-n" class:show={indexCollapse}>
		<div class="col-sm-12">
			<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && openModalForm(null)}> + Источник </button>
			<div class="p-1">
				{#if incomeSources.length}
					<ol class="income_sources-list">
						{#each incomeSources as incomeSource}
							<li>
								<div class="float-left simulated-link" role="button" tabindex="0" on:click={openModalForm(incomeSource.id)} on:keypress|stopPropagation>
									{`${incomeSource.name} - ${currencyFormat(incomeSource.amount)} руб.`}
								</div>
							</li>
						{/each}
					</ol>
				{/if}
			</div>
		</div>
	</div>

	<Modal
		modalId="form-income_source-modal"
		deleteIconDisabled={!incomeSourceId || !$dctFormAvailable}
		submitButtonAction={incomeSourceSubmit}
		cancelButtonAction={incomeSourceCancel}
		deleteButtonAction={incomeSourceDelete}
		submitButtonDisabled={disableSubmitButton}
		form={true}
		size="large"
	>
		<h2 slot="header" class="income_source-modal__header">Карточка источника дохода</h2>
		<div slot="body">
			<IncomeSourceForm {incomeSourceId} disabled={!$dctFormAvailable} bind:newIncomeSource bind:disableSubmitButton />
		</div>
	</Modal>
{/if}

<style>
	.income_sources-list {
		display: grid;
		padding-left: 15px;
		list-style-type: circle;
	}
	.income_sources-list > li > div,
	.income_sources > .header {
		cursor: pointer;
	}
</style>
