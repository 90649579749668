/** @format */

import { get } from 'svelte/store';
import { transmitter } from '~/js/global_stores/base.js';
import { formatSum, gaussRound } from '~/js/utils/tools.js';

export const applyStyles = template => `
<style>
  table.root { width: 100%; }
  table.root table { margin: auto !important; }
</style>
${template ? template.replace('<table ', '<table class="root" ') : ''}
`;

export const meetingCreditorData = (question, participant, $transmitter = get(transmitter)) => {
	return {
		meeting_question_title:
			question.kind == 'inclusion'
				? 'Бюллетень для голосования о включении дополнительного вопроса'
				: question.kind == 'additional'
					? 'Бюллетень для голосования по дополнительному вопросу № ' + question.num
					: 'Бюллетень для голосования по ' + question.num + ' вопросу повестки дня',
		meeting_date_at: $transmitter.meeting.date_at,
		meeting_form: $transmitter.meeting.form,
		meeting_time_at: $transmitter.meeting.time_at,
		meeting_note: $transmitter.meeting.note,
		meeting_from_at: $transmitter.meeting.from_at,
		meeting_to_at: $transmitter.meeting.to_at,
		meeting_address: $transmitter.meeting.address,
		meeting_read_at: $transmitter.meeting.read_at,
		meeting_read_from_at: $transmitter.meeting.read_from_at,
		meeting_read_to_at: $transmitter.meeting.read_to_at,
		meeting_read_address: $transmitter.meeting.read_address,
		meeting_questions: $transmitter.meeting.questions,
		meeting_question_id: question.id,
		meeting_bulletin_form: question.bulletin_form,
		meeting_question_question: question.question,
		meeting_question_solution: question.solution,
		meeting_question_num: question.num,
		meeting_question_secured_votes: question.secured_votes,
		meeting_participant_id: participant.id,
		meeting_participant_kind: participant.kind,
		meeting_participant_name: participant.name,
		meeting_participant_full_name: participant.full_name,
		meeting_participant_representative_name: participant.representative_name,
		// meeting_participant_birthday   : participant.birth_date,
		// meeting_participant_birthplace : participant.birth_place,
		// meeting_participant_snils      : participant.snils,
		// meeting_participant_inn        : participant.inn,
		// meeting_participant_reg_address: participant.reg_address,
		meeting_participant_is_person: participant.is_person,
		meeting_participant_voting_sum:
			question.secured_votes == 'accounted'
				? participant.voting_sum
					? formatSum(participant.voting_sum)
					: '________________'
				: participant.unsecured_voting_sum
					? formatSum(participant.unsecured_voting_sum)
					: '________________',
		meeting_participant_total_voting_sum: participant.voting_sum ? formatSum(participant.total_voting_sum) : '',
		meeting_participant_votes_percent: gaussRound(
			$transmitter.meeting.voting_sum > 0 ? ((1 * (participant.voting_sum || 0)) / $transmitter.meeting.voting_sum) * 100 : 0,
			4,
		),
		meeting_form_title: $transmitter.meeting.form == 'facetoface' ? 'Собрание кредиторов' : 'Собрание кредиторов в форме заочного голосования',
		meeting_form_subtitle:
			$transmitter.procedure.bankrupt_type === 'Person' ? 'сведения, идентифицирующие должника' : 'наименование и местонахождение должника',
		meeting_form_when: $transmitter.meeting.form == 'facetoface' ? '(дата проведения)' : '(дата окончания приёма бюллетеней)',
		meeting_form_where: $transmitter.meeting.form == 'facetoface' ? '(место проведения)' : '(адрес для направления бюллетеней)',
		meeting_voting_sum:
			question.secured_votes == 'accounted' ? formatSum($transmitter.meeting.voting_sum) : formatSum($transmitter.meeting.unsecured_voting_sum),
		bankrupt_info: undefined,
		bankrupt_short_name: undefined,
		bankrupt_legal_address: undefined,
	};
};

export const meetingWorkerData = (question, participant, $transmitter = get(transmitter)) => {
	return {
		meeting_question_title:
			question.kind == 'inclusion'
				? 'Бюллетень для голосования о включении дополнительного вопроса'
				: question.kind == 'additional'
					? 'Бюллетень для голосования по дополнительному вопросу № ' + question.num
					: 'Бюллетень для голосования по ' + question.num + ' вопросу повестки дня',
		meeting_date_at: $transmitter.meeting.date_at,
		meeting_form: $transmitter.meeting.form,
		meeting_address: $transmitter.meeting.address,
		meeting_questions: $transmitter.meeting.questions,
		meeting_question_id: question.id,
		meeting_bulletin_form: question.bulletin_form,
		meeting_question_question: question.question,
		meeting_question_solution: question.solution,
		meeting_question_num: question.num,
		meeting_participant_id: participant.id,
		meeting_participant_name: participant.name,
		meeting_participant_full_name: participant.full_name,
		meeting_participant_representative_name: participant.representative_name,
		meeting_form_title: $transmitter.meeting.form == 'facetoface' ? '' : 'в форме заочного голосования',
		meeting_form_subtitle:
			$transmitter.procedure.bankrupt_type === 'Person' ? 'сведения, идентифицирующие должника' : 'наименование и местонахождение должника',
		meeting_participant_is_person: participant.is_person,
		meeting_form_when: $transmitter.meeting.form == 'facetoface' ? '(дата проведения)' : '(дата окончания приёма бюллетеней)',
		meeting_form_where: $transmitter.meeting.form == 'facetoface' ? '(место проведения)' : '(адрес для направления бюллетеней)',
		bankrupt_info: undefined,
		bankrupt_full_name: undefined,
		bankrupt_short_name: undefined,
		bankrupt_legal_address: undefined,
	};
};
