<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { transmitter } from 'base_stores';
	import { currentData } from '~/svelte/dcts/stores/stores';
	import { toArray } from 'utils/tools';
	import InputMoney from '~/svelte/components/ui/input_money.svelte';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	const handleChange = e =>
		dispatch('change', {
			model: 'meeting_participant',
			id: $currentData.meetingParticipantId,
			attribute: 'total_voting_sum',
			value: e.target.value,
		});

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingParticipantTotalVotingSum') && $currentData.meetingForm == 'absentee';
		disabled =
			toArray($transmitter.meeting.disabled_fields).includes('meetingParticipantTotalVotingSum') ||
			$transmitter.meeting.bulletin_status == 'approved' ||
			$transmitter.meeting.status == 'canceled';
	}
</script>

{#if visible}
	<Field
		value={$currentData.meetingParticipantTotalVotingSum}
		name="meetingParticipantTotalVotingSum"
		label="Общий размер требований кредитора, руб."
		{disabled}
	>
		<InputMoney
			name="meetingParticipantTotalVotingSum"
			value={$currentData.meetingParticipantTotalVotingSum}
			onchange={handleChange}
			{disabled}
			klass="form-control text-right"
		/>
	</Field>
{/if}
