<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { transmitter } from '~/js/global_stores/base';
	import { currentData } from '../../stores/stores';
	import { toArray } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	const handleChange = e =>
		dispatch('change', {
			model: 'meeting_participant',
			id: $currentData.meetingParticipantId,
			attribute: 'representative_name',
			value: e.target.value,
		});

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible =
			!toArray($transmitter.meeting.hidden_fields).includes('meetingParticipantRepresentativeName') && $currentData.meetingParticipantKind == 'representative';
		disabled =
			toArray($transmitter.meeting.disabled_fields).includes('meetingParticipantRepresentativeName') ||
			$transmitter.meeting.bulletin_status == 'approved' ||
			$transmitter.meeting.status == 'canceled';
	}
</script>

{#if visible}
	<Field value={$currentData.meetingParticipantRepresentativeName} name="meetingParticipantRepresentativeName" label="Представитель участника" {disabled}>
		<input
			type="text"
			name="meetingParticipantRepresentativeName"
			value={$currentData.meetingParticipantRepresentativeName}
			on:change={handleChange}
			{disabled}
			class="form-control"
		/>
	</Field>
{/if}
