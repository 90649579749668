<!-- @format -->
<script>
	import { currentData } from '~/svelte/dcts/stores/stores';
	import { transmitter } from '~/js/global_stores/base';
	import { toArray } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	// readonly
	const handleChange = e => {};
	// const handleChange = e => dispatch('change', {
	//       model: 'meeting_participant',
	//          id: $currentData.meetingParticipantId,
	//   attribute: 'inn',
	//       value: e.target.value
	// });
	let options = [];
	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		options = toArray($transmitter.meeting.reasons);
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingReason');
		disabled = toArray($transmitter.meeting.disabled_fields).includes('meetingReason');
	}
</script>

{#if visible}
	<Field value={$currentData.meetingReason} name="meetingReason" label="Основание проведения собрания" {disabled}>
		{#each options as option}
			<label class="d-flex">
				<input
					type="radio"
					name="meetingReason"
					bind:group={$currentData.meetingReason}
					value={option.value}
					{disabled}
					on:change={handleChange}
					class="align-self-center m-r-xs"
				/>
				{option.label}
			</label>
		{/each}
	</Field>
{/if}
