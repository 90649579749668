/** @format */

export const currencyFormat = (number, precision = 2) => {
	number = Math.round(parseFloat(number) * 10 ** precision) / 10 ** precision;
	number = parseFloat(number).toFixed(precision).replace(/\./, ',');
	number = String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

	return number;
};

export const percentFormat = (number, precision) => {
	return `${currencyFormat(number, precision)}%`;
};

export const cardsTitle = (text, id, addition = null, withoutAddition = false) => {
	return `Карточка ${text}${withoutAddition ? '' : ` (${addition || (id ? 'изменение' : 'новая')})`}`;
};

export const stripHtml = html => {
	let tmp = document.createElement('DIV');
	tmp.innerHTML = html;

	return processNode(tmp);
};

const processList = node => {
	let items = node.querySelectorAll('li');
	let result = '';
	for (let i = 0; i < items.length; i++) {
		let prefix = '';
		if (i === 0) {
			prefix = '   ';
		} else {
			prefix = ' ';
		}
		result += prefix + items[i].textContent.trim() + '\n';
	}
	return result;
};

const processNode = node => {
	if (node.nodeType === Node.TEXT_NODE) {
		return node.textContent.trim();
	} else if (node.nodeType === Node.ELEMENT_NODE) {
		let tagName = node.tagName.toLowerCase();
		if (tagName === 'ol' || tagName === 'ul') {
			return processList(node);
		} else {
			let result = '';
			for (let i = 0; i < node.childNodes.length; i++) {
				result += processNode(node.childNodes[i]);
			}
			return result;
		}
	} else {
		return '';
	}
};
