<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: creditorsNotificationDateAboutProcedureIntroduction = {
		...$commonParams,
		attribute: 'creditors_notification_date_about_procedure_introduction',
		label: 'Дата уведомления кредиторов о введении процедуры',
		value: $currentData && $currentData.creditorsNotificationDateAboutProcedureIntroduction,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
	};
</script>

<AutosaveDatepicker {...creditorsNotificationDateAboutProcedureIntroduction} />
