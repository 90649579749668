<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { currentData } from '../../stores/stores';
	import { transmitter } from '~/js/global_stores/base';
	import { toArray, formatSum } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	const handleChange = e => {};
	// const handleChange = e => dispatch('change', {
	//       model: 'meeting_participant',
	//          id: $currentData.meetingParticipantId,
	//   attribute: $currentData.meetingQuestionSecuredVotes == 'accounted' ? 'voting_sum' : 'unsecured_voting_sum',
	//       value: e.target.value
	// })

	let value = 0;
	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingParticipantVotingProc');
		disabled = toArray($transmitter.meeting.disabled_fields).includes('meetingParticipantVotingProc');
		value = $transmitter.meeting.kind == 'creditor' ? formatSum($currentData.meetingParticipantVotingProc) + '%' : $currentData.meetingParticipantVotingProc;
	}
</script>

{#if visible}
	<Field
		value={$currentData.meetingParticipantVotingProc}
		name="meetingParticipantVotingProc"
		label="Количество голосов зарегистрированных участников"
		{disabled}
	>
		<input
			type="text"
			name="meetingParticipantVotingProc"
			{value}
			{disabled}
			on:change={handleChange}
			placeholder=""
			class="form-control text-right"
			autocomplete="off"
		/>
	</Field>
{/if}
