<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: bankruptOgrnReorg = {
		...$commonParams,
		hidden: !$currentData.insertFirstParagraph,
		attribute: 'bankrupt_ogrn_reorg',
		label: 'ОГРН реорганизованного ЮЛ',
		value: $currentData && $currentData.bankruptOgrnReorg,
		placeholder: '1234567890123',
		maskOptions: { mask: '0000000000000' },
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_ogrn_reorg',
			resource: $procedure && $procedure.bankrupt.reorg,
			resourceProp: 'ogrn',
		},
	};
</script>

{#if $currentData.bankruptWasReorg}
	<AutosaveInput {...bankruptOgrnReorg} on:update />
{/if}
