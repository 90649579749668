<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { subscription, hiddenPaymentModal, bankruptIsOrganization, bankruptIsPerson, promoCode, paymentDetails } from './stores';
	import { isPresent } from 'utils/tools';
	import Modal from '../modal.svelte';
	import PaymentWidget from './widget.svelte';
	import PersonOffers from './person_offers.svelte';
	import OrganizationOffers from './organization_offers.svelte';
	import PromoCode from './promo_code.svelte';

	let abbr = 'ЮЛ';
	let title;

	const handleCancel = async () => {
		$hiddenPaymentModal = true;
		isPresent($subscription.welcome_promo_code) ? await promoCode.apply() : await paymentDetails.load();
	};

	onMount(() => {
		if ($subscription?.procedure_phase == 'supervision') {
			abbr = 'ЮЛ (Н)';
		} else if ($subscription?.procedure_phase == 'bankruptcy_proceedings') {
			abbr = 'ЮЛ (КП)';
		}

		title = location.pathname.match(/^\/settings$/)
			? `Активировать автоплатежи процедуры<br/>${$subscription?.procedure_name} ?`
			: `Покупка процедуры ${$bankruptIsOrganization ? abbr : 'ФЛ'}`;
	});
</script>

<Modal
	modalId="notice-payment-modal"
	cancelButtonAction={handleCancel}
	submitButtonText="Оплатить"
	submitButtonCss="btn-warning"
	submitButtonAction={() => {}}
	size="medium"
>
	<h2 slot="header">{title}</h2>
	<div slot="body">
		{#if location.pathname.match(/^\/procedure(\/\d+)?$/)}
			<h4><p>Процедура сохранена.</p></h4>
		{/if}
		{#if $bankruptIsPerson}
			<PersonOffers />
		{:else if $bankruptIsOrganization}
			<OrganizationOffers />
		{/if}
		{#if !Object.keys($promoCode).includes('hidden') || !$promoCode.hidden}
			<PromoCode />
		{/if}
	</div>
	<svelte:fragment slot="submit">
		{#if !$hiddenPaymentModal}
			<PaymentWidget on:cancel={handleCancel} />
		{/if}
	</svelte:fragment>
</Modal>
