/** @format */

import { derived, get } from 'svelte/store';
import { options, transmitter, procedure, lastFinancialStateDct } from 'base_stores';
import { dct, currentData, allPossessions, creditorsRequirements, bankAccounts } from './stores';
import { isPresent, isBlank, toArray } from '~/js/utils/tools';
import moment from 'moment';
import Case from 'case';

// триггеры
// либо получают значения непосредственно из данных предоставленных формой
// либо значения вычисляются на основании этих данных
export const triggers = derived([allPossessions, currentData, procedure, bankAccounts], ([$allPossessions, $currentData, $procedure, $bankAccounts]) => {
	return {
		creditorsFirstMeetingExist: $currentData.creditorsFirstMeetingExist,
		creditorsFirstMeetingHeld: $currentData.creditorsFirstMeetingHeld,
		// Кто подал заявление о банкротстве
		declarer: $currentData.procedureDeclarer ? 'creditor' : 'bankrupt',
		// Этап банкротства
		procedurePhase: $procedure?.phase,
		outgoingRequestCorrespondentKind: $currentData.outgoingRequestCorrespondentKind,
		// Должник имеет постоянный источник дохода
		bankruptHasPermanentIncomeSource: !!$currentData.bankruptHasConstantSourceOfIncome,
		bankruptIsIndividualEntrepreneur: $currentData.bankruptIsIndividualEntrepreneur,
		// Есть судебный акт о признании единственного жилья должника
		hasJudicialActOnRecognitionOnlyHomeBankrupt: !!$currentData.hasJudicialActOnRecognitionOnlyHomeBankrupt,
		// Предвидится доп. источник дохода должника
		willThereBeAdditionalIncomeSource: !!$currentData.incomeAdditionalSourceOfBankrupt,
		// Должник привлекался к ответственности за неправомерные действия при банкротстве, преднамеренное/фиктивное банкротство
		bankruptWasProsecutedForIllegalActionsInBankruptcy: !!$currentData.receivableWasHeldLiableForIllegalActionsInBankruptcy,
		// Должник предоставил документы АУ
		bankruptSubmittedDocumentsToArbitrManager: !!$currentData.receivableSubmittedDocumentsToArbitrManager,
		// Выявлены факты незаконного/недобросовестного поведения должника
		hasFactsOfIllegalBehaviorOfBankrupt: !!$currentData.hasFactsOfIllegalUnfairBehaviorOfDebtor,
		// Есть признаки фиктивного/преднамеренного банкротства
		hasFictitiousWillfulBankruptcySigns: isPresent($currentData.hasFictitiousWillfulBankruptcySigns),
		// Есть признаки фиктивного банкротства
		hasFictitiousBankruptcySigns: isPresent($currentData.hasFictitiousBankruptcySigns),
		// Есть признаки преднамеренного банкротства
		hasWillfulBankruptcySigns: isPresent($currentData.hasWillfulBankruptcySigns),
		// У должника есть дополнительные расходы
		bankruptHasAdditionalExpenses: !!$currentData.bankruptHasAdditionalExpenses,
		// АУ поступал план реструктуризации
		arbitrManagerReceivedRestructuringPlan: !!$currentData.arbitrManagerReceivedRestructuringPlan,
		// Выявлены сделки, подлежащие оспариванию
		identifiedTransactionsSubjectToDispute: !!$currentData.identifiedTransactionsSubjectToDispute,
		// Банки предоставили выписки по счетам
		banksProvidedAccountStatements: !!$currentData.banksProvidedAccountStatements,
		// В блоке “Имущество (активы)” есть объекты (не банковские счета) или баланс на банковских счетах более нуля
		allPossessionsNumber: [
			$allPossessions['bankAccounts'].reduce((sum, current) => sum + Number(current.balance), 0) > 0 || null,
			...$allPossessions['receivables'].filter(receivable => !receivable.excluded_from_bankruptcy),
			...$allPossessions['other'].filter(possession => !possession.excluded_from_bankruptcy && !possession.onlyResidence),
		].filter(i => i).length,
		// свободные средства должника
		freeFundsOfBankrupt: freeFundsOfBankrupt(),
		debtRepaymentPeriodYears: debtRepaymentPeriodYears(),
		// за период были сделки с активами должника
		hasTransactionsWithAssetsOfBankrupt: !!$currentData.hasTransactionsWithAssetsOfBankrupt,
		insertFirstParagraph: !!$currentData.insertFirstParagraph,
		otherBankAccountsNumber: $bankAccounts.otherBankAccounts.length,
		requestObjects: $currentData.requestObjects,
		bankruptLeaderRequestReceived: $currentData.bankruptLeaderRequestReceived,
		transferringDocumentsParty: $currentData.transferringDocumentsParty,
		votingMeetingStatus: $currentData.votingMeetingStatus,
		hasBeenDepositedArbitrManagerRemunerationIntoCourtDeposit: $currentData.hasBeenDepositedArbitrManagerRemunerationIntoCourtDeposit,
		hasBeenExpensesFundsDepositedIntoCourtDeposit: $currentData.hasBeenExpensesFundsDepositedIntoCourtDeposit,
		coveringCourtCosts: coveringCourtCosts(),
		transferSalaryToMainAccount: $currentData.transferSalaryToMainAccount,
		numberOfMinorChildrenOfBankruptForAnalysis: $currentData.numberOfMinorChildrenOfBankruptForAnalysis,
	};
});

const assetsTotalAmount = () => {
	const assets = get(allPossessions);
	return [
		...assets.bankAccounts,
		...assets.receivables,
		...[].concat(...Object.keys(assets.other).map(key => assets.other[key])).filter(procession => !procession.onlyResidence),
	].reduce((totalAmount, asset) => totalAmount + parseFloat(asset.balance_rub || asset.debt_amount || asset.cost || 0), 0);
};

const propertyStructureTotalAmount = () => {
	const assets = get(allPossessions);
	return [...assets.bankAccounts, ...[].concat(...Object.keys(assets.other).map(key => assets.other[key]))].reduce(
		(totalAmount, asset) => totalAmount + parseFloat(asset.balance_rub || asset.debt_amount || asset.cost || 0),
		0,
	);
};

const externalFactorTotalAmount = () => {
	const data = get(currentData);
	return (
		parseInt(data.externalFactorEconomicSituationInRegion || 0) +
		parseInt(data.externalFactorCostOfGroceryBasket || 0) +
		parseInt(data.externalFactorAverageIncomeOfPopulation || 0) +
		parseInt(data.externalFactorPriceGrowthRate || 0) +
		parseInt(data.externalFactorNumberOfUnemployed || 0) +
		parseInt(data.externalFactorCompanyProfits || 0)
	);
};

const internalFactorTotalAmount = () => {
	const data = get(currentData);
	return parseInt(data.internalFactorIndebtedness || 0) + parseInt(data.internalFactorIncomeLevel || 0) + parseInt(data.internalFactorNumberOfLoans || 0);
};

const incomeSourcesTotalAmount = () => {
	const data = get(transmitter);
	return (data.incomeSources || []).reduce((totalAmount, source) => totalAmount + parseFloat(source.amount || 0), 0);
};

const monthlyExpensesOfDebtorTotalAmount = () => {
	const data = get(currentData);
	return (
		parseFloat(data.monthlyExpensesOfDebtorOther || 0) +
		parseFloat(data.monthlyExpensesOfDebtorAlimony || 0) +
		parseFloat(data.monthlyExpensesOfDebtorTherapy || 0)
	);
};

const freeFundsOfBankrupt = () => {
	const data = get(currentData);
	return (
		incomeSourcesTotalAmount() -
		(data.livingWageForBankrupt || 0) -
		(data.livingWageForChildren || 0) * (data.numberOfMinorChildrenOfBankruptForAnalysis || 0) -
		monthlyExpensesOfDebtorTotalAmount()
	);
};

const responsibilitiesTotalAmount = () => {
	const requirements = get(creditorsRequirements).allBesidesExcluded || [];
	const debts = get(transmitter).undeclaredDebts || [];
	return [...requirements, ...debts].reduce((totalAmount, responsibility) => totalAmount + parseFloat(responsibility.amount || 0), 0);
};

const debtRepaymentPeriodMonths = () => {
	let freeFunds = freeFundsOfBankrupt();
	freeFunds = freeFunds && freeFunds !== 0 ? freeFunds : 1;
	return Math.round(responsibilitiesTotalAmount() / freeFunds);
};

const coveringCourtCosts = readyData => {
	const data = isPresent(readyData) ? Object.fromEntries(Object.entries(readyData).map(([key, value]) => [Case.camel(key), value])) : get(currentData);
	let freeFunds = isPresent(readyData) ? parseFloat(data.free_funds_of_bankrupt || 0) : freeFundsOfBankrupt();
	if (freeFunds < 0) {
		freeFunds = 0;
	}

	let covering = freeFunds - 25000 - data.estimatedCourtCost;

	if (data.hasBeenDepositedArbitrManagerRemunerationIntoCourtDeposit && data.hasBeenExpensesFundsDepositedIntoCourtDeposit) {
		return data.fundsOnCourtDepositCost + data.estimatedCourtCost + covering;
	} else if (!data.hasBeenDepositedArbitrManagerRemunerationIntoCourtDeposit && data.hasBeenExpensesFundsDepositedIntoCourtDeposit) {
		return data.estimatedCourtCost + covering;
	} else if (data.hasBeenDepositedArbitrManagerRemunerationIntoCourtDeposit && !data.hasBeenExpensesFundsDepositedIntoCourtDeposit) {
		return data.fundsOnCourtDepositCost + covering;
	} else {
		return covering;
	}
};

const debtRepaymentPeriodYears = () => {
	return Math.round(debtRepaymentPeriodMonths() / 12);
};

const totalBalance = () => {
	const bankAccounts = get(transmitter).bank_accounts;
	return toArray(bankAccounts)
		.map(ba => +ba.balance_rub)
		.reduce((a, b) => a + b, 0);
};

const totalCurrentBalance = () => {
	const bankAccounts = get(transmitter).bank_accounts;
	return toArray(bankAccounts)
		.map(ba => +ba.current_balance_rub)
		.reduce((a, b) => a + b, 0);
};

const compositeText = () => {
	const procedureData = get(procedure);
	const data = get(currentData);
	const dctData = get(dct);

	const procedurePhase = procedureData.phase;
	const judicialActDate = data.judicialActDate ? moment(data.judicialActDate).format('DD.MM.YYYY') : '';
	const bankruptBirthDate = data.bankruptBirthDate ? moment(data.bankruptBirthDate).format('DD.MM.YYYY') : '';
	const hearingDateAndTime = data.hearingDateAndTime ? moment(data.hearingDateAndTime).format('DD.MM.YYYY HH:mm') : '';
	const correspondentKind = dctData?.correspondent_kind;
	const bankruptFullNameChanged = isPresent(data.bankruptFullNamePrevious);
	let text = '';

	if (correspondentKind === 'newspaper') {
		if (procedurePhase === 'debt_restructuring') {
			text = `Определением суда (${data.courtFullName}) ${judicialActDate} г.${
				data.resolutionIntroductionDate ? ` (резолютивная часть объявлена ${moment(data.resolutionIntroductionDate).format('DD.MM.YYYY')} г.)` : ''
			} по делу № ${data.caseNumber || ''} в отношении гражданина(ки) (${data.bankruptFullName} ${
				bankruptFullNameChanged ? `(предыдущие ФИО - ${data.bankruptFullNamePrevious})` : ''
			}, дата рождения: ${bankruptBirthDate}, место рождения: ${data.bankruptBirthPlace || ''}, СНИЛС: ${data.bankruptSnils || ''}, ИНН: ${
				data.bankruptInn || ''
			}, регистрация по месту жительства: ${
				data.bankruptRegAddress || ''
			}) (далее по тексту - должник) введена процедура реструктуризации долгов гражданина. Финансовым управляющим ${moment(data.appointmentDate).format(
				'DD.MM.YYYY',
			)} утвержден(а) ${data.arbitrManagerFullName || ''} (ИНН: ${data.arbitrManagerInn || ''}, СНИЛС: ${data.arbitrManagerSnils || ''}) - член СРО ${
				data.sroFullName || ''
			} (ОГРН: ${data.sroOgrn || ''}, ИНН: ${data.sroInn || ''}, адрес: ${
				data.sroMailingAddress || ''
			}). Для целей включения в реестр требований кредиторов и участия в первом собрании кредиторов требования принимаются в течение двух месяцев
        с даты публикации настоящего объявления. Адрес для заявления кредиторами требований и направления корреспонденции финансовому управляющему: ${
					data.arbitrManagerMailingAddress || ''
				}. Судебное заседание по рассмотрению дела назначено на ${
					hearingDateAndTime
				} по адресу: ${data.courtMailingAddress || ''}, зал № ${data.courtroom || ''}.`;
		} else if (procedurePhase === 'property_realization') {
			text = `Решением суда (${data.courtFullName}) ${judicialActDate} г.${
				data.resolutionIntroductionDate ? ` (резолютивная часть объявлена ${moment(data.resolutionIntroductionDate).format('DD.MM.YYYY')} г.)` : ''
			} по делу № ${data.caseNumber || ''} в отношении гражданина(ки) (${data.bankruptFullName} ${
				bankruptFullNameChanged ? `(предыдущие ФИО - ${data.bankruptFullNamePrevious})` : ''
			}, дата рождения: ${bankruptBirthDate}, место рождения: ${data.bankruptBirthPlace || ''}, СНИЛС: ${data.bankruptSnils || ''}, ИНН: ${
				data.bankruptInn || ''
			}, регистрация по месту жительства: ${
				data.bankruptRegAddress || ''
			}) (далее по тексту - должник) введена процедура реализации имущества гражданина. Финансовым управляющим ${moment(data.appointmentDate).format(
				'DD.MM.YYYY',
			)} утвержден(а) ${data.arbitrManagerFullName || ''} (ИНН: ${data.arbitrManagerInn || ''}, СНИЛС: ${data.arbitrManagerSnils || ''}), член СРО ${
				data.sroFullName || ''
			} (ОГРН: ${data.sroOgrn || ''}, ИНН: ${data.sroInn || ''}, адрес: ${
				data.sroMailingAddress || ''
			}). Реестр требований кредиторов подлежит закрытию по истечении 2 месяцев с даты публикации настоящего объявления.
        Почтовый адрес финансового управляющего: ${data.arbitrManagerMailingAddress || ''}. Судебное заседание по рассмотрению дела назначено на ${
					hearingDateAndTime
				} по адресу: ${data.courtMailingAddress || ''}, зал № ${data.courtroom || ''}.`;
		} else if (procedurePhase === 'supervision') {
			text = `Определением суда (${data.courtFullName}) от ${judicialActDate} г.${
				data.resolutionIntroductionDate ? ` (резолютивная часть объявлена ${moment(data.resolutionIntroductionDate).format('DD.MM.YYYY')} г.)` : ''
			} по делу № ${data.caseNumber || ''} в отношении ${data.bankruptShortName} (ОГРН: ${data.bankruptOgrn || ''}, ИНН: ${data.bankruptInn || ''}, ${
				data.bankruptLegalAddress || ''
			}) введена процедура наблюдения. Временным управляющим утвержден(а) ${
				data.arbitrManagerFullName || ''
			} (ИНН: ${data.arbitrManagerInn || ''}, СНИЛС: ${data.arbitrManagerSnils || ''}), член СРО ${
				data.sroFullName || ''
			} (ОГРН: ${data.sroOgrn || ''}, ИНН: ${data.sroInn || ''}, адрес: ${
				data.sroMailingAddress || ''
			}). Для целей включения в реестр требований кредиторов и участия в первом собрании кредиторов требования принимаются в течение 30 календарных дней
        с даты публикации настоящего объявления. Адрес для заявления кредиторами требований и направления корреспонденции временному управляющему: ${
					data.arbitrManagerMailingAddress || ''
				}. Судебное заседание по рассмотрению дела о банкротстве назначено на ${
					hearingDateAndTime
				} по адресу: ${data.courtMailingAddress || ''}, зал № ${data.courtroom || ''}.`;
		} else if (procedurePhase === 'bankruptcy_proceedings') {
			text = `Решением суда (${data.courtFullName}) от ${judicialActDate} г.${
				data.resolutionIntroductionDate ? ` (резолютивная часть объявлена ${moment(data.resolutionIntroductionDate).format('DD.MM.YYYY')} г.)` : ''
			} по делу № ${data.caseNumber || ''} ${data.bankruptShortName} (ОГРН: ${data.bankruptOgrn || ''}, ИНН: ${data.bankruptInn || ''}, ${
				data.bankruptLegalAddress || ''
			}) признано несостоятельным (банкротом), открыто конкурсное производство. Конкурсным управляющим утвержден ${
				data.arbitrManagerFullName || ''
			} (ИНН: ${data.arbitrManagerInn || ''}, СНИЛС: ${data.arbitrManagerSnils || ''}), член СРО ${
				data.sroFullName || ''
			} (ОГРН: ${data.sroOgrn || ''}, ИНН: ${data.sroInn || ''}, адрес: ${
				data.sroMailingAddress || ''
			}). Реестр требований кредиторов подлежит закрытию по истечении 2 месяцев с даты публикации настоящего объявления.
        Адрес для заявления кредиторами требований и направления корреспонденции конкурсному управляющему: ${
					data.arbitrManagerMailingAddress || ''
				}. Судебное заседание по рассмотрению дела о банкротстве назначено на ${
					hearingDateAndTime
				} по адресу: ${data.courtMailingAddress || ''}, зал № ${data.courtroom || ''}.`;
		}
	} else if (correspondentKind === 'fedresource') {
		if (dctData.category === 'meetings' && dctData.kind === 'result_message') {
			if (procedureData.bankrupt_type === 'Person') {
				text = `${
					procedurePhase === 'debt_restructuring' ? 'Определением' : procedurePhase === 'property_realization' ? 'Решением' : ''
				} суда (${data.courtFullName}) ${judicialActDate} г.${
					data.resolutionIntroductionDate ? ` (резолютивная часть объявлена ${moment(data.resolutionIntroductionDate).format('DD.MM.YYYY')} г.)` : ''
				} по делу № ${data.caseNumber || ''} в отношении гражданина(ки) (ФИО: ${data.bankruptFullName},${
					bankruptFullNameChanged ? ` предыдущие ФИО: ${data.bankruptFullNamePrevious},` : ''
				} дата рождения: ${bankruptBirthDate}, место рождения: ${data.bankruptBirthPlace || ''}, СНИЛС: ${data.bankruptSnils || ''}, ИНН: ${
					data.bankruptInn || ''
				}, регистрация по месту жительства: ${data.bankruptRegAddress || ''}) (далее по тексту - должник) введена процедура ${
					procedurePhase === 'debt_restructuring' ? 'реструктуризации долгов' : procedurePhase === 'property_realization' ? 'реализации имущества' : ''
				} гражданина. Финансовым управляющим ${moment(data.appointmentDate).format('DD.MM.YYYY')} утвержден(а) ${
					data.arbitrManagerFullName || ''
				} (ИНН: ${data.arbitrManagerInn || ''}, СНИЛС: ${data.arbitrManagerSnils || ''}) - член СРО ${
					data.sroFullName || ''
				} (ОГРН: ${data.sroOgrn || ''}, ИНН: ${data.sroInn || ''}, адрес: ${data.sroMailingAddress || ''}). Настоящим финансовый управляющий сообщает, что ${
					data.meetingVotingStatus === 'held'
						? `собранием кредиторов должника (${data.bankruptFullName}), проведенном ${moment(data.meetingDateAt).format('DD.MM.YYYY')} г., приняты следующие решения: ${toArray(
								data.meetingSolutions,
							)
								.sort((a, b) => a.num > b.num)
								.map(s => `${s.num}. ${s.text}`)
								.join('. ')}.`
						: `собрание кредиторов должника (${data.bankruptFullName}), назначенное на ${data.meetingDateAt} г., признано несостоявшимся из-за отсутствия кворума.`
				}`;
			} else if (procedureData.bankrupt_type === 'Organization') {
				text = `${
					procedurePhase === 'supervision' ? 'Определением' : procedurePhase === 'bankruptcy_proceedings' ? 'Решением' : ''
				} суда (${data.courtFullName}) ${judicialActDate} г.${
					data.resolutionIntroductionDate ? ` (резолютивная часть объявлена ${moment(data.resolutionIntroductionDate).format('DD.MM.YYYY')} г.)` : ''
				} по делу № ${data.caseNumber || ''}${procedurePhase === 'supervision' ? ' в отношении' : ''} ${data.bankruptShortName}, ИНН ${
					data.bankruptInn || ''
				}, ОГРН: ${data.bankruptOgrn || ''}, юридический адрес: ${data.bankruptLegalAddress || ''} (далее по тексту - должник) ${
					procedurePhase === 'supervision'
						? 'введена процедура наблюдения'
						: procedurePhase === 'bankruptcy_proceedings'
							? 'признано несостоятельным (банкротом), в отношении него открыто конкурсное производство'
							: ''
				}. ${
					procedurePhase === 'supervision' ? 'Временным' : procedurePhase === 'bankruptcy_proceedings' ? 'Конкурсным' : ''
				} управляющим ${moment(data.appointmentDate).format('DD.MM.YYYY')} утвержден(а) ${data.arbitrManagerFullName || ''} (ИНН: ${
					data.arbitrManagerInn || ''
				}, СНИЛС: ${data.arbitrManagerSnils || ''}) - член СРО ${data.sroFullName || ''} (ОГРН: ${data.sroOgrn || ''}, ИНН: ${data.sroInn || ''}, адрес: ${
					data.sroMailingAddress || ''
				}). Настоящим ${
					procedurePhase === 'supervision' ? 'временный' : procedurePhase === 'bankruptcy_proceedings' ? 'конкурсный' : ''
				} управляющий сообщает, что ${
					data.meetingVotingStatus === 'held'
						? `собранием кредиторов ${data.bankruptShortName}, проведенном ${moment(data.meetingDateAt).format('DD.MM.YYYY')} г., приняты следующие решения: ${toArray(
								data.meetingSolutions,
							)
								.sort((a, b) => a.num > b.num)
								.map(s => `${s.num}. ${s.text}`)
								.join('. ')}`
						: `собрание кредиторов ${data.bankruptShortName}, назначенное на ${data.meetingDateAt} г., признано несостоявшимся из-за отсутствия кворума.`
				}`;
			}
		} else {
			if (procedurePhase === 'debt_restructuring') {
				text = `Определением суда (${data.courtFullName}) ${judicialActDate} г.${
					data.resolutionIntroductionDate ? ` (резолютивная часть объявлена ${moment(data.resolutionIntroductionDate).format('DD.MM.YYYY')} г.)` : ''
				} по делу № ${data.caseNumber || ''} в отношении гражданина(ки) (ФИО: ${data.bankruptFullName},${
					bankruptFullNameChanged ? ` предыдущие ФИО: ${data.bankruptFullNamePrevious},` : ''
				} дата рождения: ${bankruptBirthDate}, место рождения: ${data.bankruptBirthPlace || ''}, СНИЛС: ${data.bankruptSnils || ''}, ИНН: ${
					data.bankruptInn || ''
				}, регистрация по месту жительства: ${
					data.bankruptRegAddress || ''
				}) (далее по тексту - должник) введена процедура реструктуризации долгов гражданина. Финансовым управляющим ${moment(data.appointmentDate).format(
					'DD.MM.YYYY',
				)} утвержден(а) ${data.arbitrManagerFullName || ''} (ИНН: ${data.arbitrManagerInn || ''}, СНИЛС: ${data.arbitrManagerSnils || ''}) - член СРО ${
					data.sroFullName || ''
				} (ОГРН: ${data.sroOgrn || ''}, ИНН: ${data.sroInn || ''}, адрес: ${
					data.sroMailingAddress || ''
				}). Для целей включения в реестр требований кредиторов и участия в первом собрании кредиторов требования принимаются в течение двух месяцев
          с даты публикации объявления в газете «Коммерсантъ». Адрес для заявления кредиторами требований и направления корреспонденции финансовому управляющему: ${
						data.arbitrManagerMailingAddress || ''
					}. Судебное заседание по рассмотрению дела назначено на ${
						hearingDateAndTime
					} по адресу: ${data.courtMailingAddress || ''}, зал ${data.courtroom || ''}.`;
			} else if (procedurePhase === 'property_realization') {
				text = `Решением суда (${data.courtFullName}) ${judicialActDate} г.${
					data.resolutionIntroductionDate ? ` (резолютивная часть объявлена ${moment(data.resolutionIntroductionDate).format('DD.MM.YYYY')} г.)` : ''
				} по делу № ${data.caseNumber || ''} в отношении гражданина(ки) (ФИО: ${data.bankruptFullName},${
					bankruptFullNameChanged ? ` предыдущие ФИО: ${data.bankruptFullNamePrevious},` : ''
				} дата рождения: ${bankruptBirthDate}, место рождения: ${data.bankruptBirthPlace || ''}, СНИЛС: ${data.bankruptSnils || ''}, ИНН: ${
					data.bankruptInn || ''
				}, регистрация по месту жительства: ${
					data.bankruptRegAddress || ''
				}) (далее по тексту - должник) введена процедура реализации имущества гражданина. Финансовым управляющим ${moment(data.appointmentDate).format(
					'DD.MM.YYYY',
				)} утвержден(а) ${data.arbitrManagerFullName || ''} (ИНН: ${data.arbitrManagerInn || ''}, СНИЛС: ${data.arbitrManagerSnils || ''}) - член СРО ${
					data.sroFullName || ''
				} (ОГРН: ${data.sroOgrn || ''}, ИНН: ${data.sroInn || ''}, адрес: ${
					data.sroMailingAddress || ''
				}). Реестр требований кредиторов подлежит закрытию по истечении двух месяцев с даты публикации объявления в газете «Коммерсантъ».
          Адрес для заявления кредиторами требований и направления корреспонденции финансовому управляющему: ${
						data.arbitrManagerMailingAddress || ''
					}. Судебное заседание по рассмотрению дела назначено на ${
						hearingDateAndTime
					} по адресу: ${data.courtMailingAddress || ''}, зал ${data.courtroom || ''}.`;
			} else if (procedurePhase === 'supervision') {
				text = `Определением суда (${data.courtFullName}) ${judicialActDate} г.${
					data.resolutionIntroductionDate ? ` (резолютивная часть объявлена ${moment(data.resolutionIntroductionDate).format('DD.MM.YYYY')} г.)` : ''
				} по делу № ${data.caseNumber || ''} в отношении ${data.bankruptShortName}, ИНН: ${
					data.bankruptInn || ''
				}, ОГРН: ${data.bankruptOgrn || ''}, юридический адрес: ${
					data.bankruptLegalAddress || ''
				} (далее по тексту - должник) введена процедура наблюдения. Временным управляющим утвержден(а) ${
					data.arbitrManagerFullName || ''
				} (ИНН: ${data.arbitrManagerInn || ''}, СНИЛС: ${data.arbitrManagerSnils || ''}) - член СРО ${
					data.sroFullName || ''
				} (ОГРН: ${data.sroOgrn || ''}, ИНН: ${data.sroInn || ''}, адрес: ${
					data.sroMailingAddress || ''
				}). Для целей включения в реестр требований кредиторов и участия в первом собрании кредиторов требования принимаются в течение 30 (тридцати)
          календарных дней с даты публикации объявления в газете «Коммерсантъ».
          Адрес для заявления кредиторами требований и направления корреспонденции временному управляющему: ${
						data.arbitrManagerMailingAddress || ''
					}. Судебное заседание по рассмотрению дела о банкротстве назначено на ${
						hearingDateAndTime
					} по адресу: ${data.courtMailingAddress || ''}, зал ${data.courtroom || ''}.`;
			} else if (procedurePhase === 'bankruptcy_proceedings') {
				text = `Решением суда (${data.courtFullName}) ${judicialActDate} г.${
					data.resolutionIntroductionDate ? ` (резолютивная часть объявлена ${moment(data.resolutionIntroductionDate).format('DD.MM.YYYY')} г.)` : ''
				} по делу № ${data.caseNumber || ''} в отношении ${data.bankruptShortName}, ИНН: ${
					data.bankruptInn || ''
				}, ОГРН: ${data.bankruptOgrn || ''}, юридический адрес: ${
					data.bankruptLegalAddress || ''
				} (далее по тексту - должник) введена процедура конкурсного производства. Конкурсным управляющим утвержден(а) ${
					data.arbitrManagerFullName || ''
				} (ИНН: ${data.arbitrManagerInn || ''}, СНИЛС: ${data.arbitrManagerSnils || ''}) - член СРО ${
					data.sroFullName || ''
				} (ОГРН: ${data.sroOgrn || ''}, ИНН: ${data.sroInn || ''}, адрес: ${
					data.sroMailingAddress || ''
				}). Реестр требований кредиторов подлежит закрытию по истечении двух месяцев с даты публикации объявления в газете «Коммерсантъ».
          Адрес для заявления кредиторами требований и направления корреспонденции конкурсному управляющему: ${
						data.arbitrManagerMailingAddress || ''
					}. Судебное заседание по рассмотрению дела о банкротстве назначено на ${
						hearingDateAndTime
					} по адресу: ${data.courtMailingAddress || ''}, зал ${data.courtroom || ''}.`;
			}
		}
	}

	return text;
};

const informationOnSufficiencyCoveringCourtCosts = lastFinancialStateDct => {
	return isPresent(lastFinancialStateDct)
		? `Имущества для покрытия судебных расходов и расходов на выплату вознаграждения финансовому управляющему ${coveringCourtCosts(lastFinancialStateDct.substitutions) < 0 ? 'не ' : ''}достаточно`
		: null;
};

const conclusionAboutWillfulBankruptcySigns = lastFinancialStateDct => {
	return !Object.keys(lastFinancialStateDct.substitutions || {}).includes('has_willful_bankruptcy_signs')
		? null //'-'
		: lastFinancialStateDct.substitutions?.has_willful_bankruptcy_signs
			? ['Выявлены признаки преднамеренного банкротства:', lastFinancialStateDct.substitutions.willful_bankruptcy_signs].join(' ')
			: 'Признаки преднамеренного банкротства не выявлены';
};

const conclusionAboutFictitiousBankruptcySigns = lastFinancialStateDct => {
	return !Object.keys(lastFinancialStateDct.substitutions || {}).includes('has_fictitious_bankruptcy_signs')
		? null //'-'
		: lastFinancialStateDct.substitutions?.has_fictitious_bankruptcy_signs
			? ['Выявлены признаки фиктивного банкротства:', lastFinancialStateDct.substitutions.fictitious_bankruptcy_signs].join(' ')
			: 'Признаки фиктивного банкротства не выявлены';
};

const mainBankAccount = () => {
	let bankAccounts = get(transmitter).bank_accounts;
	if (isBlank(bankAccounts)) {
		bankAccounts = get(options).bankAccount;
	}
	return toArray(bankAccounts).find(a => a.is_main);
};

// данные, вычисленные из данных полей и блоков форм
// TODO: различным видам документов необходимы различные наборы вычисляемых данных.
// надо организовать разграничение таких наборов, чтоб не считать лишнее каждый раз.
export const derivedData = derived([currentData, lastFinancialStateDct], ([$currentData, $lastFinancialStateDct]) => {
	return {
		assetsTotalAmount: assetsTotalAmount(),
		propertyStructureTotalAmount: propertyStructureTotalAmount(),
		responsibilitiesTotalAmount: responsibilitiesTotalAmount(),
		externalFactorTotalAmount: externalFactorTotalAmount(),
		internalFactorTotalAmount: internalFactorTotalAmount(),
		incomeSourcesTotalAmount: incomeSourcesTotalAmount(),
		monthlyExpensesOfDebtorTotalAmount: monthlyExpensesOfDebtorTotalAmount(),
		freeFundsOfBankrupt: freeFundsOfBankrupt(),
		debtRepaymentPeriodMonths: debtRepaymentPeriodMonths(),
		debtRepaymentPeriodYears: debtRepaymentPeriodYears(),
		compositeText: compositeText(),
		completionDateAfterRenewalOrCompletionDate: $currentData.completionDateAfterRenewal || $currentData.completionDate,
		totalBalance: totalBalance(),
		totalCurrentBalance: totalCurrentBalance(),
		informationOnSufficiencyCoveringCourtCosts: informationOnSufficiencyCoveringCourtCosts($lastFinancialStateDct),
		conclusionAboutWillfulBankruptcySigns: conclusionAboutWillfulBankruptcySigns($lastFinancialStateDct),
		conclusionAboutFictitiousBankruptcySigns: conclusionAboutFictitiousBankruptcySigns($lastFinancialStateDct),
		conclusionAboutFinancialAnalysisDate: $lastFinancialStateDct.substitutions?.date,
		mainBankAccountNumber: mainBankAccount()?.number,
		mainBankAccountBankBik: mainBankAccount()?.bank_bik,
		mainBankAccountBankName: mainBankAccount()?.counterparty_name,
		mainBankAccountBankCorAccount: mainBankAccount()?.bank_cor_account,
		mainBankAccountBankInn: mainBankAccount()?.bank_inn,
		mainBankAccountBankKpp: mainBankAccount()?.bank_kpp,
	};
});
