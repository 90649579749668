<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInput from '../../components/ui/autosave_input.svelte';

	$: numberNotifiedCreditors = {
		...$commonParams,
		attribute: 'number_notified_creditors',
		label: 'Количество уведомленных кредиторов',
		value: $currentData && $currentData.numberNotifiedCreditors,
		placeholder: '5',
	};
</script>

<AutosaveInput {...numberNotifiedCreditors} />
