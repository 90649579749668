<!-- @format -->
<script>
	import { options, bankruptType } from 'base_stores';
	import TableSubkindRows from './table_subkind_rows.svelte';

	export let activeKindId;
	export let mounted = true;

	const tableTitles = {
		Person: ['Наименование объекта имущества', 'Стоимость, руб.', 'Кто определил стоимость', 'Исключён из конкурсной массы', 'Является предметом залога'],
		Organization: [
			'Наименование объекта имущества',
			'Балансовая стоимость (руб.)',
			'Рыночная стоимость (руб.)',
			'Исключен из конкурсной массы',
			'Является предметом залога',
		],
	};

	$: subkindOptions = $options.possessionKind.filter(option => option.parent_id === activeKindId).sort((a, b) => a.sequence - b.sequence);

	$: actualTableTitles = tableTitles[$bankruptType] || [
		'Наименование объекта имущества',
		'Стоимость, руб.',
		'Исключён из конкурсной массы',
		'Является предметом залога',
	];
</script>

<table class="table table-mobile border-collapse_separate mb-0">
	<thead class="thead-light">
		<tr>
			{#each actualTableTitles as title}
				<th class="text-center p-w-xs">
					{title}
				</th>
			{/each}
		</tr>
	</thead>
	<tbody class="text-center">
		<TableSubkindRows possessionKinds={subkindOptions} {activeKindId} on:edit bind:mounted />
	</tbody>
</table>
