<!-- @format -->
<script>
	import AppEditAnswers from './answers/form.svelte';
	import AppAnswers from './answers/index.svelte';
	import AppEditArbitrManagers from './arbitr_managers/form.svelte';
	import AppBankAccounts from './bank_accounts/index.svelte';
	import AppCalendar from './calendar/index.svelte';
	import AppComplaints from './complaints/index.svelte';
	import AppCounterparties from './counterparties/index.svelte';
	import AppCreditorsRequirements from './creditors_requirements/index.svelte';
	import AppReceivables from './receivables/index.svelte';
	import AppDctsOutgoing from './dcts/indexes/outgoing.svelte';
	import AppDctsReports from './dcts/indexes/reports.svelte';
	import AppDctsFinancialAnalysis from './dcts/indexes/financial_analysis.svelte';
	import AppEditDcts from './dcts/form.svelte';
	import AppRunningCosts from './running_costs/index.svelte';
	import AppPayouts from './payouts/index.svelte';
	import AppSettings from './settings/index.svelte';
	import AppMeasures from './measures/index.svelte';
	import AppMeetings from './meetings/index.svelte';
	import AppPossessions from './possessions/index.svelte';
	import AppEditProcedures from './procedures/form.svelte';
	import AppProcedures from './procedures/index.svelte';
	import AppSpecialists from './specialists/index.svelte';
	import AppMailRegistries from './mail_registries/show.svelte';
	import AppCreditorEditMeeting from './meetings/creditor/index.svelte';
	import AppWorkerEditMeeting from './meetings/worker/index.svelte';
	import AppFeeTariffs from './admin/fee/tariffs/index.svelte';
	import AppFormTemplates from './templates/form.svelte';
	import AppTinyMCE from './wysiwyg_editors/tiny_mce.svelte';
	import AppWorkers from './workers/index.svelte';

	export let elementId;
	export let data;

	const apps = {
		answer: AppEditAnswers,
		answers: AppAnswers,
		arbitr_manager: AppEditArbitrManagers,
		bank_accounts: AppBankAccounts,
		calendar: AppCalendar,
		complaints: AppComplaints,
		counterparties: AppCounterparties,
		creditors_requirements: AppCreditorsRequirements,
		receivables: AppReceivables,
		documents_outgoing: AppDctsOutgoing,
		documents_reports: AppDctsReports,
		documents_financial_analysis: AppDctsFinancialAnalysis,
		edit_documents: AppEditDcts,
		running_costs: AppRunningCosts,
		payouts: AppPayouts,
		settings: AppSettings,
		measures: AppMeasures,
		meetings: AppMeetings,
		creditor_meeting_edit: AppCreditorEditMeeting,
		worker_meeting_edit: AppWorkerEditMeeting,
		possessions: AppPossessions,
		procedure: AppEditProcedures,
		procedures: AppProcedures,
		workers: AppWorkers,
		specialists: AppSpecialists,
		mail_registry: AppMailRegistries,
		fee_tariffs: AppFeeTariffs,
		template: AppFormTemplates,
		tiny_mce: AppTinyMCE,
	};
</script>

<svelte:component this={apps[elementId]} {...data} />
