<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import Select from 'svelte-select/src/Select.svelte';
	import Item from './item.svelte';
	import { isBlank, isPresent, toArray } from 'utils/tools';

	export let value;
	export let items;
	export let name;
	export let disabled = false;
	export let onchange;
	export let addPlaceList = false;
	export let getOptionLabel = (option, filterText) => (option.isCreator ? `\"${filterText}\" (создать)` : option.label);
	export const clear = () => ctrl_select.handleClear();

	let hash_value;
	let ctrl_select;
	let ctrl_input;
	let listOpen;

	const dispatch = createEventDispatcher();

	const optionIdentifier = $$props.optionIdentifier || 'value';
	const labelIdentifier = $$props.labelIdentifier || 'label';

	const dataAttributes = Object.fromEntries(Object.entries($$props).filter(e => e[0].startsWith('data-')));

	const onSelect = ({ detail: element }) => {
		if (isBlank(element) || element[optionIdentifier] == value) return;

		if ($$props.isMulti) {
			dispatch(
				'select',
				toArray(element).map(e => e[optionIdentifier]),
			);
		} else {
			if (element.action) {
				if (hash_value) {
					if (hash_value.action) {
						ctrl_select.handleClear();
					} else {
						hash_value = hash_value;
					}
				} else {
					ctrl_select.handleClear();
				}

				dispatch('action', element[optionIdentifier]);
			} else {
				emitChangeEvent(element[optionIdentifier]);
				dispatch('select', element);
			}
		}
	};

	const onClear = () => {
		emitChangeEvent(null);
		dispatch('select', $$props.isMulti ? [] : { [optionIdentifier]: null });
	};

	const emitChangeEvent = val => {
		if (ctrl_input) {
			ctrl_input.value = val;
			ctrl_input.dispatchEvent(new Event('change'));
		}
	};

	const find = (val, items) => {
		let result;

		if (isPresent(val)) {
			result = $$props.isMulti
				? toArray(val)
						.map(id => toArray(items).find(item => item[optionIdentifier] == id))
						.filter(e => e)
				: toArray(items).find(e => e[optionIdentifier] == val);
		}

		return result;
	};

	$: hash_value = find(value, items);
	$: indent_quantity = items.length > 5 ? 250 : items.length * 42;
	$: containerStyles = addPlaceList && listOpen ? `margin-bottom: ${indent_quantity}px` : '';
</script>

<Select
	bind:this={ctrl_select}
	showIndicator={true}
	isDisabled={disabled}
	{containerStyles}
	bind:listOpen
	{Item}
	{items}
	noOptionsMessage="Нет данных"
	{getOptionLabel}
	{...$$props}
	value={hash_value}
	on:select={onSelect}
	on:clear={onClear}
	on:loaded
	on:error
/>

{#if $$props.items && name && onchange}
	<input type="text" {name} {...dataAttributes} bind:this={ctrl_input} on:change={onchange} style="display:none;" />
{/if}
