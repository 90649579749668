<!-- @format -->
<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { procedure, transmitter, newObject } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { dcts, currentData } from '../stores/stores';
	import { fetchPost } from 'utils/fetch';
	import { isPresent, isBlank } from 'utils/tools';
	import Header from '../../components/header.svelte';
	import Modal, { showModal } from '../../components/modal.svelte';
	import DctsMetaData from '../dcts_meta_data.svelte';
	import moment from 'moment';
	import Case from 'case';

	let selectedDctsMetaData = [];

	$: procedureId = $procedure && $procedure.id;
	$: allSelected = dctsMetaData.length === selectedDctsMetaData.length;
	$: dctsMetaData = $transmitter && $transmitter.dcts_meta_data && $transmitter.dcts_meta_data.all;

	const handleCancel = () => (selectedDctsMetaData = []);

	const reportIsDraft = dct => {
		return dct.status === 'draft';
	};

	const handleAddReport = () => {
		if (['debt_restructuring', 'supervision'].includes($procedure.phase)) {
			selectedDctsMetaData = dctsMetaData.filter(metaData => metaData.kind === 'productive');
			createReport();
		}

		if (['property_realization', 'bankruptcy_proceedings'].includes($procedure.phase)) {
			showModal('select-report-kind-modal');
		}
	};

	const reportOutName = report => `${report.kind_name} от ${moment(report.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}`;

	const openReport = id => (location.href = `/documents/reports/edit?id=${id}`);

	const createReport = () => {
		$newObject = true; // от него зависят значения из $currentData переносимые в substitutions
		const dctsParams = selectedDctsMetaData.map(metaData => {
			let substitutions = {};
			for (let key of metaData.form_fields) {
				substitutions[Case.snake(key)] = $currentData[key];
			}
			for (let person of ['bankrupt', 'arbitrManager']) {
				if (metaData.form_fields.includes(`${person}FullName`)) {
					for (let key of [`${person}Surname`, `${person}Name`, `${person}Patronymic`]) {
						substitutions[Case.snake(key)] = $currentData[key];
					}
				}
			}
			return { meta_record_id: metaData.id, substitutions };
		});

		const params = { category: 'reports', dcts_params: dctsParams, procedure_id: procedureId };

		fetchPost('/api/private/documents', params)
			.then(response => (location.href = `/documents/reports/edit?ids=${response.dcts.map(dct => dct.id)}&new=true`))
			.catch(errors => console.log('errors: ' + JSON.stringify(errors)));
	};

	const toggleAllKinds = () => (selectedDctsMetaData = !allSelected ? dctsMetaData : []);

	onMount(() => {
		onpageshow = event => {
			if (event.persisted) {
				location.reload();
			}
		};
	});
</script>

<Header><span slot="name">Отчёты</span></Header>
<div class="m-b-lg mb-0">
	<button
		class="btn btn-sm btn-warning m-r-sm m-b-sm ml-2 ml-sm-0"
		disabled={!$procedureAvailable || !procedureId}
		on:click={$procedureAvailable && procedureId && handleAddReport()}
	>
		+ Отчёт
	</button>
</div>
<div class="col-sm-5 col-lg-8 col-xl-5 m-b-lg overlay p-1">
	{#if isPresent($dcts)}
		<ol class="m-t-sm">
			{#each $dcts as report}
				<li>
					<div
						class="documents-wrap"
						class:draft={reportIsDraft(report)}
						class:done={!reportIsDraft(report)}
						role="button"
						tabindex="0"
						on:click={$procedureAvailable && openReport(report.id)}
						on:keypress|stopPropagation
					>
						<span>{reportOutName(report)}</span>
						<span class="dcts-handle">({reportIsDraft(report) ? 'черновик' : 'утвержден'})</span>
					</div>
				</li>
			{/each}
		</ol>
	{:else}
		<div class="m-l-xs">Нет документов</div>
	{/if}
</div>

<Modal
	modalId="select-report-kind-modal"
	submitButtonText="Создать"
	submitButtonAction={() => createReport()}
	submitButtonDisabled={isBlank(selectedDctsMetaData)}
	cancelButtonAction={handleCancel}
	size="small"
>
	<h2 slot="header" class="select-report-kind-modal__header">Создать отчёт</h2>
	<div slot="body">
		<ul class="report-kinds-list">
			<li>
				<label>
					<input class="cursor_pointer" type="checkbox" checked={allSelected} on:click={toggleAllKinds} />
					<span class="select-all">Выбрать все</span>
				</label>
			</li>
			<DctsMetaData {dctsMetaData} bind:selectedDctsMetaData />
		</ul>
	</div>
</Modal>

<style>
	.report-kinds-list {
		margin-bottom: 0;
		padding-left: 0;
	}
	.report-kinds-list li {
		list-style-type: none;
	}
	.report-kinds-list input {
		margin-right: 0.3rem;
	}
	.select-all {
		font-weight: bold;
	}
</style>
