<!-- @format -->
<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dct, dctFormAvailable, reloadRunningCosts } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch_helpers';
	import PayoutModal from '~/svelte/payouts/_modal.svelte';
	import { format } from 'date-fns';
	import { formatSum } from 'utils/tools';
	import moment from 'moment';

	export let fieldId;

	let indexCollapse = false;
	let rows = [];
	let payoutModal;
	let date;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	const handleNewItem = () => payoutModal.newItem();
	const handleEditItem = id => payoutModal.editItem(id);

	const load = async () => {
		fetching = true;

		try {
			const payoutsResponse = await fetchGet('/api/private/payouts', { dct_id: $dct.id, procedure_id: $procedure.id, date_at: $dct.date });
			const bankAccountsResponse = await fetchGet('/api/private/bank_accounts', { procedure_id: $procedure.id, date: $dct.date });

			if ($dct.kind == 'productive') {
				const response = await fetchGet('/api/private/payouts/running_costs', { procedure_id: $procedure.id, date_at: $dct.date });
				rows = response.rows;
			} else if ($dct.kind == 'financial') {
				rows = payoutsResponse.rows;
			}

			$transmitter.payouts = payoutsResponse.rows;
			$transmitter.bank_accounts = bankAccountsResponse.bank_accounts;
			$transmitter.bankAccounts = bankAccountsResponse.bank_accounts;
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	const changed = () => {
		load();
		$reloadRunningCosts = true;
	};
	// Отчет АУ о результатах (productive):
	// платежи, у которых в поле Вид платежа выбран вариант Текущие расходы, формат [Вид расхода] ([Сумма, руб.], [Дата платежа])
	//
	// Отчет об использовании денежных средств (financial, ДДС)
	// все платежи, формат [Сумма платежа (руб.)] руб., ([Дата платежа])

	const rowTitle = row => {
		if ($dct.kind == 'productive') {
			return `${row.expense_type_text} (${formatSum(row.sum)} руб., ${format(new Date(row.date_at), 'dd.LL.yyyy')})`;
		} else if ($dct.kind == 'financial') {
			return `${formatSum(row.sum)} руб., (${format(new Date(row.date_at), 'dd.LL.yyyy')})`;
		} else {
			return 'Not defined for document kind: ' + $dct.kind;
		}
	};

	const blockTitle = qty => {
		if ($dct.kind == 'productive') {
			return 'Текущие платежи (' + qty + ')';
		} else {
			return 'Платежи (' + qty + ')';
		}
	};

	$: if (!moment(date).isSame(moment($dct.date)) && !fetching) {
		date = $dct.date;
		changed();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{blockTitle(rows.length)}</span>
	</h4>
</div>
<div class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && handleNewItem}> + Платеж </button>
		<div class="p-1">
			<ol class="records-list">
				{#each rows as row (row.id)}
					<li>
						<div class="float-left simulated-link" role="button" tabindex="0" on:click={handleEditItem(row.id)} on:keypress|stopPropagation>
							{rowTitle(row)}
						</div>
					</li>
				{/each}
			</ol>
		</div>
	</div>
</div>

<PayoutModal bind:this={payoutModal} on:create={changed} on:update={changed} on:delete={changed} disabled={!$dctFormAvailable} />

<style>
	.records-list {
		padding-left: 15px;
		list-style-type: circle;
	}
	.records-list li {
		cursor: pointer;
	}
	.header {
		cursor: pointer;
	}
</style>
