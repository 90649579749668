<!-- @format -->
<script>
	import { transmitter } from '~/js/global_stores/base';
	import { currentData } from '../../stores/stores';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import { toArray } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	let items = [];

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingParticipantId');
		disabled =
			toArray($transmitter.meeting.disabled_fields).includes('meetingParticipantId') ||
			$transmitter.meeting.bulletin_status == 'approved' ||
			$transmitter.meeting.status == 'canceled';
		items = toArray($transmitter.meeting.participants).map(e => ({ value: e.id, label: e.name }));
	}
</script>

{#if visible}
	<Field value={$currentData.meetingParticipantId} name="meetingParticipantId" label="Участник" {disabled}>
		<Select
			{items}
			name="meetingParticipantId"
			isClearable={false}
			isDisabled={disabled}
			value={$currentData.meetingParticipantId}
			containerClasses="form-control"
			placeholder="Выберите участника"
		></Select>
	</Field>
{/if}
