<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import AutosaveInput from '../components/ui/autosave_input.svelte';
	import AutosaveDatepicker from '../components/ui/autosave_datepicker.svelte';

	$: arbitrProcedure = ($transmitter && $transmitter.procedure) || arbitrProcedure;
	$: procedureId = (arbitrProcedure && arbitrProcedure.id) || null;
	$: procedurePhase = arbitrProcedure && arbitrProcedure.phase;

	$: formFields = {
		publicationNewspaperNumber: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'publication_newspaper_number',
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Номер газеты «Коммерсантъ»',
			placeholder: '123(1234)',
			value: arbitrProcedure && arbitrProcedure.publication_newspaper_number,
		},
		publicationNumberNewspaper: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'publication_number_newspaper',
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Номер публикации в газете «Коммерсантъ»',
			placeholder: '123',
			value: arbitrProcedure && arbitrProcedure.publication_number_newspaper,
		},
		publicationNewspaperDate: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'publication_newspaper_date',
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'опубликован',
			placeholder: 'дд.мм.гггг',
			value: arbitrProcedure && arbitrProcedure.publication_newspaper_date,
		},
		publicationFedresursNumber: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'publication_fedresurs_number',
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Номер публикации «Федресурс»',
			placeholder: '123',
			value: arbitrProcedure && arbitrProcedure.publication_fedresurs_number,
		},
		publicationFedresursDate: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'publication_fedresurs_date',
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'опубликован',
			placeholder: 'дд.мм.гггг',
			value: arbitrProcedure && arbitrProcedure.publication_fedresurs_date,
		},
	};
</script>

<div class="m-b-sm overlay procedure__publications-fields">
	<h3>{`${procedurePhase === 'debt_restructuring' ? 6 : 7}. Публикации`}</h3>
	<AutosaveInput {...formFields.publicationNewspaperNumber} />
	<AutosaveInput {...formFields.publicationNumberNewspaper} />
	<AutosaveDatepicker {...formFields.publicationNewspaperDate} />
	<AutosaveInput {...formFields.publicationFedresursNumber} />
	<AutosaveDatepicker {...formFields.publicationFedresursDate} />
</div>
