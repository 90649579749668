<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { procedure, transmitter, bankruptType, newObject } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { dcts, currentData } from '../stores/stores';
	import { fetchPost } from 'utils/fetch';
	import { isPresent, isBlank } from 'utils/tools';
	import Header from '../../components/header.svelte';
	import Modal, { showModal } from '../../components/modal.svelte';
	import DctsMetaData from '../dcts_meta_data.svelte';
	import moment from 'moment';
	import Case from 'case';

	let selectedDctsMetaData = [];

	$: procedureId = $procedure && $procedure.id;
	$: allSelected = dctsMetaData.length === selectedDctsMetaData.length;
	$: dctsMetaData = $transmitter && $transmitter.dcts_meta_data && $transmitter.dcts_meta_data.all;

	const handleCancel = () => (selectedDctsMetaData = []);

	const financialAnalysisIsDraft = dct => {
		return dct.status === 'draft';
	};

	const handleAddFinancialAnalysis = () => showModal('select-financial_analysis-kind-modal');

	const financialAnalysisOutName = financial_analysis =>
		`${financial_analysis.kind_name} от ${moment(financial_analysis.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}`;

	const openFinancialAnalysis = id => (location.href = `/documents/financial_analysis/edit?ids=${id}`);

	const createFinancialAnalysis = () => {
		$newObject = true; // от него зависят значения из $currentData переносимые в substitutions
		const dctsParams = selectedDctsMetaData.map(metaData => {
			let substitutions = {};
			for (let key of metaData.form_fields) {
				substitutions[Case.snake(key)] = $currentData[key];
			}
			for (let person of ['bankrupt', 'arbitrManager']) {
				if (metaData.form_fields.includes(`${person}FullName`)) {
					for (let key of [`${person}Surname`, `${person}Name`, `${person}Patronymic`]) {
						substitutions[Case.snake(key)] = $currentData[key];
					}
				}
			}
			return { meta_record_id: metaData.id, substitutions };
		});

		const params = { category: 'financial_analysis', dcts_params: dctsParams, procedure_id: procedureId };

		fetchPost('/api/private/documents', params)
			.then(response => (location.href = `/documents/financial_analysis/edit?ids=${response.dcts.map(dct => dct.id)}&new=true`))
			.catch(errors => console.log('errors: ' + JSON.stringify(errors)));
	};

	const toggleAllFinancialAnalysisKinds = () => (selectedDctsMetaData = !allSelected ? dctsMetaData : []);

	onMount(() => {
		onpageshow = event => {
			if (event.persisted) {
				location.reload();
			}
		};
	});
</script>

<Header><span slot="name">Финансовый анализ</span></Header>
{#if $bankruptType !== 'Organization'}
	<div class="m-b-lg mb-0">
		<button
			class="btn btn-sm btn-warning m-r-sm m-b-sm ml-2 ml-sm-0"
			on:click={$procedureAvailable && procedureId && handleAddFinancialAnalysis()}
			disabled={!$procedureAvailable || !$procedure?.id}
		>
			+ Документ
		</button>
	</div>
	<div class="col-sm-5 col-lg-8 col-xl-5 m-b-lg overlay p-1">
		{#if isPresent($dcts)}
			<ol class="m-t-sm">
				{#each $dcts as financial_analysis}
					<li>
						<div
							class="documents-wrap"
							class:draft={financialAnalysisIsDraft(financial_analysis)}
							class:done={!financialAnalysisIsDraft(financial_analysis)}
							role="button"
							tabindex="0"
							on:click={$procedureAvailable && openFinancialAnalysis(financial_analysis.id)}
							on:keypress|stopPropagation
						>
							<span>{financialAnalysisOutName(financial_analysis)}</span>
							<span class="dcts-handle">({financialAnalysisIsDraft(financial_analysis) ? 'черновик' : 'утвержден'})</span>
						</div>
					</li>
				{/each}
			</ol>
		{:else}
			<div class="m-l-xs">Нет документов</div>
		{/if}
	</div>
{:else}
	<h3 class="text-center">Раздел в разработке</h3>
{/if}

<Modal
	modalId="select-financial_analysis-kind-modal"
	submitButtonText="Создать"
	submitButtonAction={createFinancialAnalysis}
	submitButtonDisabled={isBlank(selectedDctsMetaData)}
	cancelButtonAction={handleCancel}
	size="medium"
>
	<h2 slot="header" class="select-financial_analysis-kind-modal__header">Создать документ</h2>
	<div slot="body">
		<ul class="financial_analysis-kinds-list">
			<li>
				<label>
					<input class="cursor_pointer" type="checkbox" checked={allSelected} on:click={toggleAllFinancialAnalysisKinds} />
					<span class="select-all">Выбрать все</span>
				</label>
			</li>
			<DctsMetaData {dctsMetaData} bind:selectedDctsMetaData />
		</ul>
	</div>
</Modal>

<style>
	.financial_analysis-kinds-list {
		margin-bottom: 0;
		padding-left: 0;
	}
	.financial_analysis-kinds-list li {
		list-style-type: none;
	}
	.financial_analysis-kinds-list input {
		margin-right: 0.3rem;
	}
	.select-all {
		font-weight: bold;
	}
</style>
