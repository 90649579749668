<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';
	import moment from 'moment';
	import { showModal } from '~/svelte/components/modal.svelte';
	import ProlongationModal from './prolongations/modal.svelte';

	$: procedure = ($transmitter && $transmitter.procedure) || procedure;
	$: procedureCompletionDate = procedure && procedure.completion_date;
	$: prolongations = procedure && procedure.prolongations;
	$: procedureId = (procedure && procedure.id) || null;

	let prolongationIndex;
	let indexCollapse = false;

	const openModalForm = index => {
		prolongationIndex = index;
		showModal('form-prolongation-modal');
	};

	const completionDateHintData = () => {
		let data = {};

		if (procedure && procedure.hearing_date_and_time) {
			if (procedure.completion_date) {
				if (moment(procedure.hearing_date_and_time).format('YYYY-MM-DD') === procedure.completion_date) {
					data = { linkText: 'Дата и время заседания' };
				} else {
					data = { freeMessage: 'Изменено пользователем' };
				}
			}
		}

		return data;
	};

	$: completionDate = {
		disabled: !procedureId || !$procedureAvailable,
		attribute: 'completion_date',
		model: 'procedure',
		url: '/api/private/procedures',
		id: procedureId,
		label: 'Дата окончания процедуры',
		value: procedure && (procedure.completion_date || procedure.hearing_date_and_time),
		forceSave: procedure && procedure.hearing_date_and_time && !procedure.completion_date,
		placeholder: 'дд.мм.гггг',
		hintData: completionDateHintData(),
	};
</script>

<div class="m-b-sm overlay procedure__procedure-completing-fields">
	<h3>5. Окончание процедуры</h3>
	<AutosaveDatepicker {...completionDate} />
	<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
		<h4 class="prolongations col-sm-12">
			<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
			<span class="header">{`Продления процедуры (${prolongations.length})`}</span>
		</h4>
	</div>
	<div id="collapse-prolongations" class="collapse row m-t-n" class:show={indexCollapse}>
		<div class="col-sm-12">
			<button class="btn btn-xs btn-warning m-b-xs" disabled={!procedureCompletionDate} on:click={procedureCompletionDate && openModalForm()}>
				+ Продление
			</button>
			<div class="p-1">
				{#if prolongations.length}
					<ol class="block-list">
						{#each prolongations as prolongation, index}
							{@const lastItem = index + 1 == prolongations.length}
							<li>
								<div
									disabled={!procedureCompletionDate}
									class="float-left"
									class:simulated-link={lastItem}
									role="button"
									tabindex="0"
									on:keypress|stopPropagation
									on:click={lastItem && procedureCompletionDate && openModalForm(index)}
								>
									{[
										prolongation.period ? `на ${prolongation.period} мес.` : null,
										prolongation.completion_date ? `до ${moment(prolongation.completion_date).format('DD.MM.YYYY')} г.` : null,
									].join(' ')}
								</div>
							</li>
						{/each}
					</ol>
				{/if}
			</div>
		</div>
	</div>
</div>

<ProlongationModal {prolongationIndex} />
