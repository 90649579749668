<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter, procedure } from '~/js/global_stores/base';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { fetchGet, fetchPost } from '~/js/utils/fetch';
	import Modal, { showModal } from '../components/modal.svelte';
	import moment from 'moment';
	import { isPresent, isBlank, groupBy, toArray } from 'utils/tools';

	export let dcts = { priority: [] };
	export let context;
	export let sections;
	export let groupsWithIndex;

	let mailRegistries = [];
	let selectedDctIds = [];
	let showMailRegistrySentDocuments = false;
	let sectionGroups = [];
	let allDctIds = [];
	let newMailRegistryId;

	const buildmailRegistries = transmitter => {
		if (transmitter && transmitter.mail_registries) {
			mailRegistries = transmitter.mail_registries;

			if (context && context.meeting_id && dcts.priority) {
				const ids = dcts.priority.map(e => e.id);
				mailRegistries = transmitter.mail_registries.filter(mr => mr.mail_packages.filter(mp => ids.includes(mp.dct_id)).length);
			}
		} else {
			mailRegistries = [];
		}
	};

	$: if (sections || showMailRegistrySentDocuments) {
		sectionGroups = [];
		for (let section of sections) {
			let approvedDcts = toArray(section.data).filter(dct => dct.status == 'approved' && !['newspaper', 'fedresource'].includes(dct.correspondent_kind));
			if (!showMailRegistrySentDocuments) {
				approvedDcts = approvedDcts.filter(dct => !sentDctInPackagesIds.includes(dct.id));
			}
			let groups = Object.entries(
				groupBy(
					Object.values(approvedDcts)
						.flat()
						.filter(e => isPresent(e.group_docs)),
					e => e.group_docs,
				),
			)
				.reverse()
				.reduce((h, e, i) => {
					h[e[0]] = { selected: false, index: groupsWithIndex[e[0]].index || i + 1, ids: e[1].map(r => r.id) };
					return h;
				}, {});

			sectionGroups.push({ ...section, dcts: approvedDcts, groups: groups });
		}
		allDctIds = sectionGroups.flatMap(section => section.dcts.map(dct => dct.id));
	}

	$: category = context && context.dct_category;
	$: participantType = context && context.participant_type;

	$: buildmailRegistries($transmitter);

	$: sentMailRegistries = mailRegistries && mailRegistries.filter(registry => registry.sent);
	$: sentMailRegistriesPackages = sentMailRegistries && sentMailRegistries.map(registry => registry.mail_packages).flat();
	$: sentDctInPackagesIds = [...new Set(sentMailRegistriesPackages && sentMailRegistriesPackages.map(mailPackage => mailPackage.dct_id))];
	$: allDctsSelected = allDctIds.length === selectedDctIds.length;

	const handleSubmit = () => {
		const mail_registry_params = { substitutions: context };

		return fetchPost('/api/private/mail_registries', { dct_ids: selectedDctIds, mail_registry: mail_registry_params }).then(response => {
			newMailRegistryId = response.mail_registries[response.mail_registries.length - 1].id;
			location.href = `/mail_registry/${newMailRegistryId}?category=${category}${participantType ? `&participant_type=${participantType}` : ''}`;
		});
	};

	const handleCancel = () => {
		selectedDctIds = [];
		showMailRegistrySentDocuments = false;
	};

	const toggleDcts = ids => {
		selectedDctIds = dctsIsSelected(ids) ? selectedDctIds.filter(id => !ids.includes(id)) : [...new Set([...selectedDctIds, ...ids])];
	};

	const dctsIsSelected = ids => {
		ids.every(id => selectedDctIds.includes(id));
	};
	const toggleAllDcts = () => {
		selectedDctIds = !allDctsSelected ? allDctIds : [];
	};

	onMount(() => {
		fetchGet('/api/private/mail_registries', { category, participant_type: participantType }).then(
			response => ($transmitter = { ...$transmitter, mail_registries: response.mail_registries }),
		);
	});
</script>

<h3 class="dcts-title">Реестры почтовых отправлений ({(mailRegistries && mailRegistries.length) || 0})</h3>
<button
	class="btn btn-sm btn-warning m-r-sm m-b-sm"
	on:click={$procedureAvailable && (() => showModal('mail-registry_modal'))}
	disabled={!$procedureAvailable || !$procedure?.id}
>
	+ Реестр
</button>
{#if isPresent(mailRegistries)}
	<ol>
		{#each mailRegistries as mailRegistry}
			<li>
				<a
					href={`/mail_registry/${mailRegistry.id}?category=${category}${participantType ? `&participant_type=${participantType}` : ''}`}
					data-turbolinks="false"
				>
					Реестр от {moment(mailRegistry.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
					{mailRegistry.sent ? '(отправлен)' : ''}
				</a>
			</li>
		{/each}
	</ol>
{:else}
	<div>Нет документов</div>
{/if}

<Modal
	modalId="mail-registry_modal"
	index="0"
	submitButtonText="Создать"
	submitButtonCss="btn-warning"
	submitButtonAction={handleSubmit}
	submitButtonDisabled={isBlank(selectedDctIds)}
	cancelButtonAction={handleCancel}
	form={false}
	size="medium"
>
	<h2 slot="header" class="create-dct-modal__header">Создать реестр почтовых отправлений</h2>
	<ul slot="body" class="pl-0">
		<li class="list-style_type__none font-weight-bold mb-3">
			<label>
				<input class="cursor_pointer" type="checkbox" on:click={() => (showMailRegistrySentDocuments = !showMailRegistrySentDocuments)} />
				Отображать отправленные
			</label>
		</li>
		{#if isPresent(allDctIds)}
			<li class="list-style_type__none font-weight-bold">
				<label>
					<input class="cursor_pointer" type="checkbox" on:click={toggleAllDcts} checked={allDctsSelected} />
					Выбрать все
				</label>
			</li>
			{#each sectionGroups as section}
				<li class="list-style_type__none">
					<label>
						<input
							class="cursor_pointer"
							type="checkbox"
							on:click={() => toggleDcts(section.dcts.map(dct => dct.id))}
							checked={selectedDctIds && dctsIsSelected(section.dcts.map(dct => dct.id))}
						/>
						{section.title}
					</label>
				</li>
				{#each Object.values(section.groups) as group}
					<ul class="mb-0 pl-3">
						<li class="list-style_type__none">
							<label>
								<input class="cursor_pointer" type="checkbox" on:click={() => toggleDcts(group.ids)} checked={selectedDctIds && dctsIsSelected(group.ids)} />
								Группа
								{group.index}
							</label>
						</li>
						<ul class="mb-0 pl-3">
							{#each group.ids as id}
								<li class="list-style_type__none">
									<label>
										<input class="cursor_pointer" type="checkbox" bind:group={selectedDctIds} value={id} />
										{section.dcts.find(dct => dct.id === id).out_short_name}
									</label>
								</li>
							{/each}
						</ul>
					</ul>
				{/each}
				{#if isPresent(section.dcts.filter(dct => !dct.group_docs))}
					<ul class={category === 'outgoing' ? 'mb-0 pl-3' : 'mb-0 pl-0'}>
						{#if category === 'outgoing'}
							<li class="list-style_type__none">
								<label>
									<input
										class="cursor_pointer"
										type="checkbox"
										on:click={() => toggleDcts(section.dcts.filter(dct => !dct.group_docs).map(dct => dct.id))}
										checked={selectedDctIds && dctsIsSelected(section.dcts.filter(dct => !dct.group_docs).map(dct => dct.id))}
									/>
									Без группы
								</label>
							</li>
						{/if}
						<ul class="mb-0 pl-3">
							{#each section.dcts.filter(dct => !dct.group_docs) as dct}
								<li class="list-style_type__none">
									<label>
										<input class="cursor_pointer" type="checkbox" bind:group={selectedDctIds} value={dct.id} />
										{dct.out_short_name}
									</label>
								</li>
							{/each}
						</ul>
					</ul>
				{/if}
			{/each}
		{/if}
	</ul>
</Modal>

<style>
	.list-style_type__none {
		list-style-type: none;
	}
	a {
		color: #676a6c;
		border-bottom: 1px solid #676a6c;
	}
	a:hover {
		color: inherit;
	}
	input {
		margin-right: 0.3rem;
	}
</style>
