<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveDadataInputEmail from '~/svelte/components/ui/autosave_dadata_input_email.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: bankruptEmail = {
		...$commonParams,
		attribute: 'bankrupt_email',
		label: 'Электронная почта должника',
		value: $currentData && $currentData.bankruptEmail,
		placeholder: 'mail@mail.ru',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_email',
			resource: $procedure && $procedure.bankrupt,
			resourceProp: 'email',
		},
	};
</script>

<AutosaveDadataInputEmail {...bankruptEmail} on:update />
