/** @format */

import Popper from 'popper.js';

$(document).on('turbolinks:load', function () {
	$('.nav-header').addClass('align-nav-logo');
	$('body').addClass('mini-navbar');
	// Fast fix bor position issue with Propper.js
	// Will be fixed in Bootstrap 4.1 - https://github.com/twbs/bootstrap/pull/24092
	Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

	// Open close small chat
	$('.open-small-chat').on('click', function (e) {
		e.preventDefault();
		$(this).children().toggleClass('fa-comments').toggleClass('fa-times');
		$('.small-chat-box').toggleClass('active');
	});

	// Initialize slimscroll for small chat
	$('.small-chat-box .content').slimScroll({
		height: '234px',
		railOpacity: 0.4,
	});

	// Small todo handler
	$('.check-link').on('click', function () {
		var button = $(this).find('i');
		var label = $(this).next('span');
		button.toggleClass('fa-check-square').toggleClass('fa-square-o');
		label.toggleClass('todo-completed');
		return false;
	});

	// Tooltips demo
	/*$('.tooltip-demo').tooltip({
      selector: "[data-toggle=tooltip]",
      container: "body"
  });*/

	// Move right sidebar top after scroll
	$(window).scroll(function () {
		if ($(window).scrollTop() > 0 && !$('body').hasClass('fixed-nav')) {
			$('#right-sidebar').addClass('sidebar-top');
		} else {
			$('#right-sidebar').removeClass('sidebar-top');
		}
	});

	//$("[data-toggle=popover]")
	//    .popover();

	// Add slimscroll to element
	$('.full-height-scroll').slimscroll({
		height: '100%',
	});

	// MetisMenu
	let sideMenu = $('#side-menu').metisMenu();
	if (window.innerWidth > 576) {
		sideMenu.mouseleave(() => {
			$('body .navbar-static-side').css({ width: '70px' });
			$('body').addClass('mini-navbar');
		});

		sideMenu.mouseover(() => {
			$('body .navbar-static-side').css({ width: '220px' });
			$('body').removeClass('mini-navbar');
		});
	} else {
		$('body .navbar-static-side').css({ width: '80%' });
		$('body').removeClass('mini-navbar');
		$('.hamburger-menu').click(() => {
			$('body .navbar-static-side').toggleClass('menu_active');
		});
	}
});
