<!-- @format -->
<script>
	import { options, transmitter, procedure } from 'base_stores';
	import { currentData, commonParams, requiredFieldsFilled, dct } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';

	let bankAccountsLoaded = false;

	$: if ($dct.form_fields.includes('outgoingRequestId')) {
		$requiredFieldsFilled.outgoingRequestId = !!outgoingRequestId.value;
	}

	const loadBankAccounts = async () => {
		const response = await fetchGet('/api/private/bank_accounts', {
			procedure_id: $procedure.id,
			date: $currentData.startDateOfRequestedInformationPeriod,
		}).then(response => {
			bankAccountsLoaded = true;
			$transmitter = { ...$transmitter, bankAccounts: response.bank_accounts };
		});
	};

	$: if ($currentData.outgoingRequestCorrespondentKind === 'bank' && !bankAccountsLoaded) {
		loadBankAccounts();
	}

	$: outgoingRequestId = {
		...$commonParams,
		required: true,
		attribute: 'outgoing_request_id',
		label: 'Запрос, по которому подаётся ходатайство',
		value: $options.outgoingRequest.find(option => option.id === $currentData.outgoingRequestId),
		options: $options.outgoingRequest,
		placeholder: 'Выбрать запрос',
	};
</script>

<AutosaveSelect2Search {...outgoingRequestId} on:update />
