<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import imask from 'imask';
	import { toString } from 'utils/tools';
	import moneyOptions from '~/svelte/_shared/money_options';

	export let id;
	export let name;
	export let value;
	export let disabled;
	export let placeholder = '0,00';
	export let klass;
	export let onchange;

	const dispatch = createEventDispatcher();

	let ref;
	let ref_hidden;
	let imaskInstance;
	let currentValue;

	const imaskTemplate = node => {
		imaskInstance = imask(node, moneyOptions);
		imaskInstance.on('complete', () => (currentValue = value = imaskInstance.unmaskedValue));

		return {
			destroy() {
				imaskInstance.destroy();
			},
		};
	};
	const handleChange = () => {
		ref_hidden.value = imaskInstance.unmaskedValue;
		ref_hidden.dispatchEvent(new Event('change'));
	};

	$: if (imaskInstance && value != currentValue) imaskInstance.value = toString(value).replace(/\./, ',');
</script>

<input
	{id}
	{name}
	type="text"
	bind:this={ref}
	use:imaskTemplate
	{disabled}
	{placeholder}
	class={klass}
	on:focus={() => {
		ref.select();
		dispatch('focus');
	}}
	on:blur={() => {
		dispatch('blur');
	}}
	on:change={handleChange}
	autocomplete="off"
/>

<input type="text" {name} bind:this={ref_hidden} on:change={onchange} style="display:none;" />
