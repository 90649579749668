<!-- @format -->
<script>
	import { autosaveStatus, dctReady, fieldsReady } from 'base_stores';
	import { fade } from 'svelte/transition';

	let status = null;

	$: if ($dctReady && $autosaveStatus !== status) {
		if ($autosaveStatus === 'clear') {
			status = null;
		} else {
			status = 'saving';
			setTimeout(() => {
				status = $autosaveStatus;
			}, 1000);
		}
	}

	$: if (!$dctReady) {
		setTimeout(() => {
			status = null;
			$fieldsReady = {};
		}, 45000);
	}
</script>

<div class="autosave-wrapper">
	{#if !$dctReady}
		<div in:fade={{ delay: 50, duration: 3000 }} out:fade={{ delay: 500, duration: 3000 }} class="badge badge-warning badge-striped badge-animated">
			Загружается...
		</div>
	{:else if status === 'saving'}
		<div in:fade={{ delay: 50, duration: 3000 }} out:fade={{ delay: 500, duration: 500 }} class="badge badge-info badge-striped badge-animated">
			Сохраняется...
		</div>
	{:else if status === 'saved'}
		<div in:fade={{ delay: 1000, duration: 1500 }} out:fade={{ delay: 0, duration: 50 }} class="badge badge-primary">Автосохранён</div>
	{:else if status === 'not_saved'}
		<div in:fade={{ delay: 1000, duration: 1500 }} out:fade={{ delay: 0, duration: 50 }} class="badge badge-danger">Не сохранён</div>
	{/if}
</div>

<style>
	.autosave-wrapper {
		position: relative;
	}
	.badge {
		position: absolute;
		top: 0;
	}
	.badge-striped {
		background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25%, #0000 50%, #ffffff26 50%, #ffffff26 75%, #0000 75%, #0000);
		background-size: 1rem 1rem;
	}
	.badge-animated {
		animation: progress-bar-stripes 1s linear infinite;
	}
</style>
