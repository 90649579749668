<!-- @format -->
<script>
	import { autosaveStatus, transmitter } from 'base_stores';
	import { onMount } from 'svelte';
	import iMask from 'imask';
	import 'tempusdominus-bootstrap-4';
	import '~/js/patch_for_datetimepicker';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import { validate } from 'tools/validators';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import moment from 'moment';
	import IMask from 'imask';

	export let attribute;
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let label;
	export let skipVerificationRequired = true;
	export let value = undefined;
	export let required = false;
	export let disabled = false;
	export let placeholder = 'дд.мм.гггг чч:мм';
	export let maskOptions;
	export let hintData;
	export let additionalParams = {};
	export let saveAutomatically = true;
	export let timeOnly = false;

	let inputField;
	let dataErrors;
	let datetimepickerNode;
	let maskRef;
	let previousValue = null;
	let countdown = 1;

	const datetimepickerId = `datetimepicker-${model}-${attribute}`;

	$: method = id ? fetchPut : fetchPost;
	$: if (id) {
		url = `${url}/${id}`;
	}
	$: fValue = value ? (timeOnly ? moment(value, 'HH:mm').format('HH:mm') : moment.utc(value).format('DD.MM.YYYY HH:mm')) : null;
	$: isSuccess = value && !dataErrors;

	$: if (!previousValue && countdown > 0 && value === undefined) {
		countdown = countdown - 1;
	}
	$: if (!previousValue && countdown === 0 && value !== undefined) {
		previousValue = value;
		countdown = countdown - 1;
	}

	const handleChange = () => {
		$autosaveStatus = null;

		if (!moment(fValue, timeOnly ? 'HH:mm' : 'DD.MM.YYYY HH:mm', true).isValid()) {
			fValue = value = null;
		}

		if (previousValue !== value) {
			dataErrors = validate(model, attribute, value, skipVerificationRequired);

			if (!dataErrors) {
				let params = {
					[model]: { [attribute]: timeOnly ? moment(value, 'HH:mm').format('HH:mm') : value },
					[`${childModel}_id`]: childId,
					...additionalParams,
				};

				method(url, params)
					.then(response => {
						if (JSON.stringify(response) !== '{}') {
							$autosaveStatus = 'saved';
						}
						$transmitter = { ...$transmitter, ...response };
						previousValue = timeOnly ? moment(value, 'HH:mm').format('HH:mm') : value;
					})
					.catch(_errors => {
						inputField.focus();
						$autosaveStatus = 'not_saved';
					});
			} else {
				$autosaveStatus = 'not_saved';
			}
		}
	};

	const updateMaskValue = () => {
		if (!timeOnly) {
			maskRef.updateValue(maskRef.value);
		}
	};

	const initMask = () => {
		let momentFormat = 'DD.MM.YYYY HH:mm';

		maskRef = iMask(inputField, {
			...maskOptions,
			pattern: momentFormat,
			format: dateTime => moment(dateTime).format(momentFormat),
			parse: str => moment(str, momentFormat),
			blocks: {
				YYYY: {
					mask: IMask.MaskedRange,
					from: 1900,
					to: 2099,
				},
				MM: {
					mask: IMask.MaskedRange,
					from: 1,
					to: 12,
				},
				DD: {
					mask: IMask.MaskedRange,
					from: 1,
					to: 31,
				},
				HH: {
					mask: IMask.MaskedRange,
					from: 0,
					to: 23,
				},
				mm: {
					mask: IMask.MaskedRange,
					from: 0,
					to: 59,
				},
			},
		});
	};

	onMount(() => {
		if (!timeOnly) {
			initMask();
		}

		// eslint-disable-next-line no-undef
		datetimepickerNode = jQuery(`#${datetimepickerId}`);
		datetimepickerNode.datetimepicker({
			locale: 'ru',
			format: timeOnly ? 'HH:mm' : 'DD.MM.YYYY HH:mm',
			sideBySide: !timeOnly, // true,
			useCurrent: false,
			allowInputToggle: true,
			keyBinds: {
				escape: null,
				up: null,
				down: null,
				right: null,
				left: null,
				delete: null,
			},
		});

		datetimepickerNode.on('change.datetimepicker', event => {
			event.stopPropagation();
			updateMaskValue();

			fValue = timeOnly ? event.date : maskRef.value;

			if (/\d/.test(fValue)) {
				if (timeOnly) {
					value = fValue;
				} else {
					if (/\d{2}$/.test(fValue.split('.')[0]) && /\d{2}$/.test(fValue.split('.')[1]) && /\d{4} \d{2}:\d{2}$/.test(fValue.split('.')[2])) {
						value = moment.utc(fValue, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm');
					} else {
						value = previousValue;
					}
				}
			} else {
				value = null;
				fValue = null;
			}
		});
	});
</script>

<div class="form-group row" class:has-error={dataErrors} class:has-success={isSuccess}>
	<label for={datetimepickerId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<div id={datetimepickerId} class="input-group date" class:custom-datepicker={!timeOnly} class:time-only={timeOnly} data-target-input="nearest">
			<input
				type="text"
				{placeholder}
				class="form-control datetimepicker-input"
				value={fValue}
				bind:this={inputField}
				{disabled}
				on:blur={saveAutomatically && handleChange}
				on:focus={updateMaskValue}
				data-target={`#${datetimepickerId}`}
				maxlength="16"
				autocomplete="off"
			/>
			<div class="input-group-append" data-target={`#${datetimepickerId}`} data-toggle="datetimepicker">
				<div class="input-group-text">
					<iconify-icon icon={timeOnly ? 'bx-bxs-time' : 'bx-bxs-calendar'} />
				</div>
			</div>
		</div>
		{#if dataErrors}
			<InputErrorsString errors={dataErrors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>

<style lang="scss">
</style>
