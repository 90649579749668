/** @format */

import { readable, derived, writable } from 'svelte/store';
import { procedure } from 'base_stores';

export const types = readable([
	{
		id: 'Person',
		text: 'Физическое лицо',
		incompatibleRoles: {
			Person: ['bank', 'stockbroker', 'cryptoex', 'debtor_participant'],
			Organization: ['bank', 'stockbroker', 'cryptoex'],
		},
	},
	{
		id: 'Organization',
		text: 'Юридическое лицо',
		incompatibleRoles: {
			Person: ['worker', 'kdl', 'debtor_participant'],
			Organization: ['worker', 'kdl'],
		},
	},
	{
		id: 'Individual_Entrepreneur',
		text: 'ИП',
		incompatibleRoles: {
			Person: ['bank', 'stockbroker', 'cryptoex', 'worker', 'kdl', 'debtor_participant'],
			Organization: ['bank', 'stockbroker', 'cryptoex', 'worker', 'kdl'],
		},
	},
]);

export const roles = readable([
	{ id: 'declarer', text: 'Заявитель', incompatibleRoles: [], disabledRoles: ['cryptoex'],
		sequenceNumber: { Person: 10, Organization: 10, Individual_Entrepreneur: 10 },
	},
	{ id: 'creditor', text: 'Кредитор', incompatibleRoles: [], disabledRoles: [],
		sequenceNumber: { Person: 20, Organization: 20, Individual_Entrepreneur: 20 },
	},
	{ id: 'current_creditor', text: 'Текущий кредитор', incompatibleRoles: [], disabledRoles: [],
		sequenceNumber: { Person: 30, Organization: 30, Individual_Entrepreneur: 30 },
	},
	{ id: 'bank', text: 'Банк', incompatibleRoles: [], disabledRoles: ['stockbroker', 'cryptoex'],
		sequenceNumber: { Person: 40, Organization: 40, Individual_Entrepreneur: 40 },
	},
	{ id: 'stockbroker', text: 'Брокер', incompatibleRoles: [], disabledRoles: ['bank', 'cryptoex'],
		sequenceNumber: { Person: 50, Organization: 50, Individual_Entrepreneur: 50 },
	},
	{ id: 'payee', text: 'Получатель платежа', incompatibleRoles: [], disabledRoles: [],
		sequenceNumber: { Person: 60, Organization: 60, Individual_Entrepreneur: 60 },
	},
	{ id: 'debtor', text: 'Дебитор', incompatibleRoles: [], disabledRoles: [],
		sequenceNumber: { Person: 70, Organization: 80, Individual_Entrepreneur: 80 },
	},
	{ id: 'debtor_participant', text: 'Участник должника (на дату введения процедуры)', incompatibleRoles: [], disabledRoles: [],
		sequenceNumber: { Person: 80, Organization: 90, Individual_Entrepreneur: 90 },
	},
	{ id: 'meeting_participant', text: 'Участник собрания', incompatibleRoles: [], disabledRoles: [],
		sequenceNumber: { Person: 90, Organization: 100, Individual_Entrepreneur: 100 },
	},
	{ id: 'worker', text: 'Работник', incompatibleRoles: ['bank', 'stockbroker', 'cryptoex'], disabledRoles: [],
		sequenceNumber: { Person: 100, Organization: 110, Individual_Entrepreneur: 110 },
	},
	{ id: 'employer', text: 'Работодатель', incompatibleRoles: [], disabledRoles: [],
		sequenceNumber: { Person: 110, Organization: 70, Individual_Entrepreneur: 70 },
	},
	{ id: 'kdl', text: 'Контролирующее должника лицо', incompatibleRoles: [], disabledRoles: [],
		sequenceNumber: { Person: 120, Organization: 120, Individual_Entrepreneur: 120 },
	},
	{ id: 'cryptoex', text: 'Криптобиржа', incompatibleRoles: [], disabledRoles: ['declarer', 'bank', 'stockbroker'],
		sequenceNumber: { Person: 130, Organization: 130, Individual_Entrepreneur: 130 },
	},
]);

export const rolesRu = derived(roles, $roles => {
	const translations = {};
	for (let role of $roles) {
		translations[role.id] = role.text;
	}
	return translations;
});

export const counterparty = (() => {
	const { subscribe, set } = writable({
		id: null,
		contractor_type: null,
		contractor_id: null,
		roles: [],
		bank_bik: null,
		bank_name: null,
		bank_cor_account: null,
		bank_account: null,
		bank_rkc: null,
		can_be_destroyed: true,
		additions: {},
		errors: {},
	});

	const clear = () =>
		set({
			id: null,
			contractor_type: null,
			contractor_id: null,
			roles: [],
			bank_bik: null,
			bank_name: null,
			bank_cor_account: null,
			bank_account: null,
			bank_rkc: null,
			can_be_destroyed: true,
			additions: {},
			errors: {},
		});

	return { subscribe, set, clear };
})();

export const actualRoles = derived([types, roles, counterparty, procedure], ([$types, $roles, $counterparty, $procedure]) => {
	const counterpartyIncompatibleRoles = [...new Set(($counterparty?.roles?.map(cr => $roles.find(r => r.id == cr)?.incompatibleRoles) || []).flat())]

	const counterpartyDisabledRoles = [...new Set(($counterparty?.roles?.map(cr => $roles.find(r => r.id == cr)?.disabledRoles) || []).flat())]

	const byBankruptTypeIncompatibleRoles = $types.find(t => t.id == $counterparty.contractor_type)?.incompatibleRoles[$procedure?.bankrupt_type] || []

	let selectedRoles = $roles.filter(role => ![...new Set([...counterpartyIncompatibleRoles, ...byBankruptTypeIncompatibleRoles])]?.includes(role.id))

	return selectedRoles.map(role => {
		if (counterpartyDisabledRoles.includes(role.id) || $counterparty.id && role.id == 'cryptoex' && $counterparty?.roles?.includes(role.id)) {
			return { ...role, fixed: true }
		} else {
			return role
		}
	}).sort((a, b) => a.sequenceNumber[$counterparty.contractor_type] - b.sequenceNumber[$counterparty.contractor_type]);
});
