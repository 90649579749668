<!-- @format -->
<script>
	import { getContext, onMount } from 'svelte';
	import { isPresent, isBlank, isString, isArray, randomString } from 'utils/tools';

	const context = getContext('FormContext');

	export let formInstance = context.formInstance;
	export let name;
	export let label;
	export let disabled = false;
	export let required = false;
	export let short = false;
	export let date = false;
	export let time = false;
	export let labelClasses = 'col-form-label col-sm-4 col-lg-3 col-xl-4';
	export let fieldClasses = 'col-sm-8 col-lg-9 col-xl-8';

	const id = randomString();
	const { form, errors, formFields } = formInstance;

	onMount(() => {
		if (required) {
			context.requiredFields.update(arr => arr.concat([name]));
		}

		formFields.update(arr => {
			arr.push(name);
			return arr;
		});

		return () => {
			if (required) {
				context.requiredFields.update(arr => {
					const index = arr.indexOf(name);
					if (index > -1) {
						arr.splice(index, 1);
					}
					return arr;
				});
			}
			formFields.update(arr => arr.filter(e => e != name));
		};
	});

	const getValue = (f, name) => {
		if (isArray(name)) {
			return name.length === 1 ? getValue(f, name[0]) : getValue(f[name[0]], name.slice(1));
		} else {
			return f[name];
		}
	};

	$: fieldErrors = getValue($errors, name);
	$: fieldValue = getValue($form, name);
	$: isError = isArray(fieldErrors) ? isPresent(fieldErrors[0]) : isPresent(fieldErrors);
	$: isRequired = required && isBlank(fieldValue);
	$: isSuccess = isPresent(fieldValue) && !isError;
</script>

<div
	class="form-field form-group row"
	class:field-error={isError}
	class:field-success={isSuccess && !isError && !disabled}
	class:field-warning={isRequired && !isError && !isSuccess}
	class:field-disabled={disabled}
>
	<label for={id} class={labelClasses} class:text-disabled={disabled}>
		{label}
		{#if required}<attr class="required">*</attr>{/if}
	</label>

	<div class={fieldClasses} class:short class:date-control={date} class:time-control={time}>
		<slot {id} />

		{#if isPresent(fieldErrors) && isString(fieldErrors)}
			<span class="form-text text-danger m-b-none m-l-xs">{fieldErrors}</span>
		{:else if $$slots.hint}
			<slot name="hint" />
		{/if}
	</div>
</div>

<style lang="scss">
	/*:global(.form-field) {
		.selectContainer,
		.form-control {
			border-color: #aaaaaa !important;
			z-index: auto !important;
			.listContainer {
				z-index: 10 !important;
			}
		}
		input:focus,
		textarea:focus {
			border-color: #aaaaaa;
		}

		&.field-error {
			.selectContainer,
			.form-control {
				border: 1px solid #ed5565 !important;
			}
			& > div > input,
			& > div > div.input-wrapper > input,
			& > div > textarea {
				border: 1px solid #ed5565 !important;
			}
		}
		&.field-success {
			.selectContainer,
			.form-control {
				border: 1px solid #1ab394 !important;
			}
			& > div > input,
			& > div > div.input-wrapper > input,
			& > div > textarea {
				border: 1px solid #1ab394 !important;
			}
		}
		&.field-warning {
			.selectContainer,
			.form-control {
				border: 1px solid #f8ac59 !important;
			}
			& > div > input,
			& > div > div.input-wrapper > input,
			& > div > textarea {
				border: 1px solid #f8ac59 !important;
			}
		}
		&.field-disabled {
			.selectContainer,
			.form-control {
				border: 1px solid #aaaaaa !important;
				color: #a8a8a8 !important;
				background-color: #f9f7f7 !important;
			}
			& > div > input,
			& > div > div.input-wrapper > input,
			& > div > textarea {
				border: 1px solid #aaaaaa !important;
			}
		}
	}
	.short {
		input,
		div.form-control {
			// col-4
			flex: 0 0 33.3333333333%;
			max-width: 33.3333333333%;
			min-width: 155px;
		}
	}
	.date-control {
		input,
		div.form-control {
			// col-4
			flex: 0 0 33.3333333333%;
			max-width: 33.3333333333%;
			min-width: 155px;
		}
	}
	.time-control {
		input,
		div.form-control {
			// col-1
			flex: 0 0 8.3333333333%;
			max-width: 8.3333333333%;
			min-width: 95px;
		}
	}*/
</style>
