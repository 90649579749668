<!-- @format -->
<script>
	import { tick } from 'svelte';
	import { childId } from './stores';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import ChildForm from './_form.svelte';
	import { cardsTitle } from 'utils/formats';

	const modalId = 'form-child-modal';

	let submitButtonDisabled;
	let handleSubmit = () => {};
	let handleDelete = () => {};
	let handleDuplicate = () => {};

	const switchModal = () => {
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};
</script>

<Modal
	{modalId}
	{submitButtonDisabled}
	deleteIconDisabled={!$childId}
	submitButtonAction={handleSubmit}
	deleteButtonAction={handleDelete}
	showCopyAndSaveButton={$childId}
	copyAndSaveButtonAction={handleDuplicate}
	form={true}
	size="large"
>
	<h2 slot="header" class="child-modal__header">{cardsTitle('ребёнка', $childId)}</h2>
	<div slot="body">
		<ChildForm bind:handleSubmit bind:handleDelete bind:handleDuplicate bind:submitButtonDisabled on:submit on:delete on:duplicate={switchModal} />
	</div>
</Modal>
