<!-- @format -->
<script>
	import { hideModal } from '~/svelte/components/modal.svelte';
	import { transmitter } from 'base_stores';
	import { fetchPut } from 'utils/fetch';
	import { isPresent, isBlank } from 'utils/tools';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import Input from '~/svelte/components/ui/input.svelte';
	import moment from 'moment';

	export let prolongationIndex;
	export let handleSubmit;
	export let handleDelete;
	export let handleCancel;
	export let submitButtonDisabled;

	let prolongation = {};
	let periodHolder;

	$: procedure = ($transmitter && $transmitter.procedure) || procedure;
	$: procedureCompletionDate = procedure && procedure.completion_date;
	$: prolongations = procedure && procedure.prolongations;
	$: submitButtonDisabled = !prolongation.period && !prolongation.completion_date;

	$: if (isBlank(procedure)) {
		procedure = $transmitter && $transmitter.procedure;
	}

	$: if (isBlank(prolongation)) {
		prolongation = { ...(prolongations || [])[prolongationIndex] } || {
			resolution_completion_date: null,
			period: null,
			completion_date: null,
			date: moment().format('YYYY-MM-DD'),
		};
		periodHolder = prolongation.period;
	}

	$: if (isPresent(prolongation) && isPresent(prolongation.period) && periodHolder != prolongation.period) {
		prolongation.completion_date = moment(completionDate).add(prolongation.period, 'months').add(1, 'days').format('YYYY-MM-DD');
		periodHolder = prolongation.period;
	}

	$: completionDate =
		isBlank(prolongations) || prolongationIndex == 0
			? procedureCompletionDate
			: (isBlank(prolongationIndex) ? prolongations[prolongations.length - 1] : prolongations[prolongationIndex - 1])['completion_date'];

	$: formFields = {
		resolutionCompletionDate: {
			expansionInFocus: true,
			widgetLeft: true,
			model: 'prolongation',
			attribute: 'resolution_completion_date',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			//startDate: moment(completionDate).add(1, 'days').format('YYYY-MM-DD'),
			label: 'Дата резолютивной части судебного акта о продлении',
			labelCss: 'col-sm-8 col-lg-9 col-xl-8 col-form-label',
			divCss: 'col-sm-4 col-lg-3 col-xl-4',
		},
		period: {
			model: 'prolongation',
			attribute: 'period',
			placeholder: '2',
			label: 'Период продления, мес.',
			labelCss: 'col-sm-8 col-lg-9 col-xl-8 col-form-label',
			divCss: 'col-sm-4 col-lg-3 col-xl-4',
		},
		completionDate: {
			expansionInFocus: true,
			widgetLeft: true,
			model: 'prolongation',
			attribute: 'completion_date',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			startDate: moment(completionDate).add(1, 'days').format('YYYY-MM-DD'),
			label: 'Дата окончания после продления',
			labelCss: 'col-sm-8 col-lg-9 col-xl-8 col-form-label',
			divCss: 'col-sm-4 col-lg-3 col-xl-4',
		},
	};

	handleSubmit = async del => {
		const params = {
			prolongations: isPresent(prolongationIndex)
				? prolongations.map((p, i) => (prolongationIndex == i ? (del ? null : prolongation) : p)).filter(i => i)
				: [...prolongations, prolongation],
		};

		console.log('params: ' + JSON.stringify(params));

		const response = await fetchPut(`/api/private/procedures/${procedure.id}`, { procedure: params });
		$transmitter = { ...$transmitter, ...response };
		//$procedure = response.procedure
	};

	handleDelete = async () => handleSubmit(true);

	handleCancel = async () => {
		hideModal('form-prolongation-modal');
		prolongation = undefined;
	};
</script>

<Datepicker {...formFields.resolutionCompletionDate} bind:value={prolongation.resolution_completion_date} />
<Input {...formFields.period} bind:value={prolongation.period} />
<Datepicker {...formFields.completionDate} bind:value={prolongation.completion_date} />
