<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInputCurrency from '~/svelte/components/ui/autosave_input_currency.svelte';

	$: fundsOnCourtDepositCost = {
		...$commonParams,
		attribute: 'funds_on_court_deposit_cost',
		label: 'Денежные средства на депозите суда',
		value: $currentData?.fundsOnCourtDepositCost,
		placeholder: '0,00',
	};
</script>

{#if $currentData?.hasBeenExpensesFundsDepositedIntoCourtDeposit}
	<AutosaveInputCurrency {...fundsOnCourtDepositCost} />
{/if}
