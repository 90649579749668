<!-- @format -->
<!-- @format -->
<script>
	import moment from 'moment';

	export let paymentSubscription;
	export let showActivationConfirmation = () => {};
	export let showDeactivationConfirmation = () => {};
	export let goToProcedure = () => {};

	let infoShow = {};

	$: trialPeriod = paymentSubscription.trial_period_expired_at && moment(paymentSubscription.trial_period_expired_at).isSameOrAfter(moment());

	const statusString = subscription => {
		let statusStr = '';

		if (trialPeriod) {
			statusStr = `пробный период до ${moment(subscription.trial_period_expired_at).format('LLL')}`;
		} else if (subscription.active) {
			statusStr = 'активна';
		} else {
			statusStr = 'не активна';
		}

		return statusStr;
	};

	const paymentDateString = subscription => {
		let paymentDateStr = '';

		if (!subscription.next_payment_date && !trialPeriod) {
			paymentDateStr = 'не оплачивалась';
		} else if (subscription.next_payment_date) {
			paymentDateStr = moment(subscription.next_payment_date).format('LLL');
		}

		return paymentDateStr;
	};
</script>

<tr data-payment-subscription-id={paymentSubscription.id}>
	<td class="text-left" data-procedure-id={paymentSubscription.procedure_id}>
		{#if paymentSubscription.procedure_id}
			<span class="procedure-name" role="button" tabindex="0" on:click={() => goToProcedure(paymentSubscription.procedure_id)} on:keypress|stopPropagation>
				{paymentSubscription.procedure_name}
			</span>
		{:else}
			-
		{/if}
	</td>
	<td
		class="text-center"
		class:text-navy={paymentSubscription.active}
		class:text-danger={!paymentSubscription.active && !trialPeriod}
		class:text-warning={trialPeriod}
	>
		{statusString(paymentSubscription)}
	</td>
	<td
		class="text-center"
		class:text-navy={moment(paymentSubscription.next_payment_date).isSameOrAfter(moment())}
		class:text-danger={moment(paymentSubscription.next_payment_date).isBefore(moment())}
	>
		{paymentDateString(paymentSubscription)}
	</td>
	<td class="col-sm-3 text-center">
		{#if paymentSubscription.active}
			<button
				class="btn btn-xs btn-danger"
				disabled={!paymentSubscription.payment_compulsory || trialPeriod}
				on:click={paymentSubscription.payment_compulsory && !trialPeriod && showDeactivationConfirmation(paymentSubscription)}
			>
				Прекратить
			</button>
		{:else}
			{#if infoShow['activate'] && paymentSubscription.procedure_completed}
				<div class="order-of-repayment__popover show fade m-b-xs">Для возобновления платежей сначала активируйте процедуру.</div>
			{/if}
			<button
				class="btn btn-xs btn-primary"
				tabindex="0"
				disabled={!paymentSubscription.payment_compulsory || trialPeriod || paymentSubscription.procedure_completed}
				on:click={paymentSubscription.payment_compulsory &&
					!trialPeriod &&
					!paymentSubscription.procedure_completed &&
					showActivationConfirmation(paymentSubscription)}
				on:mouseover={() => (infoShow['activate'] = true)}
				on:mouseout={() => (infoShow['activate'] = false)}
				on:focus={() => (infoShow['activate'] = true)}
				on:blur={() => (infoShow['activate'] = true)}
			>
				Активировать
			</button>
		{/if}
	</td>
</tr>

<style>
	.order-of-repayment__popover {
		position: absolute;
		bottom: 2rem;
		left: calc(50% - 7vw);
		z-index: 9999;
		width: 14vw;
		background-color: #fff;
		border: solid 1px;
		border-radius: 0.3rem;
		box-shadow: 0 0 10px;
		display: block;
		padding: 0.7rem 1rem;
	}
	.procedure-name {
		cursor: pointer;
		text-decoration: underline;
		color: #337ab7;
	}
</style>
