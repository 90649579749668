<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveCheckbox from '~/svelte/components/ui/autosave_checkbox.svelte';
	import AutosaveTextarea from '~/svelte/components/ui/autosave_textarea.svelte';

	$: bankAccounts = ($transmitter && $transmitter.bankAccounts) || [];

	$: formFields = {
		banksProvidedAccountStatements: {
			...$commonParams,
			attribute: 'banks_provided_account_statements',
			label: 'Банки предоставили выписки по счетам',
			value: $currentData.banksProvidedAccountStatements,
			text: 'Да',
		},
		conclusionsBasedOnAnalysisOfBankStatements: {
			...$commonParams,
			attribute: 'conclusions_based_on_analysis_of_bank_statements',
			label: 'Выводы по результатам анализа выписок банков',
			value: $currentData.conclusionsBasedOnAnalysisOfBankStatements,
			placeholder: 'Доходы должника включают в себя…',
		},
	};
</script>

{#if bankAccounts.length}
	<AutosaveCheckbox {...formFields.banksProvidedAccountStatements} />
{/if}
{#if $currentData.banksProvidedAccountStatements}
	<AutosaveTextarea {...formFields.conclusionsBasedOnAnalysisOfBankStatements} />
{/if}
