<!-- @format -->
<script>
	import printJS from 'print-js';
	import SvelteTooltip from 'svelte-tooltip';
	import { fetchPost } from '~/js/utils/fetch';

	export let dctId;
	export let pdfData;
	export let isDraft;
	export let disabled;

	const printPdf = async () => {
		const response = await fetchPost(`/api/private/documents/${dctId}/print_file`, {
			pdf_data: typeof pdfData === 'function' ? pdfData() : pdfData,
		});

		printJS({ printable: response.data, type: 'pdf', base64: true, showModal: true });
	};
</script>

{#if disabled || isDraft}
	<SvelteTooltip tip="Печать. Сначала утвердите документ." right color="#e9ecef">
		<svg width="16" height="13" viewBox="0 0 20 20" style="opacity: 0.6; cursor: not-allowed" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17 5H16V0H4V5H3C2.20435 5 1.44129 5.31607 0.87868 5.87868C0.316071 6.44129 0 7.20435 0 8V15C0 15.5304 0.210714 16.0391 0.585786 16.4142C0.960859 16.7893 1.46957 17 2 17H4V20H16V17H18C18.5304 17 19.0391 16.7893 19.4142 16.4142C19.7893 16.0391 20 15.5304 20 15V8C20 7.20435 19.6839 6.44129 19.1213 5.87868C18.5587 5.31607 17.7956 5 17 5ZM6 2H14V5H6V2ZM6 18V14H14V18H6ZM17 10H13V8H17V10Z"
				fill="#676a6c"
			/>
		</svg>
	</SvelteTooltip>
{:else}
	<span class="cursor_pointer" role="button" tabindex="0" on:click={printPdf} on:keypress|stopPropagation>
		<svg width="16" height="13" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17 5H16V0H4V5H3C2.20435 5 1.44129 5.31607 0.87868 5.87868C0.316071 6.44129 0 7.20435 0 8V15C0 15.5304 0.210714 16.0391 0.585786 16.4142C0.960859 16.7893 1.46957 17 2 17H4V20H16V17H18C18.5304 17 19.0391 16.7893 19.4142 16.4142C19.7893 16.0391 20 15.5304 20 15V8C20 7.20435 19.6839 6.44129 19.1213 5.87868C18.5587 5.31607 17.7956 5 17 5ZM6 2H14V5H6V2ZM6 18V14H14V18H6ZM17 10H13V8H17V10Z"
				fill="#676a6c"
			/>
		</svg>
	</span>
{/if}
