<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatetimepicker from '~/svelte/components/ui/autosave_datetimepicker.svelte';

	$: transferringDocumentsTime = {
		...$commonParams,
		attribute: 'transferring_documents_time',
		timeOnly: true,
		label: 'Время передачи документации',
		value: $currentData && $currentData.transferringDocumentsTime,
		maskOptions: { mask: Date },
		placeholder: 'чч:мм',
	};
</script>

<AutosaveDatetimepicker {...transferringDocumentsTime} />
