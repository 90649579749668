<!-- @format -->
<script>
	import { currentData, commonParams } from '../../../stores/stores';
	import AutosaveDatepicker from '../../../../components/ui/autosave_datepicker.svelte';

	$: propertyInventoryDate = {
		...$commonParams,
		attribute: 'property_inventory_date',
		label: 'Дата описи имущества',
		value: $currentData && $currentData.propertyInventoryDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
	};
</script>

<AutosaveDatepicker {...propertyInventoryDate} />
