<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveCheckbox from '../../components/ui/autosave_checkbox.svelte';

	$: receivableWasHeldLiableForIllegalActionsInBankruptcy = {
		...$commonParams,
		attribute: 'receivable_was_held_liable_for_illegal_actions_in_bankruptcy',
		label: 'Должник привлекался к ответственности за неправомерные действия при банкротстве, преднамеренное/фиктивное банкротство',
		value: $currentData && $currentData.receivableWasHeldLiableForIllegalActionsInBankruptcy,
		text: 'Да',
	};
</script>

<AutosaveCheckbox {...receivableWasHeldLiableForIllegalActionsInBankruptcy} />
