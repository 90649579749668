<!-- @format -->
<script>
	import { options } from 'base_stores';
	import { currentData, commonParams } from '../stores/stores';
	import { onMount } from 'svelte';
	import AutosaveRadioSelect from '../../components/ui/autosave_radio_select.svelte';

	$: obtainingMethod = {
		...$commonParams,
		attribute: 'obtaining_method',
		label: 'Способ получения данных',
		value: $options.obtainingMethod && $options.obtainingMethod.find(option => option.id === $currentData.obtainingMethod),
		options: $options.obtainingMethod,
		placeholder: 'Выбрать способ получения данных',
	};

	onMount(() => {
		$options = {
			...$options,
			obtainingMethod: [
				{ id: 1, text: 'в регистрирующем органе' },
				{ id: 2, text: 'почтовым отправлением' },
				{ id: 3, text: 'в электронной форме' },
			],
		};
	});
</script>

<AutosaveRadioSelect {...obtainingMethod} />
