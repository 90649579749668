<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: bankruptPhone = {
		...$commonParams,
		attribute: 'bankrupt_phone',
		label: 'Телефон должника',
		value: $currentData && $currentData.bankruptPhone,
		placeholder: '+7 (___) ___-__-__',
		maskOptions: { mask: '+7 (000) 000-00-00' },
		modify: phone => phone && phone.replace(/\+7|\D/g, ''),
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_phone',
			resource: $procedure && $procedure.bankrupt,
			resourceProp: 'phone',
		},
	};
</script>

<AutosaveInput {...bankruptPhone} on:update />
