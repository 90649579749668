/** @format */

import { isPresent, isBlank, caseWord } from './tools';

export const daysOfWeek = [
	['Воскресенье', 'Вск'],
	['Понедельник', 'Пн'],
	['Вторник', 'Вт'],
	['Среда', 'Ср'],
	['Четверг', 'Чт'],
	['Пятница', 'Пт'],
	['Суббота', 'Сб'],
];
export const monthsOfYear = [
	['Январь', 'Янв.', 'января'],
	['Февраль', 'Фев.', 'февраля'],
	['Март', 'Мар.', 'марта'],
	['Апрель', 'Апр.', 'апреля'],
	['Май', 'Май', 'мая'],
	['Июнь', 'Июнь', 'июня'],
	['Июль', 'Июль', 'июля'],
	['Август', 'Авг.', 'августа'],
	['Сентябрь', 'Сен.', 'сентября'],
	['Октябрь', 'Окт.', 'октября'],
	['Ноябрь', 'Ноя.', 'ноября'],
	['Декабрь', 'Дек.', 'декабря'],
];

export const formatDate = (date, fmt = 'DayMonthYear', separator = '-') => {
	const dt = date instanceof Date ? date : new Date(date);

	if (dt != 'Invalid Date') {
		if (fmt == 'WWW') {
			// Понедельник

			return daysOfWeek[dt.getDay()][0];
		} else if (fmt == 'www') {
			// Пн

			return daysOfWeek[date.getDay()][1];
		} else if (fmt == 'HHMM' || fmt == 'HH:MM') {
			// 23:59
			const hours = dt.getHours();
			const minutes = dt.getMinutes();

			return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
		} else if (fmt == 'DayMonth') {
			// 23 сентября

			return `${dt.getDate()} ${monthsOfYear[dt.getMonth()][2]}`;
		} else if (fmt == 'DayMonthYear') {
			// 23 сентября 2020

			return `${dt.getDate()} ${monthsOfYear[dt.getMonth()][2]} ${dt.getFullYear()}`;
		} else if (fmt == 'DayMonthYearTime') {
			// 23 сентября 2020 15:45
			const hours = dt.getHours();
			const minutes = dt.getMinutes();

			return `${dt.getDate()} ${monthsOfYear[dt.getMonth()][2]} ${dt.getFullYear()} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
		} else if (fmt == 'YYYY-MM-DD') {
			// 2020-09-23
			const month = dt.getMonth() + 1;
			const day = dt.getDate();

			return `${dt.getFullYear()}${separator}${month < 10 ? '0' + month : month}${separator}${day < 10 ? '0' + day : day}`;
		} else if (fmt == 'DD.MM.YYYY') {
			// 12.09.2020
			const month = dt.getMonth() + 1;
			const day = dt.getDate();

			return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${dt.getFullYear()}`;
		} else {
			//

			return '';
		}
	} else {
		return '';
	}
};
export const formatTime = time => {
	if (isBlank(time)) return '';

	const [hours, minutes] = time.split(':');

	return hours + ' ' + caseWord(hours, 'час', 'часа', 'часов') + ' ' + minutes + ' ' + caseWord(minutes, 'минута', 'минуты', 'минут');
};
export const formatTimeAgo = date => {
	const dt = date instanceof Date ? date : new Date(date);
	const diff = new Date().getTime() - dt.getTime();
	let val;

	val = Math.round(diff / (1000 * 3600 * 24 * 365)); // years
	if (val > 0) return val + ' ' + caseWord(val, 'год', 'года', 'лет');
	val = Math.round(diff / (1000 * 3600 * 24 * 30)); // months
	if (val > 0) return val + ' ' + caseWord(val, 'месяц', 'месяца', 'месяцев');
	val = Math.round(diff / (1000 * 3600 * 24 * 7)); // weeks
	if (val > 0) return val + ' ' + caseWord(val, 'неделя', 'недели', 'недель');
	val = Math.round(diff / (1000 * 3600 * 24)); // days
	if (val > 0) return val + ' ' + caseWord(val, 'день', 'дня', 'дней');
	val = Math.round(diff / (1000 * 3600)); // hours
	if (val > 0) return val + ' ' + caseWord(val, 'час', 'часа', 'часов');
	val = Math.round(diff / (1000 * 60)); // minutes
	if (val > 0) return val + ' ' + caseWord(val, 'минута', 'минуты', 'минут');
	val = Math.round(diff / 1000); // seconds
	return val + ' ' + caseWord(val, 'секунда', 'секунды', 'секунд');
};
export const toDate = date => {
	const dt = date instanceof Date ? date : new Date(date);
	dt.setHours(0, 0, 0, 0);
	return dt;
};
export const today = (dt = undefined) => (dt ? toDate(dt) : toDate(new Date()));
export const isToday = date => toDate(date).getTime() == toDate(new Date()).getTime();
export const tomorrow = (day = 1) => {
	const dt = new Date();
	dt.setDate(dt.getDate() + day);
	return dt;
};
export const inayear = (years = 1) => {
	const dt = new Date();
	return new Date(dt.setYear(dt.getFullYear() + years));
};
export const addDays = (date, days) => {
	if (isPresent(date)) {
		const dt = new Date(date);
		dt.setDate(dt.getDate() + days);
		return dt;
	} else {
		return undefined;
	}
};
