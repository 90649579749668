<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { selectionСoordinates, canvasWidth, canvasHeight, pages, scale } from '~/js/global_stores/ocr';
	import { isPresent, isBlank } from 'utils/tools';

	const dispatch = createEventDispatcher();

	export let scan;
	export let withPossessionsBlocks;

	let canvasWrapper;
	let canvasWrapperPositioning;
	let canvasElements = [];

	$: $pages = isBlank(scan) ? 0 : scan.length;

	$: canvasElements = isBlank(scan) ? [] : canvasElements;

	$: {
		canvasWrapperPositioning = canvasWrapper && canvasWrapper.getBoundingClientRect();
		$canvasWidth = canvasWrapper?.clientWidth;

		if ($canvasWidth && isPresent(scan)) {
			const image = new Image();
			image.src = scan[0];
			image.onload = () => {
				$canvasHeight = ((image.naturalHeight * $canvasWidth) / image.naturalWidth).toFixed(0);
				$scale = $canvasWidth / image.naturalWidth;
			};
		}

		if ($canvasWidth && $canvasHeight && canvasElements) {
			for (let idx in canvasElements) {
				canvasElements[idx].width = $canvasWidth;
				canvasElements[idx].height = $canvasHeight;
				$selectionСoordinates[idx] = [];
			}
		}
	}

	const getCanvasData = idx => {
		const canvas = canvasElements[idx];

		return { self: canvas, positioning: canvas.getBoundingClientRect() };
	};

	const handleMouseDown = (e, idx) => {
		if (withPossessionsBlocks) {
			return;
		}

		const canvas = getCanvasData(idx);
		const ctx = canvas.self.getContext('2d');

		ctx.clearRect(0, 0, canvas.self.width, canvas.self.height);

		$selectionСoordinates[idx] = [
			(e.pageX - canvas.positioning.left).toFixed(0),
			(e.pageY - canvas.positioning.top).toFixed(0),
			(e.pageX - canvas.positioning.left).toFixed(0),
			(e.pageY - canvas.positioning.top).toFixed(0),
		];

		canvas.self.onmousemove = e => drawRect(e, idx);
		window.onmouseup = () => stopDrawRect(canvas);
	};

	const stopDrawRect = canvas => {
		canvas.self.onmousemove = null;
		window.onmouseup = null;
	};

	const drawRect = (e, idx) => {
		const canvas = getCanvasData(idx);

		if (e.pageY <= canvasWrapperPositioning.top + 16 && canvas.positioning.bottom - canvas.positioning.top - e.pageY < canvas.self.height) {
			canvasWrapper.scrollBy(0, -15);
		}

		if (e.pageY >= canvasWrapperPositioning.bottom - 16 && canvas.positioning.bottom - canvas.positioning.top - e.pageY < canvas.self.height) {
			canvasWrapper.scrollBy(0, 15);
		}

		buildRect(e, idx);
	};

	const buildRect = (e, idx) => {
		const canvas = getCanvasData(idx);
		const ctx = canvas.self.getContext('2d');

		$selectionСoordinates[idx] = $selectionСoordinates[idx] || [];
		$selectionСoordinates[idx] = [
			$selectionСoordinates[idx][0],
			$selectionСoordinates[idx][1],
			(e.pageX - canvas.positioning.left).toFixed(0),
			(e.pageY - canvas.positioning.top).toFixed(0),
		];

		if (e.pageX < canvas.positioning.left || e.pageX > canvas.positioning.right || e.pageY < canvas.positioning.top || e.pageY > canvas.positioning.bottom) {
			if (e.pageX < canvas.positioning.left) {
				$selectionСoordinates[idx][2] = 0;
			}
			if (e.pageX > canvas.positioning.right) {
				$selectionСoordinates[idx][2] = canvas.self.width;
			}
			if (e.pageY < canvas.positioning.top) {
				$selectionСoordinates[idx][3] = 0;
			}
			if (e.pageY > canvas.positioning.bottom) {
				$selectionСoordinates[idx][3] = canvas.self.height;
			}
		}

		ctx.fillStyle = 'rgb(26, 179, 148, .07)';
		ctx.strokeStyle = '#1ab394';
		ctx.clearRect(0, 0, canvas.self.width, canvas.self.height);
		ctx.fillRect(
			$selectionСoordinates[idx][0],
			$selectionСoordinates[idx][1],
			$selectionСoordinates[idx][2] - $selectionСoordinates[idx][0],
			$selectionСoordinates[idx][3] - $selectionСoordinates[idx][1],
		);
		ctx.strokeRect(
			$selectionСoordinates[idx][0],
			$selectionСoordinates[idx][1],
			$selectionСoordinates[idx][2] - $selectionСoordinates[idx][0],
			$selectionСoordinates[idx][3] - $selectionСoordinates[idx][1],
		);
	};

	const checkAndLoadNextBatch = () => {
		if (canvasElements[scan.length - 1].getBoundingClientRect().bottom - canvasWrapperPositioning.bottom < $canvasHeight) {
			dispatch('needNextBatch');
		}
	};
</script>

{#if isPresent(scan)}
	<div
		bind:this={canvasWrapper}
		class="scrollable w-100 h-100"
		role="button"
		tabindex="0"
		on:contextmenu={() => {
			return false;
		}}
		on:scroll={checkAndLoadNextBatch}
	>
		{#each scan as imgUrl, idx}
			<canvas
				bind:this={canvasElements[idx]}
				class:canvas={!withPossessionsBlocks}
				style={`background: url("${imgUrl}") no-repeat; background-size: cover; width: ${canvasWrapper?.clientWidth}px;`}
				on:mousedown={e => handleMouseDown(e, idx)}
			>
			</canvas>
		{/each}
	</div>
{/if}

<style>
	.scrollable {
		overflow-y: auto;
		overflow-x: hidden;
	}
	.canvas {
		position: relative;
		cursor: crosshair;
	}
</style>
