<!-- @format -->
<script>
	import { autosaveStatus, procedure, transmitter, fieldsReady, dctReady } from 'base_stores';
	import { commonParams } from '~/svelte/dcts/stores/stores';
	import { fetchGet, fetchPut } from 'utils/fetch';
	import { showModal } from '~/svelte/components/modal.svelte';
	import RadioSelect from '~/svelte/components/ui/radio_select.svelte';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte';

	export let fieldId;

	let forceSave = false;
	let declarerChoice;
	let newDeclarerId = null;
	let counterpartyOptions = [];

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = ($procedure && $procedure.id) || null;
	$: declarer = $procedure && $procedure.declarer;
	$: declarerId = (declarer && declarer.id) || null;

	$: if (procedureId) {
		refreshOptions();
	}

	$: if ($procedure && !declarerChoice) {
		declarerChoice = declarer ? 'creditor' : 'bankrupt';
	}

	$: if (procedureId && declarerChoice === 'bankrupt') {
		newDeclarerId = null;
		nullifyDeclarer();
		changeDeclarer();
	} else {
		forceSave = false;
	}

	$: if ($transmitter && $transmitter.counterparties) {
		const counterpartiesLen = $transmitter.counterparties.length;
		let newCounterparty = counterpartiesLen && $transmitter.counterparties[0];

		if (newCounterparty) {
			setCounterpartyOption(newCounterparty);
		} else {
			refreshOptions();
			nullifyDeclarer();
			forceSave = false;
		}
	}

	const setCounterpartyOption = counterparty => {
		if (counterpartyOptions && !counterpartyOptions.find(option => option.id == counterparty.id)) {
			counterpartyOptions = [...counterpartyOptions, { id: counterparty.id, text: counterparty.name }];
			newDeclarerId = counterparty.id;
			changeDeclarer();
		}

		if (declarerId === counterparty.id) {
			$transmitter.counterparties = null;
		}
	};

	const getOptions = async () => {
		const response = await fetchGet('/api/private/counterparties/get_options_for_roles', { procedure_id: procedureId, roles: 'all' });
		return [{ id: null, text: '' }, { id: 'new', text: 'Добавить заявителя' }, ...response.options];
	};

	const refreshOptions = async () => (counterpartyOptions = await getOptions());

	const changeDeclarer = async () => {
		$autosaveStatus = null;

		try {
			const response = await fetchPut(`/api/private/procedures/${procedureId}/change_declarer`, { declarer_id: newDeclarerId });
			if (declarerChoice === 'bankrupt') {
				// обеспечивает изменения в transmitter для запуска обновления превью
				$transmitter.dct.substitutions['procedure_declarer_id'] = null;
				$transmitter.dct.substitutions['procedure_declarer'] = null;
			}
			$procedure = response.procedure;
			if ($dctReady) {
				$autosaveStatus = 'saved';
			}
		} catch (error) {
			$autosaveStatus = 'not_saved';
			console.error(error);
			throw error;
		}
	};

	const nullifyDeclarer = () => {
		$procedure.declarer = null;
		declarerChoice = 'bankrupt';
	};

	const blockHandleChange = valueId => {
		newDeclarerId = valueId;
		if (valueId && valueId !== 'new') {
			changeDeclarer();
		}
		return valueId === 'new';
	};

	const handleBlocking = () => {
		if (newDeclarerId === 'new') {
			declarerId = null;
			$procedure.declarer = null;
			showModal('form-declarer-modal');
		} else if (!newDeclarerId) {
			declarerChoice = 'bankrupt';
		} else {
			changeDeclarer();
		}
	};

	$: declarerChoiceField = {
		disabled: $commonParams.disabled,
		model: 'procedure',
		attribute: 'declarer_choice',
		options: [
			{ id: 'bankrupt', text: 'Должник' },
			{ id: 'creditor', text: 'Кредитор' },
		],
		label: 'Заявителем является',
	};

	$: procedureDeclarerField = {
		...$commonParams,
		attribute: 'procedure_declarer_id',
		label: 'Кредитор',
		options: counterpartyOptions,
		value: declarer,
		placeholder: 'Выбрать заявителя',
		hintData: declarerId ? { freeMessage: 'AI заполнил из Контрагенты' } : null,
	};
</script>

<RadioSelect {...declarerChoiceField} bind:value={declarerChoice} />
{#if declarerChoice === 'creditor'}
	<AutosaveSelect2Search {...procedureDeclarerField} {blockHandleChange} {handleBlocking} />
{/if}
<CounterpartyModal counterpartyId={declarerId} fixedRoles={['declarer']} />
