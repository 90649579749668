<!-- @format -->
<script>
	import { procedure } from 'base_stores';
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveCheckbox from '~/svelte/components/ui/autosave_checkbox.svelte';

	$: transferSalaryToMainAccount = {
		...$commonParams,
		attribute: 'transfer_salary_to_main_account',
		label: 'Переводить зарплату на основной счёт',
		value: $currentData?.transferSalaryToMainAccount,
		text: 'Да',
	};
</script>

{#if $procedure.phase == 'property_realization'}
	<AutosaveCheckbox {...transferSalaryToMainAccount} on:update />
{/if}
