/** @format */
import App from '~/svelte/profile/profile.svelte';
import { initializeData } from './tools/initialize_data';

document.addEventListener('DOMContentLoaded', () => {
	const target = document.getElementById('profile');

	if (!target) {
		return;
	}

	initializeData(target.dataset);
	window.app = new App({
		target: target,
		props: JSON.parse(target.dataset.user),
	});
});
