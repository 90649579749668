<!-- @format -->
<script>
	import { autosaveStatus, transmitter as transmitter0 } from 'base_stores';
	import { fetchGet, fetchPost, fetchPut } from 'utils/fetch';
	import { validate } from 'tools/validators';
	import { randomString } from 'utils/tools';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';

	export let attribute;
	export let kind = 'address';
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let label;
	export let transmitter;
	export let skipVerificationRequired = true;
	export let value = undefined;
	//export let withoutIndex = false // пока не удалять, указывает отдать адрес с индексом
	export let required = false;
	export let disabled = false;
	export let forceSave = false;
	export let placeholder;
	export let hintData;
	export let previousValue = null;
	export let additionalParams = {};
	export let saveAutomatically = true;

	let inputField;
	let dataErrors;
	let suggestions = [];
	let countdown = 1;

	const inputId = `dadate-input-${model}-${attribute}`; //-${randomString()}`

	$: method = !!id ? fetchPut : fetchPost;
	$: if (!!id) {
		url = `${url}/${id}`;
	}
	$: isSuccess = newValue && !dataErrors;
	$: newValue = value || null;
	$: sentValue = newValue && (kind === 'okved' ? newValue.split(' ')[0] : newValue);
	$: if (forceSave && previousValue !== value) {
		handleChange();
	}
	$: if (!previousValue && countdown > 0 && value === undefined) {
		countdown = countdown - 1;
	}
	$: if (!previousValue && countdown === 0 && value !== undefined) {
		previousValue = value;
		countdown = countdown - 1;
	}
	$: fetchGet('/api/private/dadate_client', { query: sentValue, kind: kind })
		.then(response => (suggestions = response.suggestions))
		.catch(error => console.error('Ошибка при выполнении запроса:', error));

	const handleChange = () => {
		$autosaveStatus = null;

		if (previousValue !== value) {
			dataErrors = validate(model, attribute, newValue, skipVerificationRequired);

			if (!dataErrors) {
				let params = { [model]: { [attribute]: newValue }, [`${childModel}_id`]: childId, ...additionalParams };

				method(url, params)
					.then(response => {
						if (!forceSave && JSON.stringify(response) !== '{}') {
							$autosaveStatus = 'saved';
						}
						transmitter = { ...transmitter, ...response };
						$transmitter0 = { ...$transmitter0, ...response };
						previousValue = value;
					})
					.catch(_errors => {
						inputField.focus();
						$autosaveStatus = 'not_saved';
					});
				return;
			}

			$autosaveStatus = 'not_saved';
			if (transmitter && transmitter[model]) {
				transmitter[model][attribute] = value;
			}
			$transmitter0[model] = { ...$transmitter0[model], [attribute]: value };
		}
	};
</script>

<div class="form-group row" class:has-error={dataErrors} class:has-success={isSuccess}>
	<label for={inputId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<input
			{placeholder}
			id={inputId}
			name={inputId}
			list={`dadate-suggestions-${inputId}`}
			type="text"
			class:required-border={required}
			class:text-disabled={disabled}
			class="form-control"
			bind:value
			bind:this={inputField}
			{disabled}
			on:blur={saveAutomatically && handleChange}
			autocomplete="off"
		/>
		<datalist id={`dadate-suggestions-${inputId}`}>
			{#each suggestions as suggestion}
				<option value={[suggestion.name, suggestion.hint].filter(i => i).join(' ')}></option>
			{/each}
		</datalist>
		{#if dataErrors}
			<InputErrorsString errors={dataErrors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>
