<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { isPresent, isBlank, keyPressed, debounce, toArray } from 'utils/tools';
	import searchBy from './search_by.js';
	import { methods } from './config';

	export let method;
	export let value;
	export let id;
	export let placeholder;
	export let disabled;
	export let disabledtext;
	export let requiredborder;
	export let inputField;

	const dispatch = createEventDispatcher();
	const config = methods[method];

	let results;
	let errors;
	let highlighted;
	let selected;
	let inputWrapper;
	let optionsCssClass;

	const load = () =>
		debounce(() => {
			selected = null;
			if (!!method && !disabled && isPresent(inputField.value)) {
				searchBy(method, config.search_value(inputField.value))
					.then(result => (results = result))
					.catch(error => (errors = error));
			} else {
				reset();
			}
		}, 500);

	const reset = () => {
		if (isPresent(results)) results = [];
		if (isPresent(errors)) errors = null;
	};

	const moveToTheNextItem = dir => {
		if (isBlank(results)) return;

		const curIndex = results.findIndex(item => highlighted == item);

		if (curIndex == -1) {
			if (dir == 'down') {
				selected = results[0];
			} else {
				selected = results[results.length - 1];
			}
		} else {
			if (dir == 'down') {
				selected = results[curIndex < results.length - 1 ? curIndex + 1 : 0];
			} else {
				selected = results[curIndex > 0 ? curIndex - 1 : results.length - 1];
			}
		}
		inputField.value = config.input_value(selected);
		highlighted = selected;
	};

	const handleKeydown = e => {
		const key = keyPressed(e);

		if (key.is_symbol || key.is_digit || key.is_del || key.is_backspace || key.is_space) {
			load();
		} else if (key.is_escape) {
			reset();
		} else if (key.is_tab) {
			reset();
		} else if (key.is_enter) {
			handleSelectItemByEnter(highlighted);
		} else if (key.is_down) {
			moveToTheNextItem('down');
		} else if (key.is_up) {
			moveToTheNextItem('up');
		}
	};

	const handleSelectItemByMouse = item => {
		selected = item;
		inputField.dispatchEvent(new Event('change'));
		inputField.focus();
		reset();
	};

	const handleBlur = () => {
		dispatch('blur', value);
	};

	const handleSelectItemByEnter = item => {
		selected = item;
		reset();
	};

	const handleChange = () => {
		debounce(() => {
			value = isPresent(selected) ? config.input_value(selected) : inputField.value;
			dispatch('change', isPresent(selected) ? config.return_value(selected) : value);
		}, 100);
	};

	const handleClick = () => {
		isBlank(results) && load();
	};

	const handleOutsideClick = () => reset();

	// $: value = value === undefined ? '' : value
	// $: if (id == "read_address" ) console.log('read_address', value)

	$: h = document.documentElement.clientHeight;
	$: p = inputWrapper && inputWrapper.getBoundingClientRect();
	$: heigth = p && p.height;
	$: top = p && p.top;
	$: bottom = h - top - heigth;
	$: optionsCssClass = top > bottom ? 'options-up' : 'options-down';
</script>

<svelte:body on:click={handleOutsideClick} />

<div class="input-wrapper" role="button" tabindex="0" bind:this={inputWrapper} on:click|stopPropagation={handleClick} on:keypress|stopPropagation>
	<input
		{id}
		{placeholder}
		{disabled}
		{value}
		type="text"
		class="form-control"
		class:required-border={requiredborder}
		class:text-disabled={disabledtext}
		bind:this={inputField}
		on:keydown={handleKeydown}
		on:change={handleChange}
		on:blur={handleBlur}
		autocomplete="off"
	/>

	{#if isPresent(results) || isPresent(errors)}
		<div class={optionsCssClass} style="z-index: 11;">
			{#each toArray(results) as item}
				<div
					class="item"
					class:selected={highlighted == item}
					role="button"
					tabindex="0"
					on:click|preventDefault|stopPropagation={() => handleSelectItemByMouse(item)}
					on:mouseenter={() => (highlighted = item)}
					on:mouseleave={() => (highlighted = null)}
					on:keypress|stopPropagation
				>
					{config.display(item)}
				</div>
			{/each}
			{#if errors}
				<div class="item">{errors}</div>
			{/if}
		</div>
	{/if}
</div>

<style>
	.input-wrapper {
		position: relative;
		width: 100%;
	}
	input {
		width: 100%;
		padding: 6px 12px;
		box-sizing: border-box;
	}
	.options-up {
		position: absolute;
		left: 1px;
		bottom: 35px;
		width: calc(100% - 2px);
		background: #fff;
		overflow-y: auto;
		box-shadow: 0 0 3px #aaa;
		box-sizing: border-box;
	}
	.options-down {
		position: absolute;
		left: 1px;
		top: 35px;
		width: calc(100% - 2px);
		background: #fff;
		overflow-y: auto;
		box-shadow: 0 0 3px #aaa;
		box-sizing: border-box;
	}
	.item {
		padding: 6px 12px;
	}
	.item.selected {
		background-color: lightgray;
	}
</style>
