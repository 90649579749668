<!-- @format -->
<script>
	import InputErrorsString from './input_errors_string.svelte';
	import imask from 'imask';
	import { toString } from 'utils/tools';
	import percentOptions from '~/svelte/_shared/percent_options';
	import Hint from './hint.svelte';

	export let attribute;
	export let model;
	export let label;
	export let value;
	export let placeholder = '';
	export let required = false;
	export let disabled = false;
	export let errors;
	export let hintData = {};
	export let scale = 2;

	let ref;
	let imaskInstance;
	let currentValue;

	const imaskTemplate = node => {
		imaskInstance = imask(node, { ...percentOptions, scale });
		imaskInstance.on('complete', () => (currentValue = value = imaskInstance.unmaskedValue));

		return {
			destroy() {
				imaskInstance.destroy();
			},
		};
	};

	$: if (imaskInstance && value != currentValue) imaskInstance.value = toString(value).replace(/\./, ',');

	$: isSuccess = value && !errors;
	$: attrId = `input-currency-${model}-${attribute}`;
</script>

<div class="form-group row" class:has-error={errors} class:has-success={isSuccess}>
	<label for={attrId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<div class="input_width">
			<input
				id={attrId}
				type="text"
				class:required-border={required}
				class:text-disabled={disabled}
				class="form-control text-right bg-white"
				{placeholder}
				bind:this={ref}
				use:imaskTemplate
				{disabled}
				on:focus={() => ref.select()}
				autocomplete="off"
			/>
			{#if errors}
				<InputErrorsString {errors} />
			{/if}
		</div>
		{#if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>

<style>
	.input_width {
		position: relative;
		width: 147px;
	}
</style>
