<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveCheckbox from '../../../components/ui/autosave_checkbox.svelte';

	$: incomeAdditionalSourceOfBankrupt = {
		...$commonParams,
		attribute: 'income_additional_source_of_bankrupt',
		label: 'Предвидится доп. источник дохода должника',
		value: $currentData && $currentData.incomeAdditionalSourceOfBankrupt,
		text: 'Да',
	};
</script>

<AutosaveCheckbox {...incomeAdditionalSourceOfBankrupt} on:update />
