<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { procedure } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { extractFilename } from 'utils/tools';
	import { fetchGet } from 'utils/fetch_helpers';
	import fileDownload from 'js-file-download';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import printJS from 'print-js';
	import DownloadIcon from '~/svelte/_shared/download_icon.svelte';
	import PrintIcon from '~/svelte/_shared/print_icon.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import IconPdfFile from '~/svelte/_icons_imgs/pdf_file.svelte';
	import IconDocFile from '~/svelte/_icons_imgs/doc_file.svelte';
	import WaitingFullScreen from '~/svelte/_components/waiting/full_screen.svelte';
	import moment from 'moment';

	export let relevanceDate;
	export let openCreditorsRequirementModal = () => {};
	export let openPayoutModal = () => {};

	const requirementsReestrFields = {
		attribute: 'date',
		model: 'requirements_reestr',
		label: 'Дата РТК',
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		disabled: !$procedure || !$procedureAvailable,
	};

	let resultObserver;
	let offReestr = false;
	let waiting = false;
	let printFile = false;
	let jid;

	$: if (relevanceDate) {
		localStorage.setItem('relevanceDate', JSON.stringify({ value: relevanceDate, expire: moment().endOf('day') }));
	}

	const printPdf = () => {
		printFile = true;
		downloadFile('pdf');
	};

	const startWaiting = () => {
		hideModal('download-requirements_reestr-modal');
		hideModal('print-requirements_reestr-modal');
		waiting = true;
	};

	const interruptObserve = () => {
		clearInterval(resultObserver);
		waiting = false;
		printFile = false;
	};

	const downloadFile = ext => {
		let response;
		let accept = 'application/json';

		startWaiting();

		resultObserver = setInterval(async () => {
			try {
				const requestData = {
					file_ext: ext,
					off_reestr: offReestr,
					date: relevanceDate,
					headers: { Accept: accept },
					response_type: accept === 'application/json' ? 'json' : 'blob',
				};

				if (jid) {
					requestData.jid = jid;
				}

				response = await fetchGet('/api/private/requirements_reestr/download_file', requestData);
				jid = response.jid;

				if (response.status === 'complete') {
					accept = `application/${ext}`
				} else if (accept === `application/${ext}`) {
					response.blob().then(blob => {
						if (printFile) {
							const file = new Blob([blob], { type: "application/pdf" })
							printJS(URL.createObjectURL(file))
						} else {
							fileDownload(blob, extractFilename(response));
						}
						interruptObserve();
					});
				}
			} catch (error) {
				interruptObserve();
			}
		}, 2000);
	};

	const handleCancel = () => {
		interruptObserve();
	};

	onMount(() => {
		let savedRelevanceDate = localStorage.getItem('relevanceDate');

		if (savedRelevanceDate) {
			savedRelevanceDate = JSON.parse(savedRelevanceDate);
			relevanceDate = moment().isAfter(savedRelevanceDate.expire) ? moment().format('YYYY-MM-DD') : savedRelevanceDate.value;
		} else {
			relevanceDate = moment().format('YYYY-MM-DD');
		}
	});
</script>

<div class="creditors-requirements-dashboard d-flex justify-content-between m-b-sm">
	<ul class="nav navbar-top-links tools-bar">
		<li>
			<button
				class="btn btn-sm btn-warning float-left mb-1 ml-2 m-sm-0 mr-sm-2"
				disabled={!$procedure || !$procedureAvailable}
				on:click={!!$procedure && $procedureAvailable && openCreditorsRequirementModal(null)}
			>
				+ Требование
			</button>
		</li>
		<li>
			<button
				class="btn btn-sm btn-danger float-left m-r-sm ml-2 ml-sm-0"
				disabled={!$procedure || !$procedureAvailable}
				on:click={!!$procedure && $procedureAvailable && openPayoutModal()}
			>
				+ Платеж
			</button>
		</li>
	</ul>
	<ul class="nav navbar-top-links tools-bar flex-nowrap">
		<li class="m-l-sm requirements-reestr-date flex-grow-slot">
			<Datepicker {...requirementsReestrFields} bind:value={relevanceDate} />
		</li>
		<li class="ml-4">
			{#if !$procedure || !$procedureAvailable}
				<DownloadIcon />
			{:else}
				<span
					class="file-download"
					disabled={!$procedure || !$procedureAvailable}
					role="button"
					tabindex="0"
					on:click={!!$procedure && $procedureAvailable && (() => showModal('download-requirements_reestr-modal'))}
					on:keypress|stopPropagation
				>
					<DownloadIcon />
				</span>
			{/if}
		</li>
		<li class="ml-3 mr-2">
			{#if !$procedure || !$procedureAvailable}
				<PrintIcon />
			{:else}
				<span
					class="pdf-print"
					disabled={!$procedure || !$procedureAvailable}
					role="button"
					tabindex="0"
					on:click={!!$procedure && $procedureAvailable && (() => showModal('print-requirements_reestr-modal'))}
					on:keypress|stopPropagation
				>
					<PrintIcon />
				</span>
			{/if}
		</li>
	</ul>
</div>
<Modal modalId="print-requirements_reestr-modal" size="small">
	<div slot="body">
		<label class="d-flex">
			<input type="radio" class="align-self-center m-r-sm" bind:group={offReestr} value={false} />
			Реестровые
		</label>
		<label class="d-flex">
			<input type="radio" class="align-self-center m-r-sm" bind:group={offReestr} value={true} />
			За реестром
		</label>
	</div>
	<div slot="footer" class="d-flex justify-content-around">
		<button class="pdf-download btn btn-primary btn-outline" on:click={() => printPdf()}> Печатать </button>
	</div>
</Modal>
<Modal modalId="download-requirements_reestr-modal" size="small">
	<div slot="body">
		<label class="d-flex">
			<input type="radio" class="align-self-center m-r-sm" bind:group={offReestr} value={false} />
			Реестровые
		</label>
		<label class="d-flex">
			<input type="radio" class="align-self-center m-r-sm" bind:group={offReestr} value={true} />
			За реестром
		</label>
	</div>
	<div slot="footer" class="d-flex justify-content-around">
		<span class="docx-download btn btn-primary btn-outline" role="button" tabindex="0" on:click={() => downloadFile('pdf')} on:keypress|stopPropagation>
			<IconPdfFile />
			<span class="m-l-xs">pdf</span>
		</span>
		<span class="docx-download btn btn-primary btn-outline" role="button" tabindex="0" on:click={() => downloadFile('docx')} on:keypress|stopPropagation>
			<IconDocFile />
			<span>docx</span>
		</span>
		<span class="docx-download btn btn-primary btn-outline" role="button" tabindex="0" on:click={() => downloadFile('doc')} on:keypress|stopPropagation>
			<IconDocFile />
			<span>doc</span>
		</span>
	</div>
</Modal>
<WaitingFullScreen visible={waiting} on:canceled={handleCancel} />

<style>
	.creditors-requirements-dashboard {
		width: 70%;
	}
	.file-download,
	.pdf-print {
		cursor: pointer;
	}
	.flex-grow-slot {
		justify-content: flex-end;
	}
	.requirements-reestr-date {
		margin-bottom: -1rem;
	}
	@media (max-width: 576px) {
		.navbar-top-links li {
			align-self: end;
		}
	}

	:global(#datepicker-requirements_reestr-date.datetimepicker) {
		width: 165px !important;
	}
</style>
