<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveTextarea from '~/svelte/components/ui/autosave_textarea.svelte';

	$: hasFictitiousBankruptcySigns = $currentData && $currentData.hasFictitiousBankruptcySigns;

	$: fictitiousBankruptcySigns = {
		...$commonParams,
		attribute: 'fictitious_bankruptcy_signs',
		label: 'Признаки фиктивного банкротства',
		value: $currentData && $currentData.fictitiousBankruptcySigns,
		placeholder: 'В ходе процедуры выявлены сделки, направленные на вывод активов Должника…',
	};
</script>

{#if hasFictitiousBankruptcySigns}
	<AutosaveTextarea {...fictitiousBankruptcySigns} />
{/if}
