<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import DadataPassportDepartment from '~/svelte/components/ui/autosave_dadata_input_passport_department.svelte';

	$: procedureId = $procedure && $procedure.id;
	//$: bankrupt = $procedure && $procedure.bankrupt

	$: bankruptPassportDepartment = {
		...$commonParams,
		attribute: 'bankrupt_passport_department',
		label: 'Кем выдан паспорт должника',
		value: show ? $currentData.bankruptPassportDepartment : null,
		placeholder: 'Московским РОВД',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_passport_department',
			//resource: bankrupt && bankrupt.person,
			resource: $procedure && $procedure.bankrupt,
			resourceProp: 'passport_department',
		},
	};

	$: show = $dct.kind === 'documentation_acceptance_and_transfer_act' ? $currentData.transferringDocumentsParty === 'bankrupt' : true;
</script>

{#if show}
	<DadataPassportDepartment {...bankruptPassportDepartment} on:update />
{/if}
