<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('bankruptFullNameReorg');

	$: if ($dct.form_fields.includes('bankruptFullNameReorg')) {
		$requiredFieldsFilled.bankruptFullNameReorg = !!bankruptFullNameReorg.value || !$currentData.bankruptWasReorg;
	}

	$: bankruptFullNameReorg = {
		required: $currentData.bankruptWasReorg,
		...$commonParams,
		attribute: 'bankrupt_full_name_reorg',
		label: 'Полное наименование реорганизованного ЮЛ',
		value: $currentData.bankruptFullNameReorg,
		placeholder: 'Общество с ограниченной ответственностью «Компания»',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_full_name_reorg',
			resource: $procedure && $procedure.bankrupt.reorg,
			resourceProp: 'full_name',
		},
	};
</script>

{#if $currentData.bankruptWasReorg}
	<AutosaveInput {...bankruptFullNameReorg} on:update />
{/if}
