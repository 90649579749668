<!-- @format -->
<script>
	import { procedure, transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch';
	import Header from '../components/header.svelte';
	import { showModal } from '../components/modal.svelte';
	import ComplaintModal from './_modal.svelte';
	import moment from 'moment';

	const tableTitles = [
		'Заявитель',
		'Содержание жалобы',
		'Статья',
		'Кто рассмотрел и принял решение',
		'Дата рассмотрения',
		'Документ по итогам рассмотрения',
		'Решение',
		'Пересмотр решения',
	];

	let procedureId = null;
	let complaintId = null;
	let noComplainerSelected = true;
	let mounted = false;

	$: complaints = ($transmitter && $transmitter.complaints) || complaints || [];

	const openComplaintModalForm = id => {
		complaintId = id;
		showModal('form-complaint-modal');
	};

	onMount(() => {
		procedureId = $procedure && $procedure.id;

		fetchGet('/api/private/complaints').then(response => {
			$transmitter = { ...$transmitter, complaints: response.complaints };
			mounted = true;
		});
	});
</script>

<Header><span slot="name">Жалобы</span></Header>
<div class="m-b-lg mb-0">
	<button
		class="btn btn-sm btn-warning m-r-sm m-b-sm ml-2 ml-sm-0"
		disabled={!$procedureAvailable || !procedureId}
		on:click={procedureId && $procedureAvailable && openComplaintModalForm(null)}
	>
		+ Жалоба
	</button>
</div>
<div class="m-b-lg overlay p-1 footer-margin" style="margin-bottom: 5%">
	<table class="table table-hover table-mobile m-0 border-collapse_separate">
		<thead class="thead-light">
			<tr>
				{#each tableTitles as title}
					<th class="text-center p-w-xs">
						{title}
					</th>
				{/each}
			</tr>
		</thead>
		{#if complaints}
			<tbody class="text-center">
				{#each complaints as complaint}
					<tr class="cursor_pointer" on:click={() => openComplaintModalForm(complaint.id)}>
						<td>{complaint.counterparty_name || complaint.complainer || ''}</td>
						<td>{complaint.content || ''}</td>
						<td>{complaint.article || ''}</td>
						<td>{complaint.decisionmaker || ''}</td>
						<td class="text-center">{complaint.consideration_date ? moment(complaint.consideration_date).format('DD.MM.YYYY') : ''}</td>
						<td>{complaint.final_document || ''}</td>
						<td>{complaint.decision || ''}</td>
						<td>{complaint.decision_revision || ''}</td>
					</tr>
				{/each}
				{#if mounted && complaints.length == 0}
					<tr class="no-data"><td class="text-secondary" colspan={tableTitles.length}>Нет данных</td></tr>
				{/if}
				{#if !mounted}
					<tr><td class="text-secondary" colspan={tableTitles.length}><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
				{/if}
			</tbody>
		{/if}
	</table>
</div>

<ComplaintModal {complaintId} bind:noComplainerSelected />
