<!-- @format -->
<script>
	import { createEventDispatcher, tick } from 'svelte';
	import { isPresent, isArray, randomString } from 'utils/tools';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import { transmitter } from 'base_stores';
	import SettingForm from './_form.svelte';

	const dispatch = createEventDispatcher();

	export let index = 0;

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};
	export const editItem = id => {
		formOptions = { item: { id }, method: 'edit' };
		showModal(modalId);
	};
	export const showItem = id => {
		formOptions = { item: { id }, method: 'show' };
		showModal(modalId);
	};

	const modalId = 'modal-' + randomString();

	let formOptions = {};
	let form;
	let formCompleted = false;

	const formSubmit = () =>
		form.submitForm().then(result => {
			if (isPresent(result)) {
				// update transmitter
				if (isArray($transmitter.settings)) {
					const arr = [...$transmitter.settings].filter(row => row.id != result.item.id).concat([result.item]);
					$transmitter.settings = [...arr];
				}
				dispatch(formOptions.method == 'edit' ? 'update' : 'create', result);
				return result;
			}
		});

	const formDelete = () =>
		form.deleteItem().then(id => {
			if (isArray($transmitter.settings)) {
				$transmitter.settings = $transmitter.settings.filter(row => row.id != id);
			}
			dispatch('delete', id);
			return id;
		});

	const formDuplicate = () => {
		const { id, ...rest } = form.getItem();
		formOptions = { item: { ...rest }, duplicateMode: true, method: 'new' };
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};
</script>

<Modal
	{modalId}
	submitButtonDisabled={formOptions.method == 'show' || !formCompleted}
	submitButtonAction={formSubmit}
	deleteIconDisabled={formOptions.method == 'new'}
	deleteButtonAction={formDelete}
	deleteConfirmMessage="Вы действительно хотите удалить доступ к процедурам?"
	showCopyAndSaveButton={formOptions.method == 'edit'}
	copyAndSaveButtonAction={formDuplicate}
	form={true}
	{index}
	size="large"
>
	<h2 slot="header">Карточка доступа к процедурам</h2>
	<div slot="body">
		<SettingForm bind:this={form} bind:isCompleted={formCompleted} {formOptions} />
	</div>
</Modal>
