<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('arbitrManagerBirthPlace');

	$: arbitrManagerBirthPlace = {
		...$commonParams,
		attribute: 'arbitr_manager_birth_place',
		label: 'Место рождения АУ',
		value: $currentData && $currentData.arbitrManagerBirthPlace,
		placeholder: 'г. Москва',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_birth_place',
			resource: arbitrManager && arbitrManager.person,
			resourceProp: 'birth_place',
		},
	};
</script>

{#if !arbitrManagerBirthPlace.hidden}
	<AutosaveInput {...arbitrManagerBirthPlace} on:update />
{/if}
