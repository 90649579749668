<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInput from '../../components/ui/autosave_input.svelte';

	$: numberCreditorsIncludedInRequirementsReestrForFirstMeeting = {
		...$commonParams,
		attribute: 'number_creditors_included_in_requirements_reestr_for_first_meeting',
		label: 'Количество кредиторов, включенных в РТК на дату 1-го собрания',
		value: $currentData && $currentData.numberCreditorsIncludedInRequirementsReestrForFirstMeeting,
		placeholder: '5',
	};
</script>

<AutosaveInput {...numberCreditorsIncludedInRequirementsReestrForFirstMeeting} />
