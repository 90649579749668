<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../../../stores/stores';
	import AutosaveDatepicker from '../../../../../components/ui/autosave_datepicker.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: procedureInsurancePolicyDate = {
		...$commonParams,
		attribute: 'procedure_insurance_policy_date',
		label: 'Дата полиса по доп. страховке АУ',
		value: $currentData && $currentData.procedureInsurancePolicyDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.procedure_insurance_policy_date',
			resource: $procedure,
			resourceProp: 'insurance_policy_date',
		},
	};
</script>

<AutosaveDatepicker {...procedureInsurancePolicyDate} />
