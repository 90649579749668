/** @format */

import { get } from 'svelte/store';
import { transmitter, options, bankruptType } from 'base_stores';
import Model from 'models/Model';
import { specialistsApi } from 'tools/service_api';
import { currencyFormat } from 'utils/formats';
import moment from 'moment';

const titles = {
	Person: [
		'Вид специалиста',
		'Название или ФИО специалиста',
		'Дата определения суда',
		'Договор',
		'Дата договора',
		'Сумма (рублей)',
		'Источник оплаты',
		'Периодичность оплаты',
		'Дата начала / окончания<br/>начислений по договору',
	],
	Organization: [
		'Вид специалиста',
		'Название или ФИО специалиста',
		'Договор',
		'Дата договора',
		'Сведения об аккредитации',
		'Сумма (рублей)',
		'Источник оплаты',
		'Периодичность оплаты',
		'Дата начала / окончания<br/>начислений по договору',
	],
	default: [
		'Вид специалиста',
		'Название или ФИО специалиста',
		'Договор',
		'Дата договора',
		'Сумма (рублей)',
		'Источник оплаты',
		'Периодичность оплаты',
		'Дата начала / окончания<br/>начислений по договору',
	],
};

export default class Specialist extends Model {
	constructor(json) {
		super(json);
	}

	// getters

	get f_id() {
		return this.id;
	}

	get f_kind() {
		const specialistKind = get(options).specialistKind?.find(kind => kind.id == this.kind_id);

		return specialistKind?.text || '';
	}

	get f_name() {
		return this.name || '';
	}

	get f_court_decision_date() {
		return this.court_decision_date ? moment(this.court_decision_date).format('DD.MM.YYYY') : '';
	}

	get f_contract() {
		return this.contract || '';
	}

	get f_contract_date() {
		return this.contract_date ? moment(this.contract_date).format('DD.MM.YYYY') : '';
	}

	get f_contract_accrual_start_date() {
		return this.contract_accrual_start_date ? moment(this.contract_accrual_start_date).format('DD.MM.YYYY') : '';
	}

	get f_contract_accrual_end_date() {
		return this.contract_accrual_end_date ? moment(this.contract_accrual_end_date).format('DD.MM.YYYY') : '';
	}

	get f_accreditation() {
		return this.accreditation || '';
	}

	get f_payment() {
		return this.payment ? currencyFormat(this.payment) : '';
	}

	get f_payment_source() {
		const source = get(options).paymentSource?.find(paymentSource => paymentSource.id == this.payment_source);

		return source.text || '';
	}

	get f_payment_frequency() {
		const frequency = get(options).paymentFrequency?.find(paymentFrequency => paymentFrequency.id == this.payment_frequency);

		return frequency.text || '';
	}

	// class methods

	static subStoreName() {
		return 'specialists';
	}

	static new() {
		return new this({
			kind_id: null,
			kind: null,
			kind_name: null,
			counterparty_id: null,
			name: null,
			court_decision_date: null,
			contract: null,
			contract_date: null,
			contract_completion_date: null,
			accreditation: null,
			contract_accrual_start_date: null,
			contract_accrual_end_date: null,
			payment: 0.0,
			payment_source: null,
			payment_frequency: get(options).paymentFrequency?.at(0)?.id,
		});
	}

	static tableTitles() {
		return titles[get(bankruptType)] || titles['default'];
	}

	static async loadSpecialistKindOptions() {
		const response = await specialistsApi.specialistKindOptions();
		options.set({ ...get(options), specialistKind: [{ id: null, text: '' }, ...response.options] });
	}

	static async loadCurrentCreditorOptions() {
		const response = await specialistsApi.currentCreditorOptions();
		options.set({ ...get(options), currentCreditor: [{ id: 'new', text: 'Добавить специалиста' }, ...response.options] });
	}

	static async loadPaymentSourceOptions() {
		const response = await specialistsApi.paymentSourceOptions();
		options.set({ ...get(options), paymentSource: [{ id: null, text: '' }, ...response.options] });
	}

	static async loadPaymentFrequencyOptions() {
		const response = await specialistsApi.paymentFrequencyOptions();
		options.set({ ...get(options), paymentFrequency: response.options });
	}

	static async loadSpecialists() {
		const response = await specialistsApi.index();
		transmitter.set({ ...get(transmitter), specialists: response.specialists || [] });
		return (response.specialists || []).map(item => new this(item));
	}
}
