/** @format */

import { get } from 'svelte/store';
import { transmitter } from '~/js/global_stores/base.js';
import { gaussRound } from '~/js/utils/tools.js';

export const applyStyles = template => `
<style>
  table.root { width: 100%; border: none !important; }
  table.root table { margin: auto; }
  ol { font-family:'Times New Roman'; font-size:11pt; color: #000000; }
</style>
${template ? template.replaceAll('<table ', '<table class="root" ') : ''}
`;

export const meetingCreditorData = ($transmitter = get(transmitter)) => {
	const val1 = $transmitter.meeting.participants.reduce((sum, e) => sum + 1.0 * e.voting_sum, 0);
	const val2 = 1.0 * $transmitter.meeting.voting_sum;
	const val3 = gaussRound(val2 > 0 ? (val1 / val2) * 100 : 0, 4);

	return {
		judicial_act_date: $transmitter.procedure.introduction_date,
		bankrupt_short_name: $transmitter.procedure.bankrupt.short_name,
		meeting_arbitr_position: $transmitter.procedure.arbitr_position,
		meeting_participants: $transmitter.meeting.participants,
		meeting_questions: $transmitter.meeting.questions,
		meeting_discussions: $transmitter.meeting.discussions,
		meeting_bulletins: $transmitter.meeting.bulletins,
		meeting_kind: $transmitter.meeting.kind,
		meeting_form: $transmitter.meeting.form,
		meeting_voting_status: $transmitter.meeting.voting_meeting_status,
		meeting_first: $transmitter.meeting.first_meeting,
		meeting_reason: $transmitter.meeting.reason,
		meeting_reason_text: $transmitter.meeting.reason == 'arbitr_manager' ? 'по инициативе арбитражного управляющего' : 'по инициативе лиц, требующих созыва',
		meeting_date_at: $transmitter.meeting.date_at,
		meeting_time_at: $transmitter.meeting.time_at,
		meeting_note: $transmitter.meeting.note,
		meeting_from_at: $transmitter.meeting.from_at,
		meeting_to_at: $transmitter.meeting.to_at,
		meeting_address: $transmitter.dct.substitutions.meeting_address || $transmitter.meeting.address,
		meeting_read_at: $transmitter.meeting.read_at,
		meeting_read_from_at: $transmitter.meeting.read_from_at,
		meeting_read_to_at: $transmitter.meeting.read_to_at,
		meeting_read_address: $transmitter.meeting.read_address,
		meeting_voting_qty: $transmitter.meeting.voting_qty,
		meeting_voting_sum: $transmitter.meeting.voting_sum,
		meeting_unsecured_voting_sum: $transmitter.meeting.unsecured_voting_sum,
		meeting_chairman: $transmitter.dct.substitutions.meeting_chairman || $transmitter.procedure.arbitr_manager.person.full_name,
		meeting_secretary: $transmitter.dct.substitutions.meeting_secretary || $transmitter.procedure.arbitr_manager.person.full_name,
		meeting_participant_voting_proc: val3,
	};
};

export const meetingWorkerData = ($transmitter = get(transmitter)) => {
	const meeting_participants_qty = $transmitter.meeting.participants.filter(e => e.role_worker).length;
	const meeting_workers_qty = $transmitter.meeting.workers_qty > 0 ? $transmitter.meeting.workers_qty : meeting_participants_qty;

	return {
		judicial_act_date: $transmitter.procedure.introduction_date,
		bankrupt_short_name: $transmitter.procedure.bankrupt.short_name,
		meeting_arbitr_position: $transmitter.procedure.arbitr_position,
		meeting_participants: $transmitter.meeting.participants,
		meeting_questions: $transmitter.meeting.questions,
		meeting_discussions: $transmitter.meeting.discussions,
		meeting_bulletins: $transmitter.meeting.bulletins,
		meeting_kind: $transmitter.meeting.kind,
		meeting_form: $transmitter.meeting.form,
		meeting_voting_status: $transmitter.meeting.voting_meeting_status,
		meeting_first: $transmitter.meeting.first_meeting,
		meeting_reason: $transmitter.meeting.reason,
		meeting_reason_text: $transmitter.meeting.reason == 'arbitr_manager' ? 'по инициативе арбитражного управляющего' : 'по инициативе лиц, требующих созыва',
		meeting_date_at: $transmitter.meeting.date_at,
		meeting_time_at: $transmitter.meeting.time_at,
		meeting_note: $transmitter.meeting.note,
		meeting_from_at: $transmitter.meeting.from_at,
		meeting_to_at: $transmitter.meeting.to_at,
		meeting_address: $transmitter.dct.substitutions.meeting_address || $transmitter.meeting.address,
		meeting_read_at: $transmitter.meeting.read_at,
		meeting_read_from_at: $transmitter.meeting.read_from_at,
		meeting_read_to_at: $transmitter.meeting.read_to_at,
		meeting_read_address: $transmitter.meeting.read_address,
		meeting_voting_qty: $transmitter.meeting.kind == 'creditor' ? $transmitter.meeting.voting_qty : $transmitter.meeting.workers_qty,
		meeting_voting_sum: $transmitter.meeting.voting_sum,
		meeting_unsecured_voting_sum: $transmitter.meeting.unsecured_voting_sum,
		meeting_chairman: $transmitter.dct.substitutions.meeting_chairman || $transmitter.procedure.arbitr_manager.person.full_name,
		meeting_secretary: $transmitter.dct.substitutions.meeting_secretary || $transmitter.procedure.arbitr_manager.person.full_name,
		meeting_participant_voting_proc: $transmitter.meeting.participants.filter(e => e.role_worker).length,
		meeting_workers_qty: meeting_workers_qty,
		meeting_participants_qty: meeting_participants_qty,
	};
};
