<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveSelect from '../../../components/ui/autosave_select.svelte';

	$: procedureId = $procedure && $procedure.id;

	const judicialActOptions = [
		{ id: null, text: '' },
		{ id: 'Определение', text: 'Определение' },
		{ id: 'Решение', text: 'Решение' },
	];

	$: judicialAct = judicialActOptions.find((_option, index) => index === ($procedure && $procedure.phase));

	$: judicialActId = {
		...$commonParams,
		attribute: 'judicial_act_id',
		label: 'Наименование судебного акта',
		value: judicialActOptions.find(option => option.id === $currentData.judicialActId),
		options: judicialActOptions,
		placeholder: 'Наименование акта',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.judicial_act_id',
			resource: judicialAct,
			resourceProp: 'id',
		},
	};
</script>

<AutosaveSelect {...judicialActId} on:update />
