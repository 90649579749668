<!-- @format -->
<script>
	import { procedure } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { isPresent, debounce } from 'utils/tools';
	import { deleteCookie } from 'utils/cookies';
	import { fetchPut, fetchDelete } from 'utils/fetch';
	import Modal, { showModal } from '~/svelte/components/modal.svelte';
	import InfoIcon from '~/svelte/_shared/icon-info.svelte';

	let infoShow = {};
	let inTransitionProcess = false;
	let loading = false;

	const transfer = procedurePhase => {
		deleteCookie('_rafProcedureId');

		let url = `/procedures/new?procedure_id=${$procedure.id}`;
		if (procedurePhase) {
			url = `${url}&procedure_phase=${procedurePhase}`;
		}
		location.href = url;
	};

	const askProcedurePhase = () => {
		inTransitionProcess = true;
		if (['debt_restructuring', 'supervision'].includes($procedure.phase)) {
			debounce(() => transfer(), 200);
		}
		// после реализации всех фаз процедур раскомментировать, остальное (выше) удалить
		//$procedure.phase === 'supervision' ? showModal('procedure-phase-modal') : transfer()
	};

	const toggleComleted = async () => {
		loading = true;
		const response = await fetchPut(`/api/private/procedures/${$procedure.id}/toggle_completed`);
		$procedure = response.procedure;
		loading = false;
	};

	const deleteProcedure = async () => {
		await fetchDelete(`/api/private/procedures/${$procedure.id}`).then(_response => (location.href = '/procedures'));
	};
</script>

<div class="m-b-xxl overlay procedure__actions">
	{#if $procedure && $procedure.transitional && ['debt_restructuring', 'supervision'].includes($procedure.phase)}
		<!-- после реализации всех фаз процедур удалить из условия ('debt_restructuring', 'supervision').includes($procedure.phase) -->
		<div class="button_with_info_wrapper action__transition">
			<button
				class="btn btn-sx btn-warning m-r-sm"
				disabled={!$procedureAvailable || !isPresent($procedure.id) || inTransitionProcess}
				on:click={$procedureAvailable && isPresent($procedure) && askProcedurePhase}
			>
				Перевести процедуру
			</button>
			<div
				class="wrapper-info-icon"
				role="button"
				tabindex="0"
				on:mouseover={() => (infoShow['transition'] = true)}
				on:mouseout={() => (infoShow['transition'] = false)}
				on:focus={() => (infoShow['transition'] = true)}
				on:blur={() => (infoShow['transition'] = false)}
			>
				<InfoIcon />
			</div>
			{#if infoShow['transition']}
				<div class="order-of-repayment__popover show fade m-b-xs">
					Выполнится копирование данных в новую процедуру: суд, заявление, должник, супруги, счета, требования, имущество, РТК, финанализ, контрагенты.
				</div>
			{/if}
		</div>
	{/if}
	{#if $procedure.can_be_completed}
		<div class="button_with_info_wrapper action__transition m-t-sm">
			<button
				class="btn btn-sx m-r-sm"
				class:btn-danger={!$procedure.completed}
				class:btn-primary={$procedure.completed}
				disabled={!isPresent($procedure.id) || inTransitionProcess}
				on:click={isPresent($procedure) && toggleComleted}
			>
				{$procedure.completed ? 'Активировать процедуру' : 'Завершить процедуру'}
				{#if loading}
					<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
				{/if}
			</button>
			<div
				class="wrapper-info-icon"
				role="button"
				tabindex="0"
				on:mouseover={() => (infoShow['complete'] = true)}
				on:mouseout={() => (infoShow['complete'] = false)}
				on:focus={() => (infoShow['complete'] = true)}
				on:blur={() => (infoShow['complete'] = false)}
			>
				<InfoIcon />
			</div>
			{#if infoShow['complete']}
				<div class="order-of-repayment__popover show fade m-b-xs">
					{#if $procedure.completed}
						Процедура будет показана в списках активных процедур.
					{:else}
						Процедура останется доступной, но будет скрыта из списков активных процедур.
					{/if}
				</div>
			{/if}
		</div>
		<div class="button_with_info_wrapper action__transition m-t-sm">
			<button
				class="btn btn-sx m-r-sm btn-danger"
				disabled={!isPresent($procedure.id) && $procedure.can_be_completed}
				on:click={() => showModal('procedure-delete-modal')}
			>
				Удалить процедуру
			</button>
		</div>
	{/if}
</div>
<Modal modalId="procedure-phase-modal" size="small">
	<h3 slot="header" class="create-dct-modal__header">Выберите этап банкротства</h3>
	<div slot="footer" class="d-flex justify-content-around">
		<ul class="procedure-phases-list">
			<li>
				<button class="btn btn-default btn-outline" on:click={() => transfer('bankruptcy_proceedings')} on:keypress|stopPropagation>
					Конкурсное производство
				</button>
			</li>
			<li>
				<button class="btn btn-default btn-outline" on:click={() => transfer('external_control')} on:keypress|stopPropagation> Внешнее управление </button>
			</li>
		</ul>
	</div>
</Modal>
<Modal modalId="procedure-delete-modal" size="medium" submitButtonText="Удалить" submitButtonCss="btn-danger" submitButtonAction={deleteProcedure}>
	<h3 slot="header" class="m-r-md">
		<p>Вы действительно хотите удалить процедуру?</p>
	</h3>
	<h4 slot="body" class="m-r-md">
		<ul>
			<li>Процедуру можно восстановить в течение шести месяцев через службу поддержки</li>
		</ul>
	</h4>
</Modal>

<style lang="scss">
	.wrapper-info-icon {
		margin-top: 5px;
	}

	.button_with_info_wrapper {
		position: relative;
		display: flex;

		button {
			min-width: 13rem;
		}
	}

	.order-of-repayment__popover {
		position: absolute;
		bottom: 2rem;
		left: 0;
		z-index: 9999;
		width: 17vw;
		background-color: #fff;
		border: solid 1px;
		border-radius: 0.3rem;
		box-shadow: 0 0 10px;
		display: block;
		padding: 0.7rem 1rem;
	}

	.procedure-phases-list {
		padding-left: 0;
		width: 100%;

		li {
			list-style-type: none;
			margin-bottom: 0.5rem;

			button {
				width: 100%;
				text-align: left;
			}
		}
	}
</style>
