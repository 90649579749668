<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { createEventDispatcher, onDestroy } from 'svelte';
	import Child from 'models/Child';
	import { childId, child } from './stores';
	import { isPresent, isBlank } from 'utils/tools';
	import Input from '~/svelte/components/ui/input.svelte';
	import InputCurrency from '~/svelte/components/ui/input_currency.svelte';
	import InputPercent from '~/svelte/components/ui/input_percent.svelte';
	import Checkbox from '~/svelte/components/ui/checkbox.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import DadateAddress from '~/svelte/components/ui/input_dadata_address.svelte';
	import DadataPassportDepartment from '~/svelte/components/ui/input_dadata_passport_department.svelte';
	import RadioSelect from '~/svelte/components/ui/radio_select.svelte';
	import Case from 'case';

	const dispatch = createEventDispatcher();

	export let handleSubmit;
	export let handleDelete;
	export let handleDuplicate;
	export let submitButtonDisabled;

	let mailingAddressCheck = false;
	let mailingAddressCheckInit = false;
	let fullName = null;
	let duplicated = false;

	$: if (isBlank($child)) {
		$child = Object.entries(Child.find($childId) || Child.new()).reduce((res, [key, value]) => {
			if (key == 'descendant') {
				res = { ...res, ...Object.entries(value).reduce((mRes, [k, v]) => (mRes = { ...mRes, [`descendant_${k}`]: v }), {}) };
			} else {
				res[key] = value;
			}
			return res;
		}, {});
	}
	$: procedure = ($transmitter && $transmitter.procedure) || procedure;
	$: bankrupt = procedure && procedure.bankrupt;
	$: $child.descendant_bankrupt_id = bankrupt && bankrupt.id;

	const initFullName = () => {
		fullName = [$child.surname, $child.name, $child.patronymic].filter(n => isPresent(n)).join(' ');
	};

	$: if (fullName === null && !!$child.surname) {
		initFullName();
	}

	$: if (![$child.full_name, null].includes(fullName)) {
		[$child.surname, $child.name, $child.patronymic] = fullName.split(' ');
	}

	$: if (!mailingAddressCheckInit) {
		mailingAddressCheck = $child && $child.reg_address_full && $child.mailing_address_full && $child.reg_address_full === $child.mailing_address_full;
		mailingAddressCheckInit = true;
	}

	const setMailingAddress = (mailingAddressCheck, regAddressFull) => {
		if ($child && mailingAddressCheck) {
			$child.mailing_address_full = regAddressFull;
		}
	};

	$: setMailingAddress(mailingAddressCheck, $child.reg_address_full);

	$: hasPreviousFullName = $child && $child.full_name_previous;
	$: changedFullNameCheck = hasPreviousFullName;

	const nullifyPreviousFullName = () => {
		if ($child) {
			$child.full_name_previous = null;
		}
	};

	$: if (!changedFullNameCheck) {
		nullifyPreviousFullName();
	}

	// Соглашение о выплате алиментов
	const nullifyDescendantAlimonyPaymentAgreementData = () => {
		if ($child) {
			$child.descendant_alimony_payment_agreement_date = null;
			$child.descendant_agreement_alimony_amount_per_month = null;
			$child.descendant_agreement_alimony_amount_as_percentage = null;
		}
	};

	$: if (!$child.descendant_alimony_payment_agreement_issued) {
		nullifyDescendantAlimonyPaymentAgreementData();
	}

	// Судебные акты о выплате алиментов
	const nullifyDescendantAlimonyPaymentCourtOrderData = () => {
		if ($child) {
			$child.descendant_court = null;
			$child.descendant_alimony_payment_court_order_date = null;
			$child.descendant_court_order_alimony_amount_per_month = null;
			$child.descendant_court_order_alimony_amount_as_percentage = null;
		}
	};

	$: if (!$child.descendant_alimony_payment_court_order_issued) {
		nullifyDescendantAlimonyPaymentCourtOrderData();
	}

	const formFields = {
		fullName: {
			model: 'child',
			attribute: 'full_name',
			label: 'ФИО ребёнка',
			placeholder: 'Фамилия Имя Отчество',
			required: true,
		},
		changedFullNameCheck: {
			label: 'Ребёнок менял ФИО',
			text: 'да, менял',
		},
		previousFullName: {
			model: 'child',
			attribute: 'full_name_previous',
			label: 'Предыдущие ФИО',
			placeholder: 'Фамилия Имя Отчество',
		},
		inn: {
			model: 'child',
			attribute: 'inn',
			label: 'ИНН ребёнка',
			placeholder: '123456789012',
			maskOptions: { mask: '000000000000' },
		},
		snils: {
			model: 'child',
			attribute: 'snils',
			label: 'СНИЛС ребёнка',
			placeholder: '123-456-789 10',
			maskOptions: { mask: '000-000-000 00' },
		},
		documentType: {
			model: 'child',
			attribute: 'document_type',
			label: 'Документ ребёнка',
			options: [
				{ id: 'birth_certificate', text: 'Свидетельство о рождении' },
				{ id: 'passport', text: 'Паспорт' },
			],
		},
		birthCertificateNumber: {
			model: 'child',
			attribute: 'birth_certificate_number',
			label: 'Свидетельство о рождении ребёнка',
			placeholder: 'VIIЮБ123456',
		},
		birthCertificateDepartment: {
			model: 'child',
			attribute: 'birth_certificate_department',
			label: 'Место государственной регистрации',
			placeholder: 'ЗАГС г.Красногорск',
		},
		birthCertificateDate: {
			model: 'child',
			attribute: 'birth_certificate_date',
			label: 'Дата выдачи свидетельства о рождении',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		passportNumber: {
			model: 'child',
			attribute: 'passport_number',
			label: 'Паспорт ребёнка',
			placeholder: '9900 123456',
			maskOptions: { mask: '0000 000000' },
		},
		passportDepartment: {
			model: 'child',
			attribute: 'passport_department',
			label: 'кем выдан',
			placeholder: 'Московским РОВД',
		},
		passportDate: {
			model: 'child',
			attribute: 'passport_date',
			label: 'когда выдан',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		birthDate: {
			model: 'child',
			attribute: 'birth_date',
			label: 'Дата рождения ребёнка',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		birthPlace: {
			model: 'child',
			attribute: 'birth_place',
			label: 'Место рождения ребёнка',
			placeholder: 'гор. Москва',
		},
		regAddress: {
			model: 'child',
			attribute: 'reg_address_full',
			label: 'Адрес регистрации ребёнка',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
		},
		mailingAddress: {
			model: 'child',
			attribute: 'mailing_address_full',
			label: 'Почтовый адрес ребёнка',
			placeholder: '100000, Московская область, г. Королев, ул. Вторая, д. 2, кв. 2',
		},
		mailingAddressCheck: {
			text: 'тот же, что и адрес регистрации',
		},
		alimonyPaymentAgreementIssued: {
			model: 'child',
			attribute: 'alimony_payment_agreement_issued',
			label: 'Соглашение о выплате алиментов заключалось',
			text: 'да, заключалось',
		},
		alimonyPaymentAgreementDate: {
			model: 'child',
			attribute: 'alimony_payment_agreement_date',
			label: 'Дата соглашения о выплате алиментов',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		agreementAlimonyAmountPerMonth: {
			model: 'child',
			attribute: 'agreement_alimony_amount_per_month',
			label: 'Сумма алиментов в месяц, руб.',
			placeholder: '0,00',
			type: 'number',
		},
		agreementAlimonyAmountAsPercentage: {
			model: 'child',
			attribute: 'agreement_alimony_amount_as_percentage',
			label: 'Сумма алиментов в % от дохода должника',
			placeholder: '0',
			scale: 0,
		},
		alimonyPaymentCourtOrderIssued: {
			model: 'child',
			attribute: 'alimony_payment_court_order_issued',
			label: 'Судебные акты о выплате алиментов выносились',
			text: 'да, выносились',
		},
		court: {
			model: 'child',
			attribute: 'court',
			label: 'Наименование суда',
			placeholder: 'Наименование суда',
		},
		alimonyPaymentCourtOrderDate: {
			model: 'child',
			attribute: 'alimony_payment_court_order_date',
			label: 'Дата судебного акта о выплате алиментов',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
		},
		courtOrderAlimonyAmountPerMonth: {
			model: 'child',
			attribute: 'court_order_alimony_amount_per_month',
			label: 'Сумма алиментов в месяц, руб.',
			placeholder: '0,00',
			type: 'number',
		},
		courtOrderAlimonyAmountAsPercentage: {
			model: 'child',
			attribute: 'court_order_alimony_amount_as_percentage',
			label: 'Сумма алиментов в % от дохода должника',
			placeholder: '0',
			scale: 0,
		},
	};

	$: submitButtonDisabled = isBlank(fullName);

	handleSubmit = async () => {
		$child = Object.entries($child).reduce(
			(res, [key, value]) => {
				if (key.startsWith('descendant_')) {
					res.descendant[key.replace(/^descendant_/, '')] = value;
				} else {
					res[key] = value;
				}
				return res;
			},
			{ descendant: {} },
		);

		const result = isPresent($childId) ? await Child.update($child) : await Child.create($child);

		if (isPresent(result.errors)) {
			for (let attribute of Object.keys(formFields)) {
				formFields[attribute] = { ...formFields[attribute], errors: result.errors[Case.snake(attribute)] };
			}
			throw {};
		} else {
			dispatch('submit');
		}
	};

	handleDelete = async () => {
		await Child.delete($childId);
		dispatch('delete');
	};

	handleDuplicate = async () => {
		duplicated = true;
		$childId = null;
		$child = await Child.duplicate($child);
		dispatch('duplicate');
	};

	onDestroy(() => {
		if (!duplicated) {
			$childId = null;
			$child = null;
		}
	});
</script>

<Input {...formFields.fullName} bind:value={fullName} />
<Checkbox {...formFields.changedFullNameCheck} bind:checked={changedFullNameCheck} />
{#if changedFullNameCheck}
	<Input {...formFields.previousFullName} bind:value={$child.full_name_previous} />
{/if}
<Input {...formFields.inn} bind:value={$child.inn} />
<Input {...formFields.snils} bind:value={$child.snils} />
<RadioSelect {...formFields.documentType} bind:value={$child.descendant_document_type} />
{#if $child.descendant_document_type == 'birth_certificate'}
	<Input {...formFields.birthCertificateNumber} bind:value={$child.descendant_birth_certificate_number} />
	<DadataPassportDepartment {...formFields.birthCertificateDepartment} bind:value={$child.descendant_birth_certificate_department} />
	<Datepicker {...formFields.birthCertificateDate} bind:value={$child.descendant_birth_certificate_date} />
{:else if $child.descendant_document_type == 'passport'}
	<Input {...formFields.passportNumber} bind:value={$child.passport_number} />
	<DadataPassportDepartment {...formFields.passportDepartment} bind:value={$child.passport_department} />
	<Datepicker {...formFields.passportDate} bind:value={$child.passport_date} />
{/if}
<Datepicker {...formFields.birthDate} bind:value={$child.birth_date} />
<Input {...formFields.birthPlace} bind:value={$child.birth_place} />
<DadateAddress {...formFields.regAddress} bind:value={$child.reg_address_full} />
<DadateAddress {...formFields.mailingAddress} disabled={mailingAddressCheck} bind:value={$child.mailing_address_full} />
<Checkbox {...formFields.mailingAddressCheck} bind:checked={mailingAddressCheck} />
<Checkbox {...formFields.alimonyPaymentAgreementIssued} bind:checked={$child.descendant_alimony_payment_agreement_issued} />
{#if $child.descendant_alimony_payment_agreement_issued}
	<Datepicker {...formFields.alimonyPaymentAgreementDate} bind:value={$child.descendant_alimony_payment_agreement_date} />
	<InputCurrency {...formFields.agreementAlimonyAmountPerMonth} bind:value={$child.descendant_agreement_alimony_amount_per_month} />
	<InputPercent {...formFields.agreementAlimonyAmountAsPercentage} bind:value={$child.descendant_agreement_alimony_amount_as_percentage} />
{/if}
<Checkbox {...formFields.alimonyPaymentCourtOrderIssued} bind:checked={$child.descendant_alimony_payment_court_order_issued} />
{#if $child.descendant_alimony_payment_court_order_issued}
	<Input {...formFields.court} bind:value={$child.descendant_court} />
	<Datepicker {...formFields.alimonyPaymentCourtOrderDate} bind:value={$child.descendant_alimony_payment_court_order_date} />
	<InputCurrency {...formFields.courtOrderAlimonyAmountPerMonth} bind:value={$child.descendant_court_order_alimony_amount_per_month} />
	<InputPercent {...formFields.courtOrderAlimonyAmountAsPercentage} bind:value={$child.descendant_court_order_alimony_amount_as_percentage} />
{/if}
