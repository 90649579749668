<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: isRegistrar = $currentData && $currentData.registrarChoice === 'registrar';

	$: registrarInsuranceOrg = {
		...$commonParams,
		attribute: 'registrar_insurance_org',
		label: 'Страховая компания по страховке реестродержателя',
		value: $currentData && $currentData.registrarInsuranceOrg,
		placeholder: 'Первая страховая компания',
	};
</script>

{#if isRegistrar}
	<AutosaveInput {...registrarInsuranceOrg} />
{/if}
