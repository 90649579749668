/** @format */

export default {
	mask: Number,
	lazy: false, // make placeholder always visible
	signed: false, // disallow negative
	thousandsSeparator: ' ', // any single char
	padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
	normalizeZeros: true, // appends or removes zeros at ends
	radix: ',', // fractional delimiter
	mapToRadix: ['.'], // symbols to process as radix
};
