<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInputCurrency from '~/svelte/components/ui/autosave_input_currency.svelte';

	$: amountStatedDemands = {
		...$commonParams,
		attribute: 'amount_stated_demands',
		label: 'Заявленные требования, не рассмотренные судом',
		value: $currentData && $currentData.amountStatedDemands,
		placeholder: '0,00',
	};
</script>

<AutosaveInputCurrency {...amountStatedDemands} />
