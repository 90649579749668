<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInputCurrency from '~/svelte/components/ui/autosave_input_currency.svelte';

	$: amountClaimsIncludedInRequirementsReestrForFirstMeeting = {
		...$commonParams,
		attribute: 'amount_claims_included_in_requirements_reestr_for_first_meeting',
		label: 'Сумма требований, включенных в РТК на дату 1-го собрания (руб.)',
		value: $currentData && $currentData.amountClaimsIncludedInRequirementsReestrForFirstMeeting,
		placeholder: '0,00',
		inputCssClasses: 'small-input',
	};
</script>

<AutosaveInputCurrency {...amountClaimsIncludedInRequirementsReestrForFirstMeeting} />
