<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('arbitrManagerBirthDate');

	$: arbitrManagerBirthDate = {
		...$commonParams,
		attribute: 'arbitr_manager_birth_date',
		label: 'Дата рождения АУ',
		value: $currentData && $currentData.arbitrManagerBirthDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_birth_date',
			resource: arbitrManager && arbitrManager.person,
			resourceProp: 'birth_date',
		},
	};
</script>

{#if !arbitrManagerBirthDate.hidden}
	<AutosaveDatepicker {...arbitrManagerBirthDate} on:update />
{/if}
