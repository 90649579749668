<!-- @format -->
<!-- @format -->
<script>
	import { transmitter, options } from 'base_stores';
	import { isPresent, toArray } from 'utils/tools';
	import TableRow from './table_row.svelte';

	export let activeKindId;
	export let possessionKinds;
	export let indentMultiplier = 0;
	export let mounted = true;

	$: possessions = activeKindId ? toArray($transmitter.possessions).filter(e => String(e.kind_id) === String(activeKindId)) : [];

	const subkinds = kind => $options.possessionKind.filter(option => option.parent_id === kind.id).sort((a, b) => a.sequence - b.sequence);
</script>

{#if isPresent(possessionKinds)}
	{#each possessionKinds as possessionKind}
		<tr class="text-left text-secondary subkind">
			<td colspan="5">
				<span style={`padding-left: ${1 * indentMultiplier}rem;`}>{possessionKind.text}</span>
			</td>
		</tr>
		{#if isPresent(subkinds(possessionKind))}
			<svelte:self possessionKinds={subkinds(possessionKind)} indentMultiplier={indentMultiplier + 1} on:edit />
		{:else}
			<svelte:self {indentMultiplier} activeKindId={possessionKind.id} on:edit />
		{/if}
	{/each}
{:else if isPresent(possessions)}
	{#each possessions as possession}
		<TableRow {possession} {indentMultiplier} on:edit />
	{/each}
{:else if mounted}
	<tr class="text-secondary"><td colspan="5">Нет данных</td></tr>
{:else}
	<tr><td class="text-secondary" colspan="5"><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
{/if}

<style>
	.subkind {
		background-color: #efefef;
	}
</style>
