<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: numberOfMinorChildrenOfBankruptForAnalysis = {
		...$commonParams,
		attribute: 'number_of_minor_children_of_bankrupt_for_analysis',
		label: 'Количество несовершеннолетних детей должника для анализа платежеспособности должника',
		value: $currentData?.numberOfMinorChildrenOfBankruptForAnalysis,
		placeholder: '0',
	};
</script>

<AutosaveInput {...numberOfMinorChildrenOfBankruptForAnalysis} on:update />
