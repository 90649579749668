<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import Checkbox from '~/svelte/components/ui/autosave_checkbox.svelte';

	$: hasFictitiousBankruptcySigns = {
		...$commonParams,
		attribute: 'has_fictitious_bankruptcy_signs',
		label: 'Есть признаки фиктивного банкротства',
		value: $currentData.hasFictitiousBankruptcySigns,
		text: 'Да',
	};
</script>

<Checkbox {...hasFictitiousBankruptcySigns} />
