<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import judicialActRecognizedHint from './judicial_act_recognized_hint';
	import AutosaveMultiDatepicker from '../components/ui/autosave_multi_datepicker.svelte';
	import Checkbox from '../components/ui/checkbox.svelte';
	import { isPresent } from 'utils/tools';

	let init = true;

	let introductionCheck = false;
	let appointmentCheck = false;
	let introductionAppointmentCheck = true;
	let resolutionIntroductionDateValue = undefined;
	let introductionDateValue = undefined;
	let resolutionAppointmentDateValue = undefined;
	let appointmentDateValue = undefined;
	let resolutionIntroductionDateAttributes = ['resolution_introduction_date'];
	let introductionDateAttributes = ['introduction_date'];
	let resolutionAppointmentDateAttributes = ['resolution_appointment_date'];

	$: arbitrProcedure = ($transmitter && $transmitter.procedure) || arbitrProcedure;
	$: procedureId = (arbitrProcedure && arbitrProcedure.id) || null;

	$: if (init && !!arbitrProcedure) {
		introductionCheck =
			arbitrProcedure.introduction_date === arbitrProcedure.resolution_introduction_date &&
			arbitrProcedure.introduction_date &&
			arbitrProcedure.resolution_introduction_date;

		appointmentCheck =
			arbitrProcedure.appointment_date === arbitrProcedure.resolution_appointment_date &&
			arbitrProcedure.appointment_date &&
			arbitrProcedure.resolution_appointment_date;

		introductionAppointmentCheck =
			arbitrProcedure.resolution_appointment_date === arbitrProcedure.resolution_introduction_date &&
			arbitrProcedure.appointment_date === arbitrProcedure.introduction_date;

		init = false;
	}

	$: if (!!arbitrProcedure) {
		resolutionIntroductionDateValue = arbitrProcedure.resolution_introduction_date;
	}

	$: if (introductionCheck && !!arbitrProcedure) {
		introductionDateValue = arbitrProcedure.resolution_introduction_date;
	}

	$: if (!introductionCheck && !!arbitrProcedure) {
		introductionDateValue = arbitrProcedure.introduction_date;
	}

	$: if (introductionAppointmentCheck && !!arbitrProcedure) {
		resolutionAppointmentDateValue = resolutionIntroductionDateValue;
		appointmentDateValue = introductionDateValue;
	}

	$: if (!introductionAppointmentCheck && !!arbitrProcedure) {
		resolutionAppointmentDateValue = arbitrProcedure.resolution_appointment_date;
	}

	$: if (!introductionAppointmentCheck && appointmentCheck && !!arbitrProcedure) {
		appointmentDateValue = arbitrProcedure.resolution_appointment_date;
	}

	$: if (!introductionAppointmentCheck && !appointmentCheck && !!arbitrProcedure) {
		appointmentDateValue = arbitrProcedure.appointment_date;
	}

	$: if (introductionCheck && introductionDateAttributes) {
		resolutionIntroductionDateAttributes = [...resolutionIntroductionDateAttributes, ...introductionDateAttributes];
	} else {
		resolutionIntroductionDateAttributes = resolutionIntroductionDateAttributes.filter(item => !introductionDateAttributes.includes(item));
	}

	$: if (introductionAppointmentCheck && resolutionAppointmentDateAttributes && introductionDateAttributes) {
		resolutionIntroductionDateAttributes = [...resolutionIntroductionDateAttributes, ...resolutionAppointmentDateAttributes];
		introductionDateAttributes = [...introductionDateAttributes, 'appointment_date'];
	} else {
		resolutionIntroductionDateAttributes = resolutionIntroductionDateAttributes.filter(item => !resolutionAppointmentDateAttributes.includes(item));
		resolutionIntroductionDateAttributes = resolutionIntroductionDateAttributes.filter(item => item !== 'appointment_date');
	}

	$: if (appointmentCheck) {
		resolutionAppointmentDateAttributes = ['resolution_appointment_date', 'appointment_date'];
	} else {
		resolutionAppointmentDateAttributes = ['resolution_appointment_date'];
	}

	$: formFields = {
		resolutionIntroductionDate: {
			disabled: !procedureId || !$procedureAvailable,
			attributes: resolutionIntroductionDateAttributes,
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Резолютивная часть',
			value: resolutionIntroductionDateValue,
			placeholder: 'дд.мм.гггг',
			hintData: {
				judicial_act_recognized: isPresent(arbitrProcedure.judicial_act_recognized_data)
					? judicialActRecognizedHint({ name: 'resolutionIntroductionDate', value: resolutionIntroductionDateValue })
					: null,
			},
		},
		introductionDate: {
			disabled: !procedureId || !$procedureAvailable || introductionCheck,
			attributes: introductionDateAttributes,
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Полный текст',
			value: introductionDateValue,
			forceSave: introductionCheck,
			placeholder: 'дд.мм.гггг',
			hintData: {
				judicial_act_recognized: isPresent(arbitrProcedure.judicial_act_recognized_data)
					? judicialActRecognizedHint({ name: 'introductionDate', value: arbitrProcedure?.introduction_date })
					: null,
			},
		},
		resolutionAppointmentDate: {
			disabled: !procedureId || !$procedureAvailable || introductionAppointmentCheck,
			attributes: resolutionAppointmentDateAttributes,
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Резолютивная часть',
			value: resolutionAppointmentDateValue,
			forceSave: introductionAppointmentCheck,
			placeholder: 'дд.мм.гггг',
		},
		appointmentDate: {
			disabled: !procedureId || !$procedureAvailable || appointmentCheck || introductionAppointmentCheck,
			attributes: ['appointment_date'],
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Полный текст',
			value: appointmentDateValue,
			forceSave: appointmentCheck || introductionAppointmentCheck,
			placeholder: 'дд.мм.гггг',
		},
		introductionCheck: {
			disabled: !procedureId || !$procedureAvailable,
			text: 'та же, что и резолютивная часть',
		},
		introductionAppointmentCheck: {
			offset: false,
			disabled: !procedureId || !$procedureAvailable,
			text: 'та же, что и дата введения процедуры',
		},
		appointmentCheck: {
			disabled: !procedureId || !$procedureAvailable || introductionAppointmentCheck,
			text: 'та же, что и резолютивная часть',
		},
	};
</script>

<div class="m-b-sm overlay procedure__introduction-date-fields">
	<h3>2. Дата судебного акта о введении процедуры</h3>
	<AutosaveMultiDatepicker {...formFields.resolutionIntroductionDate} />
	<AutosaveMultiDatepicker {...formFields.introductionDate} />
	<Checkbox {...formFields.introductionCheck} bind:checked={introductionCheck} />
</div>

<div class="m-b-sm overlay procedure__appointment-date-fields">
	<h3>3. Дата судебного акта о назначении АУ</h3>
	<Checkbox {...formFields.introductionAppointmentCheck} bind:checked={introductionAppointmentCheck} />
	<AutosaveMultiDatepicker {...formFields.resolutionAppointmentDate} />
	<AutosaveMultiDatepicker {...formFields.appointmentDate} />
	<Checkbox {...formFields.appointmentCheck} bind:checked={appointmentCheck} />
</div>
