<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { transmitter, autosaveStatus, processing } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import printJS from 'print-js';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';
	import DownloadIcon from '~/svelte/_shared/download_icon.svelte';
	import PrintIcon from '~/svelte/_shared/print_icon.svelte';
	import DeleteIcon from '~/svelte/_shared/delete_icon.svelte';
	import { fetchPut } from 'utils/fetch_helpers';
	import { addToFormData } from '~/svelte/components/ui/form/svelte-forms-lib';
	import { isPresent, isBlank } from 'utils/tools';
	import { showModal } from '~/svelte/components/modal.svelte';

	const dispatch = createEventDispatcher();

	let files;
	let showInfo = false;

	$: arbitrManager = $transmitter?.arbitr_manager || arbitrManager;

	$: info = () => {
		if (isBlank(arbitrManager?.passport)) {
			return 'Добавьте файл формата .pdf, .jpg или .jpeg размером не более 15Мб';
		} else {
			return null;
		}
	};

	$: uploadFile(files);

	const uploadFile = files => {
		$autosaveStatus = null;

		if (isBlank(files)) {
			return;
		}

		const formData = new FormData();
		const act_edition = files[0].type == 'application/pdf' ? 'passport_pdf' : 'passport_jpg';

		addToFormData(formData, 'arbitr_manager', { [act_edition]: files[0] });

		$processing = true;

		fetchPut(`/api/private/arbitr_managers/${arbitrManager.id}`, formData)
			.then(result => {
				$processing = false;
				$autosaveStatus = 'saved';
				$transmitter = { ...$transmitter, ...result };
			})
			.catch(error => {
				$processing = false;
				$autosaveStatus = 'not_saved';
				throw error;
			});
	};
</script>

<div class="row">
	<div class="col-11 col-sm-5">
		<ul class="nav float-left m-t-xs">
			{#if isBlank(arbitrManager?.passport)}
				<li>
					<label class="file-label">
						<input bind:files accept="image/png, image/jpeg, application/pdf" multiple="true" class="file-input" type="file" />
						<span class="btn btn-xs btn-warning m-r-sm cursor_pointer" disabled={$processing || !$procedureAvailable}>
							+ Скан паспорта
							{#if $processing}
								<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
							{/if}
						</span>
					</label>
				</li>
				{#if isPresent(info())}
					<li>
						<div
							class="my-popover m-r-sm"
							role="button"
							tabindex="0"
							on:mouseover={() => (showInfo = true)}
							on:mouseout={() => (showInfo = false)}
							on:focus={() => (showInfo = true)}
							on:blur={() => (showInfo = false)}
						>
							<IconInfo />
						</div>
					</li>
				{/if}
				{#if showInfo}
					<div class="my-popover show fade">{info()}</div>
				{/if}
			{:else}
				<li class="ml-2 mt-1">
					<a download target="_self" href={`${arbitrManager.id}/download_passport`}>
						<DownloadIcon />
					</a>
				</li>
				<li class="ml-3 mt-1">
					<span
						class="cursor_pointer"
						role="button"
						tabindex="0"
						on:click={() =>
							printJS({
								printable: arbitrManager?.passport,
								type: 'image',
								showModal: true,
								modalMessage: 'Подготовка документа...',
							})}
						on:keypress|stopPropagation
					>
						<PrintIcon />
					</span>
				</li>
				<li class="ml-3 mt-1">
					<span
						class="cursor_pointer"
						role="button"
						tabindex="0"
						on:click={() => {
							dispatch('deletePassport');
							showModal('delete-judicial-act-modal');
						}}
						on:keypress|stopPropagation
					>
						<DeleteIcon />
					</span>
				</li>
			{/if}
		</ul>
	</div>
</div>

<style lang="scss">
	.nav > li > a {
		padding: 0 !important;
		min-height: 0 !important;
	}
	.file-label {
		cursor: pointer;
		overflow: hidden;
		position: relative;
		margin-bottom: 0;
	}
	.file-input {
		height: 100%;
		left: 0;
		opacity: 0;
		outline: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.my-popover {
		margin-top: 2px;
		position: relative;
	}
	.show {
		position: absolute;
		z-index: 10010;
		background-color: #fff;
		border: solid 1px;
		border-radius: 0.3rem;
		box-shadow: 0 0 10px;
		display: block;
		padding: 0.7rem 1rem;
		top: 35px;
		width: 300px;
	}
</style>
