/** @format */

import { get } from 'svelte/store';
import { transmitter } from 'base_stores';
import Model from 'models/Model';
import { childrenApi } from 'tools/service_api';
import { validate } from 'tools/validators';
import { isPresent } from 'utils/tools';

export default class Child extends Model {
	constructor(json) {
		super(json);
	}

	// class methods

	static subStoreName() {
		return 'children';
	}

	static new() {
		return new this({
			surname: null,
			name: null,
			patronymic: null,
			full_name_previous: null,
			inn: null,
			snils: null,
			passport_number: null,
			passport_department: null,
			passport_date: null,
			birth_date: null,
			birth_place: null,
			reg_address_full: null,
			mailing_address_full: null,
			descendant: {
				document_type: 'birth_certificate',
				birth_certificate_number: null,
				birth_certificate_department: null,
				birth_certificate_date: null,
				alimony_payment_agreement_issued: false,
				alimony_payment_agreement_date: null,
				agreement_alimony_amount_per_month: null,
				agreement_alimony_amount_as_percentage: null,
				alimony_payment_court_order_issued: false,
				court: null,
				alimony_payment_court_order_date: null,
				court_order_alimony_amount_per_month: null,
				court_order_alimony_amount_as_percentage: null,
			},
		});
	}

	static async loadChildren() {
		const response = await childrenApi.index();
		transmitter.set({ ...get(transmitter), children: response.children || [] });
		return (response.children || []).map(item => new this(item));
	}

	static async validates(params) {
		this.errors = {};

		for (let attribute of ['inn', 'snils', 'passport_number']) {
			const attributeErrors = validate('person', attribute, params[attribute], true);

			if (isPresent(attributeErrors)) {
				this.errors = { ...this.errors, [attribute]: attributeErrors };
			}
		}
	}

	static async create(params) {
		this.validates(params);

		return isPresent(this.errors) ? { errors: this.errors } : await super.create(params);
	}

	static async update(params) {
		this.validates(params);

		return isPresent(this.errors) ? { errors: this.errors } : await super.update(params);
	}
}
