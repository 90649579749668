<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { currentData } from '../../stores/stores';
	import { transmitter } from '~/js/global_stores/base';
	import { toArray, randomString } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	// readonly
	const handleChange = e => {};
	// const handleChange = e => dispatch('change', {
	//       model: 'meeting_participant',
	//          id: $currentData.meetingParticipantId,
	//   attribute: 'inn',
	//       value: e.target.value
	// });
	let id = randomString();
	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingFirst');
		disabled = toArray($transmitter.meeting.disabled_fields).includes('meetingFirst');
	}
</script>

{#if visible}
	<Field value={$currentData.meetingFirst} name="meetingFirst" label="Первое собрание" {disabled}>
		<div style="display: flex; align-self: center; height: 100%;">
			<input
				type="checkbox"
				{id}
				name="meetingFirst"
				bind:checked={$currentData.meetingFirst}
				on:change={handleChange}
				{disabled}
				class="align-self-center m-r-sm m-l-xs"
			/>
			<label style="margin: auto 0;" for={id}>Да</label>
		</div>
	</Field>
{/if}
