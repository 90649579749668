<!-- @format -->
<script>
	import imask from 'imask';
	import { toString } from '~/js/utils/tools.js';

	export let id;
	export let name;
	export let value;
	export let disabled;
	export let placeholder = '___-___-___ __';
	export let klass;
	export let onchange;

	let mask = '000-000-000 00';
	let lazy = false;

	let ref;
	let ref_hidden;
	let imaskInstance;

	const imaskConstructor = node => {
		imaskInstance = imask(node, { mask, lazy });
		imaskInstance.on('complete', () => (value = imaskInstance.unmaskedValue));

		return {
			destroy() {
				imaskInstance.destroy();
			},
		};
	};
	const handleChange = () => {
		ref_hidden.value = imaskInstance.unmaskedValue;
		ref_hidden.dispatchEvent(new Event('change'));
	};

	$: if (imaskInstance) imaskInstance.value = toString(value);
</script>

<input
	{id}
	{name}
	type="text"
	bind:this={ref}
	use:imaskConstructor
	{disabled}
	{placeholder}
	class={klass}
	on:focus={() => ref.select()}
	on:change={handleChange}
	autocomplete="off"
/>

<input type="text" {name} bind:this={ref_hidden} on:change={onchange} style="display:none;" />
