<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dctFormAvailable, dct } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { currencyFormat } from 'utils/formats';
	import { showModal } from '~/svelte/components/modal.svelte';
	import ReceivableModal from '~/svelte/receivables/_modal.svelte';
	import moment from 'moment';

	export let fieldId;
	export let headerText;

	let indexCollapse = false;
	let procedureId = null;
	let receivableId = null;
	let noCounterpartySelected = false;
	let date;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	$: receivables = ($transmitter && $transmitter.receivables) || receivables || [];

	const openReceivableModalForm = id => {
		receivableId = id;
		$transmitter.receivableId = id;
		showModal('form-receivable-modal');
	};

	const loadReceivables = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/receivables', { procedure_id: procedureId, date_at: $dct.date });
			$transmitter = { ...$transmitter, receivables: response.receivables };
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: if (!moment(date).isSame(moment($dct.date)) && !fetching) {
		date = $dct.date;
		loadReceivables();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|preventDefault>
	<h4 class="possessions-receivables col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`${headerText} (${receivables.length})`}</span>
	</h4>
</div>
<div id={`collapse-possessions-receivables-${(Math.random() * 1e16).toString(32)}`} class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && openReceivableModalForm()}> + Дебиторка </button>
		<div class="p-1">
			<ol class="possessions-receivables-list">
				{#if receivables.length}
					{#each receivables as receivable}
						<li>
							<div class="float-left simulated-link" role="button" tabindex="0" on:click={openReceivableModalForm(receivable.id)} on:keypress|preventDefault>
								{`${receivable.counterparty_name} (${receivable.cost ? currencyFormat(receivable.cost) : '0,00'} руб.)`}
							</div>
						</li>
					{/each}
				{/if}
			</ol>
		</div>
	</div>
</div>
<!--ReceivableModal {subkindOptions} {receivableId} bind:noCounterpartySelected/-->
<ReceivableModal disabled={!$dctFormAvailable} bind:receivableId bind:noCounterpartySelected />

<style>
	.possessions-receivables-list {
		display: grid;
		padding-left: 15px;
		list-style-type: circle;
	}
	.possessions-receivables-list > li > div,
	.possessions-receivables > .header {
		cursor: pointer;
	}
</style>
