<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { isPresent, isBlank, toArray, toFloat, formatSum, formatDate } from '~/js/utils/tools.js';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import { procedure } from '~/js/global_stores/base.js';

	const dispatch = createEventDispatcher();

	export let formOptions;
	export let isCompleted;
	export const submitForm = () => formControl.submitForm();
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => (data = { ...data, ...changes });

	let formControl;
	let form, errorsBase, handleChange;
	let data = {};

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
	};

	const setDictionariesValues = values => {
		data = { ...values };
	};
	const setSubmitParams = () => {
		const params = {};

		return params;
	};
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	formOptions={{ ...formOptions, model: 'meeting_question_sample', controller: 'meetings/' + formOptions.params.meeting_id + '/meeting_question_samples' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<!-- Название вопроса -->
	<Field required={true} name="name" label="Название вопроса">
		<input name="name" class="form-control" bind:value={$form.name} on:change={handleChange} placeholder="Краткое название вопроса" />
	</Field>
	<!-- Формулировка вопроса -->
	<Field name="question" label="Формулировка вопроса">
		<textarea
			name="question"
			rows="3"
			class="form-control"
			value={$form.question}
			on:change={handleChange}
			placeholder={formOptions.meeting.kind == 'creditor' ? 'Утверждение отчета арбитражного управляющего' : 'Избрание представителя работников должника'}
		/>
	</Field>
	<!-- Решение, поставленное на голосование -->
	<Field name="solution" label="Решение, поставленное на голосование">
		<textarea
			name="solution"
			rows="3"
			class="form-control"
			value={$form.solution}
			on:change={handleChange}
			placeholder={formOptions.meeting.kind == 'creditor' ? 'Утвердить отчет арбитражного управляющего' : 'Избрать представителем работников должника'}
		/>
	</Field>

	{#if formOptions.meeting.kind == 'creditor'}
		<!-- Голосование -->
		<Field name="voting" label="Голосование">
			{#each toArray(data.votings) as option}
				<label class="d-flex">
					<input type="radio" bind:group={$form.voting} class="align-self-center m-r-xs" value={option.value} />
					{option.label}
				</label>
			{/each}
		</Field>
		<!-- Порядок подсчета голосов -->
		<Field name="calc_order" label="Порядок подсчета голосов">
			{#each toArray(data.calc_orders) as option}
				<label class="d-flex">
					<input type="radio" bind:group={$form.calc_order} class="align-self-center m-r-xs" value={option.value} />
					{option.label}
				</label>
			{/each}
		</Field>
		<!-- Голоса залоговых кредиторов -->
		<Field name="secured_votes" label="Голоса залоговых кредиторов">
			{#each toArray(data.secured_votes) as option}
				<label class="d-flex">
					<input type="radio" bind:group={$form.secured_votes} class="align-self-center m-r-xs" value={option.value} />
					{option.label}
				</label>
			{/each}
		</Field>
	{/if}
	<!-- Форма бюллетеня -->
	<Field required={true} name="bulletin_form" label="Форма бюллетеня">
		<Select
			name="bulletin_form"
			items={toArray(data.bulletin_forms)}
			value={$form.bulletin_form}
			onchange={handleChange}
			containerClasses="form-control"
			placeholder="Выбрать форму"
		></Select>
	</Field>

	<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
</Form>
