<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('judicialActDate');

	$: judicialActDate = {
		...$commonParams,
		attribute: 'judicial_act_date',
		label: 'Дата судебного акта',
		value: $currentData && $currentData.judicialActDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hintData:
			($procedure && $procedure.introduction_date) === ($currentData && $currentData.judicialActDate)
				? {
						url: `/procedures/${procedureId}`,
						linkText: 'Процедура',
						consumerNew: $newObject,
						resource: $procedure,
						resourceProp: 'introduction_date',
					}
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveDatepicker {...judicialActDate} on:update />
