<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { transmitter, procedure, payments, paymentSubscriptions } from 'base_stores';
	import { confirmedPaymentSubscription, hiddenPaymentModal, paymentMethod, promoCode } from './stores';
	import { fetchGet } from 'utils/fetch';
	import { isPresent } from 'utils/tools';
	import { showModal, hideModal } from '../modal.svelte';
	import { makeZeroPayment } from 'tools/service_api';
	import { paymentDetails } from './stores';

	let place = 'left';
	let widgetCanceled = false;
	let widgetOpened = false;
	let listenWidgetCancel = false;
	let tWrapperObserver;
	let paymentResultObserver;
	let paymentTimer;

	const dispatch = createEventDispatcher();

	const zeroPay = () => {
		$hiddenPaymentModal = true;
		hideModal('notice-payment-modal');
		makeZeroPayment({ ...$paymentDetails, Status: 'CONFIRMED' });
	};

	const tWrapperPoll = () => {
		const tWrapper = document.getElementsByClassName('t-wrapper')[0];
		widgetOpened = tWrapper.style.display == 'block';

		if (!listenWidgetCancel) {
			const tCloseFrameDesktop = document.getElementsByClassName('t-close-frame-desktop')[0];
			tCloseFrameDesktop.addEventListener(
				'click',
				() => {
					widgetCanceled = true;
					listenWidgetCancel = false;
					dispatch('cancel');
				},
				{ once: true },
			);
			listenWidgetCancel = true;
		}

		if (!widgetOpened) {
			clearInterval(tWrapperObserver);
			if (!widgetCanceled) {
				subscribeToPaymentResult();
			}
		}
	};

	const startPayment = () => {
		$hiddenPaymentModal = true;
		widgetCanceled = false;
		widgetOpened = true;
		hideModal('notice-payment-modal');
		tWrapperObserver = setInterval(() => tWrapperPoll(), 500);
	};

	const subscribeToPaymentResult = () => {
		showModal('completion-payment-modal');
		paymentResultObserver = setInterval(() => paymentResultPoll(), 3000);
		paymentTimer = setTimeout(() => {
			clearInterval(paymentResultObserver);
			hideModal('completion-payment-modal');
			showModal('oops-payment-modal');
		}, 180000);
	};

	const paymentResultPoll = () => {
		fetchGet(`/api/private/payments/get_payment_result?payment_subscription_id=${$paymentDetails.id}`).then(response => {
			if (response.procedure_paid) {
				$transmitter = { ...$transmitter, procedure: { ...$transmitter.procedure, paid: response.procedure_paid } };
				$procedure = { ...$procedure, paid: response.procedure_paid };

				clearTimeout(paymentTimer);
				clearInterval(paymentResultObserver);
				fetchGet('/api/private/payments')
					.then(response => {
						$payments = response.payments;
						$paymentSubscriptions = response.payment_subscriptions;
						hideModal('completion-payment-modal');
					})
					.catch(() => {
						showModal('oops-payment-modal');
					});
			}
		});
	};

	// код для подключения Apple Pay от Тинькофф
	/*const widgetParameters = {
		container: 'tinkoffWidgetContainer',
		terminalKey: paymentDetails && paymentDetails.terminalkey,
		paymentSystems: {
			ApplePay: {
				buttonOptions: {
					color: 'white',
					outline: true,
				},
				paymentInfo: () => {
					return {
						infoEmail: paymentDetails && paymentDetails.email,
						paymentData: document.forms.TinkoffPayForm,
					};
				},
			},
		},
	};*/

	//	$: if (window) {
	//		window.addEventListener('load', () => initPayments(widgetParameters));
	//	}
</script>

{#if $paymentMethod == 'by_invoice'}
	<a class="btn float-left btn-warning m-r-sm" href="https://rafinad.ai/invoice_pack" target="blank"> Заказать счёт </a>
{:else if isPresent($paymentDetails)}
	{#if isPresent($promoCode.text) && $promoCode.isValid && +$promoCode.discountPrice == 0}
		<button
			class={`btn btn-warning btn-sm float-${place}`}
			class:m-r-sm={place == 'left'}
			class:m-l-sm={place == 'right'}
			disabled={!$confirmedPaymentSubscription}
			on:click={zeroPay}
		>
			Оплатить
		</button>
	{:else}
		<form
			name="TinkoffPayForm"
			class={`float-${place}`}
			class:m-r-sm={place == 'left'}
			class:m-l-sm={place == 'right'}
			onsubmit="pay(this); return false;"
			on:submit={startPayment}
		>
			<input class="tinkoffPayRow" type="hidden" name="terminalkey" value={$paymentDetails.terminalkey} />
			<input class="tinkoffPayRow" type="hidden" name="frame" value="true" />
			<input class="tinkoffPayRow" type="hidden" name="language" value="ru" />
			<input class="tinkoffPayRow" type="hidden" name="reccurentPayment" value={$paymentDetails.reccurent_payment} />
			<input class="tinkoffPayRow" type="hidden" name="customerKey" value={$paymentDetails.customer_key} />
			<input class="tinkoffPayRow" type="hidden" placeholder="Сумма заказа" name="amount" required value={$paymentDetails.total_amount / 100} />
			<input class="tinkoffPayRow" type="hidden" placeholder="Номер заказа" name="order" value={$paymentDetails.order_id} />
			<input class="tinkoffPayRow" type="hidden" placeholder="Описание заказа" name="description" value={$paymentDetails.description} />
			<input class="tinkoffPayRow" type="hidden" placeholder="ФИО плательщика" name="name" value="" />
			<input class="tinkoffPayRow" type="hidden" placeholder="E-mail" name="email" value={$paymentDetails.email} />
			<input class="tinkoffPayRow" type="hidden" placeholder="Контактный телефон" name="phone" value={$paymentDetails.phone} />
			<input class="tinkoffPayRow" type="hidden" name="receipt" value={JSON.stringify($paymentDetails.receipt)} />
			<input
				class="btn btn-sm float-left"
				class:btn-primary={location.pathname.match(/^\/settings$/)}
				class:btn-warning={!location.pathname.match(/^\/settings$/)}
				type="submit"
				disabled={!$confirmedPaymentSubscription}
				value={location.pathname.match(/^\/settings$/) ? 'Активировать' : 'Оплатить'}
			/>
		</form>
		<div id="tinkoffWidgetContainer"></div>
	{/if}
{/if}

<style>
	.tinkoffPayRow {
		display: block;
		margin: 1%;
		width: 160px;
	}
</style>
