<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { transmitter, newObject, options } from 'base_stores';
	import { fetchGet } from 'utils/fetch';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import Case from 'case';

	const collator = new Intl.Collator();

	const dispatch = createEventDispatcher();

	export let regionAttr = 'regionId';
	export let attrKind;
	export let attrKindName;
	export let placeholder = 'https://website.ru';

	$: regionId = ($currentData && $currentData[Case.camel(regionAttr)]) || 'all';
	$: correspondentId = $currentData && $currentData.correspondentId;
	$: correspondentKind = attrKind || $dct.correspondent_kind || $currentData.correspondentKind;
	$: correspondentKindName = attrKindName || ($dct && $dct.correspondent_kind_name);
	$: correspondentKindNameR = $transmitter.correspondentKinds?.find(c => c.code == correspondentKind)?.declensions?.r;
	$: label = `Вебсайт ${correspondentKindNameR || correspondentKindName || 'Корреспондента'}`;

	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('correspondentWebsite');

	$: correspondentWebsite = {
		...$commonParams,
		disabled:
			(correspondentKind &&
				$dct.form_fields.includes('correspondentId') &&
				($commonParams.disabled ||
					disabled ||
					$dct.status === 'approved' ||
					(!correspondentId && !['administration', 'court_usual', 'creditor', 'bank'].includes(correspondentKind)))) ||
			$commonParams.disabled,
		attribute: 'correspondent_website',
		label,
		value: $currentData.correspondentWebsite,
		placeholder: attrKindName || placeholder,
		previousValue: $currentData.correspondentWebsite,
		hintData: ['bank', 'creditor', 'kdl', 'worker'].includes(correspondentKind)
			? {
					url: correspondentKind === 'worker' ? '/workers' : '/counterparties',
					linkText: `Карточка ${correspondentKind === 'worker' ? 'работника' : 'контрагента'}`,
					consumerNew: $newObject,
					consumer: $dct,
					consumerProp: 'substitutions.correspondent_website',
					resource: 'counterparty',
					resourceProp: 'website',
				}
			: null,
	};

	const refreshCorrespondentOptions = () => {
		if (!correspondentKind || ['addressee', 'court_usual'].includes(correspondentKind)) {
			return;
		}

		let url = '/api/private/counterparties/get_options_for_roles_dcts';
		let params = {};

		if (correspondentKind == 'counterparty') {
			params.roles = 'all';
		} else if (['bank', 'creditor', 'kdl', 'debtor_participant', 'worker', 'cryptoex'].includes(correspondentKind)) {
			params.roles = [correspondentKind];
		} else if (correspondentKind) {
			url = '/api/private/organizations/options';
			params.region_id = ['court', 'bki', 'rsa', 'favt', 'fsis'].includes(correspondentKind) || regionId == 'all' ? null : regionId;
			params.correspondent_kind = correspondentKind;
		}

		fetchGet(url, params).then(
			response =>
				($options.correspondent = {
					...$options.correspondent,
					[correspondentKind]: { [regionId]: response.options.sort((a, b) => collator.compare(a.text, b.text)) },
				}),
		);
	};

	const handleResponse = response => {
		dispatch('update', response);
		refreshCorrespondentOptions();
	};
</script>

<AutosaveInput {...correspondentWebsite} on:update={({ detail: response }) => handleResponse(response)} />
