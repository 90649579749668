/** @format */

import { get } from 'svelte/store';
import { transmitter } from 'base_stores';
import ServiceApi from 'tools/service_api';
import { isBlank } from 'utils/tools';
import Case from 'case';

export default class Model {
	constructor(json) {
		if (isBlank(json)) {
			return;
		}

		for (let key in json) {
			this[key] = json[key];
		}

		this['errors'] = {};
	}

	// class methods

	static new() {
		return new this({});
	}

	static all() {
		return (get(transmitter)[this.subStoreName()] || []).map(item => new this(item));
	}

	static findBy(prop, value) {
		return this.all()?.find(item => item[prop] == value);
	}

	static find(id) {
		return this.findBy('id', id);
	}

	static duplicate(item) {
		this.update(item);
		const { id, ...dupItem } = item;
		return dupItem;
	}

	static async _operate(method, params) {
		const response = await ServiceApi[`${this.subStoreName()}Api`][method](params);

		const snakeSubStoreName = Case.snake(this.subStoreName());

		if (Object.keys(response).includes(snakeSubStoreName)) {
			transmitter.set({ ...get(transmitter), [this.subStoreName()]: response[snakeSubStoreName] || [] });
			return response[snakeSubStoreName] || [];
		} else {
			return response;
		}
	}

	static async index() {
		return await this._operate('index', null);
	}

	static async get(id) {
		return await this._operate('show', id);
	}

	static async create(params) {
		return await this._operate('create', params);
	}

	static async update(params) {
		return await this._operate('update', params);
	}

	static async delete(params) {
		return await this._operate('delete', params);
	}
}
