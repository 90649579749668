<!-- @format -->
<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import { isBlank } from 'utils/tools';
	import Input from '~/svelte/components/ui/input.svelte';
	import Select2 from '~/svelte/components/ui/select2.svelte';

	const dispatch = createEventDispatcher();

	export let tariff;
	export let bankruptTypeOptions = [];
	export let procedurePhasesOptions = {};
	export let handleSubmit;
	export let handleCancel;

	let bankruptType;

	let nullTariff = {
		name: '',
		bankrupt_type: '',
		procedure_phases: [],
		quantity: 0,
		price: 0,
		payment_methods: [],
		recurrent: false,
		allow_promo_code: false,
		active: true,
	};

	$: if (!tariff) {
		tariff = { ...nullTariff };
	}

	$: phasesOptions = tariff.bankrupt_type ? procedurePhasesOptions[tariff.bankrupt_type] : Object.values(procedurePhasesOptions).flat();

	$: if (tariff.bankrupt_type !== bankruptType) {
		tariff.procedure_phases = [];
		bankruptType = tariff.bankrupt_type;
	}

	$: fetchMethod = tariff.id ? fetchPut : fetchPost;

	handleSubmit = async () => {
		const result = await fetchMethod(`/api/admin/fee/tariffs/${tariff.id || ''}`, { fee_tariff: tariff });
		// передать новый тариф в индекс, чтоб вставить в список или подменить имеющийся
		if (result.tariff) {
			dispatch('submit', tariff);
		}
	};

	handleCancel = () => (tariff = null);

	$: tariffName = {
		attribute: 'name',
		model: 'tariff',
		label: 'Название',
		placeholder: '1500 рублей за 1 процедуру ФЛ',
	};

	$: tariffBankruptType = {
		attribute: 'bankrupt_type',
		model: 'tariff',
		label: 'Вид банкрота',
		placeholder: 'Выберите вид банкрота',
		options: bankruptTypeOptions,
	};

	$: tariffProcedurePhases = {
		attribute: 'procedure_phases',
		model: 'tariff',
		multiple: 'multiple',
		label: 'Фазы процедур',
		placeholder: 'Выберите фазы процедур',
		options: phasesOptions,
		disabled: isBlank(tariff.bankrupt_type),
	};
</script>

<Input {...tariffName} bind:value={tariff.name} />
<Select2 {...tariffBankruptType} bind:value={tariff.bankrupt_type} />
<Select2 {...tariffProcedurePhases} bind:value={tariff.procedure_phases} />
<div class="form-group row">
	<label for="tariff-quantity" class="col-sm-4 col-lg-3 col-xl-4 col-form-label"> Количество процедур </label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<input id="tariff-quantity" class="form-control" bind:value={tariff.quantity} />
	</div>
</div>
<div class="form-group row">
	<label for="tariff-price" class="col-sm-4 col-lg-3 col-xl-4 col-form-label"> Стоимость за единицу, руб. </label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<input id="tariff-price" class="form-control" bind:value={tariff.price} />
	</div>
</div>
<div class="form-group row">
	<label for="tariff-payment-methods" class="col-sm-4 col-lg-3 col-xl-4 col-form-label"> Способы оплаты </label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<input id="tariff-payment-methods" class="form-control" bind:value={tariff.payment_methods} />
	</div>
</div>
<div class="form-group row">
	<label for="tariff-recurrent" class="col-sm-4 col-lg-3 col-xl-4 col-form-label"> Повторяющийся платёж </label>
	<div class="d-flex align-items-center checkbox-left col-sm-8 col-lg-9 col-xl-8">
		<input type="checkbox" id="tariff-recurrent" class="m-l-xs" bind:value={tariff.recurrent} />
	</div>
</div>
<div class="form-group row">
	<label for="tariff-allow-promo-code" class="col-sm-4 col-lg-3 col-xl-4 col-form-label"> Комбинируется с промокодом </label>
	<div class="d-flex align-items-center checkbox-left col-sm-8 col-lg-9 col-xl-8">
		<input type="checkbox" id="tariff-allow-promo-code" class="m-l-xs" bind:value={tariff.allow_promo_code} />
	</div>
</div>
<div class="form-group row">
	<label for="tariff-active" class="col-sm-4 col-lg-3 col-xl-4 col-form-label"> Активен </label>
	<div class="d-flex align-items-center checkbox-left col-sm-8 col-lg-9 col-xl-8">
		<input type="checkbox" id="tariff-active" class="m-l-xs" bind:value={tariff.active} />
	</div>
</div>

<style lang="scss">
	.form-control {
		font-size: inherit;
	}

	/*.form-footer {
    width: calc(100% + 30px);
    justify-content: space-between;
    align-items: center;
    background-color: #f3f3f4;
    box-sizing: border-box;
    color: #676a6c;
    padding: 7px 15px;
    margin-bottom: -15px;
    margin-left: -15px;
  }*/
</style>
