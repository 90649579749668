<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter } from '~/js/global_stores/base.js';
	import Header from '../components/header.svelte';
	import Modal, { showModal } from '../components/modal.svelte';
	import Editor from './tiny_mce/Editor.svelte';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';
	import fileDownload from 'js-file-download';
	import moment from 'moment';

	let template;
	let templateFilename;

	let conf = {
		menubar: false,
		selector: 'textarea',
		theme: 'rafsilver',
		skin: 'oxide-raf',
		content_css: 'oxide-raf',
		placeholder: 'Печатайте здесь...',
		plugins: 'save anchor autolink charmap codesample image link lists searchreplace table visualblocks wordcount pagebreak nonbreaking accordion raftags',
		toolbar: `save undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight | 
              image table | numlist bullist indent outdent | nonbreaking charmap visualblocks | searchreplace pagebreak removeformat | 
              accordion raftags`,
		raftags: [
			{
				name: 'Поля',
				tags: [
					['%%DATE%%', 'дата'],
					['%%NUMBER%%', 'номер'],
					['%%CORRESPONDENT_FULL_NAME%%', 'корреспондент, полное наименование'],
					['%%BANKRUPT_FULL_NAME%%', 'банкрот, полное имя'],
					['%%ARBITR_MANAGER_FULL_NAME%%', 'АУ, полное имя'],
					['%%BANKRUPT_INN%%', 'банкрот, ИНН'],
					['%%BANKRUPT_SNILS%%', 'банкрот, СНИЛС'],
				],
			},
			{
				name: 'Триггеры',
				tags: [
					['##IF-DECLARER-EQ-CREDITOR##', 'заявитель кредитор'],
					['##IF-DECLARER-EQ-BANKRUPT##', 'заявитель банкрот'],
					['##IF-PROCEDURE_PHASE-EQ-PROPERTY_REALIZATION##', 'процедура РИ'],
					['##IF-PROCEDURE_PHASE-EQ-DEBT_RESTRUCTURING##', 'процедура РД'],
					['##IF-BANKRUPT_HAS_PERMANENT_INCOME_SOURCE-EQ-TRUE##', 'должник имеет источник постоянного дохода'],
					['##IF-BANKRUPT_HAS_PERMANENT_INCOME_SOURCE-EQ-FALSE##', 'должник не имеет источник постоянного дохода'],
				],
			},
		],
		toolbar_mode: 'wrap',
		statusbar: false,
		tinycomments_mode: 'embedded',
		tinycomments_author: 'Author name',
		min_height: 500,
		language_url: `${location.origin}/js/wysiwyg_editors/tinymce/langs/ru.js`,
		language: 'ru',
		save_enablewhendirty: false,
		save_onsavecallback: () => {
			templateFilename = `template-${moment().format('YYYYMMDD-HHmmss')}.txt`;
			showModal('download-template-modal');
		},
	};

	const downloadTemplate = () => {
		fileDownload(template, templateFilename);
		templateFilename = undefined;
	};

	const prepareTemplate = t => {
		return (t || '').replace(/<span((?!<).)*?mce-content-raf-tag((?!<).)*?<\/span>/g, str => {
			return str.match(/[%#]{2}.+[%#]{2}/);
		});
	};

	onMount(() => {
		$transmitter = { ...$transmitter, dct: { substitutions: {}, form_fields: [] } };
	});
</script>

<Header>
	<span slot="name">
		TinyMCE
		<span role="button" tabindex="0" on:click={() => showModal('info-about-tinymce-template-modal')} on:keypress|stopPropagation>
			<IconInfo />
		</span>
	</span>
</Header>
<div class="flex m-b-lg">
	<div class="document-form" style="width: 100%;">
		<div class="row" style="height: calc(100vh - 92px); margin-right: 0;">
			<div class="col-sm-6" style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="overlay">
					<form>
						<Editor bind:value={template} {conf} />
					</form>
				</div>
			</div>
			<div class="col-sm-6" style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="preview">
					<div class="preview__page">
						<div>{@html prepareTemplate(template)}</div>
						<!--Template/-->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<Modal modalId="download-template-modal" submitButtonText="Сохранить" submitButtonAction={downloadTemplate}>
	<h4 slot="body">
		<p class="text-center">Шаблон будет скачан и сохранён на ваш диск</p>
		<p class="text-center">под именем {templateFilename}</p>
	</h4>
</Modal>

<Modal modalId="info-about-tinymce-template-modal" submitButtonText="Понятно" cancelButtonText="Закрыть" size="large">
	<div slot="body" style="font-size: 1.2em;">
		<p>Для создания нового шаблона просто обновите страницу (F5).</p>
		<p>Чтобы отредактировать готовый шаблон используйте copy/paste (Ctrl+C / Ctrl+V).</p>
		<p>Для сохранения шаблона в файл используйте встроенную возможность редактора кнопку Сохранить.</p>
		<p>Для добавления полей подстановки или тэгов триггеров используйте символы % и # соответственно.</p>
		<p>
			При наборе % или # после пробела или в начале строки, а также любого символа следом (русские буквы или знаки препинания) будет предложено выбрать одно из
			полей подстановки или тэга триггера на выбор из имеющихся (поиск происходит по наличию символа в названиях полей и тэгов).
		</p>
	</div>
</Modal>

<style lang="scss">
	.preview {
		flex-direction: column;
		align-items: center;
		color: #000;
		box-shadow:
			0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 3px 1px -2px rgba(0, 0, 0, 0.2),
			0 1px 5px 0 rgba(0, 0, 0, 0.12);
	}

	.preview__page {
		--aspect-ratio: 210 / 297;
		box-sizing: border-box;
		background-color: #fff;
		padding: 7.14% 7.14% 7.14% 9.52%;
		position: relative;
	}
</style>
