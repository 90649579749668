<!-- @format -->
<script>
	export let attribute;
	export let model;
	export let value;
	export let placeholder;
	export let required = false;
	export let disabled = false;
	export let label;

	let inputField;
	$: attrId = `textarea-${model}-${attribute}`;
</script>

<div class="form-group row" class:has-success={value}>
	<label for={`input-${model}-${attribute}`} class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<textarea
			id={attrId}
			class:required-border={required}
			class="form-control"
			bind:value
			bind:this={inputField}
			{disabled}
			{placeholder}
			autocomplete="off"
			autocorrect="off"
		/>
	</div>
</div>
