<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('bankruptShortNameReorg');

	$: if ($dct.form_fields.includes('bankruptShortNameReorg')) {
		$requiredFieldsFilled.bankruptShortNameReorg = !!bankruptShortNameReorg.value || !$currentData.bankruptWasReorg;
	}

	$: bankruptShortNameReorg = {
		required: $currentData.bankruptWasReorg,
		...$commonParams,
		attribute: 'bankrupt_short_name_reorg',
		label: 'Сокращенное наименование реорганизованного ЮЛ',
		value: $currentData.bankruptShortNameReorg,
		placeholder: 'ООО «Компания»',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_short_name_reorg',
			resource: $procedure && $procedure.bankrupt.reorg,
			resourceProp: 'short_name',
		},
	};
</script>

{#if $currentData.bankruptWasReorg}
	<AutosaveInput {...bankruptShortNameReorg} on:update />
{/if}
