<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { isPresent, isBlank, isArray, toArray, toFloat, formatSum, formatDate } from '~/js/utils/tools.js';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import { procedure } from '~/js/global_stores/base.js';

	const dispatch = createEventDispatcher();

	export let formOptions;
	export let isCompleted;
	export const submitForm = () => formControl.submitForm();
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => (data = { ...data, ...changes });

	let formControl;
	let form, errorsBase, handleChange;
	let data = {};

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
	};
	const setDictionariesValues = values => {
		data = { ...values };
	};
	const setSubmitParams = () => ({});
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	formOptions={{ ...formOptions, model: 'meeting_discussion', controller: 'meetings/' + formOptions.params.meeting_id + '/meeting_discussions' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<!-- Вопрос -->
	<Field required={true} name="meeting_question_id" label="Вопрос собрания" disabled={formOptions.readonly}>
		<Select
			name="meeting_question_id"
			items={toArray(formOptions.params.meeting_questions.map(e => ({ value: e.id, label: e.name })))}
			value={$form.meeting_question_id}
			onchange={handleChange}
			disabled={formOptions.readonly}
			containerClasses="form-control"
			placeholder="Выбрать вопрос"
		></Select>
	</Field>
	<!-- Текст выступления -->
	<Field required={true} disabled={isBlank($form.meeting_question_id) || formOptions.readonly} name="note" label="Текст выступления">
		<textarea
			name="note"
			rows="5"
			class="form-control"
			bind:value={$form.note}
			on:change={handleChange}
			disabled={isBlank($form.meeting_question_id) || formOptions.readonly}
			placeholder="Кредитор Иванов И.И., который ознакомил участников собрания…"
		/>
	</Field>

	<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
</Form>
