<!-- @format -->
<script>
	import { transmitter, options } from 'base_stores';
	import { fetchDelete, fetchPost, fetchPut } from 'utils/fetch';
	import Select2 from '../../svelte/components/ui/select2.svelte';
	import Input from '../components/ui/input.svelte';
	import InputCurrency from '../components/ui/input_currency.svelte';
	import InputInteger from '../components/ui/input_integer.svelte';

	export let mailPackageId;
	export let mailRegistry;

	export let handleSubmit;
	export let handleDelete;
	export let handleCancel;

	export let requiredFieldsFilled = false;
	export let mailPackageDelete = false;

	const nullMailPackage = {
		dct_id: null,
		document_sheets_amount: 3,
		weight: '0,020',
		kind: $options.mail_package && $options.mail_package.mailing_kind.find(option => option.code === '2').id,
		envelope_kind: $options.mail_package && $options.mail_package.envelope_kind.find(option => option.code === 'value_from_mail').id,
		receipt_notification_kind: $options.mail_package && $options.mail_package.receipt_notification_kind.find(option => option.code === 'no_notification').id,
		mail_registry_id: mailRegistry && mailRegistry.id,
	};

	$: mailPackages = $transmitter && $transmitter.mail_packages;
	$: mailPackage = mailPackages.find(p => p.id === mailPackageId) || nullMailPackage;

	$: documentAddressee = mailPackage.dct_correspondent_out_name;
	// $: documentAddressee = mailPackage.dct_id && $options.dct && $options.dct.find(option => option.id === mailPackage.dct_id).correspondent_out_full_name
	$: documentPostalAddress = mailPackage.dct_correspondent_mailing_address;
	//$: documentPostalAddress = mailPackage.dct_id && $options.dct && $options.dct.find(option => option.id === mailPackage.dct_id).correspondent_mailing_address
	$: method = !!mailPackage.id ? fetchPut : fetchPost;
	$: requiredFieldsFilled =
		!!mailPackage.dct_id && !!mailPackage.weight && !!mailPackage.kind && !!mailPackage.envelope_kind && !!mailPackage.receipt_notification_kind;

	let handleOnChange = () => (mailPackage.weight = String((+mailPackage.document_sheets_amount + 1) * 0.005));

	handleSubmit = () => {
		method(`/api/private/mail_packages${mailPackage.id ? `/${mailPackage.id}` : ''}`, mailPackage).then(
			response => ($transmitter = { ...$transmitter, mail_packages: response.mail_packages }),
		);
	};

	handleDelete = () => {
		fetchDelete(`/api/private/mail_packages/${mailPackage.id}`, { mail_registry_id: mailRegistry.id }).then(
			response => ($transmitter = { ...$transmitter, mail_packages: response.mail_packages }),
		);
	};

	handleCancel = () => (mailPackageDelete = false);

	$: formFields = {
		outgoingDct: {
			model: 'mail_package',
			attribute: 'dct',
			required: true,
			placeholder: 'Выбрать документ',
			label: 'Исходящий документ',
			options: $options.dct && $options.dct.filter(option => !['newspaper', 'fedresource'].includes(option.correspondent_kind)),
		},
		fullName: {
			placeholder: 'Адресат',
			label: 'Полное наименование адресата',
			value: documentAddressee,
			disabled: true,
			info: { tip: 'Изменение данных доступно на странице документа' },
		},
		postalAddress: {
			placeholder: '100000, Московская область, г.Королев, ул. Первая, д.1',
			label: 'Почтовый адрес адресата',
			value: documentPostalAddress,
			disabled: true,
			info: { tip: 'Изменение данных доступно на странице документа' },
		},
		documentSheetsAmount: {
			label: 'Количество листов документа с учетом приложений',
		},
		weight: {
			required: true,
			label: 'Вес (кг)',
			scale: 3,
			hintData: { freeMessage: 'AI заполнил по формуле 1 лист = 5 гр., + 5 гр. на конверт' },
		},
		mailingType: {
			model: 'mail_package',
			attribute: 'mailing_type',
			required: true,
			label: 'Вид отправления',
			options: $options.mail_package && $options.mail_package.mailing_kind,
		},
		envelopeType: {
			model: 'mail_package',
			attribute: 'envelop_type',
			required: true,
			label: 'Тип конверта',
			options: $options.mail_package && $options.mail_package.envelope_kind,
		},
		receiptNotificationType: {
			model: 'mail_package',
			attribute: 'receipt_notification_kind',
			required: true,
			label: 'Тип уведомления о вручении',
			options: $options.mail_package && $options.mail_package.receipt_notification_kind,
		},
		tracknumber: {
			model: 'mail_package',
			attribute: 'tracknumber',
			required: false,
			label: 'Трек-номер',
			placeholder: '12345678901234',
			maskOptions: { mask: '00000000000000' },
		},
	};
</script>

<Select2 {...formFields.outgoingDct} bind:value={mailPackage.dct_id} />
<Input {...formFields.fullName} />
<Input {...formFields.postalAddress} />
<InputInteger {...formFields.documentSheetsAmount} bind:value={mailPackage.document_sheets_amount} bind:handleOnChange />
<InputCurrency {...formFields.weight} bind:value={mailPackage.weight} />
<Select2 {...formFields.mailingType} bind:value={mailPackage.kind} />
<Select2 {...formFields.envelopeType} bind:value={mailPackage.envelope_kind} />
<Select2 {...formFields.receiptNotificationType} bind:value={mailPackage.receipt_notification_kind} />
<Input {...formFields.tracknumber} bind:value={mailPackage.tracknumber} />
