<!-- @format -->
<script>
	import { options, fieldsReady } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';
	import { isBlank, toArray } from 'utils/tools';
	import { fetchGet } from 'utils/fetch_helpers';

	//export let fieldId

	let forceSave = false;
	let initFieldReady = true;

	//$: if (fieldId) { $fieldsReady[fieldId] = !initFieldReady }

	$: if (!($dct && $dct.substitutions.realty_location_id)) {
		forceSave = true;
	} else {
		forceSave = false;
		initFieldReady = false;
	}

	$: if (isBlank($options.region)) {
		fetchGet('/api/private/regions/get_options').then(result => ($options.region = result.options));
	}

	$: realtyLocationId = {
		...$commonParams,
		attribute: 'realty_location_id',
		label: 'Расположение объектов недвижимости',
		value: $currentData.realtyLocationId,
		options: $options && $options.region,
		placeholder: 'Выбрать расположение объекта недвижимости',
		forceSave: forceSave,
	};
</script>

<AutosaveSelect2Search {...realtyLocationId} />
