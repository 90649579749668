<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveTextarea from '~/svelte/components/ui/autosave_textarea.svelte';

	$: data = {
		...$commonParams,
		disabled: $commonParams.disabled || true,
		attribute: 'note',
		label: 'Дополнение',
		value: $currentData.meetingNote,
		placeholder: 'предварительно согласовав дату и время ознакомления по телефону 8 (000) 000-00-00',
	};
</script>

<AutosaveTextarea {...data} />
