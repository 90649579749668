<!-- @format -->
<!-- @format -->
<script>
	import { onMount, createEventDispatcher } from 'svelte';
	import { fetchGet, fetchPut } from '~/js/utils/fetch_helpers.js';
	import { transmitter, procedure, options, processing } from '~/js/global_stores/base.js';
	import { substitutions, requiredFieldsFilled, pdfDctData } from '~/svelte/dcts/stores/stores.js';
	import FormFields from '~/svelte/dcts/form_fields.svelte';
	import Template from '~/svelte/dcts/template.svelte';
	import Modal, { hideModal } from '~/svelte/components/modal.svelte';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import printJS from 'print-js';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';
	import PrintIcon from '~/svelte/_shared/print_icon.svelte';
	import DownloadIcon from '~/svelte/_shared/download_icon.svelte';
	import PdfIcon from '~/svelte/_shared/pdf_icon.svelte';
	import DocxIcon from '~/svelte/_shared/docx_icon.svelte';
	import { applyStyles, meetingCreditorData, meetingWorkerData } from '~/svelte/_shared/meeting_protocol.js';
	import SwitchBtn from '~/svelte/components/ui/switch_btn.svelte';

	const dispatch = createEventDispatcher();

	export let meeting;
	export let data;
	export let pageProtocolDisabled;
	export let pageProtocolApproveDisabled;

	let downloadUrl;
	let errors = [];
	let switchValue;

	const disabled_fields = [
		'courtId',
		'judicialActDate',
		'bankruptShortName',
		'bankruptFullName',
		'bankruptLegalAddress',
		'bankruptRegAddress',
		'bankruptBirthDate',
		'bankruptBirthPlace',
		'bankruptPassportNumber',
		'bankruptPassportDepartment',
		'bankruptPassportDate',
		'bankruptSnils',
		'arbitrManagerMailingAddress',
	];

	const initCurrentDocument = () => {
		if ($transmitter.dct.status == 'draft') {
			$transmitter.dct.disabled_fields = disabled_fields;

			if ($transmitter.meeting.kind == 'creditor') {
				$transmitter.dct.substitutions = { ...$transmitter.dct.substitutions, ...meetingCreditorData() };
			} else if ($transmitter.meeting.kind == 'worker') {
				$transmitter.dct.substitutions = { ...$transmitter.dct.substitutions, ...meetingWorkerData() };
			}
		}
	};

	const updateFormField = () => initCurrentDocument();

	const updateDocument = () => {
		const params = isDraft ? { dct: { ...$substitutions, status: 'approved' }, pdf_data: $pdfDctData } : { dct: { status: 'draft' } };

		$processing = true;

		fetchPut(`/api/private/documents/${$transmitter.dct.id}`, params)
			.then(result => {
				$transmitter.dct = { ...result.dct };
				$procedure = { ...result.procedure };
				dispatch('changeProtocolStatus', $transmitter.dct.status);
				$processing = false;
				//if ($transmitter.dct.status == 'draft') { initCurrentDocument() }
			})
			.catch(error => {
				errors = error;
				$processing = false;
				throw error;
			});
	};

	const printDocument = () => printJS({ printable: downloadUrl, type: 'pdf', showModal: true });

	onMount(() => {
		$processing = true;

		fetchGet('/api/private/meetings/' + meeting.id + '/get_protocol')
			.then(result => {
				$options = { ...$options, ...result.options };
				$requiredFieldsFilled = {};
				$transmitter.meeting = {
					...meeting,
					questions: [...data.questions],
					participants: [...data.participants],
					bulletins: [...data.bulletins],
					discussions: [...result.discussions],
					forms: [...data.forms],
					reasons: [...data.reasons],
					voting_meeting_statuses: [...data.voting_meeting_statuses],
					disabled_fields: [
						'meetingVotingQty',
						'meetingVotingSum',
						'meetingForm',
						'meetingFirst',
						'meetingReason',
						'meetingFromAt',
						'meetingVotingStatus',
						'meetingAddress',
						'meetingParticipantVotingProc',
					],
				};
				$transmitter.dct = { ...result.dct };
				initCurrentDocument();
				$processing = false;
			})
			.catch(error => {
				$processing = false;
				errors = error;
				throw error;
			});
	});

	$: downloadUrl = '/api/private/meetings/' + meeting.id + '/download_protocol?type=pdf';
	$: isDraft = meeting.protocol_status == 'draft';
	$: formReady = Object.values($requiredFieldsFilled).every(value => value);
</script>

<div class="document-form">
	<div class="toolbar">
		<div class="flex-slot">
			{#if meeting.status != 'canceled'}
				<ul class="nav navbar-top-links tools-bar flex-shrink-slot">
					<li class="m-r-sm" style="display: flex;">
						{#if isDraft}
							<div class="raf__with-tooltip">
								<button
									class="btn btn-sm btn-primary"
									class:btn-primary={!pageProtocolDisabled}
									class:btn-default={pageProtocolDisabled}
									disabled={pageProtocolDisabled || pageProtocolApproveDisabled || (isDraft && !(pageProtocolDisabled || formReady)) || $processing}
									on:click={updateDocument}
								>
									Утвердить
								</button>
								{#if pageProtocolDisabled}
									<div class="raf__tooltip">Недоступно. Сначала утвердите предыдущую вкладку.</div>
								{/if}
							</div>
						{:else}
							<button class="btn btn-sm btn-warning" disabled={pageProtocolApproveDisabled} on:click={updateDocument}> Изменить </button>
						{/if}
						<div class="btn btn-sm raf__with-tooltip">
							<IconInfo />
							<div class="raf__tooltip">Фиксирует результаты голосования, защищает их от случайных изменений</div>
						</div>
					</li>
				</ul>
				<ul class="nav navbar-top-links tools-bar flex-grow-slot">
					<li class="m-l-sm">
						{#if isDraft}
							<DownloadIcon disabled={true} />
						{:else}
							<a download href={downloadUrl}>
								<DownloadIcon />
							</a>
							<!-- <a href on:click|preventDefault={() => !pageProtocolDisabled && downloadDocument()}>
                <DownloadIcon />
              </a> -->
						{/if}
					</li>
					<li class="m-l-sm m-r-xs">
						{#if isDraft}
							<PrintIcon disabled={true} />
						{:else}
							<span style="cursor: pointer;" role="button" tabindex="0" on:click={printDocument} on:keypress|stopPropagation>
								<PrintIcon />
							</span>
						{/if}
					</li>
				</ul>
			{/if}
		</div>
	</div>
	<div class="content-body footer-margin">
		<div class="row" style="height: calc(100vh - 92px); margin-right: 0;">
			<div class="col-sm-5" class:hidden-mobile={!switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="overlay">
					<ErrorMessage message={errors} on:click={() => (errors = [])} />
					{#if $processing}
						загружается...
					{:else}
						<FormFields
							disabledForm={pageProtocolDisabled || pageProtocolApproveDisabled || $processing}
							on:change
							on:create={updateFormField}
							on:update={updateFormField}
							on:delete={updateFormField}
						/>
					{/if}
				</div>
			</div>
			<div class="col-sm-7" class:hidden-mobile={switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="preview">
					<div class="preview__page">
						{#if $processing}
							загружается...
						{:else}
							<Template {applyStyles} />
						{/if}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<SwitchBtn bind:switchValue />

<Modal modalId="print-modal" size="small">
	<div slot="footer" class="d-flex justify-content-around">
		<a
			download
			href={'/api/private/meetings/' + meeting.id + '/download_protocol?type=pdf'}
			class="download-icon btn btn-primary btn-outline"
			on:click={() => hideModal('print-modal')}
		>
			<PdfIcon />
			<span class="m-l-xs">pdf</span>
		</a>
		<a
			download
			href={'/api/private/meetings/' + meeting.id + '/download_protocol?type=docx'}
			class="download-icon btn btn-primary btn-outline"
			on:click={() => hideModal('print-modal')}
		>
			<DocxIcon />
			<span>docx</span>
		</a>
	</div>
</Modal>

<style lang="scss">
	.document-form {
		width: 100%;

		.toolbar {
			border-bottom: 1px solid #e7eaec;
			padding-bottom: 10px;
			padding-left: 15px;
			margin-left: -15px;

			.navbar-top-links li a {
				padding: 0px;
				min-height: auto;
			}
		}
		.content-body {
			background-color: #f3f3f4;
			.overlay,
			.preview {
				border-left: none;
				border-top: none;
			}
		}
	}
	.preview {
		flex-direction: column;
		align-items: center;
		color: #000;
		box-shadow:
			0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 3px 1px -2px rgba(0, 0, 0, 0.2),
			0 1px 5px 0 rgba(0, 0, 0, 0.12);
		border: 1px solid #e7eaec;
	}
	.preview__page {
		--aspect-ratio: 210 / 297;
		box-sizing: border-box;
		background-color: #fff;
		padding: 7.14% 7.14% 7.14% 9.52%;
	}
	.download-icon {
		padding: 10px;
		min-height: unset;
		fill: #1ab394;
		&:hover {
			fill: #fff;
		}
	}
</style>
