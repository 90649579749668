<!-- @format -->
<script>
	import Modal from '../../components/modal.svelte';
	import SpouseForm from './_form.svelte';
	import { cardsTitle } from 'utils/formats';

	export let spouseId;

	let submitButtonDisabled;
	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};
</script>

<Modal
	modalId="form-spouse-modal"
	{submitButtonDisabled}
	deleteIconDisabled={!spouseId}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	form={true}
	size="large"
>
	<h2 slot="header" class="spouse-modal__header">{cardsTitle('супруга(и)', spouseId)}</h2>
	<div slot="body">
		<SpouseForm {spouseId} bind:handleSubmit bind:handleCancel bind:handleDelete bind:submitButtonDisabled on:submit on:delete />
	</div>
</Modal>
