<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter, bankruptType, options } from 'base_stores';
	import { fetchPut, fetchPost } from 'utils/fetch';
	import { isPresent } from 'utils/tools';
	import { validate } from 'tools/validators';
	import Input from '~/svelte/components/ui/input.svelte';
	import Select2 from '~/svelte/components/ui/select2.svelte';
	import Modal, { showModal } from '~/svelte/components/modal.svelte';

	export let message;
	export let messageMode;

	let skipVerificationRequired = false;
	let initData = true;
	let creationConfirmed = false;
	let loadingKadCaseUrl = false;
	let mounting = false;

	$: arbitrProcedure = ($transmitter && $transmitter.procedure) || arbitrProcedure;
	$: procedureId = (arbitrProcedure && arbitrProcedure.id) || null;
	$: procedurePhase = arbitrProcedure && arbitrProcedure.phase;
	$: arbitrCase = arbitrProcedure && arbitrProcedure.arbitr_case;
	$: arbitrCaseId = arbitrCase && arbitrCase.id;
	$: arbitrCaseNumber = arbitrCase && arbitrCase.number;
	$: arbitrManager = arbitrProcedure && arbitrProcedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;
	$: bankrupt = arbitrProcedure && arbitrProcedure.bankrupt;
	$: bankruptId = bankrupt && bankrupt.id;
	$: transferProcedureId = arbitrProcedure && arbitrProcedure.transfer_procedure_id;
	$: kadCaseUrl = arbitrProcedure && arbitrProcedure.kad_case_url;
	$: if (arbitrManagerId) {
		localStorage.setItem('arbitrManagerId', arbitrManagerId);
	}

	let nullData = {
		arbitr_case: {
			number: null,
			errors: null,
		},
		procedure: {
			phase: null,
			referee_full_name: null,
			arbitr_case_id: null,
			arbitr_manager_id: null,
			bankrupt_id: null,
			bankrupt_type: $bankruptType,
			bankruptcy_petition_date: null,
			transfer_procedure_id: null,
			errors: null,
		},
	};

	let data = { ...nullData };

	$: if (!!procedureId || (!procedureId && !!procedurePhase && initData)) {
		data = {
			arbitr_case: {
				number: arbitrCaseNumber || null,
				errors: null,
			},
			procedure: {
				phase: procedurePhase || null,
				referee_full_name: (arbitrProcedure && arbitrProcedure.referee_full_name) || null,
				arbitr_case_id: arbitrCaseId || null,
				arbitr_manager_id: arbitrManagerId || null,
				bankrupt_id: bankruptId || null,
				bankrupt_type: $bankruptType,
				bankruptcy_petition_date: (arbitrProcedure && arbitrProcedure.bankruptcy_petition_date) || null,
				transfer_procedure_id: transferProcedureId,
				errors: null,
			},
		};
		if (!procedureId && !!procedurePhase) {
			initData = false;
		}
	}

	$: if (!procedureId && !procedurePhase) {
		data = { ...nullData };
	}

	$: formFields = {
		caseNumber: {
			required: true,
			disabled: !!procedureId,
			attribute: 'number',
			model: 'arbitr_case',
			label: 'Номер дела',
			placeholder: 'А00-0000/0000',
			errors: data.arbitr_case.errors && data.arbitr_case.errors.number,
		},
		phase: {
			required: true,
			disabled: !!procedureId,
			attribute: 'phase',
			model: 'procedure',
			label: 'Этап банкротства',
			options: $options.procedure_phase[$bankruptType],
			placeholder: 'Выбрать этап',
			errors: data.procedure.errors && data.procedure.errors.phase,
		},
		arbitrManager: {
			required: true,
			disabled: !!procedureId,
			attribute: 'arbitr_manager_id',
			model: 'procedure',
			label: 'Арбитражный управляющий',
			options: $options.arbitr_manager,
			placeholder: 'Выбрать АУ',
			modify: goToProcedures,
			errors: data.procedure.errors && data.procedure.errors.arbitr_manager_id,
		},
	};

	$: loading = !procedureId && creationConfirmed;

	$: kadCaseUrlValid = /^https:/.test(kadCaseUrl);

	const createProcedure = () => {
		creationConfirmed = true;

		if (fieldsValid()) {
			let params = { ...data, procedure: { ...data.procedure, bankrupt_type: $bankruptType } };

			for (let model in params) {
				delete params[model]['errors'];
			}

			fetchPost('/api/private/procedures', params)
				.then(response => (location.href = `/procedures/${response.procedure_id}`))
				.catch(errors => {
					creationConfirmed = false;
					console.log('errors: ' + JSON.stringify(errors));
				});
		}
	};

	const goToProcedures = value => {
		if (value === 'all') {
			location.href = `/procedures?show_procedures=false`;
		}
	};

	const fieldsValid = () => {
		let noErrorMarks = [];

		for (let model in data) {
			for (let attribute in data[model]) {
				if (attribute === 'errors') {
					continue;
				}

				const errors = validate(model, attribute, data[model][attribute], skipVerificationRequired);
				noErrorMarks.push(!errors);

				if (errors) {
					data[model]['errors'] = data[model]['errors'] ? { ...data[model]['errors'], [attribute]: errors } : { [attribute]: errors };
				} else {
					if (data[model]['errors']) {
						delete data[model]['errors'][attribute];
					}
				}
			}
		}
		return noErrorMarks.every(mark => mark);
	};

	const showNoticeModal = () => {
		if (fieldsValid()) {
			showModal('required-data-fields-modal');
		}
	};

	const cancelCreation = () => (creationConfirmed = false);

	const getKadCaseUrl = async () => {
		if (procedureId && !(kadCaseUrl && kadCaseUrlValid) && !mounting) {
			mounting = true;
			try {
				const response = await fetchPut(`/api/private/procedures/${procedureId}/set_kad_case_url`);

				if (isPresent(response.errors)) {
					if (loadingKadCaseUrl) {
						message = response.errors;
						messageMode = 'danger';
					}
				} else if (isPresent(response.info)) {
					if (loadingKadCaseUrl) {
						message = response.info;
						messageMode = 'warning';
					}
				} else {
					kadCaseUrl = response.kad_case_url;
					$transmitter = { ...$transmitter, procedure: { ...arbitrProcedure, kad_case_url: kadCaseUrl } };
					message = undefined;
					messageMode = undefined;
				}
			} catch (e) {
				if (loadingKadCaseUrl) {
					message = e;
					messageMode = 'danger';
				}
				throw e;
			} finally {
				mounting = false;
				loadingKadCaseUrl = false;
			}
		}
	};

	const goToKAD = async () => {
		loadingKadCaseUrl = true;
		await getKadCaseUrl();

		if (kadCaseUrl && kadCaseUrlValid) {
			loadingKadCaseUrl = false;
			window.open(kadCaseUrl, '_blank');
		}
	};

	onMount(async () => {
		await getKadCaseUrl();
	});
</script>

<div class="m-b-sm overlay procedure__required-data-fields">
	<Select2 {...formFields.arbitrManager} bind:value={data.procedure.arbitr_manager_id} />
	<div style="position: relative;">
		<Input {...formFields.caseNumber} bind:value={data.arbitr_case.number} />
		<button
			class="case-number btn"
			class:btn-warning={data.arbitr_case.number}
			disabled={!procedureId || loadingKadCaseUrl}
			style={`min-width: ${loadingKadCaseUrl ? 195 : 170}px;`}
			on:click={procedureId && !loadingKadCaseUrl && goToKAD()}
		>
			<span>
				Перейти&nbsp;в&nbsp;КАД.Арбитр
				{#if loadingKadCaseUrl}
					<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
				{/if}
			</span>
		</button>
	</div>
	<Select2 {...formFields.phase} bind:value={data.procedure.phase} />
	<div class="form-group">
		<button class="btn" class:btn-primary={!procedureId} disabled={!!procedureId || loading} on:click={!creationConfirmed && showNoticeModal()}>
			Сохранить
			{#if loading}
				<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
			{/if}
		</button>
	</div>
</div>
<Modal modalId="required-data-fields-modal" submitButtonAction={createProcedure} cancelButtonAction={cancelCreation}>
	<h2 slot="header">Подтвердите сохранение</h2>
	<div slot="body">
		<h5>После сохранения вы <strong>НЕ сможете</strong> изменить следующие поля:</h5>
		<ul>
			<li class="m-b-xs">Арбитражный управляющий</li>
			<li class="m-b-xs">Номер дела</li>
			<li class="m-b-xs">Этап банкротства</li>
		</ul>
	</div>
</Modal>

<style lang="scss">
	.case-number {
		position: absolute;
		left: calc(100% + 30px);
		top: 0.25em;
	}

	@media (max-width: 576px) {
		.case-number {
			left: calc(100% - 200px);
			top: 10.8em;
		}
	}
</style>
