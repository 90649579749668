<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveDadataInputEmail from '~/svelte/components/ui/autosave_dadata_input_email.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;
	$: arbitrManagerPerson = arbitrManager && arbitrManager.person;

	$: arbitrManagerEmail = {
		...$commonParams,
		attribute: 'arbitr_manager_email',
		label: 'Электронная почта АУ',
		value: $currentData.arbitrManagerEmail,
		placeholder: 'mail@mail.ru',
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_email',
			resource: arbitrManagerPerson,
			resourceProp: 'email',
		},
	};
</script>

<AutosaveDadataInputEmail {...arbitrManagerEmail} on:update />
