/** @format */

export const getCookie = (name, options = {}) => {
	let matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'));
	if (options['delete']) {
		setCookie(name, '', { 'max-age': -1 });
	}
	return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, options = {}) => {
	// при необходимости добавить другие значения по умолчанию
	options = { path: '/', ...options };

	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	}

	let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

	for (let optionKey in options) {
		updatedCookie = `${updatedCookie}; ${optionKey}`;
		if (options[optionKey] !== true) {
			updatedCookie = `${updatedCookie}=${options[optionKey]}`;
		}
	}

	document.cookie = updatedCookie;
};

export const deleteCookie = name => setCookie(name, '', { 'max-age': -1 });
