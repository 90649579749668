<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;
	$: sro = arbitrManager && arbitrManager.sro;

	$: sroRegDate = {
		...$commonParams,
		attribute: 'sro_reg_date',
		label: 'Дата регистрации СРО',
		value: $currentData && $currentData.sroRegDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.sro_reg_date',
			resource: arbitrManager && arbitrManager.sro,
			resourceProp: 'reg_date',
		},
	};
</script>

<AutosaveDatepicker {...sroRegDate} on:update />
