<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '../../../stores/stores';
	import AutosaveDadateAddress from '../../../../components/ui/autosave_dadate_input_address.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: if ($dct.form_fields.includes('bankruptMailingAddress')) {
		$requiredFieldsFilled.bankruptMailingAddress = !!bankruptMailingAddress.value;
	}
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('bankruptMailingAddress');

	$: bankruptMailingAddress = {
		...$commonParams,
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		required: true,
		attribute: 'bankrupt_mailing_address',
		label: 'Почтовый адрес должника',
		value: $currentData && $currentData.bankruptMailingAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_mailing_address',
			resource: $procedure && $procedure.bankrupt,
			resourceProp: 'mailing_address',
		},
	};
</script>

<AutosaveDadateAddress {...bankruptMailingAddress} on:update />
