<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: livingWageSourceData = {
		...$commonParams,
		attribute: 'living_wage_source_data',
		label: 'Источник данных о прожиточном минимуме',
		value: $currentData && $currentData.livingWageSourceData,
		placeholder: 'Постановление Минтруда региона №0 от 00.00.0000 г.',
	};
</script>

<AutosaveInput {...livingWageSourceData} />
