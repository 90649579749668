<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveDatepicker from '../../../components/ui/autosave_datepicker.svelte';

	$: propertyAppraisalDate = {
		...$commonParams,
		attribute: 'property_appraisal_date',
		label: 'Дата оценки имущества',
		value: $currentData && $currentData.propertyAppraisalDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
	};
</script>

<AutosaveDatepicker {...propertyAppraisalDate} />
