<!-- @format -->
<div class="row">
	<div class="col-sm-4"><strong>Первая очередь:</strong></div>
	<div class="col-sm-8 m-b-xs">
		Требования по текущим платежам, связанным с уплатой алиментов, судебными расходами по делу о банкротстве гражданина, выплатой вознаграждения финансовому
		управляющему, взысканием задолженности по выплате вознаграждения лицам, привлеченным финансовым управляющим для обеспечения возложенных на него обязанностей
		в деле о банкротстве гражданина.
	</div>
	<div class="col-sm-4"><strong>Вторая очередь:</strong></div>
	<div class="col-sm-8 m-b-xs">Требования о выплате выходных пособий и об оплате труда лиц, работающих или работавших по трудовым договорам.</div>
	<div class="col-sm-4"><strong>Третья очередь:</strong></div>
	<div class="col-sm-8">
		Требования о внесении платы за жилое помещение и коммунальные услуги, в том числе об уплате взноса на капитальный ремонт общего имущества в многоквартирном
		доме.
	</div>
	<div class="col-sm-4"><strong>Четвертая очередь:</strong></div>
	<div class="col-sm-8">Требования по иным текущим платежам.</div>
</div>
