<!-- @format -->
<script>
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';

	$: if ($dct.form_fields.includes('transferringDocumentsParty')) {
		$requiredFieldsFilled.transferringDocumentsParty = !!transferringDocumentsParty.value.id;
	}

	const transferringDocumentsPartyOptions = [
		{ id: 'bankrupt', text: 'Должник' },
		{ id: 'ex_arbitr_manager', text: 'Бывший АУ' },
	];

	$: transferringDocumentsParty = {
		...$commonParams,
		required: true,
		attribute: 'transferring_documents_party',
		label: 'Сторона акта',
		value: transferringDocumentsPartyOptions.find(option => option.id === $currentData.transferringDocumentsParty) || { id: null },
		options: transferringDocumentsPartyOptions,
		placeholder: 'Выбрать сторону акта',
	};
</script>

<AutosaveSelect2Search {...transferringDocumentsParty} />
