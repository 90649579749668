<!-- @format -->
<script>
	import { isArray } from 'utils/tools';

	export let errors;
</script>

<span class="form-text text-danger m-b-none m-l-xs" class:hidden={!errors}>
	{#if isArray(errors)}
		{errors && errors.join('; ')}
	{:else}
		{errors}
	{/if}
</span>
