<!-- @format -->
<div class="row">
	<div class="col-sm-4"><strong>Первая очередь:</strong></div>
	<div class="col-sm-8 m-b-xs">
		Требования граждан, перед которыми должник несет ответственность за причинение вреда жизни или здоровью, а также требования по компенсации морального вреда.
	</div>
	<div class="col-sm-4"><strong>Вторая очередь:</strong></div>
	<div class="col-sm-8 m-b-xs">
		Требования по выплате выходных пособий и оплате труда лиц, работающих или работавших по трудовому договору, и по выплате вознаграждений по авторским
		договорам.
	</div>
	<div class="col-sm-4"><strong>Третья очередь:</strong></div>
	<div class="col-sm-8">Расчеты с другими кредиторами.</div>
</div>
