<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('bankruptBirthDate');

	$: bankruptBirthDate = {
		...$commonParams,
		hidden: !$currentData.insertFirstParagraph,
		attribute: 'bankrupt_birth_date',
		label: 'Дата рождения должника',
		value: $currentData && $currentData.bankruptBirthDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_birth_date',
			resource: $procedure && $procedure.bankrupt,
			resourceProp: 'birth_date',
		},
	};
</script>

{#if !bankruptBirthDate.hidden}
	<AutosaveDatepicker {...bankruptBirthDate} on:update />
{/if}
