<!-- @format -->
<script>
	import InputErrorsString from './input_errors_string.svelte';

	export let attribute;
	export let model;
	export let label = null;
	export let options = [];
	export let columnCount = 1;
	export let value;
	export let errors;

	const elementId = `f${(+new Date()).toString(16)}`;
	const id = `checkboxes-${model}-${attribute}`; //-${elementId}`
</script>

<div class="form-group row">
	<label for={id} class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
		{label}
		<attr class="required">*</attr>
	</label>
	<div {id} class='col-sm-8 col-lg-9 col-xl-8' style={`column-count: ${columnCount}`}>
		{#each options as option}
			<label class="d-flex">
				<input type="checkbox" bind:group={value} disabled={option.fixed} class="align-self-center m-r-xs" value={option.id} />
				{option.text}
			</label>
		{/each}
		<InputErrorsString {errors} />
	</div>
</div>
