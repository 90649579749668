<!-- @format -->
<script>
	import { transmitter, processing } from 'base_stores';
	import { fetchPut } from 'utils/fetch';
	import { isPresent } from 'utils/tools';
	import OCRDashbord from './_ocr_dashboard.svelte';
	import Modal from '../components/modal.svelte';

	$: arbitrManager = $transmitter?.arbitr_manager || arbitrManager;

	const deleteJudicialAct = () => {
		$processing = true;

		fetchPut(`/api/private/arbitr_managers/${arbitrManager.id}/delete_passport`).then(result => {
			$processing = false;
			$transmitter = { ...$transmitter, ...result };
		});
	};
</script>

<div class={`m-b-sm overlay ocr-block`}>
	<OCRDashbord type={'resolution'} on:deleteJudicialAct={() => {}} />
	{#if isPresent(arbitrManager?.passport)}
		<div class="flex justify-content-center height__full border__solid-grey m-t-sm">
			<div class="scrollable h-100">
				{#each arbitrManager?.passport as page, idx}
					<div class:mb-2={idx !== arbitrManager?.passport.length - 1}>
						<img id={`imgId-${idx}`} class="d-block w-100" src={page} alt="Скан паспорта" />
					</div>
				{/each}
			</div>
		</div>
	{/if}
</div>
<Modal modalId="delete-judicial-act-modal" submitButtonText="Удалить" submitButtonCss="btn-danger" submitButtonAction={deleteJudicialAct}>
	<h3 slot="header">Удалить прикрепленный файл?</h3>
</Modal>

<style>
	.border__solid-grey {
		border: solid 1px #bebebe;
	}

	.height__full {
		height: calc(100vh - 130px);
	}

	.scrollable {
		overflow: auto;
	}
</style>
