/** @format */

$.fn.setCursorPosition = function (pos) {
	this.each(function (index, elem) {
		if (elem.setSelectionRange) {
			elem.setSelectionRange(pos, pos);
		} else if (elem.createTextRange) {
			var range = elem.createTextRange();
			range.collapse(true);
			range.moveEnd('character', pos);
			range.moveStart('character', pos);
			range.select();
		}
	});
	return this;
};

const searchParticle = () => {
	const q = location.href.match(/search_particle=((?!&).)*/);
	return q ? q[0].replace(/search_particle=/, '') : '';
};

let timer;

const debounce = (method, delay = 100) => {
	clearTimeout(timer);
	timer = setTimeout(method, delay);
};

$(document).on('turbolinks:load', () => {
	const $input = $('[name=search_particle]');
	$input.focus();
	$input.setCursorPosition(searchParticle().length);

	let sort = location.href.match(/order\[(((?!\?).)*)\]=(asc|desc)/) || location.href.match(/order%5B(((?!\?).)*)%5D=(asc|desc)/); //[0] || ''
	sort = (sort ? sort[0] : '').replace(/%5B/, '[').replace(/%5D/, ']');

	const $dataSortBy = $('[data-sort-by]');

	$dataSortBy.each(function () {
		const $this = $(this);
		if (RegExp(`\\[${$this.attr('data-sort-by')}\\]`).test(sort) || (!sort && ['created_at', 'expires_at'].includes($this.attr('data-sort-by')))) {
			const direct = sort.match(RegExp(`order\\[${$this.attr('data-sort-by')}\\]=(asc|desc)`));
			$this.attr('data-sort-direction', direct ? direct[1] : 'desc');
		} else if ($this.attr('data-sort-direction')) {
			//sort = `${sort}${sort ? '&' : ''}order[${$this.attr('data-sort-by')}]=${$this.attr('data-sort-direction')}`
		}
	});

	$('[name=per]').on('click', e => {
		const href = /per=/.test(location.href)
			? location.href.replace(/per=\d+/, `per=${e.target.value}`)
			: `${location.href}${/\?/.test(location.href) ? '&' : '?'}per=${e.target.value}`;
		location.href = href;
	});

	$('[name=search_particle]').on('keyup', async e => {
		if (searchParticle() == e.target.value) {
			return;
		}

		debounce(() => {
			const href = /search_particle=/.test(location.href)
				? location.href.replace(/search_particle=((?!&).)*/, `search_particle=${e.target.value}`)
				: `${location.href}${/\?/.test(location.href) ? '&' : '?'}search_particle=${e.target.value}`;
			location.href = href.replace(/page=\d*&?/, '');
		}, 500);
	});

	$dataSortBy.on('click', function () {
		$this = $(this);
		const orderAttr = $this.attr('data-sort-by');
		const orderDirectionCurrent = $this.attr('data-sort-direction');

		$dataSortBy.removeAttr('data-sort-direction');

		if (RegExp(orderAttr).test(sort)) {
			if (orderDirectionCurrent == 'asc') {
				sort = sort.replace(RegExp(`order\\[${orderAttr}\\]=asc`), `order[${orderAttr}]=desc`);
			} else {
				sort = sort.replace(RegExp(`order\\[${orderAttr}\\]=desc`), '');
			}
		} else {
			//sort = `${sort}${sort ? '&' : ''}order[${$this.attr('data-sort-by')}]=asc`
			sort = `${sort ? '&' : ''}order[${$this.attr('data-sort-by')}]=asc`;
		}

		let href =
			/order\[/.test(location.href) || /order%5B/.test(location.href)
				? location.href.replace(/order\[(((?!\?).)*)\]=(asc|desc)/, sort).replace(/order%5B(((?!\?).)*)%5D=(asc|desc)/, sort)
				: `${location.href}${/\?/.test(location.href) ? '&' : '?'}${sort}`;

		location.href = href.replace(/&&/, '&').replace(/&$/, '').replace(/\?$/, '');
	});
});
