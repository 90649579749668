<!-- @format -->
<script>
	import { procedure } from 'base_stores';
	import moment from 'moment';
	import { showModal } from '~/svelte/components/modal.svelte';
	import ProlongationModal from '~/svelte/procedures/prolongations/modal.svelte';

	$: procedureCompletionDate = $procedure && $procedure.completion_date;
	$: prolongations = $procedure && $procedure.prolongations;

	let prolongationIndex;
	let indexCollapse = false;

	const openModalForm = index => {
		prolongationIndex = index;
		showModal('form-prolongation-modal');
	};
</script>

<div class="form-group row">
	<h4>
		<span
			class="prolongations col-sm-4 col-lg-3 col-xl-4 text-left"
			role="button"
			tabindex="0"
			on:click={() => (indexCollapse = !indexCollapse)}
			on:keypress|stopPropagation
		>
			<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
			<span class="header">{`Продления процедуры (${prolongations.length})`}</span>
		</span>
	</h4>
</div>
<div id="collapse-prolongations" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!procedureCompletionDate} on:click={procedureCompletionDate && openModalForm()}>
			+ Продление
		</button>
		<div class="p-1">
			{#if prolongations.length}
				<ol class="block-list">
					{#each prolongations as prolongation, index}
						{@const lastItem = index + 1 == prolongations.length}
						<li>
							<div
								disabled={!procedureCompletionDate}
								class="float-left"
								class:simulated-link={lastItem}
								role="button"
								tabindex="0"
								on:keypress|stopPropagation
								on:click={lastItem && procedureCompletionDate && openModalForm(index)}
							>
								{[
									prolongation.period ? `на ${prolongation.period} мес.` : null,
									prolongation.completion_date ? `до ${moment(prolongation.completion_date).format('DD.MM.YYYY')} г.` : null,
								].join(' ')}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>

<ProlongationModal {prolongationIndex} />

<!--style lang="scss">
  .prolongation-btn {
    margin-top: 2px;
  }
</style-->
