<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: judicialActPagesNumber = {
		...$commonParams,
		attribute: 'judicial_act_pages_number',
		label: 'Копия судебного акта - количество листов',
		placeholder: 'Ввести количество листов',
		value: $currentData?.judicialActPagesNumber,
		type: 'number',
		positive: true,
	};
</script>

<AutosaveInput {...judicialActPagesNumber} on:update />
