<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import SpecialistForm from './form.svelte';
	import { cardsTitle } from 'utils/formats';

	export let specialistId;
	export let disabled = false;

	let submitButtonDisabled;
	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};
</script>

<Modal
	modalId="form-specialist-modal"
	submitButtonDisabled={submitButtonDisabled || disabled}
	deleteIconDisabled={!specialistId || disabled}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	form={true}
	size="large"
>
	<h2 slot="header" class="specialist-modal__header">{cardsTitle('привлеченного специалиста', specialistId)}</h2>
	<div slot="body">
		<SpecialistForm {specialistId} {disabled} bind:handleSubmit bind:handleCancel bind:handleDelete bind:submitButtonDisabled on:submit on:delete />
	</div>
</Modal>
