<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;

	$: arbitrManagerPhone = {
		...$commonParams,
		attribute: 'arbitr_manager_phone',
		label: 'Телефон АУ',
		value: $currentData.arbitrManagerPhone,
		placeholder: '+7 (___) ___-__-__',
		maskOptions: { mask: '+7 (000) 000-00-00' },
		modify: phone => phone && phone.replace(/\+7|\D/g, ''),
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_phone',
			resource: arbitrManager && arbitrManager.person,
			resourceProp: 'phone',
		},
	};
</script>

<AutosaveInput {...arbitrManagerPhone} on:update />
