<!-- @format -->
<script>
	import { procedure, options, autosaveStatus } from 'base_stores';
	import { onMount } from 'svelte';
	import DctMetaRecord from 'models/DctMetaRecord';
	import DctCorrespondentKind from 'models/DctCorrespondentKind';
	import Header from '~/svelte/components/header.svelte';
	import Modal from '~/svelte/components/modal.svelte';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Editor from '~/svelte/wysiwyg_editors/tiny_mce/Editor.svelte';
	import Dashboard from '~/svelte/templates/dashboard.svelte';
	import TemplateFields from '~/svelte/templates/fields/index.svelte';
	import SwitchBtn from '~/svelte/components/ui/switch_btn.svelte';
	import fileDownload from 'js-file-download';
	import { isPresent, debounce, toArray } from 'utils/tools';
	import { setCookie } from 'utils/cookies';
	import { fetchGet } from 'utils/fetch_helpers';
	import Case from 'case';
	import moment from 'moment';

	export let id;
	export let disabled = false;

	let errors = [];
	let templateErrors = [];
	let switchValue;
	let dctMetaRecord = {};
	let previewPageWidth;
	let toxEditorHeaderHeight;
	let headerHeight;
	let refreshEditor = false;
	let allFields = [];
	let tags = [];
	let deleteIconDisabled = false;

	const editorId = `tinymce_${Math.floor(Math.random() * 1000000000)}_${String(Date.now())}`;

	$: editorConfig = {
		menubar: false,
		selector: `#${editorId}`,
		theme: 'rafsilver',
		skin: 'oxide-raf',
		content_css: 'oxide-raf',
		placeholder: 'Печатайте здесь...',
		plugins: 'anchor autolink autoresize charmap codesample lists advlist searchreplace table wordcount openfile raftags',
		toolbar:
			' openfile | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight | numlist bullist outdent indent | table | searchreplace removeformat | raftags',
		format_empty_lines: true,
		format_noneditable_selector: 'span',
		font_size_formats: '6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 20pt 24pt 30pt 36pt 48pt',
		default_font_stack: ['Arial', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'sans-serif', 'Times New Roman'],
		font_family_formats:
			'Arial=Liberation Sans,FreeSans,Arial,open-sans; Times New Roman=Liberation Serif,FreeSerif,Times New Roman; Courier New=Courier New,FreeMono;',
		line_height_formats: '1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 2 2.2 2.5 3 3.5 4',
		table_default_styles: { 'font-family': 'Arial', 'font-size': '11pt', width: '100%' },
		indentation: '30pt',
		indent_use_margin: true,
		raftags: [{ name: 'Поля', tags: tags }],
		toolbar_mode: 'wrap',
		statusbar: false,
		tinycomments_mode: 'embedded',
		tinycomments_author: 'Author name',
		language_url: `${location.origin}/js/wysiwyg_editors/tinymce/langs/ru.js`,
		language: 'ru',
		save_enablewhendirty: false,
		noneditable_class: 'raf_noneditable',
		file_picker_types: 'file',
		content_style: 'body { font-size: 11pt; }',
	};

	$: formFields = {
		name: {
			required: true,
			attribute: 'name',
			model: 'meta_record',
			url: '/api/private/meta_records',
			id: id,
			placeholder: 'Задайте имя шаблона',
			label: 'Название шаблона',
			minHeight: 80,
			disabled,
		},
		correspondentKind: {
			required: true,
			attribute: 'correspondent_kind',
			model: 'meta_record',
			url: '/api/private/meta_records',
			id: id,
			placeholder: 'Выбрать вид корреспондента',
			label: 'Вид корреспондента',
			options: $options.correspondentKind,
			value: toArray($options.correspondentKind).find(o => o.id == dctMetaRecord.correspondent_kind),
			disabled,
			addOption: DctCorrespondentKind.addAsOptions,
		},
	};

	const prepareTemplate = t => {
		return (t || '').replace(/<span((?!<).)*?mce-content-raf-tag((?!<).)*?((?!<span|span>).)*?(<span(.)*?span>)*?((?!<span|span>).)*?span>/g, str =>
			str.match(/[%#]{2}.+[%#]{2}/),
		);
	};

	const comeBack = async () => {
		setCookie('_rafRefreshDctMetaRecords', true);

		debounce(() => (location.href = document.referrer), 700);
	};

	const handleDelete = () => {
		DctMetaRecord.delete(id);
		deleteIconDisabled = false;

		comeBack();
	};

	const downloadTemplate = () => {
		const newTemplate = prepareTemplate(dctMetaRecord.template);
		fileDownload(newTemplate, `template-${dctMetaRecord.name}-${$procedure.phase}-${moment().format('YYYYMMDD-HHmmss')}.txt`);
	};

	const fillTemplate = initTemplate => {
		return (initTemplate || '').replace(/(%%[0-9A-Z_-]+%%)/g, str => {
			const tag = tags.find(t => t[0] == str);
			const title = tag ? tag[1] : '';
			return `<span class='mce-content-raf-tag raf_noneditable' title='${title}' data-tag='${str}'>${title}</span>`;
		});
	};

	const autosaveFormFields = async fields => {
		//		console.log('fields: ' + JSON.stringify(fields))

		fields = actualizedFields(fields);
		const formOptions = [...new Set(fields.map(f => allFields.find(i => i.code == f).options).flat())].filter(i => i);
		const prevFields = dctMetaRecord.form_fields;
		await updateDctMetaRecord({ ...dctMetaRecord, template: prepareTemplate(dctMetaRecord.template), form_fields: fields, form_options: formOptions });
		if (
			isPresent([...fields.filter(f => !prevFields.includes(f)), ...prevFields.filter(f => !fields.includes(f))]) ||
			!fields.every((f, i) => f == prevFields[i])
		) {
			refreshEditor = true;
		}
	};

	let currentCorrespondentKind;

	$: if (dctMetaRecord.correspondent_kind != currentCorrespondentKind) {
		autosaveFormFields(dctMetaRecord.form_fields);
		currentCorrespondentKind = dctMetaRecord.correspondent_kind;
	}

	const actualizedFields = dirtyFields => {
		let fields = dirtyFields;

		if (toArray(dirtyFields).includes('correspondentFullName')) {
			if (!dctMetaRecord.correspondent_kind) {
				dctMetaRecord.correspondent_kind = 'addressee';
			}

			if (['addressee', 'court_usual'].includes(dctMetaRecord.correspondent_kind)) {
				fields = fields.filter(f => f != 'correspondentId');
			} else if (!fields.includes('correspondentId')) {
				fields.splice(fields.indexOf('correspondentFullName'), 0, 'correspondentId');
			}

			if (['addressee', 'court', 'court_usual', 'bki', 'rsa', 'favt', 'fsis', 'bank', 'creditor'].includes(dctMetaRecord.correspondent_kind)) {
				fields = fields.filter(f => f != 'regionId');
			} else if (!fields.includes('regionId')) {
				fields.splice(fields.indexOf('correspondentId'), 0, 'regionId');
			}
		} else {
			dctMetaRecord.correspondent_kind = null;
		}

		return fields;
	};

	const autosaveTemplate = async () => {
		updateDctMetaRecord({ ...dctMetaRecord, template: prepareTemplate(dctMetaRecord.template) });
	};

	const updateDctMetaRecord = async params => {
		$autosaveStatus = null;

		try {
			const response = await DctMetaRecord.update(params);

			dctMetaRecord = DctMetaRecord.new(response.meta_record);
			formTags();
			dctMetaRecord = { ...dctMetaRecord, template: fillTemplate(dctMetaRecord.template) };
			$autosaveStatus = 'saved';
		} catch (e) {
			templateErrors = e;
			$autosaveStatus = 'not_saved';
			throw e;
		}
	};

	const getUsedFields = () => {
		$options.usedFields = [...new Set(Array.from((dctMetaRecord.template || '').matchAll(/%%[0-9A-Z_-]+%%/g)).map(f => Case.camel(f[0])))];
	};

	$: getUsedFields(dctMetaRecord.template);

	const formTags = () =>
		(tags = (dctMetaRecord.form_fields || [])
			.map(f => {
				const field = allFields.find(i => i.code == f && !i.metadata.auxiliary);
				// [код поля, название поля, обязательность, очерёдность]
				return field ? [`%%${Case.constant(f)}%%`, field.name, false, field.sequence] : null;
			})
			.filter(i => i));

	const getDctMetaRecord = async id => {
		let response = await fetchGet('/api/private/form_fields');
		$options.groupedFormFields = response.grouped_form_fields;
		allFields = ($options.groupedFormFields || []).map(o => JSON.parse(o.fields)).flat();
		response = await DctMetaRecord.get(id);
		dctMetaRecord = response.meta_record || DctMetaRecord.new();
		formTags();
		dctMetaRecord.template = [null, undefined].includes(dctMetaRecord.template) ? '' : (dctMetaRecord.template = fillTemplate(dctMetaRecord.template));
		refreshEditor = true;
	};

	onMount(() => {
		DctCorrespondentKind.loadAsOptions();
		getDctMetaRecord(id);
	});
</script>

<Header bind:headerHeight>
	<span slot="name">
		<span role="button" tabindex="0" style="vertical-align: middle;" on:click={comeBack} on:keypress|stopPropagation>
			<iconify-icon icon="fa-solid:chevron-left" class="m-r-xs hoverable" />
		</span>
		Шаблон документа
	</span>
	<div slot="dashboard">
		<Dashboard {dctMetaRecord} {switchValue} bind:deleteIconDisabled on:downloadTemplate={downloadTemplate} />
	</div>
</Header>
<div class="flex m-b-lg footer-margin">
	<div class="document-form">
		<div class="row" style="height: calc(100vh - 92px); margin-right: 0;">
			<div class="col-12 col-sm-5 footer-margin" class:hidden-mobile={!switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="overlay">
					<ErrorMessage cssClass="m-t-none" message={errors} on:click={() => (errors = [])} />
					<AutosaveInput {...formFields.name} bind:value={dctMetaRecord.name} />
					{#if (dctMetaRecord.form_fields || []).includes('correspondentFullName')}
						<AutosaveSelect2Search
							{...formFields.correspondentKind}
							on:update={({ detail: e }) => (dctMetaRecord.correspondent_kind = e.meta_record.correspondent_kind)}
						/>
					{/if}
					<TemplateFields currentFields={dctMetaRecord.form_fields} on:submit={({ detail: cFields }) => autosaveFormFields(cFields)} />
				</div>
			</div>
			<div class="col-12 col-sm-7 offset-sm-5 footer-margin" class:hidden-mobile={switchValue} style="position: absolute;">
				<sub class="beta" style:top={`${headerHeight + toxEditorHeaderHeight - 15}px`}>beta</sub>
			</div>
			<div class="col-12 col-sm-7 footer-margin" class:hidden-mobile={switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="preview">
					<div class="editing__page" bind:offsetWidth={previewPageWidth}>
						<ErrorMessage message={templateErrors} on:click={() => (errors = [])} />
						<form>
							<Editor
								id={editorId}
								conf={editorConfig}
								bind:refreshEditor
								bind:value={dctMetaRecord.template}
								bind:toxEditorHeaderHeight
								on:blur={autosaveTemplate}
								on:click={_e => {
									/*console.log(e.pageX + ' : ' + e.pageY)*/
								}}
								--editor-header-width={`${previewPageWidth}px`}
								--editor-header-margin-left={`-${previewPageWidth * 0.0952}px`}
								--editor-header-height={`${toxEditorHeaderHeight}px`}
							/>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<SwitchBtn bind:switchValue />
<Modal
	modalId="delete-template-modal"
	submitButtonText="Удалить"
	submitButtonCss="btn-danger"
	submitButtonAction={handleDelete}
	cancelButtonAction={() => (deleteIconDisabled = false)}
>
	<h2 slot="header">Удалить шаблон?</h2>
</Modal>

<style lang="scss">
	.beta {
		background: #ed5565;
		border-radius: 1em;
		color: #fff;
		display: inline-block;
		font-size: 0.7em;
		font-weight: 600;
		line-height: 1.5;
		padding: 0 0.1em 0 0.4em;
		letter-spacing: 0.4em;
		vertical-align: text-bottom;
		text-rendering: optimizeLegibility;

		position: fixed;
		z-index: 9;
		right: 33px;
		height: 1.5em;
	}

	div[slot$='dashboard'],
	.document-form {
		width: 100%;
	}

	.preview {
		flex-direction: column;
		align-items: center;
		color: #000;
		box-shadow:
			0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 3px 1px -2px rgba(0, 0, 0, 0.2),
			0 1px 5px 0 rgba(0, 0, 0, 0.12);
	}

	.editing__page {
		--aspect-ratio: 210 / 297;
		box-sizing: border-box;
		background-color: #fff;
		padding: 5px 7.14% 7.14% 9.52%;
		position: relative;
		margin-top: 1px;
	}

	@media (max-width: 576px) {
		.beta {
			right: 7px;
		}
	}
</style>
