<!-- @format -->
<script>
	import { autosaveStatus, transmitter as transmitter0 } from 'base_stores';
	import { onMount } from 'svelte';
	import iMask from 'imask';
	import 'tempusdominus-bootstrap-4';
	import '~/js/patch_for_datetimepicker';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import { validate } from 'tools/validators';
	import { randomString } from 'utils/tools';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import moment from 'moment';

	export let attribute;
	export let model;
	export let url;
	export let id;
	export let childModel;
	export let childId;
	export let additionalAttributes;
	export let label;
	export let transmitter;
	export let skipVerificationRequired = true;
	export let period = [];
	export let index = 0;
	export let required = false;
	export let disabled = false;
	export let forceSave = false;
	export let placeholder;
	export let hintData;
	export let additionalParams = {};
	export let labelAlign = 'left';
	export let saveAutomatically = true;

	let inputField;
	let dataErrors;
	let datetimepickerNode;
	let maskRef;
	let previousValue = null;
	let fValue = null;
	let countdown = 1;

	const datepickerId = `date-period-${model}-${attribute}-${randomString()}`;

	$: value = period[index];
	$: method = !!id ? fetchPut : fetchPost;
	$: if (!!id) {
		url = `${url}/${id}`;
	}
	$: fValue = value ? moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY') : null;
	$: isSuccess = value && !dataErrors;
	$: forceVal = value;
	$: if (forceSave && previousValue !== value) {
		handleChange();
	}

	$: if (!previousValue && countdown > 0 && value === undefined) {
		countdown = countdown - 1;
	}
	$: if (!previousValue && countdown === 0 && value !== undefined) {
		previousValue = value;
		countdown = countdown - 1;
	}

	const handleChange = () => {
		$autosaveStatus = null;

		if (!moment(fValue, 'DD.MM.YYYY', true).isValid()) {
			fValue = value = null;
		}
		if (previousValue !== value) {
			dataErrors = validate(model, attribute, forceSave ? forceVal : value, skipVerificationRequired);

			if (!dataErrors) {
				period[index] = forceSave ? forceVal : value;
				let params = { [model]: { [attribute]: period }, [`${childModel || model}_id`]: childId || id, ...additionalParams };

				if (additionalAttributes) {
					additionalAttributes.forEach(attribute => {
						if (attribute.previousValue === previousValue || !previousValue) {
							params[model] = { ...params[model], [attribute.name]: attribute.prepareValue(value) };
						}
					});
				}

				method(url, params)
					.then(response => {
						if (!forceSave && JSON.stringify(response) !== '{}') {
							$autosaveStatus = 'saved';
						}
						transmitter = { ...transmitter, ...response };
						$transmitter0 = { ...$transmitter0, ...response };
						previousValue = value;
						return;
					})
					.catch(_errors => {
						inputField.focus();
						$autosaveStatus = 'not_saved';
						return;
					});
			}
			$autosaveStatus = 'not_saved';
			if (transmitter && transmitter[model]) {
				transmitter[model][attribute] = value;
			}
			$transmitter0[model] = { ...$transmitter0[model], [attribute]: value };
		}
	};

	const updateMaskValue = () => maskRef.updateValue(maskRef.value);

	onMount(() => {
		maskRef = iMask(inputField, { mask: Date });
		updateMaskValue();

		datetimepickerNode = jQuery(`#${datepickerId}`);
		datetimepickerNode.datetimepicker({
			locale: 'ru',
			format: 'DD.MM.YYYY',
			useCurrent: false,
			allowInputToggle: true,
			keyBinds: {
				escape: null,
				up: null,
				down: null,
				right: null,
				left: null,
				delete: null,
			},
		});
		datetimepickerNode.on('change.datetimepicker', event => {
			event.stopPropagation();
			updateMaskValue();
			fValue = maskRef.value;

			if (/\d/.test(fValue)) {
				if (/\d{2}$/.test(fValue.split('.')[0]) && /\d{2}$/.test(fValue.split('.')[1]) && /\d{4}$/.test(fValue.split('.')[2])) {
					value = moment(fValue, 'DD.MM.YYYY').format('YYYY-MM-DD');
				} else {
					value = previousValue;
				}
			} else {
				value = null;
				fValue = null;
			}
		});
	});
</script>

<div class="form-group row" class:has-error={dataErrors} class:has-success={isSuccess}>
	<label for={datepickerId} class={`col-sm-4 col-lg-3 col-xl-4 col-form-label text-${labelAlign}`}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8 float-right">
		<div id={datepickerId} class="input-group date datetimepicker" data-target-input="nearest">
			<input
				id={`input-${model}-${attribute}-${randomString()}`}
				type="text"
				class:required-border={required}
				class:text-disabled={disabled}
				class="form-control datetimepicker-input"
				value={fValue}
				bind:this={inputField}
				{disabled}
				on:blur={saveAutomatically && handleChange}
				on:focus={updateMaskValue}
				{placeholder}
				spellcheck="false"
				data-target={`#${datepickerId}`}
				autocomplete="off"
			/>
			<div class="input-group-append" data-target={`#${datepickerId}`} data-toggle="datetimepicker">
				<div class="input-group-text" style={disabled ? 'cursor: not-allowed;' : ''}>
					<iconify-icon icon="bx-bxs-calendar" style={disabled ? 'opacity: 0.6;' : ''} />
				</div>
			</div>
		</div>
		{#if dataErrors}
			<InputErrorsString errors={dataErrors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>
