<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: data = {
		...$commonParams,
		disabled: $commonParams.disabled || true,
		attribute: 'read_from_at',
		label: 'Время ознакомления с материалами (рабочие дни)',
		value: $currentData.meetingReadFromAt,
		placeholder: '10:00',
	};
</script>

<AutosaveInput {...data} />
