<!-- @format -->
<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { onDestroy } from 'svelte';

	const dispatch = createEventDispatcher();

	export let visible = false;
	export let timeout = 75000;
	export let checkInterval = 2000;

	let resultObserver;
	let startTime;
	let overtime = false;

	const handleInterrupt = () => {
		overtime = false;
		visible = false;
		clearInterval(resultObserver);
		dispatch('canceled');
	};

	const handleContinuon = () => {
		overtime = false;
		startTime = new Date();
		clearInterval(resultObserver);
		resultObserver = setInterval(() => {
			overtime = visible && new Date() - startTime > timeout;
		}, checkInterval);
	};

	$: if (visible) {
		handleContinuon();

		document.body.classList.add('raf-modal-show');
	} else {
		handleInterrupt();

		document.body.classList.remove('raf-modal-show');
	}

	onDestroy(handleInterrupt);
</script>

{#if visible}
	<div class="raf-modal-background" style="z-index: 9000;"></div>
	<div class="raf-modal" id="waiting-full-screen" style="z-index: 9001;">
		<div class="fa-wrapper">
			<i class="fa fa-spinner fa-pulse text-warning" style="font-size: 12em;" />
		</div>
	</div>
{/if}
{#if overtime}
	<div class="raf-modal" role="dialog" style="z-index: 9003;">
		<div class="raf-modal-dialog modal-md">
			<div class="raf-modal-content animated fadeIn">
				<button type="button" class="close" on:click={handleInterrupt}>
					<span>×</span>
				</button>
				<div class="raf-modal-header">
					<h3 class="m-r-md">
						<p>Операция продолжается дольше обычного.</p>
						<p>Вы можете прервать операцию.</p>
					</h3>
				</div>
				<div class="raf-modal-footer">
					<button type="button" class={'btn float-left m-r-xs btn-danger'} on:click={handleInterrupt}> Прервать </button>
					<button type="button" class={'btn float-left btn-primary'} on:click={handleContinuon}> Продолжить </button>
				</div>
			</div>
		</div>
	</div>
{/if}

<style>
	:global(.raf-modal-show .raf-modal) {
		overflow-y: scroll;
	}

	:global(.raf-modal-show) {
		overflow: hidden;
	}

	:global(.raf-modal-header > h1, .raf-modal-header > h2, .raf-modal-header > h3) {
		margin-top: 0;
		margin-bottom: 0;
	}

	@media (min-width: 576px) {
		.raf-modal-dialog {
			max-width: 500px;
			margin: 1.75rem auto;
		}
	}

	@media (min-width: 992px) {
		.modal-md {
			max-width: 500px;
		}
	}

	.raf-modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		outline: 0;
		overflow-y: scroll;
	}

	.close {
		position: absolute;
		top: 0.7rem;
		right: 0.8rem;
	}

	.raf-modal-dialog {
		position: relative;
		width: auto;
		pointer-events: none;
		/*margin: 1.75rem auto;*/

		margin-left: calc(50vw - 250px);
		margin-top: calc(41vh - 6em);
	}

	.raf-modal-content {
		position: relative;
		background-clip: padding-box;
		background-color: #fff;
		border: 1px solid#000;
		border-radius: 4px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
		outline: 0 none;
		display: flex;
		flex-direction: column;
		width: 100%;
		pointer-events: auto;
	}

	.raf-modal-header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 1rem;
		border-bottom: 1px solid #dee2e6;
		border-top-left-radius: 0.3rem;
		border-top-right-radius: 0.3rem;
		text-align: center;
		display: block;
	}

	.raf-modal-footer {
		padding: 1rem;
		border-top: 1px solid #dee2e6;
		border-bottom-right-radius: 0.3rem;
		border-bottom-left-radius: 0.3rem;
		font-size: 0.8125rem;
	}

	.raf-modal-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 50%;
	}

	/*.fa-12x {
    font-size: 12em;
  }*/

	.fa-wrapper {
		width: 12em;
		margin-left: calc(50vw - 6em);
		margin-top: calc(42vh - 6em);
	}
</style>
