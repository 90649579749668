<!-- @format -->
<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dct, dctFormAvailable } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch_helpers';
	import PayoutModal from '~/svelte/payouts/_modal.svelte';
	import ReceiptModal from '~/svelte/receipts/_modal.svelte';
	import { format } from 'date-fns';
	import { toArray, formatSum, isBlank } from 'utils/tools';
	import moment from 'moment';

	export let fieldId;

	let indexCollapse = false;
	let payoutModal;
	let receiptModal;
	let date;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	const handleNewItem = () => receiptModal.newItem();

	const handleEditPayoutItem = id => payoutModal.editItem(id);
	const handleEditReceiptItem = id => receiptModal.editItem(id);

	const handleEditItem = row => {
		if (row.kind == 'between_accounts') {
			handleEditPayoutItem(row.payout_id);
		} else {
			handleEditReceiptItem(row.id);
		}
	};

	const load = async () => {
		fetching = true;

		try {
			const receiptsResponse = await fetchGet('/api/private/receipts', { procedure_id: $procedure.id, dct_id: $dct.id });
			$transmitter.receipts = receiptsResponse.rows;

			if (isBlank($transmitter.bankAccounts)) {
				const bankAccountsResponse = await fetchGet('/api/private/bank_accounts', { procedure_id: $procedure.id, date: $dct.date });
				$transmitter.bank_accounts = bankAccountsResponse.bank_accounts;
				$transmitter.bankAccounts = bankAccountsResponse.bank_accounts;
			}
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	const changed = () => load();

	$: if (!moment(date).isSame(moment($dct.date)) && !fetching) {
		date = $dct.date;
		changed();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Поступления (${toArray($transmitter.receipts).length})`}</span>
	</h4>
</div>
<div class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && handleNewItem}> + Поступление </button>
		<div class="p-1">
			<ol class="records-list">
				{#each toArray($transmitter.receipts) as row (row.id)}
					<li>
						<div class="float-left simulated-link" role="button" tabindex="0" on:click={handleEditItem(row.id)} on:keypress|stopPropagation>
							{`${formatSum(row.sum)} руб., (${format(new Date(row.date_at), 'dd.LL.yyyy')})`}
						</div>
					</li>
				{/each}
			</ol>
		</div>
	</div>
</div>

<ReceiptModal bind:this={receiptModal} on:create={changed} on:update={changed} on:delete={changed} disabled={!$dctFormAvailable} />

<PayoutModal bind:this={payoutModal} on:create={changed} on:update={changed} on:delete={changed} disabled={!$dctFormAvailable} />

<style>
	.records-list {
		padding-left: 15px;
		list-style-type: circle;
	}
	.records-list li {
		cursor: pointer;
	}
	.header {
		cursor: pointer;
	}
</style>
