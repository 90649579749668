<!-- @format -->
<script>
	import { fieldsReady } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';

	export let fieldId;

	const kindRealtyOptions = [
		{ id: null, text: '' },
		{ id: 'Все', text: 'Все' },
		{ id: 'Жилой дом', text: 'Жилой дом' },
		{ id: 'Здание (нежилое)', text: 'Здание (нежилое)' },
		{ id: 'Земельный участок', text: 'Земельный участок' },
		{ id: 'Квартира', text: 'Квартира' },
		{ id: 'Комната', text: 'Комната' },
		{ id: 'Объект незавершенного строительства', text: 'Объект незавершенного строительства' },
		{ id: 'Помещение (нежилое)', text: 'Помещение (нежилое)' },
		{ id: 'Предприятие как имущественный комплекс (ПИК)', text: 'Предприятие как имущественный комплекс (ПИК)' },
		{ id: 'Сооружение', text: 'Сооружение' },
		{ id: 'Участок недр', text: 'Участок недр' },
	];

	let forceSave = false;
	let initFieldReady = true;

	$: $fieldsReady[fieldId] = !initFieldReady;

	$: if (!($dct && $dct.substitutions.kind_realty)) {
		forceSave = true;
	} else {
		forceSave = false;
		initFieldReady = false;
	}

	$: kindRealty = {
		...$commonParams,
		attribute: 'kind_realty',
		label: 'Виды объекта недвижимости',
		value: kindRealtyOptions.find(option => option.id === $currentData.kindRealty) || kindRealtyOptions.find(realty => realty.id === 'Все').id,
		options: kindRealtyOptions,
		placeholder: 'Выбрать вид объекта недвижимости',
		forceSave: forceSave,
	};
</script>

<AutosaveSelect2Search {...kindRealty} />
