<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../../stores/stores';
	import AutosaveInput from '../../../../components/ui/autosave_input.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;

	$: arbitrManagerPassportDepartment = {
		...$commonParams,
		attribute: 'arbitr_manager_passport_department',
		label: 'Кем выдан паспорт АУ',
		value: $currentData.arbitrManagerPassportDepartment,
		placeholder: 'Московским РОВД',
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_passport_department',
			resource: arbitrManager && arbitrManager.person,
			resourceProp: 'passport_department',
		},
	};
</script>

<AutosaveInput {...arbitrManagerPassportDepartment} on:update />
