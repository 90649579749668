<!-- @format -->
<script>
	import { dct, currentData, requiredFieldsFilled, commonParams } from '../stores/stores';
	import { procedure } from 'base_stores';
	import AutosaveDatepicker from '../../components/ui/autosave_datepicker.svelte';
	import moment from 'moment/moment';

	$: if ($dct.form_fields.includes('startDateOfRequestedInformationPeriod')) {
		$requiredFieldsFilled.startDateOfRequestedInformationPeriod = !!startDateOfRequestedInformationPeriod.value;
	}
	$: startDateOfRequestedInformationPeriod = {
		...$commonParams,
		required: true,
		attribute: 'start_date_of_requested_information_period',
		label: 'Начало периода запрашиваемых сведений',
		value: $currentData.startDateOfRequestedInformationPeriod,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData:
			moment($currentData.startDateOfRequestedInformationPeriod).format('YYYY-MM-DD') ===
			moment($procedure && $procedure.bankruptcy_petition_date)
				.subtract(3, 'years')
				.format('YYYY-MM-DD')
				? {
						linkText: 'Дата принятия судом заявления',
						additionalText: ' - 3 года',
					}
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveDatepicker {...startDateOfRequestedInformationPeriod} />
