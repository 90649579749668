<!-- @format -->
<script>
	import { currentData, commonParams, dct } from '../stores/stores';
	import AutosaveCheckbox from '../../components/ui/autosave_checkbox.svelte';

	$: label = $dct && $dct.kind === 'challenging_transactions' ? 'Должник предоставил АУ документы по имуществу и сделкам' : 'Должник предоставил АУ документы';

	$: receivableSubmittedDocumentsToArbitrManager = {
		...$commonParams,
		attribute: 'receivable_submitted_documents_to_arbitr_manager',
		label,
		value: $currentData && $currentData.receivableSubmittedDocumentsToArbitrManager,
		text: 'Да',
	};
</script>

<AutosaveCheckbox {...receivableSubmittedDocumentsToArbitrManager} />
