<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveDatepicker from '../../../components/ui/autosave_datepicker.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: resolutionAppointmentDate = {
		...$commonParams,
		attribute: 'resolution_appointment_date',
		label: 'Дата резолютивной части назначения АУ',
		value: $currentData && $currentData.resolutionAppointmentDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.resolution_appointment_date',
			resource: $procedure,
			resourceProp: 'resolution_appointment_date',
		},
	};
</script>

<AutosaveDatepicker {...resolutionAppointmentDate} on:update />
