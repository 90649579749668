<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dctFormAvailable, actualMeasures, actualMeasuresOptions } from '~/svelte/dcts/stores/stores';
	import { isPresent } from 'utils/tools';
	import { fetchGet } from 'utils/fetch';
	import { showModal } from '~/svelte/components/modal.svelte';
	import MeasuresModal from '~/svelte/measures/_modal.svelte';

	export let fieldId;
	export let blockTitle = 'Меры по сохранности имущества';

	let procedureId = null;
	let newMeasure = false;
	let measureId = null;
	let indexCollapse = false;
	let noMeasureSelected = true;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	//$: measureKinds = $options && $options.measureKind
	//$: measures = Object.values($transmitter && $transmitter.measures || []).flat(1)
	$: measuresNumber = $actualMeasures && $actualMeasures.length;

	const openModalForm = id => {
		newMeasure = !id;
		measureId = id;
		showModal('form-measure-modal');
	};

	const loadMeasures = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/measures', { procedure_id: procedureId });
			$transmitter = { ...$transmitter, measures: response.measures };
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: if (!$transmitter.measures && !fetching) {
		loadMeasures();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="measures col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`${blockTitle} (${measuresNumber})`}</span>
	</h4>
</div>
<div id="collapse-measures" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && openModalForm(null)}> + Мера </button>
		<div class="p-1">
			{#if isPresent($actualMeasures)}
				<ol class="block-list">
					{#each $actualMeasures as measure}
						<li>
							<div class="float-left simulated-link" role="button" tabindex="0" on:click={openModalForm(measure.id)} on:keypress|stopPropagation>
								{measure.name || ''}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>
<MeasuresModal {measureId} disabled={!$dctFormAvailable} options={$actualMeasuresOptions} bind:newMeasure bind:noMeasureSelected />
