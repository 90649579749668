<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../../stores/stores';
	import AutosaveDatepicker from '../../../../components/ui/autosave_datepicker.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;

	$: arbitrManagerPassportDate = {
		...$commonParams,
		attribute: 'arbitr_manager_passport_date',
		label: 'Дата выдачи паспорта АУ',
		value: $currentData.arbitrManagerPassportDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_passport_date',
			resource: arbitrManager && arbitrManager.person,
			resourceProp: 'passport_date',
		},
	};
</script>

<AutosaveDatepicker {...arbitrManagerPassportDate} on:update />
