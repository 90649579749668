<!-- @format -->
<!-- @format -->
<script>
	import { isPresent, isArray, isObject } from 'utils/tools';
	import { fade } from 'svelte/transition';

	export let message;
	export let mode = 'danger';
	export let cssClass = '';
	export let cancelable = true;
</script>

{#if isPresent(message)}
	<div
		class={`my-notification${isPresent(cssClass) ? ` ${cssClass}` : ''}`}
		class:bg-danger={mode == 'danger'}
		class:bg-warning={mode == 'warning'}
		class:bg-primary={mode == 'info'}
		transition:fade={{ delay: 100, duration: 1000 }}
	>
		<p class="my-message">
			{#if isArray(message)}
				<ul>
					{#each message as mess}<li>{mess}</li>{/each}
				</ul>
			{:else if isObject(message)}
				<ul>
					{#each Object.keys(message) as key}<li>{`${key}: ${message[key]}`}</li>{/each}
				</ul>
			{:else}
				{message}
			{/if}
		</p>
		{#if cancelable}
			<button on:click={() => (message = undefined)}></button>
		{/if}
	</div>
{/if}

<style lang="scss">
	.my-notification {
		position: relative;
		margin: 10px auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		min-height: 3rem;
		border-radius: 0.4rem;
		padding: 0 1.6rem 0 0.8rem;
		opacity: 0.8;

		.my-message {
			margin: auto 0;
			color: white;
		}

		button {
			position: absolute;
			right: 0.4rem;
			top: 0.4rem;

			user-select: none;
			background-color: rgba(10, 10, 10, 0);
			border: none;
			border-radius: 9999px;
			cursor: pointer;
			pointer-events: auto;
			display: inline-block;
			flex-grow: 0;
			flex-shrink: 0;
			font-size: 0;
			height: 20px;
			max-height: 20px;
			max-width: 20px;
			min-height: 20px;
			min-width: 20px;
			outline: 0;
			width: 20px;
			&:before {
				background-color: #fff;
				content: '';
				position: absolute;
				display: block;
				left: 50%;
				top: 50%;
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
				transform-origin: center center;

				height: 2px;
				width: 50%;
			}
			&:after {
				position: absolute;
				content: '';
				display: block;
				left: 50%;
				top: 50%;
				background-color: #fff;
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
				transform-origin: center center;

				height: 50%;
				width: 2px;
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}
	}
</style>
