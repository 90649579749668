<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInput from '../../components/ui/autosave_input.svelte';

	$: numberSheetsDocumentsForSettlementClaims = {
		...$commonParams,
		attribute: 'number_sheets_documents_for_settlement_claims',
		label: 'Количество листов документов по погашению требований (приложение)',
		value: $currentData && $currentData.numberSheetsDocumentsForSettlementClaims,
		placeholder: '5',
	};
</script>

<AutosaveInput {...numberSheetsDocumentsForSettlementClaims} />
