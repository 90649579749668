/** @format */

const templateStyles = `<style>
  .page-break_after { page-break-after: always; }
  .wrap { font-family: Arial, "Open Sans", "Helvetica Neue", Helvetica, sans-serif, "Times New Roman"; font-size: 11pt; }
  .wrap-pv { font-family: Arial, "Open Sans", "Helvetica Neue", Helvetica, sans-serif, "Times New Roman"; }
  .wrap-pv_normal { font-size: 11pt; }
  .pv__header { font-weight: bold; }
  .pv__header_size_1 { font-size: 14pt; line-height: 1.9rem; margin-top: .9rem; }
  .pv__header_size_2 { font-size: 12pt; line-height: 1.7rem; margin-top: .7rem; }
  .pv__header_size_3 { font-size: 11pt; line-height: 1.6rem; margin-top: .6rem; }
  .pv__header_size_4 { font-size: 10pt; line-height: 1.5rem; margin-top: .5rem; }
  .pv__paragraph { text-align: justify; }
  .pv__paragraph_indent_1 { text-indent: 1rem; }
  .pv__paragraph_indent_2 { text-indent: 2rem; }
  .pv__table { border-collapse: collapse; margin: .5rem auto; }
  .pv__table_bordered, .pv__table_bordered > tbody > tr > th, .pv__table_bordered > tbody > tr > td { border: 1px solid #000000; }
  .pv__table > tbody > tr > th, .pv__table > tbody > tr > td { padding: .3rem; }
  .preview__headers, .preview__introduction, .preview__body-title { text-align: center}
  .preview__introduction { font-size: 9pt }
  .preview__body-paragraph { font-size: 11pt; }
  .preview__headers, .preview__body-title { font-weight: bold; }
  .preview__header1 { font-size: 14pt; line-height: 1.9rem; }
  .preview__header2 { font-size: 12pt; line-height: 1.7rem; }
  .preview__header3, .preview__body-title { font-size: 15pt; line-height: 1.6rem; }
  .preview__body { margin-bottom: 1.5rem; font-family: Arial, "Open Sans", "Helvetica Neue", Helvetica, sans-serif, "Times New Roman"; font-size: 11pt;}
  .preview__body-title { font-size: 11pt; }
  .preview__body-paragraph { text-indent: 2rem; text-align: justify; }
  .preview__optional-paragraph__block { text-align: justify; text-indent: 2rem; margin-bottom: 1rem }
  .preview__optional-paragraph__hidden { display: none }
  .preview__requisites, .preview__body-paragraph, .preview__afterword, .preview__signature {
    line-height: 1.35rem;
  }
  .preview__introduction, .preview__requisites, .preview__body-title, .preview__body-paragraph { margin-bottom: .5rem; }
  .preview__afterword, .preview__signature { margin-bottom: 1.5rem; font-family: Arial, "Open Sans", "Helvetica Neue", Helvetica, sans-serif, "Times New Roman"; font-size: 11pt;}
  .preview__body-numbered-list, .preview__body-bulleted-list { padding-left: 2rem; text-align: justify; line-height: 1.5;}
  .preview__body-horizontal-list { line-height: 1.5; padding: 0; display: inline-block }
  .preview__body-horizontal-list li { display: inline }
  .preview__body-horizontal-list li:after { content: ', ' }
  .preview__body-horizontal-list li:last-child:after { content: '' }
  .preview__requisites::after, .preview__signature::after { display: block; clear: both; content: ''; }
  .preview__requisites--left, .preview__signature--left { float: left; }
  .preview__requisites--right, .preview__signature--right { float: right; max-width: 40%; }
  .preview__signature-field {
    border-top: 1px solid #000000;
    min-width: 20%;
    font-size: 11pt;
    margin-top: 1.3rem;
    width: 15rem;
    float: right;
    text-align: center;
  }
  hr { margin: .5rem 0; border-top: 1px solid #000000; }
  .detached { margin-top: .8rem; margin-bottom: .8rem; font-size: 11pt; }
  .success { border: 1px solid #1ab394; padding: 0 3px; }
  .error { border: 1px solid #ed5565; text-indent: 0 !important; min-width: 0 !important }
  .empty { border: 1px solid #aaa;
    padding: 0 2px;
    min-width: 3em;
    display: inline-block;
    height: 1.09em;
    vertical-align: text-bottom;
  }
  .required-empty { border: 1px solid #ffa500; }
  .error, .required-empty { padding: 0 3px; min-width: 7em; height: 1.5em; display: inline-block; vertical-align: text-bottom; }
  .bold, .bold-and-underlined { font-weight: bold; }
  .bold-and-underlined { text-decoration: underline; }
  .section { margin-bottom: 3px; }
  .preview__signature { page-break-before: avoid; }
  .small { font-size: 10pt }
  table { border-collapse: collapse; margin: .5rem 0; font-size: 11pt; }
  table th { text-align: center; font-size: 11pt; }
  .table-bordered, .table-bordered > tbody > tr > th, .table-bordered > tbody > tr > td { border: 1px solid #000000; }
  .table-bordered > tbody > tr > th { text-align: center; }
  .table-borderless, .table-borderless > tbody > tr > td, .borderless { border: 0 !important; }
  .table-full-width { width: 100%; }
  .half-width { width: 50%; }
  .third-width { width: 33.33%; }
  .table-nested { width: calc(100% + 2px); margin: -1px; }
  td { padding: .3rem; }
  td.indent { padding-left: 1.2rem; }
  td.double_indent { padding-left: 2.2rem; }
  p { margin: 0; }
  .bordered-block { border: 1px solid #000000; padding: 1rem; text-align: justify; margin: .5rem 0; }
  .paddingless { padding: 0; }
  .text-top { vertical-align: top; }
  .text-bottom { vertical-align: bottom; }
  .text-center { text-align: center; }
  .text-left { text-align: left; }
  .text-right { text-align: right; }
  .table-row-high > td { height: 2.5rem; }
  .underline { text-decoration: underline; }
  .box_with_check { font-size: 12pt; line-height: 1.3rem; vertical-align: bottom; margin-right: .3rem; }
  .blank { text-align: center; }

  .document-signature {
    width: 100%;
  }
  .document-signature-left {
    width: 75%;
    text-align: left;
  }
  .document-signature-right {
    width: 25%;
    text-align: center;
  }
  .document-signature-field {
    border-top: 1px solid #000000;
    width: 100%;
    font-size: .6rem;
    margin-top: 1.3rem;
    margin-bottom: 1.5rem;
  }
  .document-signature2 {
    width: 100%;
  }
  .document-signature2-left {
    width: auto;
    text-align: left;
  }
  .document-signature2-center {
    width: 25%;
    text-align: center;
  }
  .document-signature2-right {
    width: auto;
    text-align: left;
  }
</style>`;

const conformities = {
	'==КОНТЕЙНЕР==': "<div class='wrap'>",
	'==КОНТЕЙНЕР_КОНЕЦ==': '</div>',
	'==ВЫДЕЛЕНИЕ_ЖИРНЫМ_И_ПОДЧЕРКИВАНИЕМ==': "<span class='bold-and-underlined'>",
	'==ВЫДЕЛЕНИЕ_ЖИРНЫМ_И_ПОДЧЕРКИВАНИЕМ_КОНЕЦ==': '</span>',
	'==ЗАГОЛОВКИ==': "<div class='preview__headers'>",
	'==ЗАГОЛОВКИ_КОНЕЦ==': '</div>',
	'==ЗАГОЛОВОК1==': "<div class='preview__header1'>",
	'==ЗАГОЛОВОК1_КОНЕЦ==': '</div>',
	'==ЗАГОЛОВОК2==': "<div class='preview__header2'>",
	'==ЗАГОЛОВОК2_КОНЕЦ==': '</div>',
	'==ЗАГОЛОВОК3==': "<div class='preview__header3'>",
	'==ЗАГОЛОВОК3_КОНЕЦ==': '</div>',
	'==ГОРИЗОНТАЛЬНАЯ_ЛИНИЯ==': '<hr/>',
	'==ВВЕДЕНИЕ==': "<div class='preview__introduction'>",
	'==ВВЕДЕНИЕ_КОНЕЦ==': '</div>',
	'==РЕКВИЗИТЫ==': "<div class='preview__requisites'>",
	'==РЕКВИЗИТЫ_КОНЕЦ==': '</div>',
	'==РЕКВИЗИТЫ_СЛЕВА==': "<div class='preview__requisites--left'>",
	'==РЕКВИЗИТЫ_СЛЕВА_КОНЕЦ==': '</div>',
	'==РЕКВИЗИТЫ_СПРАВА==': "<div class='preview__requisites--right'>",
	'==РЕКВИЗИТЫ_СПРАВА_КОНЕЦ==': '</div>',
	'==ПЕРЕНОС_СТРОКИ==': '<br/>',
	'==ОСНОВНОЙ_ТЕКСТ==': "<div class='preview__body'>",
	'==ОСНОВНОЙ_ТЕКСТ_КОНЕЦ==': '</div>',
	'==ЗАГОЛОВОК_ТЕКСТА==': "<div class='preview__body-title'>",
	'==ЗАГОЛОВОК_ТЕКСТА_КОНЕЦ==': '</div>',
	'==АБЗАЦ==': "<div class='preview__body-paragraph'>",
	'==АБЗАЦ_КОНЕЦ==': '</div>',
	'==ОПЦИОНАЛЬНЫЙ_АБЗАЦ==': "<div class='preview__optional-paragraph__block'>",
	'==ОПЦИОНАЛЬНЫЙ_АБЗАЦ_КОНЕЦ==': '</div>',
	'==НУМЕРОВАНЫЙ_СПИСОК==': "<ol class='preview__body-numbered-list'>",
	'==НУМЕРОВАНЫЙ_СПИСОК_КОНЕЦ==': '</ol>',
	'==НУМЕРОВАННЫЙ_СПИСОК==': "<ol class='preview__body-numbered-list'>",
	'==НУМЕРОВАННЫЙ_СПИСОК_КОНЕЦ==': '</ol>',
	'==МАРКИРОВАНЫЙ_СПИСОК==': "<ul class='preview__body-bulleted-list'>",
	'==МАРКИРОВАНЫЙ_СПИСОК_КОНЕЦ==': '</ul>',
	'==МАРКИРОВАННЫЙ_СПИСОК==': "<ul class='preview__body-bulleted-list'>",
	'==МАРКИРОВАННЫЙ_СПИСОК_КОНЕЦ==': '</ul>',
	'==СПИСОК_ГОРИЗОНТАЛЬНЫЙ==': "<ul class='preview__body-horizontal-list'>",
	'==СПИСОК_ГОРИЗОНТАЛЬНЫЙ_КОНЕЦ==': '</ul>',
	'==ЭЛЕМЕНТ_СПИСКА==': "<li class='preview__body-list-item'>",
	'==ЭЛЕМЕНТ_СПИСКА_КОНЕЦ==': '</li>',
	'==ПОСЛЕСЛОВИЕ==': "<div class='preview__afterword'>",
	'==ПОСЛЕСЛОВИЕ_КОНЕЦ==': '</div>',
	'==ПОДПИСЬ==': "<div class='preview__signature'>",
	'==ПОДПИСЬ_КОНЕЦ==': '</div>',
	'==ПОДПИСЬ_ЛЕВАЯ_ЧАСТЬ==': "<div class='preview__signature--left'>",
	'==ПОДПИСЬ_ЛЕВАЯ_ЧАСТЬ_КОНЕЦ==': '</div>',
	'==ПОДПИСЬ_ПРАВАЯ_ЧАСТЬ==': "<div class='preview__signature--right'>",
	'==ПОДПИСЬ_ПРАВАЯ_ЧАСТЬ_КОНЕЦ==': '</div>',
	'==ЧЕКБОКС==': "<span class='box_with_check'>",
	'==ЧЕКБОКС_КОНЕЦ==': '</span>',
};

export const encodeTemplate = (template, applyStyles) => {
	let encodedTemplate = typeof applyStyles == 'function' ? applyStyles(template) : `${templateStyles}${template}`;

	for (let key in conformities) {
		const field = RegExp(key, 'g');
		encodedTemplate = encodedTemplate.replace(field, conformities[key]);
	}

	return encodedTemplate;
};
