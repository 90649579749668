<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDadateAddress from '~/svelte/components/ui/autosave_dadate_input_address.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('arbitrManagerMailingAddress');

	$: arbitrManagerMailingAddress = {
		...$commonParams,
		required: $dct && $dct.correspondent_kind == 'fedresource',
		attribute: 'arbitr_manager_mailing_address',
		label: 'Почтовый адрес АУ',
		value: $currentData.arbitrManagerMailingAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_mailing_address',
			resource: arbitrManager && arbitrManager.person,
			resourceProp: 'mailing_address',
		},
	};
</script>

<AutosaveDadateAddress {...arbitrManagerMailingAddress} on:update />
