<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: data = {
		...$commonParams,
		disabled: $commonParams.disabled || true,
		attribute: 'read_at',
		label: 'Дата, с которой возможно ознакомиться с материалами',
		value: $currentData.meetingReadAt,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
	};
</script>

<AutosaveDatepicker {...data} />
