<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import AutosaveInput from '../components/ui/autosave_input.svelte';
	import AutosaveDatepicker from '../components/ui/autosave_datepicker.svelte';

	$: procedure = ($transmitter && $transmitter.procedure) || procedure;
	$: procedureId = (procedure && procedure.id) || null;
	$: procedurePhase = procedure && procedure.phase;

	$: org = {
		disabled: !procedureId || !$procedureAvailable,
		attribute: 'insurance_org',
		model: 'procedure',
		url: '/api/private/procedures',
		id: procedureId,
		label: 'Страховая компания',
		placeholder: 'Первая страховая компания',
		value: procedure && procedure.insurance_org,
	};
	$: number = {
		disabled: !procedureId || !$procedureAvailable,
		attribute: 'insurance_policy_number',
		model: 'procedure',
		url: '/api/private/procedures',
		id: procedureId,
		label: 'Номер полиса',
		placeholder: '123456',
		value: procedure && procedure.insurance_policy_number,
	};
	$: date = {
		disabled: !procedureId || !$procedureAvailable,
		attribute: 'insurance_policy_date',
		model: 'procedure',
		url: '/api/private/procedures',
		id: procedureId,
		label: 'Дата полиса',
		placeholder: 'дд.мм.гггг',
		value: procedure && procedure.insurance_policy_date,
	};
	$: startDate = {
		disabled: !procedureId || !$procedureAvailable,
		attribute: 'insurance_policy_start_date',
		model: 'procedure',
		url: '/api/private/procedures',
		id: procedureId,
		label: 'Дата начала действия полиса',
		placeholder: 'дд.мм.гггг',
		value: procedure && (procedure.insurance_policy_start_date || procedure.insurance_policy_date),
		forceSave: procedure && procedure.insurance_policy_date && !procedure.insurance_policy_start_date,
		hintData:
			procedure && procedure.insurance_policy_date
				? procedure.insurance_policy_start_date !== procedure.insurance_policy_date
					? { freeMessage: 'Изменено пользователем' }
					: { freeMessage: 'AI заполнил из Дата полиса' }
				: {},
	};
	$: finishDate = {
		disabled: !procedureId || !$procedureAvailable,
		attribute: 'insurance_policy_finish_date',
		model: 'procedure',
		url: '/api/private/procedures',
		id: procedureId,
		label: 'Дата окончания действия полиса',
		placeholder: 'дд.мм.гггг',
		value:
			procedure &&
			(procedure.insurance_policy_finish_date ||
				(procedure.insurance_policy_date && moment(procedure.insurance_policy_date).add(6, 'months').subtract(1, 'day').format('YYYY-MM-DD'))),
		forceSave: procedure && procedure.insurance_policy_date && !procedure.insurance_policy_finish_date,
		hintData:
			procedure && procedure.insurance_policy_date
				? procedure.insurance_policy_finish_date !== moment(procedure.insurance_policy_date).add(6, 'months').subtract(1, 'day').format('YYYY-MM-DD')
					? { freeMessage: 'Изменено пользователем' }
					: { freeMessage: 'AI заполнил на основе Дата полиса + 6 мес. - 1 день' }
				: {},
	};
</script>

<div class="m-b-sm overlay procedure__insurance-fields">
	<h3>{`${procedurePhase === 'debt_restructuring' ? 8 : 9}. Дополнительная страховка`}</h3>
	<AutosaveInput {...org} />
	<AutosaveInput {...number} />
	<AutosaveDatepicker {...date} />
	<AutosaveDatepicker {...startDate} />
	<AutosaveDatepicker {...finishDate} />
</div>
