<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInputCurrency from '~/svelte/components/ui/autosave_input_currency.svelte';

	$: specialistsTotalPayment = {
		...$commonParams,
		attribute: 'specialists_total_payment',
		label: 'Размер вознаграждения всех специалистов',
		value: $currentData && $currentData.specialistsTotalPayment,
		placeholder: '0,00',
	};
</script>

<AutosaveInputCurrency {...specialistsTotalPayment} />
