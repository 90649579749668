<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;
	$: sro = arbitrManager && arbitrManager.sro;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('sroInn');

	$: sroInn = {
		...$commonParams,
		hidden: !$currentData.insertFirstParagraph,
		required: $dct && $dct.correspondent_kind != 'fedresource',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved' || !$currentData.sroId,
		attribute: 'sro_inn',
		label: 'ИНН СРО',
		value: $currentData && $currentData.sroInn,
		placeholder: '1234567890',
		maskOptions: { mask: '0000000000' },
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.sro_inn',
			resource: sro,
			resourceProp: 'inn',
		},
	};
</script>

{#if !sroInn.hidden}
	<AutosaveInput {...sroInn} on:update />
{/if}
