/** @format */
import jQuery from 'jquery';

jQuery.fn.datetimepicker.Constructor.prototype._origSetValue = jQuery.fn.datetimepicker.Constructor.prototype._setValue;
jQuery.fn.datetimepicker.Constructor.prototype._setValue = function _setValue(targetMoment, index) {
	var oldDate = this.unset ? null : this._dates[index];
	var outpValue = '';
	// case of calling setValue(null or false)
	if (!targetMoment) {
		if (!this._options.allowMultidate || this._dates.length === 1) {
			this.unset = true;
			this._dates = [this.getMoment()];
			this._viewDate = this.getMoment().clone();
			this._datesFormatted = [this.getMoment().format(this._options.format || 'YYYY-MM-DD')];
		} else {
			this._origSetValue(targetMoment, index);
			return;
		}
		if (this.input !== undefined) {
			this.input.val(outpValue);
			this.input.trigger('input');
		}
		this._element.data('date', outpValue);
		this._notifyEvent({
			type: jQuery.fn.datetimepicker.Constructor.Event.CHANGE,
			date: false,
			oldDate: oldDate,
		});
		this._update();
	} else {
		this._origSetValue(targetMoment, index);
	}
};
