<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveTextarea from '../../../components/ui/autosave_textarea.svelte';

	$: hasFactsOfIllegalUnfairBehaviorOfDebtor = $currentData && $currentData.hasFactsOfIllegalUnfairBehaviorOfDebtor;

	$: factsOfIllegalUnfairBehaviorOfDebtor = {
		...$commonParams,
		attribute: 'facts_of_illegal_unfair_behavior_of_debtor',
		label: 'Факты незаконного/недобросовестного поведения должника',
		value: $currentData && $currentData.factsOfIllegalUnfairBehaviorOfDebtor,
		placeholder: 'В ходе процедуры выявлены сделки, направленные на вывод активов должника…',
	};
</script>

{#if hasFactsOfIllegalUnfairBehaviorOfDebtor}
	<AutosaveTextarea {...factsOfIllegalUnfairBehaviorOfDebtor} />
{/if}
