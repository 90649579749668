<!-- @format -->
<script context="module">
	let handleSubmit;
	let handleCancel;
	let handleDelete;

	export function incomeSubmit() {
		handleSubmit();
	}
	export function incomeCancel() {
		handleCancel();
	}
	export function incomeDelete() {
		handleDelete();
	}
</script>

<script>
	import { procedure, transmitter } from 'base_stores';
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch';
	import { hideModal } from '~/svelte/components/modal.svelte';
	import Input from '~/svelte/components/ui/input.svelte';
	import InputCurrency from '~/svelte/components/ui/input_currency.svelte';

	export let incomeId;
	export let disableSubmitButton;
	export let disabled = false;

	const nullIncome = { year: null, sum: null };

	let income = { ...nullIncome };

	$: method = !!incomeId ? fetchPut : fetchPost;
	$: data = { ...income };
	$: disableSubmitButton = !income.year || !income.sum;

	$: if (incomeId) {
		fetchGet(`/api/private/statistics/incomes/${incomeId}`, { procedure_id: $procedure.id }).then(response => (income = response.income));
	}

	$: if (!incomeId) {
		income = { ...nullIncome };
	}

	$: formFields = {
		year: {
			required: true,
			model: 'income',
			attribute: 'year',
			placeholder: '2000',
			label: 'Год получения дохода',
			disabled,
		},
		sum: {
			required: true,
			model: 'income',
			attribute: 'sum',
			placeholder: '0,00',
			label: 'Сумма дохода (руб.)',
			disabled,
		},
	};

	handleSubmit = () => {
		method(`/api/private/statistics/incomes${incomeId ? `/${incomeId}` : ''}`, data, { procedure_id: $procedure.id })
			.then(response => {
				$transmitter = { ...$transmitter, incomes: response.incomes };
				hideModal('form-income-modal');
			})
			.catch(errors => console.log('errors: ' + JSON.stringify(errors)));
	};

	handleDelete = () => {
		fetchDelete(`/api/private/statistics/incomes/${incomeId}`, { procedure_id: $procedure.id })
			.then(response => ($transmitter = { ...$transmitter, incomes: response.incomes }))
			.catch(errors => console.log('errors: ' + JSON.stringify(errors)));
	};

	handleCancel = () => {
		income = { ...nullIncome };
	};
</script>

{#if income}
	<Input {...formFields.year} bind:value={income.year} />
	<InputCurrency {...formFields.sum} bind:value={income.sum} />
{/if}
