<!-- @format -->
<script>
	import { procedure, transmitter, options } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch';
	import { showModal } from '../components/modal.svelte';
	import Header from '../components/header.svelte';
	import Table from './table.svelte';

	let procedureId = null;
	let mounted = false;

	const openModalForm = () => {
		$transmitter.receivableId = null;
		showModal('form-receivable-modal');
	};

	onMount(() => {
		procedureId = $procedure && $procedure.id;

		fetchGet('/api/private/possessions/get_kind_options', { procedure_id: procedureId })
			.then(response => ($options = { ...$options, possessionKind: response.options }))
			.then(() => fetchGet('/api/private/counterparties/get_options_for_roles', { roles: 'debtor' }))
			.then(response => ($options = { ...$options, counterpartyDebtor: [{ id: 'new', text: 'Добавить дебитора' }, ...response.options] }))
			.then(() => fetchGet('/api/private/possessions/get_valuer_options'))
			.then(response => ($options = { ...$options, valuer: response.options }))
			.then(() => fetchGet('/api/private/receivables'))
			.then(response => ($transmitter = { ...$transmitter, receivables: response.receivables }))
			.then(() => fetchGet('/api/private/creditors_requirements/index_as_options'))
			.then(response => ($options = { ...$options, creditorsRequirements: response.options }))
			.then(() => (mounted = true))
			.catch(error => console.error(error.message));
	});
</script>

<Header><span slot="name">Дебиторка</span></Header>
<div class="m-b-lg mb-0">
	<button
		class="btn btn-sm btn-warning m-r-sm m-b-sm ml-2 ml-sm-0"
		disabled={!$procedureAvailable || !procedureId}
		on:click={procedureId && $procedureAvailable && openModalForm()}
	>
		+ Дебиторка
	</button>
</div>
<div class="m-b-lg overlay p-1 footer-margin">
	<Table bind:mounted />
</div>
