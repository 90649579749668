<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveCheckbox from '~/svelte/components/ui/autosave_checkbox.svelte';

	$: hasBeenDepositedArbitrManagerRemunerationIntoCourtDeposit = {
		...$commonParams,
		attribute: 'has_been_deposited_arbitr_manager_remuneration_into_court_deposit',
		label: 'Вознаграждение АУ внесено на депозит суда',
		value: $currentData?.hasBeenDepositedArbitrManagerRemunerationIntoCourtDeposit,
		text: 'Да',
	};
</script>

<AutosaveCheckbox {...hasBeenDepositedArbitrManagerRemunerationIntoCourtDeposit} on:update />
