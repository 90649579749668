<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: creditorsFirstMeetingMinutesDate = {
		...$commonParams,
		attribute: 'creditors_first_meeting_minutes_date',
		label: 'Дата протокола 1-го собрания кредиторов',
		value: $currentData && $currentData.creditorsFirstMeetingMinutesDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
	};
</script>

<AutosaveDatepicker {...creditorsFirstMeetingMinutesDate} />
