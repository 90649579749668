<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dctFormAvailable } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { showModal } from '~/svelte/components/modal.svelte';
	import ComplaintsModal from '~/svelte/complaints/_modal.svelte';
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte';

	export let fieldId;

	let complaintId = null;
	let counterpartyId;
	let indexCollapse = false;
	let noComplainerSelected = true;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	$: complaints = ($transmitter && $transmitter.complaints) || complaints || [];

	const openComplaintModalForm = id => {
		complaintId = id;
		showModal('form-complaint-modal');
	};

	const openCounterpartyModalForm = id => {
		counterpartyId = id;
		showModal('form-counterparty-modal');
	};

	const loadComplaints = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/complaints', { procedure_id: procedureId });
			$transmitter = { ...$transmitter, complaints: response.complaints };
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: if (!$transmitter.complaints && !fetching) {
		loadComplaints();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="complaints col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Жалобы (${complaints.length})`}</span>
	</h4>
</div>
<div id="collapse-complaints" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && openComplaintModalForm(null)}> + Жалоба </button>
		<div class="p-1">
			{#if complaints.length}
				<ol class="block-list">
					{#each complaints as complaint}
						<li>
							<div class="float-left simulated-link" role="button" tabindex="0" on:click={openComplaintModalForm(complaint.id)} on:keypress|stopPropagation>
								{complaint.name}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>
<ComplaintsModal {complaintId} disabled={!$dctFormAvailable} {openCounterpartyModalForm} bind:noComplainerSelected />
<CounterpartyModal {counterpartyId} disabled={!$dctFormAvailable} fixedRoles={['creditor']} />
