<!-- @format -->
<script>
	import { newObject, procedure } from 'base_stores';
	import { dct, currentData, commonParams } from '../stores/stores';
	import AutosaveDatetimepicker from '../../components/ui/autosave_datetimepicker.svelte';

	$: hearingDateAndTime = {
		...$commonParams,
		attribute: 'hearing_date_and_time',
		label: 'Дата и время заседания по результатам процедуры',
		value: $currentData.hearingDateAndTime,
		maskOptions: { mask: Date },
		hintData: {
			url: `/procedures/${$procedure.id}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.hearing_date_and_time',
			resource: $procedure,
			resourceProp: 'hearing_date_and_time',
		},
	};
</script>

<AutosaveDatetimepicker {...hearingDateAndTime} />
