<!-- @format -->
<script>
	import { bankruptIsPerson, bankruptIsOrganization, bankruptType, procedure, transmitter } from 'base_stores';
	import NotificationBadge from './notification_badge.svelte';
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch_helpers';
	import { setCookie } from 'utils/cookies';
	import moment from 'moment';

	let selectProcedureNode = null;
	let arbitrManagers = [];
	let bankruptSurname;
	let bankruptShortName;
	let procedureOptions = [{ id: 'null', text: allProceduresText }];

	const allProceduresText = 'Все процедуры и добавить';

	$: procedureId = ($procedure && $procedure.id) || 'null';
	$: arbitrProcedure = $transmitter && $transmitter.procedure;
	$: bankrupt = arbitrProcedure && arbitrProcedure.bankrupt;

	const goToProcedures = showProcedures => {
		if (location.pathname !== '/procedures') {
			location.href = `/procedures?show_procedures=${showProcedures}`;
		}
	};

	const refreshOptions = () => {
		fetchGet('/api/private/procedures').then(response => {
			let options = response['grouped_procedures'].map(group => {
				return { arbitrName: group.arbitr_manager.text, arbitrId: group.arbitr_manager.id, options: group.procedures.filter(p => !p.text.endsWith(' (з)')) };
			});

			$transmitter = { ...$transmitter, arbitr_procedures: options };

			procedureOptions = [{ id: 'null', text: allProceduresText }, ...options];

			selectProcedureNode.empty();
			procedureOptions.forEach(group => {
				if (group.options) {
					const arbitrOption = new Option(group.arbitrName, group.arbitrId, true, true);
					selectProcedureNode.append(arbitrOption);
					arbitrManagers.push(group.arbitrName);

					group.options.forEach(data => {
						const option = new Option('\u00A0\u00A0\u00A0\u00A0' + data.text, data.id, true, true);
						selectProcedureNode.append(option);
					});
				} else {
					const option = new Option(group.text, group.id, true, true);
					selectProcedureNode.append(option);
				}
			});

			selectProcedureNode.val(procedureId).trigger('change');
		});
	};

	$: if (
		($bankruptIsPerson && (bankrupt && bankrupt.surname) !== bankruptSurname) ||
		($bankruptIsOrganization && (bankrupt && bankrupt.short_name) !== bankruptShortName)
	) {
		bankruptSurname = bankrupt && bankrupt.surname;
		bankruptShortName = bankrupt && bankrupt.short_name;
		refreshOptions();
	}

	$: if (selectProcedureNode) {
		const wrapperBordered = selectProcedureNode.next().first().find('#select2-tools-bar__select-procedure-container').parent();
		$procedure ? wrapperBordered.removeClass('required-border') : wrapperBordered.addClass('required-border');
	}

	onMount(() => {
		// eslint-disable-next-line no-undef
		selectProcedureNode = jQuery('.select-procedure');
		selectProcedureNode.select2({ minimumResultsForSearch: Infinity });

		refreshOptions();

		const observer = new MutationObserver((mutationsList, _) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
					const target = mutation.target;
					const classList = target.classList;
					if (classList.contains('select2-container--open')) {
						let allOptions = document.querySelectorAll('.select2-results__option');
						allOptions[0].parentNode.style.overflowX = 'hidden';
						allOptions.forEach(option => {
							option.style.whiteSpace = 'nowrap';
							if (option.textContent === allProceduresText) {
								option.style.fontWeight = 'bold';
							}
						});
					}
				}
			}
		});

		const targetNode = document.querySelector('.select2-container');
		const config = { attributes: true, attributeFilter: ['class'] };
		observer.observe(targetNode, config);

		selectProcedureNode.on('select2:select', event => {
			const idFromEvent = event.params.data.id;

			if (!idFromEvent || idFromEvent == 'null') {
				goToProcedures(true);
			} else {
				selectProcedureNode.val(idFromEvent).trigger('change');
				if (arbitrManagers.includes(event.params.data.text)) {
					location.href = `/arbitr_managers/${event.params.data.id}`;
				} else if (location.pathname.match(/^\/procedures\/\d+/)) {
					location.href = `/procedures/${idFromEvent}`;
				} else if (location.pathname.match(/^\/procedures\/new/)) {
					location.href = `/procedures/new`;
				} else {
					fetchGet(`/api/private/procedures/${idFromEvent}`).then(response => {
						$procedure = JSON.parse(response.procedure);
						setCookie('_rafProcedureId', $procedure.id, { expires: moment().endOf('day').toDate() });
						$bankruptType = $procedure.bankrupt_type;
						$transmitter = { ...$transmitter, procedure: response.procedure };

						if (location.pathname.match(/^\/documents\/outgoing(s)?/) || location.pathname.match(/^\/templates/)) {
							location.href = '/documents/outgoings';
						} else if (location.pathname.match(/^\/documents\/financial_analysis/)) {
							location.href = '/documents/financial_analysis';
						} else if (location.pathname.match(/^\/documents\/reports/)) {
							location.href = '/documents/reports';
						} else if (location.pathname.match(/^\/answers/)) {
							location.href = '/answers';
						} else if (location.pathname.match(/^(\/mail_registry\/\d+|\/meetings)/)) {
							location.href = '/meetings';
						} else {
							location.reload();
						}
					});
				}
			}
		});

		selectProcedureNode.next().on('click', () => {
			if (procedureId == 'null') {
				goToProcedures(true);
			}
		});
	});
</script>

<ul class="nav navbar-top-links navbar-right tools-bar flex-nowrap">
	<li class="navbar-procedure-dropdown ml-0 m-l-sm m-r-sm">
		<div>
			<select class="select-procedure" name="procedure" id="tools-bar__select-procedure"> </select>
		</div>
	</li>
	<li class="toolbar__notification-badge">
		<div class="nav-image cursor_pointer mr-0">
			<NotificationBadge />
		</div>
	</li>
	<li>
		<div class="nav-image cursor_pointer toolbar__handbook">
			<span
				role="button"
				tabindex="0"
				on:keypress|stopPropagation
				target="_blank"
				style="padding: 0; margin-left: 3px;"
				on:click={() => (location.href = '/profile')}
			>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 125" x="0px" y="0px">
					<defs>
						<style>
							.cls-1 {
								fill: none;
							}
						</style>
					</defs>
					<path fill="#676a6c" d="M67.1,36.39c0,9.45-7.66,20.83-17.1,20.83S32.9,45.84,32.9,36.39a17.1,17.1,0,1,1,34.2,0Z" />
					<path
						fill="#676a6c"
						d="M82.46,85.87c0,1.27-17,12.72-32.46,12.72C34.82,98.59,17.54,88,17.54,85.87c0-13.94,18.52-25.24,32.46-25.24S82.46,71.93,82.46,85.87Z"
					/>
					<path
						fill="#676a6c"
						d="M50,100A50,50,0,0,1,30.54,3.93,50,50,0,0,1,69.46,96.07,49.61,49.61,0,0,1,50,100ZM50,4.37A45.63,45.63,0,1,0,95.63,50,45.68,45.68,0,0,0,50,4.37Z"
					/>
				</svg>
			</span>
		</div>
	</li>
	<li>
		<div class="nav-image cursor_pointer toolbar__handbook">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 85" x="0px" y="0px">
				<path
					fill="#676a6c"
					d="M34,1A33,33,0,1,0,67,34,33,33,0,0,0,34,1Zm0,55.42a4,4,0,1,1,4-4A4,4,0,0,1,34,56.42ZM40,39.1a3.57,3.57,0,0,0-2,3.3v2.07a1,1,0,0,1-1,1H31a1,1,0,0,1-1-1V42.4a11.57,11.57,0,0,1,6.65-10.58,6.39,6.39,0,0,0,3.75-6.09,6.5,6.5,0,0,0-6.15-6.14A6.39,6.39,0,0,0,27.6,26a1,1,0,0,1-1,1h-6a1,1,0,0,1-1-1,14.4,14.4,0,0,1,14.95-14.4A14.41,14.41,0,0,1,40,39.1Z"
				/>
			</svg>
		</div>
	</li>
	<li>
		<div class="nav-image cursor_pointer toolbar__setting" style="margin-bottom: 5px;">
			<a href="/settings" style="padding: 0" target="_self">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
					<path
						fill="#676a6c"
						d="m2.344 15.271 2 3.46a1 1 0 0 0 1.366.365l1.396-.806c.58.457 1.221.832 1.895 1.112V21a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.598a8.094 8.094 0 0 0 1.895-1.112l1.396.806c.477.275 1.091.11 1.366-.365l2-3.46a1.004 1.004 0 0 0-.365-1.366l-1.372-.793a7.683 7.683 0 0 0-.002-2.224l1.372-.793c.476-.275.641-.89.365-1.366l-2-3.46a1 1 0 0 0-1.366-.365l-1.396.806A8.034 8.034 0 0 0 15 4.598V3a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1.598A8.094 8.094 0 0 0 7.105 5.71L5.71 4.904a.999.999 0 0 0-1.366.365l-2 3.46a1.004 1.004 0 0 0 .365 1.366l1.372.793a7.683 7.683 0 0 0 0 2.224l-1.372.793c-.476.275-.641.89-.365 1.366zM12 8c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4z"
					>
					</path>
				</svg>
			</a>
		</div>
	</li>
</ul>

<style>
	.select-procedure {
		width: 16rem;
	}

	.nav.navbar-top-links a {
		font-size: inherit;
	}

	.navbar-top-links li a {
		padding: 5px 10px;
		min-height: unset;
	}

	li {
		text-align: left;
	}

	.navbar-procedure-dropdown {
		min-width: 200px;
	}
</style>
