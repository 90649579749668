<!-- @format -->
<script>
	import { currentData, requiredFieldsFilled, commonParams } from '../stores/stores';
	import AutosaveRadioSelect from '../../components/ui/autosave_radio_select.svelte';

	const options = [
		{ id: 'yes', text: 'да, утверждался' },
		{ id: 'no', text: 'нет, не утверждался' },
	];

	$: $requiredFieldsFilled.wasDebtRestructuringApproved = !!wasDebtRestructuringApproved.value;

	$: wasDebtRestructuringApproved = {
		required: true,
		...$commonParams,
		attribute: 'was_debt_restructuring_approved',
		label: 'Утверждался ли АС план реструктуризации долгов',
		value: options.find(option => option.id === ($currentData && $currentData.wasDebtRestructuringApproved)),
		options,
	};
</script>

<AutosaveRadioSelect {...wasDebtRestructuringApproved} />
