<!-- @format -->
<!-- @format -->
<script>
	import dialogPolyfill from 'dialog-polyfill';
	import { createEventDispatcher, onMount } from 'svelte';

	export let index = 0;
	export let canClose = true;
	export let className = '';
	export let dialog = null;
	export let icon = undefined;
	export let title;

	const dispatch = createEventDispatcher();

	const cancel = () => {
		dispatch('cancel');
		dialog.close();
	};

	onMount(() => dialogPolyfill.registerDialog(dialog));
</script>

<dialog bind:this={dialog} class="dialog" class:className style="z-index: {3001 + index * 50};">
	<div class="dialog-header">
		{#if icon}
			{icon}
		{/if}
		<div class="title">{title}</div>
		{#if canClose}
			<button class="close-btn" on:click={cancel}>
				<iconify-icon icon="fa-solid:close" />
			</button>
		{/if}
	</div>
	{#if $$slots.body}
		<div class="dialog-body">
			<slot name="body" />
		</div>
	{/if}
	{#if $$slots.footer}
		<div class="dialog-footer">
			<slot name="footer" />
		</div>
	{/if}
</dialog>

<style lang="scss">
	.close-btn {
		border: none;
		cursor: pointer;
		color: #676a6c;
		outline: none;
		margin: 0;
		padding: 0;
	}

	dialog {
		position: fixed;
		top: 10%;
		/*transform: translate(0, -50%);*/
		border: none;
		box-shadow: 0 0 10px darkgray;
		padding: 0;
		margin-top: 0;
		border-radius: 5px;
		max-width: 80%;

		.dialog-header,
		.dialog-footer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #f3f3f4;
			box-sizing: border-box;
			color: #676a6c;
			padding: 7px 15px;
			width: 100%;
		}

		.dialog-header {
			border-bottom: 1px solid #e7eaec;
		}

		.dialog-body {
			padding: 15px;
		}

		.dialog-footer {
			border-top: 1px solid #e7eaec;
		}
	}

	.title {
		flex-grow: 1;
		font-size: 1rem;
		margin-right: 10px;
	}

	dialog::backdrop,
	:global(dialog + .backdrop) {
		background: rgba(0, 0, 0, 0.4);
	}
</style>
