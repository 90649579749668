<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import DownloadListForm from './download_list_form.svelte';

	export let requiredFieldsFilled;
	export let index;
	export let disabled = false;

	const modalId = 'download-worker-modal';

	let uploadFileUrl = '/templates/Шаблон работники.xlsx';
	let uploadFileName = 'Шаблон работники.xlsx';
	let uploadButtonText = 'Скачать шаблон';

	let handleSubmit = () => {};

	const handleUploadTemplate = () => {
		const link = document.createElement('a');
		link.href = uploadFileUrl;
		link.download = uploadFileName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
</script>

<Modal
	{modalId}
	{index}
	submitButtonAction={handleSubmit}
	rightButtonAction={handleUploadTemplate}
	submitButtonDisabled={!requiredFieldsFilled || disabled}
	rightButtonText={uploadButtonText}
	cancelButtonText="Закрыть"
	submitButtonText="Загрузить"
	form={true}
	deleteIcon={false}
	hideAfterSubmit={false}
	deleteButton={false}
	size="large"
>
	<h2 slot="header" class="worker-modal__header">Загрузить список работников из файла</h2>
	<div slot="body">
		<DownloadListForm bind:requiredFieldsFilled bind:handleSubmit />
	</div>
</Modal>
