<!-- @format -->
<script>
	import { transmitter } from '~/js/global_stores/base';
	import MeetingDiscussionModal from '~/svelte/meetings/creditor/discussion/_modal.svelte';
	import { isPresent, toArray } from '~/js/utils/tools';

	let indexCollapse = false;
	let meetingDiscussionModal;

	const handleNewItem = () =>
		meetingDiscussionModal.newItem({ params: { meeting_id: $transmitter.meeting.id, meeting_questions: $transmitter.meeting.questions } });
	const handleEditItem = id =>
		meetingDiscussionModal.editItem({ params: { meeting_id: $transmitter.meeting.id, meeting_questions: $transmitter.meeting.questions }, item: { id } });

	$: isDraft = isPresent($transmitter.dct) && $transmitter.dct.status == 'draft';
	$: discussions = toArray($transmitter.meeting.discussions);
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Выступления (${discussions.length})`}</span>
	</h4>
</div>
<div class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!isDraft} on:click={handleNewItem}> + Выступление </button>
		<div class="p-1">
			<ol class="records-list">
				{#each discussions as row}
					<li>
						<div
							class:simulated-link={isDraft}
							class:simulated-link-disabled={!isDraft}
							role="button"
							tabindex="0"
							on:click={() => handleEditItem(row.id)}
							on:keypress|stopPropagation
						>
							{row.note}
						</div>
					</li>
				{/each}
			</ol>
		</div>
	</div>
</div>

<MeetingDiscussionModal bind:this={meetingDiscussionModal} bind:storage={$transmitter.meeting.discussions} on:create on:update on:delete />

<style>
	.records-list {
		padding-left: 15px;
	}
	.records-list li {
		cursor: pointer;
	}
	.header {
		cursor: pointer;
	}
</style>
