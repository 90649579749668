<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch_helpers';
	import { transmitter } from 'base_stores';
	import Header from '~/svelte/components/header.svelte';
	import SettingModal from './_modal.svelte';
	import Payments from '../payments/index.svelte';
	import SyncWithKad from './sync_with_kad.svelte';

	let settingModal;
	let rows = [];
	let collapsedAccesses = false;
	let hrefId;

	$: rows = $transmitter?.settings || [];

	const handleNewSettingItem = () => settingModal.newItem();
	const handleEditSettingItem = id => settingModal.editItem(id);

	onMount(() => {
		hrefId = location.href.split('#')[1];

		fetchGet('/api/private/settings').then(result => ($transmitter.settings = result.rows));
	});
</script>

<Header><span slot="name">Настройки</span></Header>
<div
	title={collapsedAccesses ? 'скрыть доступы' : 'показать доступы'}
	role="button"
	tabindex="0"
	on:click={() => (collapsedAccesses = !collapsedAccesses)}
	on:keypress|stopPropagation
>
	<h3 class="ml-1 pt-2">
		1. Доступ к процедурам других пользователей
		{#if collapsedAccesses}
			<iconify-icon icon="fa-solid:chevron-up" class="m-r-sm hoverable" />
		{:else}
			<iconify-icon icon="fa-solid:chevron-down" class="m-r-sm hoverable" />
		{/if}
	</h3>
</div>
<div class="collapse" class:show={collapsedAccesses} id={`collapse-accesses`}>
	<div class="ml-2 mt-2">
		<button class="btn btn-xs btn-warning m-r-sm mb-1 ml-2 ml-sm-0" on:click={handleNewSettingItem}> + Доступ </button>
	</div>
	<div class="m-b-lg overlay p-1">
		<table class="table table-hover table-mobile m-0 border-collapse_separate">
			<thead class="thead-light">
				<tr>
					<th class="text-center p-w-xs">Электронная почта пользователя</th>
					<th class="text-center p-w-xs">Процедуры, к которым дан доступ</th>
					<th class="text-center p-w-xs">Комментарий</th>
				</tr>
			</thead>
			<tbody class="text-center">
				{#each rows as row}
					<tr class="cursor_pointer" on:click|stopPropagation={() => handleEditSettingItem(row.id)}>
						<td class="text-left">{row.email}</td>
						<td class="text-left">
							<div class="badge-container">
								{#each row.procedures as row (row.id)}
									<div style="white-space: nowrap">{row.name}</div>
								{/each}
							</div>
						</td>
						<td class="text-right">{row.note || ''}</td>
					</tr>
				{/each}
			</tbody>
		</table>
	</div>
</div>
<Payments />
<SyncWithKad collapsedSyncWithKad={hrefId == 'sync-with-kad'} />
<SettingModal bind:this={settingModal} />

<style>
	iconify-icon[icon='fa-solid:chevron-up'] {
		vertical-align: top;
	}

	iconify-icon[icon='fa-solid:chevron-down'] {
		vertical-align: bottom;
	}
</style>
