<!-- @format -->
<script>
	import { fetchPost } from 'utils/fetch';
	import { createEventDispatcher } from 'svelte';
	import { isPresent, isBlank, toArray, toFloat, formatSum, formatDate } from 'utils/tools';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import InputMoney from '~/svelte/components/ui/input_money.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import InfoBlock from './info_block.svelte';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';

	const dispatch = createEventDispatcher();

	export let disabled = false;
	export let fromAnswer = false;
	export let answer;
	export let formOptions;
	export let isCompleted;
	export let isLoaded;
	export let isPreviewed = false;
	export const submitForm = () => formControl.submitForm();
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => {
		data = { ...data, ...changes };
		if (Object.keys(changes).includes('counterparties')) {
			data.counterparties = [{ value: -1, label: 'Добавить кредитора', action: true }, ...data.counterparties];
		}
	};

	let formControl;
	let form, errorsBase, handleChange;
	let data = {};
	let orderOfRepaymentShow = false;

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
	};

	const setDictionariesValues = values => {
		data = { ...values };
		data.counterparties = [{ value: -1, label: 'Добавить кредитора', action: true }, ...data.counterparties];

		isPreviewed = $form.disabled_by_amendement && isPresent($form.disabled_by_amendement?.id);
	};

	const setSubmitParams = () => {
		const payout_lines_attributes = toArray($form.payout_lines).map(e => {
			return {
				id: e.id,
				payoutable_id: e.payoutable_id,
				payoutable_type: e.payoutable_type,
				sum: e.sum,
				_destroy: toFloat(e.sum) == 0,
			};
		});

		return { payout_lines_attributes, answer_id: fromAnswer ? answer.id : null };
	};

	const handleNewCounterparty = () => dispatch('newCounterparty');
	const handleEditPayoutItem = id => dispatch('editPayout', id);
	const handleShowAmendementItem = id => dispatch('showAmendement', id);

	const kinds = [
		'Основной долг',
		'Обязательные платежи в бюджет',
		'Проценты по договору',
		'Проценты за пользование займом',
		'Судебные расходы',
		'Государственная пошлина',
		'Проценты за просрочку платежа',
		'Пени',
		'Штраф',
		'Проценты за пользование чужими денежными средствами (по ст.395 ГК)',
		'Неустойка',
	];
	const kinds1 = kinds.map(e => ({ value: e, label: e }));
	const kinds2 = kinds
		.slice(0, 1)
		.concat(kinds.slice(2, kinds.length))
		.map(e => ({ value: e, label: e }));

	$: isPreviewed = isPresent($form) && isPresent($form.disabled_by_amendement?.id);

	$: if (fromAnswer && isPresent(answer) && isPresent(data.counterparties) && isBlank(data.counterparties.find(c => +c.value == +answer.counterparty_id))) {
		setData({ counterparties: [{ value: answer.counterparty_id, label: answer.counterparty_name }, ...data.counterparties.filter(c => c.value > 0)] });
		setItem({ counterparty_id: answer.counterparty_id });
	}

	$: if (fromAnswer && isPresent(answer) && isBlank(answer.counterparty_id)) {
		setAnswerCounterpartyId();
	}

	const setAnswerCounterpartyId = async () => {
		const params = { counterparty: { contractor_type: 'Organization', contractor_id: answer.addressee_id, roles: ['creditor'] } };

		const response = await fetchPost('/api/private/counterparties', params);
		const counterparties = response.counterparties.filter(c => c.roles.includes('creditor')).map(c => ({ value: c.id, label: c.name }));
		setData({ counterparties });
		setItem({ counterparty_id: response.counterparty_id });
	};
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	bind:isLoaded
	formOptions={{ ...formOptions, model: 'creditors_requirement', controller: 'creditors_requirements' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	{#if isPresent($form.born_by_amendement) && isPresent($form.born_by_amendement.id)}
		<!-- Изменение, на основании которого создано требование -->
		<Field name="born_by_amendement" label="Изменение, на основании которого создано требование">
			<div class="badge-container">
				<div
					{disabled}
					role="button"
					tabindex="0"
					on:click|stopPropagation={!disabled && handleShowAmendementItem($form.born_by_amendement.id)}
					on:keypress|stopPropagation
				>
					{$form.born_by_amendement.kind_text + ' (' + formatDate($form.born_by_amendement.judicial_act_at) + ')'}
				</div>
			</div>
		</Field>
	{/if}
	{#if !fromAnswer}
		<!-- Кредитор -->
		<Field required={true} disabled={isPreviewed || disabled} name="counterparty_id" label="Кредитор">
			<Select
				name="counterparty_id"
				items={data.counterparties}
				value={$form.counterparty_id}
				onchange={handleChange}
				on:action={handleNewCounterparty}
				isDisabled={isPreviewed || disabled}
				containerClasses="form-control"
				placeholder="Выбрать кредитора"
			></Select>
		</Field>
		<!-- Судебный акт о включении в реестр -->
		<Field required={true} disabled={isPreviewed || disabled} name="judicial_act_on_inclusion" label="Судебный акт о включении в реестр">
			<Select
				name="judicial_act_on_inclusion"
				items={data.judicial_act_on_inclusions}
				value={$form.judicial_act_on_inclusion}
				onchange={handleChange}
				isDisabled={isPreviewed || disabled}
				containerClasses="form-control"
				placeholder="Выбрать акт"
			></Select>
		</Field>
		<!-- Дата судебного акта -->
		<Datepicker
			required={true}
			model='creditors_requirement'
			attribute='judicial_act_on_inclusion_date'
			label='Дата судебного акта'
			placeholder='дд.мм.гггг'
			maskOptions={{ mask: Date }}
			disabled={isPreviewed || disabled}
			bind:value={$form.judicial_act_on_inclusion_date}
		/>
		<!-- Наименование суда -->
		<Field required={true} disabled={isPreviewed || disabled} name="court_id" label="Наименование суда">
			<Select
				name="court_id"
				items={data.courts}
				value={$form.court_id}
				onchange={handleChange}
				isDisabled={isPreviewed || disabled}
				containerClasses="form-control"
				placeholder="Выбрать суд"
			></Select>
		</Field>
	{/if}
	<!-- Очередность погашения -->
	<Field required={true} disabled={isPreviewed || disabled} name="order_of_repayment" label="Очередность погашения">
		<Select
			name="order_of_repayment"
			items={data.order_of_repayments}
			value={$form.order_of_repayment}
			onchange={handleChange}
			isDisabled={isPreviewed || disabled}
			containerClasses="form-control"
			placeholder="Выбрать очередь"
		/>
		<div
			class="wrapper-info-icon"
			role="button"
			tabindex="0"
			on:mouseover={() => (orderOfRepaymentShow = true)}
			on:mouseout={() => (orderOfRepaymentShow = false)}
			on:focus={() => (orderOfRepaymentShow = true)}
			on:blur={() => (orderOfRepaymentShow = false)}
		>
			<IconInfo />
		</div>
		{#if orderOfRepaymentShow}
			<div class="my-popover show fade"><InfoBlock /></div>
		{/if}
	</Field>
	{#if !fromAnswer}
		<!-- Голосует ли требование -->
		{#if $form.order_of_repayment == 2}
			<Field
				disabled={isPreviewed || disabled}
				name="is_voting"
				label="Требование является голосующим"
				fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left"
			>
				<input
					id="checkbox_is_voting"
					name="is_voting"
					type="checkbox"
					class="align-self-center m-r-sm m-l-xs"
					disabled={isPreviewed || disabled}
					bind:checked={$form.is_voting}
					on:change={handleChange}
				/>
				<label class="label-text mb-0" for="checkbox_is_voting">да, голосующее</label>
			</Field>
		{/if}
	{/if}
	<!-- Обеспеченность залогом -->
	{#if $form.order_of_repayment == 3}
		<Field
			disabled={isPreviewed || disabled}
			name="secured_by_pledge"
			label="Обеспеченность залогом"
			fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left"
		>
			<input
				id="checkbox_secured_by_pledge"
				name="secured_by_pledge"
				type="checkbox"
				class="align-self-center m-r-sm m-l-xs"
				disabled={isPreviewed || disabled}
				bind:checked={$form.secured_by_pledge}
				on:change={handleChange}
			/>
			<label class="label-text" for="checkbox_secured_by_pledge" style="margin-bottom: 0;">да, обеспечено</label>
		</Field>
		{#if $form.secured_by_pledge}
			<!-- Залоговое имущество -->
			<Field disabled={isPreviewed || disabled} name="possession_ids" label="Залоговое имущество">
				<Select
					id="creditors_requirement_possession_ids"
					name="possession_ids"
					items={data.creditor_possessions}
					value={$form.possession_ids}
					on:select={e => ($form.possession_ids = e.detail)}
					isMulti={true}
					isDisabled={isPreviewed || disabled}
					containerClasses="form-control"
					placeholder="Выбрать имущество"
				></Select>
			</Field>
			<!-- Залоговая дебиторка -->
			<Field disabled={isPreviewed || disabled} name="receivable_ids" label="Залоговая дебиторка">
				<Select
					id="creditors_requirement_receivable_ids"
					name="receivable_ids"
					items={data.creditor_receivables}
					value={$form.receivable_ids}
					on:select={e => ($form.receivable_ids = e.detail)}
					isMulti={true}
					isDisabled={isPreviewed || disabled}
					containerClasses="form-control"
					placeholder="Выбрать дебиторку"
				></Select>
			</Field>
		{/if}
	{/if}
	<!-- Требование за реестром -->
	<Field
		disabled={isPreviewed || disabled}
		name="off_reestr"
		label="Требование за реестром"
		fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left"
	>
		<input
			id="checkbox_off_reestr"
			name="off_reestr"
			type="checkbox"
			class="align-self-center m-r-sm m-l-xs"
			disabled={isPreviewed || disabled}
			bind:checked={$form.off_reestr}
			on:change={handleChange}
		/>
		<label class="label-text" for="checkbox_off_reestr" style="margin-bottom: 0;">да, за реестром</label>
	</Field>
	<!-- Вид обязательства -->
	<Field required={true} disabled={isPreviewed || disabled} name="kind" label="Вид обязательства">
		{#if $form.order_of_repayment == 3}
			<Select
				name="kind"
				items={$form.secured_by_pledge ? kinds2 : kinds1}
				value={$form.kind}
				onchange={handleChange}
				isDisabled={isPreviewed || disabled}
				containerClasses="form-control"
				placeholder="Выбрать вид"
			/>
		{:else}
			<input
				name="kind"
				type="text"
				class="form-control"
				bind:value={$form.kind}
				on:change={handleChange}
				disabled={isPreviewed || disabled}
				placeholder="Выбрать вид"
			/>
		{/if}
	</Field>
	<!-- Документ-основание возникновения -->
	<Field required={fromAnswer} disabled={isPreviewed || disabled} name="base_document" label="Документ-основание возникновения">
		<input
			name="base_document"
			type="text"
			class="form-control"
			bind:value={$form.base_document}
			on:change={handleChange}
			disabled={isPreviewed || disabled}
			placeholder="Договор №0 от 00.00.0000 г."
		/>
	</Field>
	<!-- Дата возникновения -->
	<Datepicker
		model='creditors_requirement'
		attribute='occurrence_date'
		label='Дата возникновения'
		placeholder='дд.мм.гггг'
		maskOptions={{ mask: Date }}
		disabled={isPreviewed || disabled}
		bind:value={$form.occurrence_date}
	/>
	<!-- Размер, определенный судом (руб.) -->
	<Field
		required={fromAnswer}
		disabled={isPreviewed || disabled}
		name="amount"
		label={fromAnswer ? 'Размер требования (руб.)' : 'Размер, определенный судом (руб.)'}
		short={true}
	>
		<InputMoney name="amount" bind:value={$form.amount} disabled={isPreviewed || disabled} klass="form-control text-right" />
	</Field>
	{#if !fromAnswer}
		<!-- Платежи по требованию -->
		<Field name="payout_lines" label="Платежи по требованию">
			<div class="badge-container">
				{#each toArray($form.payout_lines) as row}
					<div {disabled} role="button" tabindex="0" on:click|stopPropagation={!disabled && handleEditPayoutItem(row.payout_id)} on:keypress|stopPropagation>
						{formatSum(row.sum) + ' руб. (' + formatDate(row.date_at) + ')'}
					</div>
				{/each}
			</div>
		</Field>
		{#if isPreviewed && isPresent($form.disabled_by_amendement)}
			<!-- Изменение по требованию -->
			<Field name="disabled_by_amendement" label="Изменение по требованию">
				<div class="badge-container">
					<div
						{disabled}
						role="button"
						tabindex="0"
						on:click|stopPropagation={!disabled && handleShowAmendementItem($form.disabled_by_amendement?.id)}
						on:keypress|stopPropagation
					>
						{$form.disabled_by_amendement.kind_text + ' (' + formatDate($form.disabled_by_amendement.judicial_act_at) + ')'}
					</div>
				</div>
			</Field>
		{/if}
	{/if}
	<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
</Form>

<style>
	.wrapper-info-icon {
		position: absolute;
		top: 0.5rem;
		right: -0.5rem;
		z-index: 10003;
	}
	.my-popover {
		position: absolute;
		z-index: 10010;
		background-color: #fff;
		border: solid 1px;
		border-radius: 0.3rem;
		box-shadow: 0 0 10px;
		display: block;
		padding: 0.7rem 1rem;
		right: 0px;
	}
</style>
