<!-- @format -->
<script>
	//import { createEventDispatcher } from 'svelte';
	import { currentData } from '../../stores/stores';
	import { transmitter } from '~/js/global_stores/base';
	import { toArray, formatSum } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	//const dispatch = createEventDispatcher();

	// readonly
	const handleChange = e => {};
	// const handleChange = e => dispatch('change', {
	//       model: 'meeting_participant',
	//          id: $currentData.meetingParticipantId,
	//   attribute: 'inn',
	//       value: e.target.value
	// });

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingVotingSum');
		disabled = toArray($transmitter.meeting.disabled_fields).includes('meetingVotingSum');
	}
</script>

{#if visible}
	<Field value={$currentData.meetingVotingSum} name="meetingVotingSum" label="Сумма голосующих требований" {disabled}>
		<input
			type="text"
			name="meetingVotingSum"
			value={formatSum($currentData.meetingVotingSum)}
			{disabled}
			on:change={handleChange}
			placeholder=""
			class="form-control"
			autocomplete="off"
		/>

		<span class="form-text text-muted m-b-none m-l-xs">AI заполнил из <span class="hint-link svelte-foclwo">РТК</span></span>
	</Field>
{/if}
