<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { fetchGet, fetchDelete } from 'utils/fetch';
	import { options, transmitter } from 'base_stores';
	import Modal, { showModal } from '../components/modal.svelte';
	import PackageModal from './modal.svelte';
	import DeleteIcon from '../components/dashboard/delete_icon.svelte';
	import { currencyFormat } from 'utils/formats';
	import { isPresent } from 'utils/tools';

	export let mailRegistry;

	let mailPackageId;
	let deleteIconDisabled = false;

	$: mailPackages = $transmitter && $transmitter.mail_packages;

	const titles = ['Адресат', 'Почтовый адрес адресата', 'Количество листов', 'Вес, кг', 'Вид отправления'];

	const openModalForm = id => {
		mailPackageId = id;
		showModal('package-form-modal');
	};

	const deleteMailRegistry = () => {
		fetchDelete(`/api/private/mail_registries/${mailRegistry.id}`).then(_result => {
			let backUrl;
			if (mailRegistry.substitutions.dct_category == 'outgoing') {
				backUrl = '/documents/outgoings';
			} else if (mailRegistry.substitutions.dct_category == 'meetings') {
				backUrl = `/meetings/${mailRegistry.substitutions.meeting_id}/edit#notifications`;
			}
			deleteIconDisabled = false;
			location.href = backUrl;
		});
	};

	onMount(() => {
		fetchGet('/api/private/mail_packages/get_mail_packages', { mail_registry_id: mailRegistry.id }).then(
			response => ($transmitter = { ...$transmitter, mail_packages: response.mail_packages }),
		);
		fetchGet('/api/private/mail_packages/get_options').then(response => ($options = { ...$options, mail_package: response.options }));

		let dctOptions = { category: mailRegistry.substitutions.dct_category };

		if (mailRegistry.substitutions.dct_category === 'meetings') {
			dctOptions['kind'] = 'notification';
		}

		fetchGet(`/api/private/documents/as_options`, mailRegistry.substitutions).then(response => ($options = { ...$options, dct: response.dcts }));
	});
</script>

<div class="mb-0" style="overflow: auto">
	<div class="d-flex justify-content-between align-items-end">
		<button class="btn btn-sm btn-warning" on:click={() => openModalForm()}> + Отправление </button>
		<div class="px-2">
			<a download href={`/api/private/mail_registries/${mailRegistry.id}/download`} class="mr-3">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
					<path
						d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20s9 20 20 20s20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20s9 20 20 20s20-9 20-20z"
						fill="#676a6c"
					/>
				</svg>
			</a>
			<DeleteIcon bind:disabled={deleteIconDisabled} height="16" width="16" modalId="delete-mail_registry-modal" />
		</div>
	</div>
	<table class="table table-stripped border-collapse_separate mt-2 mb-0">
		<thead class="thead-light">
			<tr>
				{#each titles as title}
					<th class="text-center p-w-xs">
						{title}
					</th>
				{/each}
			</tr>
		</thead>
		<tbody>
			{#if isPresent(mailPackages)}
				{#each mailPackages as mailPackage}
					<tr class="cursor_pointer" on:click={() => openModalForm(mailPackage.id)}>
						<td width="34%">{mailPackage.dct_correspondent_out_name || ''}</td>
						<td width="34%">{mailPackage.dct_correspondent_mailing_address || ''}</td>
						<td width="10%" class="text-center">{mailPackage.document_sheets_amount}</td>
						<td width="10%" class="text-center">{currencyFormat(mailPackage.weight, 3)}</td>
						<td width="12%">
							{$options.mail_package &&
								$options.mail_package.receipt_notification_kind.find(option => option.id === mailPackage.receipt_notification_kind).text}
						</td>
					</tr>
				{/each}
			{/if}
		</tbody>
	</table>
</div>
<PackageModal modalId="package-form-modal" {mailRegistry} {mailPackageId} />
<Modal
	modalId="delete-mail_registry-modal"
	submitButtonText="Удалить"
	submitButtonCss="btn-danger"
	submitButtonAction={deleteMailRegistry}
	cancelButtonAction={() => (deleteIconDisabled = false)}
>
	<h2 slot="header">Удалить реестр почтовых отправлений?</h2>
</Modal>
