/** @format */

// нумерация Требований и Кредиторов
export default arr => {
	const rows = [...arr];

	rows.sort((a, b) => new Date(a.judicial_act_on_inclusion_date) - new Date(b.judicial_act_on_inclusion_date));

	[1, 2, 3].forEach(order => {
		const order_rows = rows.filter(e => e.order_of_repayment == order);

		const counterpartyIds = [...new Set(order_rows.map(e => e.counterparty_id))];

		order_rows.forEach((e, index) => {
			e.index = index + 1;
			e.counterparty_number = counterpartyIds.indexOf(e.counterparty_id) + 1;
		});
	});

	return rows;
};
