<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dctFormAvailable } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { currencyFormat } from 'utils/formats';
	import { showModal } from '~/svelte/components/modal.svelte';
	import UndeclaredDebtModal from '~/svelte/undeclared_debts/_modal.svelte';

	export let fieldId;

	let procedureId = null;
	let newUndeclaredDebt = false;
	let undeclaredDebtId = null;
	let indexCollapse = false;
	let disableSubmitButton = true;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	$: undeclaredDebts = ($transmitter && $transmitter.undeclaredDebts) || undeclaredDebts || [];

	const openCounterpartyModalForm = () => showModal('form-counterparty-modal');

	const openModalForm = id => {
		newUndeclaredDebt = !id;
		undeclaredDebtId = id;
		showModal('form-undeclared_debt-modal');
	};

	const loadUndeclaredDebts = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/undeclared_debts', { procedure_id: procedureId });
			$transmitter = { ...$transmitter, undeclaredDebts: response.undeclared_debts };
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: if (!$transmitter.undeclaredDebts && !fetching) {
		loadUndeclaredDebts();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="undeclared_debts col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Долги (вне РТК) (${undeclaredDebts.length})`}</span>
	</h4>
</div>
<div id="collapse-undeclared_debts" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && openModalForm(null)}> + Долг </button>
		<div class="p-1">
			{#if undeclaredDebts.length}
				<ol class="undeclared_debts-list">
					{#each undeclaredDebts as undeclaredDebt}
						<li>
							<div class="float-left simulated-link" role="button" tabindex="0" on:click={openModalForm(undeclaredDebt.id)} on:keypress|stopPropagation>
								{`${undeclaredDebt.counterparty_name} - ${currencyFormat(undeclaredDebt.amount)} руб.`}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>
<UndeclaredDebtModal {undeclaredDebtId} disabled={!$dctFormAvailable} {openCounterpartyModalForm} bind:newUndeclaredDebt bind:disableSubmitButton />

<style>
	.undeclared_debts-list {
		display: grid;
		padding-left: 15px;
		list-style-type: circle;
	}
	.undeclared_debts-list > li > div,
	.undeclared_debts > .header {
		cursor: pointer;
	}
</style>
