<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveDatepicker from '../../components/ui/autosave_datepicker.svelte';

	$: employeeNotificationDate = {
		...$commonParams,
		attribute: 'employee_notification_date',
		label: 'Дата уведомления работников',
		value: $currentData && $currentData.employeeNotificationDate,
		placeholder: 'дд.мм.гггг',
	};
</script>

<AutosaveDatepicker {...employeeNotificationDate} />
