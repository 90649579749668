<!-- @format -->
<script>
	import { autosaveStatus, transmitter as transmitter0 } from 'base_stores';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import SearchBy from '~/svelte/components/ui/dadata/search_by.svelte';
	import { createEventDispatcher } from 'svelte';

	export let value;
	export let model;
	export let attribute = null;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let label;
	export let transmitter;
	export let dataErrors = [];
	export let required = false;
	export let disabled = false;
	export let placeholder;
	export let hintData;
	export let saveAutomatically = true;

	const dispatch = createEventDispatcher();
	const inputId = `dadata-input-fio2-${model}-${attribute}`;

	$: method = id ? fetchPut : fetchPost;
	$: if (id) {
		url = `${url}/${id}`;
	}

	const handleChanges = e => {
		$autosaveStatus = null;

		value = e.detail;
		if (value && value.length > 0) {
			const v = value.split(/\s/).filter(item => item.length > 0);
			value = [...v.slice(0, 2), v.slice(2).join(' ')].map(value => value.charAt(0).toUpperCase() + value.slice(1)).join(' ');
		}

		const params = { [model]: { [attribute]: value }, [`${childModel}_id`]: childId };

		method(url, params)
			.then(response => {
				if (JSON.stringify(response) !== '{}') {
					$autosaveStatus = 'saved';
				}
				transmitter = { ...transmitter, ...response };
				$transmitter0 = { ...$transmitter0, ...response };
				dispatch('update', response);
			})
			.catch(_errors => {
				$autosaveStatus = 'not_saved';
			});
	};
</script>

<div class="form-group row" class:has-error={Boolean(dataErrors.length)} class:has-success={value}>
	<label for={inputId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<SearchBy
			method="fio"
			id={inputId}
			requiredborder={required}
			disabledtext={disabled}
			{value}
			{disabled}
			{placeholder}
			on:change={saveAutomatically && handleChanges}
			autocomplete="off"
		/>
		{#if Boolean(dataErrors.length)}
			<InputErrorsString errors={dataErrors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>

<style>
</style>
