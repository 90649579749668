<!-- @format -->
<script>
	import { fieldsReady } from 'base_stores';
	import { dctFormAvailable } from '~/svelte/dcts/stores/stores';
	import { onMount } from 'svelte';
	import { showModal } from '~/svelte/components/modal.svelte';
	import { isArray } from 'utils/tools';
	import Specialist from 'models/Specialist';
	import SpecialistModal from '~/svelte/specialists/_modal.svelte';

	export let fieldId;

	let specialists = [];
	let specialistId = null;
	let indexCollapse = false;

	const openModalForm = id => {
		specialistId = id;
		showModal('form-specialist-modal');
	};

	onMount(async () => {
		$fieldsReady[fieldId] = false;
		specialists = await Specialist.loadSpecialists();
		$fieldsReady[fieldId] = true;
	});
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="specialists col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Привлеченные специалисты (${specialists.length})`}</span>
	</h4>
</div>
<div id="collapse-specialists" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && openModalForm(null)}> + Привлечённый </button>
		<div class="p-1">
			{#if isArray(specialists)}
				<ol class="block-list">
					{#each specialists as specialist}
						<li>
							<div class="float-left simulated-link" role="button" tabindex="0" on:click={openModalForm(specialist.id)} on:keypress|stopPropagation>
								{specialist.f_name ? `${specialist.f_name} (${specialist.f_kind})` : specialist.f_kind}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>
<SpecialistModal
	{specialistId}
	disabled={!$dctFormAvailable}
	on:submit={() => (specialists = Specialist.all())}
	on:delete={() => (specialists = Specialist.all())}
/>
