/** @format */

import { toString } from 'utils/tools';

const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/';

// export const token = 'f421dc7e906ab75314403677f44d1cbf0d15a7c3';
export const token = 'e613853973e4388685beec305c27f539da4a25cf';

export const methods = {
	fio: {
		url: url + 'fio',
		input_value: item => item.value,
		search_value: text => text,
		return_value: item => item.value,
		display: item => item.value,
	},
	fms: {
		url: url + 'fms_unit',
		input_value: item => item.value,
		search_value: text => text,
		return_value: item => item, //.value,
		display: item => item.value,
	},
	email: {
		url: url + 'email',
		input_value: item => item.value,
		search_value: text => text,
		return_value: item => item.value,
		display: item => item.value,
	},
	party: {
		url: url + 'party',
		input_value: item => item.value,
		search_value: text => text,
		return_value: item => item.data,
		display: item => item.value + ' (' + item.data.inn + ')',
	},
	okved: {
		url: url + 'okved2',
		input_value: item => item.data.kod + ' ' + item.data.name,
		search_value: text => toString(text).split(' ')[0],
		return_value: item => item.data.kod + ' ' + item.data.name,
		display: item => item.data.kod + ' ' + item.data.name,
	},
	bank: {
		url: url + 'bank',
		input_value: item => item.value,
		search_value: text => text,
		return_value: item => item.data,
		display: item => item.data.bic + ', ' + item.value + ', к/с: ' + (item.data.correspondent_account || ''),
	},
	address: {
		url: url + 'address',
		input_value: item => builder([item.data.postal_code, item.data.country, item.value]),
		search_value: text => text,
		return_value: item => builder([item.data.postal_code, item.data.country, item.value]),
		display: item => builder([item.data.postal_code, item.data.country, item.value]),
	},
};

function builder(values, separator = ', ') {
	return values.filter(e => e).join(separator);
}
