<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: label = ['challenging_transactions', 'fictitious_willful_bankruptcy'].includes($dct && $dct.kind)
		? 'Дата принятия судом заявления'
		: 'Дата судебного акта о принятии заявления';

	$: bankruptcyPetitionDate = {
		...$commonParams,
		attribute: 'bankruptcy_petition_date',
		label,
		value: $currentData && $currentData.bankruptcyPetitionDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankruptcy_petition_date',
			resource: $procedure,
			resourceProp: 'bankruptcy_petition_date',
		},
	};
</script>

<AutosaveDatepicker {...bankruptcyPetitionDate} />
