<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import UndeclaredDebtForm from './_form.svelte';

	export let undeclaredDebtId;
	export let openCounterpartyModalForm;
	export let newUndeclaredDebt;
	export let disableSubmitButton;
	export let disabled = false;

	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};
</script>

<Modal
	modalId="form-undeclared_debt-modal"
	deleteIconDisabled={!undeclaredDebtId || disabled}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	submitButtonDisabled={disableSubmitButton || disabled}
	form={true}
	size="large"
>
	<h2 slot="header" class="undeclared_debt-modal__header">Карточка долга вне РТК</h2>
	<div slot="body">
		<UndeclaredDebtForm
			{undeclaredDebtId}
			{disabled}
			{openCounterpartyModalForm}
			bind:handleSubmit
			bind:handleCancel
			bind:handleDelete
			bind:newUndeclaredDebt
			bind:disableSubmitButton
		/>
	</div>
</Modal>
