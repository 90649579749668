<!-- @format -->
<script>
	import { bankruptType } from 'base_stores';
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveRadioSelect from '../../components/ui/autosave_radio_select.svelte';

	const label = {
		Person: 'Бухгалтерскую отчётность за публикацию оформить на',
		Organization: 'На кого оформить отчетные бухгалтерские документы',
	};

	const recipientOfAccountingStatementOptions = {
		Person: [
			{ id: 1, text: 'Арбитражного управляющего' },
			{ id: 2, text: 'Должника-банкрота' },
		],
		Organization: [
			{ id: 1, text: 'Арбитражного управляющего' },
			{ id: 2, text: 'Должника-банкрота' },
			//{ id: 3, text: '' }
		],
	};

	$: recipientOptions = recipientOfAccountingStatementOptions[$bankruptType];

	$: recipientOfAccountingStatement = {
		...$commonParams,
		attribute: 'recipient_of_accounting_statement',
		label: label[$bankruptType],
		value: recipientOptions && recipientOptions.find(option => option.id === $currentData.recipientOfAccountingStatement),
		options: recipientOptions,
	};
</script>

<AutosaveRadioSelect {...recipientOfAccountingStatement} />
