<!-- @format -->
<script>
	import { env } from 'base_stores';
	import { paymentMethod, promoCode } from './stores';
	import RadioSelect from '~/svelte/components/ui/radio_select.svelte';

	const paymentMethodOptions = [
		{ id: 'by_card', text: 'Картой или по QR-коду' },
		{ id: 'by_invoice', text: 'По счёту' },
	];

	let tariffOptions = [
		{ id: 1, text: `1  процедура (цена 2000 руб.)`, price: $env == 'production' ? 200000 : 200 },
		{ id: 2, text: '50  процедур (цена 1750 руб.)' },
		{ id: 3, text: '100 процедур (цена 1500 руб.)' },
		{ id: 4, text: '200 процедур (цена 1250 руб.)' },
		{ id: 5, text: '300 процедур (цена 1000 руб.)' },
	];

	let tariff = 1;

	$: tariffChoice = {
		model: 'procedure',
		attribute: 'tariff',
		options: tariffOptions,
		label: 'Количество процедур',
	};

	$: paymentMethodChoice = {
		disabled: tariff > 1 && $paymentMethod == 'by_invoice',
		model: 'procedure',
		attribute: 'paymentMethod',
		options: paymentMethodOptions,
		label: 'Способ оплаты',
	};

	$: $promoCode.hidden = tariff > 1;

	$: if (tariff == 1) {
		$paymentMethod = 'by_card';

		if ($promoCode.text && ($promoCode.discountPrice || $promoCode.discountPrice == 0) && $promoCode.discountPrice != tariffOptions[0].price) {
			const priceText = [
				'1 процедура (цена&nbsp;',
				$promoCode.isValid ? `<span class='promo-code__del'>${tariffOptions[0].price / 100}</span>` : '',
				`<span class='${$promoCode.isValid ? 'm-l-xs text-danger' : ''}'>`,
				($promoCode.isValid ? $promoCode.discountPrice : tariffOptions[0].price) / 100,
				`</span>&nbsp;руб.)`,
			].join('');
			tariffOptions[0] = { ...tariffOptions[0], text: priceText };
		} else {
			tariffOptions[0] = { ...tariffOptions[0], text: `1  процедура (цена 2000 руб.)` };
		}
	} else {
		$paymentMethod = 'by_invoice';
	}
</script>

<h4>
	<p>Для дальнейшей работы оплатите процедуру.</p>
	<RadioSelect {...tariffChoice} bind:value={tariff} />
	<RadioSelect {...paymentMethodChoice} bind:value={$paymentMethod} />
</h4>
