<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { currentData } from '../../stores/stores';
	import { transmitter } from '~/js/global_stores/base';
	//import InputDate from '~/svelte/components/ui/input_date.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import { toArray } from '~/js/utils/tools.js';
	//import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	const handleChange = e =>
		dispatch('change', {
			model: 'meeting_participant',
			id: $currentData.meetingParticipantId,
			attribute: 'birth_date',
			value: e.target.value,
		});

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = $currentData.meetingParticipantIsPerson && !toArray($transmitter.meeting.hidden_fields).includes('meetingParticipantBirthday');
		disabled =
			toArray($transmitter.meeting.disabled_fields).includes('meetingParticipantBirthday') ||
			$transmitter.meeting.bulletin_status == 'approved' ||
			$transmitter.meeting.status == 'canceled';
	}
</script>

{#if visible}
	<!--Field value={$currentData.meetingParticipantBirthday} name="meetingParticipantBirthday" label="Дата рождения участника" {disabled} short={true}>
		<InputDate name="meetingParticipantBirthday" value={$currentData.meetingParticipantBirthday} {disabled} onchange={handleChange} klass="form-control" />
	</Field-->
	<Datepicker
		model='meeting'
		attribute='meetingParticipantBirthday'
		label='Дата рождения участника'
		placeholder='дд.мм.гггг'
		maskOptions={{ mask: Date }}
		{disabled}
		value={$currentData.meetingParticipantBirthday}
		on:change={handleChange}
	/>
{/if}
