<!-- @format -->
<script context="module">
	let handleSubmit;
	let handleCancel;
	let handleDelete;

	export function incomeSourceSubmit() {
		handleSubmit();
	}
	export function incomeSourceCancel() {
		handleCancel();
	}
	export function incomeSourceDelete() {
		handleDelete();
	}
</script>

<script>
	import { procedure, transmitter, options } from 'base_stores';
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch';
	import { hideModal } from '../components/modal.svelte';
	import Input from '../components/ui/input.svelte';
	import InputCurrency from '../components/ui/input_currency.svelte';
	import Select2 from '../components/ui/select2.svelte';

	export let incomeSourceId;
	export let disableSubmitButton;
	export let disabled = false;

	const nullIncomeSource = {
		name: null,
		amount: null,
		answer_id: null,
	};

	let incomeSource = { ...nullIncomeSource };

	$: method = !!incomeSourceId ? fetchPut : fetchPost;
	$: data = { ...incomeSource };
	$: disableSubmitButton = !incomeSource.name || !incomeSource.amount;

	$: if (incomeSourceId) {
		fetchGet(`/api/private/income_sources/${incomeSourceId}`).then(response => (incomeSource = response.income_source));
	}

	$: if (!incomeSourceId) {
		incomeSource = { ...nullIncomeSource };
	}

	$: formFields = {
		name: {
			required: true,
			model: 'income_source',
			attribute: 'name',
			placeholder: 'Заработная плата',
			label: 'Наименование источника дохода',
			disabled,
		},
		amount: {
			required: true,
			model: 'income_source',
			attribute: 'amount',
			placeholder: '0,00',
			label: 'Величина дохода (руб./мес.)',
			disabled,
		},
		informationSource: {
			model: 'income_source',
			attribute: 'answer_id',
			placeholder: 'Выбрать источник',
			options: $options.answer,
			label: 'Источник информации',
			disabled,
		},
	};

	handleSubmit = () => {
		method(`/api/private/income_sources${incomeSourceId ? `/${incomeSourceId}` : ''}`, data, { procedure_id: $procedure.id })
			.then(response => {
				$transmitter = { ...$transmitter, incomeSources: response.income_sources };
				hideModal('form-income_source-modal');
			})
			.catch(errors => console.log('errors: ' + JSON.stringify(errors)));
	};

	handleDelete = () => {
		fetchDelete(`/api/private/income_sources/${incomeSourceId}`)
			.then(response => ($transmitter = { ...$transmitter, incomeSources: response.income_sources }))
			.catch(errors => console.log('errors: ' + JSON.stringify(errors)));
	};

	handleCancel = () => {
		incomeSource = { ...nullIncomeSource };
	};
</script>

{#if incomeSource}
	<Input {...formFields.name} bind:value={incomeSource.name} />
	<InputCurrency {...formFields.amount} bind:value={incomeSource.amount} />
	<Select2 {...formFields.informationSource} bind:value={incomeSource.answer_id} />
{/if}
