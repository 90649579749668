<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveDadateOkved from '../../../components/ui/autosave_dadate.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: bankruptOkved = {
		...$commonParams,
		attribute: 'bankrupt_okved',
		kind: 'okved',
		label: 'ОКВЭД должника',
		value: $currentData && $currentData.bankruptOkved,
		placeholder: 'Начните вводить ОКВЭД',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_okved',
			resource: $procedure && $procedure.bankrupt,
			resourceProp: 'okved',
		},
	};
</script>

<AutosaveDadateOkved {...bankruptOkved} on:update />
