<!-- @format -->
<script>
	import { procedure, options, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: currentCourtId = $currentData && $currentData.courtId;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('courtId');

	$: if ($dct.form_fields.includes('courtId')) {
		$requiredFieldsFilled.courtId = !!courtId.value;
	}

	$: courtId = {
		...$commonParams,
		required: $dct && $dct.correspondent_kind != 'fedresource',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		attribute: 'court_id',
		label: 'Наименование суда (АС)',
		value: $options.court && $options.court.find(option => parseInt(option.id) === parseInt(currentCourtId)),
		options: $options.court,
		placeholder: 'Искать или выбрать суд',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.court_id',
			resource: $procedure && $procedure.arbitr_case,
			resourceProp: 'court_id',
			fieldDisabled: true,
		},
	};
</script>

<AutosaveSelect2Search {...courtId} on:update />
