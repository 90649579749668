<!-- @format -->
<script>
	import { createEventDispatcher, tick } from 'svelte';
	import { isPresent, isArray, randomString } from '~/js/utils/tools.js';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import Form from './_form.svelte';
	import MeetingQuestionSampleModal from '../question_sample/_modal.svelte';

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let storage = [];

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};
	export const editItem = (options = {}) => {
		formOptions = { ...options, method: 'edit' };
		showModal(modalId);
	};
	export const showItem = (options = {}) => {
		formOptions = { ...options, method: 'show', readonly: true };
		showModal(modalId);
	};

	const modalId = 'modal-' + randomString();

	let formOptions = {};
	let form;
	let formCompleted = false;

	const formSubmit = () => {
		if (formOptions.method == 'new') {
			form.setItem({ num: storage.length ? Math.max(...storage.map(e => e.num)) + 1 : 1 });
		}

		return form.submitForm().then(result => {
			if (isPresent(result)) {
				if (isArray(storage)) {
					const arr = formOptions.method == 'edit' ? storage.map(e => (e.id == result.item.id ? { ...e, ...result.item } : e)) : [...storage, result.item];
					storage = [...arr];
				}
				dispatch(formOptions.method == 'edit' ? 'update' : 'create', result);
				return result;
			}
		});
	};

	const formDelete = () =>
		form.deleteItem().then(id => {
			if (isArray(storage)) {
				storage = storage.filter(row => row.id != id);
			}
			dispatch('delete', { items: storage });
			return id;
		});

	const formDuplicate = () => {
		const { id, ...rest } = form.getItem();
		formOptions = { item: { ...rest }, duplicateMode: true, method: 'new' };
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};

	let questionSampleModal;

	const questionSampleNew = () => questionSampleModal.newItem(formOptions);
	const questionSampleCreated = ({ detail: r }) => {
		form.setData({ meeting_question_samples: [r.item, ...form.getData().meeting_question_samples] });
		form.setItem({
			meeting_question_sample_id: r.item.value,
			question: r.item.question,
			solution: r.item.solution,
			voting: r.item.voting,
			calc_order: r.item.calc_order,
			secured_votes: r.item.secured_votes,
			bulletin_form: r.item.bulletin_form,
		});
	};
</script>

<Modal
	{modalId}
	submitButtonDisabled={formOptions.method == 'show' || !formCompleted}
	submitButtonAction={formSubmit}
	deleteIconDisabled={formOptions.method == 'new' || formOptions.readonly}
	deleteButtonAction={formDelete}
	deleteConfirmMessage="Вы действительно хотите удалить вопрос собрания?"
	showCopyAndSaveButton={false}
	showError={false}
	form={true}
	{index}
	size="large"
>
	<h2 slot="header">Карточка вопроса собрания</h2>
	<div slot="body">
		<Form bind:this={form} bind:isCompleted={formCompleted} {formOptions} on:newQuestionSample={questionSampleNew} />
	</div>
</Modal>

<MeetingQuestionSampleModal bind:this={questionSampleModal} index={index + 1} on:create={questionSampleCreated} />
