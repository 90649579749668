<!-- @format -->
<script>
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: if ($dct.form_fields.includes('date')) {
		$requiredFieldsFilled.date = !!date.value;
	}

	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('date');

	$: additionalAttributes =
		$dct.category === 'reports'
			? [
					{
						name: 'fictitious_or_deliberate_bankruptcy_signs_verification_date',
						previousValue: $currentData && $currentData.fictitiousOrDeliberateBankruptcySignsVerificationDate,
						prepareValue: value => value,
					},
					{
						name: 'documents_analysis_date_to_property_rights_registration',
						previousValue: $currentData && $currentData.documentsAnalysisDateToPropertyRightsRegistration,
						prepareValue: value => value,
					},
				]
			: null;

	$: date = {
		...$commonParams,
		required: true,
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		attribute: 'date',
		label: `Дата ${$dct.category === 'reports' ? 'отчёта' : 'документа'}`,
		value: $currentData.date,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		additionalAttributes: additionalAttributes,
	};
</script>

<AutosaveDatepicker {...date} on:update />
