<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: publicationNewspaperCopiesNumber = {
		...$commonParams,
		attribute: 'publication_newspaper_copies_number',
		label: 'Объявление из газеты «Коммерсантъ» - количество экземпляров',
		placeholder: 'Введите количество экземпляров',
		value: $currentData && $currentData.publicationNewspaperCopiesNumber,
	};
</script>

<AutosaveInput {...publicationNewspaperCopiesNumber} />
