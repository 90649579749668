<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInput from '../../components/ui/autosave_input.svelte';

	$: debtorAndArbitrManagerObjectionsNumberCourtConsidered = {
		...$commonParams,
		attribute: 'debtor_and_arbitr_manager_objections_number_court_considered',
		label: 'Количество возражений АУ и должника, рассмотренных судом',
		value: $currentData && $currentData.debtorAndArbitrManagerObjectionsNumberCourtConsidered,
		placeholder: '5',
	};
</script>

<AutosaveInput {...debtorAndArbitrManagerObjectionsNumberCourtConsidered} />
