/** @format */

// This file is automatically compiled by Webpack, along with any other files
// Hide menu in order to smoothly turn on when maximize menu
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('javascript/jquery-3.7.1.min');
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('javascript/channels');
require('javascript/advance');
require('javascript/svelte_apps');
require('javascript/admin');
//require('javascript/wysiwyg_editors/tinymce')

window.jQuery = jQuery;
window.$ = jQuery;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = name => images(name, true);
