<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import Checkbox from '~/svelte/components/ui/autosave_checkbox.svelte';

	$: bankruptWasReorg = {
		...$commonParams,
		attribute: 'bankrupt_was_reorg',
		label: 'Является правопреемником',
		value: $currentData && $currentData.bankruptWasReorg,
		text: 'да, является',
	};
</script>

<Checkbox {...bankruptWasReorg} on:update />
