<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveTextarea from '~/svelte/components/ui/autosave_textarea.svelte';

	$: hasFictitiousWillfulBankruptcySigns = $currentData && $currentData.hasFictitiousWillfulBankruptcySigns;

	$: fictitiousWillfulBankruptcySigns = {
		...$commonParams,
		attribute: 'fictitious_willful_bankruptcy_signs',
		label: 'Признаки фиктивного/преднамеренного банкротства',
		value: $currentData && $currentData.fictitiousWillfulBankruptcySigns,
		placeholder: 'В ходе процедуры выявлены сделки, направленные на вывод активов Должника…',
	};
</script>

{#if hasFictitiousWillfulBankruptcySigns}
	<AutosaveTextarea {...fictitiousWillfulBankruptcySigns} />
{/if}
