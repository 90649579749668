<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { autosaveStatus, transmitter } from 'base_stores';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';

	// Вложенные атрибуты можно передавать через точку
	// model: 'a', attribute: 'b.c'
	// params => { 'a': { 'b': { 'c': value...
	export let attribute;
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let label;
	export let text;
	export let additionalParams = {};
	export let positionStyle = '';
	export let forceSave = false;
	export let infoData;
	export let saveAutomatically = true;

	export let offset = true;
	export let value = false;
	export let disabled = false;

	let showInfo = false;

	const dispatch = createEventDispatcher();

	const offsetCss = 'offset-sm-4 offset-lg-3 offset-xl-4 m-t-n-sm m-b-sm';
	const checkboxId = `checkbox-${model}-${attribute}`;

	$: method = id ? fetchPut : fetchPost;
	$: if (id) {
		url = `${url}/${id}`;
	}

	$: if (label) {
		offset = false;
	}

	$: if (forceSave) {
		handleChange();
	}

	const handleChange = () => {
		$autosaveStatus = null;
		let valueHash = attribute
			.split('.')
			.reverse()
			.reduce((acc, curr) => ({ [curr]: acc }), value);
		let params = { [model]: valueHash, [`${childModel}_id`]: childId, ...additionalParams };

		method(url, params)
			.then(response => {
				if (!forceSave && JSON.stringify(response) !== '{}') {
					$autosaveStatus = 'saved';
				}
				$transmitter = { ...$transmitter, ...response };
				forceSave = false;
				dispatch('update', response);
			})
			.catch(_errors => {
				$autosaveStatus = 'not_saved';
			});
	};
</script>

<div class="form-group row" style={positionStyle}>
	{#if label}
		<label for={checkboxId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
			{label}
		</label>
	{/if}
	<div class={`col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center ${offset ? offsetCss : ''}`}>
		<!--div class={`d-flex align-items-center checkbox-left col-sm-8 col-lg-9 col-xl-8 ${offset ? offsetCss : ''}`}-->
		<input
			type="checkbox"
			id={checkboxId}
			bind:checked={value}
			on:change={saveAutomatically && handleChange}
			{disabled}
			class="align-self-center m-r-sm m-l-xs"
		/>
		<label class="label-text" for={checkboxId}>
			{text}
		</label>

		{#if infoData}
			<div
				class="btn btn-sm my-popover ml-auto"
				role="button"
				tabindex="0"
				on:mouseover={() => (showInfo = true)}
				on:mouseout={() => (showInfo = false)}
				on:focus={() => (showInfo = true)}
				on:blur={() => (showInfo = false)}
			>
				<IconInfo />
				{#if showInfo}
					<div class="show fade">
						{infoData}
					</div>
				{/if}
			</div>
		{/if}
	</div>
</div>

<style lang="scss">
	.label-text {
		margin-bottom: 0;
	}
	.my-popover {
		position: relative;
		.show {
			position: absolute;
			z-index: 10010;
			background-color: #fff;
			border: solid 1px;
			border-radius: 0.3rem;
			box-shadow: 0 0 10px;
			display: block;
			padding: 0.7rem 1rem;
			bottom: 25px;
			right: 0px;
			width: 300px;
			white-space: normal;
		}
	}
</style>
