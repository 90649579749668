<!-- @format -->
<script>
	import { dct, currentData, requiredFieldsFilled, commonParams } from '../stores/stores';
	import AutosaveDatepicker from '../../components/ui/autosave_datepicker.svelte';

	$: if ($dct.form_fields.includes('endDateOfRequestedInformationPeriod')) {
		$requiredFieldsFilled.endDateOfRequestedInformationPeriod = !!endDateOfRequestedInformationPeriod.value;
	}

	$: endDateOfRequestedInformationPeriod = {
		...$commonParams,
		required: true,
		attribute: 'end_date_of_requested_information_period',
		label: 'Конец периода запрашиваемых сведений',
		value: $currentData && $currentData.endDateOfRequestedInformationPeriod,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			linkText: 'Дата введения процедуры',
		},
	};
</script>

<AutosaveDatepicker {...endDateOfRequestedInformationPeriod} />
