<!-- @format -->
<!-- @format -->
<script>
	import { transmitter } from '~/js/global_stores/base.js';
	import MeetingCreditorParticipantModal from '~/svelte/meetings/creditor/participant/_modal.svelte';
	import MeetingWorkerParticipantModal from '~/svelte/meetings/worker/participant/_modal.svelte';
	import { isPresent, isArray } from '~/js/utils/tools.js';

	let indexCollapse = false;
	let meetingParticipantModal;
	let participants = [];

	const handleShowItem = id => meetingParticipantModal.showItem({ meeting: $transmitter.meeting, item: { id } });

	$: if ($transmitter.meeting && isArray($transmitter.meeting.participants)) participants = $transmitter.meeting.participants;
	$: isDraft = isPresent($transmitter.dct) && $transmitter.dct.status == 'draft';
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Участники собрания (${participants.length})`}</span>
	</h4>
</div>
<div class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<div class="p-1">
			<ol class="records-list">
				{#each participants as row}
					<li>
						<div
							class:simulated-link={isDraft}
							class:simulated-link-disabled={!isDraft}
							role="button"
							tabindex="0"
							on:click={() => handleShowItem(row.id)}
							on:keypress|stopPropagation
						>
							{row.name}
						</div>
					</li>
				{/each}
			</ol>
		</div>
	</div>
</div>

{#if isPresent($transmitter.meeting) && $transmitter.meeting.kind == 'creditor'}
	<MeetingCreditorParticipantModal bind:this={meetingParticipantModal} bind:storage={participants} />
{:else}
	<MeetingWorkerParticipantModal bind:this={meetingParticipantModal} bind:storage={participants} />
{/if}

<style>
	.records-list {
		padding-left: 15px;
	}
	.records-list li {
		cursor: pointer;
	}
	.header {
		cursor: pointer;
	}
</style>
