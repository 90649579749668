<!-- @format -->
<script>
	import { currentData, commonParams } from '../../../../stores/stores';
	import AutosaveDatepicker from '../../../../../components/ui/autosave_datepicker.svelte';

	$: policeDate = $currentData && $currentData.procedureInsurancePolicyDate;
	$: policeStartDate = $currentData && $currentData.procedureInsurancePolicyStartDate;

	$: procedureInsurancePolicyStartDate = {
		...$commonParams,
		attribute: 'procedure_insurance_policy_start_date',
		label: 'Дата начала действия полиса по доп. страховке АУ',
		value: policeStartDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: policeDate ? (policeStartDate === policeDate ? { freeMessage: 'AI заполнил из Дата полиса' } : { freeMessage: 'Изменено пользователем' }) : {},
	};
</script>

<AutosaveDatepicker {...procedureInsurancePolicyStartDate} />
