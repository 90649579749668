<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '../../stores/stores';
	import AutosaveMultipleInput from '../../../components/ui/autosave_multiple_input.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: arbitrManager = $procedure && $procedure.arbitr_manager;

	$: if ($dct.form_fields.includes('arbitrManagerFullName')) {
		$requiredFieldsFilled.arbitrManagerFullName = !!arbitrManagerFullName.data[0].value;
	}

	$: localCommonParams = { ...$commonParams, disabled: true };

	$: arbitrManagerFullName = {
		...localCommonParams,
		required: true,
		label: 'ФИО АУ',
		placeholder: 'Фамилия Имя Отчество',
		data: [
			{
				name: 'arbitr_manager_surname',
				value: $currentData && $currentData.arbitrManagerSurname,
				localizedName: 'Фамилия',
			},
			{
				name: 'arbitr_manager_name',
				value: $currentData && $currentData.arbitrManagerName,
				localizedName: 'Имя',
			},
			{
				name: 'arbitr_manager_patronymic',
				value: $currentData && $currentData.arbitrManagerPatronymic,
				localizedName: 'Отчество',
			},
		],
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_surname',
			resource: arbitrManager && arbitrManager.person,
			resourceProp: 'surname',
		},
	};
</script>

<AutosaveMultipleInput {...arbitrManagerFullName} on:update />
