<!-- @format -->
<script>
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';

	export let formOptions;
	export let isCompleted;
	export let isLoaded;
	export const submitForm = () => formControl.submitForm();
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => (data = { ...data, ...changes });

	let formControl;
	let form, handleChange;
	let data = {};

	const setInitialazedForm = instance => {
		form = instance.form;
		handleChange = instance.handleChange;
	};

	const setDictionariesValues = values => {
		data = { ...values };
	};

	const setSubmitParams = () => ({});
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	bind:isLoaded
	formOptions={{ ...formOptions, model: 'setting', controller: 'settings' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<!-- Email -->
	<Field required={true} name="email" label="Электронная почта пользователя">
		<input name="email" type="text" class="form-control required-border" bind:value={$form.email} on:change={handleChange} placeholder="mail@mail.ru" />
	</Field>
	<!-- Процедуры, к которым дан доступ -->
	<Field required={true} name="procedure_ids" label="Процедуры, к которым дан доступ">
		<Select
			name="procedure_ids"
			items={data.procedures}
			value={$form.procedure_ids}
			on:select={e => ($form.procedure_ids = e.detail)}
			isMulti={true}
			containerClasses="form-control required-border"
			placeholder="Выбрать процедуру"
		></Select>
	</Field>
	<!-- Комментарий -->
	<Field name="note" label="Комментарий">
		<textarea name="note" rows="3" class="form-control" bind:value={$form.note} on:change={handleChange} placeholder="Иванов И.И." />
	</Field>
</Form>
