<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	export let attrId

	// $substitutions?.financial_analysis_date || $procedure?.introduction_date

	const labels = {
		reports: 'Дата, на которую проведен финанализ',
		financial_analysis: 'Дата, на которую проводится анализ',
	};

	$: financialAnalysisDate = {
		...$commonParams,
		attrId,
		attribute: 'financial_analysis_date',
		label: labels[$dct.category],
		value: $currentData?.financialAnalysisDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData:
			$procedure?.introduction_date == $currentData?.financialAnalysisDate
				? {
						url: `/procedures/${$procedure?.id}`,
						linkText: 'Процедура',
						consumerNew: $newObject,
						resource: $procedure,
						resourceProp: 'introduction_date',
					}
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveDatepicker {...financialAnalysisDate} />
