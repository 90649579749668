<!-- @format -->
<script>
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import EventForm from './_form.svelte';
	import Case from 'case';
	import { bankruptType, transmitter } from 'base_stores';
	import { currentData } from '~/svelte/dcts/stores/stores';
	import { fetchGet, fetchPost } from 'utils/fetch';
	import { cardsTitle } from 'utils/formats';
	import { isPresent } from 'utils/tools';
	import { tick } from 'svelte';

	export let eventData;
	export let pagination;
	export let selectedProcedureIds;
	export let exceptCompleted;
	export let requiredFieldsFilled;
	export let searchParams;

	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};

	const metaDataParticles = {
		predostavit_v_bki_informatsiyu_o_dolzhnike: { correspondentKinds: ['bki'] },
		predostavit_v_bki_informatsiyu_o_dolzhnike_ul: { correspondentKinds: ['bki'] },
		opublikovat_vvedenie_procedury_na_fedresurs_ru: { correspondentKinds: ['fedresource'] },
		opublikovat_vvedenie_procedury_v_kommersant: { correspondentKinds: ['newspaper'] },
		prinyat_ot_doljnika_dokumentaciyu_rd: { correspondentKinds: ['document'], kinds: ['documentation_acceptance_and_transfer_act'] },
		prinyat_ot_doljnika_dokumentaciyu_ri: { correspondentKinds: ['document'], kinds: ['documentation_acceptance_and_transfer_act'] },
		'otpravit_zapros-uvedomlenie_rukovoditelyu_dolzhnika': { correspondentKinds: ['bankrupt'] },
		'otpravit_zapros-uvedomlenie_dolzhniku': { correspondentKinds: ['bankrupt'] },
		'napravit-v-sud-hodatajstvo-o-priobshchenii-publikacij': { kinds: ['petition_for_publications_inclusion'] },
		otpravit_zaprosy_v_gosorgany: {
			correspondentKinds: [
				'administration',
				'bti',
				'gibdd',
				'gtn',
				'zags',
				'mvd',
				'mchs',
				'sfr',
				'rosgvardiya',
				'rsa',
				'favt',
				'fns',
				'fsis',
				'fssp',
				'czn',
				'rosreestr',
				'court',
				'rtn',
				'rosimuschestvo',
			],
			kinds: ['request', 'notification', 'notification_request', 'statement'],
		},
		prepare_finanalize: { kinds: ['financial_state', 'challenging_transactions', 'fictitious_willful_bankruptcy'] },
	};

	$: additionalActionButtonShow = eventData && !eventData.personal;

	const goToUrl = async () => {
		const response = await fetchGet('/api/private/procedures', { procedure_id: eventData.procedure_id });

		const procedure = response.grouped_procedures
			.map(pg => pg.procedures)
			.flat()
			.find(prc => prc.id === eventData.procedure_id);
		$bankruptType = procedure && procedure.bankrupt_type;
		$transmitter = { ...$transmitter, procedure: response.procedure };

		if (eventData && eventData.hint === 'Открыть страницу «Отчёты»') {
			return (location.href = '/documents/reports');
		} else if (eventData && eventData.hint === 'Открыть страницу «Исходящие документы»') {
			return (location.href = '/documents/outgoings');
		}

		if (Object.keys(metaDataParticles).includes(eventData.code)) {
			const selectedDctsMetaData = $transmitter.dcts_meta_data.filter(
				data =>
					data.bankrupt_type == $bankruptType &&
					data.procedure_phase == procedure.phase_number &&
					(Object.keys(metaDataParticles[eventData.code]).includes('correspondentKinds')
						? metaDataParticles[eventData.code].correspondentKinds.includes(data.correspondent_kind)
						: true) &&
					(Object.keys(metaDataParticles[eventData.code]).includes('kinds') ? metaDataParticles[eventData.code].kinds.includes(data.kind) : true),
			);

			const dctsParams = selectedDctsMetaData.map(metaData => {
				let substitutions = {};
				for (let key of metaData.form_fields) {
					substitutions[Case.snake(key)] = $currentData[key];
				}
				for (let person of ['bankrupt', 'arbitrManager']) {
					if (metaData.form_fields.includes(`${person}FullName`)) {
						for (let key of [`${person}Surname`, `${person}Name`, `${person}Patronymic`]) {
							substitutions[Case.snake(key)] = $currentData[key];
						}
					}
				}

				return { meta_record_id: metaData.id, substitutions };
			});

			const params = { category: 'outgoing', dcts_params: dctsParams, procedure_id: procedure.id };

			await fetchPost('/api/private/documents', params)
				.then(response => (location.href = `/documents/outgoing/edit?ids=${response.dcts.map(dct => dct.id)}&new=true`))
				.catch(errors => console.log('errors: ' + JSON.stringify(errors)));
		}
	};

	const requirementDuplicate = () => {
		eventData.id = null;
		hideModal('event-modal');
		tick().then(() => showModal('event-modal'));
	};
</script>

<Modal
	modalId="event-modal"
	index="0"
	submitButtonAction={handleSubmit}
	submitButtonDisabled={!requiredFieldsFilled}
	{additionalActionButtonShow}
	additionalActionButtonText={'Сделать'}
	additionalActionButtonAction={goToUrl}
	additionalActionButtonDisabled={eventData && !eventData.hint && !eventData.personal}
	cancelButtonText="Закрыть"
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	deleteIconDisabled={(eventData && !eventData.personal) || !eventData}
	showCopyAndSaveButton={eventData && eventData.personal && eventData.id}
	copyAndSaveButtonAction={requirementDuplicate}
	form={true}
	hint={(eventData && eventData.hint) || ''}
	size="large"
>
	<h2 slot="header" class="creditors_requirement-modal__header">{cardsTitle('задачи', isPresent(eventData))}</h2>
	<div slot="body">
		<EventForm
			event={eventData}
			{pagination}
			{selectedProcedureIds}
			{exceptCompleted}
			bind:handleSubmit
			bind:handleCancel
			bind:handleDelete
			bind:requiredFieldsFilled
			bind:searchParams
		/>
	</div>
</Modal>
