<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveDatepicker from '../../../components/ui/autosave_datepicker.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('resolutionIntroductionDate');

	$: resolutionIntroductionDate = {
		...$commonParams,
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hidden: !$currentData.insertFirstParagraph,
		attribute: 'resolution_introduction_date',
		label: 'Дата резолютивной части о введении процедуры',
		value: $currentData && $currentData.resolutionIntroductionDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.resolution_introduction_date',
			resource: $procedure,
			resourceProp: 'resolution_introduction_date',
		},
	};
</script>

{#if !resolutionIntroductionDate.hidden}
	<AutosaveDatepicker {...resolutionIntroductionDate} on:update />
{/if}
