<!-- @format -->
<script>
	import { procedure } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveCheckbox from '../../components/ui/autosave_checkbox.svelte';
	import { isPresent } from 'utils/tools';

	$: isSigned = isPresent($procedure.arbitr_manager) && isPresent($procedure.arbitr_manager.signature);
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('signature');

	$: params = {
		...$commonParams,
		attribute: 'signature',
		label: 'Включить подпись АУ в .pdf',
		value: $currentData.signature,
		text: isSigned ? 'Да' : 'АУ сейчас не имеет подписи',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved' || !isSigned,
		infoData: 'Изображение подписи можно добавить на странице АУ',
	};
</script>

<AutosaveCheckbox {...params} on:update />
