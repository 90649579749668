<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { isPresent, isBlank, isArray, toArray, toFloat, formatSum, formatDate } from '~/js/utils/tools.js';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import { procedure } from '~/js/global_stores/base.js';

	const dispatch = createEventDispatcher();

	export let formOptions;
	export let isCompleted;
	export const submitForm = () => formControl.submitForm();
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => (data = { ...data, ...changes });

	let formControl;
	let form, errorsBase, handleChange;
	let data = {};

	let meeting_question_samples = [];

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
	};

	const setDictionariesValues = values => {
		data = { ...values };
	};
	const setSubmitParams = () => {
		const params = {};

		return params;
	};
	const selectQuestionSampleItem = ({ detail: newItem }) => {
		$form = {
			...$form,
			question: newItem.question,
			solution: newItem.solution,
			voting: newItem.voting,
			calc_order: newItem.calc_order,
			secured_votes: newItem.secured_votes,
			bulletin_form: newItem.bulletin_form,
		};
	};
	const handleNewQuestionSample = () => dispatch('newQuestionSample');

	$: if (isPresent($form) && isBlank($form.meeting_question_sample_id)) {
		$form.question = '';
		$form.solution = '';
	}
	$: if (isArray(data.meeting_question_samples))
		meeting_question_samples = [{ value: -1, label: 'Добавить вопрос', action: true }, ...data.meeting_question_samples];
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	formOptions={{ ...formOptions, model: 'meeting_question', controller: 'meetings/' + formOptions.params.meeting_id + '/meeting_questions' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<!-- Вопрос -->
	<Field required={true} name="meeting_question_sample_id" label="Вопрос" disabled={formOptions.readonly}>
		<Select
			name="meeting_question_sample_id"
			items={toArray(meeting_question_samples)}
			value={$form.meeting_question_sample_id}
			onchange={handleChange}
			on:select={selectQuestionSampleItem}
			on:action={handleNewQuestionSample}
			isDisabled={formOptions.readonly}
			containerClasses="form-control"
			placeholder="Выбрать вопрос"
		></Select>
	</Field>
	<!-- Формулировка вопроса -->
	<Field required={true} disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly} name="question" label="Формулировка вопроса">
		<textarea
			name="question"
			rows="3"
			class="form-control"
			value={$form.question}
			on:change={handleChange}
			disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
			placeholder="Утверждение отчета арбитражного управляющего"
		/>
	</Field>
	<!-- Решение, поставленное на голосование -->
	<Field
		required={true}
		disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
		name="solution"
		label="Решение, поставленное на голосование"
	>
		<textarea
			name="solution"
			rows="3"
			class="form-control"
			value={$form.solution}
			on:change={handleChange}
			disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
			placeholder="Утвердить отчет арбитражного управляющего"
		/>
	</Field>
	<!-- Предложение АУ по вопросу -->
	{#if $form.first_meeting}
		<Field disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly} name="arbitr_proposal" label="Предложение АУ по вопросу">
			<textarea
				name="arbitr_proposal"
				rows="3"
				class="form-control"
				value={$form.arbitr_proposal}
				on:change={handleChange}
				disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
				placeholder="Не голосовать"
			/>
		</Field>
	{/if}
	<!-- Голосование -->
	<Field name="voting" label="Голосование" disabled={formOptions.readonly}>
		{#each toArray(data.votings) as option}
			<label class="d-flex">
				<input
					type="radio"
					bind:group={$form.voting}
					class="align-self-center m-r-xs"
					value={option.value}
					disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
				/>
				{option.label}
			</label>
		{/each}
	</Field>
	{#if $form.voting == 'conduct'}
		<!-- Порядок подсчета голосов -->
		<Field name="calc_order" label="Порядок подсчета голосов" disabled={formOptions.readonly}>
			{#each toArray(data.calc_orders) as option}
				<label class="d-flex">
					<input
						type="radio"
						bind:group={$form.calc_order}
						class="align-self-center m-r-xs"
						value={option.value}
						disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
					/>
					{option.label}
				</label>
			{/each}
		</Field>
		<!-- Голоса залоговых кредиторов -->
		<Field name="secured_votes" label="Голоса залоговых кредиторов" disabled={formOptions.readonly}>
			{#each toArray(data.secured_votes) as option}
				<label class="d-flex">
					<input
						type="radio"
						bind:group={$form.secured_votes}
						class="align-self-center m-r-xs"
						value={option.value}
						disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
					/>
					{option.label}
				</label>
			{/each}
		</Field>
		<!-- Форма бюллетеня -->
		<Field required={true} name="bulletin_form" label="Форма бюллетеня" disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}>
			<Select
				name="bulletin_form"
				items={toArray(data.bulletin_forms)}
				value={$form.bulletin_form}
				onchange={handleChange}
				disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
				containerClasses="form-control"
				placeholder="Выбрать форму"
			></Select>
		</Field>
		{#if $form.bulletin_form === 'variants'}
			<!-- Вариант решения №1 -->
			<Field disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly} name="solution_var1" label="Вариант решения №1">
				<textarea
					name="solution_var1"
					rows="2"
					class="form-control"
					value={$form.solution_var1 || ''}
					on:change={handleChange}
					disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
					placeholder="Вариант решения №1"
				/>
			</Field>
			<!-- Вариант решения №2 -->
			<Field disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly} name="solution_var2" label="Вариант решения №2">
				<textarea
					name="solution_var2"
					rows="2"
					class="form-control"
					value={$form.solution_var2 || ''}
					on:change={handleChange}
					disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
					placeholder="Вариант решения №2"
				/>
			</Field>
			<!-- Вариант решения №3 -->
			<Field disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly} name="solution_var3" label="Вариант решения №3">
				<textarea
					name="solution_var3"
					rows="2"
					class="form-control"
					value={$form.solution_var3 || ''}
					on:change={handleChange}
					disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
					placeholder="Вариант решения №3"
				/>
			</Field>
			<!-- Вариант решения №4 -->
			<Field disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly} name="solution_var4" label="Вариант решения №4">
				<textarea
					name="solution_var4"
					rows="2"
					class="form-control"
					value={$form.solution_var4 || ''}
					on:change={handleChange}
					disabled={isBlank($form.meeting_question_sample_id) || formOptions.readonly}
					placeholder="Вариант решения №4"
				/>
			</Field>
		{/if}
	{/if}
	<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
</Form>
