<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('arbitrManagerInn');

	$: arbitrManagerInn = {
		...$commonParams,
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hidden: !$currentData.insertFirstParagraph,
		required: true,
		attribute: 'arbitr_manager_inn',
		label: 'ИНН АУ',
		value: $currentData.arbitrManagerInn,
		placeholder: '123456789012',
		maskOptions: { mask: '000000000000' },
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_inn',
			resource: arbitrManager && arbitrManager.person,
			resourceProp: 'inn',
		},
	};
</script>

{#if !arbitrManagerInn.hidden}
	<AutosaveInput {...arbitrManagerInn} on:update />
{/if}
