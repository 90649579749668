<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDadateAddress from '~/svelte/components/ui/autosave_dadate_input_address.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;
	$: sro = arbitrManager && arbitrManager.sro;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('sroMailingAddress');

	$: sroMailingAddress = {
		...$commonParams,
		hidden: !$currentData.insertFirstParagraph,
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved' || !$currentData.sroId,
		attribute: 'sro_mailing_address',
		label: 'Почтовый адрес СРО',
		value: $currentData && $currentData.sroMailingAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.sro_mailing_address',
			resource: sro,
			resourceProp: 'mailing_address',
		},
	};
</script>

{#if !sroMailingAddress.hidden}
	<AutosaveDadateAddress {...sroMailingAddress} on:update />
{/if}
