<!-- @format -->
<!-- @format -->
<script>
	import { showModal } from '~/svelte/components/modal.svelte';
	import { transmitter } from 'base_stores';
	import { isArray, toArray } from 'utils/tools';
	import { formatDate } from '~/js/utils/date';
	import InsurancePolicy from 'models/InsurancePolicy';
	import InsurancePolicyModal from './_modal.svelte';

	let insurancePolicyId = null;

	const openModalForm = id => {
		insurancePolicyId = id;
		showModal('form-insurance-policy-modal');
	};

	$: arbitrManager = $transmitter?.arbitr_manager || arbitrManager;
	$: arbitrManagerId = arbitrManager?.id || null;
	$: defaultArbitrManagerParams = { proprietor_type: 'ArbitrManager', proprietor_id: arbitrManagerId };
	$: insurancePolicies = toArray($transmitter?.insurancePolicies).sort((a, b) => new Date(a.date_finish) - new Date(b.date_finish));
	$: lastInsurancePolicyId = insurancePolicies[insurancePolicies.length - 1]?.id;

	$: if (arbitrManagerId) {
		InsurancePolicy.loadInsurancePolicies(defaultArbitrManagerParams);
	}
</script>

<div id="collapse-insurancePolicies" class="row">
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" on:click={() => openModalForm()}> + Страховой полис </button>
		<div class="p-1">
			{#if isArray(insurancePolicies)}
				<ol class="block-list mb-0">
					{#each insurancePolicies as insurancePolicy}
						<li>
							<div
								class="float-left simulated-link"
								class:muted-simulated-link={insurancePolicy.id != lastInsurancePolicyId}
								role="button"
								tabindex="0"
								on:click={() => openModalForm(insurancePolicy.id)}
								on:keypress|stopPropagation
							>
								{[insurancePolicy.organization, formatDate(insurancePolicy.date_finish, 'DD.MM.YYYY')].join(', до ')}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>
<InsurancePolicyModal
	{insurancePolicyId}
	on:submit={() => (insurancePolicies = InsurancePolicy.all().sort((a, b) => new Date(a.date_finish) - new Date(b.date_finish)))}
	on:delete={() => (insurancePolicies = InsurancePolicy.all().sort((a, b) => new Date(a.date_finish) - new Date(b.date_finish)))}
/>

<style lang="scss">
	.muted-simulated-link {
		color: inherit;
	}
</style>
