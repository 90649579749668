/** @format */

import { isObject, isString, isBlank } from 'utils/tools';
import { encodeParams } from 'utils/fetch';

const errors = {
	401: 'Вы должны сперва войти в систему',
	403: 'У Вас нет прав на эту операцию',
	404: 'Данные не найдены',
	504: 'Сервер недоступен. Пожалуйста попробуйте немного позже',
	999: 'Запрос содержит неэкранированные символы',
};

const defaultHeaders = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
};

// Собираем URL из хеша параметров
export const buildUrl = (url, query) => {
	if (query && Object.keys(query).length) {
		Object.keys(query).forEach(key => {
			if (isBlank(query[key])) {
				delete query[key];
			}
		});
		return url + '?' + new URLSearchParams(query);
	} else {
		return url;
	}
};

const post_patch_put = (method, url, data = {}, inHeaders = {}) => {
	const options = {
		method: method,
		response_type: 'json',
	};

	if (data instanceof FormData) {
		options.body = data;
		options.headers = { ...defaultHeaders, ...inHeaders };
		delete options.headers['Content-Type'];
	} else {
		const { headers, ...params } = data || {};

		options.body = JSON.stringify(params);
		options.headers = { ...defaultHeaders, ...headers };
	}

	if (inHeaders.response_type) {
		options.response_type = inHeaders.response_type;
	}

	return fetchProcessing(url, options);
};

export const fetchGet = (url, data) => {
	const { headers, response_type, ...params } = data || {};

	const options = {
		method: 'GET',
		response_type: response_type || 'json',
		headers: { defaultHeaders, ...headers },
	};

	return fetchProcessing(buildUrl(url, encodeParams(params)), options);
};

export const fetchPost = (url, data, inHeaders) => post_patch_put('POST', url, data, inHeaders);

export const fetchPatch = (url, data, inHeaders) => post_patch_put('PATCH', url, data, inHeaders);

export const fetchPut = (url, data, inHeaders) => post_patch_put('PUT', url, data, inHeaders);

export const fetchDelete = url => {
	const options = {
		method: 'DELETE',
		response_type: 'json',
		headers: defaultHeaders,
	};

	return fetchProcessing(url, options);
};

const detectServerError = result => {
	if (result.ok || result.status === 422) {
		return result;
	} else {
		if (result.status === 404) throw { error: true, status: result.status, message: errors[result.status] };
		else if (result.status === 401) throw { error: true, status: result.status, message: errors[result.status] };
		else if (result.status === 403) throw { error: true, status: result.status, message: errors[result.status] };
		else if (result.status === 500) throw { error: true, status: result.status, message: result.statusText };
		else if (result.status === 504) throw { error: true, status: result.status, message: errors[result.status] };
		else throw { error: true, status: result.status, message: result.statusText };
	}
};

const detectHTTPError = (error, url) => {
	if (typeof error === 'object') {
		if (error.type === 'system' && (error.errno === 'ECONNREFUSED' || error.code === 'ECONNREFUSED')) {
			return { error: true, status: 500, message: errors[504] };
		} else if (error.code === 'ERR_UNESCAPED_CHARACTERS') {
			return { error: true, status: 500, message: errors[999] + ': ' + url };
		} else {
			return error;
		}
	} else {
		return { error: true, status: 500, message: error };
	}
};

const detectAppErrors = result => {
	if (result.error) {
		if (isObject(result.error) || isString(result.error)) {
			throw result.error;
		} else {
			throw result.status + ': ' + result.message;
		}
	}

	return result;
};

const detectRedirect = result => {
	if (result.redirected) {
		document.location.url = result.url;
	}
	return result;
};

const fetchProcessing = (url, options) => {
	return fetch(url, options)
		.then(result => detectServerError(result))
		.then(result => detectRedirect(result))
		.then(result => (options.response_type == 'json' ? result.json() : result))
		.catch(error => detectHTTPError(error, url))
		.then(result => detectAppErrors(result));
};
