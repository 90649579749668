<!-- @format -->
<script>
	import { autosaveStatus, transmitter as transmitter0 } from 'base_stores';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import { validate } from 'tools/validators';
	import { isPresent, randomString } from 'utils/tools';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import SearchBy from '~/svelte/components/ui/dadata/search_by.svelte';
	import { createEventDispatcher } from 'svelte';

	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let label;
	export let transmitter;
	export let skipVerificationRequired = true;
	export let data = [];
	export let dataErrors = [];
	export let required = false;
	export let disabled = false;
	export let placeholder;
	export let hintData;
	export let attribute = null;
	export let forceSave = false;
	export let additionalParams = {};
	export let saveAutomatically = true;

	let attributes = data.map(item => item.name);
	let previousValue = null;
	let countdown = 1;

	const dispatch = createEventDispatcher();
	const inputId = `dadata-input-fio-${model}-${attributes.join('-')}`; //-${randomString()}`

	$: method = !!id ? fetchPut : fetchPost;
	$: if (!!id) {
		url = `${url}/${id}`;
	}
	$: fValue = data.some(item => item.value)
		? data
				.filter(item => item.value)
				.map(item => item.value)
				.join(' ')
		: '';
	$: isSuccess = fValue && !Boolean(dataErrors.length);

	$: if (!previousValue && countdown > 0 && isPresent(fValue)) {
		countdown = countdown - 1;
	}
	$: if (!previousValue && countdown === 0 && isPresent(fValue)) {
		previousValue = fValue.trim();
		countdown = countdown - 1;
	}
	$: if (forceSave) {
		handleChanges();
	}

	const handleChanges = () => {
		$autosaveStatus = null;

		if (previousValue !== (fValue && fValue.trim())) {
			parseNamePatronymic();
			dataErrors = [];
			let values = [];
			if (fValue && fValue.length > 0) {
				const v = fValue.split(/\s/).filter(item => item.length > 0);
				values = [...v.slice(0, 2), v.slice(2).join(' ')].map(value => value.charAt(0).toUpperCase() + value.slice(1));
			}

			data.forEach((item, index) => {
				item.value = values[index];
				const attributeErrors = validate(model, item.name, item.value, skipVerificationRequired);

				if (attributeErrors) {
					const errors = attributeErrors.map(error => [item.localizedName, error].join(' '));
					dataErrors = dataErrors ? dataErrors.concat(errors) : errors;
				}
			});

			if (!Boolean(dataErrors.length)) {
				let dataValues = {};

				data.forEach(item => (dataValues[item.name] = item.value || null));

				const params = { [model]: { ...dataValues, [attribute]: fValue }, [`${childModel}_id`]: childId, ...additionalParams };

				method(url, params)
					.then(response => {
						if (!forceSave && JSON.stringify(response) !== '{}') {
							$autosaveStatus = 'saved';
						}
						transmitter = { ...transmitter, ...response };
						$transmitter0 = { ...$transmitter0, ...response };
						previousValue = fValue;
						forceSave = false;
						dispatch('update', response);
					})
					.catch(_errors => {
						$autosaveStatus = 'not_saved';
					});
			} else {
				$autosaveStatus = 'not_saved';
				previousValue = null;
				data.some(item => {
					if (transmitter && transmitter[model]) {
						transmitter[model][item.name] = item.value;
					}
					$transmitter0[model] = { ...$transmitter0[model], [item.name]: item.value };
				});
			}
		}
	};

	const parseNamePatronymic = () => {
		if (fValue) {
			const values = fValue.split(' ');

			if (values.length == 2 && values[1].match(/^[А-ЯЁа-яё]\.{1}?[А-ЯЁа-яё]\.{1}?$/)) {
				const namePatronymic = values[1].split('.');
				fValue = `${values[0].charAt(0).toUpperCase() + values[0].slice(1)} ${namePatronymic[0].toUpperCase()}. ${namePatronymic[1].toUpperCase()}.`;
			}
		}
	};
</script>

<div class="form-group row" class:has-error={Boolean(dataErrors.length)} class:has-success={isSuccess}>
	<label for={inputId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<SearchBy
			method="fio"
			id={inputId}
			requiredborder={required}
			disabledtext={disabled}
			bind:value={fValue}
			{disabled}
			{placeholder}
			on:change={saveAutomatically && handleChanges}
			autocomplete="off"
		/>
		{#if Boolean(dataErrors.length)}
			<InputErrorsString errors={Boolean(dataErrors.length) ? dataErrors : null} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>
