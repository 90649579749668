/** @format */
import { writable, derived, readable } from 'svelte/store';
import { isPresent, isBlank, deepEqual } from 'utils/tools.js';
import Case from 'case';

export const env = readable(process.env.NODE_ENV, () => {});

export const autosaveStatus = writable(null);

export const procedure = writable(null);

export const transmitter = writable({});

export const options = writable({});

export const bankruptType = writable(null);

export const newObject = writable(false);

export const changedFields = writable([]);

export const payments = writable([]);

export const paymentSubscriptions = writable([]);

export const lastFinancialStateDct = writable({});

export const bankruptIsPerson = derived(procedure, $procedure => Case.capital($procedure?.bankrupt_type) === 'Person');

export const bankruptIsOrganization = derived(procedure, $procedure => Case.capital($procedure?.bankrupt_type) === 'Organization');

export const fieldsReady = writable({});

export const dctReady = derived([fieldsReady], ([$fieldsReady]) => {
	return isBlank($fieldsReady) || Object.values($fieldsReady).every(i => i || [[], '', {}, 0].includes(i));
});

export const dctDisabled = writable(false);

export const currentUserIsAdmin = derived([procedure], ([$procedure]) => {
	return $procedure && $procedure.current_user_is_admin;
});

export const processing = writable(false);

// Подписка на изменения в transmitter для procedure
transmitter.subscribe($transmitter => {
	if (isPresent($transmitter.procedure) && !deepEqual($transmitter.procedure, procedure)) {
		procedure.set($transmitter.procedure);
	}
});
