<!-- @format -->
<script>
	import Modal from '../components/modal.svelte';
	import PackageForm from './form.svelte';

	export let modalId;
	export let mailPackageId;
	export let mailRegistry;

	let requiredFieldsFilled = false;
	let mailPackageDelete = false;

	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};
</script>

<Modal
	{modalId}
	index="0"
	deleteIconDisabled={mailPackageDelete}
	submitButtonAction={handleSubmit}
	submitButtonDisabled={!requiredFieldsFilled}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	form={true}
	size="large"
>
	<h2 slot="header">Карточка почтового отправления</h2>
	<div slot="body">
		<PackageForm
			{modalId}
			{mailRegistry}
			{mailPackageId}
			bind:handleSubmit
			bind:handleCancel
			bind:handleDelete
			bind:requiredFieldsFilled
			bind:mailPackageDelete
		/>
	</div>
</Modal>
