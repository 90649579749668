<!-- @format -->
<script>
	import { transmitter } from '~/js/global_stores/base';
	import { currentData } from '../../stores/stores';
	import { toArray } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingQuestionQuestion');
		// disabled =  toArray($transmitter.meeting.disabled_fields).includes('meetingQuestionQuestion') ||
		//               $transmitter.meeting.bulletin_status == 'approved' ||
		//               $transmitter.meeting.status == 'canceled';
	}
</script>

{#if visible}
	<Field value={$currentData.meetingQuestionQuestion} name="meetingQuestionQuestion" label="Формулировка вопроса" {disabled}>
		<textarea name="meetingQuestionQuestion" rows="3" class="form-control" value={$currentData.meetingQuestionQuestion || ''} {disabled} />
	</Field>
{/if}
