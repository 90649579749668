<!-- @format -->
<script>
	import { createEventDispatcher, tick } from 'svelte';
	import { isPresent, isArray, randomString } from 'utils/tools';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import { transmitter } from 'base_stores';
	import PossessionForm from './_form.svelte';
	import RunningCostModal from '~/svelte/running_costs/_modal.svelte';
	import { cardsTitle } from 'utils/formats';

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let disabled = false;
	export let headerAddition;
	export let approvable = false;
	export let initBlank = false;

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};
	export const editItem = id => {
		formOptions = { item: { id }, method: 'edit' };
		showModal(modalId);
	};
	export const showItem = id => {
		formOptions = { item: { id }, method: 'show' };
		showModal(modalId);
	};

	const modalId = 'modal-' + randomString();

	let formOptions = {};
	let form;
	let formCompleted = false;
	let runningCostModal;

	const possessionSubmit = () =>
		form.submitForm().then(result => {
			if (isPresent(result)) {
				// update transmitter
				if (isArray($transmitter.possessions)) {
					if (isPresent(result.item.onlyResidence)) {
						$transmitter.possessions = $transmitter.possessions.map(possession =>
							possession.id != result.item.id ? { ...possession, onlyResidence: false } : result.item,
						);
					} else {
						const possessions = approvable && result.item.status == 'draft' ? 'draftPossessions' : 'possessions';

						$transmitter[possessions] =
							formOptions.method == 'edit'
								? $transmitter[possessions].map(possession => (possession.id !== result.item.id ? possession : result.item))
								: [...$transmitter[possessions], result.item];
					}
				}
				dispatch(formOptions.method == 'edit' ? 'update' : 'create', result);
				return result;
			}
		});

	const possessionDelete = () =>
		form.deleteItem().then(id => {
			if (isArray($transmitter.possessions)) {
				$transmitter.possessions = $transmitter.possessions.filter(row => row.id != id);
			}
			if (isArray($transmitter.draftPossessions)) {
				$transmitter.draftPossessions = $transmitter.draftPossessions.filter(row => row.id != id);
			}

			dispatch('delete', id);
			return id;
		});
	const possessionDuplicate = () => {
		const { id, ...rest } = form.getItem();
		formOptions = { item: { ...rest }, duplicateMode: true, method: 'new' };
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};

	const runningCostEdit = ({ detail: id }) => runningCostModal.editItem(id);
	const runningCostDeleted = ({ detail: id }) => {
		const rows = form.getItem().running_costs.filter(e => e.id != id);
		form.setItem({ running_costs: rows });
	};
	const runningCostUpdated = ({ detail: result }) => {
		const rows = form.getData().running_costs;
		const row = rows.find(el => el.id == result.item.id);
		row.sum = result.item.sum;
		row.date_at = result.item.date_at;
		form.setData({ running_costs: rows });
	};

	$: approvableParams = approvable
		? {
				additionalActionButtonShow: true,
				additionalActionButtonText: 'Утвердить',
				additionalActionButtonAction: () => {
					const { id } = form.getItem();
					dispatch('approve', id);
				},
			}
		: {
				additionalActionButtonShow: false,
			};
</script>

<Modal
	{modalId}
	submitButtonDisabled={formOptions.method == 'show' || !formCompleted || disabled}
	submitButtonAction={possessionSubmit}
	deleteIconDisabled={formOptions.method == 'new' || disabled}
	deleteButtonAction={possessionDelete}
	deleteConfirmMessage="Вы действительно хотите удалить объект имущества?"
	showCopyAndSaveButton={formOptions.method == 'edit' && !disabled}
	copyAndSaveButtonAction={possessionDuplicate}
	form={true}
	showError={false}
	{index}
	{...approvableParams}
	size="large"
>
	<h2 slot="header">{cardsTitle('объекта имущества', formOptions.method == 'edit', headerAddition)}</h2>
	<div slot="body">
		<PossessionForm {disabled} {initBlank} bind:this={form} bind:isCompleted={formCompleted} {formOptions} on:editRunningCost={runningCostEdit} />
	</div>
</Modal>

<RunningCostModal {disabled} bind:this={runningCostModal} index={index + 1} on:update={runningCostUpdated} on:delete={runningCostDeleted} />
