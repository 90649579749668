<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInput from '../../components/ui/autosave_input.svelte';

	$: numberSheetsRequirementsReestr = {
		...$commonParams,
		attribute: 'number_sheets_requirements_reestr',
		label: 'Количество листов РТК (приложение)',
		value: $currentData && $currentData.numberSheetsRequirementsReestr,
		placeholder: '5',
	};
</script>

<AutosaveInput {...numberSheetsRequirementsReestr} />
