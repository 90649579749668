<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter } from 'base_stores';
	import { createEventDispatcher } from 'svelte';
	import InsurancePolicy from 'models/InsurancePolicy';
	import { isPresent, isBlank, toArray } from 'utils/tools';
	import Input from '~/svelte/components/ui/input.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import Case from 'case';
	import moment from 'moment';

	const dispatch = createEventDispatcher();

	export let insurancePolicyId;
	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;
	export let submitButtonDisabled;

	let arbitrManager;
	let defaultParams;
	let insurancePolicy = {};
	let insurancePolicies;
	let lastInsurancePolicy;
	let notNewOrLast;
	let dateStartChanged = false;
	let dateFinishChanged = false;
	let mounted = false;

	$: dateField = {
		required: true,
		disabled: notNewOrLast,
		attribute: 'insurance_policy_date',
		model: 'arbitr_manager',
		label: 'Дата полиса',
		placeholder: 'дд.мм.гггг',
		maskOptions: { mask: Date },
	};

	$: formFields = {
		organization: {
			required: true,
			disabled: notNewOrLast,
			attribute: 'insurance_org',
			model: 'arbitr_manager',
			label: 'Страховая компания',
			placeholder: 'Первая страховая компания',
		},
		number: {
			disabled: notNewOrLast,
			attribute: 'insurance_policy_number',
			model: 'arbitr_manager',
			label: 'Номер полиса',
			placeholder: '123456',
		},
		dateStart: {
			disabled: notNewOrLast,
			attribute: 'insurance_policy_start_date',
			model: 'arbitr_manager',
			label: 'Дата начала действия полиса',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			hintData:
				insurancePolicy?.date && insurancePolicy?.date_start
					? dateStartChanged
						? { freeMessage: 'Изменено пользователем' }
						: { freeMessage: 'AI заполнил из «Дата полиса»' }
					: {},
		},
		dateFinish: {
			required: true,
			disabled: notNewOrLast,
			attribute: 'insurance_policy_finish_date',
			model: 'arbitr_manager',
			label: 'Дата окончания действия полиса',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			hintData:
				insurancePolicy?.date && insurancePolicy?.date_finish
					? dateFinishChanged
						? { freeMessage: 'Изменено пользователем' }
						: { freeMessage: 'AI заполнил на основе «Дата полиса» + 1 год - 1 день' }
					: {},
		},
	};

	$: submitButtonDisabled = isBlank(insurancePolicy.organization) || isBlank(insurancePolicy.date_finish) || notNewOrLast;

	const setDates = insurancePolicyDate => {
		if (!moment(insurancePolicyDate).isValid()) {
			return;
		}
		//insurancePolicy.date = insurancePolicyDate;

		if (isPresent(insurancePolicyDate) && !dateStartChanged) {
			insurancePolicy.date_start = insurancePolicyDate;
		}

		if (isPresent(insurancePolicyDate) && !dateFinishChanged) {
			insurancePolicy.date_finish = moment(insurancePolicyDate).add(1, 'years').subtract(1, 'day').format('YYYY-MM-DD');
		}
	};

	handleSubmit = async () => {
		insurancePolicy = { ...insurancePolicy, ...defaultParams };
		const result = isPresent(insurancePolicyId) ? await InsurancePolicy.update(insurancePolicy) : await InsurancePolicy.create(insurancePolicy);

		if (isPresent(result.errors)) {
			for (let attribute of Object.keys(formFields)) {
				formFields[attribute] = { ...formFields[attribute], errors: result.errors[Case.snake(attribute)] };
			}
			throw {};
		} else {
			dispatch('submit');
		}
	};

	handleDelete = async () => {
		await InsurancePolicy.delete(insurancePolicyId);
		dispatch('delete');
	};

	handleCancel = () => (insurancePolicy = InsurancePolicy.new(defaultParams));

	onMount(() => {
		arbitrManager = ($transmitter && $transmitter.arbitr_manager) || $transmitter?.procedure?.arbitr_manager || arbitrManager;
		defaultParams = { proprietor_type: 'ArbitrManager', proprietor_id: arbitrManager.id };
		insurancePolicy = InsurancePolicy.find(insurancePolicyId) || InsurancePolicy.new();
		insurancePolicies = toArray($transmitter?.insurancePolicies).sort((a, b) => new Date(a.date_finish) - new Date(b.date_finish));
		lastInsurancePolicy = insurancePolicies[insurancePolicies.length - 1];
		notNewOrLast = isPresent(insurancePolicyId) && insurancePolicyId != lastInsurancePolicy.id;

		if (isBlank(insurancePolicyId)) {
			if (isBlank(insurancePolicy.organization) && isPresent(lastInsurancePolicy?.organization)) {
				insurancePolicy.organization = lastInsurancePolicy.organization;
			}

			if (isBlank(insurancePolicy.date) && isPresent(lastInsurancePolicy?.date)) {
				insurancePolicy.date = moment(lastInsurancePolicy.date).add(1, 'years').format('YYYY-MM-DD');
			}

			setDates(insurancePolicy.date);
		} else {
			dateStartChanged = insurancePolicy.date_start != insurancePolicy.date;
			dateFinishChanged = insurancePolicy.date_finish != moment(insurancePolicy.date).add(1, 'years').subtract(1, 'day').format('YYYY-MM-DD');
		}

		mounted = true;
	});
</script>

<Input {...formFields.organization} bind:value={insurancePolicy.organization} />
<Input {...formFields.number} bind:value={insurancePolicy.number} />
<Datepicker {...dateField} expansionInFocus={true} bind:value={insurancePolicy.date} on:change={({ detail: d }) => setDates(d)} />
<Datepicker {...formFields.dateStart} expansionInFocus={true} bind:value={insurancePolicy.date_start} on:change={() => (dateStartChanged = mounted)} />
<Datepicker {...formFields.dateFinish} expansionInFocus={true} bind:value={insurancePolicy.date_finish} on:change={() => (dateFinishChanged = mounted)} />
