<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { fetchGet, fetchPost } from 'utils/fetch_helpers';
	import { syncedWithGoogleCalendar, errorMessage } from './store';
	import GoogleButton from '~/svelte/_shared/buttons/google.svelte';

	let googleAuthClientId;
	let loading = false;

	const authWithGoogle = () => {
		const scope = [
			//'https://www.googleapis.com/auth/calendar', // требует повышенных требований к приложению, пока забьём
			'https://www.googleapis.com/auth/calendar.app.created',
			'https://www.googleapis.com/auth/calendar.settings.readonly',
			'https://www.googleapis.com/auth/calendar.calendarlist.readonly',
		].join(' ');

		const client = google.accounts.oauth2.initCodeClient({
			client_id: googleAuthClientId,
			scope: scope,
			ux_mode: 'popup',
			access_type: 'offline',
			callback: response => {
				loading = true;

				fetchPost(
					'api/private/google_authorize',
					{ code: response.code },
					{ 'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest' },
				)
					.then(response => {
						$syncedWithGoogleCalendar = response.synced_with_google;
						loading = false;
					})
					.catch(error => {
						loading = false;
						$errorMessage = `Проблема: ${error}`;
						console.error(error);
					});
			},
		});

		client.requestCode();
	};

	const stopSyncWithGoogle = () => {
		loading = true;

		fetchPost('api/private/stop_sync_with_google').then(response => {
			$syncedWithGoogleCalendar = response.synced_with_google;
			loading = false;
		});
	};

	$: googleButtonText = $syncedWithGoogleCalendar ? 'Прекратить синхронизацию' : 'Синхронизация с календарём Google';
	$: googleButtonAction = $syncedWithGoogleCalendar ? stopSyncWithGoogle : authWithGoogle;

	onMount(() => {
		fetchGet('/api/private/get_sync_with_google_data').then(response => {
			$syncedWithGoogleCalendar = response.synced_with_google;
			googleAuthClientId = response.google_auth_client_id;
		});
	});
</script>

<svelte:head>
	<script src="https://accounts.google.com/gsi/client"></script>
</svelte:head>

<GoogleButton text={googleButtonText} action={googleButtonAction} {loading} />
