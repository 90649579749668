<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { autosaveStatus, transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { fetchGet, fetchPut } from 'utils/fetch';
	import { showModal } from '~/svelte/components/modal.svelte';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';
	import RadioSelect from '~/svelte/components/ui/radio_select.svelte';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte';
	import { isPresent } from 'utils/tools';

	let declarerChoice;
	let newDeclarerId = null;
	let tmpDeclarer = null;
	let counterpartyOptions = [];

	const suitableRoles = ['creditor', 'declarer'];

	$: procedure = ($transmitter && $transmitter.procedure) || procedure;
	$: procedureId = (procedure && procedure.id) || null;
	$: declarer = procedure && procedure.declarer;
	$: declarerId = (declarer && declarer.id) || null;

	$: if (procedure && !declarerChoice) {
		declarerChoice = declarer ? 'creditor' : 'bankrupt';
	}

	$: if (procedureId && declarerChoice === 'bankrupt') {
		newDeclarerId = null;
		nullifyDeclarer();
		changeDeclarer();
	}

	const getOptions = async () => {
		const response = await fetchGet('/api/private/counterparties/get_options_for_roles', { procedure_id: procedureId, possible_roles: suitableRoles });
		counterpartyOptions = [{ id: null, text: '' }, { id: 'new', text: 'Добавить заявителя' }, ...response.options];
	};

	const changeDeclarer = () => {
		$autosaveStatus = null;

		fetchPut(`/api/private/procedures/${procedureId}/change_declarer`, { declarer_id: newDeclarerId })
			.then(_response => {
				declarer = counterpartyOptions.find(option => parseInt(option.id) === parseInt(newDeclarerId));
				$autosaveStatus = 'saved';
			})
			.catch(_errors => ($autosaveStatus = 'not_saved'));
	};

	const nullifyDeclarer = () => {
		getOptions();
		$transmitter.procedure.declarer = null;
		declarerChoice = 'bankrupt';
	};

	const blockHandleChange = valueId => {
		newDeclarerId = valueId && (valueId === 'new' ? valueId : counterpartyOptions.find(option => parseInt(option.id) === parseInt(valueId)).id);
		return true;
	};

	const refresh = async newCounterpartyId => {
		await getOptions();
		declarer = { id: newCounterpartyId, text: counterpartyOptions.find(option => parseInt(option.id) === parseInt(newCounterpartyId)).name };
	};

	const checkSuitabilityDeclarer = item => {
		let counterparty = counterpartyOptions.find(opt => opt.id == item.id);
		if (isPresent(counterparty?.roles)) {
			return suitableRoles.some(role => counterparty.roles.includes(role));
		} else {
			return true;
		}
	};

	const actionInUnsuitabilityDeclarer = async item => {
		let counterparty = counterpartyOptions.find(opt => opt.id == item.id);
		let roles = counterparty.roles.concat(suitableRoles);
		await fetchPut(`/api/private/counterparties/${counterparty.id}`, { roles: roles });
		counterpartyOptions.find(opt => opt.id == item.id).roles = roles;
	};

	const handleBlocking = () => {
		if (newDeclarerId === 'new') {
			tmpDeclarer = declarer;
			declarerId = null;
			$transmitter.procedure.declarer = null;
			showModal('form-counterparty-modal');
		} else if (!newDeclarerId) {
			declarerChoice = 'bankrupt'; // = declarer ? 'creditor' : 'bankrupt'
		} else {
			changeDeclarer();
		}
	};

	$: formFields = {
		bankruptcyPetitionDate: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'bankruptcy_petition_date',
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Дата судебного акта о принятии заявления',
			value: procedure && procedure.bankruptcy_petition_date,
			placeholder: 'дд.мм.гггг',
		},
		declarerChoice: {
			disabled: !procedureId || !$procedureAvailable,
			model: 'procedure',
			attribute: 'declarer',
			options: [
				{ id: 'bankrupt', text: 'Должник' },
				{ id: 'creditor', text: 'Кредитор' },
			],
			label: 'Заявителем является',
		},
		declarerId: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: `declarer_id`,
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Кредитор',
			options: counterpartyOptions,
			checkSuitability: checkSuitabilityDeclarer,
			actionInUnsuitability: actionInUnsuitabilityDeclarer,
			value: declarer,
			placeholder: 'Выбрать заявителя',
			hintData: declarerId ? { freeMessage: 'AI заполнил из Контрагенты' } : null,
		},
	};

	onMount(() => {
		if (procedureId) {
			getOptions();
		}
	});
</script>

<div class="m-b-sm overlay procedure__bankruptcy-petition-fields">
	<h3>1. Заявление о банкротстве</h3>
	<AutosaveDatepicker {...formFields.bankruptcyPetitionDate} />
	<RadioSelect {...formFields.declarerChoice} bind:value={declarerChoice} />
	{#if declarerChoice === 'creditor'}
		<AutosaveSelect2Search {...formFields.declarerId} {blockHandleChange} {handleBlocking} />
	{/if}
</div>
<CounterpartyModal
	counterpartyId={declarerId}
	fixedRoles={['declarer', 'creditor']}
	on:create={({ detail: newCounterpartyId }) => refresh(newCounterpartyId)}
/>
