<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDadateAddress from '~/svelte/components/ui/autosave_dadate_input_address.svelte';

	$: transferringDocumentsAddress = {
		...$commonParams,
		attribute: 'transferring_documents_address',
		label: 'Адрес передачи документации',
		value: $currentData.transferringDocumentsAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
	};
</script>

<AutosaveDadateAddress {...transferringDocumentsAddress} on:update />
