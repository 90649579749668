<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '../../stores/stores';
	import AutosaveDadateAddress from '../../../components/ui/autosave_dadate.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: if ($dct.form_fields.includes('financialAnalysisPlace')) {
		$requiredFieldsFilled.financialAnalysisPlace = !!financialAnalysisPlace.value;
	}

	$: financialAnalysisPlace = {
		required: true,
		...$commonParams,
		attribute: 'financial_analysis_place',
		label: 'Место проведения анализа',
		value: $currentData && $currentData.financialAnalysisPlace,
		placeholder: 'г. Королёв',
		hintData:
			($procedure && $procedure.creation_place_docs) === ($currentData && $currentData.financialAnalysisPlace)
				? {
						url: `/procedures/${procedureId}`,
						linkText: 'Процедура',
						consumerNew: $newObject,
						resource: $procedure,
						resourceProp: 'creation_place_docs',
					}
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveDadateAddress {...financialAnalysisPlace} />
