<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { currentData } from '../../stores/stores';
	import { transmitter } from '~/js/global_stores/base';
	import SearchBy from '~/svelte/components/ui/dadata/search_by.svelte';
	import { toArray } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	const handleChange = e =>
		dispatch('change', {
			model: 'meeting_participant',
			id: $currentData.meetingParticipantId,
			attribute: 'birth_place',
			value: e.detail,
		});

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = $currentData.meetingParticipantIsPerson && !toArray($transmitter.meeting.hidden_fields).includes('meetingParticipantBirthplace');
		disabled =
			toArray($transmitter.meeting.disabled_fields).includes('meetingParticipantBirthplace') ||
			$transmitter.meeting.bulletin_status == 'approved' ||
			$transmitter.meeting.status == 'canceled';
	}
</script>

{#if visible}
	<Field value={$currentData.meetingParticipantBirthplace} name="meetingParticipantBirthplace" label="Место рождения участника" {disabled}>
		<SearchBy
			method="address"
			value={$currentData.meetingParticipantBirthplace}
			{disabled}
			on:change={handleChange}
			placeholder="100000, Московская область, г. Королев, ул. Первая, д. 1"
			autocomplete="off"
		/>
	</Field>
{/if}
