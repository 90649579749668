<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { transmitter } from '~/js/global_stores/base';
	import { currentData } from '../../stores/stores';
	import { toArray } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	const handleChange = e =>
		dispatch('change', {
			model: 'meeting_question',
			id: $currentData.meetingQuestionId,
			attribute: 'solution',
			value: e.target.value,
		});

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingQuestionSolution');
		disabled =
			toArray($transmitter.meeting.disabled_fields).includes('meetingQuestionSolution') ||
			$transmitter.meeting.bulletin_status == 'approved' ||
			$transmitter.meeting.status == 'canceled';
	}
</script>

{#if visible}
	<Field value={$currentData.meetingQuestionSolution} name="meetingQuestionSolution" label="Решение, поставленное на голосование" {disabled}>
		<textarea
			name="meetingQuestionSolution"
			rows="3"
			class="form-control"
			value={$currentData.meetingQuestionSolution || ''}
			on:change={handleChange}
			{disabled}
		/>
	</Field>
{/if}
