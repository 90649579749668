<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: creditorsFirstMeetingParticipantsNumber = {
		...$commonParams,
		attribute: 'creditors_first_meeting_participants_number',
		label: 'Количество участников 1-го собрания кредиторов',
		value: $currentData && $currentData.creditorsFirstMeetingParticipantsNumber,
		placeholder: '5',
	};
</script>

<AutosaveInput {...creditorsFirstMeetingParticipantsNumber} />
