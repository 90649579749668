<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter } from '~/js/global_stores/base.js';
	import { getTemplate } from './template.js';
	import moment from 'moment';

	export let applyStyles;

	let previewDct = '';

	onMount(() => {
		moment.locale('ru');
	});

	$: previewDct = getTemplate({ applyStyles }, $transmitter).preview;
</script>

<!-- eslint-disable-next-line svelte/no-at-html-tags -->
{@html previewDct}
