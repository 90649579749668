<!-- @format -->
<script>
	import { createEventDispatcher, tick } from 'svelte';
	import { isPresent, isArray, randomString } from '~/js/utils/tools.js';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import Form from './_form.svelte';

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let storage = [];

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};
	export const editItem = (options = {}) => {
		formOptions = { ...options, method: 'edit' };
		showModal(modalId);
	};
	export const showItem = (options = {}) => {
		formOptions = { ...options, method: 'show', readonly: true };
		showModal(modalId);
	};

	const modalId = 'modal-' + randomString();

	let formOptions = {};
	let form;
	let formCompleted = false;

	const formSubmit = () =>
		form.submitForm().then(result => {
			if (isPresent(result)) {
				if (isArray(storage)) {
					if (formOptions.method == 'edit') {
						storage = storage.map(e => (e.id == result.item.id ? { ...e, ...result.item } : e));
					} else {
						storage = [...storage, result.item];
					}
				}
				dispatch(formOptions.method == 'edit' ? 'update' : 'create', result);
				return result;
			}
		});
	const formDelete = () =>
		form.deleteItem().then(id => {
			if (isArray(storage)) {
				storage = storage.filter(row => row.id != id);
			}
			dispatch('delete', id);
			return id;
		});
	const formDuplicate = () => {
		const { id, ...rest } = form.getItem();
		formOptions = { item: { ...rest }, duplicateMode: true, method: 'new' };
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};
</script>

<Modal
	{modalId}
	submitButtonDisabled={formOptions.method == 'show' || !formCompleted}
	submitButtonAction={formSubmit}
	deleteIconDisabled={formOptions.method == 'new'}
	deleteButtonAction={formDelete}
	deleteConfirmMessage="Вы действительно хотите удалить выступление?"
	showCopyAndSaveButton={false}
	showError={false}
	form={true}
	{index}
	size="large"
>
	<h2 slot="header">Карточка выступления</h2>
	<div slot="body">
		<Form bind:this={form} bind:isCompleted={formCompleted} {formOptions} />
	</div>
</Modal>
