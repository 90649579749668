<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { isPresent, isBlank, isArray, toArray, toFloat, formatSum, formatDate } from '~/js/utils/tools.js';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import { procedure } from '~/js/global_stores/base.js';

	const dispatch = createEventDispatcher();

	export let formOptions;
	export let isCompleted;
	export const submitForm = () => formControl.submitForm();
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => (data = { ...data, ...changes });

	let formControl;
	let form, errorsBase, handleChange;
	let data = {};

	let participants = [];
	let meeting_question_samples = [];

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
	};

	const setDictionariesValues = values => {
		data = { ...values };
		participants = formOptions.params.participants.map(e => ({ value: e.id, label: e.name }));
	};
	const setSubmitParams = () => {
		const params = {};

		return params;
	};
	const selectQuestionSampleItem = async ({ detail: newItem }) => {
		$form = {
			...$form,
			question: newItem.question,
			solution: newItem.solution,
			voting: newItem.voting,
			calc_order: newItem.calc_order,
			secured_votes: newItem.secured_votes,
			bulletin_form: newItem.bulletin_form,
		};
	};
	const handleNewQuestionSample = () => dispatch('newQuestionSample');

	$: if (isPresent($form) && isBlank($form.meeting_question_sample_id)) {
		$form.question = '';
		$form.solution = '';
	}
	$: if (isArray(data.meeting_question_samples))
		meeting_question_samples = [{ value: -1, label: 'Добавить вопрос', action: true }, ...data.meeting_question_samples];
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	formOptions={{ ...formOptions, model: 'meeting_question', controller: 'meetings/' + formOptions.params.meeting_id + '/meeting_questions' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<!-- Вопрос -->
	<Field required={true} name="meeting_question_sample_id" label="Вопрос">
		<Select
			name="meeting_question_sample_id"
			items={meeting_question_samples}
			value={$form.meeting_question_sample_id}
			onchange={handleChange}
			on:select={selectQuestionSampleItem}
			on:action={handleNewQuestionSample}
			containerClasses="form-control"
			placeholder="Выбрать вопрос"
		></Select>
	</Field>
	<!-- Формулировка вопроса -->
	<Field required={true} disabled={isBlank($form.meeting_question_sample_id)} name="question" label="Формулировка вопроса">
		<textarea
			name="question"
			rows="3"
			class="form-control"
			value={$form.question}
			on:change={handleChange}
			disabled={isBlank($form.meeting_question_sample_id)}
			placeholder={formOptions.form.kind == 'creditor' ? 'Утверждение отчета арбитражного управляющего' : 'Избрание представителя работников должника'}
		/>
	</Field>
	<!-- Решение, поставленное на голосование -->
	<Field required={true} disabled={isBlank($form.meeting_question_sample_id)} name="solution" label="Решение, поставленное на голосование">
		<textarea
			name="solution"
			rows="3"
			class="form-control"
			value={$form.solution}
			on:change={handleChange}
			disabled={isBlank($form.meeting_question_sample_id)}
			placeholder={formOptions.form.kind == 'creditor' ? 'Утвердить отчет арбитражного управляющего' : 'Избрать представителем работников должника'}
		/>
	</Field>
	<!-- Участник предложивший доп. вопрос -->
	<Field required={true} name="meeting_participant_id" label="Участник предложивший доп. вопрос">
		<Select
			name="meeting_participant_id"
			items={participants}
			value={$form.meeting_participant_id}
			onchange={handleChange}
			containerClasses="form-control"
			placeholder="Выбрать участника"
		></Select>
	</Field>

	{#if formOptions.form.kind == 'creditor'}
		<!-- Голосование -->
		<Field name="voting" label="Голосование">
			{#each toArray(data.votings) as option}
				<label class="d-flex">
					<input
						type="radio"
						bind:group={$form.voting}
						class="align-self-center m-r-xs"
						value={option.value}
						disabled={isBlank($form.meeting_question_sample_id)}
					/>
					{option.label}
				</label>
			{/each}
		</Field>
		<!-- Порядок подсчета голосов -->
		{#if $form.voting == 'conduct'}
			<Field name="calc_order" label="Порядок подсчета голосов">
				{#each toArray(data.calc_orders) as option}
					<label class="d-flex">
						<input
							type="radio"
							bind:group={$form.calc_order}
							class="align-self-center m-r-xs"
							value={option.value}
							disabled={isBlank($form.meeting_question_sample_id)}
						/>
						{option.label}
					</label>
				{/each}
			</Field>
			<!-- Голоса залоговых кредиторов -->
			<Field name="secured_votes" label="Голоса залоговых кредиторов">
				{#each toArray(data.secured_votes) as option}
					<label class="d-flex">
						<input
							type="radio"
							bind:group={$form.secured_votes}
							class="align-self-center m-r-xs"
							value={option.value}
							disabled={isBlank($form.meeting_question_sample_id)}
						/>
						{option.label}
					</label>
				{/each}
			</Field>
		{/if}
	{/if}

	<!-- Форма бюллетеня -->
	<Field required={true} name="bulletin_form" label="Форма бюллетеня" disabled={isBlank($form.meeting_question_sample_id)}>
		<Select
			name="bulletin_form"
			items={toArray(data.bulletin_forms)}
			value={$form.bulletin_form}
			onchange={handleChange}
			disabled={isBlank($form.meeting_question_sample_id)}
			containerClasses="form-control"
			placeholder="Выбрать форму"
		></Select>
	</Field>

	<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
</Form>
