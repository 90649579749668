<!-- @format -->
<script>
	import { newObject, procedure } from 'base_stores';
	import { dct, currentData, commonParams } from '../stores/stores';
	import AutosaveDatepicker from '../../components/ui/autosave_datepicker.svelte';

	$: hearingDate = {
		...$commonParams,
		attribute: 'hearing_date',
		label: 'Дата заседания по результатам процедуры',
		value: $currentData.hearingDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			url: `/procedures/${$procedure.id}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.hearing_date',
			resource: $procedure,
			resourceProp: 'hearing_date',
		},
	};
</script>

<AutosaveDatepicker {...hearingDate} />
