<!-- @format -->
<script>
	import { dct, currentData, requiredFieldsFilled, commonParams, substitutions } from '../stores/stores';
	import AutosaveDatePeriod from '../../components/ui/autosave_date_period.svelte';

	$: if ($dct.form_fields.includes('fictitiousBankruptcyInvestigatedPeriod')) {
		$requiredFieldsFilled.fictitiousBankruptcyInvestigatedPeriod =
			!!fictitiousBankruptcyInvestigatedPeriodStartDate.period[0] && !!fictitiousBankruptcyInvestigatedPeriodStartDate.period[1];
	}

	$: fictitiousBankruptcyInvestigatedPeriodStartDate = {
		...$commonParams,
		required: true,
		attribute: 'fictitious_bankruptcy_investigated_period',
		label: 'с',
		labelAlign: 'right',
		period: $currentData?.fictitiousBankruptcyInvestigatedPeriod,
		index: 0,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData:
			moment($currentData.financialAnalysisDate).subtract(3, 'years').format('YYYY-MM-DD') === $currentData.fictitiousBankruptcyInvestigatedPeriod[0]
				? { linkText: 'Дата, на которую проводится анализ', additionalText: ' - 3 года' }
				: { freeMessage: 'Изменено пользователем' },
	};

	$: fictitiousBankruptcyInvestigatedPeriodFinishDate = {
		...$commonParams,
		required: true,
		attribute: 'fictitious_bankruptcy_investigated_period',
		label: 'по',
		labelAlign: 'right',
		period: $currentData?.fictitiousBankruptcyInvestigatedPeriod,
		index: 1,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData:
			$currentData.financialAnalysisDate === $currentData.fictitiousBankruptcyInvestigatedPeriod[1] ? { linkText: 'Дата, на которую проводится анализ' } : { freeMessage: 'Изменено пользователем' },
	};
</script>

<div class="form-group row">
	<div class="col-sm-12">Исследуемый период фиктивности/преднамеренности банкротства:</div>
</div>
<AutosaveDatePeriod {...fictitiousBankruptcyInvestigatedPeriodStartDate} />
<AutosaveDatePeriod {...fictitiousBankruptcyInvestigatedPeriodFinishDate} />
