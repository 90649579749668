<!-- @format -->
<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import Specialist from 'models/Specialist';
	import { bankruptIsPerson, bankruptIsOrganization } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { isBlank, isArray } from 'utils/tools';
	import Header from '../components/header.svelte';
	import { showModal } from '../components/modal.svelte';
	import SpecialistModal from './_modal.svelte';

	let specialists = [];
	let specialistId = null;
	let mounted = false;

	const openModalForm = id => {
		specialistId = id;
		showModal('form-specialist-modal');
	};

	onMount(async () => {
		await Specialist.loadSpecialistKindOptions();
		await Specialist.loadCurrentCreditorOptions();
		await Specialist.loadPaymentSourceOptions();
		await Specialist.loadPaymentFrequencyOptions();
		specialists = await Specialist.loadSpecialists();
		mounted = true;
	});
</script>

<Header><span slot="name">Привлечённые специалисты</span></Header>
<div class="m-b-lg mb-0">
	<button
		class="btn btn-sm btn-warning m-r-sm m-b-sm ml-2 ml-sm-0"
		disabled={!mounted || !$procedureAvailable}
		on:click={$procedureAvailable && openModalForm(null)}
	>
		+ Привлечённый
	</button>
</div>
<div class="m-b-lg overlay p-1 footer-margin">
	<table class="table table-hover table-mobile m-0">
		<thead class="thead-light">
			<tr>
				{#each Specialist.tableTitles() as title}
					<th class="text-center p-w-xs">
						{title}
					</th>
				{/each}
			</tr>
		</thead>
		{#if isArray(specialists)}
			<tbody class="text-center">
				{#each specialists as specialist}
					<tr class="specialist" on:click={$procedureAvailable && openModalForm(specialist.f_id)}>
						<td class="text-left">{specialist.f_kind}</td>
						<td class="text-left">{specialist.f_name}</td>
						{#if $bankruptIsPerson}
							<td>{specialist.f_court_decision_date}</td>
						{/if}
						<td class="text-left">{specialist.f_contract}</td>
						<td>{specialist.f_contract_date}</td>
						{#if $bankruptIsOrganization}
							<td class="text-left">{specialist.f_accreditation}</td>
						{/if}
						<td class="text-right">{specialist.f_payment}</td>
						<td class="text-left">{specialist.f_payment_source}</td>
						<td class="text-left">{specialist.f_payment_frequency}</td>
						<td>
							{#if specialist.payment_frequency === 'monthly'}
								{specialist.f_contract_accrual_start_date} - {specialist.f_contract_accrual_end_date}
							{/if}
						</td>
					</tr>
				{/each}
				{#if mounted && isBlank(specialists)}
					<tr class="no-data"><td class="text-secondary" colspan={Specialist.tableTitles().length}>Нет данных</td></tr>
				{/if}
				{#if !mounted}
					<tr><td class="text-secondary" colspan={Specialist.tableTitles().length}><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
				{/if}
			</tbody>
		{/if}
	</table>
</div>
<SpecialistModal {specialistId} on:submit={() => (specialists = Specialist.all())} on:delete={() => (specialists = Specialist.all())} />

<style lang="scss">
	.specialist > td {
		cursor: pointer;
	}

	.no-data:hover {
		color: inherit;
		background-color: inherit;
	}
</style>
