<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveDatepicker from '../../../components/ui/autosave_datepicker.svelte';

	$: verificationDate = $currentData && $currentData.documentsAnalysisDateToPropertyRightsRegistration;

	$: documentsAnalysisDateToPropertyRightsRegistration = {
		...$commonParams,
		attribute: 'documents_analysis_date_to_property_rights_registration',
		label: 'Дата анализа документов по регистрации прав собственности',
		value: verificationDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: ($currentData && $currentData.date) === verificationDate ? { linkText: 'Дата отчёта' } : { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveDatepicker {...documentsAnalysisDateToPropertyRightsRegistration} on:update />
