<!-- @format -->
<script>
	import imask from 'imask';
	import { toString } from 'utils/tools';

	export let id;
	export let name;
	export let value;
	export let disabled;
	export let placeholder;
	export let klass;
	export let mask = '+{7} (000) 000-00-00';
	export let lazy = false;

	let imaskInstance;

	const handlePaste = event => {
		value = (event.clipboardData || window.clipboardData).getData('text')?.replace(/^(\+7|8)/, '');
	};

	const imaskPhone = node => {
		imaskInstance = imask(node, { mask, lazy });
		imaskInstance.on('complete', () => (value = imaskInstance.unmaskedValue));

		return {
			destroy() {
				imaskInstance.destroy();
			},
		};
	};

	$: if (imaskInstance) imaskInstance.value = toString(value);
</script>

<input {id} {name} type="text" use:imaskPhone {disabled} {placeholder} class={klass} autocomplete="off" on:paste={handlePaste} />
