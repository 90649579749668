<!-- @format -->
<script>
	import { fieldsReady } from 'base_stores';
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveRadioSelect from '~/svelte/components/ui/autosave_radio_select.svelte';

	export let fieldId;

	const options = [
		{ id: 'arbitr_manager', text: 'АУ' },
		{ id: 'registrar', text: 'реестродержатель' },
	];

	const additionalAttributeData = {
		arbitr_manager: { name: 'registrar', value: null },
		registrar: { name: 'registrar', value: 'АУ' },
	};

	let forceSave = false;
	let initFieldReady = true;

	$: $fieldsReady[fieldId] = !initFieldReady;

	$: if (!($currentData && $currentData.registrarChoice)) {
		forceSave = true;
	} else {
		forceSave = false;
		initFieldReady = false;
	}

	$: registrarChoice = {
		...$commonParams,
		attribute: 'registrar_choice',
		additionalAttribute: additionalAttributeData[$currentData.registrarChoice || 'arbitr_manager'],
		label: 'Кто ведёт реестр',
		value: options.find(option => option.id === (($currentData && $currentData.registrarChoice) || 'arbitr_manager')),
		options,
		forceSave: forceSave,
	};
</script>

<AutosaveRadioSelect {...registrarChoice} />
