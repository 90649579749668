<!-- @format -->
<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { transmitter } from '~/js/global_stores/base.js';
	import { fetchGet, fetchPatch } from '~/js/utils/fetch_helpers.js';
	import { randomString } from '~/js/utils/tools.js';
	import Modal, { showModal } from '~/svelte/components/modal.svelte';
	import { getTemplate } from '~/svelte/dcts/template.js';
	import { applyStyles, meetingCreditorData } from '~/svelte/_shared/meeting_bulletin.js';
	import ErrorMessage from '~/svelte/components/message.svelte';

	const dispatch = createEventDispatcher();

	export let index = 0;
	export const open = (data, mode) => {
		participants = [];
		errors = [];
		selectedBulletins = [];
		selectedAll = true;

		$transmitter = { ...$transmitter, ...data };

		bulletinZipType = mode || 'all';

		if (mode === 'voting_creditors') {
			title = 'Скачать бюллетени (голосующие кредиторы)';
		} else {
			title = 'Скачать бюллетени';
		}

		showModal(modalId);

		fetchGet('/api/private/meetings/' + $transmitter.meeting.id + '/get_bulletins?bulletin_zip_type=' + bulletinZipType).then(result => {
			participants = result.rows.map(e => ({ ...e, selected: [e.id], bulletin_ids: e.bulletins.map(e => e.id) }));
			selectedBulletins = participants.map(e => e.bulletin_ids).flat();
		});
	};

	let participants = [];
	let selectedBulletins = [];
	let selectedAll = true;
	let processing = false;
	let errors = [];
	let title;
	let bulletinZipType;

	const modalId = randomString();

	const formSubmit = () => {
		processing = true;
		return fetchGet('/api/private/meetings/' + $transmitter.meeting.id + '/meeting_bulletins')
			.then(result =>
				Promise.all(
					result.rows.map(
						selectedBulletin =>
							new Promise((resolve, _) => {
								if (selectedBulletins.includes(selectedBulletin.id)) {
									const question = $transmitter.meeting.questions.find(e => e.id == selectedBulletin.question_id);
									const participant = $transmitter.meeting.participants.find(e => e.id == selectedBulletin.participant_id);
									const data = meetingCreditorData(question, participant);

									selectedBulletin.dct.substitutions = { ...selectedBulletin.dct.substitutions, ...data };
									$transmitter = { ...$transmitter, dct: selectedBulletin.dct };

									resolve({ id: selectedBulletin.id, pdf: getTemplate({ applyStyles }).pdfData });
								} else {
									resolve({ id: selectedBulletin.id, pdf: null });
								}
							}),
					),
				),
			)
			.then(result =>
				fetchPatch('/api/private/meetings/' + $transmitter.meeting.id, {
					meeting: { meeting_bulletins_attributes: result, bulletin_zip_type: bulletinZipType },
				}),
			)
			.then(_ => (processing = false))
			.then(_ => dispatch('download'))
			.catch(error => {
				errors = error;
				processing = false;
				throw error;
			});
	};

	const handleBulletinChange = () => {
		participants = participants.map(e => ({
			...e,
			selected: e.bulletin_ids.every(val => selectedBulletins.includes(val)) ? [e.id] : [],
		}));
		selectedAll = participants.every(e => e.selected.length);
	};
	const handleGroupChange = () => {
		selectedBulletins = participants.map(e => (e.selected.length ? e.bulletin_ids : [])).flat();
		selectedAll = participants.every(e => e.selected.length);
	};
	const handleAllChange = () => {
		selectedBulletins = selectedAll ? participants.map(e => e.bulletin_ids).flat() : [];
		participants = participants.map(e => ({ ...e, selected: selectedAll ? [e.id] : [] }));
	};
</script>

<Modal
	{modalId}
	deleteIcon={false}
	submitButtonText="Скачать"
	showCopyAndSaveButton={false}
	submitButtonDisabled={selectedBulletins.length == 0}
	submitButtonAction={formSubmit}
	form={true}
	showError={false}
	{index}
	size="large"
>
	<h2 slot="header">{title}</h2>
	<div slot="body">
		<div class="columns">
			<ul class="m-b-0 mr-auto">
				<li>
					<label>
						<input class="cursor_pointer" type="checkbox" disabled={processing} bind:checked={selectedAll} on:change={handleAllChange} />
						<strong>Выбрать все</strong>
					</label>
				</li>
			</ul>
		</div>
		<div class="columns">
			{#each participants as participant, i (participant.id)}
				<ul class:mr-auto={(i + 1) % 2 > 0} class:ml-auto={(i + 1) % 2 == 0}>
					<li>
						<label>
							<input
								class="cursor_pointer"
								type="checkbox"
								disabled={processing}
								bind:group={participant.selected}
								on:change={handleGroupChange}
								value={participant.id}
							/>
							{participant.name}
						</label>
					</li>
					{#each participant.bulletins as bulletin (bulletin.id)}
						<li style="padding-left: 18px;">
							<label>
								<input
									class="cursor_pointer"
									type="checkbox"
									disabled={processing}
									bind:group={selectedBulletins}
									on:change={handleBulletinChange}
									value={bulletin.id}
								/>
								{bulletin.name}
							</label>
						</li>
					{/each}
				</ul>
			{/each}
		</div>

		<ErrorMessage message={errors} on:click={() => (errors = [])} />
	</div>
</Modal>

<style lang="scss">
	.columns {
		display: flex;
		flex-wrap: wrap;
		ul {
			list-style-type: none;
			padding-left: 0;

			input[type='checkbox'] {
				margin-right: 5px;
			}
		}
	}
</style>
