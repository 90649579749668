<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { isPresent } from 'utils/tools';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import InputDadataAddress from '~/svelte/components/ui/input_dadata_address.svelte';
	import InputDadataEmail from '~/svelte/components/ui/input_dadata_email.svelte';
	import Input from '~/svelte/components/ui/input.svelte';
	import MultipleInput from '~/svelte/components/ui/multiple_input.svelte';
	import InputErrorsString from '~/svelte/components/ui/input_errors_string.svelte';
	import Checkbox from '~/svelte/components/ui/checkbox.svelte';
	import DadataPassportDepartment from '~/svelte/components/ui/input_dadata_passport_department.svelte';
	import { counterparty } from './_stores';

	export let contractor;
	export let errors;
	export let individualFields;
	export let nameFieldFilled;
	export let disabled = false;

	let fullName = {};
	let person;

	$: nullPerson = {
		id: null,
		inn: null,
		passport_number: null,
		passport_department: null,
		passport_date: null,
		phone: null,
		email: null,
		surname: null,
		name: null,
		patronymic: null,
		reg_address_full: null,
		mailing_address_full: null,
		individual_entrepreneur: individualEntrepreneur,
		ogrnip: null,
		errors: {},
	};

	$: individualFields = { person: { ...person, ...fullName } };
	$: individualEntrepreneur = $counterparty?.contractor_type == 'Individual_Entrepreneur';
	$: mailingAddressFull = contractor?.person?.mailing_address_full;
	$: regAddressFull = contractor?.person?.reg_address_full;
	$: mailingAddressCheckValue = mailingAddressFull && regAddressFull && mailingAddressFull === regAddressFull;
	$: mailingAddressChecked = mailingAddressCheckValue;
	$: nameFieldFilled = fullName.surname || fullName.name || fullName.patronymic;
	$: fullNameString = [fullName.surname, fullName.name, fullName.patronymic].filter(i => isPresent(i)).join(' ');
	$: duplicatedCounterparty = $transmitter?.counterparties
		?.filter(c => c.contractor_type == 'Person')
		?.find(c => c.full_name == fullNameString && c.id !== $counterparty.id);

	$: hasDuplicatedCounterparty = isPresent(duplicatedCounterparty);

	$: if (contractor) {
		person = contractor.person;
	} else {
		person = nullPerson;
	}

	$: if (mailingAddressChecked && person) person.mailing_address_full = person.reg_address_full;

	$: formFields = {
		fullName: {
			type: 'fio',
			required: true,
			model: 'person',
			label: 'ФИО',
			placeholder: 'Фамилия Имя Отчество',
			data: {
				surname: person.surname,
				name: person.name,
				patronymic: person.patronymic,
			},
			errors: errors && errors.surname,
			disabled,
		},
		position: {
			attribute: 'position',
			model: 'counterparty',
			label: 'Должность',
			placeholder: 'Менеджер',
			errors: errors && errors.position,
			disabled,
		},
		inn: {
			attribute: 'inn',
			model: 'person',
			label: 'ИНН',
			maskOptions: { mask: '000000000000' },
			placeholder: '123456789012',
			errors: errors && errors.inn,
			disabled,
		},
		snils: {
			attribute: 'snils',
			model: 'person',
			label: 'СНИЛС',
			maskOptions: { mask: '000-000-000 00' },
			placeholder: '123-456-789 10',
			errors: errors && errors.snils,
			disabled,
		},
		passportNumber: {
			attribute: 'passport_number',
			model: 'person',
			label: 'Паспорт',
			maskOptions: { mask: '0000 000000' },
			placeholder: '1234 567890',
			errors: errors && errors.passport_number,
			disabled,
		},
		passportDepartment: {
			attribute: 'passport_department',
			model: 'person',
			label: 'Кем выдан',
			placeholder: 'Московским РОВД',
			errors: errors && errors.passport_department,
			disabled,
		},
		passportDate: {
			attribute: 'passport_date',
			model: 'person',
			label: 'Дата выдачи',
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
			errors: errors && errors.passport_date,
			disabled,
		},
		phone: {
			attribute: 'phone',
			model: 'person',
			label: 'Телефон',
			placeholder: '+7 (000) 000-00-00',
			maskOptions: { mask: '+7 (000) 000-00-00' },
			modify: phone => phone && phone.replace(/\+7|\D/g, ''),
			errors: errors && errors.phone,
			disabled,
		},
		email: {
			attribute: 'email',
			model: 'person',
			label: 'Электронная почта',
			placeholder: 'mail@mail.ru',
			errors: errors && errors.email,
			disabled,
		},
		regAddressFull: {
			attribute: 'reg_address_full',
			model: 'organization',
			label: 'Адрес регистрации',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1',
			disabled,
		},
		mailingAddressFull: {
			disabled: mailingAddressChecked || disabled,
			attribute: 'mailing_address_full',
			model: 'organization',
			label: 'Почтовый адрес',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1',
		},
		ogrnIp: {
			attribute: 'ogrnip',
			model: 'counterparty',
			label: 'ОГРНИП',
			//maskOptions: { mask: '000000000000000' },
			placeholder: 'Введите ОГРНИП',
			errors: errors && errors.ogrnip,
			disabled,
		},
		mailingAddressCheck: {
			text: 'тот же, что и адрес регистрации',
			disabled,
		},
	};
</script>

<div>
	<MultipleInput {...formFields.fullName} bind:response={fullName} />
	{#if hasDuplicatedCounterparty}
		<div class="form-group row has-error" style="margin-top: -1rem;">
			<div class="col-sm-4 col-lg-3 col-xl-4 col-form-label"></div>
			<div class="col-sm-8 col-lg-9 col-xl-8">
				<InputErrorsString errors={($counterparty.roles.includes('worker') ? 'Работник' : 'Контрагент') + ' с такими ФИО уже существует'} />
			</div>
		</div>
	{/if}
	{#if $counterparty.roles.includes('worker')}
		<Input {...formFields.position} bind:value={$counterparty.additions.worker.position} />
	{/if}
	<Input {...formFields.inn} bind:value={person.inn} />
	<Input {...formFields.snils} bind:value={person.snils} />
	{#if individualEntrepreneur}
		<Input {...formFields.ogrnIp} bind:value={person.ogrnip} />
	{/if}
	<Input {...formFields.passportNumber} bind:value={person.passport_number} />
	<DadataPassportDepartment {...formFields.passportDepartment} bind:value={person.passport_department} />
	<Datepicker {...formFields.passportDate} bind:value={person.passport_date} />
	<InputDadataAddress {...formFields.regAddressFull} bind:value={person.reg_address_full} />
	<InputDadataAddress {...formFields.mailingAddressFull} bind:value={person.mailing_address_full} />
	<Checkbox {...formFields.mailingAddressCheck} bind:checked={mailingAddressChecked} />
	<Input {...formFields.phone} bind:value={person.phone} />
	<InputDadataEmail {...formFields.email} bind:value={person.email} />
</div>
