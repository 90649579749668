<!-- @format -->
<script>
	import SvelteTooltip from 'svelte-tooltip';
	import { saveAs } from 'file-saver';
	import IconPdfFile from '~/svelte/_icons_imgs/pdf_file.svelte';
	import IconDocFile from '~/svelte/_icons_imgs/doc_file.svelte';
	import Modal, { showModal, hideModal } from '../modal.svelte';
	import WaitingFullScreen from '~/svelte/_components/waiting/full_screen.svelte';

	export let dctId;
	export let pdfData;
	export let downloadFileName;
	export let isDraft;
	export let disabled;
	export let isModal = false;
	export let multipleFormats = false;
	export let modalId;
	export let additionField;
	export let additionFieldText;

	let additionFieldValue = false;
	let waiting = false;
	let fileName = 'downloaded.pdf';

	const getFileNameFromResponse = header => {
		const contentDispostion = header ? header.split(';') : [];
		const fileNameToken = `filename*=UTF-8''`;

		for (let item of contentDispostion) {
			if (item.trim().indexOf(fileNameToken) === 0) {
				fileName = decodeURIComponent(item.trim().replace(fileNameToken, ''));
				break;
			}
		}

		return fileName;
	};
	const handleCancel = () => {
		waiting = false;
		hideModal(modalId);
	};

	const download = async type => {
		waiting = true;
		const response = await fetch(`/api/private/documents/${dctId}/download_file`, {
			method: 'POST',
			credentials: 'same-origin',
			body: JSON.stringify({
				type,
				pdf_data: typeof pdfData === 'function' ? pdfData() : pdfData,
				filename: downloadFileName,
				[additionField]: additionFieldValue,
			}),
			headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
		});

		const blob = await response.blob();
		const fileName = getFileNameFromResponse(response.headers.get('content-disposition'));
		const fileType = response.headers.get('content-type');
		const fileBlob = new Blob([blob], { type: fileType });

		// MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(fileBlob);
		} else {
			saveAs(fileBlob, fileName);
		}
		waiting = false;
		hideModal(modalId);
	};
</script>

{#if disabled || isDraft}
	<SvelteTooltip tip="Скачать. Сначала утвердите документ." top color="#e9ecef">
		<svg width="16" height="13" viewBox="0 0 16 18" fill="none" style="opacity: 0.6; cursor: not-allowed;" xmlns="http://www.w3.org/2000/svg">
			<path d="M15 6H11V0H5V6H1L8 14L15 6ZM0 16H16V18H0V16Z" fill="#676a6c" />
		</svg>
	</SvelteTooltip>
{:else if multipleFormats || additionField}
	<span
		class="file-download cursor_pointer"
		role="button"
		tabindex="0"
		on:click={!disabled && isModal && (() => showModal(modalId))}
		on:keypress|stopPropagation
	>
		{#if disabled}
			<svg width="16" height="13" viewBox="0 0 16 18" fill="none" style="opacity: 0.6; cursor: not-allowed" xmlns="http://www.w3.org/2000/svg">
				<path d="M15 6H11V0H5V6H1L8 14L15 6ZM0 16H16V18H0V16Z" fill="#676a6c" />
			</svg>
		{:else}
			<svg width="16" height="13" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15 6H11V0H5V6H1L8 14L15 6ZM0 16H16V18H0V16Z" fill="#676a6c" />
			</svg>
		{/if}
	</span>
{:else}
	<span class="cursor_pointer" role="button" tabindex="0" on:click={() => download('pdf')} on:keypress|stopPropagation>
		<svg width="16" height="13" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15 6H11V0H5V6H1L8 14L15 6ZM0 16H16V18H0V16Z" fill="#676a6c" />
		</svg>
	</span>
{/if}

<Modal
	{modalId}
	size="small"
	submitButtonAction={!multipleFormats && additionField ? () => download('pdf') : undefined}
	submitButtonDisabled={false}
	submitButtonText="Скачать"
	withFooter={!multipleFormats}
>
	<h2 slot="header" class="download-with-appendices-modal__header">Скачать документ</h2>
	<div slot="body">
		{#if multipleFormats}
			<span
				class="pdf-download btn btn-primary btn-outline cursor_pointer"
				role="button"
				tabindex="0"
				on:click={() => download('pdf')}
				on:keypress|stopPropagation
			>
				<IconPdfFile />
				<span>pdf</span>
			</span>
			<span
				class="docx-download btn btn-primary btn-outline cursor_pointer"
				role="button"
				tabindex="0"
				on:click={() => download('docx')}
				on:keypress|stopPropagation
			>
				<IconDocFile />
				<span>docx</span>
			</span>
			<span
				class="doc-download btn btn-primary btn-outline cursor_pointer"
				role="button"
				tabindex="0"
				on:click={() => download('doc')}
				on:keypress|stopPropagation
			>
				<IconDocFile />
				<span>doc</span>
			</span>
		{/if}
		{#if additionField}
			<div class="d-flex align-items-center checkbox-left" class:mt-2={multipleFormats}>
				<input type="checkbox" id="check-appendices-for-pdf" bind:checked={additionFieldValue} {disabled} class="align-self-center m-r-sm m-l-xs" />
				<label class="label-text mb-0" for="check-appendices-for-pdf">{additionFieldText}</label>
			</div>
		{/if}
	</div>
</Modal>
<WaitingFullScreen visible={waiting} on:canceled={handleCancel} />
