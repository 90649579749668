<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import Checkbox from '../../../components/ui/autosave_checkbox.svelte';

	$: hasFactsOfIllegalUnfairBehaviorOfDebtor = {
		...$commonParams,
		attribute: 'has_facts_of_illegal_unfair_behavior_of_debtor',
		label: 'Выявлены факты незаконного/недобросовестного поведения должника',
		value: $currentData && $currentData.hasFactsOfIllegalUnfairBehaviorOfDebtor,
		text: 'Да',
	};
</script>

<Checkbox {...hasFactsOfIllegalUnfairBehaviorOfDebtor} />
