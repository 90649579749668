<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: completionDate = {
		...$commonParams,
		//required: true,
		attribute: 'completion_date',
		label:
			$procedure && ['bankruptcy_proceedings', 'property_realization'].includes($procedure.phase)
				? 'Дата окончания процедуры до продления'
				: 'Срок окончания процедуры',
		value: $currentData && $currentData.completionDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData:
			($procedure && $procedure.completion_date) === ($currentData && $currentData.completionDate)
				? {
						url: `/procedures/${procedureId}`,
						linkText: 'Процедура',
						consumerNew: $newObject,
						resource: $procedure,
						resourceProp: 'completion_date',
					}
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveDatepicker {...completionDate} />
