<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInput from '../../components/ui/autosave_input.svelte';

	$: numberJudicialActsOnInclusionInRequirementsReestr = {
		...$commonParams,
		attribute: 'number_judicial_acts_on_inclusion_in_requirements_reestr',
		label: 'в т.ч. решений о включении',
		value: $currentData && $currentData.numberJudicialActsOnInclusionInRequirementsReestr,
		placeholder: '5',
	};
</script>

<AutosaveInput {...numberJudicialActsOnInclusionInRequirementsReestr} />
