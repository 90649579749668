<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';
	import moment from 'moment';

	const startYear = moment().subtract(10, 'years').year();
	const finishYear = moment().year();
	const yearsOptions = [...Array(finishYear - startYear + 1)].map((_, i) => ({ id: String(startYear + i), text: String(startYear + i) }));

	$: livingWageYear = {
		...$commonParams,
		attribute: 'living_wage_year',
		label: 'Год данных о прожиточном минимуме',
		value: $currentData.livingWageYear && yearsOptions.find(option => option.id === $currentData.livingWageYear),
		options: yearsOptions,
		placeholder: 'Выбрать расположение объекта недвижимости',
		hintData:
			moment($currentData && $currentData.date).format('YYYY') === ($currentData && $currentData.livingWageYear)
				? { linkText: 'Дата документа' }
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveSelect2Search {...livingWageYear} />
