<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { dct, currentData } from '../../stores/stores';
	import { transmitter } from '~/js/global_stores/base';
	import { isPresent } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	// readonly
	const handleChange = e => {};
	// const handleChange = e => dispatch('change', {
	//       model: 'meeting_participant',
	//          id: $currentData.meetingParticipantId,
	//   attribute: 'inn',
	//       value: e.target.value
	// });
	let required = true;
	let visible = true;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = isPresent($transmitter.meeting.hidden_fields) ? $transmitter.meeting.hidden_fields.includes('meetingFromAt') : visible;
		disabled = isPresent($transmitter.meeting.disabled_fields) ? $transmitter.meeting.disabled_fields.includes('meetingFromAt') : disabled;
		disabled = $dct.disabled_fields && $dct.disabled_fields.includes('meetingRegistrationTime') ? true : disabled;
	}
</script>

{#if visible}
	<Field value={$currentData.meetingFromAt} name="meetingFromAt" label="Время регистрации" {disabled} {required}>
		<input
			type="text"
			name="meetingFromAt"
			value={$currentData.meetingFromAt}
			{disabled}
			{required}
			on:change={handleChange}
			class="form-control align-self-center m-r-xs"
			style="width:80px;display:inline-block;margin-right:5px;"
		/>
		&#8212;
		<input
			type="text"
			name="meetingFromAt"
			value={$currentData.meetingToAt}
			{disabled}
			{required}
			on:change={handleChange}
			class="form-control align-self-center m-r-xs"
			style="width:80px;display:inline-block;margin-left:5px;"
		/>
		<span slot="hint" class="form-text text-muted m-b-none m-l-xs"> AI заполнил из Параметры </span>
	</Field>
{/if}
