<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: creditorsClaimsNumberCourtConsidered = {
		...$commonParams,
		attribute: 'creditors_claims_number_court_considered',
		label: 'Количество требований кредиторов, рассмотренных судом',
		value: $currentData && $currentData.creditorsClaimsNumberCourtConsidered,
		placeholder: '5',
	};
</script>

<AutosaveInput {...creditorsClaimsNumberCourtConsidered} />
