<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { transmitter } from 'base_stores';
	import { currentData } from '~/svelte/dcts/stores/stores';
	import { toArray } from 'utils/tools';
	import InputMoney from '~/svelte/components/ui/input_money.svelte';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	const handleChange = e =>
		dispatch('change', {
			model: 'meeting_participant',
			id: $currentData.meetingParticipantId,
			attribute: $currentData.meetingQuestionSecuredVotes == 'accounted' ? 'voting_sum' : 'unsecured_voting_sum',
			value: e.target.value,
		});

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingParticipantVotingSum');
		disabled =
			toArray($transmitter.meeting.disabled_fields).includes('meetingParticipantVotingSum') ||
			$transmitter.meeting.bulletin_status == 'approved' ||
			$transmitter.meeting.status == 'canceled';
	}
</script>

{#if visible}
	<Field value={$currentData.meetingParticipantVotingSum} name="meetingParticipantVotingSum" label="Размер требований участника, руб." {disabled}>
		<InputMoney
			name="meetingParticipantVotingSum"
			value={$currentData.meetingParticipantVotingSum}
			onchange={handleChange}
			{disabled}
			klass="form-control text-right"
		/>
	</Field>
{/if}
