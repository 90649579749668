<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInputCurrency from '~/svelte/components/ui/autosave_input_currency.svelte';

	$: estimatedCourtCost = {
		...$commonParams,
		attribute: 'estimated_court_cost',
		label: 'Предполагаемые судебные расходы',
		value: $currentData?.estimatedCourtCost,
		placeholder: '0,00',
	};
</script>

<AutosaveInputCurrency {...estimatedCourtCost} />
