<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: publicationNewspaperNumber = {
		...$commonParams,
		attribute: 'publication_newspaper_number',
		label: 'Номер газеты «Коммерсантъ»',
		value: $currentData && $currentData.publicationNewspaperNumber,
		placeholder: '123(1234)',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.publication_newspaper_number',
			resource: $procedure,
			resourceProp: 'publication_newspaper_number',
		},
	};
</script>

<AutosaveInput {...publicationNewspaperNumber} />
