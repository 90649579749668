<!-- @format -->
<script>
	import { transmitter, autosaveStatus, processing } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { fetchPut } from 'utils/fetch';
	import { isPresent, isBlank } from 'utils/tools';
	import IconPaperclip from '~/svelte/_shared/icon_paperclip.svelte';
	import OCRDashbord from './_ocr_dashboard.svelte';
	import Modal from '../components/modal.svelte';
	import ErrorMessage from '~/svelte/components/message.svelte';

	const judicial_act_types = ['resolution', 'full_text'];
	const judicial_act_editions = { resolution: 'резолютивная часть', full_text: 'полный текст' };

	let show = { resolution: false, full_text: false };
	let deletedJudicialActType;
	let recognizedJudicialActType;
	let errors = [];

	$: procedure = $transmitter && $transmitter.procedure;
	$: procedureId = procedure && procedure.id;

	const deleteJudicialAct = () => {
		$processing = true;

		fetchPut(`/api/private/procedures/${procedure.id}/delete_judicial_act`, { type: deletedJudicialActType }).then(result => {
			$processing = false;
			$transmitter = { ...$transmitter, ...result };
		});
	};

	const recognizeJudicialAct = () => {
		$autosaveStatus = null;
		$processing = true;

		fetchPut(`/api/private/procedures/${procedureId}/recognize_judicial_act`, { type: recognizedJudicialActType })
			.then(result => {
				if (isBlank(result.error)) {
					$processing = false;
					$autosaveStatus = 'saved';
					$transmitter = { ...$transmitter, ...result };
				} else {
					$processing = false;
					$autosaveStatus = 'not_saved';
					console.log('error: Сервис распознавания временно не доступен по причине: ' + JSON.stringify(result.error));
					errors = `Сервис распознавания временно не доступен по причине: ${result.error}`;
				}
			})
			.catch(error => {
				$processing = false;
				console.log('error: Сервис распознавания временно не доступен по причине: ' + JSON.stringify(error));
				errors = `Сервис распознавания временно не доступен по причине: ${error.message}`;
			});
	};
</script>

{#each judicial_act_types as type}
	<div class={`m-b-sm overlay ocr-block__${type}`}>
		<h4>
			<span
				class="m-r-sm"
				class:hoverable={procedureId && $procedureAvailable}
				class:text-disabled={!procedureId || !$procedureAvailable}
				title={show[type] ? 'скрыть' : 'показать'}
				role="button"
				tabindex="0"
				on:click={procedureId && $procedureAvailable && (() => (show[type] = !show[type]))}
				on:keypress|stopPropagation
			>
				<iconify-icon icon={show[type] ? 'fa-solid:chevron-down m-r-xs' : 'fa-solid:chevron-right m-r-sm'} />
				{`Судебный акт о введении процедуры (${judicial_act_editions[type]})`}
				{#if isPresent(procedure) && isPresent(procedure[`judicial_act_${type}`])}
					<IconPaperclip />
				{/if}
			</span>
		</h4>
		<ErrorMessage message={recognizedJudicialActType === type ? errors : []} on:click={() => (errors = [])} />
		<div class="collapse" class:show={show[type]}>
			<OCRDashbord {type} on:deleteJudicialAct={() => (deletedJudicialActType = type)} on:recognizeJudicialAct={() => (recognizedJudicialActType = type)} />
			{#if isPresent(procedure[`judicial_act_${type}`])}
				<div class="flex justify-content-center height__full border__solid-grey m-t-sm">
					<div class="scrollable h-100">
						{#each procedure[`judicial_act_${type}`] as judicial_act, idx}
							<div class:mb-2={idx !== procedure[`judicial_act_${type}`].length - 1}>
								<img id={`imgId-${idx}`} class="d-block w-100" src={judicial_act} alt="Скан судебного акта" />
							</div>
						{/each}
					</div>
				</div>
			{/if}
		</div>
	</div>
{/each}
<Modal modalId="delete-judicial-act-modal" submitButtonText="Удалить" submitButtonCss="btn-danger" submitButtonAction={deleteJudicialAct}>
	<h3 slot="header">Удалить прикрепленный файл?</h3>
</Modal>
<Modal modalId="recognize-judicial-act-modal" submitButtonText="Автозаполнить" submitButtonAction={recognizeJudicialAct}>
	<h3 slot="header" class="m-r-md">
		Подтвердите автозаполнение полей<br />на стр.Процедура<br />(даты судебных актов, ФИО судьи, данные должника, судебное заседание по результатам)
	</h3>
</Modal>

<style>
	.border__solid-grey {
		border: solid 1px #bebebe;
	}

	.height__full {
		height: calc(100vh - 130px);
	}

	.scrollable {
		overflow: auto;
	}

	.ocr-block__full_text {
		margin-bottom: 60px;
	}
</style>
