<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import BankAccountForm from './_form.svelte';
	import { cardsTitle } from 'utils/formats';

	export let bankAccountId;
	export let modalId;
	export let withCreateNewCounterparty;
	export let disabled = false;
	export let index = 0;

	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};

	let noBankSelected = true;
</script>

<Modal
	{modalId}
	{index}
	deleteIconDisabled={!bankAccountId || disabled}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	submitButtonDisabled={noBankSelected || disabled}
	deleteConfirmMessage="Вы действительно хотите удалить счет?"
	form={true}
	size="large"
>
	<h2 slot="header" class="bank_account-modal__header">{cardsTitle('счёта', bankAccountId)}</h2>
	<div slot="body">
		<BankAccountForm
			{modalId}
			{disabled}
			{withCreateNewCounterparty}
			bind:handleSubmit
			bind:handleCancel
			bind:handleDelete
			bind:bankAccountId
			bind:noBankSelected
			on:create
			on:update
		/>
	</div>
</Modal>
