<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { autosaveStatus, transmitter } from 'base_stores';
	import { fetchPost, fetchPut } from 'utils/fetch';

	export let attribute;
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let value = undefined;
	export let placeholder;
	export let required = false;
	export let disabled = false;
	export let additionalParams = {};
	export let label;
	export let saveAutomatically = true;
	export let orientation = 'horizontal';
	export let rows = 2;
	export let forceSave = false;

	let previousValue = null;
	let inputField;
	let countdown = 1;

	const dispatch = createEventDispatcher();

	$: method = !!id ? fetchPut : fetchPost;
	$: if (!!id) {
		url = `${url}/${id}`;
	}
	$: isSuccess = !!value;
	$: attrId = `textarea-${model}-${attribute}`; //-${id || 0}`

	$: if (!previousValue && countdown > 0 && value === undefined) {
		countdown = countdown - 1;
	}

	$: if (!previousValue && countdown === 0 && value !== undefined) {
		previousValue = value;
		countdown = countdown - 1;
	}

	$: if (forceSave) {
		handleChange();
	}

	const handleChange = () => {
		$autosaveStatus = null;

		if (previousValue !== value) {
			let params = { [model]: { [attribute]: value }, [`${childModel}_id`]: childId, ...additionalParams };

			method(url, params)
				.then(response => {
					if (!forceSave && JSON.stringify(response) !== '{}') {
						$autosaveStatus = 'saved';
					}
					$transmitter = { ...$transmitter, ...response };
					previousValue = value;
					if ($transmitter.not_saved) {
						$autosaveStatus = 'not_saved';
					}
					forceSave = false;
					dispatch('update', response);
				})
				.catch(_errors => {
					$autosaveStatus = 'not_saved';
				});
		}
	};
</script>

<div class="form-group row" class:has-success={isSuccess}>
	<label
		for={attrId}
		class="col-form-label"
		class:col-sm-4={orientation === 'horizontal'}
		class:col-lg-3={orientation === 'horizontal'}
		class:col-xl-4={orientation === 'horizontal'}
		class:col-sm-12={orientation === 'vertical'}
		class:text-disabled={disabled}
	>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
		{#if $$slots.icon}<slot name="icon"></slot>{/if}
	</label>
	<div
		class:col-sm-8={orientation === 'horizontal'}
		class:col-lg-9={orientation === 'horizontal'}
		class:col-xl-8={orientation === 'horizontal'}
		class:col-sm-12={orientation === 'vertical'}
	>
		<textarea
			id={attrId}
			class:has-success={isSuccess}
			class:required-border={required}
			class:text-disabled={disabled}
			class="form-control"
			{rows}
			{placeholder}
			bind:value
			bind:this={inputField}
			{disabled}
			on:blur={saveAutomatically && handleChange}
			autocomplete="off"
			autocorrect="off"
		/>
	</div>
</div>
