<!-- @format -->
<script>
	import SvelteTooltip from 'svelte-tooltip';
	import { bankruptIsPerson, bankruptIsOrganization } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import moment from 'moment';
	import { formatSum } from 'utils/tools';

	export let workers;
	export let openModalForm;
	export let workerField;
	export let arrearsToWorker;

	const formatDate = date => {
		return date ? moment(date).format('DD.MM.YYYY') : '';
	};
</script>

{#if workers.length > 0}
	{#each workers as worker}
		<tr on:click={(($bankruptIsPerson && $procedureAvailable) || $bankruptIsOrganization) && (() => openModalForm(worker.id))}>
			<td>
				{#if !worker.can_be_destroyed}
					<SvelteTooltip tip="Невозможно удалить запись" right>{worker.name || ''}</SvelteTooltip>
				{:else}
					{worker.name || ''}
				{/if}
			</td>
			<td>{workerField(worker, 'position')}</td>
			<td>{formatDate(workerField(worker, 'dismissal_order_date'))}</td>
			<td class="text-right">{arrearsToWorker(worker) > 0 ? formatSum(arrearsToWorker(worker)) : ''}</td>
		</tr>
	{/each}
{:else}
	<tr class="text-secondary">
		<td colspan="4">Нет данных</td>
	</tr>
{/if}
