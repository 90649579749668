<!-- @format -->
<script>
	import { transmitter, bankruptIsPerson, options } from 'base_stores';
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch';
	import { hideModal } from '~/svelte/components/modal.svelte';
	import Select2 from '~/svelte/components/ui/select2.svelte';
	import Textarea from '~/svelte/components/ui/textarea.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';

	export let measureId;
	export let noMeasureSelected = true;
	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;
	export let disabled = false;

	const judicialActOptions = [
		{ id: 'Определение', text: 'Определение' },
		{ id: 'Решение', text: 'Решение' },
		{ id: 'Постановление', text: 'Постановление' },
	];

	const nullMeasure = {
		measure_kind_id: null,
		name: null,
		result: null,
		judicial_act: null,
		judicial_act_date: null,
		court_id: null,
	};

	let measure = { ...nullMeasure };

	$: method = !!measureId ? fetchPut : fetchPost;
	$: data = { ...measure };
	$: noMeasureSelected = !measure.measure_kind_id;

	$: if (measureId && measureId !== measure.id) {
		fetchGet(`/api/private/measures/${measureId}`).then(response => (measure = response.measure));
	}

	$: if (!measure) {
		measure = { ...nullMeasure };
	}
	$: if ($options.measureKind.length == 1) {
		measure.measure_kind_id = options[0].id;
	}

	$: formFields = {
		measureKind: {
			required: true,
			withSearch: false,
			model: 'measure',
			attribute: 'measure_kind_id',
			label: 'Вид меры',
			placeholder: 'Выбрать вид',
			options: $options.measureKind,
			disabled,
		},
		measureName: {
			model: 'measure',
			attribute: 'name',
			label: 'Реализованная мера',
			placeholder: 'Содержание меры',
			disabled,
		},
		measureReasult: {
			model: 'measure',
			attribute: 'result',
			label: 'Результат рассмотрения',
			placeholder: 'Содержание результата рассмотрения',
			disabled,
		},
		judicialAct: {
			withSearch: false,
			model: 'measure',
			attribute: 'judicial_act',
			label: 'Судебный акт по результату рассмотрения',
			placeholder: 'Выбрать акт',
			options: judicialActOptions,
			disabled,
		},
		judicialActDate: {
			model: 'measure',
			attribute: 'judicial_act_date',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			label: 'Дата судебного акта',
			disabled,
			expansionInFocus: true,
		},
		courtId: {
			model: 'measure',
			label: 'Суд',
			placeholder: 'Искать или выбрать суд',
			options: $options.court,
			disabled,
		},
	};

	handleSubmit = () => {
		return method(`/api/private/measures${measureId ? `/${measureId}` : ''}`, data).then(response => {
			$transmitter = { ...$transmitter, measures: response.measures };
			hideModal('form-measure-modal');
		});
		//.catch(errors => console.log('errors: ' + JSON.stringify(errors)))
	};

	handleDelete = () => {
		return fetchDelete(`/api/private/measures/${measureId}`).then(response => ($transmitter = { ...$transmitter, measures: response.measures }));
		//.catch(errors => console.log('errors: ' + JSON.stringify(errors)))
	};

	handleCancel = () => {
		measure = { ...nullMeasure };
	};
</script>

<Select2 {...formFields.measureKind} bind:value={measure.measure_kind_id} />
{#if measure.measure_kind_id}
	<Textarea {...formFields.measureName} bind:value={measure.name} />
	<Textarea {...formFields.measureReasult} bind:value={measure.result} />
	{#if $bankruptIsPerson}
		<Select2 {...formFields.judicialAct} bind:value={measure.judicial_act} />
		<Datepicker {...formFields.judicialActDate} bind:value={measure.judicial_act_date} />
		<Select2 {...formFields.courtId} bind:value={measure.court_id} />
	{/if}
{/if}
