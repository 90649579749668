<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { options } from 'base_stores';
	import { toArray } from 'utils/tools';
	import { showModal } from '~/svelte/components/modal.svelte';
	import SortableList from '~/svelte/_shared/svelte-sortable-list.svelte';
	import TemplateFieldsModal from './modal.svelte';

	const dispatch = createEventDispatcher();

	export let currentFields = [];

	$: allFields = ($options.groupedFormFields || []).map(o => JSON.parse(o.fields)).flat();

	const sortList = e => {
		currentFields = e.detail.map(d => d.code);
		dispatch('submit', currentFields);
	};

	const handleSubmit = fields => {
		dispatch('submit', [...toArray(currentFields).filter(f => fields.includes(f)), ...fields.filter(f => !currentFields.includes(f))]);
	};

	const openModalForm = () => showModal('form-template-fields-modal');
</script>

<div class="row m-t-n">
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs m-t-md" on:click={openModalForm}> Выбрать поля </button>
		<SortableList
			list={toArray(currentFields)
				.map(f => allFields.find(i => i.code == f))
				.filter(i => i)}
			key="id"
			on:sort={sortList}
			let:item
			let:index
		>
			{@const fieldUsed = $options.usedFields.includes(item.code)}
			<div style="display: flex; justify-content: space-between;" data-index={index}>
				<div>{item.name}</div>
				<div style="display: flex; justify-content: space-between; width: 7.5rem;">
					{#if !item.metadata.auxiliary}
						{#if fieldUsed}
							<div class="float-right" style="color: #1ab394;">используется</div>
						{:else}
							<div class="float-right" style="color: #cf1d0c;">не используется</div>
						{/if}
					{:else}
						<div></div>
					{/if}
					<iconify-icon icon="fa:exchange" class="sort-anchor" height="12" rotate="90deg" style="padding-top: 0.25em; color: #999;" />
				</div>
			</div>
		</SortableList>
	</div>
</div>

<TemplateFieldsModal {currentFields} on:submit={({ detail: fields }) => handleSubmit(fields)} />

<style lang="scss">
	.sort-anchor:active {
		cursor: grabbing;
	}
</style>
