<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import Header from '../components/header.svelte';
	import MasterDataFields from './_master_data_fields.svelte';
	import SroFields from './_sro_fields.svelte';
	import InsuranceFields from './_insurance_fields.svelte';
	import SignatureFields from './_signature_fields.svelte';
	import AccountFedresurs from './_account_fedresurs.svelte';
	import { showModal } from '~/svelte/components/modal.svelte';
	import ConfirmDeleteModal from '~/svelte/components/confirm_delete_modal.svelte';
	import { fetchDelete } from 'utils/fetch';
	import OCRblock from './ocr_block.svelte';

	$: arbitrManager = $transmitter?.arbitr_manager || arbitrManager;
	$: arbitrManagerId = (arbitrManager && arbitrManager.id) || null;

	$: if (!!arbitrManagerId && location.pathname.match(/^\/arbitr_managers\/new/)) {
		location.href = `/arbitr_managers/${arbitrManagerId}`;
	}

	const deleteArbitrManager = async () => {
		await fetchDelete(`/api/private/arbitr_managers/${arbitrManagerId}`).then(() => (location.href = '/procedures'));
	};
</script>

<Header>
	<span slot="name">Арбитражный управляющий</span>
</Header>
<div class="col-sm-5 col-lg-8 col-xl-5 float-left px-0">
	<MasterDataFields />
	<SroFields />
	<InsuranceFields />
	<SignatureFields />
	<AccountFedresurs />
	<div class="m-b-xxl overlay footer-margin" style="margin-top: -40px">
		<button class="btn btn-xs m-r-sm btn-danger" disabled={!$transmitter.arbitr_manager.can_be_destroyed} on:click={() => showModal('arbitr-delete-modal')}>
			Удалить АУ
		</button>
	</div>
</div>
<div class="col-12 col-md-6 col-xl-7 px-0 px-sm-3 mb-5 mb-sm-0 float-right procedure__ocr-block">
	<OCRblock />
</div>
<ConfirmDeleteModal deleteAction={deleteArbitrManager} />
