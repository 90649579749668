<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;

	$: arbitrManagerRegNumber = {
		...$commonParams,
		attribute: 'arbitr_manager_reg_number',
		label: 'Регистрационный номер АУ',
		value: $currentData && $currentData.arbitrManagerRegNumber,
		placeholder: '12345',
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_reg_number',
			resource: arbitrManager,
			resourceProp: 'reg_number',
		},
	};
</script>

<AutosaveInput {...arbitrManagerRegNumber} on:update />
