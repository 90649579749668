<!-- @format -->
<!-- @format -->
<script>
	import { procedure } from 'base_stores';
	import { isTrialPeriod, procedureRequiresPayment } from '~/svelte/components/payment/stores';
	import moment from 'moment';
</script>

{#if $isTrialPeriod && $procedureRequiresPayment}
	<div class="trial-period float-left m-r-xs">
		{`(пробный период до ${moment($procedure?.trial_period_expired_at).format('DD.MM.YYYY')})`}
	</div>
{/if}

<style>
	.trial-period {
		margin-top: 0.35rem;
		font-size: 0.7em;
		color: #d68a37;
	}
</style>
