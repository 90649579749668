/** @format */

import { initializeData } from './initialize_data';
import Router from '~/svelte/router.svelte';

document.addEventListener('DOMContentLoaded', async () => {
	const targets = document.querySelectorAll(
		[
			'#answer',
			'#answers',
			'#arbitr_manager',
			'#bank_accounts',
			'#calendar',
			'#complaints',
			'#counterparties',
			'#creditors_requirements',
			'#receivables',
			'#documents_outgoing',
			'#documents_reports',
			'#documents_financial_analysis',
			'#edit_documents',
			'#running_costs',
			'#payouts',
			'#settings',
			'#outgoing_documents',
			'#document',
			'#measures',
			'#meetings',
			'#creditor_meeting_edit',
			'#worker_meeting_edit',
			'#possessions',
			'#procedure',
			'#procedures',
			'#report',
			'#reports',
			'#specialists',
			'#workers',
			'#mail_registry',
			'#fee_tariffs',
			'#template',
			'#tiny_mce',
		].join(', '),
	);

	if (!targets.length) {
		return;
	}

	const target = targets[0];

	const data = await initializeData(target.dataset);
	window.app = new Router({ target, props: { elementId: target.id, data } });
});
