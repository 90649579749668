<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;
	$: localCommonParams = { ...$commonParams, childModel: 'arbitr_manager', childId: arbitrManager.id };

	$: sroRegNumber = {
		...localCommonParams,
		attribute: 'sro_reg_number',
		label: 'Регистрационный номер СРО',
		value: $currentData && $currentData.sroRegNumber,
		placeholder: '12345',
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.sro_reg_number',
			resource: arbitrManager && arbitrManager.sro,
			resourceProp: 'reg_number_sro',
		},
	};
</script>

<AutosaveInput {...sroRegNumber} on:update />
