<!-- @format -->
<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dct } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { formatDate } from '~/js/utils/tools';
	import moment from 'moment';

	export let fieldId;

	let indexCollapse = false;
	let date;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: meetings = ($transmitter && $transmitter.meetings && $transmitter.meetings.creditors) || [];

	const loadMeetings = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/meetings/get_report', {
				procedure_id: $procedure.id,
				date: $dct.date,
				kind: 'creditor',
			});
			$transmitter = { ...$transmitter, meetings: { ...$transmitter.meetings, creditors: response.rows } };
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: if (!moment(date).isSame(moment($dct.date)) && !fetching) {
		date = $dct.date;
		loadMeetings();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="meetings col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">
			Проведенные собрания кредиторов ({meetings.length})
		</span>
	</h4>
</div>
<div id="collapse-meetings" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<div class="p-1 m-l-n-md">
			{#if meetings.length}
				<ul>
					{#each meetings as meeting}
						<li>
							<a href={'/meetings/' + meeting.id + '/edit'} target="_blank">
								Собрание от {formatDate(meeting.date_at)}
								{meeting.first_meeting ? '(первое)' : ''}
							</a>
						</li>
					{/each}
				</ul>
			{/if}
		</div>
	</div>
</div>

<style>
	.header {
		cursor: pointer;
	}
</style>
