<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;

	$: arbitrManagerInsurancePolicyFinishDate = {
		...$commonParams,
		attribute: 'arbitr_manager_insurance_policy_finish_date',
		label: 'Дата окончания действия полиса',
		value: $currentData && $currentData.arbitrManagerInsurancePolicyFinishDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_insurance_policy_finish_date',
			resource: $procedure && $procedure.arbitr_manager,
			resourceProp: 'insurance_policy_finish_date',
		},
	};
</script>

<AutosaveDatepicker {...arbitrManagerInsurancePolicyFinishDate} on:update />
