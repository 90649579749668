<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { currentData } from '../../stores/stores';
	import { transmitter } from '~/js/global_stores/base';
	import { toArray, formatSum } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	// readonly
	const handleChange = e => {};
	// const handleChange = e => dispatch('change', {
	//       model: 'meeting_participant',
	//          id: $currentData.meetingParticipantId,
	//   attribute: 'inn',
	//       value: e.target.value
	// });
	let options = [];
	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		options = toArray($transmitter.meeting.forms);
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingForm');
		disabled = toArray($transmitter.meeting.disabled_fields).includes('meetingForm');
	}
</script>

{#if visible}
	<Field value={$currentData.meetingForm} name="meetingForm" label="Форма собрания" {disabled}>
		{#each options as option}
			<label class="d-flex">
				<input
					type="radio"
					name="meetingForm"
					bind:group={$currentData.meetingForm}
					value={option.value}
					{disabled}
					on:change={handleChange}
					class="align-self-center m-r-xs"
				/>
				{option.label}
			</label>
		{/each}
	</Field>
{/if}
