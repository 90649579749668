<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDadateAddress from '~/svelte/components/ui/autosave_dadate_input_address.svelte';

	$: isRegistrar = $currentData && $currentData.registrarChoice === 'registrar';

	$: registrarMailingAddress = {
		...$commonParams,
		attribute: 'registrar_mailing_address',
		label: 'Адрес реестродержателя',
		value: $currentData.registrarMailingAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д.1',
	};
</script>

{#if isRegistrar}
	<AutosaveDadateAddress {...registrarMailingAddress} />
{/if}
