<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: data = {
		...$commonParams,
		disabled: $commonParams.disabled || true,
		attribute: 'time_at',
		label: 'Время начала собрания',
		value: $currentData.meetingTimeAt,
		placeholder: '10:00',
		hintData: {
			freeMessage: 'AI заполнил из Параметры',
		},
	};
</script>

<AutosaveInput {...data} />
