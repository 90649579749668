<!-- @format -->
<script>
	import { currentData, commonParams } from '../../../../stores/stores';
	import AutosaveDatepicker from '../../../../../components/ui/autosave_datepicker.svelte';
	import moment from 'moment';

	$: policeDate = $currentData && $currentData.procedureInsurancePolicyDate;
	$: policyfinishDate = $currentData && $currentData.procedureInsurancePolicyFinishDate;

	$: procedureInsurancePolicyFinishDate = {
		...$commonParams,
		attribute: 'procedure_insurance_policy_finish_date',
		label: 'Дата окончания действия полиса по доп. страховке АУ',
		value: $currentData && $currentData.procedureInsurancePolicyFinishDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: policeDate
			? policyfinishDate === moment(policeDate).add(6, 'months').subtract(1, 'days').format('YYYY-MM-DD')
				? { freeMessage: 'AI заполнил на основе Дата полиса + 6 мес. - 1 день' }
				: { freeMessage: 'Изменено пользователем' }
			: {},
	};
</script>

<AutosaveDatepicker {...procedureInsurancePolicyFinishDate} />
