<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';

	export let deleteAction = () => {};
	export const headerText = 'Подтвердите удаление';
	export const bodyText = 'Действие нельзя отменить';
	export const modalSize = 'small';
</script>

<Modal modalId="arbitr-delete-modal" size={modalSize} submitButtonText="Удалить" submitButtonCss="btn-danger" submitButtonAction={deleteAction}>
	<h3 slot="header" class="m-r-md">
		{headerText}
	</h3>
	<h4 slot="body" class="m-r-md" style="text-align: center">
		{bodyText}
	</h4>
</Modal>
