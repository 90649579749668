<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInputCurrency from '../../components/ui/autosave_input_currency.svelte';

	$: debtToCreditorsFromStatementOfDebtor = {
		...$commonParams,
		attribute: 'debt_to_creditors_from_statement_of_debtor',
		label: 'Задолженность перед кредиторами из заявления должника (руб.)',
		value: $currentData && $currentData.debtToCreditorsFromStatementOfDebtor,
		placeholder: '0,00',
	};
</script>

{#if $currentData && !$currentData.procedureDeclarer}
	<AutosaveInputCurrency {...debtToCreditorsFromStatementOfDebtor} />
{/if}
