<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: creditorsFirstMeetingVotingParticipantsNumber = {
		...$commonParams,
		attribute: 'creditors_first_meeting_voting_participants_number',
		label: 'Количество участников 1-го собрания кредиторов с правом голоса',
		value: $currentData && $currentData.creditorsFirstMeetingVotingParticipantsNumber,
		placeholder: '5',
	};
</script>

<AutosaveInput {...creditorsFirstMeetingVotingParticipantsNumber} />
