<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: bankruptLeaderRequestReceived = $currentData && $currentData.bankruptLeaderRequestReceived;

	$: bankruptLeaderRequestReceivedDate = {
		...$commonParams,
		attribute: 'bankrupt_leader_request_received_date',
		label: 'Дата получения запроса руководителем должника',
		value: $currentData.bankruptLeaderRequestReceivedDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
	};
</script>

{#if bankruptLeaderRequestReceived}
	<AutosaveDatepicker {...bankruptLeaderRequestReceivedDate} />
{/if}
