<!-- @format -->
<script>
	import { procedure, options, newObject, fieldsReady } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';
	import { fetchGet, fetchPost } from 'utils/fetch';

	export let fieldId;

	let forceSave = true;

	$: $fieldsReady[fieldId] = !forceSave;

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;
	$: currentSroId = $currentData && $currentData.sroId;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('sroId');

	$: if ($dct.form_fields.includes('sroId')) {
		$requiredFieldsFilled.sroId = !!sroId.value;
	}

	const addSroOption = async text => {
		const params = { text };
		const response = await fetchPost('/api/private/sros/create_and_return_option', params);
		const option = response.option;

		$options.sro = [...$options.sro, { id: option.id, text: option.text }];

		return { id: option.id, text: option.text };
	};

	const loadSroOptions = async () => {
		const response = await fetchGet('/api/private/sros/get_options');
		$options.sro = response.options;
	};

	$: if (!$options.sro && currentSroId) {
		loadSroOptions();
	} else {
		forceSave = false;
	}

	$: sroId = {
		...$commonParams,
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hidden: !$currentData.insertFirstParagraph,
		required: true,
		attribute: 'sro_id',
		label: 'СРО АУ',
		value: $options.sro && $options.sro.find(option => parseInt(option.id) === parseInt(currentSroId)),
		options: $options.sro,
		placeholder: 'Искать или выбрать СРО',
		addOption: addSroOption,
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.sro_id',
			resource: arbitrManager && arbitrManager.sro,
			resourceProp: 'id',
		},
	};
</script>

{#if !sroId.hidden}
	<AutosaveSelect2Search {...sroId} on:update />
{/if}
