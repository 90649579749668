<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../stores/stores';
	import AutosaveInput from '../../components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: courtroom = {
		...$commonParams,
		attribute: 'courtroom',
		label: 'Зал заседания по результатам процедуры',
		value: $currentData.courtroom,
		placeholder: '00000000000',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.courtroom',
			resource: $procedure,
			resourceProp: 'courtroom',
		},
	};
</script>

<AutosaveInput {...courtroom} />
