<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { procedure, transmitter } from 'base_stores';
	import RequirementsList from './requirements_list.svelte';

	export let answer;

	onMount(() => {
		$transmitter.creditors_requirements = answer.creditors_requirements;
	});
</script>

{#if ['debt_restructuring', 'property_realization'].includes($procedure.phase)}
	<RequirementsList {answer} title="Существующие входящие требования" isDraft={false} requirementState="approved" />
	<RequirementsList {answer} title="Распознанные входящие требования (черновики)" isDraft={true} requirementState="draft" />
{:else}
	<RequirementsList {answer} title="Входящие требования" isDraft={false} />
{/if}
