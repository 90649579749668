<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveInputCurrency from '../../../components/ui/autosave_input_currency.svelte';

	$: hasAdditionalExpenses = $currentData && $currentData.bankruptHasAdditionalExpenses;

	$: monthlyExpensesOfDebtorOther = {
		...$commonParams,
		attribute: 'monthly_expenses_of_debtor_other',
		label: 'Ежемесячные прочие расходы должника (руб./мес.)',
		value: $currentData && $currentData.monthlyExpensesOfDebtorOther,
		placeholder: '0,00',
	};
</script>

{#if hasAdditionalExpenses}
	<AutosaveInputCurrency {...monthlyExpensesOfDebtorOther} />
{/if}
