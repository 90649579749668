<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: procedureDuration = {
		...$commonParams,
		attribute: 'procedure_duration',
		label: 'Длительность процедуры, мес.',
		value: $currentData && $currentData.procedureDuration,
		hintData: {
			freeMessage: true ? 'AI заполнил на основе «Заседание по результатам процедуры» - «Дата введения процедуры»' : 'Изменено пользователем',
		},
	};
</script>

<AutosaveInput {...procedureDuration} />
