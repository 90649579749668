/** @format */

const patterns = {
	numericality: /^(-|\+)?\d+((,|\.)\d+)*$/,
	onlyInteger: /^(-|\+)?\d+$/,
};

const numericalityValidators = {
	onlyInteger: (cond, value, message = null) => {
		if (!cond) {
			return [];
		}
		if (!!value && !String(value).match(patterns.onlyInteger)) {
			return [message || 'неверный формат числа'];
		}
	},
	greater_than: (cond, value, message = null) => {
		if (typeof cond !== 'number') {
			return [];
		}
		if (!!value && !!String(value).match(patterns.numericality) && parseFloat(String(value).replace(/,/, '.')) <= parseFloat(cond)) {
			return [message || `должно быть больше ${cond}`];
		}
	},
	greater_than_or_equal_to: (cond, value, message = null) => {
		if (typeof cond !== 'number') {
			return [];
		}
		if (!!value && !!String(value).match(patterns.numericality) && parseFloat(String(value).replace(/,/, '.')) < parseFloat(cond)) {
			return [message || `должно быть больше или равно ${cond}`];
		}
	},
};

const validators = {
	presence: (cond, value, message = null) => {
		if (typeof cond !== 'boolean' || !cond) {
			return [];
		}
		return cond && !!value ? null : [message || 'не может быть пустым'];
	},
	numericality: (cond, value, message = null) => {
		if (typeof cond == 'boolean') {
			if (!cond) {
				return [];
			}
			if (!!value && !String(value).match(patterns.numericality)) {
				return [message || 'неверный формат числа'];
			}
		} else {
			return numericalityValidators;
		}
	},
};

export const validate = (value, conditions = {}, message = null, errors = [], validatorsByConditions = validators) => {
	const validatorKeys = Object.keys(validatorsByConditions);

	for (let conditionKey of Object.keys(conditions)) {
		if (Object.keys(conditions[conditionKey]).length) {
			const subErrors = validate(
				value,
				conditions[conditionKey],
				conditions[conditionKey].message,
				errors,
				validatorsByConditions[conditionKey](conditions[conditionKey], value, message),
			);
			errors = [...errors, ...subErrors];
		} else {
			if (validatorKeys.includes(conditionKey)) {
				const errorsByCond = validatorsByConditions[conditionKey](conditions[conditionKey], value, message);
				if (errorsByCond) {
					errors = [...errors, ...errorsByCond];
				}
			}
		}
	}

	return errors;
};
