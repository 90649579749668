<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { transmitter, autosaveStatus, processing } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import printJS from 'print-js';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';
	import DownloadIcon from '~/svelte/_shared/download_icon.svelte';
	import PrintIcon from '~/svelte/_shared/print_icon.svelte';
	import DeleteIcon from '~/svelte/_shared/delete_icon.svelte';
	import { fetchPut } from 'utils/fetch_helpers';
	import { addToFormData } from '~/svelte/components/ui/form/svelte-forms-lib';
	import { isPresent, isBlank } from 'utils/tools';
	import { showModal } from '~/svelte/components/modal.svelte';

	export let type;

	const dispatch = createEventDispatcher();

	let files;
	let showInfo = false;

	$: procedure = $transmitter && $transmitter.procedure;
	$: hasJudicialActRecognizedData = isPresent(procedure[`judicial_act_recognized_data`] && procedure[`judicial_act_recognized_data`][type]);

	$: info = () => {
		if (isPresent(procedure)) {
			if (isBlank(procedure[`judicial_act_${type}`])) {
				return 'Добавьте файл формата .pdf, .jpg или .jpeg размером не более 15Мб';
			} else if (
				isBlank(procedure.judicial_act_recognized_data) ||
				(isPresent(procedure.judicial_act_recognized_data) && isBlank(procedure.judicial_act_recognized_data[type]))
			) {
				return `Автозаполнит поля на стр.Процедура (даты судебных актов, ФИО судьи, данные должника, судебное заседание по результатам)`;
			}
		} else {
			return '';
		}
	};

	$: uploadFile(files);

	const uploadFile = files => {
		$autosaveStatus = null;

		if (isBlank(files)) {
			return;
		}

		const formData = new FormData();
		const act_edition = files[0].type === 'application/pdf' ? `judicial_act_${type}_pdf` : `judicial_act_${type}`;

		addToFormData(formData, 'procedure', { [act_edition]: files[0] });

		$processing = true;

		fetchPut(`/api/private/procedures/${procedure.id}`, formData)
			.then(result => {
				$processing = false;
				$autosaveStatus = 'saved';
				$transmitter = { ...$transmitter, ...result };
			})
			.catch(error => {
				$processing = false;
				$autosaveStatus = 'not_saved';
				throw error;
			});
	};
</script>

<div class="row">
	<div class="col-11 col-sm-5">
		<ul class="nav float-left m-t-xs">
			<li>
				{#if isPresent(procedure) && isBlank(procedure[`judicial_act_${type}`])}
					<label class="file-label">
						<input bind:files accept="image/png, image/jpeg, application/pdf" multiple="true" class="file-input" type="file" />
						<span class="btn btn-xs btn-warning m-r-sm cursor_pointer" disabled={$processing || !$procedureAvailable}>
							+ Судебный акт
							{#if $processing}
								<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
							{/if}
						</span>
					</label>
				{:else if isPresent(procedure) && isPresent(procedure[`judicial_act_${type}`])}
					<span
						class="btn btn-xs btn-primary m-r-sm"
						class:cursor_pointer={!hasJudicialActRecognizedData}
						class:recognized={hasJudicialActRecognizedData}
						role="button"
						tabindex="0"
						on:click={!$processing &&
							$procedureAvailable &&
							!hasJudicialActRecognizedData &&
							(() => {
								dispatch('recognizeJudicialAct', type);
								showModal('recognize-judicial-act-modal');
							})}
						disabled={$processing || !$procedureAvailable}
						on:keypress|stopPropagation
					>
						{!hasJudicialActRecognizedData ? 'Распознать' : 'Распознано'}
						{#if $processing}
							<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
						{/if}
					</span>
				{/if}
			</li>
			{#if isPresent(info())}
				<li>
					<div
						class="my-popover m-r-sm"
						role="button"
						tabindex="0"
						on:mouseover={() => (showInfo = true)}
						on:mouseout={() => (showInfo = false)}
						on:focus={() => (showInfo = true)}
						on:blur={() => (showInfo = false)}
					>
						<IconInfo />
					</div>
				</li>
			{/if}
			{#if showInfo}
				<div class="my-popover show fade">{info()}</div>
			{/if}
		</ul>
		{#if isPresent(procedure) && isPresent(procedure[`judicial_act_${type}`])}
			<ul class="nav float-right m-t-xs">
				<li class="mt-1">
					<a download target="_self" href={`${procedure.id}/download_judicial_act?edition=${type}`}>
						<DownloadIcon />
					</a>
				</li>
				<li class="ml-3 mt-1">
					<span
						class="cursor_pointer"
						role="button"
						tabindex="0"
						on:click={() =>
							printJS({
								printable: procedure[`judicial_act_${type}`],
								type: 'image',
								showModal: true,
								modalMessage: 'Подготовка документа...',
							})}
						on:keypress|stopPropagation
					>
						<PrintIcon />
					</span>
				</li>
				<li class="ml-3 mt-1">
					<span
						class="cursor_pointer"
						role="button"
						tabindex="0"
						on:click={() => {
							dispatch('deleteJudicialAct', type);
							showModal('delete-judicial-act-modal');
						}}
						on:keypress|stopPropagation
					>
						<DeleteIcon />
					</span>
				</li>
			</ul>
		{/if}
	</div>
</div>

<style lang="scss">
	.nav > li > a {
		padding: 0 !important;
		min-height: 0 !important;
	}
	.file-label {
		cursor: pointer;
		overflow: hidden;
		position: relative;
		margin-bottom: 0;
	}
	.file-input {
		height: 100%;
		left: 0;
		opacity: 0;
		outline: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.my-popover {
		margin-top: 2px;
		position: relative;
	}
	.show {
		position: absolute;
		z-index: 10010;
		background-color: #fff;
		border: solid 1px;
		border-radius: 0.3rem;
		box-shadow: 0 0 10px;
		display: block;
		padding: 0.7rem 1rem;
		top: 35px;
		width: 300px;
	}
	.recognized {
		color: #ddd;
		background-color: #1ab394;
		border-color: #1ab394;
	}
</style>
