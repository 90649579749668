<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import ProlongationForm from './form.svelte';
	import { isPresent, isBlank } from 'utils/tools';
	import { cardsTitle } from 'utils/formats';

	export let prolongationIndex;

	let submitButtonDisabled;
	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};
</script>

<Modal
	modalId="form-prolongation-modal"
	{submitButtonDisabled}
	deleteIconDisabled={isBlank(prolongationIndex)}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	form={true}
	size="medium"
>
	<h2 slot="header" class="prolongation-modal__header">{cardsTitle('продления', isPresent(prolongationIndex))}</h2>
	<div slot="body">
		<ProlongationForm {prolongationIndex} bind:handleSubmit bind:handleDelete bind:handleCancel bind:submitButtonDisabled on:submit on:delete />
	</div>
</Modal>
