/** @format */

import { get } from 'svelte/store';
import { bankruptType, options } from 'base_stores';
import Model from 'models/Model';
import { dctMetaRecordsApi } from 'tools/service_api';
import { isPresent } from 'utils/tools';

export default class DctMetaRecord extends Model {
	constructor(json) {
		super(json);
	}

	// class methods

	static subStoreName() {
		return 'dctMetaRecords';
	}

	static new(attributes) {
		return new this({
			name: 'Шаблон документа',
			bankrupt_type: get(bankruptType),
			procedure_phase: null,
			category: null,
			kind: null,
			correspondent_kind: null,
			form_fields: [],
			template: '',
			...(attributes || {}),
		});
	}

	static async create(params) {
		return isPresent(this.errors) ? { errors: this.errors } : await super.create(params);
	}

	static async update(params) {
		return isPresent(this.errors) ? { errors: this.errors } : await super.update(params);
	}

	static async loadCorrespondentKindOptions() {
		const response = await dctMetaRecordsApi.correspondentKindOptions();
		options.set({ ...get(options), correspondentKind: response.options });
	}

	static async addCorrespondentKindOptions(params) {
		const response = await dctMetaRecordsApi.addCorrespondentKindOptions(params);
		options.set({ ...get(options), correspondentKind: response.options });
	}
}
