<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dct, creditorsRequirements, dctFormAvailable } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { currencyFormat } from 'utils/formats';
	import CreditorsRequirementModal from '~/svelte/creditors_requirements/_modal.svelte';

	export let blockTitle = 'РТК';
	export let fieldId;

	let procedureId = null;
	let indexCollapse = false;
	let requirementsReestrClosingDate;
	let creditorRequirementModal;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: relevanceDate = $dct && $dct.date;
	$: procedureId = $procedure && $procedure.id;

	const openCreditorsRequirementModalForm = id => {
		id ? creditorRequirementModal.editItem(id) : creditorRequirementModal.newItem();
	};

	const creditorsRequirementText = requirement =>
		[`Требование № ${requirement.index} на сумму ${currencyFormat(requirement.amount)} руб. (${requirement.counterparty_name_inn})`].filter(i => i).join(' ');

	const loadCreditorsRequirements = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/creditors_requirements', { procedure_id: procedureId, date: relevanceDate, only_active: true });
			$transmitter = { ...$transmitter, creditors_requirements: response.creditors_requirements };
			requirementsReestrClosingDate = relevanceDate;
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: if (requirementsReestrClosingDate !== relevanceDate && !fetching) {
		loadCreditorsRequirements();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="creditors-requirements col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`${blockTitle} (${$creditorsRequirements.numberCreditorsRequirements})`}</span>
	</h4>
</div>
<div id="collapse-creditors-requirements" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && openCreditorsRequirementModalForm()}>
			+ Требование
		</button>
		<div class="p-1">
			{#if $creditorsRequirements.creditorsRequirementsInReestr}
				<ol class="block-list">
					<h5>{`Первая очередь (${$creditorsRequirements.firstStage.length})`}</h5>
					{#each $creditorsRequirements.firstStage as firstStageRequirement}
						<li>
							<div
								class="float-left simulated-link"
								role="button"
								tabindex="0"
								on:click={openCreditorsRequirementModalForm(firstStageRequirement.id)}
								on:keypress|stopPropagation
							>
								{creditorsRequirementText(firstStageRequirement)}
							</div>
						</li>
					{/each}
					<h5>{`Вторая очередь (${$creditorsRequirements.secondStage.length})`}</h5>
					{#each $creditorsRequirements.secondStage as secondStageRequirement}
						<li>
							<div
								class="float-left simulated-link"
								role="button"
								tabindex="0"
								on:click={openCreditorsRequirementModalForm(secondStageRequirement.id)}
								on:keypress|stopPropagation
							>
								{creditorsRequirementText(secondStageRequirement)}
							</div>
						</li>
					{/each}
					<h5>{`Третья очередь (${$creditorsRequirements.thirdStage.length})`}</h5>
					<h5>{`Третья очередь: требования, обеспеченные залогом (${$creditorsRequirements.thirdStageSecuredByPledge.length})`}</h5>
					{#each $creditorsRequirements.thirdStageSecuredByPledge as thirdStageSecuredByPledgeRequirement}
						<li>
							<div
								class="float-left simulated-link"
								role="button"
								tabindex="0"
								on:click={openCreditorsRequirementModalForm(thirdStageSecuredByPledgeRequirement.id)}
								on:keypress|stopPropagation
							>
								{creditorsRequirementText(thirdStageSecuredByPledgeRequirement)}
							</div>
						</li>
					{/each}
					<h5>{`Третья очередь: требования, не обеспеченные залогом (${$creditorsRequirements.thirdStageNotSecuredByPledge.length})`}</h5>
					{#each $creditorsRequirements.thirdStageNotSecuredByPledge as thirdStageNotSecuredByPledgeRequirement}
						<li>
							<div
								class="float-left simulated-link"
								role="button"
								tabindex="0"
								on:click={openCreditorsRequirementModalForm(thirdStageNotSecuredByPledgeRequirement.id)}
								on:keypress|stopPropagation
							>
								{creditorsRequirementText(thirdStageNotSecuredByPledgeRequirement)}
							</div>
						</li>
					{/each}
					<h5>{`Третья очередь: мораторные проценты (${$creditorsRequirements.thirdStagePercents.length})`}</h5>
					<h5>{`Третья очередь: штрафы, пени (${$creditorsRequirements.thirdStageFines.length})`}</h5>
					{#each $creditorsRequirements.thirdStageFines as thirdStageFinesRequirement}
						<li>
							<div
								class="float-left simulated-link"
								role="button"
								tabindex="0"
								on:click={openCreditorsRequirementModalForm(thirdStageFinesRequirement.id)}
								on:keypress|stopPropagation
							>
								{creditorsRequirementText(thirdStageFinesRequirement)}
							</div>
						</li>
					{/each}
					<h5>{`Требования, учитываемые за реестром (${$creditorsRequirements.creditorsRequirementsOffReestr.length})`}</h5>
					{#each $creditorsRequirements.creditorsRequirementsOffReestr as offReestrRequirement}
						<li>
							<div
								class="float-left simulated-link"
								role="button"
								tabindex="0"
								on:click={openCreditorsRequirementModalForm(offReestrRequirement.id)}
								on:keypress|stopPropagation
							>
								{creditorsRequirementText(offReestrRequirement)}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>
<CreditorsRequirementModal disabled={!$dctFormAvailable} bind:this={creditorRequirementModal} />

<style>
	.block-list h5 {
		margin-left: -1.1rem;
	}
</style>
