<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveTextarea from '~/svelte/components/ui/autosave_textarea.svelte';

	$: financialAnalysisResults = {
		...$commonParams,
		attribute: 'financial_analysis_results',
		label: 'Результаты финанализа',
		value: $currentData && $currentData.financialAnalysisResults,
		placeholder: 'Описание результатов финансового анализа должника',
	};
</script>

<AutosaveTextarea {...financialAnalysisResults} />
