<!-- @format -->
<!-- @format -->
<script>
	export let text = '';
	export let loading;
	export let action = () => {};
</script>

<button tabindex="0" disabled={loading} aria-labelledby="button-label" class="nsm7Bb-HzV7m-LgbsSe hJDwNd-SxQuSe i5vt6e-Ia7Qfc uaxL4e-RbRzK" on:click={action}>
	<div class="nsm7Bb-HzV7m-LgbsSe-MJoBVe"></div>
	<div class="nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb">
		<div class="nsm7Bb-HzV7m-LgbsSe-Bz112c">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="LgbsSe-Bz112c">
				<g>
					<path
						fill="#EA4335"
						d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
					></path>
					<path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
					></path>
					<path
						fill="#FBBC05"
						d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
					></path>
					<path
						fill="#34A853"
						d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
					></path>
					<path fill="none" d="M0 0h48v48H0z"></path>
				</g>
			</svg>
		</div>
		<span class="nsm7Bb-HzV7m-LgbsSe-BPrWId">{text}</span>
		<span class="L6cTce" id="button-label">{text}</span>
		{#if loading}
			<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
		{/if}
	</div>
</button>

<style lang="scss">
	.nsm7Bb-HzV7m-LgbsSe {
		border-radius: 4px;
		box-sizing: border-box;
		transition:
			background-color 0.218s,
			border-color 0.218s;
		background-color: #fff;
		background-image: none;
		border: 1px solid #dadce0;
		color: #3c4043;
		cursor: pointer;
		font-family: 'Google Sans', arial, sans-serif;
		font-size: 14px;
		height: 29.19px;
		letter-spacing: 0.25px;
		outline: none;
		overflow: hidden;
		padding: 0 8px;
		position: relative;
		text-align: center;
		vertical-align: middle;
		white-space: nowrap;
		width: auto;

		.nsm7Bb-HzV7m-LgbsSe-MJoBVe {
			transition: background-color 0.218s;
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}

		.nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: nowrap;
			height: 100%;
			position: relative;
			width: 100%;
		}

		.nsm7Bb-HzV7m-LgbsSe-Bz112c {
			height: 18px;
			margin-right: 8px;
			min-width: 18px;
			width: 18px;
		}

		.nsm7Bb-HzV7m-LgbsSe-BPrWId {
			flex-grow: 1;
			font-family: 'Google Sans', arial, sans-serif;
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
			vertical-align: top;
		}

		.L6cTce {
			display: none;
		}
	}
</style>
