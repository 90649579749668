<!-- @format -->
<script>
	import { autosaveStatus, transmitter as transmitter0 } from 'base_stores';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import { randomString } from 'utils/tools';
	import { createEventDispatcher } from 'svelte';

	export let attribute;
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let additionalAttribute;
	export let label;
	export let transmitter;
	export let value;
	export let required = false;
	export let disabled = false;
	export let options = [];
	export let forceSave = false;
	export let additionalParams = {};
	export let saveAutomatically = true;

	const dispatch = createEventDispatcher();

	let inputField;

	$: method = !!id ? fetchPut : fetchPost;
	$: if (!!id) {
		url = `${url}/${id}`;
	}
	$: newValue = (value && value.id) || null;

	$: if (forceSave) {
		handleChange();
	}

	const handleChange = () => {
		$autosaveStatus = null;

		let params = { [model]: { [attribute]: newValue }, [`${childModel}_id`]: childId, ...additionalParams };

		if (additionalAttribute) {
			params[model] = { ...params[model], [additionalAttribute.name]: additionalAttribute.value };
		}

		method(url, params)
			.then(response => {
				if (!forceSave && JSON.stringify(response) !== '{}') {
					$autosaveStatus = 'saved';
				}
				transmitter = { ...transmitter, ...response };
				$transmitter0 = { ...$transmitter0, ...response };
				forceSave = false;
				dispatch('update', response);
			})
			.catch(_errors => {
				inputField.focus();
				$autosaveStatus = 'not_saved';
			});
	};
</script>

<div class="form-group row">
	<label for={`radio-select-${model}-${attribute}`} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		{#each options || [] as option}
			<label class="d-flex">
				<input type="radio" {disabled} bind:group={value} on:change={saveAutomatically && handleChange} class="align-self-center m-r-xs" value={option} />
				{option.text}
			</label>
		{/each}
	</div>
</div>
