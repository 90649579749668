<!-- @format -->
<script>
	import { procedure, transmitter, bankruptIsPerson, bankruptIsOrganization, currentUserIsAdmin } from 'base_stores';
	import { onMount } from 'svelte';
	import Header from '../components/header.svelte';
	import RequiredDataFields from './_required_data_fields.svelte';
	import BankruptcyPetitionFields from './_bankruptcy_petition_fields.svelte';
	import DatesFields from './_dates_fields.svelte';
	import HearingFields from './_hearing_fields.svelte';
	import BankruptPersonFields from './_bankrupt_person_fields.svelte';
	import BankruptOrganizationFields from './_bankrupt_organization_fields.svelte';
	import PublicationsFields from './_publications_fields.svelte';
	import DataForDocumentsFields from './_data_for_documents_fields.svelte';
	import ProcedureCompletingFields from './_procedure_completing_fields.svelte';
	import InsuranceFields from './_insurance_fields.svelte';
	import Actions from './_actions.svelte';
	import OCRblock from './ocr_block.svelte';
	import InputDate from '~/svelte/components/ui/input_date.svelte';
	import Message from '~/svelte/components/message.svelte';
	import { fetchPost } from 'utils/fetch';
	import { formatDate } from '~/js/utils/date';

	let today = formatDate(new Date(), 'YYYY-MM-DD');
	let message;
	let messageMode;

	const createFee = () => {
		if (confirm('Создать карточку расхода "вознаграждение АУ"?')) {
			fetchPost(`/api/private/procedures/${$procedure.id}/create_fee?today=${formatDate(today, 'YYYY-MM-DD')}`).then(result =>
				alert(result.error || 'Карточка расхода создана'),
			);
		}
	};

	onMount(async () => {
		$transmitter = { ...$transmitter, procedure: $procedure };
	});
</script>

<Header><span slot="name">Процедура</span></Header>
{#if $currentUserIsAdmin && ['supervision', 'bankruptcy_proceedings'].includes($procedure.phase)}
	<div class="mb-2" style="display: flex; gap: 8px;">
		<button on:click={createFee}>Создать расход "вознаграждение АУ"</button>
		<InputDate name="today" bind:value={today} />
	</div>
{/if}
<div class="col-12 col-sm-7 float-sm-right">
	<Message {message} mode={messageMode} />
</div>
<div class="col-sm-5 col-lg-8 col-xl-5 px-0">
	<RequiredDataFields bind:message bind:messageMode />
</div>
<div class="col-sm-5 col-lg-8 col-xl-5 float-left px-0">
	<BankruptcyPetitionFields />
	<DatesFields />
	<HearingFields />
	{#if ($procedure && $procedure.phase !== 'debt_restructuring') || !$procedure}
		<ProcedureCompletingFields />
	{/if}
	{#if $bankruptIsPerson}
		<BankruptPersonFields />
	{:else if $bankruptIsOrganization}
		<BankruptOrganizationFields />
	{/if}
	<PublicationsFields />
	<DataForDocumentsFields />
	{#if $bankruptIsOrganization}
		<InsuranceFields />
	{/if}
	<Actions />
</div>
<div class="col-12 col-md-6 col-xl-7 px-0 px-sm-3 mb-5 mb-sm-0 float-right procedure__ocr-block">
	<OCRblock />
</div>
