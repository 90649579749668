<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '../stores/stores';
	import AutosaveDatepicker from '../../components/ui/autosave_datepicker.svelte';

	$: linkText = {
		fns: $procedure.phase === 'bankruptcy_proceedings' ? 'Дата принятия судом заявления' : 'Дата введения процедуры',
		bank: 'Дата принятия судом заявления',
		fss: 'Дата принятия судом заявления',
	};

	$: requestedInformationDateSource = {
		fns: $procedure.phase === 'bankruptcy_proceedings' ? $procedure.bankruptcy_petition_date : $procedure.resolution_introduction_date,
		bank: $procedure.bankruptcy_petition_date,
		fss: $procedure.bankruptcy_petition_date,
	};

	$: correspondent_kind = $dct && $dct.correspondent_kind;

	$: if ($dct.form_fields.includes('requestedInformationDate')) {
		$requiredFieldsFilled.requestedInformationDate = !!requestedInformationDate.value;
	}

	$: requestedInformationDate = {
		...$commonParams,
		required: true,
		attribute: 'requested_information_date',
		label: 'Дата, на которую запрашиваются сведения',
		value: $currentData.requestedInformationDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		//forceSave: !($dct && $dct.substitutions.requestedInformationDate),
		hintData:
			($procedure && requestedInformationDateSource[correspondent_kind]) === ($currentData && $currentData.requestedInformationDate)
				? {
						url: $procedure.phase === 'bankruptcy_proceedings' ? false : `/procedures/${$procedure.id}`,
						linkText: linkText[correspondent_kind],
						consumerNew: $newObject,
						resource: $procedure,
						resourceProp:
							correspondent_kind === 'fns' && $procedure.phase !== 'bankruptcy_proceedings' ? 'resolution_introduction_date' : 'bankruptcy_petition_date',
					}
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveDatepicker {...requestedInformationDate} />
