<!-- @format -->
<script>
	import DeleteIcon from '~/svelte/components/dashboard/delete_icon.svelte';
	import { isPresent } from 'utils/tools';
	import OCRDashbord from '~/svelte/_shared/_ocr_dashboard.svelte';
	import { transmitter } from 'base_stores';
	import { hideModal } from '~/svelte/components/modal.svelte';
	import { fetchPost } from 'utils/fetch_helpers';

	export let answer;
	export let withPossessionsBlocks;
	export let deleteIconDisabled = false;

	let resultObserver;
	let loading;

	$: confirmRecognizeMessage =
		answer.kind === 'requirement'
			? 'Подтвердите автосоздание черновиков Карточек входящих требований'
			: withPossessionsBlocks
				? 'Подтвердите автозаполнение поля «Содержание документа» и создание черновиков Карточек объектов'
				: 'Подтвердите автозаполнение поля входящего документа «Содержание документа»';

	const getRecognizeRequirementsResult = taskId => {
		resultObserver = setInterval(async () => {
			try {
				const response = await fetchPost(`/api/private/creditors_requirements/save_recognize_result`, { task_id: taskId, answer_id: answer.id });

				if (!response.error && response.state == 'success') {
					$transmitter.creditors_requirements = [...$transmitter.creditors_requirements, ...response.recognized_requirements];

					interruptRecognition();
				}
			} catch (error) {
				console.error(error);
				$transmitter = {
					...$transmitter,
					recognizeError: `В процессе распознавания произошла ошибка: ${error}. Попробуйте ещё раз, затем обратитесь к администратору.`,
				};
				interruptRecognition();
			}
		}, 2000);
	};

	$: if (!answer.id || $transmitter.loading) {
		deleteIconDisabled = true;
	} else {
		deleteIconDisabled = false;
	}

	const interruptRecognition = () => {
		loading = false;
		clearInterval(resultObserver);
		hideModal('continue-recognize-scan-modal');
	};

	const initRecognizeRequirementsScan = () => {
		$transmitter = { ...$transmitter, recognizeError: null };

		loading = true;
		fetchPost(`/api/private/creditors_requirements/init_recognize`, { answer: { id: answer.id } })
			.then(response => {
				getRecognizeRequirementsResult(response.task_id);
			})
			.catch(error => {
				loading = false;
				console.error('Сервис распознавания временно не доступен по причине: ' + error);
				$transmitter = {
					...$transmitter,
					recognizeError: `Сервис распознавания временно не доступен по причине: ${error}`,
				};
				interruptRecognition();
			});
	};
</script>

<div class="row reverse">
	<div class="col-2 col-sm-5 mt-2 pr-1">
		<ul class="nav flex justify-content-end">
			<li class="delete-icon">
				<DeleteIcon modalId="delete-answer-modal" bind:disabled={deleteIconDisabled} />
			</li>
		</ul>
	</div>
	<div class="col-10 col-sm-7 mt-2 p-l-ocr">
		<OCRDashbord
			scanName="Скан"
			isScanned={isPresent(answer && answer.scan)}
			isRecognized={false}
			scanMessage="Добавьте файл формата .pdf, .jpg или .jpeg размером не более 15Мб"
			recognizeMessage={answer.kind === 'requirement'
				? 'Автосоздаст черновики входящих требований'
				: 'Автозаполнит поле входящего документа «Содержание документа»'}
			{confirmRecognizeMessage}
			{resultObserver}
			{loading}
			autoRecognizeModalText={answer.kind === 'requirement' ? 'Автосоздать' : 'Автозаполнить'}
			initRecognizeScan={answer.kind === 'requirement' ? initRecognizeRequirementsScan : undefined}
			getRecognizeResult={answer.kind === 'requirement' ? getRecognizeRequirementsResult : undefined}
			modelName="answer"
			dctId={answer.id}
			withPossessions={withPossessionsBlocks}
			on:scanRecognized
		/>
	</div>
</div>

<style>
	.p-l-ocr {
		padding-left: calc(15px + 0.25rem);
	}

	.delete-icon {
		padding-top: 2px;
	}

	@media (max-width: 576px) {
		.reverse {
			flex-direction: row-reverse;
		}
	}
</style>
