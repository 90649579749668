<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import TemplateFieldsForm from './form.svelte';

	export let currentFields = [];

	let columns = 1;
	let handleSubmit = () => {};
</script>

<Modal
	modalId="form-template-fields-modal"
	submitButtonAction={handleSubmit}
	submitButtonText={'Выбрать'}
	size={columns == 1 ? 'medium-large' : columns == 2 ? 'large' : 'extra-large'}
>
	<h2 slot="header" class="template-fields-modal__header">Выбор полей шаблона</h2>
	<div slot="body">
		<TemplateFieldsForm {currentFields} bind:columns bind:handleSubmit on:submit />
	</div>
</Modal>
