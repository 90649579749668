<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { autosaveStatus, transmitter } from 'base_stores';
	import { isPresent } from 'utils/tools';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import { validate } from 'tools/validators';
	import { randomString } from 'utils/tools';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import SearchBy from '~/svelte/components/ui/dadata/search_by.svelte';

	export let attribute;
	//export let kind = 'address'
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let label;
	export let skipVerificationRequired = true;
	export let value = '';
	//export let withoutIndex = false // пока не удалять, указывает отдать адрес с индексом
	export let required = false;
	export let disabled = false;
	export let forceSave = false;
	export let placeholder;
	export let hintData;
	export let previousValue = null;
	export let additionalParams = {};
	export let saveAutomatically = true;

	let inputField;
	let dataErrors;
	//let suggestions = []

	const dispatch = createEventDispatcher();

	const inputId = `dadate-input-address-${model}-${attribute}`; //-${randomString()}`

	$: method = !!id ? fetchPut : fetchPost;
	$: if (!!id) {
		url = `${url}/${id}`;
	}
	$: isSuccess = value && !dataErrors;
	$: value = value || '';
	$: if (forceSave) handleChange();

	const handleChange = v => {
		value = isPresent(v) ? v : value;
		$autosaveStatus = null;

		dataErrors = validate(model, attribute, value, skipVerificationRequired);

		if (!dataErrors) {
			let params = { [model]: { [attribute]: value }, [`${childModel}_id`]: childId, ...additionalParams };

			method(url, params)
				.then(response => {
					$autosaveStatus = 'saved';
					$transmitter = { ...$transmitter, ...response };
					previousValue = value;
					dispatch('update', response);
				})
				.catch(_errors => {
					inputField.focus();
					$autosaveStatus = 'not_saved';
				});
			return;
		}

		$autosaveStatus = 'not_saved';
		$transmitter[model] = { ...$transmitter[model], [attribute]: value };
	};
</script>

<div class="form-group row" class:has-error={dataErrors} class:has-success={isSuccess}>
	<label for={inputId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<SearchBy
			method="address"
			id={inputId}
			name={inputId}
			requiredborder={required}
			disabledtext={disabled}
			{placeholder}
			bind:value
			{disabled}
			on:change={saveAutomatically && (({ detail: v }) => handleChange(v))}
			autocomplete="off"
		/>

		{#if dataErrors}
			<InputErrorsString errors={dataErrors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>
