<!-- @format -->
<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { isPresent } from 'utils/tools';
	//import DeleteIcon from '~/svelte/components/dashboard/delete_icon.svelte'

	const dispatch = createEventDispatcher();

	export let dctsMetaData = [];
	export let selectedDctsMetaData = [];
	export let selectedCreditors = [];
	export let creditors = [];

	const petitionsKinds = [
		'petition_for_publications_inclusion',
		'arbitrary',
		'petition_for_introduction_of_bankruptcy_proceedings',
		'petition_to_transfer_to_property_realization',
		'petition_for_inclusion_of_materials_of_sk',
		'petition_of_postponement',
		'petition_for_familiarization_with_electronic_file',
		'petition_for_familiarization_with_materials_of_case',
		'application_for_issuance_of_copy_of_judicial_act',
		'petition_for_reclamation_of_evidence_by_zags',
		'petition_for_reclamation_of_evidence_by_sfr',
		'petition_for_reclamation_of_evidence',
	];

	const displayName = dctMetaData => {
		if (isPresent(dctMetaData.name)) {
			return dctMetaData.name;
		} else if (dctMetaData.category === 'outgoing') {
			if (['bank', 'rosreestr', 'fedresource'].includes(dctMetaData.correspondent_kind)) {
				return `${dctMetaData.correspondent_kind_name} (${dctMetaData.kind_short_name})`;
			}
			if (['arbitrary', 'documentation_acceptance_and_transfer_act'].includes(dctMetaData.kind)) {
				return dctMetaData.kind_short_name;
			}
			if (petitionsKinds.includes(dctMetaData.kind)) {
				return `АС (${dctMetaData.kind_short_name})`;
			}
			return dctMetaData.correspondent_kind_name;
		} else if (['financial_analysis', 'reports'].includes(dctMetaData.category)) {
			return dctMetaData.kind_name;
		}
	};

	const handleDctMetaDataChange = e => {
		if (e.target.checked) {
			selectedCreditors = selectedCreditors.concat(creditors.filter(r => r.dctMetaData == e.target.__value));
		} else {
			selectedCreditors = selectedCreditors.filter(r => r.dctMetaData != e.target.__value);
		}
	};
	const handleCreditorChange = e => {
		const dctMetaData = e.target.__value.dctMetaData;
		const selected = selectedDctsMetaData.includes(dctMetaData);
		if (e.target.checked) {
			if (!selected) {
				selectedDctsMetaData = selectedDctsMetaData.concat([dctMetaData]);
			}
		} else {
			if (selected && !selectedCreditors.find(r => r.dctMetaData == dctMetaData)) {
				selectedDctsMetaData = selectedDctsMetaData.filter(r => r != dctMetaData);
			}
		}
	};
</script>

{#each dctsMetaData as dctMetaData}
	<li>
		<label>
			<input class="cursor_pointer" type="checkbox" bind:group={selectedDctsMetaData} value={dctMetaData} on:change={handleDctMetaDataChange} />
			<div class="meta-data-text">
				{displayName(dctMetaData)}
				{#if isPresent(dctMetaData.name)}
					<button class="simulated-link" on:click={dispatch('metaRecordEdit', dctMetaData.id)}> (ваш шаблон) </button>
					<!--button class='simulated-link m-l-xxs' on:click={dispatch('metaRecordDelete', dctMetaData.id)}>
            <DeleteIcon modalId='delete-template-modal'/>
          </button-->
				{/if}
			</div>
		</label>
	</li>
	{#each creditors.filter(e => e.dctMetaData == dctMetaData) as creditor}
		<li style="padding-left: 18px;">
			<label>
				<input class="cursor_pointer" type="checkbox" bind:group={selectedCreditors} value={creditor} on:change={handleCreditorChange} />
				<div class="meta-data-text">{creditor.text}</div>
			</label>
		</li>
	{/each}
{/each}

<style lang="scss">
	li {
		list-style-type: none;

		label {
			display: flex;
		}

		.meta-data-text {
			margin-top: -3px;
		}
	}
	input {
		margin-right: 0.6rem;
	}
</style>
