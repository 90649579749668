<!-- @format -->
<script>
	import { currentData, commonParams, requiredFieldsFilled } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import { transmitter } from '~/js/global_stores/base';
	import { toArray } from '~/js/utils/tools.js';

	let disabled = false;

	$: $requiredFieldsFilled.meeting_protocol_address = !!data.value;
	$: disabled = toArray($transmitter.meeting.disabled_fields).includes('meetingProtocolAddress') || $transmitter.dct.status == 'approved';

	$: data = {
		...$commonParams,
		disabled: $commonParams.disabled || disabled,
		required: true,
		attribute: 'meeting_protocol_address',
		label: 'Место составления',
		value: $currentData.meetingProtocolAddress,
		placeholder: 'г. Королев',
	};
</script>

<AutosaveInput {...data} on:update />
