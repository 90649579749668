<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: bankruptSnils = {
		//required: true,
		...$commonParams,
		hidden: !$currentData.insertFirstParagraph,
		attribute: 'bankrupt_snils',
		label: 'СНИЛС должника',
		value: $currentData && $currentData.bankruptSnils,
		placeholder: '___-___-___ __',
		maskOptions: { mask: '000-000-000 00' },
		modify: snils => snils && snils.replace(/\D/g, ''),
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_snils',
			resource: $procedure && $procedure.bankrupt,
			resourceProp: 'snils',
		},
	};
</script>

{#if !bankruptSnils.hidden}
	<AutosaveInput {...bankruptSnils} on:update />
{/if}
