<!-- @format -->
<script>
	export let disabled = false;
</script>

<svg width="16" height="13" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" class:disabled>
	<path d="M15 6H11V0H5V6H1L8 14L15 6ZM0 16H16V18H0V16Z" fill="#676a6c" />
</svg>

<style>
	.disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}
</style>
