<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: isRegistrar = $currentData && $currentData.registrarChoice === 'registrar';

	$: registrarInsurancePolicy = {
		...$commonParams,
		attribute: 'registrar_insurance_policy',
		label: 'Номер и дата полиса по страховке реестродержателя',
		value: $currentData && $currentData.registrarInsurancePolicy,
		placeholder: '№000 от дд.мм.гггг',
	};
</script>

{#if isRegistrar}
	<AutosaveInput {...registrarInsurancePolicy} />
{/if}
