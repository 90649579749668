<!-- @format -->
<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dct, dctFormAvailable, reloadRunningCosts } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch_helpers';
	import RunningCostModal from '~/svelte/running_costs/_modal.svelte';
	import { format } from 'date-fns';
	import { formatSum, toArray } from 'utils/tools';
	import moment from 'moment';

	export let fieldId;

	let indexCollapse = false;
	let runningCostModal;
	let date;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	const handleNewItem = () => runningCostModal.newItem();

	const handleEditItem = id => runningCostModal.editItem(id);

	const load = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/running_costs', { procedure_id: $procedure.id, date_at: $dct.date });
			$transmitter.running_costs = response.rows;
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	const changed = () => load();

	$: running_costs = $transmitter.running_costs;

	$: if ($reloadRunningCosts) {
		load();
		$reloadRunningCosts = false;
	}

	$: if (!moment(date).isSame(moment($dct.date)) && !fetching) {
		date = $dct.date;
		changed();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Текущие расходы (${toArray($transmitter.running_costs).length})`}</span>
	</h4>
</div>
<div class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && handleNewItem}> + Расход </button>
		<div class="p-1">
			<ol class="records-list">
				{#each toArray(running_costs) as row}
					<li>
						<div class="float-left simulated-link" role="button" tabindex="0" on:click={handleEditItem(row.id)} on:keypress|stopPropagation>
							{`${row.expense_type_text} (${formatSum(row.sum)} руб., ${format(new Date(row.date_at), 'dd.LL.yyyy')}) `}
						</div>
					</li>
				{/each}
			</ol>
		</div>
	</div>
</div>

<RunningCostModal bind:this={runningCostModal} on:create={changed} on:update={changed} on:delete={changed} disabled={!$dctFormAvailable} />

<style>
	.records-list {
		padding-left: 15px;
		list-style-type: circle;
	}
	.records-list li {
		cursor: pointer;
	}
	.header {
		cursor: pointer;
	}
</style>
