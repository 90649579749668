<!-- @format -->
<script>
	import { currentData, commonParams } from '../../../stores/stores';
	import AutosaveCheckbox from '../../../../components/ui/autosave_checkbox.svelte';

	$: bankruptRegAndFactualAddressesMatch = {
		...$commonParams,
		attribute: 'bankrupt_reg_and_factual_addresses_match',
		label: '',
		value: $currentData && $currentData.bankruptRegAndFactualAddressesMatch,
		text: 'Тот же, что и адрес регистрации',
		positionStyle: 'margin-top: -.3rem;',
	};
</script>

<AutosaveCheckbox {...bankruptRegAndFactualAddressesMatch} on:update />
