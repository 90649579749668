<!-- @format -->
<script>
	import Case from 'case';
	import { currentData, commonParams } from '../../stores/stores';
	import Checkbox from '../../../components/ui/autosave_checkbox.svelte';

	export let attribute;
	export let label;
	export let text;

	$: fieldName = {
		...$commonParams,
		attribute: Case.snake(attribute),
		label: label,
		value: $currentData && $currentData[attribute],
		text: text,
	};
</script>

<Checkbox {...fieldName} />
