/** @format */

import { get } from 'svelte/store';
import { transmitter } from 'base_stores';
import { isPresent } from 'utils/tools';
import Case from 'case';

export default function judicial_act_recognized_hint(attribute) {
	const procedure = get(transmitter).procedure;
	const data = procedure.judicial_act_recognized_data;
	const procedureAttributes = ['introduction_date', 'resolution_introduction_date', 'referee_full_name', 'hearing_date_and_time', 'courtroom'];
	const bankruptAttributes = [
		'surname',
		'name',
		'patronymic',
		'birth_date',
		'birth_place',
		'snils',
		'reg_address_full',
		'short_name',
		'full_name',
		'firm_name',
		'ogrn',
		'okved',
		'leader_post',
		'leader_full_name',
		'legal_address_full',
		'mailing_address_full',
		'phone',
		'email',
		'inn',
	];

	if (isPresent(data)) {
		const model = attributeName => {
			if (procedureAttributes.includes(Case.snake(attributeName))) {
				return 'procedure';
			} else if (bankruptAttributes.includes(Case.snake(attributeName))) {
				return 'bankrupt';
			}
		};

		if (isPresent(data.resolution) && attribute.value == data.resolution[model(attribute.name)][Case.snake(attribute.name)]) {
			return 'AI заполнил из судебного акта (резолютивная часть)';
		} else if (isPresent(data.full_text) && attribute.value == data.full_text[model(attribute.name)][Case.snake(attribute.name)]) {
			return 'AI заполнил из судебного акта (полный текст)';
		} else if (
			isPresent(attribute.value) // &&
			//(
			//  isPresent(data.resolution) && isPresent(data.resolution[model(attribute.name)][Case.snake(attribute.name)]) ||
			//  isPresent(data.full_text) && isPresent(data.full_text[model(attribute.name)][Case.snake(attribute.name)])
			//)
		) {
			return 'Изменено пользователем';
		} else {
			return null;
		}
	}
}
