<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import ComplaintForm from './_form.svelte';
	import { cardsTitle } from 'utils/formats';

	export let noComplainerSelected;
	export let openCounterpartyModalForm;
	export let complaintId;
	export let disabled = false;

	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};
</script>

<Modal
	modalId="form-complaint-modal"
	index="0"
	deleteIconDisabled={!complaintId || disabled}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	deleteConfirmMessage={'Вы действительно хотите удалить жалобу?'}
	submitButtonDisabled={noComplainerSelected || disabled}
	form={true}
	size="large"
>
	<h2 slot="header" class="creditors_complaint__header">{cardsTitle('жалобы', complaintId)}</h2>
	<div slot="body" class="form-complaint-modal__body">
		<ComplaintForm {complaintId} {disabled} {openCounterpartyModalForm} bind:handleSubmit bind:handleDelete bind:handleCancel bind:noComplainerSelected />
	</div>
</Modal>
