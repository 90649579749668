<!-- @format -->
<!-- @format -->
<script>
	import { autosaveStatus, transmitter } from 'base_stores';
	import { onMount, createEventDispatcher } from 'svelte';
	import { exec, init } from 'pell';
	import { fetchPost, fetchPut } from 'utils/fetch';

	const actionsSet = [
		{
			name: 'bold',
			icon: "<iconify-icon icon='ph:text-bolder-bold' height='16' style='margin-top: 3px'/>",
			title: 'Жирный',
		},
		{
			name: 'italic',
			icon: "<iconify-icon icon='ph:text-italic-bold' height='16' style='margin-top: 3px'/>",
			title: 'Курсив',
		},
		{
			name: 'underline',
			icon: "<iconify-icon icon='ph:text-underline-bold' height='18' style='margin-top: 3px'/>",
			title: 'Подчёркнутый',
		},
		{
			name: 'heading1',
			icon: "<iconify-icon icon='ph:text-h-one-bold' height='18' style='margin-top: 3px'/>",
			title: 'Заголовок 1',
		},
		{
			name: 'heading2',
			icon: "<iconify-icon icon='ph:text-h-two-bold' height='18' style='margin-top: 3px'/>",
			title: 'Заголовок 2',
		},
		{
			name: 'olist',
			icon: "<iconify-icon icon='ph:list-numbers-light' height='24' style='margin-top: 3px'/>",
			title: 'Нумерованный список',
		},
		{
			name: 'ulist',
			icon: "<iconify-icon icon='ph:list-bullets-light' height='24' style='margin-top: 3px'/>",
			title: 'Маркированный список',
		},
		{
			name: 'justifyLeft',
			icon: "<iconify-icon icon='ph:text-align-left-light' height='24' style='margin-top: 3px'/>",
			title: 'Выравнивание по левому краю',
			result: () => exec('justifyLeft'),
		},
		{
			name: 'justifyCenter',
			icon: "<iconify-icon icon='ph:text-align-center-light' height='24' style='margin-top: 3px'/>",
			title: 'Выравнивание по центру',
			result: () => exec('justifyCenter'),
		},
		{
			name: 'justifyRight',
			icon: "<iconify-icon icon='ph:text-align-right-light' height='24' style='margin-top: 3px'/>",
			title: 'Выравнивание по правому краю',
			result: () => exec('justifyRight'),
		},
		{
			name: 'justifyFull',
			icon: "<iconify-icon icon='ph:text-align-justify-light' height='24' style='margin-top: 3px'/>",
			title: 'Выравнивание по ширине',
			result: () => exec('justifyFull'),
		},
		{
			name: 'removeFormat',
			icon: "<p style='margin-top: -8px; margin-left: .5rem; position:absolute'>Очистить формат</p>",
			title: 'Очистить формат',
			result: () => removeFormat(),
		},
	];

	export let attribute;
	export let model;
	export let url;
	export let id;
	export let value;
	export let disabled;
	export let label;
	export let additionalParams = {};
	export let saveAutomatically = true;
	export let actions;
	export let forceSave = false;

	let textboxWrapper;

	const dispatch = createEventDispatcher();

	$: method = id ? fetchPut : fetchPost;
	$: if (id) {
		url = `${url}/${id}`;
	}
	$: isSuccess = !!value || !!previousValue;
	$: textbox = textboxWrapper && textboxWrapper.childNodes[1];
	// надо избавиться от такой привязки и пересмотреть механизм отслеживания превью-значения.
	$: previousValue =
		($transmitter && $transmitter.document && $transmitter.document[attribute]) ||
		($transmitter && $transmitter.dct && $transmitter.dct.substitutions[attribute]);

	$: if (previousValue && textbox) {
		textbox.innerHTML = previousValue;
	}

	$: if (!value && !previousValue && textbox) {
		textbox.innerHTML = '';
	}

	$: if (disabled && textbox) {
		textbox.contentEditable = false;
	} else if (textbox) {
		textbox.contentEditable = true;
	}

	$: if (forceSave) {
		handleChange();
	}

	const handleChange = () => {
		$autosaveStatus = null;

		if (!saveAutomatically) {
			return;
		}

		let params = { [model]: { [attribute]: value }, ...additionalParams };

		method(url, params)
			.then(response => {
				if (!forceSave && JSON.stringify(response) !== '{}') {
					$autosaveStatus = 'saved';
				}
				$transmitter = { ...$transmitter, ...response };
				if ($transmitter.not_saved) {
					$autosaveStatus = 'not_saved';
				}
				forceSave = false;
				dispatch('update', response);
			})
			.catch(_errors => {
				$autosaveStatus = 'not_saved';
			});
	};

	const removeFormat = () => {
		const text = textbox.innerHTML.replace(/(<([^>]+)>)/gi, '');
		const div = document.createElement('div');

		div.innerHTML = text;
		textbox.innerHTML = '';
		textbox.appendChild(div);
		value = textbox.innerHTML;

		handleChange();
	};

	onMount(() => {
		init({
			element: document.getElementById(`pell-${attribute}`),
			onChange: html => (value = html),
			defaultParagraphSeparator: 'div',
			styleWithCSS: false,
			actions: actions ? actionsSet.filter(a => actions.includes(a.name)) : actionsSet,
			classes: {
				actionbar: 'pell-actionbar',
				button: 'pell-button',
				content: 'pell-content',
				selected: 'pell-button-selected',
			},
		});

		let textBox = textboxWrapper.childNodes[1];

		textBox.onblur = e => (e.relatedTarget && e.relatedTarget.className === 'pell-button' ? e.stopPropagation() : handleChange());

		textBox.onfocus = () => (isSuccess = true);
	});
</script>

<p>{label}</p>
<div
	id={`pell-${attribute}`}
	class={'pell'}
	class:success={isSuccess}
	class:bordered-pell={!isSuccess}
	class:disabled
	style={'font-size: 11pt; font-family: Arial;'}
	bind:this={textboxWrapper}
></div>

<style lang="scss">
	.success {
		border: 1px solid #1ab394;
		padding: 0;
	}
	.bordered-pell {
		border: 1px solid #aaaaaa;
	}
	.disabled {
		background-color: #f3f2f2;
	}
	.pell {
		margin: 0.5rem 0 1rem 0;
	}
</style>
