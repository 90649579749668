<!-- @format -->
<script>
	export let label;
	export let model;
	export let attribute;
	export let value;
	export let required = false;
	export let disabled = false;
	export let options = [];
</script>

<div class="form-group row">
	{#if !!label}
		<label for={`radio-select-${model}-${attribute}`} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
			{label}
			{#if required}<attr class:required>*</attr>{/if}
		</label>
	{/if}
	<div
		class="col-form-label"
		class:col-sm-8={!!label}
		class:col-lg-9={!!label}
		class:col-xl-8={!!label}
		class:col-sm-12={!label}
		class:col-lg-12={!label}
		class:col-xl-12={!label}
	>
		{#if options && options.length}
			{#each options as option}
				<label class="d-flex">
					<input type="radio" {disabled} bind:group={value} class="align-self-center m-r-xs" value={option.id} />
					{@html option.text}
				</label>
			{/each}
		{/if}
	</div>
</div>
