<!-- @format -->
<script>
	import { dct, currentData, requiredFieldsFilled, commonParams } from '../stores/stores';
	import AutosaveDatepicker from '../../components/ui/autosave_datepicker.svelte';
	import moment from 'moment';

	$: bankruptcyPetitionDate = $currentData && $currentData.bankruptcyPetitionDate;

	$: if ($dct.form_fields.includes('dateFromWhichAnalyzed')) {
		$requiredFieldsFilled.dateFromWhichAnalyzed = !!dateFromWhichAnalyzed.value;
	}

	const threeYearsFromDate = fromDate => (fromDate ? moment(fromDate).subtract(3, 'years').format('YYYY-MM-DD') : null);

	$: dateFromWhichAnalyzed = {
		...$commonParams,
		required: true,
		attribute: 'date_from_which_analyzed',
		label: 'Дата, с которой анализируются сделки',
		value: $currentData.dateFromWhichAnalyzed,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData:
			$currentData.dateFromWhichAnalyzed === threeYearsFromDate(bankruptcyPetitionDate)
				? { linkText: 'Дата принятия судом заявления', additionalText: ' - 3 года' }
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveDatepicker {...dateFromWhichAnalyzed} />
