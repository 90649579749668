<!-- @format -->
<script>
	import CounterpartyForm from './_form.svelte';
	import Modal from '~/svelte/components/modal.svelte';
	import { cardsTitle } from 'utils/formats';

	export let deleteIconDisabled;
	export let requiredFieldsFilled;
	export let counterpartyId;
	export let fixedRoles = [];
	export let index;
	export let modalId = 'form-counterparty-modal';
	export let disabled = false;
	export let title;
	export let personage = 'контрагента';

	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};
</script>

<Modal
	{modalId}
	{index}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	deleteIconDisabled={deleteIconDisabled || disabled}
	submitButtonDisabled={!requiredFieldsFilled || disabled}
	deleteButtonTooltipText={`Вы действительно хотите удалить ${personage}?`}
	form={true}
	size="large"
>
	<h2 slot="header" class="counterparty-modal__header">{title || cardsTitle(personage, counterpartyId)}</h2>
	<div slot="body">
		<CounterpartyForm
			{counterpartyId}
			{disabled}
			{fixedRoles}
			bind:handleSubmit
			bind:handleDelete
			bind:handleCancel
			bind:requiredFieldsFilled
			on:create
			on:update
			on:cancel
		/>
	</div>
</Modal>
