<!-- @format -->
<script>
	import { procedure, newObject, bankruptType } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;

	const metaData = {
		Person: { placeholder: '123456789012', maskOptions: { mask: '000000000000' } },
		Organization: { placeholder: '1234567890', maskOptions: { mask: '0000000000' } },
	};

	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('bankruptInn');

	$: bankruptInn = {
		//required: true,
		...$commonParams,
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		attribute: 'bankrupt_inn',
		label: 'ИНН должника',
		value: $currentData && $currentData.bankruptInn,
		...metaData[$bankruptType],
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_inn',
			resource: $procedure && $procedure.bankrupt,
			resourceProp: 'inn',
		},
	};
</script>

<AutosaveInput {...bankruptInn} on:update />
