<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: propertyInventoryNumber = {
		...$commonParams,
		attribute: 'property_inventory_number',
		label: 'Номер описи имущества',
		value: $currentData && $currentData.propertyInventoryNumber,
		placeholder: '1',
	};
</script>

<AutosaveInput {...propertyInventoryNumber} />
