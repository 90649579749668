<!-- @format -->
<script>
	import { procedure, transmitter, options } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch';
	import { showModal } from '../components/modal.svelte';
	import Header from '../components/header.svelte';
	import Table from './table.svelte';

	let procedureId = null;
	let mounted = false;
	let bankAccountId;

	const openModalForm = () => {
		bankAccountId = null;
		showModal('form-bank_account-modal');
	};

	onMount(async () => {
		procedureId = $procedure && $procedure.id;

		let response = await fetchGet('/api/private/bank_accounts/kind_options');
		$options = { ...$options, bankAccountKind: response.options };
		response = await fetchGet('/api/private/bank_accounts');
		$transmitter = { ...$transmitter, bankAccounts: response.bank_accounts };
		mounted = true;
	});
</script>

<Header><span slot="name">Счета</span></Header>
<div class="m-b-lg mb-0">
	<button
		class="btn btn-sm btn-warning m-r-sm m-b-sm ml-2 ml-sm-0"
		disabled={!$procedureAvailable || !procedureId}
		on:click={procedureId && $procedureAvailable && openModalForm()}
	>
		+ Счёт
	</button>
</div>
<div class="m-b-lg overlay p-1 footer-margin">
	<Table {bankAccountId} bind:mounted />
</div>
