<!-- @format -->
<script>
	import { procedure, newObject, fieldsReady } from 'base_stores';
	import { dct, substitutions, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDadateAddress from '~/svelte/components/ui/autosave_dadate_input_address.svelte';
	import Checkbox from '~/svelte/components/ui/checkbox.svelte';
	import { onMount } from 'svelte';

	export let fieldId;

	$: procedureId = $procedure && $procedure.id;
	$: localCommonParams = { ...$commonParams, disabled: $commonParams.disabled || $currentData.bankruptRegAndFactualAddressesMatch };

	let forceSave = false;
	let factualAddressCheck = false;
	let initFieldReady = true;

	$: $fieldsReady[fieldId] = !initFieldReady;

	$: if ($currentData.bankruptRegAndFactualAddressesMatch) {
		forceSave = true;
		if ($substitutions && $substitutions.bankrupt_reg_address === $substitutions.bankrupt_factual_address) {
			initFieldReady = false;
		}
	} else {
		forceSave = false;
		initFieldReady = false;
	}

	$: if (factualAddressCheck && $currentData.bankruptRegAddress !== $currentData.bankruptFactualAddress) {
		forceSave = true;
	}

	$: formFields = {
		bankruptFactualAddress: {
			...localCommonParams,
			attribute: 'bankrupt_factual_address',
			label: 'Адрес фактического проживания должника',
			value: factualAddressCheck ? $currentData.bankruptRegAddress : $currentData.bankruptFactualAddress,
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
			forceSave,
			disabled: factualAddressCheck,
			hintData: {
				url: `/procedures/${procedureId}`,
				linkText: 'Процедура',
				consumerNew: $newObject,
				consumer: $dct,
				consumerProp: 'substitutions.bankrupt_factual_address',
				resource: $procedure && $procedure.bankrupt,
				resourceProp: 'factual_address',
			},
		},
		factualAddressCheck: {
			...localCommonParams,
			disabled: !procedureId,
			text: 'тот же, что и адрес регистрации',
		},
	};

	onMount(() => {
		factualAddressCheck = $currentData.bankruptRegAddress === $currentData.bankruptFactualAddress;
	});
</script>

<AutosaveDadateAddress {...formFields.bankruptFactualAddress} on:update />
<Checkbox {...formFields.factualAddressCheck} bind:checked={factualAddressCheck} />
