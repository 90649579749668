/** @format */

import { writable, derived } from 'svelte/store';
import { isBlank } from 'utils/tools';

export const selectionСoordinates = writable([]);
export const scale = writable(1);
export const canvasWidth = writable(null);
export const canvasHeight = writable(null);
export const pages = writable(0);

export const coordinates = derived(
	[selectionСoordinates, scale, canvasWidth, canvasHeight, pages],
	([$selectionСoordinates, $scale, $canvasWidth, $canvasHeight, $pages]) => {
		let roi = $selectionСoordinates.map(coordinatesSet => {
			if (isBlank(coordinatesSet) || coordinatesSet[0] === coordinatesSet[2] || coordinatesSet[1] === coordinatesSet[3]) {
				return 'skip';
			} else {
				return String(coordinatesSet.map(coordinate => (coordinate / $scale).toFixed(0)));
			}
		});

		if (isBlank(roi.map(i => i)) || roi.every(coordinatesSet => coordinatesSet === 'skip')) {
			roi = roi.map(i => i).map(() => String([0, 0, ($canvasWidth / $scale).toFixed(0), ($canvasHeight / $scale).toFixed(0)]));
		}

		if (roi.length > $pages) {
			roi = roi.filter((_r, i) => i <= $pages);
		}

		return roi.join(';') + ';';
	},
);
