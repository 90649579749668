<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveDatepicker from '../../components/ui/autosave_datepicker.svelte';

	$: verificationDate = $currentData && $currentData.fictitiousOrDeliberateBankruptcySignsVerificationDate;

	$: fictitiousOrDeliberateBankruptcySignsVerificationDate = {
		...$commonParams,
		attribute: 'fictitious_or_deliberate_bankruptcy_signs_verification_date',
		label: 'Дата проверки признаков фиктивного или преднамеренного банкротства',
		value: verificationDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: ($currentData && $currentData.date) === verificationDate ? { linkText: 'Дата отчёта' } : { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveDatepicker {...fictitiousOrDeliberateBankruptcySignsVerificationDate} />
