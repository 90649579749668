<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveCheckbox from '~/svelte/components/ui/autosave_checkbox.svelte';

	$: bankruptHasConstantSourceOfIncome = {
		...$commonParams,
		attribute: 'bankrupt_has_constant_source_of_income',
		label: 'Должник имеет постоянный источник дохода',
		value: $currentData && $currentData.bankruptHasConstantSourceOfIncome,
		text: 'Да',
	};
</script>

<AutosaveCheckbox {...bankruptHasConstantSourceOfIncome} on:update />
