<!-- @format -->
<!-- @format -->
<script>
	export let attribute;
	export let model;
	export let value;
	export let length = 10;
	export let placeholder = null;
	export let required = false;
	export let disabled = false;
	export let label;
	export let handleOnChange;

	let inputField;
	let dataErrors;

	$: isSuccess = value && !dataErrors;
	$: attrId = `input-integer-${model}-${attribute}`;
	$: value = clarify(value);

	const clarify = value => {
		let tmp = value ? String(value) : '';
		tmp = tmp.replace(/[^\d]/g, '');
		if (tmp.length > length) {
			tmp = tmp.slice(0, length);
		}
		return tmp;
	};
</script>

<div class="form-group row" class:has-error={dataErrors} class:has-success={isSuccess}>
	<label for={`input-${model}-${attribute}`} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<input
			on:change={handleOnChange}
			id={attrId}
			type="text"
			class:required-border={required}
			class:text-disabled={disabled}
			class="form-control"
			{placeholder}
			bind:value
			bind:this={inputField}
			{disabled}
		/>
	</div>
</div>

<style>
	input {
		background-color: #fff !important;
	}
</style>
