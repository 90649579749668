/** @format */

import { get } from 'svelte/store';
import { transmitter } from 'base_stores';
import Model from 'models/Model';
import { insurancePoliciesApi } from 'tools/service_api';
import { isPresent } from 'utils/tools';

export default class InsurancePolicy extends Model {
	constructor(json) {
		super(json);
	}

	// class methods

	static subStoreName() {
		return 'insurancePolicies';
	}

	static new(attributes) {
		return new this({
			proprietor_type: null,
			proprietor_id: null,
			organization: null,
			number: null,
			date: null,
			date_start: null,
			date_finish: null,
			...(attributes || {}),
		});
	}

	static async loadInsurancePolicies(defaultArbitrManagerParams) {
		const response = await insurancePoliciesApi.index({ filter: defaultArbitrManagerParams });
		transmitter.set({ ...get(transmitter), insurancePolicies: response.insurance_policies || [] });
		return (response.insurance_policies || []).map(item => new this(item));
	}

	static async create(params) {
		return isPresent(this.errors) ? { errors: this.errors } : await super.create(params);
	}

	static async update(params) {
		return isPresent(this.errors) ? { errors: this.errors } : await super.update(params);
	}
}
