<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveTextarea from '~/svelte/components/ui/autosave_textarea.svelte';

	$: transactionsWithAssetsOfBankrupt = {
		...$commonParams,
		attribute: 'transactions_with_assets_of_bankrupt',
		label: 'Данные о сделках с активами должника',
		value: $currentData?.transactionsWithAssetsOfBankrupt,
		placeholder: 'В анализируемый период была сделка по продаже…',
	};
</script>

{#if $currentData?.hasTransactionsWithAssetsOfBankrupt}
	<AutosaveTextarea {...transactionsWithAssetsOfBankrupt} on:update />
{/if}
