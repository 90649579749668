<!-- @format -->
<script>
	import { formatSum, isPresent } from 'utils/tools';
	import { transmitter } from 'base_stores';
	import CreditorsRequirementModal from '~/svelte/creditors_requirements/_modal.svelte';
	import { fetchPut } from '~/js/utils/fetch';

	export let title = '';
	export let answer;
	export let requirementState = 'all';
	export let isDraft = false;

	let requirements = [];
	let indexCollapse = false;
	let creditorRequirementModal;

	$: if ($transmitter.creditors_requirements) {
		refreshRecognizedCreditorsRequirements();
	}

	const refreshRecognizedCreditorsRequirements = () => {
		if (requirementState === 'all') {
			requirements = $transmitter.creditors_requirements;
		} else {
			requirements = $transmitter.creditors_requirements.filter(cr => cr.state === requirementState);
		}
	};

	const approveCreditorsRequirement = id => {
		fetchPut(`/api/private/creditors_requirements/${id}`, { state: 'approved', answer_id: answer.id }).then(response => {
			$transmitter.creditors_requirements.find(cr => cr.id === id).state = 'approved';
			$transmitter.creditors_requirements = [...$transmitter.creditors_requirements];
			refreshRecognizedCreditorsRequirements();
		});
	};

	const openCreditorsRequirementModalForm = id => {
		id ? creditorRequirementModal.editItem(id) : creditorRequirementModal.newItem();
	};
</script>

<div class="form-group row" role="button" tabindex="0" on:keypress|preventDefault on:click={() => (indexCollapse = !indexCollapse)}>
	<h4 class="draft-requirements col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`${title} (${requirements.length})`}</span>
	</h4>
</div>
<div id="collapse-creditors-requirements" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		{#if !isDraft}
			<button class="btn btn-xs btn-warning m-b-xs" disabled={!answer.id} on:click={answer.id && openCreditorsRequirementModalForm()}>
				+ Входящее требование
			</button>
		{/if}
	</div>
</div>
<div id="collapse-draft-requirements" class="collapse row" class:show={indexCollapse}>
	<div class="col-sm-12">
		<div class="p-1">
			<ol class="block-list">
				{#if isPresent(requirements)}
					{#each requirements as requirement}
						<li class="m-b-xs">
							<div
								class="float-left simulated-link"
								role="button"
								tabindex="0"
								on:keypress|preventDefault
								on:click={openCreditorsRequirementModalForm(requirement.id)}
							>
								{`Входящее требование на ${formatSum(requirement.amount)} руб.`}
							</div>
							{#if isDraft}
								<div class="float-right">
									<button
										class="btn btn-xs btn-approve btn-primary m-b-xs"
										style="margin-top: -3px"
										disabled={!answer.id}
										on:click={answer.id && approveCreditorsRequirement(requirement.id)}
									>
										Утвердить
									</button>
								</div>
							{/if}
						</li>
					{/each}
				{/if}
			</ol>
		</div>
	</div>
</div>
<CreditorsRequirementModal
	disabled={!answer.id}
	fromAnswer={isPresent(answer.id)}
	{answer}
	{approveCreditorsRequirement}
	bind:this={creditorRequirementModal}
/>
