<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInput from '../../components/ui/autosave_input.svelte';

	$: localCommonParams = { ...$commonParams, disabled: true };

	$: debtCoverageByAssets = {
		...localCommonParams,
		attribute: 'debt_coverage_by_assets',
		label: 'Покрытие активами долгов должника, %',
		value: $currentData && $currentData.debtCoverageByAssets,
		placeholder: '0',
		hintData: { freeMessage: 'AI заполнил на основе «Имущество» / «Долги» * 100' },
	};
</script>

<AutosaveInput {...debtCoverageByAssets} />
