<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../stores/stores';
	import AutosaveInput from '../../components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: refereeFullName = {
		...$commonParams,
		attribute: 'referee_full_name',
		label: 'ФИО судьи',
		value: $currentData && $currentData.refereeFullName,
		placeholder: 'Фамилия Имя Отчество',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.referee_full_name',
			resource: $procedure,
			resourceProp: 'referee_full_name',
		},
	};
</script>

<AutosaveInput {...refereeFullName} on:update />
