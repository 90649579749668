/** @format */

// Контекстный поиск по ФИО
import { token, methods } from './config';

export default (method, query) => {
	if (!query) {
		return new Promise(resolve => {
			resolve([]);
		});
	}

	const url = methods[method] && methods[method].url;

	if (!url) {
		throw 'DaData: несуществующий тип запроса - ' + method;
	}

	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Token ' + token,
		},
		body: JSON.stringify({ query }),
	};

	return fetch(url, options)
		.then(result => result.json())
		.then(result => result.suggestions)
		.catch(() => console.error('Сервис DaData временно недоступен'));
	// .then(result => { console.log(result); return result;})
};
