/** @format */

import { get } from 'svelte/store';
import { transmitter } from 'base_stores';
import Model from 'models/Model';
import { spousesApi } from 'tools/service_api';
import { validate } from 'tools/validators';
import { isPresent } from 'utils/tools';

export default class Spouse extends Model {
	constructor(json) {
		super(json);
	}

	// class methods

	static subStoreName() {
		return 'spouses';
	}

	static new() {
		return new this({
			surname: null,
			name: null,
			patronymic: null,
			full_name_previous: null,
			inn: null,
			snils: null,
			passport_number: null,
			passport_department: null,
			passport_date: null,
			birth_date: null,
			birth_place: null,
			reg_address_full: null,
			mailing_address_full: null,
			marriage: {
				marriage_certificate_number: null,
				marriage_certificate_date: null,
				common_property_division_agreement: false,
				marriage_contract: false,
				marriage_dissolved: false,
				divorce_certificate_number: null,
				divorce_certificate_date: null,
				division_common_property_judicial_acts: false,
			},
		});
	}

	static async loadSpouses() {
		const response = await spousesApi.index();
		transmitter.set({ ...get(transmitter), spouses: response.spouses || [] });
		return (response.spouses || []).map(item => new this(item));
	}

	static async validates(params) {
		this.errors = {};

		for (let attribute of ['inn', 'snils', 'passport_number']) {
			const attributeErrors = validate('person', attribute, params[attribute], true);

			if (isPresent(attributeErrors)) {
				this.errors = { ...this.errors, [attribute]: attributeErrors };
			}
		}
	}

	static async create(params) {
		this.validates(params);

		return isPresent(this.errors) ? { errors: this.errors } : await super.create(params);
	}

	static async update(params) {
		this.validates(params);

		return isPresent(this.errors) ? { errors: this.errors } : await super.update(params);
	}
}
