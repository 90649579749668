<!-- @format -->
<script>
	import { options } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';

	$: currentCcorrespondentKind = $currentData && $currentData.correspondentKind;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('correspondentKind');

	$: if ($dct.form_fields.includes('correspondentKind')) {
		$requiredFieldsFilled.correspondentKind = !!correspondentKind.value;
	}

	$: correspondentKind = {
		...$commonParams,
		required: true,
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		attribute: 'correspondent_kind',
		label: 'Вид корреспондента',
		value: $options.correspondentKind && $options.correspondentKind.find(option => option.id === currentCcorrespondentKind),
		options: $options.correspondentKind,
		placeholder: 'Выбрать вид корреспондента',
	};
</script>

<AutosaveSelect2Search {...correspondentKind} on:update />
