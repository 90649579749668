<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import InsurancePolicyForm from './_form.svelte';
	import { cardsTitle } from 'utils/formats';

	export let insurancePolicyId;

	let submitButtonDisabled;
	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};
</script>

<Modal
	modalId="form-insurance-policy-modal"
	{submitButtonDisabled}
	deleteIconDisabled={!insurancePolicyId || submitButtonDisabled}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	form={true}
	size="large"
>
	<h2 slot="header" class="insurance-policy-modal__header">{cardsTitle('страхового полиса', insurancePolicyId)}</h2>
	<div slot="body">
		<InsurancePolicyForm {insurancePolicyId} bind:handleSubmit bind:handleCancel bind:handleDelete bind:submitButtonDisabled on:submit on:delete />
	</div>
</Modal>
