<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '../../../stores/stores';
	import AutosaveDadateAddress from '../../../../components/ui/autosave_dadate_input_address.svelte';

	$: arbitrManager = $procedure && $procedure.arbitr_manager;
	$: arbitrManagerId = arbitrManager && arbitrManager.id;

	$: if ($dct.form_fields.includes('arbitrManagerRegAddress')) {
		$requiredFieldsFilled.arbitrManagerRegAddress = !!arbitrManagerRegAddress.value;
	}

	$: arbitrManagerRegAddress = {
		...$commonParams,
		required: true,
		attribute: 'arbitr_manager_reg_address',
		label: 'Адрес регистрации АУ',
		value: $currentData.arbitrManagerRegAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_reg_address',
			resource: arbitrManager && arbitrManager.person,
			resourceProp: 'reg_address',
		},
	};
</script>

<AutosaveDadateAddress {...arbitrManagerRegAddress} on:update />
