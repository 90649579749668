<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDadateAddress from '~/svelte/components/ui/autosave_dadate_input_address.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('bankruptRegAddress');

	$: bankruptRegAddress = {
		...$commonParams,
		required: $dct && $dct.correspondent_kind == 'fedresource',
		hidden: !$currentData.insertFirstParagraph,
		attribute: 'bankrupt_reg_address',
		label: 'Адрес регистрации должника',
		value: $currentData && $currentData.bankruptRegAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_reg_address',
			resource: $procedure && $procedure.bankrupt,
			resourceProp: 'reg_address',
		},
	};
</script>

{#if !bankruptRegAddress.hidden}
	<AutosaveDadateAddress {...bankruptRegAddress} on:update />
{/if}
