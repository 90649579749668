<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveCheckbox from '../../components/ui/autosave_checkbox.svelte';

	$: arbitrManagerReceivedRestructuringPlan = {
		...$commonParams,
		attribute: 'arbitr_manager_received_restructuring_plan',
		label: 'АУ поступал план реструктуризации',
		value: $currentData && $currentData.arbitrManagerReceivedRestructuringPlan,
		text: 'Да',
	};
</script>

<AutosaveCheckbox {...arbitrManagerReceivedRestructuringPlan} />
