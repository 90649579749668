<!-- @format -->
<script>
	import { currentData, commonParams, dct } from '../stores/stores';
	import Checkbox from '../../components/ui/autosave_checkbox.svelte';

	$: insertFirstParagraph = {
		...$commonParams,
		attribute: 'insert_first_paragraph',
		label: 'Использовать первый стандартный абзац',
		value: $currentData.insertFirstParagraph,
		text: 'Да, использовать',
	};
</script>

<Checkbox {...insertFirstParagraph} />
