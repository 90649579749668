<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveCheckbox from '../../components/ui/autosave_checkbox.svelte';
	import AutosaveTextarea from '../../components/ui/autosave_textarea.svelte';

	$: formFields = {
		identifiedTransactionsSubjectToDispute: {
			...$commonParams,
			attribute: 'identified_transactions_subject_to_dispute',
			label: 'Выявлены сделки, подлежащие оспариванию',
			value: $currentData.identifiedTransactionsSubjectToDispute,
			text: 'Да',
		},
		informationOnTransactionsSubjectToDispute: {
			...$commonParams,
			attribute: 'information_on_transactions_subject_to_dispute',
			label: 'Информация о сделках, подлежащих оспариванию',
			value: $currentData.informationOnTransactionsSubjectToDispute,
			placeholder: 'Продажа автомобиля марки ВАЗ.',
		},
		groundsForDisputingTransactions: {
			...$commonParams,
			attribute: 'grounds_for_disputing_transactions',
			label: 'Основание оспаривания сделок',
			value: $currentData.groundsForDisputingTransactions,
			placeholder: 'Сделка по продаже автомобиля марки ВАЗ была недействительной, потому что…',
		},
	};
</script>

<AutosaveCheckbox {...formFields.identifiedTransactionsSubjectToDispute} />
{#if $currentData.identifiedTransactionsSubjectToDispute}
	<AutosaveTextarea {...formFields.informationOnTransactionsSubjectToDispute} />
	<AutosaveTextarea {...formFields.groundsForDisputingTransactions} />
{/if}
