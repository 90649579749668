<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: publicationNewspaperDate = {
		...$commonParams,
		attribute: 'publication_newspaper_date',
		label: 'Дата публикации в «Коммерсантъ»',
		value: $currentData && $currentData.publicationNewspaperDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.publication_newspaper_date',
			resource: $procedure,
			resourceProp: 'publication_newspaper_date',
		},
	};
</script>

<AutosaveDatepicker {...publicationNewspaperDate} />
