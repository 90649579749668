<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('arbitrManagerShortName');

	$: if ($dct.form_fields.includes('arbitrManagerShortName')) {
		$requiredFieldsFilled.arbitrManagerShortName = !!arbitrManagerShortName.value;
	}

	$: arbitrManagerShortName = {
		required: true,
		...$commonParams,
		attribute: 'arbitr_manager_short_name',
		label: 'Сокращенные ФИО АУ',
		value: $currentData.arbitrManagerShortName,
		placeholder: 'И. О. Фамилия',
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.arbitr_manager_short_name',
			resource: $procedure && $procedure.arbitr_manager,
			resourceProp: 'short_name',
		},
	};
</script>

<AutosaveInput {...arbitrManagerShortName} on:update />
