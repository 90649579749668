<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';
	import AutosaveDadateAddress from '~/svelte/components/ui/autosave_dadate_input_address.svelte';
	import AutosaveDadateEmail from '~/svelte/components/ui/autosave_dadata_input_email.svelte';
	import AutosaveDadataInputFio from '~/svelte/components/ui/autosave_dadata_input_fio.svelte';
	import AutosaveDadataInputFio2 from '~/svelte/components/ui/autosave_dadata_input_fio2.svelte';
	import AutosaveDadataInputPassportDepartment from '~/svelte/components/ui/autosave_dadata_input_passport_department.svelte';
	import AutosaveCheckbox from '~/svelte/components/ui/autosave_checkbox.svelte';
	import Checkbox from '~/svelte/components/ui/checkbox.svelte';
	import judicialActRecognizedHint from './judicial_act_recognized_hint';
	import SpousesBlock from './spouses/_list.svelte';
	import ChildrenBlock from './children/_list.svelte';

	const searchPath = ['procedure', 'bankrupt'];

	let mailingAddressCheck = false;
	let factualAddressCheck = false;

	$: arbitrProcedure = ($transmitter && $transmitter.procedure) || arbitrProcedure;
	$: procedureId = (arbitrProcedure && arbitrProcedure.id) || null;
	$: procedurePhase = arbitrProcedure && arbitrProcedure.phase;
	$: bankrupt = arbitrProcedure && arbitrProcedure.bankrupt;
	$: bankruptId = (bankrupt && bankrupt.id) || null;
	$: hasPreviousFullName = bankrupt?.full_name_previous;
	$: hasOgrnip = bankrupt && bankrupt.ogrnip;
	$: changedFullNameCheck = hasPreviousFullName;
	$: mailingAddressCheckValue =
		bankrupt && bankrupt.reg_address_full && bankrupt.mailing_address_full && bankrupt.reg_address_full === bankrupt.mailing_address_full;
	$: mailingAddressCheck = mailingAddressCheckValue;
	$: factualAddressCheckValue =
		bankrupt && bankrupt.reg_address_full && bankrupt.factual_address_full && bankrupt.reg_address_full === bankrupt.factual_address_full;
	$: factualAddressCheck = factualAddressCheckValue;
	$: showPreviousFullName = changedFullNameCheck || hasPreviousFullName;
	$: showOgrnip = bankrupt?.individual_entrepreneur || hasOgrnip;

	$: formFields = {
		fullName: {
			disabled: !procedureId || !$procedureAvailable,
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			placeholder: 'Фамилия Имя Отчество',
			label: 'ФИО должника',
			data: [
				{ name: 'surname', value: bankrupt && bankrupt.surname, localizedName: 'Фамилия' },
				{ name: 'name', value: bankrupt && bankrupt.name, localizedName: 'Имя' },
				{ name: 'patronymic', value: bankrupt && bankrupt.patronymic, localizedName: 'Отчество' },
			],
			hintData: {
				judicial_act_recognized: judicialActRecognizedHint({
					name: 'surname',
					value: bankrupt && bankrupt.surname,
				}),
			},
		},
		previousFullName: {
			disabled: !procedureId || !$procedureAvailable,
			model: 'person',
			attribute: 'full_name_previous',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Предыдущие ФИО',
			placeholder: 'Фамилия Имя Отчество',
			value: bankrupt.full_name_previous,
		},
		inn: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'inn',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'ИНН должника',
			placeholder: '123456789012',
			value: bankrupt && bankrupt.inn,
			maskOptions: { mask: '000000000000' },
			searchPath,
			hintData: {
				judicial_act_recognized: judicialActRecognizedHint({
					name: 'inn',
					value: bankrupt && bankrupt.inn,
				}),
			},
		},
		snils: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'snils',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'СНИЛС должника',
			placeholder: '123-456-789 10',
			value: bankrupt && bankrupt.snils,
			maskOptions: { mask: '000-000-000 00' },
			modify: snils => snils && snils.replace(/\D/g, ''),
			searchPath,
			hintData: {
				judicial_act_recognized: judicialActRecognizedHint({
					name: 'snils',
					value: bankrupt && bankrupt.snils,
				}),
			},
		},
		passportNumber: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'passport_number',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Паспорт должника',
			placeholder: '9900 123456',
			value: bankrupt && bankrupt.passport_number,
			maskOptions: { mask: '0000 000000' },
			searchPath,
		},
		passportDepartment: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'passport_department',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'кем выдан',
			placeholder: 'Московским РОВД',
			value: bankrupt && bankrupt.passport_department,
			searchPath,
		},
		passportDepartmentCode: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'passport_department_code',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'код подразделения',
			value: bankrupt && bankrupt.passport_department_code,
			placeholder: '700-052',
			maskOptions: { mask: '000-000' },
			searchPath,
		},
		passportDate: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'passport_date',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'когда выдан',
			value: bankrupt && bankrupt.passport_date,
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
		},
		birthDate: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'birth_date',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Дата рождения должника',
			value: bankrupt && bankrupt.birth_date,
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
			hintData: {
				judicial_act_recognized: judicialActRecognizedHint({
					name: 'birth_date',
					value: bankrupt && bankrupt.birth_date,
				}),
			},
		},
		birthPlace: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'birth_place',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Место рождения должника (как в паспорте)',
			placeholder: 'гор. Москва',
			value: bankrupt && bankrupt.birth_place,
			searchPath,
			hintData: {
				judicial_act_recognized: judicialActRecognizedHint({
					name: 'birth_place',
					value: bankrupt && bankrupt.birth_place,
				}),
			},
		},
		regAddress: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'reg_address_full',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Адрес регистрации должника',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
			value: bankrupt && bankrupt.reg_address_full,
			hintData: {
				judicial_act_recognized: judicialActRecognizedHint({
					name: 'reg_address_full',
					value: bankrupt && bankrupt.reg_address_full,
				}),
			},
		},
		mailingAddress: {
			disabled: !procedureId || !$procedureAvailable || mailingAddressCheck,
			attribute: 'mailing_address_full',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Почтовый адрес должника',
			placeholder: '100000, Московская область, г. Королев, ул. Вторая, д. 2, кв. 2',
			value: mailingAddressCheck ? bankrupt && bankrupt.reg_address_full : bankrupt && bankrupt.mailing_address_full,
			forceSave: mailingAddressCheck && procedureId,
		},
		factualAddress: {
			disabled: !procedureId || !$procedureAvailable || factualAddressCheck,
			attribute: 'factual_address_full',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Адрес фактического проживания должника',
			value: factualAddressCheck ? bankrupt && bankrupt.reg_address_full : bankrupt && bankrupt.factual_address_full,
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
			forceSave: factualAddressCheck && procedureId,
		},
		ogrnip: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'ogrnip',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'ОГРНИП',
			placeholder: '123456789012345',
			value: bankrupt.ogrnip,
			maskOptions: { mask: '000000000000000' },
			searchPath,
		},
		phone: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'phone',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Телефон должника',
			value: bankrupt && bankrupt.phone,
			placeholder: '+7 (___) ___-__-__',
			maskOptions: { mask: '+7 (000) 000-00-00' },
			modify: phone => phone && phone.replace(/^\+7|^8|\D/g, ''),
			searchPath,
		},
		email: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'email',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Электронная почта должника',
			value: bankrupt && bankrupt.email,
			placeholder: 'mail@mail.ru',
			searchPath,
		},
		mailingAddressCheck: {
			disabled: !procedureId || !$procedureAvailable,
			text: 'тот же, что и адрес регистрации',
		},
		factualAddressCheck: {
			disabled: !procedureId || !$procedureAvailable,
			text: 'тот же, что и адрес регистрации',
			additionalStyles: 'margin-top: -29px;',
		},
		individualEntrepreneurCheck: {
			disabled: !procedureId || !$procedureAvailable || hasOgrnip,
			attribute: 'individual_entrepreneur',
			model: 'person',
			url: '/api/private/persons',
			id: bankruptId,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Должник является ИП',
			value: bankrupt?.individual_entrepreneur,
			text: 'да, является',
			searchPath,
		},
		changedFullNameCheck: {
			disabled: !procedureId || !$procedureAvailable || hasPreviousFullName,
			label: 'Должник менял ФИО',
			text: 'да, менял',
		},
	};
</script>

<div class="m-b-sm overlay procedure__bankrupt-fields bankrupt-person-fields">
	<h3>{`${procedurePhase === 'debt_restructuring' ? 5 : 6}. Должник`}</h3>
	<AutosaveDadataInputFio {...formFields.fullName} />
	<Checkbox {...formFields.changedFullNameCheck} bind:checked={changedFullNameCheck} />
	{#if showPreviousFullName}
		<AutosaveDadataInputFio2 {...formFields.previousFullName} />
	{/if}
	<AutosaveInput {...formFields.inn} />
	<AutosaveInput {...formFields.snils} />
	<AutosaveInput {...formFields.passportNumber} />
	<AutosaveDadataInputPassportDepartment {...formFields.passportDepartment} />
	<AutosaveInput {...formFields.passportDepartmentCode} />
	<AutosaveDatepicker {...formFields.passportDate} />
	<AutosaveDatepicker {...formFields.birthDate} />
	<AutosaveInput {...formFields.birthPlace} />
	<AutosaveDadateAddress {...formFields.regAddress} />
	<AutosaveDadateAddress {...formFields.mailingAddress} />
	<Checkbox {...formFields.mailingAddressCheck} bind:checked={mailingAddressCheck} />
	<AutosaveDadateAddress {...formFields.factualAddress} />
	<Checkbox {...formFields.factualAddressCheck} bind:checked={factualAddressCheck} />
	<AutosaveCheckbox {...formFields.individualEntrepreneurCheck} />
	{#if showOgrnip}
		<AutosaveInput {...formFields.ogrnip} />
	{/if}
	<AutosaveInput {...formFields.phone} />
	<AutosaveDadateEmail {...formFields.email} />
	<SpousesBlock />
	<ChildrenBlock />
</div>
