<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveCheckbox from '../../../components/ui/autosave_checkbox.svelte';

	$: bankruptMarried = {
		...$commonParams,
		attribute: 'bankrupt_married',
		label: 'Должник в браке',
		value: $currentData && $currentData.bankruptMarried,
		text: 'Да',
	};
</script>

<AutosaveCheckbox {...bankruptMarried} on:update />
