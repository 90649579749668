<!-- @format -->
<script>
	import { currentData, commonParams, requiredFieldsFilled } from '~/svelte/dcts/stores/stores';
	import { transmitter } from '~/js/global_stores/base';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	$: not_modified = $currentData.meetingDateAt == $transmitter.meeting.date_at;
	$: data = {
		...$commonParams,
		disabled: $commonParams.disabled || true,
		attribute: 'meeting_date_at',
		label: 'Дата собрания',
		value: $currentData.meetingDateAt,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			freeMessage: not_modified ? 'AI заполнил из Параметры' : 'Изменено пользователем',
		},
	};
	$: $requiredFieldsFilled.date = !!data.value;
</script>

<AutosaveDatepicker {...data} />
