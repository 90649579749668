<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveInputCurrency from '~/svelte/components/ui/autosave_input_currency.svelte';

	$: livingWageForBankrupt = {
		...$commonParams,
		attribute: 'living_wage_for_bankrupt',
		label: 'Прожиточный минимум для должника (руб.)',
		value: $currentData && $currentData.livingWageForBankrupt,
		placeholder: '0,00',
	};
</script>

<AutosaveInputCurrency {...livingWageForBankrupt} />
