<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import MeasureForm from './_form.svelte';
	import { cardsTitle } from 'utils/formats';

	export let measureId;
	export let noMeasureSelected;
	export let newMeasureSelected;
	export let disabled = false;

	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};
</script>

<Modal
	modalId="form-measure-modal"
	deleteIconDisabled={!measureId || disabled}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	submitButtonDisabled={noMeasureSelected || disabled}
	form={true}
	size="large"
>
	<h2 slot="header" class="creditors_requirement-modal__header">{cardsTitle('меры', measureId)}</h2>
	<div slot="body">
		<MeasureForm {measureId} {disabled} bind:handleSubmit bind:handleCancel bind:handleDelete bind:newMeasureSelected bind:noMeasureSelected />
	</div>
</Modal>
