<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDadateAddress from '~/svelte/components/ui/autosave_dadate_input_address.svelte';

	$: arbitrManager = $procedure?.arbitr_manager;
	$: arbitrManagerId = arbitrManager?.id;
	$: sro = arbitrManager?.sro;
	$: disabled = $dct?.disabled_fields.includes('sroLegalAddress');

	$: sroLegalAddress = {
		...$commonParams,
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved' || !$currentData.sroId,
		attribute: 'sro_legal_address',
		label: 'Почтовый адрес СРО',
		value: $currentData?.sroLegalAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
		hintData: {
			url: `/arbitr_managers/${arbitrManagerId}`,
			linkText: 'АУ',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.sro_legal_address',
			resource: sro,
			resourceProp: 'legal_address',
		},
	};
</script>

<AutosaveDadateAddress {...sroLegalAddress} on:update />
