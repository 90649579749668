<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: isRegistrar = $currentData && $currentData.registrarChoice === 'registrar';

	$: registrar = {
		...$commonParams,
		attribute: 'registrar',
		label: 'Реестродержатель',
		value: $currentData && $currentData.registrar,
		placeholder: 'АО «Реестр»',
	};
</script>

{#if isRegistrar}
	<AutosaveInput {...registrar} />
{/if}
