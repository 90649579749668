<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveTextarea from '~/svelte/components/ui/autosave_textarea.svelte';

	$: hasWillfulBankruptcySigns = $currentData && $currentData.hasWillfulBankruptcySigns;

	$: willfulBankruptcySigns = {
		...$commonParams,
		attribute: 'willful_bankruptcy_signs',
		label: 'Признаки преднамеренного банкротства',
		value: $currentData && $currentData.willfulBankruptcySigns,
		placeholder: 'В ходе процедуры выявлены сделки, направленные на вывод активов Должника…',
	};
</script>

{#if hasWillfulBankruptcySigns}
	<AutosaveTextarea {...willfulBankruptcySigns} />
{/if}
