<!-- @format -->
<script>
	import { autosaveStatus, transmitter as transmitter0 } from 'base_stores';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import InputErrorsString from './input_errors_string.svelte';
	import { validate } from 'tools/validators';

	export let attribute;
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let label;
	export let required = false;
	export let disabled = false;
	export let transmitter;
	export let skipVerificationRequired = true;
	export let value;
	export let options = [];
	export let placeholder;
	export let additionalParams = {};
	export let saveAutomatically = true;

	let inputField;
	let dataErrors;

	$: method = !!id ? fetchPut : fetchPost;
	$: if (!!id) {
		url = `${url}/${id}`;
	}
	$: isSuccess = value && newValue && !dataErrors;
	$: newValue = (value && value.id) || null;
	$: hiddenPlaceholder = !!newValue;

	const handleChange = () => {
		$autosaveStatus = null;

		dataErrors = validate(model, attribute, newValue, skipVerificationRequired);

		if (!dataErrors) {
			let params = { [model]: { [attribute]: newValue }, [`${childModel}_id`]: childId, ...additionalParams };

			method(url, params)
				.then(response => {
					if (JSON.stringify(response) !== '{}') {
						$autosaveStatus = 'saved';
					}
					transmitter = { ...transmitter, ...response };
					$transmitter0 = { ...$transmitter0, ...response };
				})
				.catch(_errors => {
					inputField.focus();
					$autosaveStatus = 'not_saved';
				});
			return;
		}
		$autosaveStatus = 'not_saved';
	};
</script>

<div class="form-group row" class:has-error={dataErrors} class:has-success={isSuccess}>
	<label for={`select-${model}-${attribute}`} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<div class="select-wrapper">
			<div class="select-placeholder" class:hide-placeholder={isSuccess || hiddenPlaceholder}>
				{placeholder}
			</div>
			<select
				id={`select-${model}-${attribute}`}
				class:required-border={required}
				class:text-disabled={disabled}
				class="form-control"
				bind:value
				bind:this={inputField}
				{disabled}
				on:change={saveAutomatically && handleChange}
			>
				{#each options as option}
					<option value={option}>
						{option.text}
					</option>
				{/each}
			</select>
		</div>
		{#if dataErrors}
			<InputErrorsString errors={dataErrors} />
		{:else}
			<slot name="hint" />
		{/if}
	</div>
</div>
