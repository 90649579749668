<!-- @format -->
<script>
	import { currentData, commonParams, dct } from '~/svelte/dcts/stores/stores';
	import { derivedData } from '~/svelte/dcts/stores/analytics';
	import { isBlank } from 'utils/tools';
	import AutosaveTextarea from '~/svelte/components/ui/autosave_textarea.svelte';
	import IconReset from '~/svelte/_shared/icon-reset.svelte';

	let forceSave = false;

	$: isApproved = $dct && $dct.status === 'approved';
	$: value = $currentData.compositeText || $derivedData.compositeText;

	$: if (isBlank($currentData && $currentData.compositeText) && $dct.status === 'draft') {
		forceSave = true;
	} else {
		forceSave = false;
	}

	$: params = {
		...$commonParams,
		orientation: 'vertical',
		rows: 9,
		attribute: 'composite_text',
		label: 'Содержание сообщения',
		value: value,
		placeholder: '',
	};
</script>

<AutosaveTextarea {...params} on:update bind:forceSave>
	<span slot="icon" class="raf__with-tooltip float-right">
		<button
			class="btn btn-xs"
			class:btn-primary={!isApproved}
			class:btn-default={isApproved}
			disabled={isApproved}
			on:click={() => {
				value = $derivedData.compositeText;
				forceSave = true;
			}}
		>
			<IconReset />
		</button>
		{#if !isApproved}
			<div class="raf__tooltip">Сброс. Вернёт текст сообщения к исходному виду с обновлением данных из формы.</div>
		{/if}
	</span>
</AutosaveTextarea>

<style lang="scss">
	.raf__with-tooltip:hover .raf__tooltip {
		right: 0;
		bottom: calc(100% + 0.15rem);
		top: unset;
	}
</style>
