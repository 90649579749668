<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: if ($dct.form_fields.includes('creationPlace')) {
		$requiredFieldsFilled.creationPlace = !!creationPlace.value;
	}

	$: creationPlace = {
		required: true,
		...$commonParams,
		attribute: 'creation_place',
		label: 'Место составления',
		value: $currentData && $currentData.creationPlace,
		placeholder: 'г. Королёв',
		hintData:
			($procedure && $procedure.creation_place_docs) === ($currentData && $currentData.creationPlace)
				? {
						url: `/procedures/${procedureId}`,
						linkText: 'Процедура',
						consumerNew: $newObject,
						resource: $procedure,
						resourceProp: 'creation_place_docs',
					}
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveInput {...creationPlace} />
