<!-- @format -->
<script>
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: if ($dct.form_fields.includes('exArbitrManagerFullName') && show) {
		$requiredFieldsFilled.exArbitrManagerFullName = !!exArbitrManagerFullName.value;
	}

	$: show = $currentData.transferringDocumentsParty === 'ex_arbitr_manager';

	$: exArbitrManagerFullName = {
		required: true,
		...$commonParams,
		attribute: 'ex_arbitr_manager_full_name',
		label: 'ФИО бывшего АУ',
		value: show ? $currentData && $currentData.exArbitrManagerFullName : null,
		placeholder: 'Фамилия Имя Отчество',
	};
</script>

{#if show}
	<AutosaveInput {...exArbitrManagerFullName} />
{/if}
