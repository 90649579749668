<!-- @format -->
<script>
	import { procedure, options, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '../../stores/stores';
	import AutosaveSelect2Search from '../../../components/ui/autosave_select2-search.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: currentBankruptCategoryId = $currentData && $currentData.bankruptCategoryId;

	$: bankruptCategoryId = {
		...$commonParams,
		attribute: 'bankrupt_category_id',
		childModel: 'procedure',
		childId: $procedure && $procedure.id,
		label: 'Категория должника',
		value: $options.bankruptCategory.find(option => parseInt(option.id) === parseInt(currentBankruptCategoryId)),
		options: $options.bankruptCategory,
		placeholder: 'Выбрать категорию',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.bankrupt_category_id',
			resource: $procedure && $procedure.bankrupt,
			resourceProp: 'category_id',
		},
	};
</script>

<AutosaveSelect2Search {...bankruptCategoryId} on:update />
