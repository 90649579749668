<!-- @format -->
<script>
	import { currentData, commonParams } from '../../../stores/stores';
	import AutosaveInput from '../../../../components/ui/autosave_input.svelte';

	$: publicationFedresursCopiesNumber = {
		...$commonParams,
		attribute: 'publication_fedresurs_copies_number',
		label: 'Публикация «Федресурс» - количество экземпляров',
		placeholder: 'Введите количество экземпляров',
		value: $currentData && $currentData.publicationFedresursCopiesNumber,
	};
</script>

<AutosaveInput {...publicationFedresursCopiesNumber} />
