<!-- @format -->
<script>
	import { currentData, commonParams } from '../../../stores/stores';
	import AutosaveInput from '../../../../components/ui/autosave_input.svelte';

	$: publicationFedresursPagesNumber = {
		...$commonParams,
		attribute: 'publication_fedresurs_pages_number',
		label: 'Публикация «Федресурс» - количество листов',
		placeholder: 'Введите количество листов',
		value: $currentData && $currentData.publicationFedresursPagesNumber,
	};
</script>

<AutosaveInput {...publicationFedresursPagesNumber} />
