<!-- @format -->
<script>
	import { transmitter } from '~/js/global_stores/base';
	import { currentData, requiredFieldsFilled } from '../../stores/stores';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import { toArray } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	let items = [];

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingQuestionId');
		disabled =
			toArray($transmitter.meeting.disabled_fields).includes('meetingQuestionId') ||
			$transmitter.meeting.bulletin_status == 'approved' ||
			$transmitter.meeting.status == 'canceled';
		items = toArray($transmitter.meeting.questions).map(e => ({ value: e.id, label: e.name }));
	}
	$: $requiredFieldsFilled.meetingQuestionId = !!$currentData.meetingQuestionId;
</script>

{#if visible}
	<Field value={$currentData.meetingQuestionId} required={true} name="meetingQuestionId" label="Вопрос" {disabled}>
		<Select
			{items}
			name="meetingQuestionId"
			isClearable={false}
			isDisabled={disabled}
			value={$currentData.meetingQuestionId}
			containerClasses="form-control"
			placeholder="Выберите вопрос"
		></Select>
	</Field>
{/if}
