<!-- @format -->
<script>
	import { options } from 'base_stores';
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import { onMount } from 'svelte';
	import AutosaveRadioSelect from '~/svelte/components/ui/autosave_radio_select.svelte';

	const optionsRequestObjects = [
		{ id: 'bankrupt', text: 'должник' },
		{ id: 'spouses', text: 'супруги должника' },
		{ id: 'bankrupt_and_spouses', text: 'должник и супруги' },
	];

	$: requestObjects = {
		...$commonParams,
		attribute: 'request_objects',
		label: 'Объект запроса',
		value: ($options.requestObjects || optionsRequestObjects).find(option => option.id === $currentData.requestObjects),
		options: $options.requestObjects || optionsRequestObjects,
	};

	onMount(() => {
		$options = { ...$options, requestObjects: optionsRequestObjects };
	});
</script>

<AutosaveRadioSelect {...requestObjects} />
