<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import TariffForm from './_form.svelte';

	export let tariff;
	export let bankruptTypeOptions;
	export let procedurePhasesOptions;

	let handleSubmit = () => {};
	let handleCancel = () => {};
</script>

<Modal modalId="form-tariff-modal" deleteIconDisabled={!tariff} submitButtonAction={handleSubmit} cancelButtonAction={handleCancel} form={false} size="large">
	<h2 slot="header" class="tariff-modal__header">{`${!!tariff ? 'Редактирование' : 'Создание'} тарифа`}</h2>
	<div slot="body">
		<TariffForm {tariff} {bankruptTypeOptions} {procedurePhasesOptions} bind:handleSubmit bind:handleCancel on:submit />
	</div>
</Modal>
