<!-- @format -->
<!-- @format -->
<script>
	import Header from '~/svelte/components/header.svelte';
	import Table from './table.svelte';
	import { syncedWithGoogleCalendar } from './store';
</script>

<Header>
	<div slot="name">
		<div class="calendar-title m-r-sm">
			Календарь
			{#if $syncedWithGoogleCalendar}
				<div class="synchronization-message float-left m-l-sm">синхронизируется с календарём Google</div>
			{/if}
		</div>
	</div>
</Header>
<Table />

<style lang="scss">
	.calendar-title {
		height: 32px;
		float: left;
		display: flex;
		align-items: center;
	}

	.synchronization-message {
		margin-top: 0.2rem;
		font-size: 0.7em;
		color: #d68a37;
	}
	@media (max-width: 576px) {
		.synchronization-message {
			white-space: break-spaces;
			margin-right: 5px;
		}
	}
</style>
