/** @format */

const comfyFetch = async (url, options = {}) => {
	if (!options.method) {
		options.method = 'get';
	}

	options.method = options.method.toUpperCase();

	if (!options.credentials) {
		options.credentials = 'same-origin';
	}

	const defaultHeaders = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Access-Control-Allow-Origin': '*',
	};

	options.headers = Object.assign({}, defaultHeaders, options.headers);

	if (options.params != null && options.body == null) {
		if (options.headers['Content-Type'] === 'application/json') {
			if (options.method === 'GET') {
				url = `${url}?${encodeParams(options.params)}`;
			} else {
				options.body = JSON.stringify(options.params);
			}
		}
	}

	const response = await fetch(url, options);
	return await parseResponse(response);
};

// Функция для преобразования вложенных параметров в строку запроса
// Вход: encodeParams( { 'a': { 'b': [1, 2] } } )
// Выход: 'a[b][]=1&a[b][]=2'
export function encodeParams(obj, prefix = '') {
	const paramsArray = [];

	for (const key in obj) {
		if (Object.hasOwn(obj, key)) {
			const value = obj[key];
			const paramName = prefix ? `${prefix}[${key}]` : key;

			if (Array.isArray(value)) {
				value.forEach(item => {
					paramsArray.push(`${paramName}[]=${item}`);
				});
			} else if (typeof value === 'object' && value !== null) {
				paramsArray.push(encodeParams(value, paramName));
			} else if (value) {
				paramsArray.push(`${paramName}=${value}`);
			}
		}
	}

	return paramsArray.join('&');
}

const parseResponse = async response => {
	detectServerError(response);

	const responseJSON = await response.json();

	if (response.ok) {
		return responseJSON;
	}

	return Promise.reject(responseJSON);
};

const detectServerError = result => {
	const errors = {
		401: 'Вы должны сперва войти в систему',
		403: 'У Вас нет прав на эту операцию',
		404: 'Данные не найдены',
		504: 'Сервер недоступен. Пожалуйста попробуйте немного позже',
		999: 'Запрос содержит неэкранированные символы',
	};

	if (result.ok || result.status === 204) {
		return result;
	} else {
		throw { error: true, status: result.status, message: errors[result.status] || result.statusText };
	}
};

export const fetchGet = async (url, params) => {
	return await comfyFetch(url, { method: 'get', params });
};

export const fetchPut = async (url, params) => {
	return await comfyFetch(url, { method: 'put', params });
};

export const fetchPost = async (url, params) => {
	return await comfyFetch(url, { method: 'post', params });
};

export const fetchDelete = async (url, params) => {
	return await comfyFetch(url, { method: 'delete', params });
};

export default { fetchGet, fetchPut, fetchPost, fetchDelete };
