<!-- @format -->
<script>
	import { transmitter, options } from 'base_stores';
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch';
	import Input from '~/svelte/components/ui/input.svelte';
	import InputCurrency from '~/svelte/components/ui/input_currency.svelte';
	import Select2 from '~/svelte/components/ui/select2.svelte';
	import { hideModal } from '~/svelte/components/modal.svelte';
	import { showModal } from '~/svelte/components/modal.svelte';
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte';

	export let disabled = false;
	export let undeclaredDebtId;
	export let disableSubmitButton;
	export let dataChanged;
	export let copyUndeclaredDebtId = null;

	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;
	export let handleCopyAndSubmit;

	const nullUndeclaredDebt = {
		id: null,
		counterparty_id: null,
		kind: null,
		base_document: null,
		amount: null,
	};

	let tempCounterparty = null;
	let counterpartyId;
	let undeclaredDebt = { ...nullUndeclaredDebt };
	let copyUndeclaredDebt = { ...nullUndeclaredDebt };
	let copySaved = false;

	$: method = undeclaredDebtId ? fetchPut : fetchPost;

	$: counterparties = $transmitter && $transmitter.counterparties;
	$: newCounterparty = counterparties && counterparties[counterparties.length - 1];

	$: if (newCounterparty && !$options.counterpartyCreditor.find(option => newCounterparty.id == option.id)) {
		$options.counterpartyCreditor.splice(-1, 0, { id: newCounterparty.id, text: newCounterparty.name });
		undeclaredDebt.counterparty_id = newCounterparty.id;
		delete $transmitter.counterparties;
	}

	$: disableSubmitButton = !undeclaredDebt.counterparty_id || !undeclaredDebt.amount;

	$: if ($transmitter && $transmitter.counterpartyFormCanceled) {
		undeclaredDebt.counterparty_id = tempCounterparty;
		$transmitter.counterpartyFormCanceled = false;
		tempCounterparty = null;
	}

	$: if (undeclaredDebtId && undeclaredDebtId !== undeclaredDebt.id) {
		fetchGet(`/api/private/undeclared_debts/${undeclaredDebtId}`).then(response => (undeclaredDebt = { ...response.undeclared_debt }));
	}

	$: if (copyUndeclaredDebtId && !copySaved) {
		undeclaredDebtId = null;

		fetchGet(`/api/private/undeclared_debts/${copyUndeclaredDebtId}`).then(response => {
			undeclaredDebt = { ...response.undeclared_debt };
			copySaved = true;
		});
	}

	$: if (undeclaredDebt) {
		dataChanged = false;

		for (let key in undeclaredDebt) {
			if (undeclaredDebt[key] !== copyUndeclaredDebt[key]) {
				dataChanged = true;
			}
		}
	}

	$: formFields = {
		counterpartyId: {
			required: true,
			attribute: `counterparty_id`,
			model: 'undeclared_debt',
			label: 'Кредитор',
			options: $options.counterpartyCreditor,
			placeholder: 'Выбрать кредитора',
			modify: 'showModalCounterpartyForm',
			disabled,
		},
		kind: {
			model: 'undeclared_debt',
			attribute: 'kind',
			placeholder: 'Вид обязательства',
			label: 'Вид обязательства',
			disabled,
		},
		baseDocument: {
			model: 'undeclared_debt',
			attribute: 'document_сause',
			placeholder: 'Договор №0 от 00.00.0000 г.',
			label: 'Документ-основание возникновения',
			disabled,
		},
		amount: {
			required: true,
			attribute: 'amount',
			model: 'undeclared_debt',
			label: 'Размер долга (руб.)',
			placeholder: '0,00',
			disabled,
		},
	};

	handleSubmit = () => {
		undeclaredDebt = { ...undeclaredDebt, amount: undeclaredDebt.amount ? undeclaredDebt.amount.replace(/,/g, '.') : 0 };

		return method(`/api/private/undeclared_debts${undeclaredDebtId ? `/${undeclaredDebtId}` : ''}`, { undeclared_debt: undeclaredDebt }).then(response => {
			$transmitter = { ...$transmitter, undeclaredDebts: response.undeclared_debts };
			clearUndeclaredDebt();
			hideModal('form-undeclared_debt-modal');
			copyUndeclaredDebtId = null;
		});
		//.catch(errors => console.error(errors) )
	};

	const openCounterpartyModalForm = id => {
		counterpartyId = id;
		showModal('form-counterparty-modal');
	};

	handleCancel = () => {
		if (copySaved) {
			undeclaredDebtId = copyUndeclaredDebtId;
			copyUndeclaredDebtId = null;
			copySaved = false;
		}
		clearUndeclaredDebt();
	};

	handleDelete = () => {
		return fetchDelete(`/api/private/undeclared_debts/${undeclaredDebtId}`).then(response => {
			$transmitter = { ...$transmitter, undeclaredDebts: response.undeclared_debts };
			copyUndeclaredDebtId = null;
		});
		//.catch(errors => console.log('errors: ' + JSON.stringify(errors)))
	};

	handleCopyAndSubmit = () => {
		copyUndeclaredDebtId = undeclaredDebtId;
		hideModal('form-undeclared_debt-modal');
		setTimeout(_ => showModal('form-undeclared_debt-modal'), 1000);
	};

	const clearUndeclaredDebt = () => (dataChanged = false);

	const showModalCounterpartyForm = value => {
		if (value === 'new') {
			tempCounterparty = undeclaredDebt.counterparty_id;
			openCounterpartyModalForm();
		}
	};
</script>

<Select2 {...formFields.counterpartyId} bind:value={undeclaredDebt.counterparty_id} modify={showModalCounterpartyForm} />
<Input {...formFields.kind} bind:value={undeclaredDebt.kind} />
<Input {...formFields.baseDocument} bind:value={undeclaredDebt.base_document} />
<InputCurrency {...formFields.amount} bind:value={undeclaredDebt.amount} />
<CounterpartyModal {counterpartyId} fixedRoles={['creditor']} />
