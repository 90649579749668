<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { currentData } from '../../stores/stores';
	import { transmitter } from '~/js/global_stores/base';
	import InputSnils from '~/svelte/components/ui/input_snils.svelte';
	import { toArray } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	const handleChange = e =>
		dispatch('change', {
			model: 'meeting_participant',
			id: $currentData.meetingParticipantId,
			attribute: 'snils',
			value: e.target.value,
		});

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = $currentData.meetingParticipantIsPerson && !toArray($transmitter.meeting.hidden_fields).includes('meetingParticipantSnils');
		disabled =
			toArray($transmitter.meeting.disabled_fields).includes('meetingParticipantSnils') ||
			$transmitter.meeting.bulletin_status == 'approved' ||
			$transmitter.meeting.status == 'canceled';
	}
</script>

{#if visible}
	<Field value={$currentData.meetingParticipantSnils} name="meetingParticipantSnils" label="СНИЛС участника" {disabled}>
		<InputSnils name="meetingParticipantSnils" value={$currentData.meetingParticipantSnils} {disabled} onchange={handleChange} klass="form-control" />
	</Field>
{/if}
