<!-- @format -->
<script lang="ts" context="module">
	const uuid = (prefix: string): string => {
		return prefix + '_' + Math.floor(Math.random() * 1000000000) + String(Date.now());
	};

	const createScriptLoader = () => {
		let state = {
			listeners: [],
			scriptId: uuid('tiny-script'),
			scriptLoaded: false,
			injected: false,
		};

		const injectScript = (scriptId: string, doc: Document, url: string, cb: () => void) => {
			state.injected = true;
			const script = doc.createElement('script');
			script.referrerPolicy = 'origin';
			script.type = 'application/javascript';
			script.src = url;
			script.onload = () => {
				cb();
			};
			if (doc.head) doc.head.appendChild(script);
		};

		const load = (doc: Document, url: string, callback: () => void) => {
			if (state.scriptLoaded) {
				callback();
			} else {
				state.listeners.push(callback);
				// check we can access doc
				if (!state.injected) {
					const inDoc = doc;
					injectScript(state.scriptId, inDoc, url, () => {
						state.listeners.forEach(fn => fn());
						state.scriptLoaded = true;
					});
				}
			}
		};

		return { load };
	};

	let scriptLoader = createScriptLoader();
</script>

<script lang="ts">
	import { createEventDispatcher, onDestroy } from 'svelte';
	import { bindHandlers } from './Utils.js';
	import { isPresent } from '../../../js/utils/tools.js'; // ts однако
	import mammoth from './mammoth.browser.min.js';

	export let id: string = uuid('tinymce-svelte'); // default values
	export let disabled: boolean = false;
	export let conf: any = {};
	export let modelEvents: string = 'change input undo redo';
	export let value: string = '';
	export let text: string = '';
	export let cssClass: string = '';
	export let toxEditorHeaderHeight = 0;
	export let refreshEditor = false;

	let container: HTMLElement;
	let element: HTMLElement;
	let editorRef: any;

	let lastVal = value;
	let disablindCache = disabled;
	let toxEditorHeader: any;

	const dispatch = createEventDispatcher();

	$: {
		if (editorRef && lastVal !== value) {
			editorRef.setContent(value);
			text = editorRef.getContent({ format: 'text' });
		}

		if (editorRef && disabled !== disablindCache) {
			disablindCache = disabled;
			if (typeof editorRef.mode?.set === 'function') {
				editorRef.mode.set(disabled ? 'readonly' : 'design');
			} else {
				editorRef.setMode(disabled ? 'readonly' : 'design');
			}
		}
	}

	$: if (toxEditorHeader) {
		toxEditorHeaderHeight = toxEditorHeader.offsetHeight;
	}

	$: if (refreshEditor && container) {
		refreshEditor = false;
		getTinymce()?.remove(editorRef);
		initTinymce();
	}

	const getTinymce = () => {
		const getSink = () => (typeof window !== 'undefined' ? window : global);
		const sink = getSink();
		return sink && sink.tinymce ? sink.tinymce : null;
	};

	const init = () => {
		const finalInit = {
			...conf,
			target: element,
			inline: false,
			readonly: disabled,
			setup: (editor: any) => {
				editorRef = editor;

				editor.on('init', () => {
					editor.setContent(value);
					// bind model events
					editor.on(modelEvents, () => {
						lastVal = editor.getContent();
						if (lastVal !== value) {
							value = lastVal;
							text = editor.getContent({ format: 'text' });
						}
					});
				});
				bindHandlers(editor, dispatch);
				editor.on('PostRender', () => {
					toxEditorHeader = document.getElementsByClassName('tox-editor-header')?.item(0);
				});
				editor.on('rafOpenFileRead', async data => {
					/* не понятно, как в tinymce внедрить внешнюю библиотеку, поэтому обработка скачанного текста вынесена наружу */
					if (data.rafOpenFileType == 'text/html') {
						editor.rafOpenFileText = data.rafOpenFileText;
					} else if (data.rafOpenFileType == 'text/plain') {
						editor.rafOpenFileText = data.rafOpenFileText.replace(/\n/g, '<br/>');
					} else {
						const result = await mammoth.convertToHtml({ arrayBuffer: data.rafOpenFileText });
						editor.rafOpenFileText = result.value;
					}
				});
				if (typeof conf.setup === 'function') {
					conf.setup(editor);
				}
			},
		};
		if (isPresent(element)) {
			element.style.visibility = '';
		}
		getTinymce().init(finalInit);
	};

	const initTinymce = () => {
		if (getTinymce() !== null) {
			init();
		} else {
			const script = `${location.origin}/js/wysiwyg_editors/tinymce/tinymce.min.js`;
			scriptLoader.load(container.ownerDocument, script, () => {
				init();
			});
		}
	};

	onDestroy(() => {
		if (editorRef) {
			getTinymce()?.remove(editorRef);
		}
	});
</script>

<div bind:this={container} class={['tinymce-wrapper', cssClass].join(' ')}>
	<textarea {id} bind:this={element} style="visibility:hidden"></textarea>
</div>

<style lang="scss">
	:global(.editing__page .tox .tox-editor-header) {
		width: var(--editor-header-width);
		margin-left: var(--editor-header-margin-left);
	}
	:global(.editing__page .tox .tox-sidebar-wrap) {
		margin-top: var(--editor-header-height);
	}
</style>
