<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveCheckbox from '~/svelte/components/ui/autosave_checkbox.svelte';

	$: hasBeenExpensesFundsDepositedIntoCourtDeposit = {
		...$commonParams,
		attribute: 'has_been_expenses_funds_deposited_into_court_deposit',
		label: 'Денежные средства на расходы внесены на депозит суда',
		value: $currentData?.hasBeenExpensesFundsDepositedIntoCourtDeposit,
		text: 'Да',
	};
</script>

<AutosaveCheckbox {...hasBeenExpensesFundsDepositedIntoCourtDeposit} on:update />
