<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import Checkbox from '../../../components/ui/autosave_checkbox.svelte';

	$: includeInReport = {
		...$commonParams,
		attribute: 'include_in_report',
		label: 'Включать в Отчеты АУ как уведомление/запрос',
		value: $currentData.includeInReport,
		text: 'Да',
	};
</script>

<Checkbox {...includeInReport} on:update />
