<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInputCurrency from '~/svelte/components/ui/autosave_input_currency.svelte';

	$: creditorsFirstMeetingParticipantsClaimsAmount = {
		...$commonParams,
		attribute: 'creditors_first_meeting_participants_claims_amount',
		label: 'Сумма требований участников 1-го собрания кредиторов (руб.)',
		value: $currentData && $currentData.creditorsFirstMeetingParticipantsClaimsAmount,
		placeholder: '0,00',
		inputCssClasses: 'small-input',
	};
</script>

<AutosaveInputCurrency {...creditorsFirstMeetingParticipantsClaimsAmount} />
