<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import SearchBy from '~/svelte/components/ui/dadata/search_by.svelte';

	const dispatch = createEventDispatcher();

	export let attribute;
	export let model;
	export let label;
	export let value;
	export let placeholder;
	export let required = false;
	export let disabled = false;
	export let errors;
	export let hintData;

	const inputId = `dadata-address-${model}-${attribute}`; //-${(+new Date()).toString(16)}`

	$: isSuccess = value && !errors;

	const setValue = ({ detail: v }) => {
		value = v;
		dispatch('change', v);
	};
</script>

<div class="form-group row" class:has-error={errors} class:has-success={isSuccess}>
	<label for={inputId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<SearchBy
			method="address"
			id={inputId}
			requiredborder={required && !value}
			disabledtext={disabled}
			{placeholder}
			bind:value
			{disabled}
			on:change={setValue}
			autocomplete="off"
		/>

		{#if errors}
			<InputErrorsString {errors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>
