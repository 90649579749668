<!-- @format -->
<script>
	import Case from 'case';
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	export let attribute;
	export let label;
	export let placeholder;
	export let validates;

	$: fieldName = {
		...$commonParams,
		attribute: Case.snake(attribute),
		label: label,
		value: $currentData && $currentData[attribute],
		placeholder: placeholder,
		validates: validates,
	};
</script>

<AutosaveInput {...fieldName} />
