/** @format */

import { get } from 'svelte/store';
import { transmitter, procedure } from 'base_stores';
import Model from 'models/Model';
import { proceduresApi } from 'tools/service_api';
import { isPresent } from 'utils/tools';

export default class Procedure extends Model {
	constructor(json) {
		super(json);
	}

	// class methods

	static subStoreName() {
		return 'procedures';
	}

	static async loadProcedure(procedureId) {
		const response = await proceduresApi.show(procedureId);
		const procedure_json = isPresent(response.procedure) ? JSON.parse(response.procedure) : null;

		transmitter.set({ ...get(transmitter), procedure: procedure_json });
		procedure.set(procedure_json);

		return procedure_json;
	}
}
