<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter } from 'base_stores';
	import { fetchPut } from 'utils/fetch_helpers';
	import { isArray, isPresent, isBlank } from 'utils/tools';
	import Input from '../components/ui/input.svelte';

	let efrsbLogin;
	let efrsbPassword;
	let arbitrManager;
	let arbitrManagerId;
	let loading = false;
	let errors = {};
	let successAlert = false;

	$: formFields = {
		login: {
			required: true,
			attribute: 'efrsb_login',
			model: 'arbitr_manager',
			url: '/api/private/arbitr_managers',
			id: arbitrManagerId,
			label: 'Логин',
			placeholder: 'Login',
			errors: errors && errors.efrsb_login,
		},
		password: {
			required: true,
			attribute: 'efrsb_password',
			model: 'arbitr_manager',
			url: '/api/private/arbitr_managers',
			id: arbitrManagerId,
			label: 'Пароль',
			placeholder: 'Password12345',
			errors: errors && errors.efrsb_password,
			type: 'password',
		},
	};

	const handleSubmit = async () => {
		successAlert = false;
		errors = {};

		if (!efrsbLogin || !efrsbPassword) {
			if (!efrsbLogin) {
				errors.efrsb_login = 'Не может быть пустым';
			}
			if (!efrsbPassword) {
				errors.efrsb_password = 'Не может быть пустым';
			}
			return;
		}

		try {
			loading = true;
			const result = await fetchPut(`/api/private/arbitr_managers/${arbitrManagerId}/update_efrsb_account`, {
				efrsb_login: efrsbLogin,
				efrsb_password: efrsbPassword,
			});
			if (isPresent(result.errors)) {
				errors = isPresent(Object.keys(result.errors).filter(k => ['efrsb_login', 'efrsb_password'].includes(k)))
					? result.errors
					: Object.values(result.errors).flat();
			} else {
				$transmitter = { ...$transmitter, ...result };
				successAlert = true;
			}
		} catch (e) {
			errors = e;
			throw e;
		} finally {
			loading = false;
		}
	};

	onMount(() => {
		arbitrManager = ($transmitter && $transmitter.arbitr_manager) || arbitrManager;
		arbitrManagerId = (arbitrManager && arbitrManager.id) || null;
		efrsbLogin = arbitrManager && arbitrManager.efrsb_login;
		efrsbPassword = arbitrManager && arbitrManager.efrsb_password;
	});
</script>

<div class="m-b-xxl overlay footer-margin">
	<h3>5. Учётная запись ЕФРСБ</h3>
	<Input {...formFields.login} bind:value={efrsbLogin} disabled={!arbitrManagerId} />
	<Input {...formFields.password} bind:value={efrsbPassword} disabled={!arbitrManagerId} />
	{#if (isPresent(errors) && isBlank(errors.efrsb_login) && isBlank(errors.efrsb_password)) || successAlert}
		<div class="form-group alert" class:alert-success={successAlert} class:alert-danger={!successAlert}>
			{#if successAlert}
				Учётная запись проверена и сохранена
			{:else if isArray(errors)}
				<ul>
					{#each errors as message}<li>{message}</li>{/each}
				</ul>
			{:else}
				{JSON.stringify(errors)}
			{/if}
			<button
				type="button"
				class="close"
				data-dismiss="alert"
				on:click={() => {
					errors = {};
					successAlert = false;
				}}
			>
				<span>&times;</span>
			</button>
		</div>
	{/if}
	<button class="btn btn-xs btn-warning cursor_pointer" disabled={!arbitrManagerId || loading} on:click={handleSubmit}>
		Сохранить данные ЕФРСБ
		{#if loading}
			<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
		{/if}
	</button>
</div>

<style lang="scss">
	.alert {
		ul {
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;

			li {
				width: calc(100% - 1rem);
			}
		}

		.close {
			position: absolute;
			right: 0.8rem;
			top: 0.5rem;
		}
	}
</style>
