<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInputCurrency from '../../components/ui/autosave_input_currency.svelte';

	$: marketPropertyValue = {
		...$commonParams,
		attribute: 'market_property_value',
		label: 'Рыночная стоимость имущества (руб.)',
		value: $currentData && $currentData.marketPropertyValue,
		placeholder: '0,00',
		inputCssClasses: 'small-input',
	};
</script>

<AutosaveInputCurrency {...marketPropertyValue} />
