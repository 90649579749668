/** @format */

import { get } from 'svelte/store';
import Procedure from 'models/Procedure';
import { getCookie, setCookie } from 'utils/cookies';
import { procedure, transmitter, bankruptType, options, newObject } from 'base_stores';
import { isBlank, isInteger } from 'utils/tools';
import moment from 'moment';

const setBankruptType = () => {
	const currentProcedure = get(procedure);

	bankruptType.set(currentProcedure?.bankrupt_type);
};

export const initializeData = async data => {
	let procedure_json = data.procedure;
	delete data.procedure;

	if (isBlank(procedure_json) || procedure_json === 'null' || isBlank(JSON.parse(procedure_json))) {
		const procedureId = getCookie('_rafProcedureId');

		if (isInteger(procedureId)) {
			procedure_json = await Procedure.loadProcedure(procedureId);
			setBankruptType();
		}
	} else {
		procedure_json = JSON.parse(procedure_json);
		setCookie('_rafProcedureId', procedure_json.id, { expires: moment().add(1, 'months').endOf('day').toDate() });
		transmitter.set({ ...get(transmitter), procedure: procedure_json });
		procedure.set(procedure_json);
		setBankruptType();
	}

	if (data.options) {
		const optionsObject = JSON.parse(data.options);
		let preparedOptions = {};
		Object.keys(optionsObject).forEach(key => {
			if (key !== 'correspondent') {
				preparedOptions[key] = optionsObject[key];
			} else {
				preparedOptions.correspondent = {};
				Object.keys(optionsObject.correspondent).forEach(correspondentKey => {
					preparedOptions.correspondent[correspondentKey] = {};
					Object.keys(optionsObject.correspondent[correspondentKey]).forEach(regionKey => {
						preparedOptions.correspondent[correspondentKey][regionKey] = optionsObject.correspondent[correspondentKey][regionKey];
					});
				});
			}
		});
		options.set(preparedOptions);
		delete data.options;
	}

	if (data.new && data.new === 'true') {
		newObject.set(true);
		delete data.new;
	}

	for (let key of ['dcts', 'tags', 'events', 'pagination', 'report', 'answer', 'arbitr_manager', 'mail_registry', 'dcts_meta_data', 'bank_accounts']) {
		if (data[key]) {
			transmitter.set({ ...get(transmitter), [key]: JSON.parse(data[key]) });
			delete data[key];
		}
	}

	return data;
};
