<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: name = {
		...$commonParams,
		attribute: 'name',
		label: 'Название документа',
		value: $currentData.name || 'Документ свободной формы',
		placeholder: 'Введите название документа',
	};
</script>

<AutosaveInput {...name} on:update />
