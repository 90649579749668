<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('number');

	$: number = {
		...$commonParams,
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		attribute: 'number',
		label: 'Номер документа',
		value: $currentData.number,
		placeholder: 'Введите номер документа',
	};
</script>

<AutosaveInput {...number} on:update />
