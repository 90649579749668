<!-- @format -->
<svg
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	aria-hidden="true"
	focusable="false"
	width="16"
	height="16"
	style="transform: rotate(360deg);"
	preserveAspectRatio="xMidYMid meet"
	viewBox="0 0 256 256"
	data-icon="ph:paperclip"
	class="iconify"
>
	<path
		d="M209.7 122.3a8.1 8.1 0 0 1 0 11.4l-82.1 81.9a56 56 0 0 1-79.2-79.2l99.3-100.6a40 40 0 1 1 56.6 56.5L105 192.9a23.8 23.8 0 0 1-17 7.1a24 24 0 0 1-17-41l83.3-84.6a8 8 0 1 1 11.4 11.2l-83.3 84.7A8 8 0 0 0 80 176a8.1 8.1 0 0 0 2.3 5.7A8.3 8.3 0 0 0 88 184a8.1 8.1 0 0 0 5.7-2.3L192.9 81a23.8 23.8 0 0 0 7.1-17a24 24 0 0 0-41-17L59.8 147.7a40 40 0 1 0 56.5 56.6l82-82a8.1 8.1 0 0 1 11.4 0z"
		fill="currentColor"
	>
	</path>
</svg>
