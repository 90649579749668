<!-- @format -->
<div class="row">
	<div class="col-sm-4"><strong>Первая очередь:</strong></div>
	<div class="col-sm-8 m-b-xs">
		Требования по текущим платежам, связанным с судебными расходами по делу о банкротстве, выплатой вознаграждения арбитражному управляющему, с взысканием
		задолженности по выплате вознаграждения лицам, исполнявшим обязанности арбитражного управляющего в деле о банкротстве, требования по текущим платежам,
		связанным с оплатой деятельности лиц, привлечение которых арбитражным управляющим для исполнения возложенных на него обязанностей в деле о банкротстве в
		соответствии с Федеральным законом «О несостоятельности (банкротстве)» является обязательным, в том числе с взысканием задолженности по оплате деятельности
		указанных лиц.
	</div>
	<div class="col-sm-4"><strong>Вторая очередь:</strong></div>
	<div class="col-sm-8 m-b-xs">
		Требования об оплате труда лиц, работающих или работавших (после даты принятия заявления о признании должника банкротом) по трудовому договору, требования о
		выплате выходных пособий.
	</div>
	<div class="col-sm-4"><strong>Третья очередь:</strong></div>
	<div class="col-sm-8">
		Требования об оплате деятельности лиц, привлеченных арбитражным управляющим для обеспечения исполнения возложенных на него обязанностей в деле о
		банкротстве, в том числе о взыскании задолженности по оплате деятельности этих лиц, за исключением лиц, указанных в абзаце втором пункта 2 статьи 134
		Федерального закона «О несостоятельности (банкротстве)».
	</div>
	<div class="col-sm-4"><strong>Четвертая очередь:</strong></div>
	<div class="col-sm-8">Требования по коммунальным платежам, эксплуатационным платежам, необходимым для осуществления деятельности должника.</div>
	<div class="col-sm-4"><strong>Пятая очередь:</strong></div>
	<div class="col-sm-8">Требования по иным текущим платежам.</div>
</div>
