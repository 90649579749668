<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: publicationNewspaperPagesNumber = {
		...$commonParams,
		attribute: 'publication_newspaper_pages_number',
		label: 'Объявление из газеты «Коммерсантъ» - количество листов',
		placeholder: 'Введите количество листов',
		value: $currentData && $currentData.publicationNewspaperPagesNumber,
	};
</script>

<AutosaveInput {...publicationNewspaperPagesNumber} />
