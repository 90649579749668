<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDadateAddress from '~/svelte/components/ui/autosave_dadate_input_address.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: if ($dct.form_fields.includes('courtMailingAddress')) {
		$requiredFieldsFilled.courtMailingAddress = !!courtMailingAddress.value;
	}

	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('courtMailingAddress');

	$: courtMailingAddress = {
		...$commonParams,
		required: true,
		disabled: true, //$commonParams.disabled || disabled || $dct.status === 'approved',
		attribute: 'court_mailing_address',
		label: 'Адрес суда (АС)',
		value: $currentData && $currentData.courtMailingAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.court_mailing_address',
			resource: $procedure && $procedure.arbitr_case,
			resourceProp: 'court_mailing_address',
			fieldDisabled: true,
		},
	};
</script>

<AutosaveDadateAddress {...courtMailingAddress} on:update />
