<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveCheckbox from '~/svelte/components/ui/autosave_checkbox.svelte';

	$: outgoingRequestCorrespondentKindIsBankrupt = $currentData && $currentData.outgoingRequestCorrespondentKind === 'bankrupt';

	$: bankruptLeaderRequestReceived = {
		...$commonParams,
		attribute: 'bankrupt_leader_request_received',
		label: 'Запрос получен руководителем должника',
		value: $currentData && $currentData.bankruptLeaderRequestReceived,
		text: 'Да, получен',
	};
</script>

{#if outgoingRequestCorrespondentKindIsBankrupt}
	<!--AutosaveCheckbox { ...bankruptLeaderRequestReceived }/-->
{/if}
<AutosaveCheckbox {...bankruptLeaderRequestReceived} />
