<!-- @format -->
<!-- @format -->
<script>
	import { createEventDispatcher, tick } from 'svelte';
	import { onMount } from 'svelte';
	import { fetchGet, fetchDelete, fetchPost, fetchPut } from '~/js/utils/fetch_helpers.js';
	import { transmitter, procedure, options, bankruptIsPerson } from '~/js/global_stores/base.js';
	import { substitutions, requiredFieldsFilled, pdfDctData, currentData } from '~/svelte/dcts/stores/stores';
	import { isPresent, isBlank } from '~/js/utils/tools.js';
	import FormFields from '~/svelte/dcts/form_fields.svelte';
	import Template from '~/svelte/dcts/template.svelte';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import printJS from 'print-js';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';
	import PrintIcon from '~/svelte/_shared/print_icon.svelte';
	import DownloadIcon from '~/svelte/_shared/download_icon.svelte';
	import DeleteIcon from '~/svelte/_shared/delete_icon.svelte';
	import PdfIcon from '~/svelte/_shared/pdf_icon.svelte';
	import DocxIcon from '~/svelte/_shared/docx_icon.svelte';
	import SwitchBtn from '~/svelte/components/ui/switch_btn.svelte';

	const dispatch = createEventDispatcher();

	export let selectedNotificationId;
	export let notifications = [];
	export let disabled = false;
	export const reload = () => {
		refresh = false;
		load()
			.then(result => {
				notifications = result.rows;
				refresh = true;
			})
			.catch(error => (errors = error));
	};
	export const refreshDocument = () => tick().then(() => changeCurrentDocument(selectedNotification.id));

	let downloadPdfUrl;
	let downloadDocUrl;
	let processing = false;
	let processingEfrsb = false;
	let refresh = false;
	let errors = [];
	let selectedNotification = {};
	let efrsbResponse = null;
	let switchValue;

	// TODO: разобраться
	const load = () => {}; //fetchGet('/api/private/meetings/' + $transmitter.meeting.id + '/meeting_bulletins', params);

	const changeCurrentDocument = id => {
		if (isPresent(id)) {
			refresh = false;
			fetchGet('/api/private/meetings/' + $transmitter.meeting.id + '/meeting_notifications/' + id + '/edit')
				.then(result => {
					$options = { ...$options, ...result.options };
					selectedNotification = result.item;
					refreshCurrentDocument();
					refresh = true;
				})
				.catch(error => (errors = error));
		}
	};

	const refreshCurrentDocument = () => {
		if (isPresent(selectedNotification)) {
			initCurrentDocument();
			$requiredFieldsFilled = {};
			$transmitter.dct = selectedNotification.dct;
			$transmitter.meeting.disabled_fields = selectedNotification.dct.form_fields;

			if (disabled) {
				$transmitter.dct.disabled_fields = selectedNotification.dct.form_fields;
			}
		}
	};

	const initCurrentDocument = () => {
		if (selectedNotification.status == 'draft') {
			selectedNotification.dct.substitutions = {
				...selectedNotification.dct.substitutions,
				meeting_kind: $transmitter.meeting.kind,
				meeting_form: $transmitter.meeting.form,
				meeting_date_at: $transmitter.meeting.date_at,
				meeting_time_at: $transmitter.meeting.time_at,
				meeting_note: $transmitter.meeting.note,
				meeting_from_at: $transmitter.meeting.from_at,
				meeting_to_at: $transmitter.meeting.to_at,
				meeting_address: $transmitter.meeting.address,
				meeting_read_at: $transmitter.meeting.read_at,
				meeting_read_from_at: $transmitter.meeting.read_from_at,
				meeting_read_to_at: $transmitter.meeting.read_to_at,
				meeting_read_address: $transmitter.meeting.read_address,
				meeting_questions: $transmitter.meeting.questions,
			};
		}
	};

	// callback on any field change
	const updateCurrentDocument = ({ detail: d }) => {
		selectedNotification.dct = d.dct;
		notifications = notifications.map(e => (e.id == selectedNotificationId ? { ...e, name: d.dct.short_name } : e));
		refreshCurrentDocument();
		dispatch('change', notifications);
	};

	const deleteDocument = () => {
		fetchDelete('/api/private/meetings/' + $transmitter.meeting.id + '/meeting_notifications/' + selectedNotificationId).then(() => {
			let index = notifications.map(e => e.id).indexOf(selectedNotificationId);

			index = index > 0 ? index - 1 : 0;

			notifications = notifications.filter(e => e.id !== selectedNotificationId);

			if (notifications.length) {
				selectedNotificationId = notifications[index].id;
				hideModal('delete-document-modal');
				dispatch('delete', notifications);
			} else {
				dispatch('delete', notifications);
				dispatch('close');
			}
		});
	};

	const changeDocumentStatus = () => {
		efrsbResponse = null;
		const params = isDraft ? { dct: { ...$substitutions, status: 'approved' }, pdf_data: $pdfDctData } : { dct: { status: 'draft' } };

		processing = true;

		fetchPut(`/api/private/documents/${$transmitter.dct.id}`, params)
			.then(result => {
				selectedNotification.dct = result.dct;
				notifications = notifications.map(e => (e.id == selectedNotificationId ? { ...e, status: result.dct.status } : e));
				refreshCurrentDocument();
				dispatch('change', notifications);
				$procedure = { ...result.procedure };
				processing = false;
			})
			.catch(error => {
				errors = error;
				processing = false;
				throw error;
			});
	};

	const printDocument = () => printJS({ printable: downloadPdfUrl, type: 'pdf', showModal: true });
	const downloadDocument = () => showModal('print-modal');

	const sendToEfrsb = async () => {
		efrsbResponse = { note: 'Отправка может занять несколько минут.' };

		try {
			processingEfrsb = true;
			efrsbResponse = await fetchPost(`/api/private/meetings/${$transmitter.meeting.id}/meeting_notifications/${selectedNotificationId}/send_message_to_efrsb`);

			$transmitter.meeting = {
				...$transmitter.meeting,
				publication_about_number: efrsbResponse.publication_about_number,
				publication_about_date: efrsbResponse.publication_about_date,
			};
			dispatch('publishedAboutEFRSB', {
				publication_about_number: efrsbResponse.publication_about_number,
				publication_about_date: efrsbResponse.publication_about_date,
			});
		} catch (e) {
			errors = e;
			throw e;
		} finally {
			processingEfrsb = false;
		}
	};

	onMount(() => {
		$requiredFieldsFilled = {};
		$transmitter.dct = undefined;
		changeCurrentDocument(selectedNotificationId);
	});

	$: downloadPdfUrl = '/meetings/' + $transmitter.meeting.id + '/' + selectedNotificationId + '/download_notification?type=pdf';
	$: downloadDocUrl = '/meetings/' + $transmitter.meeting.id + '/' + selectedNotificationId + '/download_notification?type=docx';
	$: isDraft = isPresent($transmitter.dct) && $transmitter.dct.status == 'draft';
	$: formReady = Object.values($requiredFieldsFilled).every(value => value);
	$: missingInnAndSnils = $currentData && isBlank($currentData.bankruptInn) && isBlank($currentData.bankruptSnils);
	$: notPossibleSendToEfrsb = isDraft || !$procedure.arbitr_manager_efrsb_account_valid || missingInnAndSnils || processingEfrsb;
	$: sendToEfrsbTooltip = isDraft
		? 'Сначала утвердите документ'
		: !$procedure.arbitr_manager_efrsb_account_valid
			? 'Укажите учётные данные ЕФРСБ на странице АУ'
			: missingInnAndSnils
				? `Для отправки сообщения необходимо заполнить ИНН${$bankruptIsPerson ? ' или СНИЛС' : ''} должника`
				: null;
</script>

{#if isPresent(notifications)}
	<button class="back-to-list btn btn-sm btn-outline-secondary mb-2" style="font-size: inherit;" on:click={() => dispatch('close')}>
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24">
			<path d="m12.707 7.707-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z"></path>
			<path d="M16.293 6.293 10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z"></path>
		</svg>
		<span style="margin-left: -5px;">К списку</span>
	</button>
	<div class="w-100 overflow-auto">
		<div class="btn-group btn-group-toggle m-t-none" data-toggle="buttons">
			{#each notifications as r (r.id)}
				<label class:active={selectedNotificationId == r.id} class="btn btn-sm btn-outline-secondary mb-2 cursor_pointer">
					<input type="radio" bind:group={selectedNotificationId} value={r.id} on:change={e => changeCurrentDocument(e.target.value)} />
					{r.name}
				</label>
			{/each}
		</div>
	</div>
{/if}

<div class="document-form">
	<div class="toolbar">
		<div class="flex-slot">
			<ul class="nav navbar-top-links tools-bar flex-shrink-slot flex-nowrap">
				<li style="display: flex;">
					<button
						class="btn btn-sm"
						class:btn-primary={isDraft && formReady}
						class:btn-warning={!isDraft}
						disabled={(isDraft && !formReady) || processing || disabled}
						on:click={changeDocumentStatus}
					>
						{isDraft ? 'Утвердить' : 'Изменить'}
						<span class="spinner-border spinner-border-sm ml-1" class:hidden={!processing}></span>
					</button>
					<div class="btn btn-sm raf__with-tooltip">
						<IconInfo />
						<div class="raf__tooltip">Фиксирует документ в pdf, защищает его от случайных изменений</div>
					</div>
				</li>
				{#if $transmitter.dct && $transmitter.dct.correspondent_kind === 'fedresource'}
					<li class="m-l-md" style="display: flex;">
						<div class="raf__with-tooltip">
							<button class="btn btn-sm" class:btn-warning={!notPossibleSendToEfrsb} disabled={notPossibleSendToEfrsb} on:click={sendToEfrsb}>
								<span class="hidden-mobile">Отправить в </span>ЕФРСБ
								<span class="spinner-border spinner-border-sm ml-1" class:hidden={!processingEfrsb}></span>
							</button>
							{#if sendToEfrsbTooltip}
								<div class="raf__tooltip">{sendToEfrsbTooltip}</div>
							{/if}
						</div>
						<div class="btn btn-sm raf__with-tooltip">
							<IconInfo />
							<div class="raf__tooltip">Отправит сообщение в ЕФРСБ</div>
						</div>
					</li>
					{#if efrsbResponse}
						<li class="m-l-sm">
							<span
								class:text-success={isPresent(efrsbResponse.info)}
								class:text-danger={isPresent(efrsbResponse.errors)}
								class:text-note={isPresent(efrsbResponse.note)}
							>
								{isPresent(efrsbResponse.info) ? efrsbResponse.info : isPresent(efrsbResponse.errors) ? efrsbResponse.errors : efrsbResponse.note}
							</span>
						</li>
					{/if}
				{/if}
			</ul>
			<ul class="nav navbar-top-links tools-bar flex-grow-slot">
				<li class="m-l-sm">
					{#if isDraft}
						<DownloadIcon disabled={true} />
					{:else if selectedNotification.correspondent_kind == 'newspaper'}
						<a href on:click|preventDefault={downloadDocument} style="padding: 0px; min-height: fit-content;">
							<DownloadIcon />
						</a>
					{:else}
						<a download href={downloadPdfUrl} style="padding: 0px; min-height: fit-content;">
							<DownloadIcon />
						</a>
					{/if}
				</li>
				<li class="m-l-sm m-r-xs">
					{#if isDraft}
						<PrintIcon disabled={true} />
					{:else}
						<span style="cursor: pointer;" role="button" tabindex="0" on:click={printDocument} on:keypress|stopPropagation>
							<PrintIcon />
						</span>
					{/if}
				</li>
				<li class="m-l-sm m-r-sm">
					<span style="cursor: pointer;" role="button" tabindex="0" on:click={() => showModal('delete-document-modal')} on:keypress|stopPropagation>
						<DeleteIcon />
					</span>
				</li>
			</ul>
		</div>
	</div>
	<div class="content-body footer-margin">
		<div class="row" style="height: calc(100vh - 92px); margin-right: 0;">
			<div class="col-sm-5" class:hidden-mobile={!switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="overlay">
					<ErrorMessage message={errors} on:click={() => (errors = [])} />
					{#if refresh}
						<FormFields formFields={$transmitter.meeting.form_fields} on:change on:update={updateCurrentDocument} />
					{:else}
						загружается...
					{/if}
				</div>
			</div>
			<div class="col-sm-7" class:hidden-mobile={switchValue} style="overflow: auto; height: 100%; padding-right: 0;">
				<div class="preview">
					<div class="preview__page">
						{#if refresh}
							<Template />
						{:else}
							загружается...
						{/if}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<SwitchBtn bind:switchValue />

<Modal modalId="delete-document-modal" submitButtonText="Удалить" submitButtonCss="btn-danger" submitButtonAction={deleteDocument}>
	<h2 slot="header">Удалить уведомление?</h2>
</Modal>

<Modal modalId="print-modal" size="small">
	<div slot="footer" class="d-flex justify-content-around">
		<a download href={downloadPdfUrl} class="download-icon btn btn-primary btn-outline" on:click={() => hideModal('print-modal')}>
			<PdfIcon />
			<span class="m-l-xs">pdf</span>
		</a>
		<a download href={downloadDocUrl} class="download-icon btn btn-primary btn-outline" on:click={() => hideModal('print-modal')}>
			<DocxIcon />
			<span>docx</span>
		</a>
	</div>
</Modal>

<style lang="scss">
	.document-form {
		width: 100%;

		.toolbar {
			border-bottom: 1px solid #e7eaec;
			padding-bottom: 10px;
			padding-left: 15px;
			margin-left: -15px;
		}
		.content-body {
			background-color: #f3f3f4;
			.overlay,
			.preview {
				border-left: none;
				border-top: none;
			}
		}
	}
	.preview {
		flex-direction: column;
		align-items: center;
		color: #000;
		box-shadow:
			0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 3px 1px -2px rgba(0, 0, 0, 0.2),
			0 1px 5px 0 rgba(0, 0, 0, 0.12);
		border: 1px solid #e7eaec;
	}
	.preview__page {
		--aspect-ratio: 210 / 297;
		box-sizing: border-box;
		background-color: #fff;
		padding: 7.14% 7.14% 7.14% 9.52%;
	}
	.download-icon {
		padding: 10px;
		min-height: unset;
		fill: #1ab394;
		&:hover {
			fill: #fff;
		}
	}
	.back-to-list:hover {
		svg {
			fill: white;
		}
	}
	.text-success {
		color: #28a745 !important;
	}
	.text-note {
		color: #337ab7 !important;
	}
</style>
