<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import judicialActRecognizedHint from './judicial_act_recognized_hint';
	import AutosaveInput from '../components/ui/autosave_input.svelte';
	import AutosaveDatetimepicker from '../components/ui/autosave_datetimepicker.svelte';

	$: arbitrProcedure = ($transmitter && $transmitter.procedure) || arbitrProcedure;
	$: procedureId = (arbitrProcedure && arbitrProcedure.id) || null;

	$: formFields = {
		hearingDateAndTime: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'hearing_date_and_time',
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Дата и время заседания',
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг чч:мм',
			value: arbitrProcedure && arbitrProcedure.hearing_date_and_time,
			hintData: {
				judicial_act_recognized: judicialActRecognizedHint({
					name: 'hearingDateAndTime',
					value: arbitrProcedure && arbitrProcedure.hearing_date_and_time,
				}),
			},
		},
		courtroom: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'courtroom',
			model: 'procedure',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'Зал заседания',
			placeholder: '00000000000',
			value: arbitrProcedure && arbitrProcedure.courtroom,
			hintData: {
				judicial_act_recognized: judicialActRecognizedHint({
					name: 'courtroom',
					value: arbitrProcedure && arbitrProcedure.courtroom,
				}),
			},
		},
	};
</script>

<div class="m-b-sm overlay procedure__hearing-fields">
	<h3>4. Судебное заседание по результатам процедуры</h3>
	<AutosaveDatetimepicker {...formFields.hearingDateAndTime} />
	<AutosaveInput {...formFields.courtroom} />
</div>
