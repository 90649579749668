<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dctFormAvailable, dct, substitutions } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { showModal } from '~/svelte/components/modal.svelte';
	import { isBlank } from 'utils/tools';
	import BankAccountModal from '~/svelte/bank_accounts/_modal.svelte';

	export let fieldId;

	const modalId = 'form-bank_account-modal';

	let indexCollapse = false;
	let bankAccountId = null;
	let noBankSelected = true;
	let exchangeRateDate;
	let correspondentKindIsBank = $transmitter && $transmitter.dct.correspondent_kind === 'bank';
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	$: dctDate = $dct && $dct.date;
	//$: bankAccounts = $transmitter && $transmitter.bankAccounts || bankAccounts || []
	$: bankName = $substitutions && $substitutions.correspondent_short_name;
	$: mainBankAccounts = ($transmitter.bankAccounts && $transmitter.bankAccounts.filter(bankAccount => bankAccount.is_main)) || [];
	$: otherBankAccounts =
		($transmitter.bankAccounts && $transmitter.bankAccounts.filter(bankAccount => bankAccount.counterparty_name == bankName && !bankAccount.is_main)) || [];
	$: bankAccounts = correspondentKindIsBank ? otherBankAccounts.concat(mainBankAccounts) : ($transmitter && $transmitter.bankAccounts) || bankAccounts || [];
	$: numberOfBankAccounts = bankAccounts && bankAccounts.length;

	const bankAccountText = bankAccount => {
		return [
			'Счёт',
			bankAccount.number.length ? bankAccount.number : null,
			bankAccount.counterparty_name || bankAccount.bank_name ? 'в' : null,
			bankAccount.counterparty_name || bankAccount.bank_name || `(криптокошелёк ${bankAccount.currency_type})`,
			bankAccount.is_main ? '(основной)' : null,
		]
			.filter(i => i)
			.join(' ');
	};

	const openBankAccountModalform = id => {
		bankAccountId = id;
		showModal(modalId);
	};

	const loadBankAccounts = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/bank_accounts', { procedure_id: procedureId, date: dctDate });
			$transmitter = { ...$transmitter, bankAccounts: response.bank_accounts };
			exchangeRateDate = dctDate;
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: if (dctDate && exchangeRateDate !== dctDate && isBlank($transmitter.bankAccounts) && !fetching) {
		loadBankAccounts();
	} else {
		forceSave = false;
	}

	onMount(() => {
		loadBankAccounts();
	});
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="bank_accounts col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Счета (${numberOfBankAccounts})`}</span>
	</h4>
</div>
<div id="collapse-bank_accounts" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && openBankAccountModalform()}> + Счёт </button>
		<div class="p-1">
			{#if numberOfBankAccounts}
				<ol class="block-list">
					{#each bankAccounts as bankAccount}
						<li>
							<div class="float-left simulated-link" role="button" tabindex="0" on:click={openBankAccountModalform(bankAccount.id)} on:keypress|stopPropagation>
								{bankAccountText(bankAccount)}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>

<BankAccountModal {bankAccountId} disabled={!$dctFormAvailable} {modalId} />
