<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveTextarea from '../../components/ui/autosave_textarea.svelte';

	$: assessmentReportData = {
		...$commonParams,
		attribute: 'assessment_report_data',
		label: 'Данные отчетов об оценке',
		value: $currentData && $currentData.assessmentReportData,
		placeholder: 'Отчет об оценке №0 от дд.мм.гггг',
	};
</script>

<AutosaveTextarea {...assessmentReportData} />
