<!-- @format -->
<script>
	import { bankruptType, transmitter, autosaveStatus, processing } from 'base_stores';
	import { onMount } from 'svelte';
	import Header from '~/svelte/components/header.svelte';
	import {
		isPresent,
		isBlank,
		isArray,
		toArray,
		formatDate,
		formatSum,
		formatDateISO,
		daysBetweenDates,
		debounce,
		gaussRound,
		ifBlank,
		extractFilename,
	} from '~/js/utils/tools';
	import { addDays } from '~/js/utils/date';
	import { fetchGet, fetchPost, fetchPatch } from '~/js/utils/fetch_helpers';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import InputTime from '~/svelte/components/ui/input_time.svelte';
	import InputMoney from '~/svelte/components/ui/input_money.svelte';
	import MeetingQuestionModal from './question/_modal.svelte';
	import AdditionalQuestionModal from './additional_question/_modal.svelte';
	import SearchBy from '~/svelte/components/ui/dadata/search_by.svelte';
	import SelectBulletinsModal from './bulletin/_select.svelte';
	import SelectNotificationsModal from './notification/_select.svelte';
	import PrintIcon from '~/svelte/_shared/print_icon.svelte';
	import DownloadIcon from '~/svelte/_shared/download_icon.svelte';
	import ParticipantModal from './participant/_modal.svelte';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import printJS from 'print-js';
	import EditorOfNotifications from '~/svelte/meetings/_dct/_editor_of_notifications.svelte';
	import EditorOfBulletins from '~/svelte/meetings/_dct/_editor_of_bulletins.svelte';
	import EditorOfProtocol from '~/svelte/meetings/_dct/_editor_of_protocol.svelte';
	import EditorOfResultMessage from '~/svelte/meetings/_dct/_editor_of_result_message.svelte';
	import PdfIcon from '~/svelte/_shared/pdf_icon.svelte';
	import DocxIcon from '~/svelte/_shared/docx_icon.svelte';
	import { dndzone } from 'svelte-dnd-action';
	import { flip } from 'svelte/animate';
	import fileDownload from 'js-file-download';
	import MailRegistryForm from '../../mail_registries/form.svelte';
	import { applyStyles, meetingCreditorData } from '~/svelte/_shared/meeting_bulletin';
	import { getTemplate } from '~/svelte/dcts/template';
	import SvelteTooltip from 'svelte-tooltip';

	export let id;

	let page;
	let participants_for_bulletins = [];
	let participantsWithRight = [];
	let participants_without_right = [];
	let address_kinds = [];
	let cannotBeScheduled = false;
	let indexCollapse = false;
	let meetingQuestionModal;
	let selectBulletinsModal;
	let selectNotificationsModal;
	let additionalQuestionModal;
	let participantModal;
	let editNotificationsMode = false;
	let editNotificationId;
	let editBulletinsMode = false;
	let ctrlEditorOfBulletins;
	let editBulletinsOfQuestion = false;
	let editBulletinsOfParticipant = false;
	let bulletinsBlanksLoading = false;

	let formControl;
	let formOptions = isPresent(id) ? { item: { id }, method: 'edit' } : { item: {}, method: 'new' };
	let form, errorsBase, handleChange, isModified, updateInitialValues, processErrors;

	let pageParamsDisabled = false;
	let pageParamsApproveDisabled = false;
	let pageNotificationsDisabled = false;
	let pageRegistrationsDisabled = false;
	let pageRegistrationsApproveDisabled = false;
	let pageBulletinsDisabled = false;
	let pageBulletinsApproveDisabled = false;
	let pageVotingDisabled = false;
	let pageVotingApproveDisabled = false;
	let pageVotingReady = false;
	let pageProtocolDisabled = false;
	let pageProtocolApproveDisabled = false;
	let pageResultMessageDisabled = false;

	let isCompleted = false;
	let isSubmited = false;
	let isSubmitting = false;
	let isSubmitFailed = false;
	let data = {};
	let title = '';
	let usual_questions = [];
	let unusual_questions = [];
	let bulletinZipMessage;
	let bulletinZipStatus;
	let bulletinZipType;
	let bulletinZipStatusObserver;

	let meeting_did_not_take_place = false;

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
		isModified = instance.isModified;
		updateInitialValues = instance.updateInitialValues;
		processErrors = instance.processErrors;
	};

	const setDictionariesValues = values => {
		data = { ...values };

		setFormKind();
		setTitle();

		bulletinZipType = data.bulletin_zip_type;
		bulletinZipStatus = data.bulletin_zip_status;

		if (bulletinZipStatus == 'working') {
			refreshBulletinZipStatus();
		}
	};

	const setSubmitParams = () => ({});

	const handleSaveMeeting = () => {
		if ('id' in $form) {
			formControl.submitForm().then(result => isPresent(result) && updateInitialValues(result.item));
		}
	};

	const handleDeleteMeeting = () => {
		if (confirm('Вы действительно хотите удалить собрание?')) {
			formControl.deleteItem().then(id => {
				if (isArray($transmitter.meetings)) {
					$transmitter.meetings = $transmitter.meetings.filter(row => row.id != id);
				}
				document.location = '/meetings';
			});
		}
	};

	const handleNewQuestion = () =>
		meetingQuestionModal.newItem({
			params: { meeting_id: $form.id },
			meeting: $form,
		});
	const handleEditQuestion = id =>
		meetingQuestionModal.editItem({
			params: { meeting_id: $form.id },
			meeting: $form,
			item: { id },
			readonly: pageParamsDisabled,
		});

	let firstTimeAddress = true;
	const handleSelectAddressKind = ({ detail: { value: id } }) => {
		if (id === 'prev') {
			$form.address = data.prev_address;
		} else if (id === 'legal_debtor') {
			$form.address = data.legal_address;
		} else if (id === 'reg_debtor') {
			$form.address = data.reg_address;
		} else if (id === 'post') {
			$form.address = data.post_address;
		} else if ($form.address_kind === 'other') {
			if (firstTimeAddress) {
				firstTimeAddress = false;
			} else {
				$form.address = '';
			}
		} else {
			$form.address = '';
		}
		syncAddresses();
	};

	let firstTimeReadAddress = true;
	const handleSelectReadAddressKind = ({ detail: { value: id } }) => {
		if (id == 'same') {
			$form.read_address = $form.address;
		} else if (id == 'prev' && isPresent(data.prev_read_address) && isBlank($form.read_address)) {
			$form.read_address = data.prev_read_address;
		} else if (id == 'post' && isPresent(data.post_address) && isBlank($form.read_address)) {
			$form.read_address = data.post_address;
		} else if (id == 'other') {
			if (firstTimeReadAddress) {
				firstTimeReadAddress = false;
			} else {
				$form.read_address = '';
			}
		} else {
			$form.read_address = '';
		}
	};

	const setFormKind = () => {
		if ($form.form == 'facetoface') {
			if ($bankruptType == 'Organization') {
				address_kinds = data.address_kinds_facetoface_org;
			} else {
				address_kinds = data.address_kinds_facetoface_person;
			}
		} else {
			address_kinds = data.address_kinds_absentee;
		}

		if (
			!toArray(address_kinds)
				.map(e => e.value)
				.includes($form.address_kind)
		) {
			$form.address = '';
			$form.address_kind = '';
		}
	};

	const handleFormKind = () => {
		setFormKind();

		if ($form.form == 'facetoface') {
			$form.date_at = formatDateISO(addDays($form.created_at, 15)); // isPresence($form.date_at) || formatDateISO(addDays($form.created_at, 15))
		} else if ($form.form == 'absentee') {
			$form.date_at = formatDateISO(addDays($form.created_at, 31)); // isPresence($form.date_at) || formatDateISO(addDays($form.created_at, 31))
		}

		$form.read_at = formatDateISO(addDays($form.date_at, -7));
	};

	const handleChangeTimeAt = e => {
		handleChange(e);
		if (isPresent($form.time_at)) {
			let [hours, minutes] = $form.time_at.split(':').map(e => 1 * e);
			let dt = new Date();
			dt.setHours(hours, minutes);
			dt = new Date(dt.getTime() - 30 * 60000);

			$form.from_at = (dt.getHours() < 10 ? '0' + dt.getHours() : dt.getHours()) + ':' + (dt.getMinutes() < 10 ? '0' + dt.getMinutes() : dt.getMinutes());
			$form.to_at = $form.time_at;
		}
	};
	const handleChangeDateAt = () => {
		if (isPresent($form.date_at)) {
			$form.read_at = addDays($form.date_at, -7);
		}
	};

	const handleNewParticipant = () =>
		participantModal.newItem({ meeting: $form, readonly: $form.params_status != 'approved' || $form.logbook_status == 'approved' });
	const handleEditParticipant = id =>
		participantModal.editItem({ meeting: $form, readonly: $form.params_status != 'approved' || $form.logbook_status == 'approved', item: { id } });
	const handleDownloadLogbook = () => showModal('print-registration-modal');
	const handlePrintLogbook = () => printJS({ printable: '/api/private/meetings/' + id + '/download_registration?type=pdf', type: 'pdf', showModal: true });

	const handleNewNotification = () => selectNotificationsModal.newItem({ meeting: $form });
	const handleEditNotification = id => {
		$transmitter.meeting = {
			...$form,
			questions: data.questions,
			disabled_fields: ['meetingAddress', 'meetingFromAt'],
		};
		editNotificationId = id;
		editNotificationsMode = true;
	};
	const handleAutosave = () => {
		$autosaveStatus = isSubmitting ? 'saving' : isSubmited ? 'saved' : isSubmitFailed ? 'not_saved' : null;
	};
	const clearAutosave = () => {
		$autosaveStatus = 'clear';
	};
	const selectTab = name => {
		page = name;
		document.location.hash = '#' + page;
		editNotificationsMode = editBulletinsMode = false;
		clearAutosave();
	};

	const compareTimes = (a, b) => {
		const aa = ifBlank(a.time_at, '99:99');
		const bb = ifBlank(b.time_at, '99:99');

		if (aa < bb) return -1;
		if (aa > bb) return +1;
		return 0;
	};

	const handleDndConsider = e => (usual_questions = e.detail.items);
	const handleDndFinalize = e => {
		usual_questions = e.detail.items.map((e, index) => ({ ...e, num: index + 1 }));
		data.questions = [...data.questions.filter(q => q.kind !== 'usual'), ...usual_questions];

		const params = { meeting: { meeting_questions_attributes: usual_questions.map(e => ({ id: e.id, num: e.num })) } };
		fetchPatch(`/api/private/meetings/${$form.id}`, params).catch(processErrors);
	};

	const addStandardQuestions = () => {
		fetchPost('/api/private/meetings/' + id + '/add_standard_questions')
			.then(result => {
				const { item, ...rest } = result;
				data = { ...rest };
			})
			.catch(processErrors);
	};

	const handleCreateNotifications = e => {
		if (e.detail.storage) {
			data.notifications = e.detail.storage;
		}

		if (data.notifications.length) {
			handleEditNotification(e.detail.id);
		}
	};

	const handleNewAdditionalQuestion = () => additionalQuestionModal.newItem({ form: $form, params: { meeting_id: $form.id, participants: data.participants } });
	const handleOpenDownloadBulletins = mode =>
		selectBulletinsModal.open({ meeting: { ...$form, questions: data.questions, participants: data.participants } }, mode);
	const handleOpenDownloadVotingCreditorsBulletins = () => handleOpenDownloadBulletins('voting_creditors');
	const dowloadBulletinTemplates = async () => {
		try {
			bulletinsBlanksLoading = true;

			$transmitter = {
				...$transmitter,
				meeting: {
					...$form,
					questions: data.questions,
					participants: data.participants,
				},
			};

			const templates = await fetchGet('/api/private/meetings/' + $form.id + '/get_blanks');
			const prefix = $form.kind == 'creditor' ? 'bulletin_' : 'worker_';

			const usual_docs = data.questions
				.filter(e => e.kind == 'usual')
				.map(question => {
					const dct = templates.find(e => prefix + question.bulletin_form === e.correspondent_kind);

					const mcd = meetingCreditorData(question, {});

					dct.substitutions = { ...dct.substitutions, ...mcd };
					$transmitter = { ...$transmitter, dct };

					return {
						question_id: question.id,
						kind: 'usual',
						template: getTemplate({ applyStyles }).pdfData,
					};
				});
			// Добавляем шаблоны доп-вопросов
			const unusual_docs = [
				{
					kind: 'additional_simple',
					dct: templates.find(e => prefix + 'simple' === e.correspondent_kind),
				},
				{
					kind: 'additional_variants',
					dct: templates.find(e => prefix + 'variants' === e.correspondent_kind),
				},
				{
					kind: 'inclusion',
					dct: templates.find(e => prefix + 'simple' === e.correspondent_kind),
				},
			].map(({ kind, dct }) => {
				const mcd = meetingCreditorData(
					{
						kind: kind === 'inclusion' ? 'inclusion' : kind === 'additional_simple' ? 'additional' : kind === 'additional_variants' ? 'additional' : '',
						num: '',
					},
					{},
				);

				dct.substitutions = { ...dct.substitutions, ...mcd };
				$transmitter = { ...$transmitter, dct };

				return { kind, template: getTemplate({ applyStyles }).pdfData };
			});

			const docs = [...usual_docs, ...unusual_docs];

			return fetchPost('/api/private/meetings/' + $form.id + '/download_blanks', { docs }, { response_type: 'blob' }).then(result => {
				result.blob().then(blob => {
					fileDownload(blob, extractFilename(result));
					bulletinsBlanksLoading = false;
				});
			});
		} catch (errors) {
			bulletinsBlanksLoading = false;
			console.error(errors);
			processErrors(errors);
		}
	};

	const downloadBulletinsFile = () => {
		fetchGet('/api/private/meetings/' + $form.id + '/download_bulletins', { response_type: 'blob' })
			.then(result => {
				result.blob().then(blob => {
					fileDownload(blob, extractFilename(result));
				});
			})
			.catch(errors => processErrors(errors));
	};

	const handleEditBulletinsOfQuestion = id => {
		editBulletinsOfQuestion = id;
		editBulletinsOfParticipant = false;
		handleEditBulletins();
	};

	const handleEditBulletinsOfParticipant = id => {
		editBulletinsOfQuestion = false;
		editBulletinsOfParticipant = id;
		handleEditBulletins();
	};

	const handleEditBulletins = () => {
		$transmitter.meeting = {
			...$form,
			questions: [...data.questions],
			participants: [...data.participants],
			bulletin_forms: data.bulletin_forms,
			hidden_fields: editBulletinsOfQuestion
				? ['meetingParticipantRepresentativeName']
				: ['meetingQuestionBulletinForm', 'meetingQuestionQuestion', 'meetingQuestionSolution'],
			disabled_fields: editBulletinsOfQuestion
				? ['meetingQuestionId', 'meetingQuestionQuestion', 'meetingParticipantId']
				: ['meetingQuestionId', 'meetingParticipantId', 'meetingParticipantTotalVotingSum', 'meetingParticipantVotingSum', 'meetingParticipantVotesPercent'],
		};
		editBulletinsMode = true;
	};

	const handleChangeEditorOfBulletins = ({ detail: d }) => {
		isSubmitting = true;
		isSubmited = false;
		isSubmitFailed = false;

		if (d.model == 'meeting_question') {
			fetchPatch('/api/private/meetings/' + $form.id + '/meeting_questions/' + d.id, { meeting_question: { [d.attribute]: d.value } })
				.then(result => {
					data.questions = data.questions.map(e => (e.id == result.item.id ? { ...result.item } : e));
					$transmitter.meeting.questions = data.questions;
					isSubmitting = false;
					isSubmited = true;

					if (d.attribute == 'bulletin_form') {
						// в этом случае перезагружаем все бюллетени, т.к. сменился сам шаблон
						ctrlEditorOfBulletins.reload();
					} else {
						ctrlEditorOfBulletins.refreshDocument();
					}
				})
				.catch(errors => {
					processErrors(errors);
					isSubmitting = false;
					isSubmited = false;
					isSubmitFailed = true;
				});
		} else if (d.model == 'meeting_participant') {
			fetchPatch('/api/private/meetings/' + $form.id + '/meeting_participants/' + d.id, { meeting_participant: { [d.attribute]: d.value } })
				.then(result => {
					data.participants = data.participants.map(e => (e.id == result.item.id ? { ...result.item } : e));
					$transmitter.meeting.participants = data.participants;
					isSubmitting = false;
					isSubmited = true;
					ctrlEditorOfBulletins.refreshDocument();
				})
				.catch(errors => {
					processErrors(errors);
					isSubmitting = false;
					isSubmited = false;
					isSubmitFailed = true;
				});
		}
	};

	const handleDeleteEditorOfBulletins = () => {};
	const handleChangeEditorOfNotifications = ({ detail: d }) => (data.notifications = d);
	const handleDeleteEditorOfNotifications = ({ detail: d }) => (data.notifications = d);
	const handlePublishedAboutEFRSB = ({ detail: d }) => {
		$form.publication_about_number = d.publication_about_number;
		$form.publication_about_date = d.publication_about_date;
	};
	const handlePublishedSolutionsEFRSB = ({ detail: d }) => {
		$form.publication_solutions_number = d.publication_solutions_number;
		$form.publication_solutions_date = d.publication_solutions_date;
	};

	const setTitle = () => {
		if (!isPresent($form)) {
			return;
		}

		title = 'Собрание ' + ($form.kind == 'creditor' ? 'кредиторов от ' : 'работников от ') + formatDate($form.date_at);

		if (page == 'notifications' && editNotificationsMode) {
			title = title + ' (уведомления)';
		} else if (page == 'bulletins' && editBulletinsMode) {
			if (editBulletinsOfQuestion) {
				const idx = data.questions.findIndex(e => e.id == editBulletinsOfQuestion);
				title = title + (idx >= 0 ? ' (бюллетени по вопросу № ' + (idx + 1) + ')' : '');
			} else if (editBulletinsOfParticipant) {
				const idx = data.participants.findIndex(e => e.id == editBulletinsOfParticipant);
				title = title + (idx >= 0 ? ' (бюллетени ' + data.participants[idx].name + ')' : '');
			}
		}
	};

	const handleDownloadBulletins = () => {
		bulletinZipStatus = 'queued';
		refreshBulletinZipStatus();
	};

	const refreshBulletinZipStatus = () => {
		bulletinZipStatusObserver = setInterval(() => {
			fetchGet('/api/private/meetings/' + $form.id + '/get_bulletins_zip_status')
				.then(result => {
					bulletinZipType = result.bulletin_zip_type;
					bulletinZipStatus = result.status;
					bulletinZipMessage = result.message;

					if (['complete', 'failed', 'interrupted', 'none'].includes(result.status)) {
						clearInterval(bulletinZipStatusObserver);
					}
				})
				.catch(errors => {
					clearInterval(bulletinZipStatusObserver);
					processErrors(errors);
				});
		}, 2000);
	};

	const handleRefreshForm = () => {
		if ($isModified) {
			debounce(() => handleSaveMeeting(), 100);
		} else {
			pageParamsDisabled = $form.params_status == 'approved' || $form.status == 'canceled';
			pageParamsApproveDisabled = $form.logbook_status == 'approved' || $form.status == 'canceled';
			pageNotificationsDisabled = $form.params_status == 'draft' || $form.logbook_status == 'approved' || $form.status == 'canceled';
			pageRegistrationsDisabled = $form.params_status == 'draft' || $form.logbook_status == 'approved' || $form.status == 'canceled';
			pageRegistrationsApproveDisabled = $form.bulletin_status == 'approved' || $form.status == 'canceled';
			pageBulletinsDisabled = $form.logbook_status == 'draft' || $form.bulletin_status == 'approved' || $form.status == 'canceled';
			pageBulletinsApproveDisabled = $form.voting_status == 'approved' || $form.status == 'canceled';
			pageVotingDisabled = $form.bulletin_status == 'draft' || $form.voting_status == 'approved' || $form.status == 'canceled';
			pageVotingApproveDisabled = $form.protocol_status == 'approved' || $form.status == 'canceled';
			//pageVotingReady                  = !data.bulletins.find(e => isBlank(e.voting_value) && !e.voting_invalid)
			pageVotingReady = isBlank(
				data.questions.find(
					e => !(isPresent(e.conclusion) || e.voting_invalid || data.bulletins.filter(b => b.question_id == e.id).every(b => b.voting_invalid)),
				),
			);
			pageProtocolDisabled = $form.voting_status == 'draft' || $form.protocol_status == 'approved' || $form.status == 'canceled';
			pageProtocolApproveDisabled = $form.result_message_status == 'approved' || $form.status == 'canceled';
			pageResultMessageDisabled = $form.protocol_status == 'draft' || $form.status == 'canceled';
		}
	};

	const handleRefreshData = () => {
		const total_present_sum = data.participants.reduce((sum, e) => sum + 1 * e.voting_sum, 0);

		let arr = data.participants
			.filter(e => e.voting_sum > 0)
			.map(e => ({
				...e,
				voting_sum: 1 * e.voting_sum,
				proc_sum: gaussRound($form.voting_sum > 0 ? ((1 * e.voting_sum) / $form.voting_sum) * 100 : 0, 4),
				proc_present_sum: gaussRound(total_present_sum > 0 ? ((1 * e.voting_sum) / total_present_sum) * 100 : 0, 4),
			}));
		arr.sort(compareTimes);
		participantsWithRight = [...arr];
		total_percent_qty = toArray(participantsWithRight).reduce((sum, e) => sum + e.proc_sum, 0);
		noQuorum =
			isPresent($form) && (($form.creation_type === 'initial' && total_percent_qty < 50) || ($form.creation_type === 'reconvened' && total_percent_qty < 30));
		if ($form.voting_status == 'draft') {
			$form.voting_meeting_status = noQuorum ? 'not_held' : 'held';
		}

		arr = data.participants.filter(e => 1 * e.voting_sum == 0);
		arr.sort(compareTimes);
		participants_without_right = [...arr];

		participants_for_bulletins =
			$form.kind == 'creditor' ? data.participants.filter(e => e.voting_sum > 0 || e.unsecured_voting_sum > 0) : data.participants.filter(e => e.role_worker);

		usual_questions = data.questions.filter(e => e.kind == 'usual');
		unusual_questions = data.questions.filter(e => e.kind != 'usual');
	};

	let noQuorum;

	const handleVotingQuestionChange = e => {
		const id = e.target.getAttribute('data-id');
		const name = e.target.getAttribute('data-name');
		const value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;

		let meetingParams = { meeting_questions_attributes: [{ id, [name]: value }] };
		let meetingQuestionsAttributes = [];

		if (name == 'voting_invalid') {
			const bulletins = toArray(data.bulletins).filter(e => e.question_id == id);

			for (let bulletin of bulletins) {
				meetingQuestionsAttributes = [...meetingQuestionsAttributes, { id: bulletin.id, voting_invalid: value }];
			}

			meetingParams = { ...meetingParams, meeting_bulletins_attributes: meetingQuestionsAttributes };
		}

		isSubmitFailed = false;
		isSubmitting = true;
		isSubmited = false;
		fetchPatch(`/api/private/meetings/${$form.id}`, { meeting: meetingParams })
			.then(result => {
				const { item: resultItem, ...rest } = result;
				isSubmited = true;
				isSubmitting = false;
				updateInitialValues(resultItem);
				setDictionariesValues(rest);
			})
			.catch(errors => {
				isSubmitting = false;
				isSubmitFailed = true;
				processErrors(errors);
				throw errors;
			});
	};

	const handleVotingBulletinChange = e => {
		const question_id = e.target.getAttribute('data-question-id');
		const bulletin_id = e.target.getAttribute('data-id');
		const attribute_name = e.target.getAttribute('data-name');
		const attribute_value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;

		updateVotingBulletinValue(question_id, bulletin_id, attribute_name, attribute_value);
	};

	const updateVotingBulletinValue = (question_id, bulletin_id, attribute_name, attribute_value) => {
		isSubmitFailed = false;
		isSubmitting = true;
		isSubmited = false;

		let meetingQuestionsAttributes = { id: question_id, conclusion: '' };

		// логика: установить статус для вопроса "Голосование не проводилось", если по всем голосующим бюллетени не действительны
		// сейчас почему-то это не так
		//if (attribute_name == 'voting_invalid') {
		//  const bulletins = toArray(data.bulletins).filter(e => e.question_id == question_id)
		//  const bulletinChecks = bulletins.map(b => b.id == bulletin_id ? attribute_value : b.voting_invalid)
		//  meetingQuestionsAttributes = { ...meetingQuestionsAttributes, voting_invalid: bulletinChecks.every(i => i) }
		//}

		fetchPatch(`/api/private/meetings/${$form.id}`, {
			meeting: {
				meeting_bulletins_attributes: [{ id: bulletin_id, [attribute_name]: attribute_value }],
				meeting_questions_attributes: [meetingQuestionsAttributes],
			},
		})
			.then(result => {
				const { item: resultItem, ...rest } = result;
				isSubmited = true;
				isSubmitting = false;
				updateInitialValues(resultItem);
				setDictionariesValues(rest);
			})
			.catch(errors => {
				isSubmitting = false;
				isSubmitFailed = true;
				processErrors(errors);
				throw errors;
			});
	};

	const updateVotingBulletinElection = (question_id, bulletin_id, variant_id, attribute_name, attribute_value) => {
		isSubmitFailed = false;
		isSubmitting = true;
		isSubmited = false;
		fetchPatch('/api/private/meetings/' + $form.id, {
			meeting: {
				meeting_bulletins_attributes: [{ id: bulletin_id, meeting_bulletin_variants_attributes: [{ id: variant_id, [attribute_name]: attribute_value }] }],
				meeting_questions_attributes: [{ id: question_id, conclusion: '' }],
			},
		})
			.then(result => {
				const { item: resultItem, ...rest } = result;
				isSubmited = true;
				isSubmitting = false;
				updateInitialValues(resultItem);
				setDictionariesValues(rest);
			})
			.catch(errors => {
				isSubmitting = false;
				isSubmitFailed = true;
				processErrors(errors);
				throw errors;
			});
	};

	const participantById = id => data.participants.find(e => e.id == id) || {};

	const handleAssignMeeting = () => changeStatusFor('assign');
	const handleChangeMeeting = () => changeStatusFor('change');
	const handleCancelMeeting = () => confirm('Отменить собрание?') && changeStatusFor('cancel');
	const handleReconveneMeeting = () => confirm('Назначить повторное?') && reconveneMeeting();
	const handleDuplicateMeeting = () => confirm('Продублировать собрание?') && duplicateMeeting();
	const handleRegistrationStatusChange = () => changeStatusFor('registration');
	const handleBulletinStatusChange = () => changeStatusFor('bulletin');
	const handleVotingStatusChange = () => changeStatusFor('voting');
	const handleProtocolStatusChange = () => changeStatusFor('protocol');
	const handleResultMessageStatusChange = () => changeStatusFor('result_message');

	const reconveneMeeting = () => {
		fetchPost('/api/private/meetings/' + $form.id + '/reconvene')
			.then(() => (document.location = '/meetings'))
			.catch(processErrors);
	};

	const duplicateMeeting = () => {
		fetchPost('/api/private/meetings/' + $form.id + '/duplicate')
			.then(() => (document.location = '/meetings'))
			.catch(processErrors);
	};

	const changeStatusFor = method => {
		fetchPost('/api/private/meetings/' + $form.id + '/' + method)
			.then(result => updateInitialValues(result.item))
			.catch(processErrors);
	};

	const createVotingVariant = (question_id, bulletin_id, attribute_name, newItem) => {
		if (isPresent(newItem.value) && newItem.value == newItem.label) {
			fetchPost('/api/private/meetings/' + $form.id + '/meeting_question_variants', { name: newItem.label, meeting_question_id: question_id }).then(result => {
				data.voting_variants = [result.item, ...data.voting_variants];
				updateVotingBulletinValue(question_id, bulletin_id, attribute_name, result.item.value);
			});
		} else {
			updateVotingBulletinValue(question_id, bulletin_id, attribute_name, newItem.value);
		}
	};

	const createVotingElection = (question_id, bulletin_id, variant_id, attribute_name, newItem) => {
		if (isPresent(newItem.value) && newItem.value == newItem.label) {
			fetchPost('/api/private/meetings/' + $form.id + '/meeting_question_variants', { name: newItem.label, meeting_question_id: question_id }).then(result => {
				data.voting_variants = [result.item, ...data.voting_variants];
				updateVotingBulletinElection(question_id, bulletin_id, variant_id, attribute_name, result.item.value);
			});
		} else {
			updateVotingBulletinElection(question_id, bulletin_id, variant_id, attribute_name, newItem.value);
		}
	};

	const refreshMeeting = () => {
		isSubmitFailed = false;
		isSubmitting = true;
		isSubmited = false;

		fetchGet(`/api/private/meetings/${$form.id}`)
			.then(result => {
				const { item: resultItem, ...rest } = result;
				isSubmited = true;
				isSubmitting = false;
				updateInitialValues(resultItem);
				setDictionariesValues(rest);
			})
			.catch(errors => {
				isSubmitting = false;
				isSubmitFailed = true;
				processErrors(errors);
				throw errors;
			});
	};

	const syncAddresses = () => {
		if ($form.read_address_kind === 'same') {
			$form.read_address = $form.address;
		}
	};

	const calcMeetingTakingPlaceStatus = qty => {
		if (isBlank($form)) {
			return;
		}

		meeting_did_not_take_place = ($form.creation_type === 'initial' && qty < 50) || ($form.creation_type === 'reconvened' && qty < 30);
		if ($form.voting_status == 'draft') {
			$form.voting_meeting_status = meeting_did_not_take_place ? 'not_held' : 'held';
		}

		const params = { meeting: { voting_meeting_status: $form.voting_meeting_status } };
		fetchPatch(`/api/private/meetings/${$form.id}`, params).catch(processErrors);
	};

	$: isPresent($form) && handleRefreshForm($form);
	$: isPresent(data) && handleRefreshData(data);
	$: handleAutosave(isSubmitting, isSubmited, isSubmitFailed);

	$: mailRegistryData =
		data.notifications &&
		data.notifications
			.filter(notification => !['newspaper', 'fedresource'].includes(notification.kind))
			.map(notification => {
				return { id: notification.dct_id, out_short_name: notification.name, status: notification.status };
			});

	$: cannotBeScheduled = !isCompleted || $form.params_status == 'approved';
	$: setTitle(page, editNotificationsMode, editBulletinsMode);
	$: bulletinZipMessage =
		bulletinZipStatus == 'queued'
			? 'процесс подготовки ожидает своей очереди'
			: bulletinZipStatus == 'working'
				? bulletinZipMessage
				: bulletinZipStatus == 'retrying'
					? 'в процессе подготовки возникли ошибки'
					: bulletinZipStatus == 'failed'
						? 'в процессе подготовки возникли ошибки'
						: bulletinZipStatus == 'complete'
							? 'Файл бюллетеней готов (скачать)'
							: bulletinZipStatus == 'interrupted'
								? 'процесс подготовки прерван'
								: bulletinZipStatus == 'none'
									? 'Файл бюллетеней еще не сформирован'
									: bulletinZipMessage;

	$: total_percent_qty = toArray(participantsWithRight).reduce((sum, e) => sum + e.proc_sum, 0);
	//$: isPresent($form) && calcMeetingTakingPlaceStatus(total_percent_qty)
	$: calcMeetingTakingPlaceStatus(total_percent_qty);

	onMount(() => {
		page = ['#params', '#notifications', '#registration', '#bulletins', '#votes', '#protocol', '#result-message'].includes(document.location.hash)
			? document.location.hash.slice(1)
			: 'params';
	});
</script>

<Header>
	<span slot="name">{title}</span>
</Header>
<Form
	bind:this={formControl}
	bind:isCompleted
	bind:isSubmited
	bind:isSubmitting
	bind:isSubmitFailed
	formOptions={{ ...formOptions, model: 'meeting', controller: 'meetings' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<div
		class="tabs-container"
		class:fullwidth={editNotificationsMode || editBulletinsMode || ['votes', 'protocol', 'result-message'].includes(page) || innerWidth < 576}
	>
		<ul class="nav nav-tabs">
			<li class="nav-item">
				<a
					class="nav-link"
					href
					class:active={page == 'params'}
					class:cursor-default={$processing}
					on:click|preventDefault={!$processing && (() => selectTab('params'))}
				>
					Параметры
				</a>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					href
					class:active={page == 'notifications'}
					class:cursor-default={$processing}
					on:click|preventDefault={!$processing && (() => selectTab('notifications'))}
				>
					Уведомления ({toArray(data.notifications).length})
				</a>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					href
					class:active={page == 'registration'}
					class:cursor-default={$processing}
					on:click|preventDefault={!$processing && (() => selectTab('registration'))}
				>
					Регистрация ({toArray(data.participants).length})
				</a>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					href
					class:active={page == 'bulletins'}
					class:cursor-default={$processing}
					on:click|preventDefault={!$processing && (() => selectTab('bulletins'))}
				>
					Бюллетени
				</a>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					href
					class:active={page == 'votes'}
					class:cursor-default={$processing}
					on:click|preventDefault={!$processing && (() => selectTab('votes'))}
				>
					Голосование
				</a>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					href
					class:active={page == 'protocol'}
					class:cursor-default={$processing}
					on:click|preventDefault={!$processing && (() => selectTab('protocol'))}
				>
					Протокол
				</a>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					href
					class:active={page == 'result-message'}
					class:cursor-default={$processing}
					on:click|preventDefault={!$processing && (() => selectTab('result-message'))}
				>
					Сообщение о результатах
				</a>
			</li>
		</ul>
		<div class="tab-content footer-margin">
			<div class="tab-pane overlay" class:active={page == 'params'}>
				<div class="toolbar">
					<div class="flex-slot m-b-sm">
						<ul class="nav">
							{#if $form.status != 'canceled'}
								<li class="m-r-lg" style="display: flex;">
									{#if $form.params_status == 'approved'}
										<button class="btn btn-sm btn-warning" disabled={pageParamsApproveDisabled} on:click={handleChangeMeeting}> Изменить </button>
									{:else if $form.params_status == 'draft'}
										<button
											class="btn btn-sm"
											class:btn-primary={!cannotBeScheduled}
											class:btn-default={cannotBeScheduled}
											disabled={cannotBeScheduled || pageParamsApproveDisabled}
											on:click={handleAssignMeeting}
										>
											Утвердить
										</button>
									{/if}
									{#if $form.params_status == 'draft' || $form.params_status == 'approved'}
										<div class="btn btn-sm raf__with-tooltip">
											<IconInfo />
											<div class="raf__tooltip">
												{cannotBeScheduled
													? 'Фиксирует параметры Собрания, защищает его от случайных изменений'
													: 'Сначала заполните обязательные поля, помеченные *.'}
											</div>
										</div>
									{/if}
								</li>
							{:else}
								<div class="badge">Отменено</div>
							{/if}
						</ul>
						<ul class="nav">
							<li class="m-r-xs">
								<div role="button" tabindex="0" on:click|preventDefault={handleDuplicateMeeting} on:keypress|stopPropagation>
									<SvelteTooltip tip="Дублировать собрание" left>
										<iconify-icon icon="bx-bxs-copy" height="24" class="cursor_pointer" />
									</SvelteTooltip>
								</div>
							</li>
							<li class="m-r-xs">
								<div role="button" tabindex="0" on:click|preventDefault={handleReconveneMeeting} on:keypress|stopPropagation>
									<SvelteTooltip tip="Назначить повторное" left>
										<iconify-icon icon="bx-bxs-copy-alt" height="24" class="cursor_pointer" />
									</SvelteTooltip>
								</div>
							</li>
							<li class="m-r-xs">
								{#if $form.params_status == 'approved'}
									<div role="button" tabindex="0" on:click|preventDefault={handleCancelMeeting} on:keypress|stopPropagation>
										<SvelteTooltip tip="Отменить собрание" left>
											<iconify-icon icon="bx-bxs-minus-circle" height="24" class="cursor_pointer" />
										</SvelteTooltip>
									</div>
								{/if}
							</li>
							<li class="m-r-xs">
								<div role="button" tabindex="0" on:click|preventDefault={handleDeleteMeeting} on:keypress|stopPropagation>
									<SvelteTooltip tip="Удалить собрание" left>
										<iconify-icon icon="bx-bxs-trash" height="24" class="cursor_pointer" />
									</SvelteTooltip>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="content-body-wrapper">
					<div class="content-body param-page">
						<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
						{#if $form.creation_type === 'reconvened'}
							<div class="meeting_reconvened_dont_change">
								<div>Повторное собрание. Не изменяйте параметры собрания.</div>
							</div>
						{/if}
						<!-- Форма собрания -->
						<Field name="form" label="Форма собрания" required={true} disabled={pageParamsDisabled}>
							{#each toArray(data.forms) as option}
								<label class="d-flex">
									<input
										type="radio"
										bind:group={$form.form}
										on:change={handleFormKind}
										class="align-self-center m-r-xs"
										value={option.value}
										disabled={pageParamsDisabled}
									/>
									{option.label}
								</label>
							{/each}
						</Field>
						<!-- Основание проведения собрания -->
						<Field name="reason" label="Основание проведения собрания" disabled={pageParamsDisabled}>
							{#each toArray(data.reasons) as option}
								<label class="d-flex">
									<input type="radio" bind:group={$form.reason} class="align-self-center m-r-xs" value={option.value} disabled={pageParamsDisabled} />
									{option.label}
								</label>
							{/each}
						</Field>
						<!-- Первое собрание -->
						<Field
							disabled={pageParamsDisabled}
							name="first_meeting"
							label="Первое собрание"
							fieldClasses="col-sm-8 col-lg-9 col-xl-8 d-flex align-items-center checkbox-left"
						>
							<input
								id="checkbox_first_meeting"
								name="first_meeting"
								type="checkbox"
								class="align-self-center m-r-sm m-l-xs"
								disabled={pageParamsDisabled}
								bind:checked={$form.first_meeting}
								on:change={handleChange}
							/>
							<label class="label-text" for="checkbox_first_meeting">да</label>
						</Field>
						<!-- Дата собрания -->
						<Datepicker
							required={true}
							model='meeting_creditor'
							attribute='date_at'
							label='Дата собрания'
							placeholder='дд.мм.гггг'
							maskOptions={{ mask: Date }}
							disabled={pageParamsDisabled}
							hintData={{
								freeMessage: isPresent($form.date_at) ? (
									$form.form == 'facetoface' && Math.ceil(daysBetweenDates($form.created_at, $form.date_at)) == 15 ?
									'AI заполнил +15 дней к дате создания Собрания' :
									$form.form == 'absentee' && Math.ceil(daysBetweenDates($form.created_at, $form.date_at)) == 31 ?
									'AI заполнил +31 дней к дате создания Собрания' :
									'Изменено пользователем'
								) :
								null
							}}
							bind:value={$form.date_at}
							on:change={handleChangeDateAt}
						/>
						<!-- Время начала собрания -->
						{#if $form.form == 'facetoface'}
							<Field required={true} time={true} disabled={pageParamsDisabled} name="time_at" label="Время начала собрания">
								<InputTime name="time_at" disabled={pageParamsDisabled} value={$form.time_at} onchange={handleChangeTimeAt} klass="form-control" />
							</Field>
						{/if}
						<!-- Вид адреса собрания -->
						<Field required={true} disabled={pageParamsDisabled} name="address_kind" label="Вид адреса собрания">
							<Select
								name="address_kind"
								items={address_kinds}
								value={$form.address_kind}
								onchange={handleChange}
								isDisabled={pageParamsDisabled}
								on:select={handleSelectAddressKind}
								containerClasses="form-control"
								placeholder="Выбрать вид"
							></Select>
						</Field>
						<!-- Адрес собрания -->
						<Field required={true} disabled={pageParamsDisabled} name="address" label="Адрес собрания">
							<SearchBy
								method="address"
								bind:value={$form.address}
								on:change={syncAddresses}
								disabled={pageParamsDisabled || isBlank($form.address_kind)}
								placeholder="100000, Московская область, г. Королев, ул. Первая, д. 1"
								autocomplete="off"
							/>
						</Field>
						{#if $form.form == 'absentee' && /.*(абонентский( )+ящик|а( )*\/( )*я|а( )*\\( )*я)(.)*/i.test($form.address)}
							<div class="meeting_did_not_take_place mb-3">
								<div>Использование почтового абонентского ящика для получения заполненных бюллетеней не допускается (ч.7 ст.213.8 ЗоБ)</div>
							</div>
						{/if}
						<!-- Время регистрации -->
						{#if $form.form == 'facetoface'}
							<Field required={true} time={true} disabled={pageParamsDisabled} name="from_at" label="Время регистрации">
								<div class="registration-time">
									<InputTime
										id="from_at"
										name="from_at"
										required={true}
										disabled={pageParamsDisabled}
										value={$form.from_at}
										onchange={handleChange}
										klass="form-control {isBlank($form.from_at) ? 'required-border' : 'field-success'}"
									/>
									<div class="delimeter">—</div>
									<InputTime
										id="to_at"
										name="to_at"
										required={true}
										disabled={pageParamsDisabled}
										value={$form.to_at}
										onchange={handleChange}
										klass="form-control {isBlank($form.to_at) ? 'required-border' : 'field-success'}"
									/>
								</div>
							</Field>
						{/if}
						<!-- Дата, с которой возможно ознакомиться с материалами -->
						<Datepicker
							required={true}
							model='meeting_creditor'
							attribute='read_at'
							label='Дата, с которой возможно ознакомиться с материалами'
							placeholder='дд.мм.гггг'
							maskOptions={{ mask: Date }}
							disabled={pageParamsDisabled}
							hintData={{
								freeMessage: isPresent($form.read_at) ? (
									Math.ceil(daysBetweenDates($form.read_at, $form.date_at)) == 7 ?
									'AI заполнил -7 дней от Даты собрания' :
									'Изменено пользователем'
								) :
								null
							}}
							bind:value={$form.read_at}
						/>
						<!-- Время ознакомления с материалами (рабочие дни) -->
						<Field time={true} required={true} disabled={pageParamsDisabled} name="read_from_at" label="Время ознакомления с материалами (рабочие дни)">
							<div class="registration-time">
								<InputTime
									id="read_from_at"
									name="read_from_at"
									required={true}
									disabled={pageParamsDisabled}
									value={$form.read_from_at}
									onchange={handleChange}
									klass="{isBlank($form.read_from_at) ? 'required-border' : 'field-success'} form-control"
								/>
								<div class="delimeter">—</div>
								<InputTime
									id="read_to_at"
									name="read_to_at"
									required={true}
									disabled={pageParamsDisabled}
									value={$form.read_to_at}
									onchange={handleChange}
									klass="{isBlank($form.read_to_at) ? 'required-border' : 'field-success'} form-control"
								/>
							</div>
						</Field>
						<!-- Вид адреса ознакомления с материалами -->
						<Field required={true} disabled={pageParamsDisabled} name="read_address_kind" label="Вид адреса ознакомления с материалами">
							<Select
								name="read_address_kind"
								items={data.read_address_kinds}
								value={$form.read_address_kind}
								onchange={handleChange}
								isDisabled={pageParamsDisabled}
								on:select={handleSelectReadAddressKind}
								containerClasses="form-control"
								placeholder="Выбрать вид"
							></Select>
						</Field>
						<!-- Адрес ознакомления с материалами -->
						<Field required={true} disabled={pageParamsDisabled} name="read_address" label="Адрес ознакомления с материалами">
							<SearchBy
								id="read_address"
								method="address"
								bind:value={$form.read_address}
								disabled={pageParamsDisabled || isBlank($form.read_address_kind)}
								placeholder="100000, Московская область, г. Королев, ул. Первая, д. 1"
								autocomplete="off"
							/>
						</Field>
						<!-- Дополнение -->
						<Field name="note" disabled={pageParamsDisabled} label="Дополнение">
							<textarea
								name="note"
								rows="3"
								class="form-control"
								value={$form.note}
								on:change={handleChange}
								disabled={pageParamsDisabled}
								placeholder="предварительно согласовав дату и время ознакомления по телефону 8 (000) 000-00-00"
							/>
						</Field>
						<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
							<h4 class="col-sm-12">
								<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
								<span class="header">Вопросы собрания ({toArray(data.questions).length})</span>
							</h4>
						</div>
						<div class="collapse row m-t-n m-b-sm" class:show={indexCollapse}>
							<div class="col-sm-12">
								<button class="btn btn-sm btn-warning m-b-xs" disabled={pageParamsDisabled} on:click={handleNewQuestion}> + Вопрос </button>
								{#if $form.params_status == 'draft' && data.questions.length == 0}
									<div class="add-standard-questions" role="button" tabindex="0" on:click={addStandardQuestions} on:keypress|stopPropagation>
										Добавить стандартный набор вопросов
									</div>
								{/if}
								<div class="p-1">
									<div
										use:dndzone={{ items: usual_questions, flipDurationMs: 300, dropTargetStyle: { 'border-color': 'gray' } }}
										on:consider={handleDndConsider}
										on:finalize={handleDndFinalize}
									>
										{#each usual_questions as row (row.id)}
											<div
												class="badge"
												role="button"
												tabindex="0"
												on:click|stopPropagation={() => handleEditQuestion(row.id)}
												on:keypress|stopPropagation
												animate:flip={{ duration: 300 }}
											>
												{row.name}
											</div>
										{/each}
									</div>
								</div>
								<div class="p-1">
									{#if unusual_questions.length}
										<div class="m-b-sm">Доп. вопросы</div>
										{#each unusual_questions as row (row.id)}
											<div class="badge" role="button" tabindex="0" on:click|stopPropagation={() => handleEditQuestion(row.id)} on:keypress|stopPropagation>
												{row.name}
											</div>
										{/each}
									{/if}
								</div>
							</div>
						</div>
						<!-- Публикация о проведении собрания -->
						<Field name="publication_about_number" label="Номер публикации о проведении собрания">
							<input
								name="publication_about_number"
								type="text"
								class="form-control"
								value={$form.publication_about_number}
								placeholder="Номер публикации"
								on:change={handleChange}
							/>
						</Field>
						<Datepicker
							model='meeting_creditor'
							attribute='publication_about_date'
							label='Дата публикации о проведении собрания'
							placeholder='дд.мм.гггг'
							maskOptions={{ mask: Date }}
							hintData={{ freeMessage: $form.publication_about }}
							bind:value={$form.publication_about_date}
							onchange={handleChange}
						/>
						<!-- Публикация о принятых решениях -->
						<Field name="publication_solutions_number" label="Номер публикации о принятых решениях">
							<input
								name="publication_solutions_number"
								type="text"
								class="form-control"
								value={$form.publication_solutions_number}
								placeholder="Номер публикации"
								on:change={handleChange}
							/>
						</Field>
						<Datepicker
							model='meeting_creditor'
							attribute='publication_solutions_date'
							label='Дата публикации о принятых решениях'
							placeholder='дд.мм.гггг'
							maskOptions={{ mask: Date }}
							hintData={{ freeMessage: $form.solution_publication }}
							bind:value={$form.publication_solutions_date}
							onchange={handleChange}
						/>
					</div>
				</div>
			</div>
			<div class="tab-pane overlay" class:active={page == 'notifications'}>
				{#if !editNotificationsMode}
					<div class="toolbar">
						<div class="flex-slot m-b-sm">
							<ul class="nav">
								{#if $form.status != 'canceled'}
									<li style="display: flex;">
										<div class="raf__with-tooltip">
											<button class="btn btn-sm btn-warning" disabled={pageNotificationsDisabled} on:click={handleNewNotification}> + Уведомление </button>
											{#if pageNotificationsDisabled && $form.logbook_status == 'draft'}
												<div class="raf__tooltip">Вкладка Параметры должна быть утверждена, последующие - нет</div>
											{/if}
										</div>
									</li>
								{/if}
							</ul>
						</div>
					</div>
					<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
					<div class="content-body-wrapper">
						<div class="content-body">
							<ol class="notifications">
								{#if isPresent(toArray(data.notifications))}
									{#each toArray(data.notifications) as r (r.id)}
										<li>
											<div class="row">
												<div class="col-6 col-sm-4">
													<a
														href
														class:done={r.status == 'approved'}
														class:draft={r.status == 'draft'}
														on:click|preventDefault={() => handleEditNotification(r.id)}
													>
														{r.name + (r.status == 'draft' ? ' (черновик)' : ' (утвержден)')}
													</a>
												</div>
												{#if r.mail_registry}
													<div class="col-2">
														<span class="ml-2">
															<a target="_self" href={`/mail_registry/${r.mail_registry.id}?category=meetings&participant_type=creditor`}>
																{r.mail_registry.sent ? 'Отправлен' : 'Не отправлен'}
															</a>
														</span>
													</div>
												{/if}
											</div>
										</li>
									{/each}
								{:else}
									<div>Нет документов</div>
								{/if}
							</ol>
							<ul style="list-style: none">
								<li style="margin-bottom: 18px;">
									<ul style="display: flex; list-style: none; padding-left: 0;">
										<li class="zip-download">
											{#if bulletinZipType === 'voting_creditors'}
												{#if bulletinZipStatus == 'complete'}
													<a href on:click|preventDefault={downloadBulletinsFile}> Бюллетени (голосующие кредиторы): скачать </a>
												{:else if isPresent(bulletinZipMessage)}
													Бюллетени (голосующие кредиторы): {bulletinZipMessage}
												{/if}
											{:else}
												Бюллетени (голосующие кредиторы)
											{/if}
										</li>
										<li style="margin-left: 8px;">
											<a href on:click|preventDefault={handleOpenDownloadVotingCreditorsBulletins}>
												<DownloadIcon />
											</a>
										</li>
									</ul>
								</li>
								<li>
									<a href class:bulletinsBlanksLoading on:click|preventDefault={!bulletinsBlanksLoading && dowloadBulletinTemplates}>
										Бланки бюллетеней ({bulletinsBlanksLoading ? '... идет подготовка  ...' : 'скачать'})
									</a>
								</li>
							</ul>

							<hr style="margin-left: -10px" />
							{#if isPresent(mailRegistryData)}
								<MailRegistryForm
									dcts={{ priority: [...mailRegistryData], other: [], for_bank: [] }}
									context={{ dct_category: 'meetings', meeting_id: id, participant_type: 'creditor' }}
									sections={[{ data: [...mailRegistryData], title: 'Уведомления', type: 'priority' }]}
								/>
							{/if}
						</div>
					</div>
				{:else}
					<EditorOfNotifications
						notifications={data.notifications}
						selectedNotificationId={editNotificationId}
						disabled={pageNotificationsDisabled}
						on:change={handleChangeEditorOfNotifications}
						on:delete={handleDeleteEditorOfNotifications}
						on:close={() => (editNotificationsMode = false)}
						on:publishedAboutEFRSB={handlePublishedAboutEFRSB}
					/>
				{/if}
			</div>
			<div class="tab-pane overlay" class:active={page == 'registration'}>
				<div class="toolbar">
					<div class="flex-slot m-b-sm">
						{#if $form.status != 'canceled'}
							<ul class="nav">
								<li class="m-r-sm" style="display: flex;">
									<div class="raf__with-tooltip">
										<button class="btn btn-sm btn-warning" on:click={handleNewParticipant} disabled={pageRegistrationsDisabled}> + Участник </button>
										{#if pageRegistrationsDisabled && $form.logbook_status == 'draft'}
											<div class="raf__tooltip">Вкладка Параметры должна быть утверждена, последующие - нет</div>
										{/if}
									</div>
								</li>
								<li class="m-r-sm" style="display: flex;">
									{#if $form.logbook_status == 'draft'}
										<div class="raf__with-tooltip">
											<button
												class="btn btn-sm btn-primary mt-1 mt-sm-0"
												class:btn-primary={!pageRegistrationsDisabled}
												class:btn-default={pageRegistrationsDisabled}
												disabled={pageRegistrationsDisabled || pageRegistrationsApproveDisabled}
												on:click={handleRegistrationStatusChange}
											>
												Утвердить
											</button>
											{#if pageRegistrationsDisabled}
												<div class="raf__tooltip">Недоступно. Сначала утвердите вкладку Параметры.</div>
											{/if}
										</div>
									{:else if $form.status != 'canceled'}
										<button class="btn btn-sm btn-warning mt-1 mt-sm-0" disabled={pageRegistrationsApproveDisabled} on:click={handleRegistrationStatusChange}>
											Изменить
										</button>
									{/if}
								</li>
							</ul>
							<ul class="nav">
								<li class="m-r-sm">Журнал регистрации</li>
								<li class="m-r-sm">
									<a href on:click|preventDefault={handleDownloadLogbook}>
										<DownloadIcon />
									</a>
								</li>
								<li class="m-r-md">
									<a href on:click|preventDefault={handlePrintLogbook}>
										<PrintIcon />
									</a>
								</li>
							</ul>
						{/if}
					</div>
				</div>
				<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
				<div class="content-body-wrapper">
					<div class="content-body">
						<table class="table table-stripped table-hover m-0 border-collapse_separate">
							<thead class="thead-light">
								<tr>
									{#if $form.form == 'facetoface'}
										<th class="text-center p-w-xs">Время регистрации</th>
									{/if}
									<th class="text-center p-w-xs">Участник</th>
									<th class="text-center p-w-xs">Представитель</th>
									<th class="text-center p-w-xs">Размер требований, руб.</th>
									<th class="text-center p-w-xs">Количество голосов, %</th>
									<th class="text-center p-w-xs">Доля от присутствующих, %</th>
								</tr>
							</thead>
							<tbody class="text-center">
								<tr>
									<th colspan="10" class="text-left bg-light">Участники собрания с правом голоса</th>
								</tr>
								{#if isPresent(participantsWithRight)}
									{#each participantsWithRight as row (row.id)}
										<tr class="cursor_pointer" on:click={() => handleEditParticipant(row.id)}>
											{#if $form.form == 'facetoface'}
												<td class="text-center">{row.time_at || ''}</td>
											{/if}
											<td class="text-left">{row.name}</td>
											<td class="text-left">{row.kind == 'representative' ? row.representative_name || '' : ''}</td>
											<td class="text-right">{formatSum(row.voting_sum)}</td>
											<td class="text-right">{formatSum(row.proc_sum)}</td>
											<td class="text-right">{formatSum(row.proc_present_sum)}</td>
										</tr>
									{/each}
								{:else}
									<tr><td colspan="10" class="text-center text-muted">Нет данных</td></tr>
								{/if}
								<tr>
									<th colspan="10" class="text-left bg-light">Участники собрания без права голоса</th>
								</tr>
								{#if isPresent(participants_without_right)}
									{#each participants_without_right as row (row.id)}
										<tr class="cursor_pointer" on:click={() => handleEditParticipant(row.id)}>
											<td class="text-center">{row.time_at || ''}</td>
											<td class="text-left">{row.name}</td>
											<td class="text-left">{row.kind == 'representative' ? row.representative_name || '' : ''}</td>
											<td class="text-center">-</td>
											<td class="text-center">-</td>
											<td class="text-center">-</td>
										</tr>
									{/each}
								{:else}
									<tr><td colspan="10" class="text-center text-muted">Нет данных</td></tr>
								{/if}
							</tbody>
							<tfoot>
								<tr>
									<th class="text-left bg-light" colspan={$form.form == 'facetoface' ? 3 : 2}>ИТОГО:</th>
									<th class="text-right bg-light">{formatSum(toArray(participantsWithRight).reduce((sum, e) => sum + e.voting_sum, 0))}</th>
									<th class="text-right bg-light">{formatSum(total_percent_qty)}</th>
									<th class="text-right bg-light">{formatSum(toArray(participantsWithRight).reduce((sum, e) => sum + e.proc_present_sum, 0))}</th>
								</tr>
							</tfoot>
						</table>

						{#if noQuorum}
							<div class="meeting_did_not_take_place">
								<div>Собрание не состоялось из-за отсутствия кворума</div>
							</div>
						{/if}
					</div>
				</div>
			</div>
			<div class="tab-pane overlay" class:active={page == 'bulletins'}>
				{#if !editBulletinsMode}
					<div class="toolbar">
						<div class="flex-slot m-b-sm">
							{#if $form.status != 'canceled'}
								<ul class="nav">
									<li class="m-r-sm" style="display: flex;">
										<div class="raf__with-tooltip">
											<button class="btn btn-sm btn-warning" on:click={handleNewAdditionalQuestion} disabled={pageBulletinsDisabled}> + Доп. вопрос </button>
											{#if pageBulletinsDisabled && $form.bulletin_status == 'draft'}
												<div class="raf__tooltip">Предыдущая вкладка должна быть утверждена, последующие - нет</div>
											{/if}
										</div>
									</li>
									<li class="m-r-sm" style="display: flex;">
										{#if $form.bulletin_status == 'draft' && $form.status != 'canceled'}
											<div class="raf__with-tooltip">
												<button
													class="btn btn-sm btn-primary"
													class:btn-primary={!pageBulletinsDisabled}
													class:btn-default={pageBulletinsDisabled}
													disabled={pageBulletinsDisabled || pageBulletinsApproveDisabled}
													on:click={handleBulletinStatusChange}
												>
													Утвердить
												</button>
												{#if pageBulletinsDisabled}
													<div class="raf__tooltip">Недоступно. Сначала утвердите предыдущую вкладку.</div>
												{/if}
											</div>
										{:else if $form.status != 'canceled'}
											<button class="btn btn-sm btn-warning" disabled={pageBulletinsApproveDisabled} on:click={handleBulletinStatusChange}> Изменить </button>
										{/if}
									</li>
								</ul>
								<ul class="nav">
									<li class="m-r-md zip-download">
										{#if bulletinZipType === 'all'}
											{#if bulletinZipStatus == 'complete'}
												<a href on:click|preventDefault={downloadBulletinsFile}> Файл бюллетеней готов (скачать) </a>
											{:else if isPresent(bulletinZipMessage)}
												{bulletinZipMessage}
											{/if}
										{:else}
											Файл бюллетеней еще не сформирован
										{/if}
									</li>
									<li class="m-r-md">
										{#if !pageBulletinsDisabled}
											<DownloadIcon disabled={true} />
										{:else}
											<a href on:click|preventDefault={() => handleOpenDownloadBulletins('all')}>
												<DownloadIcon />
											</a>
										{/if}
									</li>
								</ul>
							{/if}
						</div>
					</div>
					<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
					<div class="content-body-wrapper">
						<div class="content-body">
							<div class="bulletins">
								<div>Бюллетени по всем участникам</div>
								<ol>
									{#if isPresent(participants_for_bulletins)}
										{#each toArray(data.questions).filter(e => e.voting) as row (row.id)}
											<li>
												<a href on:click|preventDefault={() => !pageBulletinsDisabled && handleEditBulletinsOfQuestion(row.id)}>
													Бюллетени по вопросу: {row.name}
												</a>
											</li>
										{/each}
									{:else}
										<div class="mt-1">Нет документов</div>
									{/if}
								</ol>
							</div>
							<div class="bulletins">
								<div>Бюллетени по всем вопросам</div>
								<ol>
									{#if isPresent(toArray(data.questions).filter(e => e.voting))}
										{#each participants_for_bulletins as row (row.id)}
											<li>
												<a href on:click|preventDefault={() => !pageBulletinsDisabled && handleEditBulletinsOfParticipant(row.id)}>
													Бюллетени: {row.name}
												</a>
											</li>
										{/each}
									{:else}
										<div class="mt-1">Нет документов</div>
									{/if}
								</ol>
							</div>
							<div class="bulletins">
								<a href class:bulletinsBlanksLoading on:click|preventDefault={!bulletinsBlanksLoading && dowloadBulletinTemplates}>
									Бланки бюллетеней ({bulletinsBlanksLoading ? '... идет подготовка  ...' : 'скачать'})
								</a>
							</div>
						</div>
					</div>
				{:else}
					<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
					<EditorOfBulletins
						bind:this={ctrlEditorOfBulletins}
						params={editBulletinsOfParticipant ? { participant_id: editBulletinsOfParticipant } : { question_id: editBulletinsOfQuestion }}
						on:delete={handleDeleteEditorOfBulletins}
						on:change={handleChangeEditorOfBulletins}
						on:close={() => (editBulletinsMode = editBulletinsOfQuestion = editBulletinsOfParticipant = false)}
					/>
				{/if}
			</div>
			<div class="tab-pane overlay" class:active={page == 'votes'}>
				{#if page == 'votes' && $form && $form.id}
					<div class="toolbar">
						<div class="flex-slot m-b-sm">
							<ul class="nav">
								<li class="m-r-sm" style="display: flex;">
									{#if $form.status != 'canceled'}
										{#if $form.voting_status == 'draft'}
											<div class="raf__with-tooltip">
												<button
													class="btn btn-sm"
													class:btn-primary={!pageVotingDisabled}
													class:btn-default={pageVotingDisabled}
													disabled={pageVotingDisabled || pageVotingApproveDisabled || (!pageVotingReady && $form.voting_meeting_status == 'held')}
													on:click={handleVotingStatusChange}
												>
													Утвердить
												</button>
												{#if pageVotingDisabled || (!pageVotingReady && $form.voting_meeting_status == 'held')}
													<div class="raf__tooltip">
														{pageVotingDisabled
															? 'Недоступно. Сначала утвердите предыдущую вкладку'
															: !pageVotingReady && $form.voting_meeting_status == 'held'
																? 'Не завершено голосование по всем вопросам'
																: null}
													</div>
												{/if}
											</div>
											<div class="btn btn-sm raf__with-tooltip">
												<IconInfo />
												<div class="raf__tooltip">Фиксирует документ в pdf, защищает его от случайных изменений</div>
											</div>
										{:else}
											<button class="btn btn-sm btn-warning" disabled={pageVotingApproveDisabled} on:click={handleVotingStatusChange}> Изменить </button>
										{/if}
									{/if}
								</li>
							</ul>
						</div>
					</div>
					<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
					<div class="content-body-wrapper">
						<div class="content-body">
							<div style="width: 450px;">
								<!-- Статус собрания -->
								<Field name="form" label="Статус собрания" disabled={pageVotingDisabled || pageVotingApproveDisabled}>
									{#each toArray(data.voting_meeting_statuses) as option}
										<label class="d-flex">
											<input
												type="radio"
												bind:group={$form.voting_meeting_status}
												class="align-self-center m-r-xs"
												value={option.value}
												disabled={pageVotingDisabled || pageVotingApproveDisabled || noQuorum}
											/>
											{option.label}
										</label>
									{/each}
								</Field>
							</div>
							<table class="table border-collapse_separate">
								<thead class="thead-light">
									<tr>
										<th class="text-center p-w-xs">Вопросы/Участники</th>
										<th class="text-center p-w-xs" colspan="3" style="min-width: 500px;">Результаты голосования</th>
										<th class="text-center p-w-xs"></th>
									</tr>
								</thead>
								<tbody>
									{#each toArray(data.questions) as question, i (question.id)}
										<tr>
											<th>{i + 1}. {question.name}</th>
											{#if ['simple', 'inclusion', 'additional'].includes(question.bulletin_form)}
												<th class="text-center" width="100px">За</th>
												<th class="text-center" width="100px">Против</th>
												<th class="text-center" width="100px">Воздержался</th>
											{:else}
												<th colspan="3"></th>
											{/if}
											<th class="nowrap" style="display: flex;">
												<input
													id="meeting_question_{question.id}_voting_invalid"
													data-id={question.id}
													data-name="voting_invalid"
													type="checkbox"
													class="align-self-center m-r-sm m-l-xs"
													disabled={pageVotingDisabled || pageVotingApproveDisabled || !question.voting || $form.voting_meeting_status == 'not_held'}
													bind:checked={question.voting_invalid}
													on:change={handleVotingQuestionChange}
												/>
												<label class="label-text" for="meeting_question_{question.id}_voting_invalid">Голосование не проводилось</label>
											</th>
										</tr>
										{#if !question.voting_invalid}
											{#if question.bulletin_form == 'election'}
												<tr>
													<td class="pl-4">Количество вариантов</td>
													<td colspan="3" style="padding-bottom: 0px;">
														<table class="table table-election table-borderless table-condensed">
															<tr>
																<td class:field-success={isPresent(question.voting_election_qty)}>
																	<Select
																		data-id={question.id}
																		data-name="voting_election_qty"
																		name="voting_election_qty"
																		items={data.voting_quantities}
																		value={question.voting_election_qty}
																		onchange={handleVotingQuestionChange}
																		placeholder="Выбрать вариант"
																		isDisabled={pageVotingDisabled ||
																			pageVotingApproveDisabled ||
																			question.voting_invalid ||
																			$form.voting_meeting_status == 'not_held'}
																	/>
																</td>
																<td></td>
															</tr>
														</table>
													</td>
													<td></td>
												</tr>
											{/if}
											{#each toArray(data.bulletins).filter(e => e.question_id == question.id) as bulletin (bulletin.id)}
												{@const variant_sum = bulletin.meeting_bulletin_variants.reduce((sum, e) => sum + 1.0 * e.sum, 0)}
												{@const participant = participantById(bulletin.participant_id)}
												{@const participant_sum =
													question.secured_votes == 'accounted'
														? 1.0 * participant.voting_sum * question.voting_election_qty
														: 1.0 * participant.unsecured_voting_sum * question.voting_election_qty}
												{@const mismatched_sum = Math.ceil(participant_sum) != Math.ceil(variant_sum)}
												<tr>
													<td class="pl-4">{participant.name}</td>
													{#if ['simple', 'inclusion', 'additional'].includes(question.bulletin_form)}
														{#each ['positive', 'negative', 'neutral'] as val (val)}
															<td class="text-center">
																<input
																	type="radio"
																	data-id={bulletin.id}
																	data-question-id={question.id}
																	data-name="voting_value"
																	bind:group={bulletin.voting_value}
																	on:change={handleVotingBulletinChange}
																	class="align-self-center m-r-xs"
																	value={val}
																	disabled={pageVotingDisabled ||
																		pageVotingApproveDisabled ||
																		question.voting_invalid ||
																		bulletin.voting_invalid ||
																		$form.voting_meeting_status == 'not_held'}
																/>
															</td>
														{/each}
													{:else if question.bulletin_form == 'variants'}
														<td colspan="3" class:field-success={isPresent(bulletin.voting_value)}>
															<!-- items={data.voting_variants.filter(e => e.meeting_question_id == question.id)} -->
															<Select
																data-id={bulletin.id}
																data-question-id={question.id}
																data-name="voting_value"
																name="voting_value"
																items={data.voting_variants.filter(e => e.meeting_question_id == question.id)}
																value={bulletin.voting_value}
																on:select={e => createVotingVariant(question.id, bulletin.id, 'voting_value', e.detail)}
																isCreatable={true}
																placeholder="Выбрать или добавить вариант"
																field-success
																isDisabled={pageVotingDisabled ||
																	pageVotingApproveDisabled ||
																	question.voting_invalid ||
																	bulletin.voting_invalid ||
																	$form.voting_meeting_status == 'not_held'}
															></Select>
														</td>
													{:else if question.bulletin_form == 'quantity'}
														<td colspan="2" class:field-success={isPresent(bulletin.voting_value)}>
															<Select
																data-id={bulletin.id}
																data-question-id={question.id}
																data-name="voting_value"
																name="voting_value"
																items={data.voting_quantities}
																value={bulletin.voting_value}
																onchange={handleVotingBulletinChange}
																placeholder="Выбрать вариант"
																isDisabled={pageVotingDisabled ||
																	pageVotingApproveDisabled ||
																	question.voting_invalid ||
																	bulletin.voting_invalid ||
																	$form.voting_meeting_status == 'not_held'}
															></Select>
														</td>
														<td></td>
													{:else if question.bulletin_form == 'election'}
														<td colspan="3">
															{#if bulletin.meeting_bulletin_variants.length}
																<table class="table table-election table-borderless table-condensed">
																	{#each bulletin.meeting_bulletin_variants as variant (variant.id)}
																		<tr>
																			<td class:field-success={isPresent(variant.meeting_question_variant_id)}>
																				<Select
																					items={data.voting_variants.filter(e => e.meeting_question_id == question.id)}
																					value={variant.meeting_question_variant_id}
																					on:select={e => createVotingElection(question.id, bulletin.id, variant.id, 'meeting_question_variant_id', e.detail)}
																					isCreatable={true}
																					placeholder="Выбрать или добавить вариант"
																					isDisabled={pageVotingDisabled ||
																						pageVotingApproveDisabled ||
																						question.voting_invalid ||
																						bulletin.voting_invalid ||
																						$form.voting_meeting_status == 'not_held'}
																				></Select>
																			</td><td class:field-success={isPresent(variant.sum)}>
																				<InputMoney
																					name="sum"
																					value={variant.sum}
																					onchange={e => updateVotingBulletinElection(question.id, bulletin.id, variant.id, 'sum', e.target.value)}
																					disabled={pageVotingDisabled ||
																						pageVotingApproveDisabled ||
																						question.voting_invalid ||
																						bulletin.voting_invalid ||
																						$form.voting_meeting_status == 'not_held'}
																					klass="form-control text-right"
																				/>
																			</td>
																		</tr>
																	{/each}
																	<tr>
																		<th style="te">ИТОГО:</th>
																		<td>
																			<InputMoney
																				value={variant_sum}
																				disabled={true}
																				klass={mismatched_sum
																					? 'form-control text-right font-weight-bold border-danger text-danger'
																					: 'form-control text-right font-weight-bold'}
																			/>
																		</td>
																	</tr>
																	{#if mismatched_sum}
																		<tr>
																			<td></td>
																			<td
																				><span class="form-text text-muted m-b-none m-l-xs"
																					>Отличается от количества голосов участника ({formatSum(participant_sum)})</span
																				></td
																			>
																		</tr>
																	{/if}
																</table>
															{/if}
														</td>
													{:else}
														<td colspan="3"></td>
													{/if}
													<td class="nowrap">
														<input
															id="meeting_participant_{bulletin.id}_voting_invalid"
															data-id={bulletin.id}
															data-question-id={question.id}
															data-name="voting_invalid"
															type="checkbox"
															class="align-self-center m-r-sm m-l-xs"
															disabled={pageVotingDisabled || pageVotingApproveDisabled || question.voting_invalid || $form.voting_meeting_status == 'not_held'}
															checked={bulletin.voting_invalid}
															on:change={handleVotingBulletinChange}
														/>
														<label class="label-text" for="meeting_participant_{bulletin.id}_voting_invalid">Бюллетень недействителен</label>
													</td>
												</tr>
											{/each}

											{#if ['simple', 'inclusion', 'additional'].includes(question.bulletin_form)}
												<tr>
													<th class="pl-4">Результат по всем участникам</th>
													<th class="text-center" width="100px">{formatSum(question.positive_amount)}%</th>
													<th class="text-center" width="100px">{formatSum(question.negative_amount)}%</th>
													<th class="text-center" width="100px">{formatSum(question.neutral_amount)}%</th>
													<th></th>
												</tr>
											{:else if question.bulletin_form == 'variants' || question.bulletin_form == 'quantity'}
												<tr>
													<th class="pl-4">Результат по всем участникам</th>
													<td colspan="4"></td>
												</tr>
												{#each toArray(question.voting_variants) as variant (variant.id)}
													<tr>
														<td class="pl-4">{variant.name}</td>
														<th>{formatSum(variant.value)}%</th>
														<td></td>
														<td></td>
														<td></td>
													</tr>
												{/each}
											{:else if question.bulletin_form == 'election'}
												<tr>
													<th class="pl-4">Результат по всем участникам</th>
													<td colspan="4"></td>
												</tr>
												{#each toArray(question.voting_variants) as variant (variant.id)}
													<tr>
														<td class="pl-4">{variant.name}</td>
														<th>{formatSum(variant.value)} руб.</th>
														<td></td>
														<td></td>
														<td></td>
													</tr>
												{/each}
											{:else}
												<tr>
													<th class="pl-4">Результат по всем участникам</th>
													<th colspan="4"></th>
												</tr>
											{/if}

											<tr>
												<th class="pl-4">Принятое решение</th>
												<td colspan="3" class:field-success={isPresent(question.conclusion)}>
													<textarea
														rows="3"
														class="form-control"
														data-id={question.id}
														data-name="conclusion"
														value={question.conclusion}
														disabled={pageVotingDisabled ||
															pageVotingApproveDisabled ||
															question.voting_invalid ||
															!question.all_voted ||
															$form.voting_meeting_status == 'not_held'}
														on:change={handleVotingQuestionChange}
														placeholder="Формулировка решения"
													/>
													<span class="form-text text-muted m-b-none m-l-xs">{question.hint}</span>
												</td>
												<td></td>
											</tr>
										{/if}
									{/each}
								</tbody>
							</table>
						</div>
					</div>
				{/if}
			</div>
			<div class="tab-pane overlay" class:active={page == 'protocol'}>
				{#if page == 'protocol' && $form && $form.id}
					<EditorOfProtocol {pageProtocolDisabled} {pageProtocolApproveDisabled} meeting={$form} {data} on:changeProtocolStatus={handleProtocolStatusChange} />
				{/if}
			</div>
			<div class="tab-pane overlay" class:active={page == 'result-message'}>
				{#if page == 'result-message' && $form && $form.id}
					<EditorOfResultMessage
						{pageResultMessageDisabled}
						meeting={$form}
						{data}
						on:changeResultMessageStatus={handleResultMessageStatusChange}
						on:publishedSolutionsEFRSB={handlePublishedSolutionsEFRSB}
					/>
				{/if}
			</div>
		</div>
	</div>
</Form>

<SelectNotificationsModal bind:this={selectNotificationsModal} storage={data.notifications} on:create={handleCreateNotifications} />
<MeetingQuestionModal
	bind:this={meetingQuestionModal}
	storage={data.questions}
	on:create={refreshMeeting}
	on:update={refreshMeeting}
	on:delete={refreshMeeting}
/>
<ParticipantModal bind:this={participantModal} storage={data.participants} on:create={refreshMeeting} on:update={refreshMeeting} on:delete={refreshMeeting} />
<AdditionalQuestionModal
	bind:this={additionalQuestionModal}
	storage={data.questions}
	on:create={refreshMeeting}
	on:update={refreshMeeting}
	on:delete={refreshMeeting}
/>
<SelectBulletinsModal bind:this={selectBulletinsModal} on:download={handleDownloadBulletins} />

<Modal modalId="print-registration-modal" size="small">
	<div slot="footer" class="d-flex justify-content-around">
		<a
			download
			href="/api/private/meetings/{$form.id}/download_registration?type=pdf"
			class="download-icon btn btn-primary btn-outline"
			on:click={() => hideModal('print-registration-modal')}
		>
			<PdfIcon />
			<span class="m-l-xs">pdf</span>
		</a>
		<a
			download
			href="/api/private/meetings/{$form.id}/download_registration?type=docx"
			class="download-icon btn btn-primary btn-outline"
			on:click={() => hideModal('print-registration-modal')}
		>
			<DocxIcon />
			<span>docx</span>
		</a>
	</div>
</Modal>

<style lang="scss">
	.cursor-default {
		cursor: default !important;
	}
	.table-election {
		margin-bottom: 0px;
		td {
			padding-top: 0px;
			padding-bottom: 8px;
			&:first-child {
				width: 60%;
				padding-left: 0px;
			}
			&:last-child {
				width: 60%;
				padding-right: 0px;
			}
		}
	}
	table td,
	th {
		vertical-align: middle;
	}
	.tabs-container {
		width: 80%;
		&.fullwidth {
			width: 100%;
		}
		.tab-content > .tab-pane {
			margin-bottom: 20px;
		}
		.nav-tabs {
			border-bottom: none;
		}
	}
	.toolbar {
		border-bottom: 1px solid #e7eaec;
		margin-left: -20px;
		& > div {
			padding-left: 20px;
		}
		.nav > li > a {
			padding: 0px;
		}
	}
	.content-body-wrapper {
		margin-top: 20px;

		& > .content-body {
			overflow: auto;
			height: calc(100vh - 92px);
			// padding-right: 20px;
		}
		.param-page {
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
	.registration-time {
		display: flex;
		align-items: baseline;
		.delimeter {
			margin: 0 10px;
		}
	}
	:global(#from_at.field-success),
	:global(#to_at.field-success),
	:global(#read_from_at.field-success),
	:global(#read_to_at.field-success) {
		border: 1px solid #1ab394 !important;
		&:disabled {
			border: 1px solid #aaaaaa !important;
		}
	}
	:global(#from_at.required-border),
	:global(#to_at.required-border),
	:global(#read_from_at.required-border),
	:global(#read_to_at.required-border) {
		border: 1px solid #f8ac59 !important;
		&:disabled {
			border: 1px solid #aaaaaa !important;
		}
	}
	textarea.form-control {
		z-index: auto;
	}
	/*.file-download,
  .file-print {
    cursor: pointer;
  }*/
	.draft {
		color: red;
	}
	.badge {
		display: block;
		max-width: fit-content;
		cursor: pointer;
		margin: 0 0 5px 0;
		border-radius: 16px;
		height: 32px;
		line-height: 32px;
		text-align: left;
		padding: 0 10px 0 10px;
		font-size: inherit;
		font-weight: inherit;
		background-color: #ebedef;
	}
	.download-icon {
		padding: 10px;
		min-height: unset;
		fill: #1ab394;
		&:hover {
			fill: #fff;
		}
	}
	.add-standard-questions {
		cursor: pointer;
		color: #337ab7;
		text-decoration: underline;
	}
	.bulletins {
		li {
			text-decoration: underline;
		}
	}
	.zip-download {
		a {
			text-decoration: underline;
			background-color: transparent;
			color: #337ab7;
			font-weight: initial;
		}
	}
	.label-text {
		margin-bottom: 0;
	}
	:global(.field-success) {
		:global(.selectContainer),
		:global(.form-control) {
			border: 1px solid #1ab394 !important;
		}
		:global(& > input) {
			border: 1px solid #1ab394 !important;
		}
	}
	.bulletinsBlanksLoading {
		color: grey;
	}
	.meeting_did_not_take_place {
		margin-top: 8px;
		div {
			padding: 8px 0px;
			background: #f9cb9c;
			font-weight: bold;
			width: 75%;
			margin: 0 auto;
			text-align: center;
		}
	}
	.meeting_reconvened_dont_change {
		margin-bottom: 8px;
		div {
			padding: 8px 0px;
			background: #f9cb9c;
			font-weight: bold;
			width: 100%;
			margin: 0 auto;
			text-align: center;
		}
	}
	@media (max-width: 576px) {
		.tabs-container {
			.nav-tabs {
				overflow: auto;
				flex-wrap: nowrap;
				.nav-link {
					white-space: nowrap;
				}
			}
		}
	}
</style>
