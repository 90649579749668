<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveCheckbox from '~/svelte/components/ui/autosave_checkbox.svelte';

	$: bankruptIsIndividualEntrepreneur = {
		...$commonParams,
		attribute: 'bankrupt_is_individual_entrepreneur',
		label: 'Должник является ИП',
		value: $currentData && $currentData.bankruptIsIndividualEntrepreneur,
		text: 'Да, является',
	};
</script>

<AutosaveCheckbox {...bankruptIsIndividualEntrepreneur} on:update />
