<!-- @format -->
<script>
	import { bankruptIsPerson, bankruptIsOrganization, options } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { currencyFormat } from 'utils/formats';

	export let receivable;
	export let openReceivableModalForm;
	export let columns;
	export let index;
	export let totalDebtAmount;

	const calculateShareInTotalDebt = debtAmount => {
		const receivableDebtAmount = parseFloat(debtAmount || 0);
		const notZeroTotalDebtAmount = totalDebtAmount === 0 ? 1 : totalDebtAmount;
		return Math.round((parseFloat(receivableDebtAmount) / notZeroTotalDebtAmount) * 100);
	};

	const showValue = id => $options.valuer.map(valuer => (valuer.id == id ? valuer.text : '')).join('');
</script>

<tr class="cursor_pointer" on:click={(($bankruptIsPerson && $procedureAvailable) || $bankruptIsOrganization) && openReceivableModalForm(receivable.id)}>
	{#if columns.index}
		<td>{columns.index && index + 1}</td>
	{/if}
	<td class="text-left">{receivable.counterparty_name || (columns.receivable_name && receivable.receivable_name) || ''}</td>
	<td class="text-right">{receivable.debt_amount ? currencyFormat(receivable.debt_amount) : ''}</td>
	{#if columns.debt_amount_percentage}
		<td class="text-right">{calculateShareInTotalDebt(receivable.debt_amount)}%</td>
	{/if}
	{#if columns.paid_sum}
		<td class="text-right">{receivable.paid_sum ? currencyFormat(receivable.paid_sum) : ''}</td>
	{/if}
	{#if columns.debt_justification}
		<td class="text-left">{receivable.debt_justification || ''}</td>
	{/if}
	{#if columns.cost}
		<td class="text-right">{receivable.cost ? currencyFormat(receivable.cost) : ''}</td>
	{/if}
	{#if columns.valuer}
		<td class="text-center">{showValue(receivable.valuer) || ''}</td>
	{/if}
	{#if columns.excluded_from_bankruptcy}
		<td class="text-center">{receivable.excluded_from_bankruptcy ? 'Да' : 'Нет'}</td>
	{/if}
	{#if columns.note}
		<td class="text-left">{receivable.note || ''}</td>
	{/if}
</tr>
