<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { procedure, transmitter, options, bankruptIsPerson, bankruptIsOrganization } from 'base_stores';
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch';
	import { isBlank, isPresent } from 'utils/tools';
	import Select2 from '~/svelte/components/ui/select2.svelte';
	import InputCurrency from '~/svelte/components/ui/input_currency.svelte';
	import Checkbox from '~/svelte/components/ui/checkbox.svelte';
	import Input from '~/svelte/components/ui/input.svelte';
	import IntegerInput from '~/svelte/components/ui/input_integer.svelte';
	import Textarea from '~/svelte/components/ui/textarea.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import { showModal, hideModal } from '~/svelte/components/modal.svelte';
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte';
	import Case from 'case';

	export let currencyId;
	export let currencyKind;
	export let modalId;

	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;
	export let disabled = false;

	const dispatch = createEventDispatcher();

	$: nullCurrency = {
		code: null,
		name: null,
		kind: currencyKind,
	};

	$: if (isBlank(currency)) {
		currency = { ...nullCurrency };
	}

	let currency = { ...nullCurrency };
	let counterpartyKind;

	$: method = isPresent(currencyId) ? fetchPut : fetchPost;
	$: data = { ...currency };

	$: formFields = {
		code: {
			required: true,
			model: 'currency',
			attribute: 'code',
			length: 20,
			label: 'Код валюты',
			placeholder: 'BTN',
			disabled,
		},
		name: {
			required: true,
			model: 'currency',
			attribute: 'name',
			length: 256,
			label: 'Название валюты',
			placeholder: 'Bitcoin',
			disabled,
		},
	};

	handleSubmit = () => {
		return method(`/api/private/currencies${currencyId ? `/${currencyId}` : ''}`, data).then(response => {
			//$transmitter = { ...$transmitter, currencies: response.currencies }
			dispatch(currencyId ? 'update' : 'create', response.currency);
			hideModal(modalId);
			currencyId = null;
		});
		//.catch(errors => console.log('errors: ' + JSON.stringify(errors)))
	};

	handleDelete = () => {
		return fetchDelete(`/api/private/currencies/${currencyId}`).then(response => ($transmitter = { ...$transmitter, currencies: response.currencies }));
		//.catch(errors => console.log('errors: ' + JSON.stringify(errors)))
	};

	handleCancel = () => {
		currency = { ...nullCurrency };
		currencyId = null;
	};
</script>

<Input {...formFields.code} bind:value={currency.code} />
<Input {...formFields.name} bind:value={currency.name} />
