<!-- @format -->
<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { options } from 'base_stores';
	import { isPresent, isBlank } from 'utils/tools';

	const dispatch = createEventDispatcher();

	export let handleSubmit;

	export let columns = 1;
	export let currentFields = [];

	let fields = [];
	let allFieldsSelected = false;
	let allSectionFieldsSelected = [];
	let clearedAllFields;

	$: if (isPresent(currentFields) && isBlank(fields) && !clearedAllFields) {
		fields = currentFields
			.map(e => {
				const groups = allFields.find(f => f.code == e)?.metadata?.groups;

				if (!groups || groups.length > 1) {
					return e;
				}

				const group = groups[0];
				return allFields.filter(f => f.metadata?.groups?.includes(group))?.map(f => f.code);
			})
			.flat();
	}

	$: sectionsCodes = $options.groupedFormFields.map(o => o.code);
	$: allFieldsSelected = sectionsCodes.every(c => allSectionFieldsSelected.includes(c));
	$: allSectionFieldsSelected = sectionsCodes.filter(c => sectionFields(c).every(f => fields.includes(f.code)));
	$: allFields = $options.groupedFormFields.map(o => JSON.parse(o.fields).map(f => ({ ...f, section_id: o.id }))).flat();
	$: columns = Math.ceil((sectionsCodes.length + allFields.length) / 20) > 3 ? 3 : Math.ceil((sectionsCodes.length + allFields.length) / 20);

	const sectionFields = sectionCode => JSON.parse($options.groupedFormFields.find(o => o.code == sectionCode).fields);
	const sectionFieldsCodes = sectionCode => sectionFields(sectionCode).map(f => f.code);

	const toggleAllFields = () => {
		fields = allFields.map(f => f.code);
		if (!allFieldsSelected) {
			allSectionFieldsSelected = sectionsCodes;
			clearedAllFields = false;
		} else {
			allSectionFieldsSelected = [];
			fields = fields.filter(f => $options.usedFields.includes(f));
			clearedAllFields = true;
		}
	};

	const toggleAllSectionFields = code => {
		const codes = sectionFieldsCodes(code);
		if (!allSectionFieldsSelected.includes(code)) {
			fields = [...fields, ...codes];
		} else {
			fields = fields.filter(f => !codes.includes(f) || $options.usedFields.includes(f));
		}
	};

	const checkGroup = e => {
		const groups = allFields.find(f => f.code == e.target.value)?.metadata?.groups;

		if (!groups || groups.length > 1) {
			clearedAllFields = fields.length < 2;
			return;
		}

		const group = groups[0];
		const groupFieldsCodes = allFields.filter(f => f.metadata?.groups?.includes(group))?.map(f => f.code);

		const newFields = groupFieldsCodes.some(c => fields.includes(c)) ? fields.filter(c => !groupFieldsCodes.includes(c)) : [...fields, ...groupFieldsCodes];
		clearedAllFields = isBlank(newFields);
		fields = newFields;
	};

	handleSubmit = () => {
		currentFields = fields;
		dispatch('submit', currentFields);
	};
</script>

<label style="display: none;">
	<input class="cursor_pointer" type="checkbox" checked={allFieldsSelected} on:click={toggleAllFields} />
	<div class="section-name">Выбрать все</div>
</label>
<ul class="fields-list mb-0" style={`column-count: ${columns}`}>
	{#each $options.groupedFormFields as section}
		<li>
			<label>
				<input
					class="cursor_pointer"
					type="checkbox"
					checked={allSectionFieldsSelected.includes(section.code)}
					on:click={toggleAllSectionFields(section.code)}
				/>
				<div class="section-name">{section.name}</div>
			</label>
			<ul class="section-fields-list">
				{#each JSON.parse(section.fields) as field}
					<li style="padding-left: 18px;" hidden={isPresent(field.metadata.groups) && !field.metadata.leading}>
						<label>
							<input
								class="cursor_pointer"
								type="checkbox"
								disabled={$options.usedFields.includes(field.code)}
								value={field.code}
								bind:group={fields}
								on:click={checkGroup}
							/>
							<div class="field-name">{field.name}</div>
						</label>
					</li>
				{/each}
			</ul>
		</li>
	{/each}
</ul>

<style lang="scss">
	label {
		display: flex;

		.section-name {
			/*margin-top: -3px;*/
			font-weight: bold;
		}

		input {
			margin-right: 0.6rem;
		}
	}

	.fields-list,
	.section-fields-list {
		padding-left: 0;

		li {
			list-style-type: none;

			.field-name {
				margin-top: -3px;
			}
		}
	}

	.fields-list {
		li {
			margin-top: 0.8rem;
		}
	}
</style>
