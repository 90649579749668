<!-- @format -->
<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { procedure, bankruptIsPerson, bankruptIsOrganization } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { fetchGet } from 'utils/fetch';
	import { isPresent } from 'utils/tools';
	import Header from '~/svelte/components/header.svelte';
	import moment from 'moment';

	let answers = [];
	let answers_requirement = [];
	let answers_other = [];

	$: procedureId = $procedure && $procedure.id;
	$: if (procedureId) {
		fetchGet('/api/private/answers', { procedure_id: procedureId }).then(response => {
			answers = response.answers.sort((a, b) => (b.reply_date > a.reply_date ? 1 : -1));
			answers_other = response.answers_other.sort((a, b) => a.additions.name.localeCompare(b.additions.name));
			answers_requirement = response.answers_requirement.sort((a, b) => (b.reply_date > a.reply_date ? 1 : -1));
		});
	}

	const answerName = answer => `${answer.name || 'Неизвестный отправитель'} ${answer.reply_date ? 'от ' + moment(answer.reply_date).format('DD.MM.YYYY') : ''}`;
	const goToAnswer = answerId => (location.href = `/answers/${answerId}`);
	const goToOutgoing = outgoingId => (location.href = `/documents/outgoing/edit?ids=${outgoingId}`);
	const newAnswer = () => (location.href = '/answers/new');
	const newAnswerRequirement = () => (location.href = '/answers/new?kind=requirement');
	const newAnswerOther = () => (location.href = '/answers/new?kind=other');

	onMount(() => {
		onpageshow = event => {
			if (event.persisted) {
				location.reload();
			}
		};
	});
</script>

<Header><span slot="name">Входящие документы</span></Header>
<div class="m-b-md">
	<div class="col-sm-5 col-lg-8 col-xl-5 overlay m-b-sm">
		<h3 class="documents-title">Ответы на запросы ({(answers && answers.length) || 0})</h3>
		<button
			class="btn btn-sm btn-warning m-r-sm m-b-sm"
			disabled={!$procedureAvailable || !procedureId}
			on:click={procedureId && $procedureAvailable && newAnswer()}
		>
			+ Входящий
		</button>
		{#if isPresent(answers)}
			<ul class="list-unstyled">
				{#each answers as answer, idx}
					<li>
						<div
							class="flex"
							role="button"
							tabindex="0"
							on:click={(($bankruptIsPerson && $procedureAvailable) || $bankruptIsOrganization) && (() => goToAnswer(answer.id))}
							on:keypress|preventDefault
						>
							{#if isPresent(answer.has_scan)}
								<div class="pr-1">
									<iconify-icon icon="ph:paperclip" height="12" />
								</div>
							{:else}
								<div class="pr-3"></div>
							{/if}
							<div class="col-12 cursor_pointer pl-1">
								<span class="done">{idx + 1}. {answerName(answer)}</span>
							</div>
						</div>
					</li>
					{#if isPresent(answer.outgoing)}
						<div
							class="dct_link col-12 cursor_pointer pl-1"
							role="button"
							tabindex="0"
							on:click={() => goToOutgoing(answer.outgoing.id)}
							on:keypress|preventDefault
						>
							<span>{answer.outgoing.text}</span>
						</div>
					{/if}
				{/each}
			</ul>
		{:else}
			<div>Нет документов</div>
		{/if}
	</div>
	<div class="col-sm-5 col-lg-8 col-xl-5 overlay m-b-sm footer-margin">
		<h3 class="documents-title">Прочие ({(answers_other && answers_other.length) || 0})</h3>
		<button
			class="btn btn-sm btn-warning m-r-sm m-b-sm"
			disabled={!$procedureAvailable || !procedureId}
			on:click={procedureId && $procedureAvailable && newAnswerOther()}
		>
			+ Входящий
		</button>
		{#if isPresent(answers_other)}
			<ul class="list-unstyled">
				{#each answers_other as answer, idx}
					<li>
						<div
							class="flex"
							role="button"
							tabindex="0"
							on:click={(($bankruptIsPerson && $procedureAvailable) || $bankruptIsOrganization) && (() => goToAnswer(answer.id))}
							on:keypress|preventDefault
						>
							{#if isPresent(answer.has_scan)}
								<div class="pr-1">
									<iconify-icon icon="ph:paperclip" height="12" />
								</div>
							{:else}
								<div class="pr-3"></div>
							{/if}
							<div class="col-12 cursor_pointer pl-1">
								<span class="done">{idx + 1}. {answer.additions.name}</span>
							</div>
						</div>
					</li>
					{#if isPresent(answer.outgoing)}
						<div
							class="dct_link col-12 cursor_pointer pl-1"
							role="button"
							tabindex="0"
							on:click={() => goToOutgoing(answer.outgoing.id)}
							on:keypress|preventDefault
						>
							<span>{answer.outgoing.text}</span>
						</div>
					{/if}
				{/each}
			</ul>
		{:else}
			<div>Нет документов</div>
		{/if}
	</div>
	<div class="col-sm-5 col-lg-8 col-xl-5 overlay m-b-sm footer-margin">
		<h3 class="documents-title">Входящие с требованиями ({(answers_requirement && answers_requirement.length) || 0})</h3>
		<button
			class="btn btn-sm btn-warning m-r-sm m-b-sm"
			disabled={!$procedureAvailable || !procedureId}
			on:click={procedureId && $procedureAvailable && newAnswerRequirement()}
		>
			+ Входящий
		</button>
		{#if isPresent(answers_requirement)}
			<ul class="list-unstyled">
				{#each answers_requirement as answer, idx}
					<li>
						<div
							class="flex"
							role="button"
							tabindex="0"
							on:click={(($bankruptIsPerson && $procedureAvailable) || $bankruptIsOrganization) && (() => goToAnswer(answer.id))}
							on:keypress|preventDefault
						>
							{#if isPresent(answer.has_scan)}
								<div class="pr-1">
									<iconify-icon icon="ph:paperclip" height="12" />
								</div>
							{:else}
								<div class="pr-3"></div>
							{/if}
							<div class="col-12 cursor_pointer pl-1">
								<span class="done">{idx + 1}. {answerName(answer)}</span>
							</div>
						</div>
					</li>
					{#if isPresent(answer.outgoing)}
						<div
							class="dct_link col-12 cursor_pointer pl-1"
							role="button"
							tabindex="0"
							on:click={() => goToOutgoing(answer.outgoing.id)}
							on:keypress|preventDefault
						>
							<span>{answer.outgoing.text}</span>
						</div>
					{/if}
				{/each}
			</ul>
		{:else}
			<div>Нет документов</div>
		{/if}
	</div>
</div>

<style>
	.dct_link {
		margin-left: 2rem;
		color: #676a6c;
	}

	.dct_link:hover {
		text-decoration: underline;
	}
</style>
