<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { currentData } from '~/svelte/dcts/stores/stores';
	import { toArray, formatSum } from 'utils/tools';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const handleChange = e => {};

	let visible = false;
	let disabled = true;

	$: value = formatSum($currentData.meetingParticipantVotesPercent);

	$: if ($transmitter.meeting) {
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingParticipantVotingPercent') && $currentData.meetingForm == 'absentee';
	}
</script>

{#if visible}
	<Field {value} name="meetingParticipantVotesPercent" label="Количество голосов,%" {disabled}>
		<input
			type="text"
			name="meetingParticipantVotesPercent"
			{value}
			{disabled}
			on:change={handleChange}
			placeholder=""
			class="form-control text-right"
			autocomplete="off"
		/>
	</Field>
{/if}
