<!-- @format -->
<script>
	import { currentData, commonParams, dct, requiredFieldsFilled } from '../../stores/stores';
	import AutosaveDadateAddress from '../../../components/ui/autosave_dadate_input_address.svelte';

	$: if ($dct.form_fields.includes('arbitraryMailingAddress')) {
		$requiredFieldsFilled.arbitraryMailingAddress = !!arbitraryMailingAddress.value;
	}

	$: arbitraryMailingAddress = {
		...$commonParams,
		required: true,
		attribute: 'arbitrary_mailing_address',
		label: 'Почтовый адрес адресата',
		value: $currentData.arbitraryMailingAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
	};
</script>

<AutosaveDadateAddress {...arbitraryMailingAddress} on:update />
