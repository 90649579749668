<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { createForm } from '~/svelte/components/ui/form/svelte-forms-lib';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import { fetchGet, fetchPost, fetchPut } from 'utils/fetch_helpers';
	import { isPresent, isObject, toArray, randomString } from 'utils/tools';
	import SearchBy from '~/svelte/components/ui/dadata/search_by.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import InputPhone from '~/svelte/components/ui/input_phone.svelte';
	import { procedure, autosaveStatus, transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import judicialActRecognizedHint from './judicial_act_recognized_hint';
	import Hint from '~/svelte/components/ui/hint.svelte';

	let procedurePhase;
	let mailingAddressCheck = false;
	let options = { category: [] };
	let isSuccessor;

	const formInstance = createForm({
		onSubmit: values => {
			$autosaveStatus = null;

			if ($isModified && isPresent($procedure) && $procedure.id) {
				const method = values.id ? fetchPut : fetchPost;
				const url = `/api/private/organizations${values.id ? `/${values.id}` : ''}`;
				delete values.id;

				method(url, { organization: values, procedure_id: $procedure && $procedure.id })
					.then(result => {
						$autosaveStatus = 'saved';
						$transmitter = { ...$transmitter, ...result };
					})
					.catch(error => {
						processErrors(error);
						$autosaveStatus = 'not_saved';
					});
			}
		},
	});

	const { form, isModified, handleSubmit, handleChange, updateInitialValues, processErrors } = formInstance;
	const initializeForm = data => {
		const d = isObject(data) ? data : {};
		const fields = (({
			id,
			short_name,
			full_name,
			firm_name,
			reorg_id,
			reorg,
			inn,
			ogrn,
			okved,
			category_id,
			leader_post,
			leader_full_name,
			legal_address_full,
			mailing_address_full,
			phone,
			email,
		}) => ({
			id,
			short_name,
			full_name,
			firm_name,
			reorg_id,
			reorg,
			inn,
			ogrn,
			okved,
			category_id,
			leader_post,
			leader_full_name,
			legal_address_full,
			mailing_address_full,
			phone,
			email,
		}))(d.bankrupt || {});

		procedurePhase = d.phase;
		mailingAddressCheck = isPresent(fields.legal_address_full) && fields.legal_address_full == fields.mailing_address_full;
		updateInitialValues(fields);
		isSuccessor = isPresent($form.reorg_id);
	};

	const partySelected = ({ detail: data }) => {
		if (isObject(data)) {
			$form.full_name = (data.name && data.name.full_with_opf) || '';
			$form.inn = data.inn;
			$form.ogrn = data.ogrn;
			$form.okved = data.okved;
			$form.leader_post = (data.management && data.management.post) || '';
			$form.leader_full_name = (data.management && data.management.name) || '';
			$form.legal_address_full = (data.address && data.address.value) || '';
			$form.mailing_address_full = mailingAddressCheck ? $form.legal_address_full : '';
			$form.phone = toArray(data.phones)[0] || '';
			$form.email = toArray(data.emails)[0] || '';
			$form.category_id = null;
		} else {
			$form.short_name = data;
		}
	};
	const bankruptReorgPartySelected = ({ detail: data }) => {
		if (isObject(data)) {
			$form.reorg.full_name = (data.name && data.name.full_with_opf) || '';
			$form.reorg.ogrn = data.ogrn;
		} else {
			$form.reorg.short_name = data;
		}
	};

	const generateId = name => `input-organization-${name}-${randomString()}`;

	onMount(() => {
		fetchGet('/api/private/bankrupt_categories/get_options').then(result => (options.category = result.options.map(e => ({ value: e.id, label: e.text }))));
	});

	$: initializeForm($transmitter.procedure);
	$: if (!isSuccessor) {
		$form.reorg = {};
	}
	$: if (mailingAddressCheck) $form.mailing_address_full = $form.legal_address_full;
	$: if ($isModified) handleSubmit();
</script>

<div class="m-b-sm overlay procedure__bankrupt-fields bankrupt-organization-fields">
	<h3>{`${procedurePhase === 'debt_restructuring' ? 5 : 6}. Должник`}</h3>

	<Field {formInstance} name="short_name" label="Сокращенное наименование должника">
		<SearchBy
			id={generateId('short_name')}
			method="party"
			bind:value={$form.short_name}
			on:change={partySelected}
			disabled={!$procedureAvailable}
			placeholder="ООО “Компания”"
			autocomplete="off"
		/>
		<Hint judicial_act_recognized={judicialActRecognizedHint({ name: 'short_name', value: $form.short_name })} />
	</Field>
	<Field {formInstance} name="full_name" label="Полное наименование должника">
		<input
			value={$form.full_name}
			id={generateId('full_name')}
			name="full_name"
			placeholder="Общество с ограниченной ответственностью “Компания”"
			on:change={handleChange}
			disabled={!$procedureAvailable}
			class="form-control"
			autocomplete="off"
			type="text"
		/>
		<Hint judicial_act_recognized={judicialActRecognizedHint({ name: 'full_name', value: $form.full_name })} />
	</Field>
	<Field {formInstance} name="firm_name" label="Фирменное наименование должника">
		<input
			value={$form.firm_name}
			id={generateId('firm_name')}
			name="firm_name"
			placeholder="ООО “Компания”"
			on:change={handleChange}
			disabled={!$procedureAvailable}
			class="form-control"
			autocomplete="off"
			type="text"
		/>
	</Field>
	<Field {formInstance} name="inn" label="ИНН должника">
		<input
			value={$form.inn}
			id={generateId('inn')}
			name="inn"
			placeholder="1234567890"
			on:change={handleChange}
			disabled={!$procedureAvailable}
			class="form-control"
			autocomplete="off"
			type="text"
		/>
		<Hint judicial_act_recognized={judicialActRecognizedHint({ name: 'inn', value: $form.inn })} />
	</Field>
	<Field {formInstance} name="ogrn" label="ОГРН должника">
		<input
			value={$form.ogrn}
			id={generateId('ogrn')}
			name="ogrn"
			placeholder="ОГРН должника"
			on:change={handleChange}
			disabled={!$procedureAvailable}
			class="form-control"
			autocomplete="off"
			type="text"
		/>
		<Hint judicial_act_recognized={judicialActRecognizedHint({ name: 'ogrn', value: $form.ogrn })} />
	</Field>
	<Field {formInstance} name="isSuccessor" label="Является правопреемником">
		<div class="d-flex align-items-center checkbox-left col-sm-8 col-lg-9 col-xl-8" style="margin-left: -15px; margin-top: 7px;">
			<input bind:checked={isSuccessor} id="isSuccessor" disabled={!$procedureAvailable} class="align-self-center m-r-sm m-l-xs" type="checkbox" />
			<label class="label-text" for="isSuccessor" style="margin-bottom: 0;">да, является</label>
		</div>
	</Field>
	{#if isSuccessor}
		<Field {formInstance} name={['reorg', 'short_name']} label="Сокращенное наименование реорганизованного ЮЛ">
			<SearchBy
				id={generateId('reorg_short_name')}
				method="party"
				bind:value={$form.reorg.short_name}
				on:change={bankruptReorgPartySelected}
				disabled={!$procedureAvailable}
				placeholder="ООО “Компания”"
				autocomplete="off"
			/>
		</Field>
		<Field {formInstance} name={['reorg', 'full_name']} label="Полное наименование реорганизованного ЮЛ">
			<input
				value={$form.reorg.full_name}
				id={generateId('reorg_full_name')}
				name="reorg_full_name"
				placeholder="Общество с ограниченной ответственностью “Компания”"
				on:change={event => ($form.reorg.full_name = event.target.value)}
				disabled={!$procedureAvailable}
				class="form-control"
				autocomplete="off"
				type="text"
			/>
		</Field>
		<Field {formInstance} name={['reorg', 'ogrn']} label="ОГРН реорганизованного ЮЛ">
			<input
				value={$form.reorg.ogrn}
				id={generateId('reorg_ogrn')}
				name="reorg_ogrn"
				placeholder="1234567890123"
				on:change={event => ($form.reorg.ogrn = event.target.value)}
				disabled={!$procedureAvailable}
				class="form-control"
				autocomplete="off"
				type="text"
				maxlength="13"
			/>
		</Field>
	{/if}
	<Field {formInstance} name="okved" label="ОКВЭД должника">
		<SearchBy
			id={generateId('okved')}
			method="okved"
			bind:value={$form.okved}
			disabled={!$procedureAvailable}
			placeholder="Начните вводить ОКВЭД"
			autocomplete="off"
		/>
		<Hint judicial_act_recognized={judicialActRecognizedHint({ name: 'okved', value: $form.okved })} />
	</Field>
	<Field {formInstance} name="category_id" label="Категория должника">
		<Select
			id={generateId('category_id')}
			name="category_id"
			items={options.category}
			value={$form.category_id}
			onchange={handleChange}
			isDisabled={!$procedureAvailable}
			placeholder="Выбрать категорию"
		></Select>
	</Field>
	<Field {formInstance} name="leader_post" label="Должность руководителя должника">
		<input
			value={$form.leader_post}
			id={generateId('leader_post')}
			name="leader_post"
			placeholder="Директор"
			on:change={handleChange}
			disabled={!$procedureAvailable}
			class="form-control"
			autocomplete="off"
			type="text"
		/>
		<Hint judicial_act_recognized={judicialActRecognizedHint({ name: 'leader_post', value: $form.leader_post })} />
	</Field>
	<Field {formInstance} name="leader_full_name" label="ФИО руководителя должника">
		<input
			value={$form.leader_full_name}
			id={generateId('leader_full_name')}
			name="leader_full_name"
			placeholder="Фамилия Имя Отчество"
			on:change={handleChange}
			disabled={!$procedureAvailable}
			class="form-control"
			autocomplete="off"
			type="text"
		/>
		<Hint judicial_act_recognized={judicialActRecognizedHint({ name: 'leader_full_name', value: $form.leader_full_name })} />
	</Field>
	<Field {formInstance} name="legal_address_full" label="Юридический адрес должника">
		<SearchBy
			method="address"
			id={generateId('legal_address_full')}
			name="legal_address_full"
			placeholder="100000, Московская область, г. Королев, ул. Первая, д. 2"
			bind:value={$form.legal_address_full}
			disabled={!$procedureAvailable}
			autocomplete="off"
		/>
		<Hint judicial_act_recognized={judicialActRecognizedHint({ name: 'legal_address_full', value: $form.legal_address_full })} />
	</Field>
	<Field {formInstance} name="mailing_address_full" label="Почтовый адрес должника" disabled={mailingAddressCheck}>
		<SearchBy
			method="address"
			id={generateId('mailing_address_full')}
			name="mailing_address_full"
			placeholder="100000, Московская область, г. Королев, ул. Первая, д. 2"
			bind:value={$form.mailing_address_full}
			disabled={mailingAddressCheck || !$procedureAvailable}
			autocomplete="off"
		/>

		<Hint judicial_act_recognized={judicialActRecognizedHint({ name: 'mailing_address_full', value: $form.mailing_address_full })} />
		<div class="d-flex align-items-center checkbox-left col-sm-8 col-lg-9 col-xl-8 m-b-sm" style="margin: 0px 0 5px -15px;">
			<input bind:checked={mailingAddressCheck} id="same-address" disabled={!$procedureAvailable} class="align-self-center m-r-sm m-l-xs" type="checkbox" />
			<label class="label-text" for="same-address" style="margin-bottom: 0;">тот же, что и юридический адрес</label>
		</div>
	</Field>
	<Field {formInstance} name="phone" label="Телефон должника">
		<InputPhone id={generateId('phone')} name="phone" bind:value={$form.phone} disabled={!$procedureAvailable} klass="form-control" autocomplete="off" />
	</Field>
	<Field {formInstance} name="email" label="Электронная почта должника">
		<SearchBy method="email" id={generateId('email')} placeholder="mail@mail.ru" bind:value={$form.email} disabled={!$procedureAvailable} autocomplete="off" />
	</Field>
</div>
