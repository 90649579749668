<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInputCurrency from '~/svelte/components/ui/autosave_input_currency.svelte';

	$: hasAdditionalExpenses = $currentData && $currentData.bankruptHasAdditionalExpenses;

	$: monthlyExpensesOfDebtorTherapy = {
		...$commonParams,
		attribute: 'monthly_expenses_of_debtor_therapy',
		label: 'Ежемесячные расходы должника на лечение (руб./мес.)',
		value: $currentData && $currentData.monthlyExpensesOfDebtorTherapy,
		placeholder: '0,00',
	};
</script>

{#if hasAdditionalExpenses}
	<AutosaveInputCurrency {...monthlyExpensesOfDebtorTherapy} />
{/if}
