<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveRadioSelect from '../../components/ui/autosave_radio_select.svelte';

	const formProvidingInformationOptions = [
		{ id: 'paper', text: 'В виде бумажного документа' },
		{ id: 'electronic', text: 'В виде электронного документа' },
	];

	$: formProvidingInformation = {
		...$commonParams,
		attribute: 'form_providing_information',
		label: 'Форма предоставления сведений',
		value: formProvidingInformationOptions.find(option => option.id === $currentData.formProvidingInformation),
		options: formProvidingInformationOptions,
	};
</script>

<AutosaveRadioSelect {...formProvidingInformation} />
