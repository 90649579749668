<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { transmitter } from '~/js/global_stores/base';
	import { currentData, requiredFieldsFilled } from '../../stores/stores';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import { toArray } from '~/js/utils/tools.js';
	import Field from '~/svelte/components/ui/form/_field.svelte';

	const dispatch = createEventDispatcher();

	let items = [];

	const handleChange = e =>
		dispatch('change', {
			model: 'meeting_question',
			id: $currentData.meetingQuestionId,
			attribute: 'bulletin_form',
			value: e.target.value,
		});

	let visible = false;
	let disabled = true;

	$: if ($transmitter.meeting) {
		visible = !toArray($transmitter.meeting.hidden_fields).includes('meetingQuestionBulletinForm');
		disabled =
			toArray($transmitter.meeting.disabled_fields).includes('meetingQuestionBulletinForm') ||
			$transmitter.meeting.bulletin_status == 'approved' ||
			$transmitter.meeting.status == 'canceled';
		items = toArray($transmitter.meeting.bulletin_forms);
	}
	$: $requiredFieldsFilled.meetingQuestionBulletinForm = !!$currentData.meetingQuestionBulletinForm;
</script>

{#if visible}
	<Field value={$currentData.meetingQuestionBulletinForm} required={true} name="meetingQuestionBulletinForm" label="Форма бюллетеня" {disabled}>
		<Select
			{items}
			name="meetingQuestionBulletinForm"
			isClearable={false}
			value={$currentData.meetingQuestionBulletinForm}
			isDisabled={disabled}
			onchange={handleChange}
			containerClasses="form-control"
			placeholder="Выберите форму бюлетеня"
		></Select>
	</Field>
{/if}
