<!-- @format -->
<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter } from 'base_stores';
	import { fetchGet } from 'utils/fetch';
	import { isPresent } from 'utils/tools';
	import moment from 'moment';

	let events = [];
	let eventsDate = moment();

	$: eventsWithReminder =
		events && events.filter(event => event.delay_before_reminder && moment(event.expiration_date).diff(moment(), 'days') <= event.delay_before_reminder);
	$: expiredEvents = events && events.filter(event => moment(event.expiration_date).diff(moment(), 'days') <= 0 && !event.delay_before_reminder);
	$: notificationsAmount = events && eventsWithReminder.length + expiredEvents.length;

	$: if ($transmitter && $transmitter.events) {
		getUndoneEvents();
	}

	const getUndoneEvents = () => {
		fetchGet('/api/private/events/notifications_events')
			.then(response => {
				events = response.events;
				localStorage.setItem('_rafEvents', JSON.stringify(events));
				localStorage.setItem('_rafEventsDate', JSON.stringify(response.events_date));
			})
			.catch(error => console.error(error.message));
	};

	const defineBadgeColor = event => {
		if (event.completed) {
			return 'badge-secondary';
		} else if (moment(event.expiration_date).diff(moment().startOf('day'), 'days') <= 0 && !event.completed) {
			return 'badge-danger';
		} else if (moment(event.expiration_date).diff(moment().startOf('day'), 'days') <= event.delay_before_reminder && !event.completed) {
			return 'badge-warning';
		} else {
			return 'badge-primary';
		}
	};

	const notificationsSortBy = (data, attribute) => {
		return data.sort((a, b) => (a[attribute] > b[attribute] ? 1 : -1));
	};

	onMount(async () => {
		eventsDate = localStorage.getItem('_rafEventsDate');
		const storedEvents = localStorage.getItem('_rafEvents');

		if (!eventsDate || !storedEvents || (!!eventsDate && !moment(eventsDate, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD')))) {
			getUndoneEvents();
		} else {
			events = JSON.parse(storedEvents);
		}
	});
</script>

<div>
	<div class="dropdown">
		{#if notificationsAmount}
			<div class="notification badge" data-toggle="dropdown">
				<p class="mb-0">{notificationsAmount}</p>
			</div>
		{/if}
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" class="dropdown-toggle" type="icon" id="dropdownMenuButton" data-toggle="dropdown">
			<path
				fill="#676a6c"
				d="M12 22a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22zm7-7.414V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v4.586l-1.707 1.707A.996.996 0 0 0 3 17v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-1a.996.996 0 0 0-.293-.707L19 14.586z"
			>
			</path>
		</svg>
		<div class="dropdown-menu notifications__notifications-list">
			{#if isPresent(events)}
				<h3 class="ml-4 mt-3">Напоминания ({notificationsAmount})</h3>
				{#each notificationsSortBy([...expiredEvents, ...eventsWithReminder], 'expiration_date') as notification}
					<a class="dropdown-item pt-1" href={`/calendar?id=${notification.id}`} data-turbolinks="false">
						<div class="row">
							<div class="col-3 pt-1 pr-0">
								<span class="badge {defineBadgeColor(notification)} mb-0">
									{moment(notification.expiration_date).format('DD.MM.YYYY') || ''}
								</span>
							</div>
							<div class="col-9 pl-0 pr-0">
								<span>{notification.name}</span> <br />
								<span class="text-secondary">{notification.procedure_name}</span>
							</div>
						</div>
					</a>
				{/each}
			{/if}
		</div>
	</div>
</div>

<style lang="scss">
	.notification {
		color: white;
		background: #cf1d0c;
		border-radius: 100px;
		width: 25px;
		height: auto;
		overflow: hidden;
		position: absolute;
		margin-top: -5px;
		font-size: 8px;
		z-index: 1;
		font-weight: bold;
	}

	.dropdown-menu {
		left: -350px !important;
		top: 36px !important;
		width: 450px !important;
		max-height: 600px !important;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.dropdown-item {
		white-space: normal !important;
		font-size: 0.8rem !important;
		padding: 0 12px;
	}

	@media (max-width: 576px) {
		.dropdown-menu.notifications__notifications-list {
			position: fixed;
			width: 100% !important;
			left: 0 !important;
			top: 100px !important;
		}
	}
</style>
