<!-- @format -->
<script>
	import { onMount, createEventDispatcher, tick, setContext } from 'svelte';
	import { writable } from 'svelte/store';
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch_helpers';
	import { isPresent } from 'utils/tools';
	import { createForm } from '~/svelte/components/ui/form/svelte-forms-lib';
	import { procedure } from 'base_stores';

	const dispatch = createEventDispatcher();
	const requiredFields = writable([]);

	export let formOptions = {};
	export let isCompleted = false;
	export let isLoaded = false;
	export let isSubmited = false;
	export let isSubmitting = false;
	export let isSubmitFailed = false;
	export let setDictionariesValues = values => {};
	export let setSubmitParams = () => ({});
	export let setInitialazedForm = instance => {};

	let isMounted = false;

	export const submitForm = () =>
		new Promise((resolve, reject) => {
			if (isLoaded && (formOptions.duplicateMode || $isModified)) {
				const values = { ...$form };
				const method = values.id ? fetchPut : fetchPost;
				const url = `/api/private/${formOptions.controller}${values.id ? `/${values.id}` : ''}`;
				let params = values.id
					? Object.keys($modified)
							.filter(key => $modified[key])
							.reduce((h, key) => ({ ...h, [key]: values[key] }), {})
					: values;

				params = { ...params, ...setSubmitParams(params) };
				isSubmitFailed = false;
				isSubmitting = true;
				isSubmited = false;

				method(url, { [formOptions.model]: params })
					.then(result => {
						isSubmited = true;
						isSubmitting = false;

						const { item: resultItem, ...rest } = result;

						if (isPresent(rest)) {
							setDictionariesValues(rest);
						}

						resolve(result);
					})
					.catch(errors => {
						isSubmitting = false;
						isSubmitFailed = true;
						processErrors(errors);
						reject(errors);
					});
			} else {
				resolve();
			}
		});

	export const deleteItem = () =>
		new Promise((resolve, reject) => {
			if ($form.id) {
				isSubmitFailed = false;
				isSubmitting = true;
				isSubmited = false;

				fetchDelete(`/api/private/${formOptions.controller}/${$form.id}`)
					.then(result => {
						isSubmited = true;
						isSubmitting = false;
						resolve($form.id);
					})
					.catch(errors => {
						isSubmitting = false;
						isSubmitFailed = true;
						processErrors(errors);
						reject();
					});
			} else {
				resolve();
			}
		});

	export const getItem = () => $form;

	export const setItem = changes => ($form = { ...$form, ...changes });

	const item = formOptions.item || {};
	const formInstance = createForm({});
	const { form, modified, isModified, isFormCompleted, isValid, updateInitialValues, processErrors } = formInstance;

	setInitialazedForm(formInstance);

	setContext('FormContext', { formInstance, requiredFields });

	onMount(() => {
		const url = `/api/private/${formOptions.controller}/${isPresent(item.id) ? `${item.id}/edit` : 'new'}`;
		const params = { procedure_id: $procedure && $procedure.id, ...(formOptions.queryParams || {}) };

		fetchGet(url, params)
			.then(result => {
				const { item: resultItem, ...rest } = result;

				setDictionariesValues(rest);
				isMounted = true;

				if (item.id) {
					// Edit mode detected
					updateInitialValues(resultItem);
					tick().then(() => (isLoaded = true));
				} else if (formOptions.duplicateMode) {
					// Duplicate mode detected
					const { id, duplicateMode, ...fields } = item;
					updateInitialValues({ ...resultItem, ...fields });
					isLoaded = true;
				} else {
					// New mode detected
					updateInitialValues({ ...resultItem, ...item });
					tick().then(() => (isLoaded = true));
				}

				isMounted = true;

				if (isPresent(rest)) {
					setDictionariesValues(rest);
				}
				//isMounted = true
			})
			.catch(processErrors);

		//isMounted = true
	});

	$: isCompleted = $form && $isValid && isFormCompleted($requiredFields);
	$: isLoaded && dispatch('load');
</script>

{#if isMounted}
	<slot></slot>
{/if}
