/** @format */

import { get } from 'svelte/store';
import { options } from 'base_stores';
import Model from 'models/Model';
import { dctCorrespondentKindsApi } from 'tools/service_api';

export default class DctCorrespondentKind extends Model {
	constructor(json) {
		super(json);
	}

	// class methods

	static subStoreName() {
		return 'dctCorrespondentKinds';
	}

	static new() {
		return new this({
			code: null,
			name: 'Шаблон документа',
		});
	}

	static async loadAsOptions() {
		const response = await dctCorrespondentKindsApi.index({ filters: { dct_category: 'outgoing' } });
		let hasAddressee = false;
		response.correspondent_kinds.map(kind => ({ id: kind.code, text: kind.name })).sort((a, b) => a.text.localeCompare(b.text));
		const correspondentKind = response.correspondent_kinds
			.map(kind => {
				if (kind.code == 'addressee') {
					hasAddressee = true;
				}
				return { id: kind.code, text: kind.name };
			})
			.sort((a, b) => a.text.localeCompare(b.text));
		options.set({
			...get(options),
			correspondentKind: hasAddressee ? correspondentKind : [{ id: 'addressee', text: 'Адресат' }, ...correspondentKind],
		});
	}

	static async addAsOptions(name) {
		const response = await dctCorrespondentKindsApi.create({ name });
		const kind = response.correspondentKind;
		options.set({
			...get(options),
			correspondentKind: [...get(options).correspondentKind, { id: kind.code, text: kind.name }].sort((a, b) => a.text.localeCompare(b.text)),
		});
	}
}
