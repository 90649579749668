<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { bankruptIsPerson, bankruptIsOrganization, options } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { currencyFormat } from 'utils/formats';

	const dispatch = createEventDispatcher();

	export let possession;
	export let indentMultiplier = 0;

	const showValue = (id, arr) => arr.map(item => (item.id == id ? item.text : '')).join('');

	const openPossessiondModalForm = id => dispatch('edit', id);
</script>

<tr class="cursor_pointer" on:click={$procedureAvailable && openPossessiondModalForm(possession.id)}>
	<td class="text-left">
		<span style={`padding-left: ${1 * indentMultiplier}rem;`}>
			{possession.name_ext || ''}
			{#if possession.onlyResidence}
				<span class="badge badge-primary">Единственное жильё</span>
			{/if}
		</span>
	</td>
	<td class="text-right">{possession.cost ? currencyFormat(possession.cost) : ''}</td>
	{#if $bankruptIsPerson}
		<td>{possession.valuer ? showValue(possession.valuer, $options.valuer) : ''}</td>
	{:else if $bankruptIsOrganization}
		<td class="text-right">{possession.market_cost ? currencyFormat(possession.market_cost) : ''}</td>
	{/if}
	<td>{possession.excluded_from_bankruptcy ? 'Да' : 'Нет'}</td>
	<td>{possession.is_pledge_subject ? 'Да' : 'Нет'}</td>
</tr>
