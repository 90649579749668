<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatetimepicker from '~/svelte/components/ui/autosave_datetimepicker.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: completionDateTime = {
		...$commonParams,
		attribute: 'completion_date_time',
		label: 'Дата и время заседания по результатам процедуры',
		value: $currentData && $currentData.completionDateTime,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг чч:мм',
		hintData:
			($procedure && $procedure.hearing_date_and_time) === ($currentData && $currentData.completionDateTime)
				? {
						url: `/procedures/${procedureId}`,
						linkText: 'Процедура',
						consumerNew: $newObject,
						resource: $procedure,
						resourceProp: 'hearing_date_and_time',
					}
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveDatetimepicker {...completionDateTime} />
