<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInput from '../../components/ui/autosave_input.svelte';

	$: numberSheetsDocumentsForSaleProperty = {
		...$commonParams,
		attribute: 'number_sheets_documents_for_sale_property',
		label: 'Количество листов документов по продаже имущества (приложение)',
		value: $currentData && $currentData.numberSheetsDocumentsForSaleProperty,
		placeholder: '5',
	};
</script>

<AutosaveInput {...numberSheetsDocumentsForSaleProperty} />
