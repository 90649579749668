/** @format */

require('datatables');
require('iconify-icon');
require('jquery-jeditable');
require('footable');
//require('font-awesome')
require('select2');
require('popper.js');
require('bootstrap');
require('pace');
require('peity');
require('jquery-slimscroll');
require('metismenu');
require('./custom');
