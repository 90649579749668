<!-- @format -->
<script>
	export let offset = true;
	export let checked = false;
	export let disabled = false;
	export let label = null;
	export let labelCssClass;
	export let text = '';
	export let bodyCssClass = '';
	export let additionalStyles = '';

	const offsetCss = 'offset-sm-4 offset-lg-3 offset-xl-4 m-t-n-sm m-b-sm';
	const id = `f${(Math.random() * 1e16).toString(32)}`;

	$: if (label) {
		offset = false;
	}
</script>

<div class="form-group row">
	{#if label}
		<label for={id} class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
			{label}
		</label>
	{/if}
	<div class={`d-flex align-items-center checkbox-left col-sm-8 col-lg-9 col-xl-8 ${offset ? offsetCss : ''} ${bodyCssClass}`} style={additionalStyles}>
		<input type="checkbox" {id} bind:checked {disabled} class="align-self-center m-r-sm m-l-xs" />
		<label class={['label-text', labelCssClass].join(' ')} for={id}>
			{text}
		</label>
	</div>
</div>

<style>
	.label-text {
		margin-bottom: 0;
	}
</style>
