<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;

	$: if ($dct.form_fields.includes('caseNumber')) {
		$requiredFieldsFilled.caseNumber = !!caseNumber.value;
	}

	$: localCommonParams = { ...$commonParams, disabled: $commonParams.disabled || true };

	$: caseNumber = {
		...localCommonParams,
		required: true,
		attribute: 'case_number',
		label: 'Номер дела',
		value: $currentData && $currentData.caseNumber,
		placeholder: 'А00-0000/0000',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: 'substitutions.case_number',
			resource: $procedure && $procedure.arbitr_case,
			resourceProp: 'number',
			fieldDisabled: true,
		},
	};
</script>

<AutosaveInput {...caseNumber} />
