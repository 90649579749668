<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dctFormAvailable } from '~/svelte/dcts/stores/stores';
	import { fetchGet, fetchPut } from 'utils/fetch';
	import { isPresent } from 'utils/tools';
	import PossessionModal from '~/svelte/possessions/_modal.svelte';

	export let fieldId;
	export let answerId;

	let indexCollapse = false;
	let possessionModal;
	let procedureId = null;
	let forceSave = true;

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	$: draftPossessions = ($transmitter && $transmitter.draftPossessions) || draftPossessions || [];
	$: flattedPossessions = Object.values(draftPossessions).flat(1);

	const handleEditPossessionItem = id => possessionModal.editItem(id);

	const loadPossessions = async () => {
		const response = await fetchGet('/api/private/possessions', { procedure_id: procedureId, status: 'draft', answer_id: answerId });
		$transmitter = { ...$transmitter, draftPossessions: response.possessions };
	};

	const approvePossession = async id => {
		const response = await fetchPut(`/api/private/possessions/${id}`, { status: 'approved' });
		$transmitter = {
			...$transmitter,
			draftPossessions: $transmitter.draftPossessions.filter(p => p.id !== id),
			possessions: [...$transmitter.possessions, response.item],
		};
	};

	$: canAddPossession = isPresent($transmitter.dct) ? $dctFormAvailable : true;

	$: if (!$transmitter.draftPossessions) {
		loadPossessions();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:keypress|preventDefault on:click={() => (indexCollapse = !indexCollapse)}>
	<h4 class="draft-possessions col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Распознанные объекты (черновики) (${flattedPossessions.length})`}</span>
	</h4>
</div>
<div id="collapse-draft-possessions" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<div class="p-1">
			<ol class="block-list">
				{#if flattedPossessions.length}
					{#each flattedPossessions as possession}
						<li class="m-b-xs">
							<div
								class="float-left simulated-link pl-0 col-xl-9 col-lg-9 col-sm-8"
								role="button"
								tabindex="0"
								on:keypress|preventDefault
								on:click={handleEditPossessionItem(possession.id)}
							>
								{possession.name_ext}
							</div>
							<button class="action-button btn btn-xs btn-primary cursor_pointer float-right" on:click={approvePossession(possession.id)}> Утвердить </button>
						</li>
					{/each}
				{/if}
			</ol>
		</div>
	</div>
</div>
<PossessionModal
	disabled={!canAddPossession}
	headerAddition={'распознанная'}
	approvable={true}
	bind:this={possessionModal}
	on:approve={({ detail: id }) => approvePossession(id)}
/>
