<!-- @format -->
<script>
	import { options } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';
	import Case from 'case';
	import { isBlank, toArray } from 'utils/tools';
	import { fetchGet } from 'utils/fetch_helpers';

	export let regionAttr = 'region_id';
	export let label;
	export let attrKindName;

	let filteredOptions = [];

	$: filteredOptions = toArray($options.region).filter(option => option.full_name !== 'Российская Федерация');

	$: if (isBlank($options.region)) {
		fetchGet('/api/private/regions/get_options').then(result => ($options.region = result.options));
	}

	$: disabled =
		($dct.disabled_fields && $dct.disabled_fields.includes('regionId')) ||
		($currentData.correspondentKind && ['bki', 'rsa', 'favt', 'fsis', 'bank', 'creditor'].includes($currentData.correspondentKind));

	$: regionId = {
		...$commonParams,
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		attribute: regionAttr,
		label: label || ['Регион', attrKindName || $dct.correspondent_kind_name || ''].join(' '),
		value: $options.region && $options.region.find(option => parseInt(option.id) === parseInt($currentData[Case.camel(regionAttr)])),
		options: [{ id: null, text: '' }, ...filteredOptions],
		placeholder: 'Выбрать регион',
		hintData: { freeMessage: 'AI заполнил на основании Наименование суда' },
	};
</script>

<AutosaveSelect2Search {...regionId} />
