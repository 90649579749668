<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import { transmitter } from '~/js/global_stores/base';
	import { toArray } from '~/js/utils/tools.js';

	let disabled = true;

	$: not_modified = $currentData.meetingAddress == $transmitter.meeting.address;
	$: data = {
		...$commonParams,
		disabled: $commonParams.disabled || disabled,
		attribute: 'meeting_address',
		label: 'Адрес собрания',
		value: $currentData.meetingAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1',
		hintData: {
			freeMessage: not_modified ? 'AI заполнил из Параметры' : 'Изменено пользователем',
		},
	};

	$: disabled = toArray($transmitter.meeting.disabled_fields).includes('meetingAddress');
</script>

<AutosaveInput {...data} on:update />
