<!-- @format -->
<!-- @format -->
<script>
	export let isActive = false;
	export let isFirst = false;
	export let isHover = false;
	export let isSelectable = false;
	export let getOptionLabel = undefined;
	export let item = undefined;
	export let filterText = '';

	let itemClasses = '';

	$: {
		const classes = [];
		if (isActive) {
			classes.push('active');
		}
		if (isFirst) {
			classes.push('first');
		}
		if (isHover) {
			classes.push('hover');
		}
		if (item.isGroupHeader) {
			classes.push('groupHeader');
		}
		if (item.isGroupItem) {
			classes.push('groupItem');
		}
		if (!isSelectable) {
			classes.push('notSelectable');
		}
		itemClasses = classes.join(' ');
	}
</script>

<div class="item {itemClasses}">
	<div>
		{getOptionLabel(item, filterText)}
	</div>
	<div>
		{#if item.specific}
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				aria-hidden="true"
				focusable="false"
				width="1em"
				height="1em"
				preserveAspectRatio="xMidYMid meet"
				viewBox="0 0 24 24"
				class="iconify mt-1 float-right"
				data-icon="bx-bxs-user"
				style="vertical-align: -0.125em; transform: rotate(360deg);"
			>
				<path
					d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2S7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
					fill="currentColor"
				></path>
			</svg>
		{/if}
	</div>
</div>

<style lang="scss">
	.item {
		cursor: default;
		height: var(--height, 42px);
		line-height: var(--height, 42px);
		padding: var(--itemPadding, 0 20px);
		color: var(--itemColor, inherit);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.groupHeader {
		text-transform: var(--groupTitleTextTransform, uppercase);
	}

	.groupItem {
		padding-left: var(--groupItemPaddingLeft, 40px);
	}

	.item:active {
		background: var(--itemActiveBackground, #b9daff);
	}

	.item.active {
		background: var(--itemIsActiveBG, #007aff);
		color: var(--itemIsActiveColor, #fff);
	}

	.item.notSelectable {
		color: var(--itemIsNotSelectableColor, #999);
	}

	.item.first {
		border-radius: var(--itemFirstBorderRadius, 4px 4px 0 0);
	}

	.item.hover:not(.active) {
		background: var(--itemHoverBG, #e7f2ff);
		color: var(--itemHoverColor, inherit);
	}

	.item {
		display: flex;
		align-items: center;

		div:first-child {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			flex-grow: 1;
		}
	}
</style>
