<!-- @format -->
<script>
	import { procedure, newObject, bankruptIsPerson, bankruptIsOrganization, bankruptType } from 'base_stores';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveMultipleInput from '~/svelte/components/ui/autosave_multiple_input.svelte';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: procedureId = $procedure && $procedure.id;
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('bankrupt_full_name');

	$: metaData = {
		Person: {
			label: 'Должник',
			placeholder: 'Фамилия Имя Отчество',
			data: [
				{
					name: 'bankrupt_surname',
					value: showPersonFullName ? $currentData && $currentData.bankruptSurname : null,
					localizedName: 'Фамилия',
				},
				{
					name: 'bankrupt_name',
					value: showPersonFullName ? $currentData && $currentData.bankruptName : null,
					localizedName: 'Имя',
				},
				{
					name: 'bankrupt_patronymic',
					value: showPersonFullName ? $currentData && $currentData.bankruptPatronymic : null,
					localizedName: 'Отчество',
				},
			],
		},
		Organization: {
			label: 'Полное наименование должника',
			value: $currentData.bankruptFullName,
			placeholder: 'Общество с ограниченной ответственностью «Компания»',
		},
	};

	$: otherMetaData = {
		Person: {
			consumerProp: 'substitutions.bankrupt_full_name',
			resourceProp: 'full_name',
			checkRequired: !!$currentData.bankruptSurname,
		},
		Organization: {
			consumerProp: 'substitutions.bankrupt_full_name',
			resourceProp: 'full_name',
			checkRequired: !!$currentData.bankruptFullName,
		},
	};

	$: if ($dct.form_fields.includes('bankruptFullName') && otherMetaData[$bankruptType]) {
		$requiredFieldsFilled.bankruptFullName = otherMetaData[$bankruptType].checkRequired;
	}

	$: bankruptFullName = {
		required: true,
		...$commonParams,
		attribute: 'bankrupt_full_name',
		...metaData[$bankruptType],
		disabled: $commonParams.disabled || disabled || $dct.status === 'approved',
		hintData: {
			url: `/procedures/${procedureId}`,
			linkText: 'Процедура',
			consumerNew: $newObject,
			consumer: $dct,
			consumerProp: otherMetaData[$bankruptType] && otherMetaData[$bankruptType].consumerProp,
			resource: $procedure && $procedure.bankrupt,
			resourceProp: otherMetaData[$bankruptType] && otherMetaData[$bankruptType].resourceProp,
		},
	};

	$: showPersonFullName =
		$bankruptIsPerson && ($dct.kind === 'documentation_acceptance_and_transfer_act' ? $currentData.transferringDocumentsParty === 'bankrupt' : true);
</script>

{#if showPersonFullName}
	<AutosaveMultipleInput {...bankruptFullName} on:update />
{:else if $bankruptIsOrganization}
	<AutosaveInput {...bankruptFullName} on:update />
{/if}
