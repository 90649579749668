<!-- @format -->
<script>
	import { onMount, onDestroy, createEventDispatcher } from 'svelte';
	import InputErrorsString from './input_errors_string.svelte';
	import { isPresent, isBlank } from 'utils/tools';
	import Hint from './hint.svelte';

	export let label;
	export let required = false;
	export let disabled = false;
	export let withSearch = true;
	export let multiple = false;
	export let value = multiple ? [] : null;
	export let options = [];
	export let errors;
	export let modify;
	export let placeholder;
	export let addOption;
	export let hintData;
	export let attribute;
	export let model;
	export let checkSuitability = () => true;
	export let actionInUnsuitability = () => {};

	const dispatch = createEventDispatcher();

	let selectNode = null;
	let showInfo = false;
	let currentPlaceholder;

	const elementId = `select2-${model}-${attribute}`;

	const changeBorder = () => {
		const wrapperBordered = selectNode && selectNode.next().first().find(`#select2-${elementId}-container`).parent();
		const wrapperBorderedMultiple = selectNode && selectNode.next().first().find(`.select2-selection.select2-selection--multiple`);

		if (isPresent(value)) {
			wrapperBordered.removeClass('required-border');
			wrapperBorderedMultiple.removeClass('required-border');

			if (isPresent(errors)) {
				wrapperBordered.removeClass('has-success').addClass('has-error');
				wrapperBorderedMultiple.removeClass('has-success').addClass('has-error');
			} else {
				wrapperBordered.removeClass('has-error').addClass('has-success');
				wrapperBorderedMultiple.removeClass('has-error').addClass('has-success');
			}
		} else if (isBlank(value) || disabled) {
			wrapperBordered.removeClass('has-success').removeClass('has-error');
			wrapperBorderedMultiple.removeClass('has-success').removeClass('has-error');

			if (required) {
				wrapperBordered.addClass('required-border');
				wrapperBorderedMultiple.addClass('required-border');
			} else {
				wrapperBordered.removeClass('required-border');
				wrapperBorderedMultiple.removeClass('required-border');
			}
		}
	};

	const createOption = async data => {
		value = await addOption(data.text);
	};

	const templateResult = state => {
		const optionWithIcon = (options || []).find(option => String(option.id) === String(state.id) && option.user_id);

		let displayedText = document.createElement('span');
		if (!checkSuitability(state)) {
			displayedText.classList.add('unsuitable');
		}
		if (!optionWithIcon) {
			displayedText.textContent = state.text;
			return displayedText;
		} else {
			displayedText.innerHTML = `${state.text} <iconify-icon class='mt-1 float-right' icon='bx-bxs-user'/>`;
		}

		return displayedText;
	};

	const qwert = () => {
		currentPlaceholder = placeholder;

		let select2Options = {
			closeOnSelect: true,
			language: { noResults: () => 'Ничего не найдено...',},//'ru',
			placeholder,
			multiple,
			allowClear: multiple,
			disabled,
			templateResult: templateResult,
			tags: isPresent(addOption),
			data: options,
			createTag: tag => {
				const data = {
					id: tag.term,
					text: tag.term + ' (создать)',
					isNew: true,
				};
				return data;
			},
			matcher: (params, data) => {
				if (!params.term || data.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
					return data;
				}

				return data.id === 'new' ? data : null;
			},
		};

		if (!withSearch) {
			select2Options = { ...select2Options, minimumResultsForSearch: Infinity };
		}

		selectNode.select2(select2Options);

		selectNode.on('select2:open', () => {
			document.querySelector('.select2-search__field').focus();
		});

		selectNode.on('select2:select', event => {
			if (event.params.data.isNew) {
				const data = {
					id: event.params.data.id,
					text: event.params.data.text.split(' ').slice(0, -1).join(' '),
				};
				createOption(data);
			}
			if (modify) {
				modify(event.params.data.id);
			}
			value = multiple ? [...value, event.params.data.id] : event.params.data.id;
			if (!checkSuitability(event.params.data)) {
				actionInUnsuitability(event.params.data);
			}

			dispatch('change', value);
		});

		selectNode.on('select2:unselect', event => {
			value = multiple ? value.filter(item => String(item) !== String(event.params.data.id)) : null;
		});

		selectNode.on('change.select2', () => selectNode.select2('close'));
	};

	$: if (selectNode) {
		selectNode.children('option').detach();

		if (isPresent(options)) {
			options.forEach(option => {
				const newOption = new Option(option.text, option.id, false, false);
				selectNode.append(newOption).trigger('change');
			});
		}

		selectNode.val(value || null).trigger('change');
		selectNode.prop('disabled', disabled);

		changeBorder();
	}

	$: if (selectNode && placeholder != currentPlaceholder) {
		const tempValue = value;
		value = null;

		selectNode.select2('destroy');
		qwert();

		if (isPresent(tempValue)) {
			value = tempValue;
		}

		changeBorder();
	}

	onMount(() => {
		// eslint-disable-next-line no-undef
		selectNode = jQuery(`#${elementId}`);

		qwert();
		changeBorder();
	});

	onDestroy(() => {
		if (selectNode) {
			selectNode.select2('destroy');
		}
	});
</script>

<div class="form-group row select-form">
	<label for={elementId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<div class='select-wrapper'>
			<select id={elementId}></select>
			{#if errors}
				<InputErrorsString {errors} />
			{:else if hintData}
				<Hint {...hintData} />
			{/if}
		</div>
	</div>
	{#if $$slots.info}
		<div
			class="wrapper-info-icon"
			role="button"
			tabindex="0"
			on:mouseover={() => (showInfo = true)}
			on:mouseout={() => (showInfo = false)}
			on:focus={() => (showInfo = true)}
			on:blur={() => (showInfo = false)}
		>
			<svg
				width="16"
				height="16"
				version="1.1"
				id="Capa_1"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 330 330"
				style="enable-background:new 0 0 330 330;"
				xml:space="preserve"
			>
				<g>
					<path
						fill="#676a6c"
						d="M165,0C74.019,0,0,74.02,0,165.001C0,255.982,74.019,330,165,330s165-74.018,165-164.999C330,74.02,255.981,0,165,0z
						M165,300c-74.44,0-135-60.56-135-134.999C30,90.562,90.56,30,165,30s135,60.562,135,135.001C300,239.44,239.439,300,165,300z"
					/>
					<path
						fill="#676a6c"
						d="M164.998,70c-11.026,0-19.996,8.976-19.996,20.009c0,11.023,8.97,19.991,19.996,19.991
						c11.026,0,19.996-8.968,19.996-19.991C184.994,78.976,176.024,70,164.998,70z"
					/>
					<path fill="#676a6c" d="M165,140c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-90C180,146.716,173.284,140,165,140z" />
				</g>
			</svg>
		</div>
		{#if showInfo}
			<slot name="info" />
		{/if}
	{/if}
</div>

<style>
	.form-group.row {
		position: relative;
	}
	.wrapper-info-icon {
		position: absolute;
		top: 0.5rem;
		right: -0.7rem;
	}
	.select-wrapper {
		height: 100%;
	}
</style>
