<!-- @format -->
<script>
	import { currentData, commonParams } from '../stores/stores';
	import AutosaveInput from '../../components/ui/autosave_input.svelte';

	$: numberJudicialActsOnRefusalToIncludedInRequirementsReestr = {
		...$commonParams,
		attribute: 'number_judicial_acts_on_refusal_to_included_in_requirements_reestr',
		label: 'в т.ч. решений об отказе',
		value: $currentData && $currentData.numberJudicialActsOnRefusalToIncludedInRequirementsReestr,
		placeholder: '5',
	};
</script>

<AutosaveInput {...numberJudicialActsOnRefusalToIncludedInRequirementsReestr} />
