<!-- @format -->
<script>
	import { procedure, bankruptIsPerson } from 'base_stores';
	import { onMount } from 'svelte';
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { isPresent } from '~/js/utils/tools';
	import AutosaveWysiwygTextbox from '~/svelte/components/ui/autosave_wysiwyg_textbox.svelte';

	$: data = {
		...$commonParams,
		actions: ['olist', 'ulist', 'removeFormat'],
		attribute: 'analisys_conclusion',
		label: 'Выводы анализа финансового состояния',
		rows: 4,
		value: isPresent($currentData.analisysConclusion) ? $currentData.analisysConclusion : analisysConclusion,
	};

	let forceSave = false;
	let analisysConclusion = '';

	onMount(async () => {
		if (!$bankruptIsPerson) {
			return;
		}

		let response = await fetchGet('/api/private/documents/get_last_financial_state_dct', { procedure_id: $procedure.id });

		const procedurePhase = $procedure.phase;
		const substitutions = response.dct.substitutions;

		const allPossessionsNumber = [
			response.bank_accounts.reduce((sum, current) => sum + Number(current.balance), 0) > 0 || null,
			...response.receivables.filter(receivable => !receivable.excluded_from_bankruptcy),
			...response.possessions.filter(possession => !possession.excluded_from_bankruptcy && !possession.onlyResidence),
		].filter(i => i).length;

		const freeFundsOfBankrupt = substitutions
			? (response.income_sources || []).reduce((totalAmount, source) => totalAmount + parseFloat(source.amount || 0), 0) -
				parseFloat(substitutions.living_wage_for_bankrupt || 0) -
				parseFloat(substitutions.living_wage_for_children || 0) * parseFloat(substitutions.number_of_minor_children_of_bankrupt_for_analysis || 0) -
				parseFloat(substitutions.monthly_expenses_of_debtor_other || 0) -
				parseFloat(substitutions.monthly_expenses_of_debtor_alimony || 0) -
				parseFloat(substitutions.monthly_expenses_of_debtor_therapy || 0)
			: 0;

		const responsibilitiesTotalAmount = [
			...(response.creditors_requirements.filter(requirement => !requirement.excluded_date) || []),
			...(response.undeclared_debts || []),
		].reduce((totalAmount, responsibility) => totalAmount + parseFloat(responsibility.amount || 0), 0);

		const debtRepaymentPeriodYears = Math.round(
			Math.round(responsibilitiesTotalAmount / (freeFundsOfBankrupt && freeFundsOfBankrupt !== 0 ? freeFundsOfBankrupt : 1)) / 12,
		);

		analisysConclusion = $bankruptIsPerson
			? substitutions
				? [
						'<ul>',
						`<li>у должника ${allPossessionsNumber === 0 ? 'не ' : ''}выявлено имущество, на которое возможно обратить взыскание;</li>`,
						substitutions.bankrupt_has_constant_source_of_income
							? freeFundsOfBankrupt > 0
								? debtRepaymentPeriodYears > 5
									? `<li>размер ежемесячного дохода должника не позволяет рассчитаться с кредиторами в течение 5-ти лет;</li>`
									: `<li>размер ежемесячного дохода должника позволяет рассчитаться с кредиторами в течение 5-ти лет;</li>`
								: `<li>размер ежемесячного дохода должника не позволяет рассчитаться с кредиторами в течение 5-ти лет;</li>`
							: `<li>должник не имеет постоянный доход, реструктуризация задолженности невозможна;</li>`,
						substitutions.has_fictitious_willful_bankruptcy_signs
							? `<li>у должника имеются признаки фиктивного и преднамеренного банкротства;</li>`
							: `<li>у должника отсутствуют признаки фиктивного и преднамеренного банкротства;</li>`,
						procedurePhase === 'property_realization'
							? `<li>финансовый управляющий по результатам проведенного анализа финансового состояния должника пришел к выводу о целесообразности процедуры реализации имущества гражданина, по результатам которой ${substitutions.receivable_submitted_documents_to_arbitr_manager ? 'освободить' : 'не освобождать'} должника от обязательств и применить к нему последствия, предусмотренные ст. 213.30 ФЗ «О несостоятельности (банкротстве)».</li>`
							: procedurePhase === 'debt_restructuring'
								? `<li>целесообразно перейти к процедуре реализации имущества гражданина, по результатам которой ${substitutions.receivable_submitted_documents_to_arbitr_manager ? 'освободить' : 'не освобождать'} должника от обязательств и применить к нему последствия, предусмотренные ст. 213.30 ФЗ «О несостоятельности (банкротстве)».</li>`
								: '',
						'</ul>',
					].join('')
				: ''
			: '';

		forceSave = true;
	});
</script>

<AutosaveWysiwygTextbox {...data} bind:forceSave />
