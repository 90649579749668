<!-- @format -->
<script>
	import Modal from '~/svelte/components/modal.svelte';
	import CurrencyForm from './_form.svelte';
	import { cardsTitle } from 'utils/formats';

	export let currencyId;
	export let currencyKind;
	export let modalId = 'form-currency-modal';
	export let disabled = false;
	export let index = 0;

	let handleSubmit = () => {};
	let handleCancel = () => {};
	let handleDelete = () => {};
</script>

<Modal
	{modalId}
	{index}
	deleteIconDisabled={!currencyId || disabled}
	submitButtonAction={handleSubmit}
	cancelButtonAction={handleCancel}
	deleteButtonAction={handleDelete}
	submitButtonDisabled={disabled}
	deleteConfirmMessage="Вы действительно хотите удалить валюту?"
	form={true}
	size="medium"
>
	<h2 slot="header" class="currency-modal__header">{cardsTitle('валюты', currencyId)}</h2>
	<div slot="body">
		<CurrencyForm {modalId} {disabled} {currencyKind} bind:handleSubmit bind:handleCancel bind:handleDelete bind:currencyId on:create on:update />
	</div>
</Modal>
