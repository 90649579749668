<!-- @format -->
<script>
	import { currentData, commonParams, requiredFieldsFilled } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import { transmitter } from '~/js/global_stores/base';
	import { isPresent, toArray } from '~/js/utils/tools.js';

	let disabled = true;

	$: not_modified = isPresent($transmitter.procedure) && $currentData.meetingChairman == $transmitter.procedure.arbitr_manager.person.full_name;
	$: data = {
		...$commonParams,
		disabled: $commonParams.disabled || disabled,
		attribute: 'meeting_chairman',
		label: 'Председатель собрания',
		value: $currentData.meetingChairman,
		placeholder: '',
		required: true,
		hintData: {
			freeMessage: not_modified ? 'AI заполнил ФИО АУ из Процедура' : 'Изменено пользователем',
		},
	};
	$: $requiredFieldsFilled.meeting_chairman = !!data.value;
	$: disabled = toArray($transmitter.meeting.disabled_fields).includes('meetingChairman') || $transmitter.dct.status == 'approved';
</script>

<AutosaveInput {...data} on:update />
