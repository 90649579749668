<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { fetchGet, fetchPut } from 'utils/fetch';
	import { isPresent } from 'utils/tools';

	export let collapsedSyncWithKad = false;

	let arbitrManagers = [];
	let selectedProcedureIds = [];
	let mounted = false;
	let loading = false;

	const toggleProcedures = arbitrManager => {
		selectedProcedureIds = procedureIsSelected(arbitrManager.procedures)
			? selectedProcedureIds.filter(id => !arbitrManager.procedures.map(procedure => procedure.id).includes(id))
			: [...selectedProcedureIds, ...arbitrManager.procedures.map(procedure => procedure.id)];
	};

	$: procedureIsSelected = procedures => selectedProcedureIds.some(value => procedures.map(procedure => procedure.id).includes(value));

	$: if (isPresent(selectedProcedureIds) && mounted) {
		fetchPut('/api/private/user_settings', { user_setting: { efrsb: { sync_procedure_ids: selectedProcedureIds } } });
	}

	onMount(() => {
		loading = true;

		fetchGet('/api/private/user_settings').then(response => (selectedProcedureIds = response.efrsb?.sync_procedure_ids || []));

		fetchGet('/api/private/procedures')
			.then(response => {
				arbitrManagers = response.grouped_procedures.map(am => ({ ...am, procedures: am.procedures.filter(p => !p.completed) }));
				loading = false;
			})
			.catch(_ => (loading = false));

		mounted = true;
	});
</script>

<div
	title={collapsedSyncWithKad ? 'скрыть синхронизацию' : 'показать синхронизацию'}
	role="button"
	tabindex="0"
	on:click={() => (collapsedSyncWithKad = !collapsedSyncWithKad)}
	on:keypress|stopPropagation
>
	<h3 class="ml-1 mt-3" id="sync-with-kad">
		4. Синхронизация Календаря с КАД.Арбитр
		{#if collapsedSyncWithKad}
			<iconify-icon icon="fa-solid:chevron-up" class="m-r-sm hoverable" />
		{:else}
			<iconify-icon icon="fa-solid:chevron-down" class="m-r-sm hoverable" />
		{/if}
	</h3>
</div>
<div class="collapse" class:show={collapsedSyncWithKad} id={`collapse-sync-with-kad`}>
	<div class="col-12 m-b-md p-1 overlay float-right calendar-filter">
		<h4 class="ml-2 mb-2">Выберите процедуры, для которых будут создаваться задачи в Календаре на основе данных КАД.Арбитр.</h4>
		{#if arbitrManagers}
			<button class="ml-2 mb-2 btn btn-xs btn-secondary" disabled={!selectedProcedureIds?.length} on:click={() => (selectedProcedureIds = [])}>
				Очистить
			</button>
			{#if loading}
				<i class="fa fa-spinner fa-pulse fa-icon-large ml-2" style="vertical-align: unset;"></i>
			{/if}
			<ul class="list-group ml-2 mb-3">
				{#each arbitrManagers as arbitrManager}
					<li class="list-group-item borderless">
						<input
							id={arbitrManager.arbitr_manager.id}
							type="checkbox"
							on:click={toggleProcedures(arbitrManager)}
							checked={procedureIsSelected(arbitrManager.procedures)}
						/>
						<label for={arbitrManager.arbitr_manager.id} class="mb-0 ml-2">{arbitrManager.arbitr_manager.text} ({arbitrManager.procedures.length})</label>
					</li>
					<ul class="list-group ml-4">
						{#each arbitrManager.procedures as procedure}
							<li class="list-group-item borderless">
								<input id={procedure.id} type="checkbox" bind:group={selectedProcedureIds} value={procedure.id} />
								<label for={procedure.id} class="mb-0 ml-2">{procedure.text}</label>
							</li>
						{/each}
					</ul>
				{/each}
			</ul>
		{/if}
	</div>
</div>

<style>
	iconify-icon[icon='fa-solid:chevron-up'] {
		vertical-align: top;
	}

	iconify-icon[icon='fa-solid:chevron-down'] {
		vertical-align: bottom;
	}

	li.borderless {
		border: 0 none;
		padding: 5px;
	}
</style>
