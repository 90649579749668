<!-- @format -->
<script>
	import { autosaveStatus, transmitter } from 'base_stores';
	import { onMount, createEventDispatcher } from 'svelte';
	import iMask from 'imask';
	import 'tempusdominus-bootstrap-4';
	import '~/js/patch_for_datetimepicker';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import { validate } from 'tools/validators';
	import { randomString } from 'utils/tools';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import moment from 'moment';
	import { isPresent } from 'utils/tools';

	export let attributes;
	export let model;
	export let url;
	export let id;
	export let childModel;
	export let childId;
	export let additionalAttributes;
	export let requiredAttributes = {};
	export let label;
	export let skipVerificationRequired = true;
	export let value = undefined; // !!! если в месте вызова value неопределён, то сюда обязательно передать undefined
	export let required = false;
	export let disabled = false;
	export let forceSave = false;
	export let placeholder;
	export let hintData;
	export let additionalParams = {};
	export let labelAlign = 'left';
	export let saveAutomatically = true;
	export let labelCss = 'col-sm-4 col-lg-3 col-xl-4';
	export let divCss = 'col-sm-8 col-lg-9 col-xl-8';

	let inputField;
	let dataErrors;
	let datetimepickerNode;
	let maskRef;
	let previousValue = null;
	let fValue = null;
	let countdown = 1;
	let updating = false;
	let previousAttributeLength = false;
	let attributeNameForId = attributes[0];

	const dispatch = createEventDispatcher();

	const datepickerId = `datepicker-${model}-${attributeNameForId}`;

	$: method = id ? fetchPut : fetchPost;
	$: if (id) {
		url = `${url}/${id}`;
	}
	$: fValue = value ? moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY') : null;
	$: isSuccess = value && !dataErrors;
	$: forceVal = value;

	$: if (isPresent(value) && attributes.length !== previousAttributeLength && !updating) {
		let data = childModel ? $transmitter[childModel][model] : $transmitter[model];
		if (attributes.some(attr => data && data[attr] !== value)) {
			handleChange();
		}
	}

	$: if (!previousValue && countdown > 0 && value === undefined) {
		countdown = countdown - 1;
	}
	$: if (!previousValue && countdown === 0 && value !== undefined) {
		previousValue = value;
		countdown = countdown - 1;
	}

	const handleChange = () => {
		updating = true;
		$autosaveStatus = null;
		if (!moment(fValue, 'DD.MM.YYYY', true).isValid()) {
			fValue = value = null;
		}
		dataErrors = validate(model, attributes, forceSave ? forceVal : value, skipVerificationRequired);

		if (!dataErrors) {
			let params = {
				[model]: { ...requiredAttributes },
				[`${childModel || model}_id`]: childId || id,
				...additionalParams,
			};

			previousAttributeLength = attributes.length;
			attributes.forEach(attr => {
				params[model][attr] = forceSave ? forceVal : value;
			});

			if (additionalAttributes) {
				additionalAttributes.forEach(attributes => {
					if (attributes.previousValue === previousValue || !previousValue) {
						params[model] = { ...params[model], [attributes.name]: attributes.prepareValue(value) };
					}
				});
			}

			method(url, params)
				.then(response => {
					if (JSON.stringify(response) !== '{}') {
						$autosaveStatus = 'saved';
					}

					$transmitter = { ...$transmitter, ...response };
					previousValue = value;
					dispatch('update', response);
					return;
				})
				.catch(_errors => {
					inputField.focus();
					$autosaveStatus = 'not_saved';
					return;
				});
		} else {
			$autosaveStatus = 'not_saved';
		}

		attributes.forEach(attr => {
			$transmitter[model] = { ...$transmitter[model], [attr]: value };
		});
		updating = false;
	};

	const updateMaskValue = () => maskRef.updateValue(maskRef.value);

	onMount(() => {
		maskRef = iMask(inputField, { mask: Date });
		updateMaskValue();

		// eslint-disable-next-line no-undef
		datetimepickerNode = jQuery(`#${datepickerId}`);
		datetimepickerNode.datetimepicker({
			locale: 'ru',
			format: 'DD.MM.YYYY',
			useCurrent: false,
			allowInputToggle: true,
			keyBinds: {
				escape: null,
				up: null,
				down: null,
				right: null,
				left: null,
				delete: null,
			},
		});
		datetimepickerNode.on('change.datetimepicker', event => {
			event.stopPropagation();
			updateMaskValue();
			fValue = maskRef.value;

			if (/\d/.test(fValue)) {
				if (/\d{2}$/.test(fValue.split('.')[0]) && /\d{2}$/.test(fValue.split('.')[1]) && /\d{4}$/.test(fValue.split('.')[2])) {
					value = moment(fValue, 'DD.MM.YYYY').format('YYYY-MM-DD');
				} else {
					value = previousValue;
				}
			} else {
				value = null;
				fValue = null;
			}
		});
	});
</script>

<div class="form-group row" class:has-error={dataErrors} class:has-success={isSuccess}>
	<label for={datepickerId} class={`${labelCss} col-form-label text-${labelAlign}`} class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class={`${divCss} float-right`}>
		<div id={datepickerId} class="input-group date datetimepicker" data-target-input="nearest">
			<input
				id={`input-${model}-${attributeNameForId}-${randomString()}`}
				type="text"
				class:required-border={required}
				class:text-disabled={disabled}
				class="form-control datetimepicker-input"
				value={fValue}
				bind:this={inputField}
				{disabled}
				on:blur={saveAutomatically && handleChange}
				on:focus={updateMaskValue}
				{placeholder}
				spellcheck="false"
				data-target={`#${datepickerId}`}
				autocomplete="off"
			/>
			<div class="input-group-append" data-target={`#${datepickerId}`} data-toggle="datetimepicker">
				<div class="input-group-text" style={disabled ? 'cursor: not-allowed;' : ''}>
					<iconify-icon icon="bx-bxs-calendar" style={disabled ? 'opacity: 0.6;' : ''} />
				</div>
			</div>
		</div>
		{#if dataErrors}
			<InputErrorsString errors={dataErrors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>

<style lang="scss">
	@media (max-width: 576px) {
		.datetimepicker {
			width: 247px;
		}
	}
</style>
