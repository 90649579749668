<!-- @format -->
<script>
	import { transmitter } from '~/js/global_stores/base';
	import Header from '../components/header.svelte';
	import moment from 'moment';
	import AutosaveDatepicker from '../components/ui/autosave_datepicker.svelte';
	import AutosaveCheckbox from '../components/ui/autosave_checkbox.svelte';
	import MailPackagesTable from '../mail_packages/table.svelte';

	$: mailRegistry = $transmitter && $transmitter.mail_registry;
	$: substitutions = mailRegistry && mailRegistry.substitutions;
	$: category = substitutions && substitutions.dct_category;
	$: participantType = substitutions && substitutions.participant_type;
	$: meetingData = mailRegistry && mailRegistry.meeting_data;
	$: header = [
		'Реестр почтовых отправлений от',
		moment(mailRegistry.date).format('DD.MM.YYYY'),
		'(',
		{ outgoing: 'исходящие', meetings: 'уведомления собрания' }[category],
		category === 'meetings'
			? `${{ creditor: 'кредиторов', employee: 'работников' }[participantType]} от ${moment(meetingData && meetingData.date_at).format('DD.MM.YYYY')}`
			: null,
		')',
	]
		.filter(i => i)
		.join(' ');

	$: formFields = {
		date: {
			id: mailRegistry.id,
			required: true,
			value: mailRegistry.date,
			label: 'Дата реестра',
			placeholder: 'дд.мм.гггг',
			attribute: 'date',
			model: 'mail_registry',
			url: '/api/private/mail_registries',
			maskOptions: { mask: Date },
		},
		sent: {
			id: mailRegistry.id,
			value: !!mailRegistry.sent,
			label: 'Реестр отправлен',
			attribute: 'sent',
			model: 'mail_registry',
			url: '/api/private/mail_registries',
			text: 'Да',
		},
	};

	const backUrl = () => {
		let url;

		if (category == 'outgoing') {
			url = '/documents/outgoings';
		} else if (category == 'meetings') {
			url = `/meetings/${meetingData && meetingData.id}/edit#notifications`;
		}

		return url;
	};
</script>

<Header><span slot="name">{header}</span></Header>
<div class="overlay mt-3 mb-5">
	<div class="col-4 pl-0">
		<a class="back-to-list btn btn-sm btn-outline-secondary mb-2" style="font-size: inherit;" href={backUrl()} data-turbolinks="false">
			<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24">
				<path d="m12.707 7.707-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z"></path>
				<path d="M16.293 6.293 10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z"></path>
			</svg>
			<span style="margin-left: -5px;">К списку</span>
		</a>
		<AutosaveDatepicker {...formFields.date} />
		<AutosaveCheckbox {...formFields.sent} />
	</div>
	<div col="12">
		<MailPackagesTable {mailRegistry} />
	</div>
</div>
