<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { substitutions, currentData, commonParams } from '../stores/stores';
	import AutosaveDatepicker from '../../components/ui/autosave_datepicker.svelte';

	let init = true;
	let closing_date;

	$: if (init && $procedure && $procedure.requirements_reestr) {
		closing_date = $procedure.requirements_reestr.closing_date;
		init = false;
	}

	$: requirementsReestrClosingDate = {
		...$commonParams,
		attribute: 'requirements_reestr_closing_date',
		label: 'Дата закрытия РТК',
		value: $currentData && $currentData.requirementsReestrClosingDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData: {
			url: '/requirements_reestr',
			linkText: 'РТК',
			consumerNew: $newObject,
			consumer: $substitutions,
			consumerProp: 'substitutions.requirements_reestr_closing_date',
			//resource: $procedure && $procedure.requirements_reestr,
			//resourceProp: 'closing_date',
			something: closing_date,
		},
	};
</script>

<AutosaveDatepicker {...requirementsReestrClosingDate} />
