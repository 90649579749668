<!-- @format -->
<script>
	import Case from 'case';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveWysiwygTextbox from '~/svelte/components/ui/autosave_wysiwyg_textbox.svelte';
	import AutosaveTextarea from '~/svelte/components/ui/autosave_textarea.svelte';

	export let label;
	export let attribute;
	export let placeholder;

	$: documentArbitraryText = {
		...$commonParams,
		attribute: attribute ? Case.snake(attribute) : 'arbitrary_text',
		label: label || ($dct.kind == 'arbitrary' ? 'Текст документа' : 'Дополнительный текст'),
		value: $currentData[attribute || 'arbitraryText'],
		placeholder: placeholder || ($dct.kind == 'arbitrary' ? 'Текст документа свободной формы' : ''),
	};
</script>

{#if ['reports', 'financial_analysis'].includes($dct.category)}
	<AutosaveTextarea {...documentArbitraryText} on:update />
{:else}
	<AutosaveWysiwygTextbox {...documentArbitraryText} on:update />
{/if}
