<!-- @format -->
<script>
	import { currentData, commonParams, dct, requiredFieldsFilled } from '../../stores/stores';
	import AutosaveInput from '../../../components/ui/autosave_input.svelte';

	$: if ($dct.form_fields.includes('arbitraryCorrespondent')) {
		$requiredFieldsFilled.arbitraryCorrespondent = !!arbitraryCorrespondent.value;
	}

	$: arbitraryCorrespondent = {
		...$commonParams,
		required: true,
		attribute: 'arbitrary_correspondent',
		label: 'Адресат',
		value: $currentData.arbitraryCorrespondent,
		placeholder: 'Введите адресат',
	};
</script>

<AutosaveInput {...arbitraryCorrespondent} on:update />
