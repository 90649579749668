<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { procedure, newObject, changedFields } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import Checkbox from '~/svelte/components/ui/checkbox.svelte';
	import AutosaveDadataInputFio2 from '~/svelte/components/ui/autosave_dadata_input_fio2.svelte';

	const dispatch = createEventDispatcher();

	const markAsChanged = e => {
		$changedFields = [...new Set([...$changedFields, 'bankruptFullNamePrevious'])];
		dispatch('update', e);
	};

	$: procedureId = $procedure?.id;
	$: hasPreviousFullName = $currentData?.bankruptFullNamePrevious;
	$: changedFullNameCheck = hasPreviousFullName;
	$: showPreviousFullName = changedFullNameCheck || hasPreviousFullName;

	$: formFields = {
		bankruptFullNamePrevious: {
			...$commonParams,
			attribute: 'bankrupt_full_name_previous',
			label: 'Предыдущие ФИО должника',
			placeholder: 'Фамилия Имя Отчество',
			value: $currentData?.bankruptFullNamePrevious,
			hintData: {
				url: `/procedures/${procedureId}`,
				linkText: 'Процедура',
				consumerNew: $newObject,
				consumer: $dct,
				consumerProp: 'substitutions.bankrupt_full_name_previous',
				resource: $procedure && $procedure.bankrupt,
				resourceProp: 'full_name_previous',
			},
		},
		changedFullNameCheck: {
			disabled: !procedureId || !$procedureAvailable || hasPreviousFullName,
			label: 'Должник менял ФИО',
			text: 'да, менял',
		},
	};
</script>

<Checkbox {...formFields.changedFullNameCheck} bind:checked={changedFullNameCheck} />
{#if showPreviousFullName}
	<AutosaveDadataInputFio2 {...formFields.bankruptFullNamePrevious} on:update={markAsChanged} />
{/if}
